import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  AppSettings,
  SEOService,
  HelperService,
  AuthenticationService,
  deferImageService,
} from '../../services/_services';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TtqTrackingService } from 'src/app/services/_services/ttq-tracking.service';

declare var $, Swiper;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  public currentUser: any;
  public authSub: any;
  public routerSubscription: any;
  public navOpen: any;
  public resultDownloadPdf: string =
    'assets/downloads/results/selected-candidates-of-psh.pdf';
  public resultEigthGradeDownloadPdf: string =
    'assets/downloads/results/grade-eight-result.pdf';
  public mobNav: any = false;
  public mobDropdown: any = false;
  public normalNav: any = false;
  public fixedNavPages: any = [
    'dashboard',
    'inquiry',
    'profile',
    'thanks',
    'testimonials',
    'about-us',
    'our-projects',
    'get-involved',
    'news',
    'contact-us',
    'gallery',
    'student-application',
    'student-dashboard',
    'orphan-profiles',
    'add-sponsorship',
  ];
  public fixedNavPages2: any = [
    'signup',
    'student-application',
    'student-dashboard',
    'login',
    'fill-form',
    'review-information',
  ];
  public page: any;
  public subPage: any;
  public admissionPage: any = false;
  public siUrl: any = '/sign-in';
  public suUrl: any = '/sign-in';
  public homepage: any = false;
  public mobDonateShow = true;
  public noindexUrls = [
    'orphan-profiles',
    'sponsorhip-program',
    'scholarship-program',
    'add-sponsorship',
  ];

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private helperService: HelperService,
    private seoService: SEOService,
    private deferImageService: deferImageService,
    private ttqTrackingService: TtqTrackingService
  ) {
    // this.currentUser = this.authenticationService.get_currentUserValue();
    if (typeof localStorage != 'undefined') {
      this.authSub = this.authenticationService.currentUserS$.subscribe(
        (user) => {
          if (user) {
            this.currentUser = user;
            // console.log("--- this.currentUser: ", this.currentUser);
          } else {
            this.currentUser = null;
          }
        }
      );
    }
    this.routeCheck();

    $('section').removeClass('transformLeft');
  }

  identifyUser(email, phone_number, id) {
    this.ttqTrackingService.identify(email, phone_number, id);
  }

  routeCheck() {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        let fullUrl = val.url.split('/');
        let firstUrl = val.url.split('/')[1];
        this.page = firstUrl;
        if (fullUrl.length < 4) {
          this.subPage = fullUrl[fullUrl.length - 1];
        } else {
          this.subPage = fullUrl[fullUrl.length - 2];
        }

        if (
          val.url == '/donate' ||
          val.url == '/facebook-lp-7578466214' ||
          val.url == '/add-sponsorship' ||
          val.url == '/thankyou-sponsor' ||
          val.url == '/thanks'
        ) {
          if (this.currentUser) {
            this.identifyUser(
              this.currentUser.email,
              this.currentUser.phone_number,
              this.currentUser.ID
            );
          }
          if (val.url == '/donate') {
            this.ttqTrackingService.track(
              'ViewContent',
              5,
              'USD',
              '123',
              'Donate',
              'Donation',
              5
            );
          } else if (val.url == '/facebook-lp-7578466214') {
            this.ttqTrackingService.track(
              'ViewContent',
              5,
              'USD',
              '234',
              'Landing Page',
              'Landing Page',
              5
            );
          } else if (val.url == '/add-sponsorship') {
            this.ttqTrackingService.track(
              'ViewContent',
              99,
              'USD',
              '234',
              'Sponsor',
              'Sponsorship',
              99
            );
          } else if (val.url == '/thankyou-sponsor') {
            this.ttqTrackingService.track(
              'PlaceAnOrder',
              99,
              'USD',
              '234',
              'Sponsor',
              'Sponsorship',
              99
            );
          } else if (val.url == '/thanks') {
            this.ttqTrackingService.track(
              'PlaceAnOrder',
              5,
              'USD',
              '123',
              'Donate',
              'Donation',
              5
            );
          }
        }

        if (this.fixedNavPages.includes(firstUrl)) {
          this.normalNav = true;
        } else if (this.fixedNavPages2.includes(this.subPage)) {
          this.normalNav = true;
        } else if (val.url.includes('admission/forgot-password')) {
          this.normalNav = true;
        } else {
          this.normalNav = false;
        }
        if (
          firstUrl == 'admission' ||
          firstUrl == 'cadet-college-admission-8th-class' ||
          firstUrl == 'cadet-college-admission-11th-class' ||
          val.url.includes('applicant')
        ) {
          this.admissionPage = true;
          this.siUrl = '/admission/login';
          this.suUrl = '/admission/sign-up';
        } else {
          this.admissionPage = false;
          this.siUrl = 'sign-in';
          this.suUrl = 'sign-up';
        }

        this.homepage = false;
        this.mobDonateShow = true;
        if (
          val.url == '/' ||
          val.url == '/NLP-LFVD3H0xvDn7cryQOIaLpEAv0yHlWPSJH4AxzCfY'
        ) {
          this.normalNav = false;
          this.homepage = true;
          this.setSEO_Tags(
            'Pakistan Sweet Home - A Heaven for the Underprivileged',
            'Pakistan Sweet Home is an orphanage for abandoned children across the country providing basic necessities of life including education, home, & healthcare.'
          );
        } else if (val.url == '/audit-reports') {
          this.setSEO_Tags(
            'Annual Audit Reports | Pakistan Sweet Home',
            'We believe in transparency and accountability, and we want you to hold us responsible for the outcome of your donations. Check out our annual reports here!'
          );
        } else if (val.url == '/certificates') {
          this.setSEO_Tags(
            'Certificates | Pakistan Sweet Home',
            'Find out what certifications Pakistan Sweet Home has earned, why they are important to you, and how we ensure total security and transparency of data.'
          );
        } else if (val.url == '/gallery') {
          this.setSEO_Tags(
            'Our Gallery | Pakistan Sweet Home',
            'Visit our gallery to check out the pictures and videos of our events, opening ceremonies & recognizable guests visiting our Pakistan Sweet Home’s headquarters.'
          );
        } else if (val.url == '/testimonials') {
          this.setSEO_Tags(
            'Testimonials | Pakistan Sweet Home',
            'Check out these testimonials from some of the most famous personalities in Pakistan, and see what they have to say about Pakistan Sweet Home.'
          );
        } else if (val.url == '/our-projects') {
          this.setSEO_Tags(
            'Our Projects | Pakistan Sweet Home',
            'Find out the details of all upcoming and completed projects. Each project is divided into phases with complete information and expense details for each phase.'
          );
        } else if (val.url == '/donate') {
          this.setSEO_Tags(
            'Sign Up | Pakistan Sweet Home',
            'Sign up to donate to Pakistan Sweet Home by joining our 10,000+ incredible members! The sum is small, but the impact will be huge.'
          );
        } else if (val.url == '/privacy') {
          this.setSEO_Tags(
            'Privacy Policy | Pakistan Sweet Home',
            'Our privacy policy explains how we collect and store your data. Pakistan Sweet Home’s website does not share any information with any third parties.'
          );
        } else if (val.url == '/terms') {
          this.setSEO_Tags(
            'Terms and Conditions | Pakistan Sweet Home',
            'Here are the terms and conditions for use of the Pakistan Sweet Home’s website. Please read it before using any information provided on the website.'
          );
        } else if (val.url == '/refund-policy') {
          this.setSEO_Tags(
            'Refund & Exchange Policy | Pakistan Sweet Home',
            'The refund and exchange policy of Pakistan Sweet Home is very simple. Continue reading to learn more about our policies and refunds.'
          );
        } else if (val.url == '/donation-detail') {
          this.setSEO_Tags(
            'Donation Details | Pakistan Sweet Home',
            'The next step is to fill in the donation details. One-time, monthly, or yearly donation options are available to help the children with their basic needs.'
          );
        } else if (val.url == '/payment-detail') {
          this.setSEO_Tags(
            'Payments Details | Pakistan Sweet Home',
            'The last step is to fill in the payment details. You can choose from the online transfer via card, doorstep collection, cheque or draft, or walk-in donations.'
          );
        } else if (
          val.url == '/fatima-jinnah-campus-for-girls-islamabad-center'
        ) {
          this.setSEO_Tags(
            'Fatima Jinnah Campus For Girls (Islamabad Center)',
            'Pakistan Sweet Home AFP was created in 2016. This campus in Islamabad also provides shelter to children who have lost their parents in terrorist attacks.'
          );
        } else if (
          val.url ==
          '/golden-arrow-pakistan-sweet-home-mir-ali-north-waziristan'
        ) {
          this.setSEO_Tags(
            'Golden Arrow Pakistan Sweet Home (Mir Ali, North Waziristan)',
            'Golden Arrow Pakistan Sweet Home was created in 2017 in collaboration with Pakistan Army. Find out all about this program and how you can contribute to it. '
          );
        } else if (val.url == '/irshad-bb-complex-bhalwal') {
          this.setSEO_Tags(
            ' Irshad BB Complex Bhalwal | Pakistan Sweet Home',
            'Irshad BB complex located in Bhalwal city is a free education center for all unprivileged children. It provides free education, books, and uniforms to students.'
          );
        } else if (val.url == '/pakistan-sweet-home-cadet-college') {
          this.setSEO_Tags(
            ' Pakistan Sweet Home Cadet College for Orphans ',
            'Pakistan Sweet Home AFP is the first of its kind Cadet Colleges for Orphans. It’s located in Sohawa, Gujar Khan Distt. Rawalpindi with all the basic facilities.'
          );
        } else if (
          val.url == '/major-aziz-bhatti-nishan-e-haider-campus-islamabad'
        ) {
          this.setSEO_Tags(
            ' Major Aziz Bhatti (Nishan-e-Haider) Campus (Islamabad)',
            'The campus is named after the famous Pakistani hero Major Muhammad Aziz Bhatti Nishan-e-Haider. This campus has been dedicated to the boys of 6th - 8th class.'
          );
        } else if (val.url == '/pakistan-great-home') {
          this.setSEO_Tags(
            'Pakistan Great Homes Helps Old People In Islamabad ',
            ' Pakistan Great Homes is an organization by the Al Zohra Education Trust UK that provides shelter, food, and medical care to underprivileged older people. '
          );
        } else if (
          val.url == '/rashid-minhas-nishan-e-haider-campus-islamabad'
        ) {
          this.setSEO_Tags(
            'Rashid Minhas Campus | Pakistan Sweet Home ',
            ' This separate campus has been dedicated for boys of 1st - 5th Class. For education, all the boys have been admitted to a nearby, renowned Siddeeq Public School.'
          );
        } else if (
          val.url == '/sarwar-shaheed-nishan-e-haider-campus-islamabad'
        ) {
          this.setSEO_Tags(
            'Sarwar Shaheed Campus | Pakistan Sweet Home ',
            ' This separate campus has been dedicated for boys of 6th - 8th class. All the boys have been admitted to nearby one of the renowned National School System.'
          );
        } else if (val.url == '/sheikhupura-center-under-construction') {
          this.setSEO_Tags(
            'Sheikhupura Center | Pakistan Sweet Home',
            ' Sheikhupura Center is under construction for the last 1 year. This school will be first of its kind in the area with different facilities to help the children.'
          );
        } else if (val.url == '/sukkur-center') {
          this.setSEO_Tags(
            'AFP’s Sukkur Center | Pakistan Sweet Home',
            '  AFP’s Sukkur center is one the best center of its kind in interior Sindh. Continue to learn more about how Sukkar Center is helping the underprivileged.'
          );
        } else if (val.url == '/thankyou') {
          this.setSEO_Tags(
            'Thank you for Your Donation | Pakistan Sweet Home',
            ' We would like to thank you for your donation. Your donation will help us in reaching our goal of helping the underprivileged by providing them basic necessities'
          );
        } else if (val.url == '/sorry') {
          this.setSEO_Tags(
            'Payment Failed - Try Again Later',
            'We are sorry but your payment could not be processed at this time. We apologize for any inconvenience caused. Please try again later.'
          );
        } else if (val.url == '/album') {
          this.setSEO_Tags(
            'Albums - Pictures & Videos | Pakistan Sweet Home',
            'Welcome to the official site of Pakistan Sweet Home. Here you will find all the images and videos of our recent activities, programs, events, and ceremonies.'
          );
        } else if (val.url == '/contact-us') {
          this.setSEO_Tags(
            'Contact Us | Pakistan Sweet Home',
            'In order to contact us, fill in the form by providing us your details along with the email and phone number. We will in touch with you as soon as possible.'
          );
        } else if (val.url == '/sponsorship-program') {
          this.setSEO_Tags(
            'Change a Life Today | Child Sponsorship Program - Pakistan Sweet Home',
            "Join our child sponsorship program. For just $99 a month, transform an orphan's life and future. Learn more about how you can make a difference."
          );
        } else if (val.url == '/orphan-profiles' && this.page == this.subPage) {
          this.setSEO_Tags(
            'Meet Our Children | Explore Orphan Profiles - Pakistan Sweet Home',
            'Browse through the profiles of our children awaiting sponsorship. Their unique stories await your kindness. Start your sponsorship journey today.'
          );
        } else if (val.url == '/add-sponsorship') {
          this.setSEO_Tags(
            'Sponsor a Child in 3 Easy Steps | Pakistan Sweet Home',
            'Ready to make a difference? Sponsor a child with us in just three simple steps. Join our mission to transform lives and futures today.'
          );
        } else if (val.url == '/thankyou-sponsor') {
          this.setSEO_Tags(
            'Thank You for Your Sponsorship | Pakistan Sweet Home',
            'Your sponsorship is the beginning of a life-changing journey. Thank you for your generosity and for being a beacon of hope for our children.'
          );
        } else if (val.url == '/dashboard') {
          this.setSEO_Tags(
            'Your Sponsorship Dashboard | Pakistan Sweet Home',
            "Manage and track your sponsorships, view your sponsored child's progress, and make updates to your account. All in one place."
          );
        } else if (val.url == '/dashboard/settings') {
          this.setSEO_Tags(
            'Manage Your Account Settings | Pakistan Sweet Home',
            'Personalize your account settings and update your contact details to stay connected with your sponsored child.'
          );
        } else if (val.url == '/blog') {
          this.setSEO_Tags(
            'Blogs | Pakistan Sweet Homes',
            'Read blogs about our charity, donations, and all about our orphanage here.'
          );
        } else if (val.url == '/give-charity-in-rabi-awwal-2024') {
          this.setSEO_Tags(
            'Give Charity in Rabi Al-Awwal 2024',
            'Give charity in Rabi al-Awwal to honor the Prophet’s Sunnah and uplift orphans. Your support helps us host Milad and Islamic sessions & provide meals to them.'
          );
        } else if (val.url == '/support-our-needs') {
          this.setSEO_Tags(
            'Support the Orphan Needs - Pakistan Sweet Home',
            'Empower orphans with your contributions to education, food, health, and sports. Donate now to help shape a brighter future for them.'
          );
        } else {
          this.setSEO_Tags('Pakistan Sweet Home', ' Pakistan Sweet Home ');
        }

        if (val.url == '/sponsorship-program') {
          this.mobDonateShow = false;
        }

        if (this.noindexUrls.includes(firstUrl)) {
          console.log('noindex');
          this.mobDonateShow = false;
          this.seoService.addNofollow();
          this.setSEO_Tags('Pakistan Sweet Home', ' Pakistan Sweet Home ');
        }
      }
    });
  }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.menuOverlay').click(function () {
        $('.cat-pages').removeClass('show');
      });

      $('a.pageLink.mobmydropbtn.about_btn').click(function () {
        const $currentDropdown = $(this).siblings('.mobdropdown-content');

        // Close other dropdowns and remove the 'toggled' and 'opened' classes
        $('.mobdropdown-content').not($currentDropdown).slideUp('fast');
        $('.navItems li').not($(this).parent().parent()).removeClass('toggled');
        $('a.pageLink.mobmydropbtn.about_btn').not(this).removeClass('opened');

        // Toggle the current dropdown
        $currentDropdown.slideToggle('fast');
        $(this).parent().parent().toggleClass('toggled');
        $(this).toggleClass('opened'); // Add 'opened' class to control arrow color
      });

      if ($('.cat-pages').hasClass('show')) {
        $('body').addClass('menuOverlay');
      }
    });
  }

  ngOnDestroy() {
    if (typeof this.authSub != 'undefined') {
      //prevent memory leak when component destroyed
      this.authSub.unsubscribe();
    }

    console.log('destroyed');
  }

  logout() {
    this.authenticationService.simpleLogout();
    this.router.navigate(['/']);
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }

  toggleNav() {
    this.mobNav = !this.mobNav;
    $('section').toggleClass('transformLeft');
    $('body').css('height', '100vh');
    $('body').css('overflow', 'hidden');
  }
  removeNav() {
    this.mobNav = !this.mobNav;
    $('section').removeClass('transformLeft');
    $('body').css('height', 'unset');
    $('body').css('overflow', 'inherit');
  }
}
