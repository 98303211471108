import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'shortName'
})
export class ShortNamePipe implements PipeTransform {

  transform(value: String): unknown {
    let nameArr = value.split(' ');
    if(nameArr.length > 2){
      let shortName = nameArr[nameArr.length - 2] + ' ' + nameArr[nameArr.length - 1];
      return shortName;
    } else {
      return value;
    }

    
  }

}
