import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AuthenticationService, HelperService, COUNTRYLIST, AppSettings } from 'src/app/services/_services';

declare var $;

@Component({
  selector: 'app-sponsor-contact-details',
  templateUrl: './sponsor-contact-details.component.html',
  styleUrls: ['./sponsor-contact-details.component.css']
})
export class SponsorContactDetailsComponent implements OnInit, OnDestroy {

  @Input() contactDetails;
  @Input() sponsorships;
  @Output() stepChangeEvent = new EventEmitter();
  @Output() contactDetailsChangeEvent = new EventEmitter();

  public authSub;
  public currentUser;

  public countryOptions : any;
  public flagOptions : any;

  public countryList;
  public countryAgainstCode;

  public countryCode;
  public country;
  public city;

  public loading = true;
  public disabledBtn = false;

  public sponsorDetails = {
                            ID              : '',
                            name            : '',
                            email           : '',
                            phone           : {
                                                code     : '',
                                                number   : '',
                                                country  : ''
                                              },
                            whatsapp        : {
                                                code     : '',
                                                number   : '',
                                                country  : ''
                                              },
                            address         : '',
                            city            : '',
                            country         : ''
                          }

  constructor(
    private authenticationService  : AuthenticationService,
    private helperService          : HelperService,
    private http                   : HttpClient,
  ) {    

    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.currentUser = user;
            console.log('current user::', this.currentUser);
          } else {
          this.currentUser = null;
        }
      });
    }

    this.countryList = COUNTRYLIST.COUNTRY_LIST;
    this.countryAgainstCode = COUNTRYLIST.COUNTRY_AGAINST_CODE;
    this.getGeoLocs();

   }

   ngOnDestroy(): void {
    this.disabledBtn = false;
   }

   generateLead(){
    let data = new FormData();

    var duration;
    var amount = 0;
    var nature;

    for (let i = 0; i < this.sponsorships.length; i++) {
      const element = this.sponsorships[i];
      if(element.type == 'Child Sponsorship' || element.type == 'Special Friends Club'){
        duration = element.timePeriod;
        nature = element.type == 'Child Sponsorship' ? 'Sponsor' : 'Membership'
      }
      amount += Number(element.price);
    }

    data.append('duration', duration)    
    data.append('nature', nature)    
    data.append('email', this.currentUser.email)    
    data.append('amount', amount.toString())    

    let url  = AppSettings.BACK_ENDPOINT + 'create-lead';

    this.helperService.httpPostRequests(url, data).then(resp => {
      console.log('lead response::', resp)
    }).catch(error => {
        console.error("error: ", error);
    });
   }

  ngOnInit(): void {
    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
      const countries = [];
      const countriesImg = [];
      for (let i = 0; i < resp.length; ++i) {
          const country = resp[i];
          countries.push(country.text);
          countriesImg.push(country.value);
      }
      this.countryOptions = countries;
      this.countryOptions.push('Canada');  
      this.countryOptions.sort();
      var canadaIndex = this.countryOptions.indexOf('Canada');      
      this.flagOptions = countriesImg;
      this.flagOptions.splice(canadaIndex, 0, 'CA');
  });

  this.generateLead()

  }

  saveContactDetails(event){
    event.preventDefault();

    if(this.validateForm()){
      this.updateProfile();
    }

  }

  updateProfile(){
    this.disabledBtn = true;

    let data = new FormData();
    data.append("action" , "update_user_form");
    data.append("ID", this.currentUser.ID);

    var firstName = this.sponsorDetails.name.includes(' ') ? this.sponsorDetails.name.split(' ')[0] : this.sponsorDetails.name;
    var lastName = this.sponsorDetails.name.includes(' ') ? this.sponsorDetails.name.split(' ')[1] : '';
    var phone_number = '+' + this.sponsorDetails.phone.code + ' ' + this.removeFirstZero(this.sponsorDetails.phone.number);
    var whatsapp_number = '+' + this.sponsorDetails.whatsapp.code + ' ' + this.removeFirstZero(this.sponsorDetails.whatsapp.number);

    data.append('firstName', firstName);
    data.append('lastName', lastName);
    data.append('phone_number', phone_number);
    data.append('whatsapp_number', whatsapp_number);
    data.append('address', this.sponsorDetails.address);
    data.append('city', this.sponsorDetails.city);
    data.append('country', this.sponsorDetails.country);

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

    this.helperService.httpPostRequests(url, data).then(resp => {
      if(resp.status != 'ERROR'){
        this.authenticationService.setCurrentUserValue(resp.data);
        this.contactDetailsChangeEvent.emit(this.sponsorDetails);
        this.stepChangeEvent.emit('make-payment');
      }
    }).catch(error => {
        console.error("error: ", error);
        this.disabledBtn = false;
    });


  }

  getGeoLocs() {
    const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
      this.helperService.httpGetRequests(url).then(resp => {
            this.setSponsorDetails(this.currentUser, resp);
      }).catch(err => {
        console.log('error', err)
      })
  }

  setSponsorDetails(user, geoResp){

    console.log('geoResp::', geoResp);

    if(this.contactDetails){
      this.sponsorDetails = this.contactDetails;
    } else {
      this.country = geoResp.geoplugin_countryName;
    this.city = geoResp.geoplugin_city;
    this.countryCode = this.countryList[this.country]['countryCodes'][0];
    
    this.sponsorDetails.ID = user.ID;
    this.sponsorDetails.name = user.name;
    this.sponsorDetails.email = user.email;    

    this.sponsorDetails.phone.code = user.phone_number && user.phone_number.length > 8 ? user.phone_number.split(' ')[0].replace('+','') : this.countryCode;    
    this.sponsorDetails.phone.number = user.phone_number && user.phone_number.length > 8 ? this.removeFirstZero(user.phone_number.split(' ')[1]) : '';   
    this.sponsorDetails.phone.country = this.countryAgainstCode[this.sponsorDetails.phone.code]['isoCode2'];
    
    this.sponsorDetails.whatsapp.code = user.whatsapp_number && user.whatsapp_number.length > 8 ? user.whatsapp_number.split(' ')[0].replace('+','') : this.countryCode;    
    this.sponsorDetails.whatsapp.number = user.whatsapp_number && user.whatsapp_number.length > 8 ? this.removeFirstZero(user.whatsapp_number.split(' ')[1]) : '';   
    this.sponsorDetails.whatsapp.country = this.countryAgainstCode[this.sponsorDetails.whatsapp.code]['isoCode2'];

    this.sponsorDetails.address = user.address;
    this.sponsorDetails.city = user.city ? user.city : this.city;
    this.sponsorDetails.country = user.country ? user.country : this.country;
    }

    this.loading = false;

  }

  setPhoneValues(numberType, country, flag){
    if(numberType == 'phone'){
      this.sponsorDetails.phone.code = this.countryList[country]['countryCodes'][0];
      this.sponsorDetails.phone.country = flag;
    } else if(numberType == 'whatsapp'){
      this.sponsorDetails.whatsapp.code = this.countryList[country]['countryCodes'][0];
      this.sponsorDetails.whatsapp.country = flag;
    }
  }

  isNumberValidate(e, type) {
    var charCode = (e.which) ? e.which : e.keyCode;
    if(type == 'phone'){
      if(!this.sponsorDetails.phone.number && charCode == 48){
        return false;
      }
    } else if(type == 'whatsapp'){
      if(!this.sponsorDetails.whatsapp.number && charCode == 48){
        return false;
      }
    }
    
  }

  changeStep(stepName){
    this.contactDetailsChangeEvent.emit(this.sponsorDetails);
    this.stepChangeEvent.emit(stepName);
  }

  validateForm(){
    if($('.contactDetailsForm').hasClass('ng-valid')){
      return true;
    } else {
      $('input.ng-invalid').addClass('invalid');
      $('input.invalid').each(function(){
        $(this).focus();
        return false;
      })
    }
  }

  removeError(e){
    if($(e).val()){
      $(e).removeClass('invalid');
    }
  }

  removeFirstZero(str) {
    let modifiedString = str;

    if (str.charAt(0) == "0") {
      modifiedString = str.slice(1);
    }
  
    return modifiedString;
  }

}
