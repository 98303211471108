import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $;

@Component({
  selector: 'app-sponsorship-thanks',
  templateUrl: './sponsorship-thanks.component.html',
  styleUrls: ['./sponsorship-thanks.component.css', '../../../include/footer/footer.component.css']
})
export class SponsorshipThanksComponent implements OnInit {

  public timer = 30;
  public thanksText = 'Sponsorship Program';

  constructor(
    private router : Router,
    private activatedRoute : ActivatedRoute
    ) {
    let url = this.activatedRoute.snapshot['_routerState'].url;

      if(url.includes('member')){
        this.thanksText = 'Special Friends Club'
      } else {
        this.thanksText = 'Sponsorship Program'
      }

   }

  ngOnInit(): void {

    setInterval(() => {
      this.timer--;
      this.timer ? '' : window.location.href = window.location.origin + '/dashboard';
    }, 1000);

  }

}
