import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-donation-jazzcash',
  templateUrl: './donation-jazzcash.component.html',
  styleUrls: ['./donation-jazzcash.component.css']
})
export class DonationJazzcashComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Support Pakistan Sweet Home via JazzCash Donation","Help to Pakistan Sweet Home via JazzCash Donation and help provide orphans with shelter, education, and a bright future.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
