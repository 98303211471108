<section class="projectsTopSec newBlueSec">
  <div class="container">
    <div class="projectHead">
      <p>CONTACT US</p>
      <h1>We’d Love to Hear From You!</h1>
    </div>
    <div [pageName]="activeProjectType" *ngIf="activeProjectType == 'campuses'">
      <div class="contact_section">
        <div class="main_contact">
          <div class="contact_left">
            <p>
              To contact us with your questions and suggestions please reach out
              to us at the given contact details and we will direct them to the
              right person within our team. Thank you.
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.511 0H6.48828C5.10192 0 3.98828 1.11364 3.98828 2.5V17.5C3.98828 18.8864 5.12464 20 6.48828 20H13.511C14.8974 20 16.011 18.8864 16.011 17.5V2.5C16.011 1.11364 14.8974 0 13.511 0ZM10.011 18.3864C9.37464 18.3864 8.87465 17.8864 8.87465 17.25C8.87465 16.6136 9.37464 16.1136 10.011 16.1136C10.6474 16.1136 11.1474 16.6136 11.1474 17.25C11.1474 17.8864 10.6474 18.3864 10.011 18.3864ZM14.8746 14.9091H5.12464V3.34091H14.8746V14.9091Z"
                  fill="black"
                />
              </svg>
              <strong>General Inquiries:</strong> +92 335 1118477
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              ></svg>
              <strong>Admission Inquiries:</strong> +92 333 8410011<br />+92 335
              1118476
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8234 3.97412L13.7578 10.0006L19.8234 16.0272C19.933 15.798 19.9996 15.5446 19.9996 15.2741V4.72721C19.9996 4.45662 19.933 4.2033 19.8234 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M18.2414 2.96875H1.75699C1.48641 2.96875 1.23309 3.03527 1.00391 3.14492L8.75637 10.8583C9.44184 11.5438 10.5565 11.5438 11.242 10.8583L18.9945 3.14492C18.7653 3.03527 18.512 2.96875 18.2414 2.96875Z"
                  fill="#221F1F"
                />
                <path
                  d="M0.176172 3.97412C0.0665234 4.2033 0 4.45662 0 4.72721V15.2741C0 15.5447 0.0665234 15.798 0.176172 16.0272L6.24176 10.0006L0.176172 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M12.9289 10.8286L12.0705 11.6869C10.9284 12.8291 9.06992 12.8291 7.92777 11.6869L7.06949 10.8286L1.00391 16.8551C1.23309 16.9648 1.48641 17.0313 1.75699 17.0313H18.2414C18.512 17.0313 18.7653 16.9648 18.9945 16.8551L12.9289 10.8286Z"
                  fill="#221F1F"
                />
              </svg>
              info@pakistansweethome.org.pk
            </p>
            <p class="hecText">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0.625C8.17664 0.625 6.42795 1.34933 5.13864 2.63864C3.84933 3.92795 3.125 5.67664 3.125 7.5C3.125 11.1313 9.25 18.8125 9.5125 19.1437C9.57106 19.2167 9.64527 19.2757 9.72965 19.3161C9.81402 19.3566 9.90641 19.3776 10 19.3776C10.0936 19.3776 10.186 19.3566 10.2704 19.3161C10.3547 19.2757 10.4289 19.2167 10.4875 19.1437C10.75 18.8125 16.875 11.1313 16.875 7.5C16.875 5.67664 16.1507 3.92795 14.8614 2.63864C13.572 1.34933 11.8234 0.625 10 0.625ZM10 9.375C9.50555 9.375 9.0222 9.22838 8.61107 8.95367C8.19995 8.67897 7.87952 8.28852 7.6903 7.83171C7.50108 7.37489 7.45157 6.87223 7.54804 6.38727C7.6445 5.90232 7.8826 5.45686 8.23223 5.10723C8.58186 4.7576 9.02732 4.5195 9.51227 4.42304C9.99723 4.32657 10.4999 4.37608 10.9567 4.5653C11.4135 4.75452 11.804 5.07495 12.0787 5.48607C12.3534 5.8972 12.5 6.38055 12.5 6.875C12.5 7.53804 12.2366 8.17393 11.7678 8.64277C11.2989 9.11161 10.663 9.375 10 9.375Z"
                  fill="#221F1F"
                />
              </svg>
              Pakistan Sweet Home, Sector H-9/4 , Street 4, near HEC building,
              Islamabad Capital Territory, 44000.
            </p>
            <!-- <p class="supportText">
              <strong>Support:</strong> +923333006752 | +923333001964 |
              +923333001660 | +923351118482
            </p> -->
            <!-- <p class="supportText"> <strong>Support:</strong> +923333006752 | +923333001964 | +923333001660 | +923351118482 </p> -->
            <div class="supportNumbers">
              <p style="font-size: 12px">
                Support:&nbsp;+923333006752&nbsp;|&nbsp;+923333001964&nbsp;|&nbsp;+923333001660&nbsp;|&nbsp;
                +923351118482
              </p>
              <!-- <div>Support:</div>
              <div>+923333006752</div>
              <div>|</div>
              <div>+923333001964</div>
              <div>|</div>
              <div>+923333001660</div>
              <div>|</div>
              <div>+923351118482</div> -->
            </div>
          </div>
          <div class="contact_right">
            <img
              class="desk-img"
              src="assets/new-images/contact-us-img.png"
              alt="contact img"
            />
            <img
              class="mob-img"
              src="assets/new-images/contact-us-img.png"
              alt="contact img"
            />
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="activeProjectType == 'bloodBank'">
      <div class="contact_section">
        <div class="main_contact">
          <div class="contact_left">
            <p>
              To contact us with your questions and suggestions please reach out
              to us at the given contact details and we will direct them to the
              right person within our team. Thank you.
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1365_5475)">
                  <path
                    d="M18.7719 14.125C18.7206 14.0844 15 11.4025 13.9787 11.595C13.4912 11.6812 13.2125 12.0138 12.6531 12.6794C12.5631 12.7869 12.3469 13.0444 12.1788 13.2275C11.8252 13.1122 11.4804 12.9718 11.1469 12.8075C9.42533 11.9694 8.03437 10.5784 7.19625 8.85687C7.03179 8.52339 6.89143 8.17855 6.77625 7.825C6.96 7.65625 7.2175 7.44 7.3275 7.3475C7.99 6.79125 8.32312 6.5125 8.40938 6.02375C8.58625 5.01125 5.90625 1.265 5.87812 1.23125C5.75653 1.05754 5.59784 0.913039 5.41355 0.808189C5.22925 0.70334 5.02395 0.640768 4.8125 0.625C3.72625 0.625 0.625 4.64813 0.625 5.32562C0.625 5.365 0.681875 9.3675 5.6175 14.3881C10.6331 19.3181 14.635 19.375 14.6744 19.375C15.3525 19.375 19.375 16.2737 19.375 15.1875C19.3594 14.9768 19.2972 14.7722 19.1929 14.5884C19.0886 14.4047 18.9448 14.2464 18.7719 14.125Z"
                    fill="#221F1F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1365_5475">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              (051) 4865856
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.511 0H6.48828C5.10192 0 3.98828 1.11364 3.98828 2.5V17.5C3.98828 18.8864 5.12464 20 6.48828 20H13.511C14.8974 20 16.011 18.8864 16.011 17.5V2.5C16.011 1.11364 14.8974 0 13.511 0ZM10.011 18.3864C9.37464 18.3864 8.87465 17.8864 8.87465 17.25C8.87465 16.6136 9.37464 16.1136 10.011 16.1136C10.6474 16.1136 11.1474 16.6136 11.1474 17.25C11.1474 17.8864 10.6474 18.3864 10.011 18.3864ZM14.8746 14.9091H5.12464V3.34091H14.8746V14.9091Z"
                  fill="black"
                />
              </svg>
              +92 335 1118477
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8234 3.97412L13.7578 10.0006L19.8234 16.0272C19.933 15.798 19.9996 15.5446 19.9996 15.2741V4.72721C19.9996 4.45662 19.933 4.2033 19.8234 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M18.2414 2.96875H1.75699C1.48641 2.96875 1.23309 3.03527 1.00391 3.14492L8.75637 10.8583C9.44184 11.5438 10.5565 11.5438 11.242 10.8583L18.9945 3.14492C18.7653 3.03527 18.512 2.96875 18.2414 2.96875Z"
                  fill="#221F1F"
                />
                <path
                  d="M0.176172 3.97412C0.0665234 4.2033 0 4.45662 0 4.72721V15.2741C0 15.5447 0.0665234 15.798 0.176172 16.0272L6.24176 10.0006L0.176172 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M12.9289 10.8286L12.0705 11.6869C10.9284 12.8291 9.06992 12.8291 7.92777 11.6869L7.06949 10.8286L1.00391 16.8551C1.23309 16.9648 1.48641 17.0313 1.75699 17.0313H18.2414C18.512 17.0313 18.7653 16.9648 18.9945 16.8551L12.9289 10.8286Z"
                  fill="#221F1F"
                />
              </svg>
              info@pakistansweethome.org.pk
            </p>
            <p class="hecText">
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0.625C8.17664 0.625 6.42795 1.34933 5.13864 2.63864C3.84933 3.92795 3.125 5.67664 3.125 7.5C3.125 11.1313 9.25 18.8125 9.5125 19.1437C9.57106 19.2167 9.64527 19.2757 9.72965 19.3161C9.81402 19.3566 9.90641 19.3776 10 19.3776C10.0936 19.3776 10.186 19.3566 10.2704 19.3161C10.3547 19.2757 10.4289 19.2167 10.4875 19.1437C10.75 18.8125 16.875 11.1313 16.875 7.5C16.875 5.67664 16.1507 3.92795 14.8614 2.63864C13.572 1.34933 11.8234 0.625 10 0.625ZM10 9.375C9.50555 9.375 9.0222 9.22838 8.61107 8.95367C8.19995 8.67897 7.87952 8.28852 7.6903 7.83171C7.50108 7.37489 7.45157 6.87223 7.54804 6.38727C7.6445 5.90232 7.8826 5.45686 8.23223 5.10723C8.58186 4.7576 9.02732 4.5195 9.51227 4.42304C9.99723 4.32657 10.4999 4.37608 10.9567 4.5653C11.4135 4.75452 11.804 5.07495 12.0787 5.48607C12.3534 5.8972 12.5 6.38055 12.5 6.875C12.5 7.53804 12.2366 8.17393 11.7678 8.64277C11.2989 9.11161 10.663 9.375 10 9.375Z"
                  fill="#221F1F"
                />
              </svg>
              HEC building، Street 4, H 9/4 , Islamabad, 44000
            </p>
          </div>
          <div class="contact_right">
            <img class="desk-img" src="assets/images/contact-us.png" alt="" />
            <img class="mob-img" src="assets/images/mob_contact.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="activeProjectType == 'upcoming'">
      <div class="contact_section">
        <div class="main_contact">
          <div class="contact_left">
            <p>
              To contact us with your questions and suggestions please reach out
              to us at the given contact details and we will direct them to the
              right person within our team. Thank you.
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1365_5475)">
                  <path
                    d="M18.7719 14.125C18.7206 14.0844 15 11.4025 13.9787 11.595C13.4912 11.6812 13.2125 12.0138 12.6531 12.6794C12.5631 12.7869 12.3469 13.0444 12.1788 13.2275C11.8252 13.1122 11.4804 12.9718 11.1469 12.8075C9.42533 11.9694 8.03437 10.5784 7.19625 8.85687C7.03179 8.52339 6.89143 8.17855 6.77625 7.825C6.96 7.65625 7.2175 7.44 7.3275 7.3475C7.99 6.79125 8.32312 6.5125 8.40938 6.02375C8.58625 5.01125 5.90625 1.265 5.87812 1.23125C5.75653 1.05754 5.59784 0.913039 5.41355 0.808189C5.22925 0.70334 5.02395 0.640768 4.8125 0.625C3.72625 0.625 0.625 4.64813 0.625 5.32562C0.625 5.365 0.681875 9.3675 5.6175 14.3881C10.6331 19.3181 14.635 19.375 14.6744 19.375C15.3525 19.375 19.375 16.2737 19.375 15.1875C19.3594 14.9768 19.2972 14.7722 19.1929 14.5884C19.0886 14.4047 18.9448 14.2464 18.7719 14.125Z"
                    fill="#221F1F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1365_5475">
                    <rect width="20" height="20" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              (051) 4865856
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.511 0H6.48828C5.10192 0 3.98828 1.11364 3.98828 2.5V17.5C3.98828 18.8864 5.12464 20 6.48828 20H13.511C14.8974 20 16.011 18.8864 16.011 17.5V2.5C16.011 1.11364 14.8974 0 13.511 0ZM10.011 18.3864C9.37464 18.3864 8.87465 17.8864 8.87465 17.25C8.87465 16.6136 9.37464 16.1136 10.011 16.1136C10.6474 16.1136 11.1474 16.6136 11.1474 17.25C11.1474 17.8864 10.6474 18.3864 10.011 18.3864ZM14.8746 14.9091H5.12464V3.34091H14.8746V14.9091Z"
                  fill="black"
                />
              </svg>
              +92 335 1118477
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19.8234 3.97412L13.7578 10.0006L19.8234 16.0272C19.933 15.798 19.9996 15.5446 19.9996 15.2741V4.72721C19.9996 4.45662 19.933 4.2033 19.8234 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M18.2414 2.96875H1.75699C1.48641 2.96875 1.23309 3.03527 1.00391 3.14492L8.75637 10.8583C9.44184 11.5438 10.5565 11.5438 11.242 10.8583L18.9945 3.14492C18.7653 3.03527 18.512 2.96875 18.2414 2.96875Z"
                  fill="#221F1F"
                />
                <path
                  d="M0.176172 3.97412C0.0665234 4.2033 0 4.45662 0 4.72721V15.2741C0 15.5447 0.0665234 15.798 0.176172 16.0272L6.24176 10.0006L0.176172 3.97412Z"
                  fill="#221F1F"
                />
                <path
                  d="M12.9289 10.8286L12.0705 11.6869C10.9284 12.8291 9.06992 12.8291 7.92777 11.6869L7.06949 10.8286L1.00391 16.8551C1.23309 16.9648 1.48641 17.0313 1.75699 17.0313H18.2414C18.512 17.0313 18.7653 16.9648 18.9945 16.8551L12.9289 10.8286Z"
                  fill="#221F1F"
                />
              </svg>
              info@pakistansweethome.org.pk
            </p>
            <p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10 0.625C8.17664 0.625 6.42795 1.34933 5.13864 2.63864C3.84933 3.92795 3.125 5.67664 3.125 7.5C3.125 11.1313 9.25 18.8125 9.5125 19.1437C9.57106 19.2167 9.64527 19.2757 9.72965 19.3161C9.81402 19.3566 9.90641 19.3776 10 19.3776C10.0936 19.3776 10.186 19.3566 10.2704 19.3161C10.3547 19.2757 10.4289 19.2167 10.4875 19.1437C10.75 18.8125 16.875 11.1313 16.875 7.5C16.875 5.67664 16.1507 3.92795 14.8614 2.63864C13.572 1.34933 11.8234 0.625 10 0.625ZM10 9.375C9.50555 9.375 9.0222 9.22838 8.61107 8.95367C8.19995 8.67897 7.87952 8.28852 7.6903 7.83171C7.50108 7.37489 7.45157 6.87223 7.54804 6.38727C7.6445 5.90232 7.8826 5.45686 8.23223 5.10723C8.58186 4.7576 9.02732 4.5195 9.51227 4.42304C9.99723 4.32657 10.4999 4.37608 10.9567 4.5653C11.4135 4.75452 11.804 5.07495 12.0787 5.48607C12.3534 5.8972 12.5 6.38055 12.5 6.875C12.5 7.53804 12.2366 8.17393 11.7678 8.64277C11.2989 9.11161 10.663 9.375 10 9.375Z"
                  fill="#221F1F"
                />
              </svg>
              HEC building، Street 4, H 9/4 , Islamabad, 44000
            </p>
          </div>
          <div class="contact_right">
            <img class="desk-img" src="assets/images/contact-us.png" alt="" />
            <img class="mob-img" src="assets/images/mob_contact.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
