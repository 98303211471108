<section class="addSponsorshipSec">
  <div class="container">
    <h1>Follow the steps below to {{ pageType == 'sponsorship' ? 'sponsor a child' : 'become a member' }}</h1>
    <div class="stepsStatus leftSide">
      <div class="singleStep" [ngClass]="activeStep == 'your-sponsorships' ? 'active' : 'done' ">
        <p class="stepNum unfinished">1</p>
        <p class="stepNum finished">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.1577 3.54059C17.788 3.21727 17.2251 3.2538 16.9009 3.62431L7.41702 14.4629L3.07943 9.83588C2.74276 9.47782 2.17984 9.46001 1.82089 9.79579C1.46194 10.1316 1.44324 10.6963 1.77991 11.0552L6.78995 16.3993C6.95918 16.5783 7.19342 16.6798 7.44016 16.6798H7.4553C7.70735 16.6762 7.94427 16.5658 8.11082 16.3761L18.2423 4.79734C18.5656 4.42682 18.5282 3.8648 18.1577 3.54059Z"
              fill="white" stroke="white" />
          </svg>
        </p>
        <p class="stepName"> {{ pageType == 'sponsorship' ? 'Select a child' : 'Membership Details' }} </p>
      </div>
      <div class="singleStep middle"
        [ngClass]="activeStep == 'contact-details' ? 'active' : activeStep == 'make-payment' ? 'done' : '' ">
        <p class="stepNum unfinished">2</p>
        <p class="stepNum finished">
          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.1577 3.54059C17.788 3.21727 17.2251 3.2538 16.9009 3.62431L7.41702 14.4629L3.07943 9.83588C2.74276 9.47782 2.17984 9.46001 1.82089 9.79579C1.46194 10.1316 1.44324 10.6963 1.77991 11.0552L6.78995 16.3993C6.95918 16.5783 7.19342 16.6798 7.44016 16.6798H7.4553C7.70735 16.6762 7.94427 16.5658 8.11082 16.3761L18.2423 4.79734C18.5656 4.42682 18.5282 3.8648 18.1577 3.54059Z"
              fill="white" stroke="white" />
          </svg>
        </p>
        <p class="stepName">Create account </p>
      </div>
      <div class="singleStep" [ngClass]="activeStep == 'make-payment' ? 'active' : '' ">
        <p class="stepNum">3</p>
        <p class="stepName">Make payment</p>
      </div>
    </div>
    <div class="sponsorshipFormWrapper">
      <div class="stepsWrapper">
        <app-your-sponsorships [currencyCode]="currencyCode" [exchangeRate]="exchangeRate" (sponsorshipChangeEvent)="updateSponsorshipDetails($event)"
          *ngIf="activeStep == 'your-sponsorships' " (stepChangeEvent)="changeStep($event)" [oldSponsorships]="sponsorshipDetail.pricePlan" [pageType]="pageType" ></app-your-sponsorships>
        <app-sponsor-contact-details *ngIf="activeStep == 'contact-details'"
          (contactDetailsChangeEvent)="updateContactDetails($event)" (stepChangeEvent)="changeStep($event)"
          [contactDetails]="sponsorshipDetail.contactDetails" [sponsorships]="sponsorshipDetail.pricePlan" >
        </app-sponsor-contact-details>        
      </div>
      <div class="summaryWrapper">
        <app-sponsorship-summary [pageType]="pageType" [currencyCode]="currencyCode" [exchangeRate]="exchangeRate" [pricePlan]="sponsorshipDetail?.pricePlan" [totalPrice]="sponsorshipDetail.total"
          [activeStep]="activeStep" (stepChangeEvent)="changeStep($event)"></app-sponsorship-summary>
      </div>
    </div>
  </div>
</section>

<ng-container *ngIf="showModal">
  <app-sign-up *ngIf="modalStep == 'verify-otp'" [modalStep]="modalStep" [modalView]="true" [signupEmail]="signupEmail"
    (stepChangeEvent)="changeModalStep($event)" (modalShowEvent)="changeStep($event)"></app-sign-up>
  <div class="signupModals" *ngIf="modalStep != 'verify-otp'">
    <div class="overlay" (click)="hideModals()"></div>
    <div class="fixedFormWrapper">
      <button class="modalCloseIcon" (click)="hideModals()">
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_5253_51)">
            <path
              d="M11.0013 0.917969C9.00701 0.917969 7.0575 1.50935 5.39931 2.61732C3.74111 3.72529 2.4487 5.30009 1.68552 7.14258C0.922338 8.98507 0.722654 11.0125 1.11172 12.9685C1.50079 14.9244 2.46113 16.7211 3.87131 18.1313C5.28149 19.5415 7.07817 20.5018 9.03415 20.8909C10.9901 21.28 13.0175 21.0803 14.86 20.3171C16.7025 19.5539 18.2773 18.2615 19.3853 16.6033C20.4933 14.9451 21.0846 12.9956 21.0846 11.0013C21.0815 8.328 20.0181 5.7651 18.1278 3.87479C16.2375 1.98448 13.6746 0.921123 11.0013 0.917969ZM14.8898 13.5946C14.9774 13.6791 15.0472 13.7803 15.0952 13.8921C15.1433 14.0039 15.1686 14.1242 15.1696 14.2459C15.1707 14.3676 15.1475 14.4884 15.1014 14.601C15.0553 14.7137 14.9872 14.816 14.9012 14.9021C14.8151 14.9882 14.7128 15.0562 14.6001 15.1023C14.4874 15.1484 14.3667 15.1716 14.245 15.1705C14.1233 15.1695 14.003 15.1442 13.8912 15.0961C13.7793 15.0481 13.6782 14.9783 13.5936 14.8907L11.0013 12.2975L8.40897 14.8907C8.23609 15.0577 8.00454 15.1501 7.76419 15.148C7.52384 15.1459 7.29393 15.0495 7.12397 14.8796C6.95402 14.7096 6.85761 14.4797 6.85552 14.2393C6.85343 13.999 6.94583 13.7674 7.11281 13.5946L9.70514 11.0013L7.11281 8.40805C7.02526 8.32349 6.95542 8.22234 6.90738 8.11051C6.85934 7.99867 6.83405 7.87839 6.83299 7.75667C6.83194 7.63496 6.85513 7.51425 6.90122 7.40159C6.94731 7.28894 7.01538 7.18659 7.10144 7.10052C7.18751 7.01446 7.28986 6.94639 7.40252 6.9003C7.51517 6.85421 7.63588 6.83102 7.75759 6.83207C7.87931 6.83313 7.99959 6.85842 8.11143 6.90646C8.22326 6.9545 8.32441 7.02433 8.40897 7.11189L11.0013 9.70514L13.5936 7.11189C13.6782 7.02433 13.7793 6.9545 13.8912 6.90646C14.003 6.85842 14.1233 6.83313 14.245 6.83207C14.3667 6.83102 14.4874 6.85421 14.6001 6.9003C14.7128 6.94639 14.8151 7.01446 14.9012 7.10052C14.9872 7.18659 15.0553 7.28894 15.1014 7.40159C15.1475 7.51425 15.1707 7.63496 15.1696 7.75667C15.1686 7.87839 15.1433 7.99867 15.0952 8.11051C15.0472 8.22234 14.9774 8.32349 14.8898 8.40805L12.2975 11.0013L14.8898 13.5946Z"
              fill="#B3D1DF" />
          </g>
          <defs>
            <clipPath id="clip0_5253_51">
              <rect width="22" height="22" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
      <app-sign-up *ngIf="modalStep == 'signup'" [modalStep]="modalStep" [modalView]="true"
        (stepChangeEvent)="changeModalStep($event)" (emailVerifyEvent)="changeSignupEmail($event)"></app-sign-up>
      <app-sign-in *ngIf="modalStep == 'login'" [modalView]="true" (stepChangeEvent)="changeModalStep($event)"
        (modalShowEvent)="changeStep($event)"></app-sign-in>
    </div>
  </div>
</ng-container>
