import { Component, ViewChild, Renderer2, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FilePondOptions } from 'filepond';
import {
  AppSettings,
  AuthenticationService,
  HelperService,
  CurrencyApiService,
} from 'src/app/services/_services';
import AOS from 'aos';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  @ViewChild('myPond') myPond: any;
  title = 'pakistanSweetHomes';
  public routerSubscription: any;
  public showHeadFoot: any = true;
  public id: any;
  public currencyRates: any;
  public showFoot: any = true;
  public showModal: boolean = false;

  constructor(
    private router: Router,
    private helperService: HelperService,
    private currencyApiService: CurrencyApiService,
    private renderer: Renderer2
  ) {
    AOS.init({ once: 'true' }); //AOS - 2
    AOS.refresh();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        this.showFoot = true;

        if (val.url == '/reset_password') {
          this.showHeadFoot = false;
        } else if (val.url == '/submission') {
          this.showHeadFoot = false;
        } else if (val.url == '/academic-details') {
          this.showHeadFoot = false;
        } else if (val.url == '/personal-information') {
          this.showHeadFoot = false;
        } else if (val.url == '/landing-page-8224396726') {
          this.showHeadFoot = false;
        } else if (val.url?.includes('view-form')) {
          this.showHeadFoot = false;
        } else if (val.url == '/add-sponsorship') {
          this.showFoot = false;
        } else if (val.url == '/thankyou-sponsor') {
          this.showHeadFoot = false;
        } else if (val.url == '/dashboard') {
          this.showFoot = false;
        } else if (val.url == '/dashboard/settings') {
          this.showFoot = false;
        } else if (val.url == '/inquiry') {
          this.showFoot = false;
        } else {
          this.showHeadFoot = true;
        }
      }
    });

    // this.getCurrencyRates();
    this.currencyApiService.getCurrencyRates();
  }

  ngOnInit() {
    const script = this.renderer.createElement('script');
    const muharramModalShowed = localStorage.getItem('muharramModalShowed');

    if (!muharramModalShowed) {
      this.showModal = true;
    } else if (muharramModalShowed == '2' || muharramModalShowed == '1') {
      this.showModal = false;
    }

    script.src = 'https://js.stripe.com/v3/';
    this.renderer.appendChild(document.body, script);
  }

  pondOptions: FilePondOptions = {
    allowMultiple: true,
    labelIdle: 'Drop files here...',
  };

  pondFiles: FilePondOptions['files'] = [
    {
      source: 'assets/photo.jpeg',
      options: {
        type: 'local',
      },
    },
  ];
  pondHandleInit() {
    console.log('FilePond has initialised', this.myPond);
  }

  pondHandleAddFile(event: any) {
    console.log('A file was added', event);
  }

  pondHandleActivateFile(event: any) {
    console.log('A file was activated', event);
  }
}
