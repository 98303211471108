import { Component, OnInit } from '@angular/core';
import {
  AppSettings,
  SEOService,
  HelperService,
  AuthenticationService,
} from '../../services/_services';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  public routerSubscription: any;
  public nameField: any = '';
  public emailField: any = '';
  public pageResp: any = false;
  public successfullyDone: any = false;
  public snlForm: FormGroup;
  public submitted: any = false;
  public footerItem: any = true;
  public emailError: any = '';
  public emailSuccess: any = '';
  public loading: any = false;
  public currentPage;

  public hideSubInputList: any = [
    '/cadet-college-admission',
    '/admission/login',
    '/admission/signup',
    '/admission/login',
    '/admission/fill-form',
    '/admission/student-application',
    '/admission/student-dashboard',
    '/cadet-college-admission-8th-class',
    '/cadet-college-admission-11th-class',
    '/scholarship-program',
  ];
  public hideSubInput: any = false;

  constructor(
    private helperService: HelperService,
    private formBuilder: FormBuilder,
    private authenticationService: AuthenticationService,
    private router: Router
  ) {
    let user = this.authenticationService.get_currentUserValue();
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        console.log('footer val.url', val.url);
        this.currentPage = val.url;
        if (val.url == '/NLP-LFVD3H0xvDn7cryQOIaLpEAv0yHlWPSJH4AxzCfY') {
          this.footerItem = false;
        } else {
          this.footerItem = true;
        }

        if (this.hideSubInputList.includes(val.url)) {
          this.hideSubInput = true;
        } else if (val.url.includes('admission/review-information')) {
          this.hideSubInput = true;
        } else if (val.url == '/add-membership') {
          this.hideSubInput = true;
        } else {
          this.hideSubInput = false;
        }
      }
    });
    this.snlForm = this.formBuilder.group({
      name: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {}

  public get f() {
    return this.snlForm.controls;
  }

  subscribeNewsLetter() {
    if (this.validateEmail() && this.nameField) {
      this.loading = true;
      let url = AppSettings.API_ENDPOINT + 'user-site-login.php';

      let data = new FormData();
      data.append('action', 'subscribe_to_newsletter');
      data.append('name', this.nameField);
      data.append('email', this.emailField);

      this.helperService
        .httpPostRequests(url, data)
        .then((resp) => {
          console.log(resp);

          if (resp.status == 'OK') {
            this.emailSuccess = resp.message;
            setTimeout(() => {
              this.emailSuccess = '';
            }, 3000);
          } else {
            this.emailError = resp.message;
            setTimeout(() => {
              this.emailError = '';
            }, 3000);
          }

          this.nameField = '';
          this.emailField = '';
          this.loading = false;
        })
        .catch((error) => {
          console.error('error: ', error);
          this.loading = false;
        });
    } else {
      if (!this.nameField) {
        this.emailError = 'Enter valid Name';
      }
      setTimeout(() => {
        this.emailError = '';
      }, 3000);
    }
  }

  validateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
      return true;
    } else {
      this.emailError = 'Enter valid Email!';
      return false;
    }
  }
}
