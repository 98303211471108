<section class="applyNowSec" [ngClass]="activeStep == 'fill-form' ? 'formSec' : (reviewPage == 'view' ? 'h100' : '') ">
    <div class="container">
        <h1 style="display: none;">Get Started</h1>
        <div class="allWrapUp">
            <div class="stepsWrapper" *ngIf="reviewPage != 'view'">        
                <div class="singleStep" [ngClass]="activeStep == 'signup' || activeStep == 'login' ? 'ongoing' : activeStep == 'fill-form' || activeStep == 'review-information' ? 'completed' : ''  ">
                    <p class="stepNumber">1</p>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="20" cy="20" r="20" fill="#003366"/>
                        <path d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z" fill="white" stroke="white"/>
                    </svg>                    
                    <p class="stepName">Create Account</p>
                </div>
    
                <svg *ngIf="activeStep == 'signup'" width="55" height="2" viewBox="0 0 55 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="8.74228e-08" y1="1" x2="55" y2="1" stroke="#B0B0B0" stroke-width="2" stroke-dasharray="4 4"/>
                </svg>                
                <svg *ngIf="activeStep != 'signup'" width="55" height="4" viewBox="0 0 55 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="55" height="4" fill="#003366"/>
                </svg>
                    
                    
    
                <div class="singleStep" [ngClass]="activeStep == 'fill-form' ? 'ongoing' : activeStep == 'review-information' ? 'completed' : ''    ">
                    <p class="stepNumber">2</p>
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle
                         cx="20" cy="20" r="20" fill="#003366"/>
                        <path d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z" fill="white" stroke="white"/>
                    </svg>      
                    <p class="stepName">Fill Form</p>
                </div>
                
                <svg *ngIf="activeStep != 'review-information'" width="55" height="2" viewBox="0 0 55 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <line x1="8.74228e-08" y1="1" x2="55" y2="1" stroke="#B0B0B0" stroke-width="2" stroke-dasharray="4 4"/>
                </svg>                
                <svg *ngIf="activeStep == 'review-information'" width="55" height="4" viewBox="0 0 55 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="55" height="4" fill="#003366"/>
                </svg>
                    
                
    
                <div class="singleStep" [ngClass]="activeStep == 'review-information' ? 'ongoing' : ''  ">
                    <p class="stepNumber">3</p>
                    <p class="stepName">Review & Submit</p>
                </div>
    
            </div>
    
            <app-admission-signup *ngIf="activeStep == 'signup'"></app-admission-signup>
            <app-admission-details *ngIf="activeStep == 'fill-form'"></app-admission-details>
            <app-review-information [reviewPage]="reviewPage" *ngIf="activeStep == 'review-information'"></app-review-information>
            <app-admission-login *ngIf="activeStep == 'login'"></app-admission-login>
            <app-admission-forgot-password *ngIf="activeStep == 'forgot-password'"></app-admission-forgot-password>
        </div>

    </div>
</section>