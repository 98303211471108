import {
  Component,
  OnInit,
  AfterViewInit,
  OnDestroy
} from '@angular/core';
import {
  ActivatedRoute, Router
} from '@angular/router';
import {
  AppSettings,
  HelperService,
  SEOService
} from 'src/app/services/_services';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

declare var $, Swiper;

@Component({
  selector: 'app-sponsor-child-profile',
  templateUrl: './sponsor-child-profile.component.html',
  styleUrls: ['./sponsor-child-profile.component.css']
})
export class SponsorChildProfileComponent implements OnInit, AfterViewInit, OnDestroy {

  public orphan;
  public orphanId;
  public loading = true;
  public orphanVideoUrl;
  public orphanStory;
  public moreChildren;
  public isMobile = false;
  public disabledBtn = false;
  public orphanType: any;

  constructor(
    private route: ActivatedRoute,
    private helperService: HelperService,
    private sanitizer: DomSanitizer,
    private router : Router,
    private seoService: SEOService,
  ) {
  }

  ngOnDestroy(): void {
    $('.Pshfooter').removeClass('extraBotPad');
    setTimeout(() => {
      $('#fb-root').show();
    }, 1000);
  }

  ngOnInit(): void {

    $('body').addClass('overflowUnset');

    if (window.innerWidth < 576) {
      this.isMobile = true;
    }

    this.route.params.subscribe(params => {
      this.orphanId = params['id'];
      this.orphan = '';
      this.moreChildren = '';
      this.isMobile ? $('.profileRight').scrollTop(0) : '';
      this.getOrphan();
    });

    $(document).ready(function () {
      $('.Pshfooter').addClass('extraBotPad');
      $('.outer-card .outer-card-body:first-child').show();
      $('.outer-card-head').click(function () {
        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".outer-card-body").slideUp("fast");
        } else {
          // $(".outer-card .outer-card-body").slideUp("fast");
          // $(".outer-card").removeClass("show");
          $(this).parent().addClass("show").find(".outer-card-body").slideDown("fast");
        }
      });

    });

  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      $('#fb-root').hide();
    }, 1000);
    var swiper = new Swiper(".imgVideoSwiper", {
      slidesPerView: 1,
      spaceBetween: 20,
      pagination: {
        el: ".childVisuals-pagination",
      },
      navigation: {
        nextEl: ".video-next",
        prevEl: ".video-prev",
      },
    });

    setTimeout(() => {
      $('.moreChildren-card .outer-card-head').click();
    }, 1000);

  }

  getOrphan() {
    let url = AppSettings.BACK_ENDPOINT + 'get-orphan-by-id/' + this.orphanId;
    let data = new FormData();
    this.helperService.httpPostRequests(url,data).then(resp => {
      this.orphan = resp.orphan;
      this.orphanVideoUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.orphan.child_video + '?rel=0&controls=0');
      this.orphanStory = this.orphan.story_of_child.split(/\n/g);
      this.moreChildren = resp.sliders;
      this.orphanType = resp.orphan.sponsorship_type;
      console.log("this.orphanType",this.orphanType);

      this.loading = false;
      this.addWatermark();

      this.setSEO_Tags('Sponsor ' + this.orphan.name + ' | Pakistan Sweet Home Child Sponsorship', 'The campus is named after the famous Pakistani hero Major Muhammad Aziz Bhatti Nishan-e-Haider. This campus has been dedicated to the boys of 6th - 8th class.');

    }).catch(error => {
      console.error("error: ");
    });

  }

  dobToAge(dob) {
    var today = new Date();
    var birthDate = new Date(dob);

    var yearsDiff = today.getFullYear() - birthDate.getFullYear();
    var monthsDiff = today.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }

    var yearsString = yearsDiff == 0 ? '' : yearsDiff == 1 ? yearsDiff + ' Year ' : yearsDiff + ' Years ';
    var monthsString = monthsDiff == 0 ? '' : monthsDiff == 1 ? monthsDiff + ' Month' : monthsDiff + ' Months';

    return yearsString + monthsString

  }

  lastSponsoredDate(sponsoredDate, isSponsored) {
    var today = new Date();
    var startDate = new Date(sponsoredDate);
    var monthsDiff = (today.getFullYear() - startDate.getFullYear()) * 12 + (today.getMonth() - startDate.getMonth());

    monthsDiff = startDate.getDate() > today.getDate() ? monthsDiff - 1 : monthsDiff;

    var monthString = monthsDiff == 1 ? ' Month' : ' Months';
    var waitingString = isSponsored == '0' ? 'Waiting ' : 'Sponsored ';
    return waitingString + 'since ' + monthsDiff + monthString;
  }
  addWatermark() {
    setTimeout(() => {
      var pshLogo = $('.watermarkImg')[0];
      var i = 0;
      $('.galleryCanvas').each(function () {
        var canvas = $(this)[0];
        var image = $(this).siblings('img')[0];

        // console.log('image.width::',image.naturalWidth)

        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        var ctx = canvas.getContext('2d');

        var canvasWidth = canvas.width;
        var canvasHeight = canvas.height;
        var pshLogoWidth = pshLogo.width;
        var pshLogoHeight = pshLogo.height;
        var offsetX = 0;
        var offsetY = 0;

        ctx.drawImage(image, 0, 0);
        for (var x = 0; x < canvasWidth; x += pshLogoWidth) {
          for (var y = offsetY; y < canvasHeight; y += pshLogoHeight) {
            ctx.drawImage(pshLogo, x + offsetX, y);
          }
        }
        // ctx.drawImage(pshLogo, 0, 100);


      })

    }, 1500);
  }

  copyUrl() {
    var currentUrl = window.location.href;
    navigator.clipboard.writeText(currentUrl);
    $('.copyToolTip').addClass('show');
    $('.copyToolTip').text('Copied!');
    setTimeout(() => {
      $('.copyToolTip').removeClass('show');
      $('.copyToolTip').text('Copy Link');
    }, 3000);
  }

  saveOrphan(orphan, moreChildren){
    this.disabledBtn = true;
    localStorage.setItem('orphan', JSON.stringify(orphan));
    localStorage.setItem('moreChildren', JSON.stringify(moreChildren));

    setTimeout(() => {
      this.router.navigate(['/add-sponsorship']);
    }, 1000);
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }

}
