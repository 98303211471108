import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'exchangeRate'
})
export class ExchangeRatePipe implements PipeTransform {

  transform(value: number,exchangeRate: number, ...args: unknown[]): unknown {    
    // console.log('value::', value)
    // console.log('exchangeRate::', exchangeRate)
    return Math.ceil(value * exchangeRate);
  }

}
