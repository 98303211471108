import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-sponsor-page-faq',
  templateUrl: './sponsor-page-faq.component.html',
  styleUrls: ['./sponsor-page-faq.component.css']
})
export class SponsorPageFaqComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.card .card-body:first-child').show();
      $('.card-head').click(function () {

        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).parent().addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });
  }

}
