<section class="banner_section">
  <div class="bg_video">
    <video class="dsktpOnly" width="100%" height="100%" loop autoplay>
      <source src="assets/videos/donate-qurbani.mp4" type="video/mp4">
    </video>
    <video class="mobOnly" width="100%" height="100%" loop>
      <source src="assets/videos/donate-qurbani.mp4" type="video/mp4">
    </video>
    <div class="bg-overlay"></div>
  </div>
  <div class="container">
    <div class="mainBanner">
      <div class="leftbanner">
        <h1>Qurbani Fi Sabilillah</h1>
        <p>Orphans Deserves a Happy Eid, too.<br> Many around us can only have meat during Eid.<br> This Eid Al-Adha,
          let
          them know they are in our thoughts!</p>

      </div>
      <app-payment-form></app-payment-form>
      <!-- <app-payment-form-checkout></app-payment-form-checkout> -->
    </div>
  </div>
</section>

<section class="support_provided">
  <div class="container">
    <div class="support_head">
      <p class="qoutedText">&ldquo;Their meat will not reach Allah, nor will their blood, but what reaches
        Him is piety from you.&rdquo;
        <br />
        <span>&mdash; Surah Al-Hajj Ayat 22:37</span>
      </p>
      <h2>Donate your Qurbani to Pakistan Sweet Home</h2>
      <p>Due to our limited capacity and resources, we are only performing Qurbani for small animals.</p>
    </div>
    <div class="main_support">
      <div class="single_support">

        <img src="../../../assets/images/qurbani/Sheep.svg" alt="sheep package" />
        <p class="qurbani_pkg_price">Sheep: <span class="price_qurbani">PKR 60,000/-</span></p>


      </div>
      <div class="single_support">


        <img src="../../../assets/images/qurbani/Goat.svg" alt="goat package" />

        <p class="qurbani_pkg_price">Goat: <span class="price_qurbani">PKR 60,000/-</span></p>

      </div>
      <div class="support_head">
        <p>The Qurbani will be performed on all three days of Eid at the Sweet Home Islamabad headquarters under our
          direct
          supervision.</p>
      </div>
    </div>
  </div>

</section>

<section class="support_provided">
  <div class="container">
    <div class="support_head">
      <h2>Impact your Qurbani can Make on an Orphan’s life</h2>
      <p>With your support, we can:</p>
    </div>
    <div class="main_support">
      <div class="single_support">
        <img src="../../../assets/images/qurbani/MeatDistribution.svg" alt="Meat Distribution" />
        <p class="service_name">Distribute Meat</p>
        <p class="service_des">We provide both cooked and uncooked meat to orphans, their families, and other deserving
          community members, ensuring they
          have meals during Eid and beyond.
        </p>

      </div>
      <div class="single_support">
        <img src="../../../assets/images/qurbani/LearningAboutSacrifice.svg" alt="earning About Sacrifice" />

        <p class="service_name">Educate About Sacrifice</p>
        <p class="service_des">Your Qurbani donations help educate our children about the significance and values of
          sacrifice, giving them a deeper
          understanding of this religious practice.</p>

      </div>
      <div class="single_support">
        <img src="../../../assets/images/qurbani/UtilizingAnimalSkins.svg" alt="Utilizing Animal Skins" />

        <p class="service_name">Utilize Animal Skins</p>
        <p class="service_des">Animal skins from Qurbani are used to make clothes and accessories for our orphaned
          children, providing them with
          functional and meaningful items.</p>

      </div>
      <div class="single_support">
        <img src="../../../assets/images/qurbani/EidJoyforAll.svg" alt="Eid Joy for All" />

        <p class="service_name">Spread Eid Joys</p>
        <p class="service_des">Your support ensures that every orphan in our care, their families, and other deserving
          people can share in the joy and
          blessings of Eid.</p>
      </div>
    </div>
  </div>

</section>
<!--
<section class="howToSec">
  <div class="container">
    <h2>Another Way to Donate</h2>
    <p class="howToDesc">You can also send your Qurbani Donations directly into our bank account using the following
      details:</p>
    <div class="detailsWrapper">
      <div class="singleDetail">
        <img src="assets/new-images/meezan-bank.svg" alt="Meezan Bank">
        <div>
          <p><span>Bank:</span> Meezan Bank</p>
          <p><span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies Place</p>
          <p><span>Account Number:</span> 08220102632194</p>
          <p><span>IBAN:</span> PK98 MEZN 0008 2201 0263 2194</p>
          <p><span>Branch Code:</span> 0822</p>
          <p><span>Swift Code:</span> MEZNPKKAGRD</p>
        </div>
      </div>

    </div>
  </div>
</section> -->
<section class="howToSec">
  <div class="container">
    <h2>Another Way to Donate</h2>
    <p class="howToDesc">You can also send your Qurbani Donations directly into our bank account using the following
      details:</p>
    <div class="detailsWrapper">

      <div class="singleDetail">
        <img class="meezanBnk" src="assets/new-images/meezan-bank.svg" alt="Meezan Bank">
        <p><span>Bank:</span> Meezan Bank</p>
        <p><span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies Place</p>
        <p><span>Account Number:</span> 08220102632194</p>
        <p><span>IBAN:</span> PK98 MEZN 0008 2201 0263 2194</p>
        <p><span>Branch Code:</span> 0822</p>
        <p><span>Swift Code:</span> MEZNPKKAGRD</p>
      </div>
      <div class="middle-line-content">
        <span>OR</span>
      </div>
      <div class="singleDetail flex-grow">
        <img class="jazzcashLogo" src="assets/images/jazzcash-donation/jazzcash-logo.webp" alt="Jazz Cash Logo">
        <p><span>Till ID:</span></p>
        <div class="scan-numbers-id">
          <div class="scan-digits-row">
            <div class="digits-cols">
              <div class="digit-box">
                <span>9</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>3</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
              <div class="digit-box">
                <span>4</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>6</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>1</span>
              </div>
            </div>
          </div>
        </div>
        <div class="qrcode-content">
          <img src="assets/images/jazzcash-donation/scan-image.webp" alt="Scan QR Code">
        </div>
        <p class="scanQRTxt"><span>Scan this QR Code</span></p>
        <!-- <p><span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies Place</p>
              <p><span>Account Number:</span> 4149300607</p>
              <p><span>IBAN:</span> PK09 NBPA 1531 0041 4930 0607</p>
              <p><span>Branch Code:</span> 1531</p>
              <p><span>Swift Code:</span> NBPAPKKA02i</p> -->
      </div>
    </div>
  </div>
</section>


<section class="confirmationSec">
  <div class="container">
    <h2>Confirmation</h2>
    <p>After completing the process, please Email or WhatsApp us a copy of the funds transfer receipt issued by your
      bank (email/screenshot) at <strong>donations@pakistansweethome.org.pk</strong> or <strong>+92 335
        1118477</strong>.</p>
  </div>
</section>