<div class="summaryWrapper" [ngClass]="getSummaryWrapperClasses(showSummary, pageType)" >
  <div class="headingWrapper">
    <h2>Summary</h2>
    <button class="mobOnly" (click)="hideSummaryModal()" >
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.0018 4.99985C14.8455 4.84362 14.6336 4.75586 14.4126 4.75586C14.1917 4.75586 13.9797 4.84362 13.8235 4.99985L10.0018 8.82152L6.18013 4.99985C6.02386 4.84362 5.81194 4.75586 5.59097 4.75586C5.37 4.75586 5.15807 4.84362 5.0018 4.99985C4.84558 5.15612 4.75781 5.36804 4.75781 5.58901C4.75781 5.80998 4.84558 6.02191 5.0018 6.17818L8.82347 9.99985L5.0018 13.8215C4.84558 13.9778 4.75781 14.1897 4.75781 14.4107C4.75781 14.6317 4.84558 14.8436 5.0018 14.9998C5.15807 15.1561 5.37 15.2438 5.59097 15.2438C5.81194 15.2438 6.02386 15.1561 6.18013 14.9998L10.0018 11.1782L13.8235 14.9998C13.9797 15.1561 14.1917 15.2438 14.4126 15.2438C14.6336 15.2438 14.8455 15.1561 15.0018 14.9998C15.158 14.8436 15.2458 14.6317 15.2458 14.4107C15.2458 14.1897 15.158 13.9778 15.0018 13.8215L11.1801 9.99985L15.0018 6.17818C15.158 6.02191 15.2458 5.80998 15.2458 5.58901C15.2458 5.36804 15.158 5.15612 15.0018 4.99985Z"
          fill="black" />
      </svg>
    </button>
  </div>
  <div class="pricingWrapper">
    <div class="allPrices">
      <div class="singlePrice" *ngFor="let price of pricePlan">
        <p *ngIf="price.name; else otherPrice"> {{ price.name }} - {{ price.timePeriod }} Sponsorship </p>
        <ng-template #otherPrice>
          <p> {{ price.type }} </p>
        </ng-template>
        <p>  {{ currencyCode }} {{ price.price | exchangeRate : exchangeRate | number }} </p>
      </div>
    </div>
    <div class="totalWrapper">
      <p>Total</p>
      <p> {{ currencyCode }} {{ totalPrice | exchangeRate : exchangeRate | number }} </p>
    </div>
    <div *ngIf="activeStep == 'your-sponsorships' && !disabledBtn" class="showMoreBtnWrapper dsktpOnly">
      <button class="showMoreBtn" (click)="changeStep('contact-details')">proceed to Next Step</button>
      <span></span>
    </div>
    <div class="showMoreBtnWrapper dsktpOnly" *ngIf="activeStep == 'your-sponsorships' && disabledBtn">
      <button class="showMoreBtn loaderBlue" type="button" ><img class="loadingImg" src="/assets/new-images/pshLoaderWhite.webp" alt="loading"></button>        
    </div>
    <!-- <div *ngIf="disabledBtn" class="loader dsktpOnly">
      <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
    </div> -->
  </div>
</div>

<div class="overlay mobOnly" *ngIf="showSummary" (click)="hideSummaryModal()" ></div>

<button class="fixedSummaryBtn mobOnly" (click)="showSummaryModal()" >
  <p class="mobTotalWrapper">
    <span>Total</span>
    <span>{{ currencyCode }} {{ totalPrice | exchangeRate : exchangeRate | number }}</span>
  </p>
  <p class="moveUpIcon">
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_5459_5238)">
      <path d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20039 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63857 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59986 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5Z" fill="black"/>
      <path d="M9 7.5H8.25C8.05109 7.5 7.86032 7.57902 7.71967 7.71967C7.57902 7.86032 7.5 8.05109 7.5 8.25C7.5 8.44891 7.57902 8.63968 7.71967 8.78033C7.86032 8.92098 8.05109 9 8.25 9H9V13.5C9 13.6989 9.07902 13.8897 9.21967 14.0303C9.36032 14.171 9.55109 14.25 9.75 14.25C9.94891 14.25 10.1397 14.171 10.2803 14.0303C10.421 13.8897 10.5 13.6989 10.5 13.5V9C10.5 8.60218 10.342 8.22064 10.0607 7.93934C9.77936 7.65804 9.39782 7.5 9 7.5Z" fill="black"/>
      <path d="M9 6C9.62132 6 10.125 5.49632 10.125 4.875C10.125 4.25368 9.62132 3.75 9 3.75C8.37868 3.75 7.875 4.25368 7.875 4.875C7.875 5.49632 8.37868 6 9 6Z" fill="black"/>
      </g>
      <defs>
      <clipPath id="clip0_5459_5238">
      <rect width="18" height="18" fill="white"/>
      </clipPath>
      </defs>
      </svg>
      
  </p>
</button>
