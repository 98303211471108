import { Component, OnInit,AfterViewInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';
declare var $, Swiper;

@Component({
  selector: 'app-friends-circle',
  templateUrl: './friends-circle.component.html',
  styleUrls: ['../../include/header/header.component.css', '../faq/faq.component.css', './friends-circle.component.css']
})
export class FriendsCircleComponent implements OnInit, AfterViewInit {

  public faqs: any = [{
      'question': 'What benefits do I get as a member of this program?',
      'answer': ['As a member, you become a key part of our mission to provide a nurturing environment for orphaned children. You will receive regular updates on the progress of the children you are helping and be invited to exclusive events and webinars.'],
    },    
    {
      'question': 'How does my membership contribute to helping orphaned children?',
      'answer': ['Your membership helps us fund essential resources for the children, like nutritious food, quality education, healthcare, and more. Your contribution makes a real, tangible difference in their lives.'],
    },    
    {
      'question': 'How often will my membership renew?',
      'answer': ['Your membership will renew automatically every year. We will send you a reminder before the renewal date.'],
    },    
    {
      'question': 'How can I cancel my membership? ',
      'answer': ['You can cancel your membership at any time through your account settings on our website. If you need assistance, please reach out to our support team.'],
    },          
  ]

  public activeTab = '';
  public search = '';

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.card .card-body:first-child').show();    
    });
    this.setSEO_Tags("Join the Special Friends Circle of PSH","Join Pakistan Sweet Home’s Special Friends Club for just $1 a day. Provide essentials to orphaned children and get exclusive membership benefits.")
  }

  ngAfterViewInit(): void {
    var ourWorkSlider = new Swiper(".ourWorkSlider", {
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },      
      breakpoints: {
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },        
        768: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      },
  });
  }

  cardToggler(el){
    if ($(el).parent().hasClass("show")) {
      $(el).parent().removeClass("show").find(".card-body").slideUp("fast");
    } else {
      $(".card .card-body").slideUp("fast");
      $(".card").removeClass("show");
      $(el).parent().addClass("show").find(".card-body").slideDown("fast");

    }
    return false;
  }
  
  scrollBottom(){
      $('html, body').animate({
        scrollTop: $("#change-sec").offset().top
      }, 1000); 
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }

}
