import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-qurbani-modal',
  templateUrl: './qurbani-modal.component.html',
  styleUrls: ['./qurbani-modal.component.css']
})
export class QurbaniModalComponent implements OnInit {

  public hideModal = false

  constructor(
    private router : Router
  ) { }

  ngOnInit(): void {
  }

  hideModalFunc(){
    localStorage.setItem('qurbaniModalShowed', '2')
    this.hideModal = true
  }

  donateQurbani(){
    localStorage.setItem('qurbaniModalShowed', '1')
    this.hideModal = true
    this.router.navigate(['/donate-qurbani'])
  }

}
