<div class="stepWrapper" *ngIf=" pageType == 'sponsorship' " >
  <div class="sponsorshipsWrapper" *ngIf="sponsorships">
    <div class="singleSponsorship" *ngFor="let sponsor of sponsorships; let indx = index">
      <ng-container *ngIf="sponsor.type == 'Child Sponsorship'; else otherDonation ">
        <div class="childDetails">
          <img [src]="sponsor.image" [alt]="sponsor.name + ' image'">
          <div class="childNameWrapper">
            <p class="childName"> {{ sponsor.name }} </p>
            <p class="childType"> <span>{{ sponsor.type }} </span> <span class="sponsorshipTime">{{ sponsor.timePeriod }}</span>  </p>
            <button class="removeBtn" (click)="removePlan(indx)"
              *ngIf="(sponsorships.length - (otherDonationExists ? 1 : 0)) > 1">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5202_5434)">
                  <path
                    d="M6.5 13C2.91596 13 0 10.084 0 6.5C0 2.91596 2.91596 0 6.5 0C10.084 0 13 2.91596 13 6.5C13 10.084 10.084 13 6.5 13ZM6.5 0.8125C3.36385 0.8125 0.8125 3.36385 0.8125 6.5C0.8125 9.63615 3.36385 12.1875 6.5 12.1875C9.63615 12.1875 12.1875 9.63615 12.1875 6.5C12.1875 3.36385 9.63615 0.8125 6.5 0.8125ZM9.75 6.5C9.75 6.27545 9.56831 6.09375 9.34375 6.09375H3.65625C3.4317 6.09375 3.25 6.27545 3.25 6.5C3.25 6.72455 3.4317 6.90625 3.65625 6.90625H9.34375C9.56831 6.90625 9.75 6.72455 9.75 6.5Z"
                    fill="#1374D4" />
                </g>
                <defs>
                  <clipPath id="clip0_5202_5434">
                    <rect width="13" height="13" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Remove</span>
            </button>
          </div>
        </div>
        <div class="priceDetails">
          <div class="dollarShow">
            <span class="dollarSignContainer"> {{ currencyCode }} </span>
            <span class="sponsorPriceContainer">
              {{ sponsor.price | exchangeRate : exchangeRate | number }} </span>
          </div>
          <div class="checkBoxWrapper">
            <div class="labelWrapper">
              <input class="yearlyCheckbox" [id]=" 'checkbox-' + sponsor.id " type="checkbox"
                [checked]="sponsor.timePeriod == 'yearly'" (click)="changeTimePeriod(indx)">
              <label [for]=" 'checkbox-' + sponsor.id ">
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.22883 10.6095C5.94497 10.6096 5.67274 10.4968 5.47219 10.2959L3.18461 8.00915C2.93846 7.76293 2.93846 7.3638 3.18461 7.11758C3.43083 6.87143 3.82996 6.87143 4.07618 7.11758L6.22883 9.27022L11.9238 3.57523C12.17 3.32909 12.5692 3.32909 12.8154 3.57523C13.0615 3.82146 13.0615 4.22059 12.8154 4.46681L6.98547 10.2959C6.78492 10.4968 6.51269 10.6096 6.22883 10.6095Z"
                    fill="white" stroke="white" stroke-width="0.5" />
                </svg>
              </label>
              <p>Pay yearly</p>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-template #otherDonation>
        <div class="childDetails otherDetails">
          <img src="./assets/new-images/psh-other-donation.svg" alt="psh other donation image">
          <div class="childNameWrapper">
            <p class="childType"> {{ sponsor.type }} </p>
            <button class="removeBtn" (click)="removeOtherDonations()" *ngIf="sponsorships.length > 1">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5202_5434)">
                  <path
                    d="M6.5 13C2.91596 13 0 10.084 0 6.5C0 2.91596 2.91596 0 6.5 0C10.084 0 13 2.91596 13 6.5C13 10.084 10.084 13 6.5 13ZM6.5 0.8125C3.36385 0.8125 0.8125 3.36385 0.8125 6.5C0.8125 9.63615 3.36385 12.1875 6.5 12.1875C9.63615 12.1875 12.1875 9.63615 12.1875 6.5C12.1875 3.36385 9.63615 0.8125 6.5 0.8125ZM9.75 6.5C9.75 6.27545 9.56831 6.09375 9.34375 6.09375H3.65625C3.4317 6.09375 3.25 6.27545 3.25 6.5C3.25 6.72455 3.4317 6.90625 3.65625 6.90625H9.34375C9.56831 6.90625 9.75 6.72455 9.75 6.5Z"
                    fill="#1374D4" />
                </g>
                <defs>
                  <clipPath id="clip0_5202_5434">
                    <rect width="13" height="13" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Remove</span>
            </button>
          </div>
        </div>
        <div class="priceDetails">
          <div class="dollarShow">
            <span class="dollarSignContainer"> {{ currencyCode }} </span>
            <span class="sponsorPriceContainer">
              {{ sponsor.price | exchangeRate : exchangeRate }} </span>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
  <div class="increaseImpactWrapper">
    <h2>Increase your Impact</h2>
    <app-more-children #moreChildrenComponent *ngIf="moreChildren" [moreChildren]="moreChildren"
      [sponsorships]="sponsorships" [appClass]="'impactSec'" (newChildEvent)="setChildSponsorships($event)">
    </app-more-children>
    <!-- <div class="otherProgramsWrapper">
      <h2>2. Other programs</h2>
      <div class="otherDonationGroup">
        <span class="dollarSign">$</span>
        <input type="number" placeholder="0" [(ngModel)]="otherDonation">
        <button class="addOtherDonationBtn" [disabled]="!otherDonation || otherDonationObj?.price == otherDonation"
          (click)="addOtherDonation()">{{ otherDonationExists ? 'Update' : 'Add' }} Donation</button>
      </div>
    </div> -->
  </div>

  <div class="showMoreBtnWrapper mobOnly" *ngIf="!disabledBtn">
    <button class="showMoreBtn" (click)="changeStep('contact-details')">proceed to Next Step</button>
    <span></span>
  </div>
  <div class="showMoreBtnWrapper mobOnly" *ngIf="disabledBtn">
    <button class="showMoreBtn loaderBlue" type="button" ><img class="loadingImg" src="/assets/new-images/pshLoaderWhite.webp" alt="loading"></button>        
  </div>

</div>

<div class="stepWrapper" *ngIf=" pageType == 'membership' " >
  <div class="sponsorshipsWrapper">
    <div class="singleSponsorship">
      <ng-container>
        <div class="childDetails">
          <img src="./assets/new-images/psh-other-donation.svg" alt="membership PSH">
          <div class="childNameWrapper">
            <p class="childName"> {{ membership.name }} </p>
            <p class="childType"> <span>{{ membership.type }} </span> <span class="sponsorshipTime">{{ membership.timePeriod }}</span>  </p>
            <!-- <button class="removeBtn" (click)="removePlan(indx)"
              *ngIf="(sponsorships.length - (otherDonationExists ? 1 : 0)) > 1">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_5202_5434)">
                  <path
                    d="M6.5 13C2.91596 13 0 10.084 0 6.5C0 2.91596 2.91596 0 6.5 0C10.084 0 13 2.91596 13 6.5C13 10.084 10.084 13 6.5 13ZM6.5 0.8125C3.36385 0.8125 0.8125 3.36385 0.8125 6.5C0.8125 9.63615 3.36385 12.1875 6.5 12.1875C9.63615 12.1875 12.1875 9.63615 12.1875 6.5C12.1875 3.36385 9.63615 0.8125 6.5 0.8125ZM9.75 6.5C9.75 6.27545 9.56831 6.09375 9.34375 6.09375H3.65625C3.4317 6.09375 3.25 6.27545 3.25 6.5C3.25 6.72455 3.4317 6.90625 3.65625 6.90625H9.34375C9.56831 6.90625 9.75 6.72455 9.75 6.5Z"
                    fill="#1374D4" />
                </g>
                <defs>
                  <clipPath id="clip0_5202_5434">
                    <rect width="13" height="13" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <span>Remove</span>
            </button> -->
          </div>
        </div>
        <div class="priceDetails">
          <div class="dollarShow">
            <span class="dollarSignContainer"> {{ currencyCode }} </span>
            <span class="sponsorPriceContainer">
              {{ membership.price | exchangeRate : exchangeRate | number }} </span>
          </div>
          <!-- <div class="checkBoxWrapper">
            <div class="labelWrapper">
              <input class="yearlyCheckbox" [id]=" 'checkbox-' + sponsor.id " type="checkbox"
                [checked]="sponsor.timePeriod == 'yearly'" (click)="changeTimePeriod(indx)">
              <label [for]=" 'checkbox-' + sponsor.id ">
                <svg width="15" height="13" viewBox="0 0 15 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M6.22883 10.6095C5.94497 10.6096 5.67274 10.4968 5.47219 10.2959L3.18461 8.00915C2.93846 7.76293 2.93846 7.3638 3.18461 7.11758C3.43083 6.87143 3.82996 6.87143 4.07618 7.11758L6.22883 9.27022L11.9238 3.57523C12.17 3.32909 12.5692 3.32909 12.8154 3.57523C13.0615 3.82146 13.0615 4.22059 12.8154 4.46681L6.98547 10.2959C6.78492 10.4968 6.51269 10.6096 6.22883 10.6095Z"
                    fill="white" stroke="white" stroke-width="0.5" />
                </svg>
              </label>
              <p>Pay yearly</p>
            </div>
          </div> -->
        </div>
      </ng-container>      
    </div>
  </div>

  <div class="showMoreBtnWrapper mobOnly" *ngIf="!disabledBtn">
    <button class="showMoreBtn" (click)="changeStep('contact-details')">proceed to Next Step</button>
    <span></span>
  </div>
  <div class="showMoreBtnWrapper mobOnly" *ngIf="disabledBtn">
    <button class="showMoreBtn loaderBlue" type="button" ><img class="loadingImg" src="/assets/new-images/pshLoaderWhite.webp" alt="loading"></button>        
  </div>

</div>
