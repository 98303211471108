import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blood-bank',
  templateUrl: './blood-bank.component.html',
  styleUrls: ['./blood-bank.component.css']
})
export class BloodBankComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
