<section class="moreChildrenSec" [ngClass]="appClass">
  <div class="container">
    <div class="moreSecTop">
      <h2>{{ appClass == 'notMore' ? 'Explore Orphan Profiles to Sponsor' : appClass ? 'Sponsor more orphans' : 'View More Children' }}</h2>
      <div class="writers__Controls_one dsktpOnly">
        <div class="swiper-button-prev children-prev" tabindex="0" role="button" aria-label="Previous slide"></div>
        <div class="swiper-button-next children-next" tabindex="0" role="button" aria-label="Next slide"></div>
      </div>
    </div>
  </div>
  <div class="swiperContainer">
    <div class="moreChildrenSwiper">
      <div class="swiper-wrapper" *ngIf="!appClass || appClass == 'notMore'; else appClassTemplate">
        <div class="swiper-slide" *ngFor="let child of moreChildren"
          [routerLink]=" '/orphan-profiles/' + child.id ">
          <div class="singleChild">
            <img [src]="child.child_picture" [alt]="child.name + '-image'">
            <p class="childName">{{ child.name }}</p>
            <p>{{ child.dob | dobToAge }}</p>
          </div>
        </div>
      </div>
      <ng-template #appClassTemplate>
        <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let child of moreChildren">
              <div class="singleChild impactChild">
                <img [src]="child.child_picture" [alt]="child.name + '-image'">
                <p class="childName">{{ child.name }}</p>
                <p>{{ child.dob | dobToAge }}</p>
                <button class="addChildBtn" (click)="addNewChild(child, $event.target)" [disabled]="emittedChildren.includes(child.id)" >
                  <ng-container *ngIf="!emittedChildren.includes(child.id)">
                    Add
                  </ng-container>
                  <ng-container *ngIf="emittedChildren.includes(child.id)">
                    <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M3.8108 9.42941C3.67621 9.42947 3.54293 9.40264 3.4186 9.35047C3.29426 9.2983 3.18131 9.2218 3.08622 9.12537L0.847656 6.86017L1.70186 5.99574L3.8108 8.13093L9.30114 2.57227L10.1553 3.43669L4.53539 9.12537C4.4403 9.2218 4.32735 9.2983 4.20301 9.35047C4.07868 9.40264 3.9454 9.42947 3.8108 9.42941Z" fill="white" stroke="white" stroke-width="0.5"/>
                      </svg>
                      Added
                  </ng-container>
                </button>
              </div>
            </div>
          </div>
      </ng-template>
      <div class="children-pagination swiper-pagination mobOnly"></div>
    </div>
  </div>
</section>
