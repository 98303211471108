import { Component, Input, OnInit, AfterViewInit, ElementRef, ViewChildren, QueryList } from '@angular/core';
declare var $;
declare var YT: any; 
@Component({
  selector: 'app-campuses',
  templateUrl: './campuses.component.html',
  styleUrls: ['./campuses.component.css']
})
export class CampusesComponent implements OnInit, AfterViewInit {

  @Input() pageName: string;
  // @ViewChildren('campusVideo') videos: QueryList<ElementRef>;
  // currentlyPlaying: HTMLIFrameElement | null = null;
  @ViewChildren('campusVideo') videos: QueryList<ElementRef>;
  playerInstances: any[] = [];
  currentlyPlaying: any = null;

  public allCampuses: any = {
    "campuses": [
      {
        "image": "islamabad",
        "name": "Pakistan Sweet Home, Islamabad",
        "url": "/",
        "youtubeUrl": "https://www.youtube.com/embed/DTcl1digsiw",
        "videoName": "اسلام آباد H-9 کیمپس"
      },
      {
        "image": "sohawa",
        "name": "Pakistan Sweet Home Cadet College, Sohawa",
        "url": "/",
        "youtubeUrl": "https://www.youtube.com/embed/FyGLrnsnqcc",
        "videoName": "بوائز کیڈٹ کالج سوہاوہ"
      },
      {
        "image": "waziristan",
        "name": "Pakistan Sweet Home Golden Arrow, North Waziristan",
        "url": "/",
        "youtubeUrl": "https://www.youtube.com/embed/a4pWntf-zoc",
        "videoName": "میر علی کیمپس، شمالی وزیرستان"
      },
      {
        "image": "sargodha",
        "name": "Pakistan Sweet Home Bhalwal, Sargodha",
        "url": "/",
        "youtubeUrl": "https://www.youtube.com/embed/ECiJRIf1QSs",
        "videoName": "پاکستان سویٹ ہوم بھلوال کیمپس، سرگودھا"
      },
      {
        "image": "sukkhar",
        "name": "Pakistan Sweet Home, Sukkur",
        "url": "/",
        "youtubeUrl": "https://www.youtube.com/embed/jV26XJgE-eU",
        "videoName": "پاکستان سویٹ ہوم سکھر کیمپس"
      }
    ],
    "upcoming": [
      {
        "image": "karachi",
        "name": "Pak-Kashmir Cadet College Rehara, Rawalakot",
        "des": "After the success of Cadet College Gujar Khan, we aim to expand and make more children join a fully equipped institution, specifically for those who are interested in joining armed forces to serve the country.",
        "url": "/"
      },
      {
        "image": "rawalakot",
        "name": "Pakistan Sweet Home, Karachi",
        "des": "The construction for the Pakistan Sweet Home Karachi center has been underway for the last 1 year. This state of the art educational and boarding facility will accommodate more than 300 children at one time.",
        "url": "/"
      }
    ]
  };
 ngOnInit(): void {
   
 }

  ngAfterViewInit() {
    this.loadYouTubeAPI();
  }

  loadYouTubeAPI() {
    if (!window['YT']) {
      let tag = document.createElement('script');
      tag.src = "https://www.youtube.com/iframe_api";
      let firstScriptTag = document.getElementsByTagName('script')[0];
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

      // After the API script is loaded, initialize players
      window['onYouTubeIframeAPIReady'] = () => this.initializePlayers();
    } else {
      this.initializePlayers();
    }
  }

  initializePlayers() {
    this.allCampuses[this.pageName].forEach((campus, index) => {
      const player = new YT.Player(`player-${index}`, {
        videoId: this.extractVideoId(campus.youtubeUrl), // Get the video ID from the URL
        events: {
          'onStateChange': (event: any) => this.onPlayerStateChange(event, player)
        }
      });
      this.playerInstances[index] = player;
    });
  }

  onPlayerStateChange(event: any, player: any) {
    if (event.data === YT.PlayerState.PLAYING) {
      if (this.currentlyPlaying && this.currentlyPlaying !== player) {
        this.currentlyPlaying.pauseVideo(); // Pause the currently playing video
      }
      this.currentlyPlaying = player;
    }
  }

  playVideo(index: number, event: MouseEvent) {
    const player = this.playerInstances[index];

    if (player) {
      player.playVideo();
    }

    // Hide the overlay after playing the video
    (event.target as HTMLElement).style.display = 'none';
  }

  // Helper method to extract the video ID from a YouTube URL
  extractVideoId(url: string): string {
    const regExp = /^.*(youtu.be\/|v\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);
    return (match && match[2].length === 11) ? match[2] : '';
  }


}
