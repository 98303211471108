import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../services/_services';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

  constructor(
    private router                 : Router,
    private authenticationService : AuthenticationService,
  ) { }

  ngOnInit(): void {

    if(localStorage.getItem('currentUser')){
      this.authenticationService.simpleLogout();
      this.router.navigate(['/']);
    }

  }

}
