import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $, Swiper;

@Component({
  selector: 'app-sponsor-page',
  templateUrl: './sponsor-page.component.html',
  styleUrls: ['./sponsor-page.component.css']
})
export class SponsorPageComponent implements OnInit, AfterViewInit {


  constructor() { 
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });

    var swiper = new Swiper(".stepsSwiper", {
      slidesPerView: 1,
      pagination: {
            el: ".steps-pagination",
            clickable: true
          }
    });
  }

}
