import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';
declare var $, Swiper,window;

@Component({
  selector: 'app-legal',
  templateUrl: './legal.component.html',
  styleUrls: [ '../why-us/why-us.component.css', './legal.component.css']
})
export class LegalComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {

    var swiper = new Swiper(".docsSwiper", {
      direction: "vertical",
      slidesPerView : 1,
      centeredSlides : true,
      navigation: {
        nextEl: ".files-next",
        prevEl: ".files-prev",
      },
      breakpoints: {
        360: {
          direction: "horizontal",
        },
        575: {
          direction: "vertical",
        }
      },
    });
      this.setSEO_Tags("Explore Pakistan Sweet Home’s Legal Status and Verification","Explore how Pakistan Sweet Home is fully legal and verified, registered under SECP with tax exemptions, ensuring transparent governance and compliance.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
