<section class="banner_section">
  <div class="containerFluid">
    <div class="bannerWrapper">
      <div class="bannerContent">
        <h1>Education Sponsorship Program</h1>
        <p></p>
      </div>
      <div class="bg_video">
        <img
          src="assets/new-images/day-scholars-banner-img.png"
          alt="banner img"
        />
      </div>
    </div>
  </div>
</section>

<!-- <section class="admissionBannerSec">
    <div class="containerFluid">
      <div class="admissionBannerWrapper">
        <h1>Education Sponsorship Education Sponsorship Program</h1>
        <p></p>
        <img src="assets/new-images/day-scholars-banner-img.png" alt="banner img">
      </div>
    </div>
  </section> -->

<section class="main_accord" id="scroll-section7">
  <div class="container">
    <div
      class="main-card"
      data-aos="fade-up"
      data-aos-duration="800"
      data-aos-delay="200"
    >
      <div class="card show">
        <div class="card-head">
          <h3>Who can apply?</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body">
          <section class="admissionApply">
            <div class="main_apply">
              <div class="apply_left">
                <ol class="noStyle">
                  <li>
                    Any orphan child living at home, studying in grades 1-12,
                    who can't afford the educational expenses, is welcome to
                    apply. It doesn't matter where in Pakistan you live, as long
                    as you're passionate about learning and have the
                    determination to excel.
                  </li>
                </ol>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Who cannot apply?</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>
                  Children who are not orphans, cannot apply. Also, those who
                  can afford educational expenses are kindly requested to leave
                  the opportunity for the more deserving candidates.
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What is the admission procedure?</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>
                  After you've applied online, our team reviews all
                  applications. We will call you for an interview to assess the
                  financial condition. The final decision is based on the
                  financial need, the child's eagerness to learn, and the
                  outcome of the interview.
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How to apply?</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>
                  <strong>1. </strong> "Click on the ""Apply Now"" button at the
                  bottom of this page to create an account.
                </li>
                <li>
                  <strong>2. </strong> Fill out the online application form
                  carefully.
                </li>
                <li>
                  <strong>3. </strong> Submit the form after reviewing the
                  information."
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Documents Required For Application:</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li><strong>1. </strong> "Child's B-form issued by NADRA.</li>
                <li>
                  <strong>2. </strong> Death certificate of the child's father
                  issued by NADRA.
                </li>
                <li>
                  <strong>3. </strong> Most recent mark sheet or report card.
                </li>
                <li>
                  <strong>4. </strong> Proof of current financial condition
                  (utility bills, rent agreement, etc.)"
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Selection Procedure</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>
                  All applications are reviewed by our selection committee.
                  Interviews are scheduled with shortlisted applicants to better
                  understand their situation and needs.
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Final Selection</h3>
          <span class="faq-icon">
            <svg
              width="13"
              height="9"
              viewBox="0 0 13 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z"
                fill="#5E5E5E"
              />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display: none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>
                  Final selection is made based on financial need, the child's
                  academic potential, and the findings from the interview or
                  home visit. Selected candidates will be notified via email,
                  and their names will also be posted on our website.
                </li>
              </ol>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="promise_section" id="scroll-section8">
  <div class="container">
    <a
      class="apply_btn"
      routerLink="/education-sponsorship/apply"
      href="javascript:;"
      >Apply Now</a
    >
  </div>
</section>
