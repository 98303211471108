import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-highlights-of-ramadan',
  templateUrl: './highlights-of-ramadan.component.html',
  styleUrls: ['./highlights-of-ramadan.component.css', '../articles.component.css']
})
export class HighlightsOfRamadanComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Key Highlights of Ramadan 2024 at Pakistan Sweet Home","Relive the highlights of Ramadan 2024 at Pakistan Sweet Home, where orphans enjoyed special meals, prayers, and acts of kindness.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
