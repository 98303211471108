<section class="banner_section">
  <div class="containerFluid">
    <div class="bannerWrapper">
      <div class="bannerContent">
        <h1>Papa Jani Scholarship Program</h1>
        <p>This program is open to orphaned girls from classes 6 to 8 at Siddeeq Public School. It offers day scholar
          opportunity for students residing in Rawalpindi and Islamabad. Pakistan Sweet Home covers tuition fees,
          transportation, books, uniforms, and other academic expenses.</p>
      </div>
      <div class="bg_video">
        <img src="assets/new-images/scholarship-program-banner-img.png" alt="banner img">
      </div>
    </div>
  </div>
</section>

<section class="main_accord" id="scroll-section7">
  <div class="container">
    <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
      <div class="card show">
        <div class="card-head">
          <h3>Who can apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body">
          <section class="admissionApply">
            <div class="main_apply">
              <div class="apply_left">
                <ol class="noStyle">
                  <li>The PapaJani scholarship program is open to orphaned girls across Rawalpindi and Islamabad who
                    demonstrate outstanding academic ability and the determination to succeed. Here are the eligibility
                    criteria: </li>
                  <li><strong>1.</strong> The applicant must be an orphan.</li>
                  <li><strong>2.</strong> The applicant must be a resident of Rawalpindi or Islamabad.</li>
                  <li><strong>3.</strong> The applicant must be a girl under 15 years of age.</li>
                  <li>Due to our limited capacity and resources, we cannot offer this program to students living outside
                    Rawalpindi and Islamabad.</li>
                </ol>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Who can not apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li> <strong>1.</strong> Students who are not orphans. </li>
                <li> <strong>2.</strong> Students not residing in Rawalpindi and Islamabad. </li>
                <li> <strong>3.</strong> Students above the age of 15. </li>
                <li> Please remember: While we wish we could extend this opportunity to all, we must limit it to those
                  who meet the above-mentioned criteria. Unfortunately, due to our limited capacity and resources, we
                  cannot currently educate students outside of Rawalpindi and Islamabad at the moment. </li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What is the admission procedure?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>The admission procedure for the PapaJani Scholarship Program is a multi-step process designed to
                  ensure the selection of the most deserving candidates:</li>
                <li><strong>Initial Application:</strong> Eligible students are encouraged to apply online by filling
                  out the scholarship application form. The form requires personal information, academic records, and
                  the submission of required documents.</li>
                <li><strong>Test:</strong> After applying to the scholarship program, applicants will undergo a test on
                  campus.</li>
                <li><strong>Interview:</strong> Shortlisted candidates will be invited for an interview. This could be
                  either in-person or virtual, depending on the circumstances of the applicant.</li>
                <li><strong>Final Selection:</strong> After the interviews, the final selection of scholarship
                  recipients will be made based on the merit and overall performance of the candidates.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How to apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>To apply for the Pakistan Sweet Home Bright Futures Scholarship, follow these steps:</li>
                <li> <strong>Step 1:</strong> Click on the "Apply Now" link on this page.</li>
                <li> <strong>Step 2:</strong> Fill out the online application form accurately and honestly. Make sure
                  all the information provided is current and correct.</li>
                <li> <strong>Step 3:</strong> Upload the required documents (detailed in the next section).</li>
                <li> <strong>Step 4:</strong> Submit your application.</li>
                <li> <strong>Step 5:</strong> Once submitted, you will receive a confirmation email.</li>
                <li>Please note: Incomplete or falsely filled applications will be disregarded.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Documents Required For Application:</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>The following documents are needed for the application:</li>
                <li><strong>Proof of Orphanhood:</strong> Documentation proving your orphan status.</li>
                <li><strong>Proof of Residence:</strong> Document showing that you are a resident of Pakistan.</li>
                <li><strong>Academic Records:</strong> Submit your academic records, which include your academic
                  transcripts or report cards with your grades.</li>
                <li>Please ensure these documents are clear and legible. Incomplete applications or those with missing
                  documents will not be considered.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Selection Procedure</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>The PapaJani Scholarship Program employs a rigorous selection process to ensure that only the most
                  deserving candidates are awarded. Here's a brief overview of our selection procedure:</li>
                <li><strong>Screening of Applications:</strong> Our team will carefully review each application,
                  assessing the overall eligibility of the candidate.</li>
                <li><strong>Test:</strong> Every student who meets the criteria will come to Siddeeq Public School 6th
                  Road campus for the test. The candidates will be shortlisted for the interview based on the test
                  results.</li>
                <li><strong>Interview Process:</strong> Candidates on the shortlist will then be invited for an
                  interview. This interview assesses the candidate's motivation, resilience, and plans.</li>
                <li><strong>Final Decision:</strong> Following the interview, the committee will decide. This decision
                  will be based on the candidate's test performance, interview performance, and the overall impression
                  the candidate made on the committee.</li>
                <li>Remember, this scholarship is a merit-based program. Only candidates who excel in all areas will be
                  selected.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Final Selection</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>The final selection of the scholarship recipients will be made after a thorough review of each
                  candidate's application and performance during the interview. The selected students will receive a
                  confirmation email detailing the next steps and congratulating them on their achievement.</li>
                <li>In the event of not being selected, candidates are encouraged to continue their academic efforts and
                  apply again in the future when they meet the criteria.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="promise_section" id="scroll-section8">
  <div class="container">
    <a class="apply_btn" routerLink="/scholarship-program/apply" href="javascript:;">Apply Now</a>
    <!-- <a class="apply_btn hollow" (click)="toggleDownloadModal()"
        href="javascript:;">Download Form</a> -->
  </div>
</section>

<section class="downloadModal" *ngIf="showDownloadModal">
  <div class="downloadOverlay"></div>
  <div class="downloadModalContent">
    <a href="javascript:;" (click)="toggleDownloadModal()" class="closeDownloadModal">
      <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_1537_69)">
          <path
            d="M8.67542 7.44903L14.1337 1.99132C14.4725 1.6525 14.4725 1.10316 14.1337 0.76437C13.7949 0.425548 13.2456 0.425548 12.9068 0.76437L7.44903 6.22265L1.99132 0.76437C1.6525 0.425548 1.10316 0.425548 0.76437 0.76437C0.425576 1.10319 0.425548 1.65253 0.76437 1.99132L6.22265 7.44903L0.76437 12.9068C0.425548 13.2456 0.425548 13.7949 0.76437 14.1337C1.10319 14.4725 1.65253 14.4725 1.99132 14.1337L7.44903 8.67542L12.9067 14.1337C13.2456 14.4725 13.7949 14.4725 14.1337 14.1337C14.4725 13.7949 14.4725 13.2456 14.1337 12.9068L8.67542 7.44903Z"
            fill="#475569" />
        </g>
        <defs>
          <clipPath id="clip0_1537_69">
            <rect width="15" height="15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </a>
    <h2>How to Apply</h2>
    <ol>
      <li>Download & print the Papa Jani Scholarship form.</li>
      <li>Fill out all of the required fields.</li>
      <li>Post the form at one of these addresses:</li>
      <ul>
        <li>Siddeeq Public School 6th Road, Rawalpindi.</li>
        <li>Pakistan Sweet Home Sector H-9/4, Street 4, Near HEC Building, Islamabad.</li>
      </ul>
    </ol>
    <a class="apply_btn download" download href="/assets/downloads/Papa-Jani-Scholarship-Form.pdf">Download Form</a>
  </div>
</section>