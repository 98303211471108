import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
  ){

  }

  transform(url: String, ...args: unknown[]): unknown {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url + '?rel=0&controls=0');;
  }

}
