<section class="thanks_section">

  <div class="thanks_wrapper">
    <div class="thanksLeftside">
      <div class="thanksmsg">
        <!-- <img src="assets/images/khan.svg" alt="khan"> -->
        <video id="video" width="100%" height="100%" loop autoplay controls>
          <source src="assets/images/thanksvideo.mp4" type="video/mp4">
        </video>
        <!-- <div class="playlistwraper">
          <div class="playlist">
            <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14.5047 7.57563C15.1701 7.96077 15.1701 8.92145 14.5047 9.30659L1.75094 16.6884C1.08427 17.0743 0.249999 16.5932 0.249999 15.8229L0.25 1.05928C0.25 0.288994 1.08427 -0.192072 1.75094 0.193793L14.5047 7.57563Z"
                fill="white" />
            </svg>
          </div>
          <div class="playlisttime">
            <div class="range"></div>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1124_2335)">
                <path
                  d="M8.13392 14.1973C8.13392 14.4837 7.96844 14.7439 7.70947 14.8653C7.60934 14.9127 7.50204 14.9356 7.39545 14.9356C7.22664 14.9356 7.05941 14.8775 6.92433 14.7655L2.88804 11.4209H0.738304C0.33062 11.4212 0 11.0904 0 10.6828V7.74125C0 7.3334 0.33062 7.00277 0.738304 7.00277H2.88821L6.9245 3.65813C7.14503 3.47534 7.45084 3.43638 7.70964 3.5587C7.96844 3.68015 8.1341 3.94052 8.1341 4.22675L8.13392 14.1973ZM10.9713 13.0436C10.9533 13.0448 10.936 13.0455 10.9182 13.0455C10.7231 13.0455 10.5351 12.9684 10.3962 12.8293L10.2975 12.7303C10.0385 12.4718 10.0081 12.0624 10.2262 11.7685C10.7791 11.023 11.0709 10.1393 11.0709 9.21227C11.0709 8.21517 10.7396 7.27992 10.1126 6.50754C9.87387 6.21397 9.89589 5.78759 10.1634 5.52023L10.262 5.42149C10.4095 5.27401 10.6071 5.19363 10.821 5.20638C11.0293 5.21687 11.2238 5.3149 11.3559 5.47637C12.2256 6.54057 12.685 7.83265 12.685 9.21244C12.685 10.4975 12.2787 11.7208 11.5099 12.7493C11.3814 12.9207 11.185 13.0284 10.9713 13.0436ZM14.0238 15.3252C13.8902 15.483 13.6972 15.5778 13.4904 15.5865C13.4803 15.5868 13.47 15.5872 13.4595 15.5872C13.264 15.5872 13.0763 15.51 12.9374 15.371L12.8404 15.274C12.5693 15.0032 12.551 14.57 12.7974 14.2768C13.9881 12.8606 14.6441 11.0621 14.6441 9.21227C14.6441 7.28814 13.9418 5.43582 12.667 3.99661C12.4086 3.70444 12.4217 3.26215 12.6967 2.98605L12.7935 2.88907C12.9372 2.74473 13.1238 2.66522 13.3379 2.67204C13.5411 2.6778 13.7333 2.76762 13.8684 2.91965C15.4093 4.65453 16.2581 6.88954 16.2581 9.21227C16.2584 11.4471 15.4649 13.6181 14.0238 15.3252Z"
                  fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1124_2335">
                  <rect width="16.2581" height="16.2581" fill="white" transform="translate(0 0.922363)" />
                </clipPath>
              </defs>
            </svg>
            <svg class="videosetting" width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1124_2333)">
                    <path
                        d="M16.237 7.36368L14.9634 7.20177C14.8584 6.87865 14.7291 6.56703 14.578 6.271L15.3645 5.25826C15.6829 4.84842 15.6456 4.2699 15.2818 3.91765L14.2149 2.85071C13.8593 2.48355 13.2807 2.44697 12.8702 2.76468L11.8588 3.55116C11.5628 3.4001 11.2512 3.27071 10.9274 3.16571L10.7655 1.89419C10.7045 1.3841 10.2716 1 9.75883 1H8.24142C7.72861 1 7.29574 1.3841 7.23477 1.89216L7.07287 3.16571C6.74906 3.27071 6.43745 3.39942 6.14142 3.55116L5.12935 2.76468C4.72019 2.44697 4.14167 2.48355 3.78874 2.84732L2.7218 3.91358C2.35464 4.2699 2.31738 4.84842 2.63577 5.25894L3.42225 6.271C3.27051 6.56703 3.1418 6.87865 3.0368 7.20177L1.76529 7.36368C1.25519 7.42465 0.871094 7.85752 0.871094 8.37032V9.88774C0.871094 10.4005 1.25519 10.8334 1.76325 10.8944L3.0368 11.0563C3.1418 11.3794 3.27119 11.691 3.42225 11.9871L2.63577 12.9998C2.31738 13.4096 2.35464 13.9882 2.71842 14.3404L3.78535 15.4074C4.14167 15.7738 4.71951 15.8104 5.13003 15.4927L6.14209 14.7062C6.43813 14.858 6.74974 14.9874 7.07287 15.0917L7.23477 16.3625C7.29574 16.874 7.72861 17.2581 8.24142 17.2581H9.75883C10.2716 17.2581 10.7045 16.874 10.7655 16.3659L10.9274 15.0924C11.2505 14.9874 11.5621 14.858 11.8582 14.7069L12.8709 15.4934C13.2807 15.8118 13.8593 15.7745 14.2115 15.4107L15.2784 14.3438C15.6456 13.9875 15.6829 13.4096 15.3645 12.9991L14.578 11.9871C14.7297 11.691 14.8591 11.3794 14.9634 11.0563L16.2343 10.8944C16.7444 10.8334 17.1285 10.4005 17.1285 9.88774V8.37032C17.1292 7.85752 16.7451 7.42465 16.237 7.36368V7.36368ZM9.00013 12.5161C7.13248 12.5161 5.61303 10.9967 5.61303 9.12903C5.61303 7.26139 7.13248 5.74194 9.00013 5.74194C10.8678 5.74194 12.3872 7.26139 12.3872 9.12903C12.3872 10.9967 10.8678 12.5161 9.00013 12.5161Z"
                        fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_1124_2333">
                        <rect width="16.2581" height="16.2581" fill="white" transform="translate(0.871094 0.922363)" />
                    </clipPath>
                </defs>
            </svg>
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.75806 5.57282C1.89281 5.57282 2.02204 5.51929 2.11732 5.42401C2.2126 5.32873 2.26613 5.1995 2.26613 5.06475V3.03249C2.26613 2.89775 2.31966 2.76852 2.41494 2.67324C2.51022 2.57796 2.63945 2.52443 2.77419 2.52443H4.80645C4.9412 2.52443 5.07043 2.4709 5.16571 2.37562C5.26099 2.28034 5.31452 2.15111 5.31452 2.01637C5.31452 1.88162 5.26099 1.75239 5.16571 1.65711C5.07043 1.56183 4.9412 1.5083 4.80645 1.5083H2.77419C2.36995 1.5083 1.98227 1.66888 1.69643 1.95473C1.41058 2.24057 1.25 2.62825 1.25 3.03249V5.06475C1.25 5.1995 1.30353 5.32873 1.39881 5.42401C1.49409 5.51929 1.62332 5.57282 1.75806 5.57282Z"
                    fill="white" />
                <path
                    d="M4.80645 15.7339H2.77419C2.63945 15.7339 2.51022 15.6804 2.41494 15.5851C2.31966 15.4898 2.26613 15.3606 2.26613 15.2259V13.1936C2.26613 13.0589 2.2126 12.9296 2.11732 12.8344C2.02204 12.7391 1.89281 12.6855 1.75806 12.6855C1.62332 12.6855 1.49409 12.7391 1.39881 12.8344C1.30353 12.9296 1.25 13.0589 1.25 13.1936V15.2259C1.25 15.6301 1.41058 16.0178 1.69643 16.3036C1.98227 16.5895 2.36995 16.7501 2.77419 16.7501H4.80645C4.9412 16.7501 5.07043 16.6965 5.16571 16.6013C5.26099 16.506 5.31452 16.3767 5.31452 16.242C5.31452 16.1073 5.26099 15.978 5.16571 15.8827C5.07043 15.7875 4.9412 15.7339 4.80645 15.7339Z"
                    fill="white" />
                <path
                    d="M15.9842 12.6855C15.8494 12.6855 15.7202 12.7391 15.6249 12.8344C15.5296 12.9296 15.4761 13.0589 15.4761 13.1936V15.2259C15.4761 15.3606 15.4226 15.4898 15.3273 15.5851C15.232 15.6804 15.1028 15.7339 14.9681 15.7339H12.9358C12.8011 15.7339 12.6718 15.7875 12.5765 15.8827C12.4813 15.978 12.4277 16.1073 12.4277 16.242C12.4277 16.3767 12.4813 16.506 12.5765 16.6013C12.6718 16.6965 12.8011 16.7501 12.9358 16.7501H14.9681C15.3723 16.7501 15.76 16.5895 16.0458 16.3036C16.3317 16.0178 16.4923 15.6301 16.4923 15.2259V13.1936C16.4923 13.0589 16.4387 12.9296 16.3434 12.8344C16.2482 12.7391 16.1189 12.6855 15.9842 12.6855Z"
                    fill="white" />
                <path
                    d="M14.9681 1.5083H12.9358C12.8011 1.5083 12.6718 1.56183 12.5765 1.65711C12.4813 1.75239 12.4277 1.88162 12.4277 2.01637C12.4277 2.15111 12.4813 2.28034 12.5765 2.37562C12.6718 2.4709 12.8011 2.52443 12.9358 2.52443H14.9681C15.1028 2.52443 15.232 2.57796 15.3273 2.67324C15.4226 2.76852 15.4761 2.89775 15.4761 3.03249V5.06475C15.4761 5.1995 15.5296 5.32873 15.6249 5.42401C15.7202 5.51929 15.8494 5.57282 15.9842 5.57282C16.1189 5.57282 16.2482 5.51929 16.3434 5.42401C16.4387 5.32873 16.4923 5.1995 16.4923 5.06475V3.03249C16.4923 2.62825 16.3317 2.24057 16.0458 1.95473C15.76 1.66888 15.3723 1.5083 14.9681 1.5083Z"
                    fill="white" />
                <path
                    d="M11.9198 4.55664H5.82302C4.98123 4.55664 4.29883 5.23905 4.29883 6.08083V12.1776C4.29883 13.0194 4.98123 13.7018 5.82302 13.7018H11.9198C12.7616 13.7018 13.444 13.0194 13.444 12.1776V6.08083C13.444 5.23905 12.7616 4.55664 11.9198 4.55664Z"
                    fill="white" />
            </svg>

          </div>
        </div> -->
      </div>
      <div class="thanksCard">
        <!-- <p class="thanksHead">Thankyou for your support</p> -->
        <p class="descHead">HERE’S WHAT’S NEXT</p>
        <hr>
        <p class="shortDes">A confirmation email will be sent shortly. Please take a moment to complete your account
          to join the Pakistan Sweet Home
          community and track your lifetime impact.</p>
      </div>
    </div>
    <div class="thanksrightside">
      <p class="mobileText">Thankyou for your
        support</p>
      <div class="donationSuccessfull">
        <div class="donationtop">
          <!-- <img src="assets/images/successIcon.svg" alt="success"> -->
          <img src="assets/images/green-tick.gif" alt="success" class="green-tick">

          <div class="donationtopRight">
            <p class="donationhead">DONATION SUCCESSFUL</p>
            <p class="donationText">${{ amount }}{{ monthlyPay ? '/month' : '' }} is being processed, thank you for your support!</p>
          </div>
        </div>
        <hr>
        <p class="donationDESC">We will send you stories of your impact and let you know of other opportunities
          to give.</p>
      </div>
      <div class="mobflex" *ngIf="!checkingUser">
        <p class="comunity">You’re now part of a passionate community playing a crucial role in bringing a revolutionary
          change in the lives of
          orphans.</p>
        <p class="whatnext">HERE’S WHAT’S NEXT</p>
        <div class="signupBtn" *ngIf="!signedup">
          <a href="javascript:;" *ngIf="!oldUser" (click)="signInWithFB()">SIGN UP WITH FACEBOOK</a>
          <a href="javascript:;" *ngIf="oldUser" (click)="signInWithFB()">CONTINUE WITH FACEBOOK</a>
        </div>
        <!-- absolute Div for border -->
        <div class="orLine" *ngIf="!signedup"></div>
        <!-- absolute Div for border -->

        <div class="completeAccount" *ngIf="!signedup">
          <div class="accountTop" *ngIf="!oldUser">
            <p>Create Your Account</p>
          </div>
          <div class="accountTop" *ngIf="oldUser">
            <p>Sign In With Email</p>
          </div>
          <div class="form__Wrapper" >
            <form action="">
              <p class="serverError" *ngIf="errorMsg">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                {{errorMsg}}
              </p>
              <div class="inputGroup">
                <label for="">EMAIL</label>
                <input type="text" name="email" id="email" [(ngModel)]="email" placeholder="Enter an Email" class="disabledInput" disabled>
              </div>
              <div class="inputGroup">
                <label for="">PASSWORD</label>
                <input type="password" name="password" id="password" [(ngModel)]="password" placeholder="Enter Your Password">
              </div>

              <div class="policyCheck" *ngIf="!oldUser">
                <input class="ourpolicy" type="checkbox" id="ourpolicy" [(ngModel)]="over18" name="half_price" value="" [checked]="over18">
                <label class="policyLabel" for="ourpolicy">
                  <div class="labelHover">
                  </div>
                </label>
                <p class="policyText" >
                  I’m over 18 or have parental consent to join
                </p>
              </div>

              <div class="registerAcoount">
                <button *ngIf="(!loading) && (!oldUser) " class="registerBtn" (click)="registerAccount()">REGISTER ACCOUNT</button>
                <button *ngIf="(!loading) && (oldUser) " class="registerBtn" (click)="signIn()">SIGN IN</button>
                <div *ngIf="loading" class="loader">
                  <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
              </div>
              </div>



            </form>
          </div>
        </div>
      </div>
    </div>

  </div>
</section>
