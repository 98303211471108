<div class="formWrapper">
  <h2 class="wrapperHead">Personal Information</h2>
  <form
    #admissionForm="ngForm"
    class="admissionForm"
    (onSubmit)="submitApplication()"
  >
    <div class="formFields">
      <p class="divisionHead">Class Applying</p>

      <div class="classSelect">
        <input
          type="radio"
          name="class_applying"
          id="preCadet"
          value="preCadet"
          [(ngModel)]="class_applying"
          required=""
        />
        <label class="classLabel" for="preCadet">
          <span class="radioLabelBox">
            <svg
              _ngcontent-lbu-c120=""
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                _ngcontent-lbu-c120=""
                d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                fill="white"
              ></path>
            </svg>
          </span>
          <span class="radioLabelText">I’m applying for 8th class</span>
        </label>
        <input
          type="radio"
          name="class_applying"
          id="elevenClass"
          value="elevenClass"
          [(ngModel)]="class_applying"
          required=""
        />
        <label class="classLabel" for="elevenClass">
          <span class="radioLabelBox">
            <svg
              _ngcontent-lbu-c120=""
              width="11"
              height="11"
              viewBox="0 0 11 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                _ngcontent-lbu-c120=""
                d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                fill="white"
              ></path>
            </svg>
          </span>
          <span class="radioLabelText">I’m applying for 11th class</span>
        </label>
      </div>

      <p class="divisionHead">Candidate Information</p>
      <div class="inputGroup aj_group">
        <p class="asLabel">Full Name<span class="redLab">*</span></p>
        <input
          #nameInput
          class="firstname_input"
          type="text"
          name="candidateInfoName"
          [(ngModel)]="candidateInfo.name"
          placeholder="Name of Candidate"
          required=""
        />
        <!-- <label for="Name of Candidate*" class="firstname_label">Type Name of Candidate<span class="redLab">*</span> </label> -->
        <p class="error">Enter Full Name</p>
      </div>
      <!-- <div class="inputGroup dateGroup">
            <p class="asLabel">Date of Birth<span class="redLab">*</span></p>
            <input class="firstname_input" (change)="removeInvalid('#dob')" id="dob" name="DOB" [(ngModel)]="candidateInfo.dob" placeholder="Date of Birth*" type="date" required=""
             [max]="setDate(0)" [min]="setDate(14)">                        
            <p class="error">
               {{ageError}}
            </p>
        </div> -->
      <div class="DOBWrapper inputWrapper">
        <p class="asLabel" for="profileUrl">
          Date Of Birth<span class="redLab">*</span>
        </p>
        <div class="doubleInput">
          <ng-select
            class="DOBSelectDate selectTrio"
            [items]="dateOptions"
            [(ngModel)]="selectedDate"
            placeholder="Date"
            name="dateDOB"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <ng-select
            class="DOBSelectMonth selectTrio"
            [items]="monthOptions"
            [(ngModel)]="selectedMonth"
            bindValue="value"
            bindLabel="name"
            placeholder="Month"
            name="monthDOB"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
          <ng-select
            class="DOBSelectYear selectTrio"
            [items]="yearOptions"
            [(ngModel)]="selectedYear"
            placeholder="Year"
            name="yearDOB"
            [searchable]="false"
            [clearable]="false"
          >
          </ng-select>
        </div>
        <p class="error" *ngIf="!elligible">
          {{ ageError }}
        </p>
      </div>
      <div class="inputGroup">
        <p class="asLabel">Gender<span class="redLab">*</span></p>
        <ng-select
          class="admissionSelect"
          [items]="genderOptions"
          [(ngModel)]="candidateInfo.gender"
          placeholder="Select Gender"
          name="gender"
          [searchable]="false"
          [clearable]="false"
          required=""
          id="gender"
          (change)="removeInvalid('#gender')"
        >
        </ng-select>
        <p class="error">Please Select Gender</p>
      </div>
      <div class="inputGroup">
        <p class="asLabel">
          Whatsapp Phone Number<span class="redLab">*</span>
        </p>
        <input
          id="phone"
          (keyup)="checkPhoneNumber()"
          type="text"
          mask="(000) 0000000"
          name="phone"
          [(ngModel)]="candidateInfo.whatsapp_number"
          placeholder="(3xx) xxxxxxx"
          required=""
        />
        <span class="countryCode">{{ coutryCode }}</span>
        <div class="flagsWrapper">
          <div class="coutryFlags">
            <div
              (click)="
                selectedFlag = flag;
                selectedCountry = countryOptions[i].text;
                coutryCode = '';
                setCountryCode()
              "
              *ngFor="let flag of flagOptions; index as i"
              class="singleFlag"
            >
              <img
                [src]="
                  'https://img.mobiscroll.com/demos/flags/' + flag + '.png'
                "
                alt="country"
              />
              <p>{{ countryOptions[i].text }}</p>
            </div>
          </div>
          <div class="selectedFlag" *ngIf="selectedFlag">
            <img
              [src]="
                'https://img.mobiscroll.com/demos/flags/' +
                selectedFlag +
                '.png'
              "
              alt=""
            />
            <svg
              width="6"
              height="3"
              viewBox="0 0 6 3"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3 3L0.401924 1.25622e-08L5.59808 -4.417e-07L3 3Z"
                fill="#5E5E5E"
              />
            </svg>
          </div>
        </div>
        <p class="error">Please Enter Whatsapp Number</p>
      </div>
      <div class="inputGroup">
        <p class="asLabel">Father's Name<span class="redLab">*</span></p>
        <input
          class="firstname_input"
          id="fatherName"
          type="text"
          name="fatherName"
          [(ngModel)]="candidateInfo.father_name"
          placeholder="Enter Father’s Name"
          required=""
        />
        <p class="error">Please Enter Father's Full Name</p>
      </div>
      <div class="inputGroup">
        <p class="asLabel">CNIC Number<span class="redLab">*</span></p>
        <input
          class="firstname_input"
          mask="00000-0000000-0"
          id="cand_cnic"
          type="text"
          name="cand_cnic"
          [(ngModel)]="candidateInfo.cnic"
          placeholder="Candidate CNIC (B-Form issued by NADRA)"
          required=""
        />
        <p class="error">Please Enter CNIC Number</p>
      </div>
      <div class="inputGroup" *ngIf="class_applying == 'elevenClass'">
        <p class="asLabel">Full Address<span class="redLab">*</span></p>
        <input
          class="firstname_input"
          id="full_adress"
          type="text"
          name="full_adress"
          (keyup)="removeInvalid('#full_adress')"
          [(ngModel)]="classElevenFields.address"
          placeholder="Enter Full Address"
          required=""
        />
        <p class="error">Please Enter Address</p>
      </div>
      <p class="asLabel">
        Upload B-Form Issued by Nadra<span class="redLab">*</span>
      </p>
      <div class="fileUpload cnicUpload">
        <input
          type="file"
          id="cnicFile"
          name="cnicFile"
          (change)="saveFile($event, 'cnicFile')"
          required="cnicFile"
          accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
        />
        <label for="cnicFile">Upload CNIC</label>
        <p
          class="fileName"
          [ngClass]="candidateInfo?.cnic_img?.name ? 'exists' : ''"
        >
          {{
            candidateInfo?.cnic_img?.name
              ? candidateInfo.cnic_img.name
              : candidateInfo.cnic_img
              ? candidateInfo.cnic_img.split("/").pop()
              : "No file choosen (max: 2mb)"
          }}
        </p>
        <p class="error" *ngIf="!candidateInfo.cnic_img">
          Please Upload CNIC Image
        </p>
      </div>
      <div class="checkBoxWrapper">
        <p class="asLabel">
          Any Health/Medical Issue<span class="redLab">*</span>
        </p>
        <div class="CBAll">
          <div class="singleRadio">
            <input
              type="radio"
              name="health"
              id="yesHealth"
              [(ngModel)]="candidateInfo.health_medical_issue"
              value="1"
            />
            <label class="radioLabel" for="yesHealth">
              <span class="radioLabelBox">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="radioLabelText">Yes</span>
            </label>
          </div>
          <div class="singleRadio">
            <input
              type="radio"
              name="health"
              id="noHealth"
              [(ngModel)]="candidateInfo.health_medical_issue"
              value="0"
            />
            <label class="radioLabel" for="noHealth">
              <span class="radioLabelBox">
                <svg
                  width="11"
                  height="11"
                  viewBox="0 0 11 11"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                    fill="white"
                  />
                </svg>
              </span>
              <span class="radioLabelText">No</span>
            </label>
          </div>
        </div>
        <div
          class="inputGroup aj_group"
          *ngIf="candidateInfo.health_medical_issue == '1'"
        >
          <p class="asLabel">Health Issue Name</p>
          <input
            class="firstname_input"
            type="text"
            name="healthIssue"
            [(ngModel)]="candidateInfo.health_issue_name"
            placeholder="Describe Your Health Issue Here..."
          />
          <p class="error">Please Enter Valid Name</p>
        </div>
      </div>

      <div class="inputGroupone">
        <p class="asLabel">Candidate Photo<span class="redLab">*</span></p>
        <div class="fileUpload cnicUpload">
          <input
            name="candidatePhoto"
            type="file"
            id="candidatePhoto"
            (change)="saveFile($event, 'candidatePhoto')"
            accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
          />
          <label for="candidatePhoto">Upload File</label>
          <p class="fileName" [ngClass]="candidateInfo?.candidate_photo?.name ? 'exists' : '' ">
            {{candidateInfo?.candidate_photo?.name ? candidateInfo.candidate_photo.name : (candidateInfo.candidate_photo ? candidateInfo.candidate_photo.split("/").pop() :  'No file choosen (max: 2mb, format: jpeg, jpg, png, gif, pdf)')}}
          </p>
          <p class="error" *ngIf="!candidateInfo.candidate_photo">
            Please Upload Candidate Photo
          </p>
        </div>
      </div>
      <div class="inputGroupone">
        <p class="asLabel">
          Death Certificate of Father<span class="redLab">*</span>
        </p>
        <div class="fileUpload cnicUpload">
          <input
            name="deathcertificate"
            type="file"
            id="deathcertificate"
            (change)="saveFile($event, 'deathcertificate')"
            required=""
            accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
          />
          <label for="deathcertificate">Upload File</label>

          <p class="fileName" [ngClass]="candidateInfo?.father_death_certificate?.name ? 'exists' : '' ">
            {{candidateInfo?.father_death_certificate?.name ? candidateInfo.father_death_certificate.name : (candidateInfo.father_death_certificate ? candidateInfo.father_death_certificate.split("/").pop() :  'No file choosen (max: 2mb, format: jpeg, jpg, png, gif, pdf)')}}
          </p>
          <p class="error" *ngIf="!candidateInfo.father_death_certificate">
            Please Upload Father's Death Certificate
          </p>
          <div class="formBtns between">
            <button
              class="draftBtn"
              *ngIf="admissionForm.dirty && !editPage"
              (click)="saveDraft()"
            >
              Save & Continue Later
            </button>
          </div>
        </div>
      </div>

      <!-- <div class="inputGroup">
            <p class="asLabel">Father's CNIC<span class="redLab">*</span></p>
            <input class="firstname_input" id="fatherCNIC" mask="00000-0000000-0" id="father_cnic" type="text" name="fatherCNIC" [(ngModel)]="candidateInfo.father_cnic" placeholder="Enter Father’s CNIC" required="">
            <p class="error">
                Please Enter Father's Valid CNIC Number
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Religion<span class="redLab">*</span></p>
            <ng-select class="admissionSelect" 
                       [items]="religionOptions" 
                       [(ngModel)]="candidateInfo.religion"
                       placeholder="Select Religion" 
                       name="religion" 
                       [searchable]="false" 
                       [clearable]="false"
                       required=""
                       id="religion"
                       (change)="removeInvalid('#religion')"
                       >

            </ng-select>
            <p class="error">
                Please Select Religion
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Domicile<span class="redLab">*</span></p>
            <ng-select class="admissionSelect" 
                       [items]="domicileOptions" 
                       [(ngModel)]="candidateInfo.domicile"
                       placeholder='Select Domicile Region' 
                       name="domicile" 
                       [searchable]="false" 
                       [clearable]="false"
                       required=""
                       id="domicile"
                       (change)="removeInvalid('#domicile')"
                       >

            </ng-select>
            <p class="error">
                Please Select Domicile
            </p>
        </div>     -->

      <!-- <p class="CBText middleLabel">Death Certificate of Father<span class="redLab">*</span></p> -->

      <!-- <p class="divisionHead">Guardian Information</p> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Guardian's Name<span class="redLab">*</span></p>        
            <input id="firstname_input" class="firstname_input" type="text" name="name" [(ngModel)]="guardianInfo.guardian_name" placeholder="Enter Guardian's Full Name" required="">
            <p class="error">
                Please Enter Guardian's Full Name
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Relation with Candidate<span class="redLab">*</span></p>
            <ng-select class="admissionSelect" 
                   [items]="relationOptions" 
                   [(ngModel)]="guardianInfo.guardian_relation"
                   placeholder="Select Guardian's Relation with Candidate" 
                   name="relation" 
                   [searchable]="false" 
                   [clearable]="false"
                   required=""
                   id="guardian_relation"
                   (change)="removeInvalid('#guardian_relation')"
                   >
            </ng-select>
            <p class="error">
                Please Select Valid Relation
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Guardian's CNIC<span class="redLab">*</span></p>
            <input class="firstname_input" type="text" mask="00000-0000000-0" id="guardian_cnic" name="cnic" [(ngModel)]="guardianInfo.guardian_cnic" placeholder="Enter Guardian’s CNIC" required="">
            <p class="error">
                Please Enter Guardian's CNIC Number
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Guardian's Occupation<span class="redLab">*</span></p>
            <input class="firstname_input" type="text" name="occupation" [(ngModel)]="guardianInfo.guardian_occupation" placeholder="Enter Guardian's Occupation" required="">
            <p class="error">
                Please Enter Guardian's Occupation
            </p>
        </div> -->
      <!-- <div class="inputGroup">       
            <p class="asLabel">Guardian's Phone Number<span class="redLab">*</span></p>         
            <input id="phone" type="text" mask="(000) 000 - 0000" name="phone" [(ngModel)]="guardianInfo.guardian_phone_number" placeholder="(201) 555-0123" required="">
            <div class="flagsWrapper">
                <div class="coutryFlags">
                  <div (click)="selectedFlag = flag; signUp.country = countryOptions[i].value"
                    *ngFor="let flag of flagOptions;index as i" class="singleFlag">
                    <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flag + '.png' " alt="country">
                    <p>{{countryOptions[i].text}}</p>
                  </div>
                </div>
                <div class="selectedFlag" *ngIf="selectedFlag">
                  <img [src]="'https://img.mobiscroll.com/demos/flags/' + selectedFlag + '.png'" alt="">
                  <svg width="6" height="3" viewBox="0 0 6 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M3 3L0.401924 1.25622e-08L5.59808 -4.417e-07L3 3Z" fill="#5E5E5E" />
                  </svg>
                </div>
              </div>
            <p class="error">
                Please Enter Valid Phone Number
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Guardian's Email<span class="redLab">*</span></p>
            <input id="email" class="firstname_input" type="email" name="email" [(ngModel)]="guardianInfo.guardian_email" placeholder="Enter Guardian's Email Address" required="">
            <p class="error">
                Please Enter Valid Email Address
            </p>
        </div> -->
      <!-- <div class="inputGroup">
            <p class="asLabel">Guardian's Mailing Address<span class="redLab">*</span></p>
            <input class="firstname_input" type="text" name="address" [(ngModel)]="guardianInfo.guardian_address" placeholder="Enter Guardian's Mailing Address*" required="">
            <p class="error">
                Please Enter Valid Mailing Address
            </p>
        </div> -->
      <!-- <div class="aloneUpload">
            <p class="asLabel">Mother’s CNIC copy or Guardianship Affidavit<span class="redLab">*</span></p>
            <div class="fileUpload">
                <input name="guardianaffidavit" type="file" id="guardianaffidavit" (change)="saveFile($event, 'guardianaffidavit')" accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf">
                <label for="guardianaffidavit">Upload File</label>
                <p class="fileName" [ngClass] ="guardianInfo?.guardianship_img?.name ? 'exists' : '' ">{{guardianInfo?.guardianship_img?.name ? guardianInfo.guardianship_img.name : (guardianInfo.guardianship_img ? guardianInfo.guardianship_img.split("/").pop() :  'No file choosen (max: 2mb)')}}</p>
                <p class="error" *ngIf="!guardianInfo.guardianship_img">
                    Please Upload Mother's CNIC Copy or Guardianship Affidavit
                </p>
            </div>
        </div> -->
      <!-- <div class="formBtns">        
            <button class="draftBtn" *ngIf="admissionForm.dirty && !editPage" (click)="saveDraft()">Save & Continue Later</button>
        </div> -->

      <p class="divisionHead">Most Recent Academic Qualification</p>
      <div class="inputGroup" *ngIf="class_applying == 'preCadet'">
        <p class="asLabel">Class<span class="redLab">*</span></p>
        <ng-select
          class="admissionSelect"
          [items]="classOptions"
          [(ngModel)]="gradeInfo.class"
          placeholder="Select Previous Class"
          name="class"
          [searchable]="false"
          [clearable]="false"
          required=""
          id="class"
          (change)="removeInvalid('#class')"
        >
        </ng-select>
        <p class="error">Please Select Class</p>
      </div>
      <div class="inputGroup" *ngIf="class_applying == 'elevenClass'">
        <p class="asLabel">Class<span class="redLab">*</span></p>
        <ng-select
          class="admissionSelect"
          [(ngModel)]="gradeInfo.class"
          placeholder="Select Previous Class"
          name="class"
          [searchable]="false"
          [clearable]="false"
          required=""
          id="class"
          (change)="removeInvalid('#class')"
          disabled
        >
        </ng-select>
        <p class="error">Please Select Class</p>
      </div>
      <div class="inputGroup">
        <p class="asLabel">Grade<span class="redLab">*</span></p>
        <ng-select
          class="admissionSelect"
          [items]="gradeOptions"
          [(ngModel)]="gradeInfo.grade"
          placeholder="Select Previous Grade"
          name="grade"
          [searchable]="false"
          [clearable]="false"
          required=""
          id="grade"
          (change)="removeInvalid('#grade')"
        >
        </ng-select>
        <p class="error">Please Select Grade</p>
      </div>

      <ng-container *ngIf="class_applying == 'elevenClass'">
        <div class="inputGroup">
          <p class="asLabel">Name of Board<span class="redLab">*</span></p>
          <input
            class="firstname_input"
            id="exam_board"
            type="text"
            name="exam_board"
            (keyup)="removeInvalid('#exam_board')"
            [(ngModel)]="classElevenFields.exam_board"
            placeholder="Enter Name of Board"
            required=""
          />
          <p class="error">Please Enter Board Name</p>
        </div>
        <div class="inputGroup">
          <p class="asLabel">Matric Exam year<span class="redLab">*</span></p>
          <input
            mask="0000"
            class="firstname_input"
            id="exam_year"
            type="text"
            name="exam_year"
            (keyup)="removeInvalid('#exam_year')"
            [(ngModel)]="classElevenFields.exam_year"
            placeholder="Enter Matric Exam Year"
            required=""
          />
          <p class="error">Please Enter Year of Matric</p>
        </div>
        <div class="inputGroup">
          <p class="asLabel">
            Marks obtained in 9th class<span class="redLab">*</span>
          </p>
          <input
            class="firstname_input"
            id="previous_marks"
            type="number"
            name="previous_marks"
            (keyup)="removeInvalid('#previous_marks')"
            [(ngModel)]="classElevenFields.previous_marks"
            placeholder="Enter Marks obtained in 9th class"
            required=""
          />
          <p class="error">Please Enter Marks</p>
        </div>
      </ng-container>

      <div class="aloneUpload">
        <p class="asLabel">
          {{
            class_applying == "elevenClass"
              ? "Upload mark sheet of 9th class"
              : "Copy of Most Recent Academic Result"
          }}<span class="redLab">*</span>
        </p>
        <div class="fileUpload cnicUpload">
          <input
            name="academicRecord"
            type="file"
            id="academicRecord"
            (change)="saveFile($event, 'academicRecord')"
            required=""
            accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
          />
          <label for="academicRecord">Upload File</label>
          <p
            class="fileName"
            [ngClass]="
              gradeInfo?.previous_academic_record?.name ? 'exists' : ''
            "
          >
            {{
              gradeInfo?.previous_academic_record?.name
                ? gradeInfo.previous_academic_record.name
                : gradeInfo.previous_academic_record
                ? gradeInfo.previous_academic_record.split("/").pop()
                : "No file choosen (max: 2mb)"
            }}
          </p>
          <p class="error" *ngIf="!gradeInfo.previous_academic_record">
            Please Upload Academic Record
          </p>
        </div>
      </div>

      <ng-container *ngIf="class_applying == 'elevenClass'">
        <p class="divisionHead">Additional Info</p>
        <div class="inputGroup">
          <p class="asLabel">Applying For FSC<span class="redLab">*</span></p>
          <ng-select
            class="admissionSelect"
            [items]="programmeOptions"
            [(ngModel)]="classElevenFields.program_applying"
            placeholder="Select Programme"
            name="programmeApplying"
            [searchable]="false"
            [clearable]="false"
            required=""
            id="programmeApplying"
            (change)="removeInvalid('#programmeApplying')"
          >
          </ng-select>
          <p class="error">Please Select Programme</p>
        </div>
        <div class="checkBoxWrapper">
          <p class="asLabel">Huffaz e Quran<span class="redLab">*</span></p>
          <div class="CBAll">
            <div class="singleRadio">
              <input
                type="radio"
                name="hafiz"
                id="yesHafiz"
                [(ngModel)]="classElevenFields.is_hafiz_e_quran"
                value="1"
              />
              <label class="radioLabel" for="yesHafiz">
                <span class="radioLabelBox">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span class="radioLabelText">Yes</span>
              </label>
            </div>
            <div class="singleRadio">
              <input
                type="radio"
                name="hafiz"
                id="noHafiz"
                [(ngModel)]="classElevenFields.is_hafiz_e_quran"
                value="0"
              />
              <label class="radioLabel" for="noHafiz">
                <span class="radioLabelBox">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span class="radioLabelText">No</span>
              </label>
            </div>
          </div>
        </div>
        <div class="checkBoxWrapper">
          <p class="asLabel">Son of Shaheed<span class="redLab">*</span></p>
          <div class="CBAll">
            <div class="singleRadio">
              <input
                type="radio"
                name="shaheed"
                id="yesShaheed"
                [(ngModel)]="classElevenFields.is_son_of_shaheed"
                value="1"
              />
              <label class="radioLabel" for="yesShaheed">
                <span class="radioLabelBox">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span class="radioLabelText">Yes</span>
              </label>
            </div>
            <div class="singleRadio">
              <input
                type="radio"
                name="shaheed"
                id="noShaheed"
                [(ngModel)]="classElevenFields.is_son_of_shaheed"
                value="0"
              />
              <label class="radioLabel" for="noShaheed">
                <span class="radioLabelBox">
                  <svg
                    width="11"
                    height="11"
                    viewBox="0 0 11 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.87329C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z"
                      fill="white"
                    />
                  </svg>
                </span>
                <span class="radioLabelText">No</span>
              </label>
            </div>
          </div>
        </div>
      </ng-container>

      <div class="inputGroup">
        <p class="asLabel">Choose Test Centre<span class="redLab">*</span></p>
        <ng-select
          class="admissionSelect"
          [items]="centerOptions"
          [(ngModel)]="preferred_test_center"
          placeholder="Choose Test Centre"
          name="testCentre"
          [searchable]="false"
          [clearable]="false"
          required=""
          id="testCentre"
          bindValue="id"
          bindLabel="name"
          (change)="removeInvalid('#testCentre')"
        >
        </ng-select>
        <p class="error">Please Select Test Center</p>
      </div>
    </div>

    <div class="formBtns">
      <div class="twoTogether">
        <button
          class="draftBtn"
          (click)="saveDraft()"
          *ngIf="admissionForm.dirty && !editPage"
        >
          Save & Continue Later
        </button>
        <button
          *ngIf="!loading"
          class="nextBtn actionBtn"
          (click)="submitApplication()"
        >
          Next
        </button>
      </div>
    </div>
  </form>
</div>
<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img
    id="loaderStatus"
    class="loaderImage"
    alt="logo"
    src="assets/new-images/blue_psh_logo.png"
  />
</div>

<section class="notElligibleModal" *ngIf="unelligible">
  <div class="overlay"></div>
  <div class="notElligibleContent">
    <!-- <img src="assets/new-images/not-eligible.gif" alt="not eligible gif"> -->
    <h2>You are not Eligible to Apply</h2>
    <p>
      Sorry for the Inconvenience but you are not eligible to apply as you don’t
      match our age criteria.
    </p>
    <a href="javascript:;" routerLink="/">Okay!</a>
  </div>
</section>
