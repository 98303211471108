import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MoreChildrenComponent } from '../../sponsor-child-profile/more-children/more-children.component';
declare var $;

@Component({
  selector: 'app-your-sponsorships',
  templateUrl: './your-sponsorships.component.html',
  styleUrls: ['./your-sponsorships.component.css']
})
export class YourSponsorshipsComponent implements OnInit {

  @Input() oldSponsorships;
  @Input() currencyCode;
  @Input() exchangeRate;
  @Input() pageType;
  @ViewChild('moreChildrenComponent', { static: false }) moreChildrenComponent: MoreChildrenComponent;
  @Output() sponsorshipChangeEvent = new EventEmitter();
  @Output() stepChangeEvent = new EventEmitter();

  public orphan;
  public moreChildren;
  public sponsorships : any = [];
  public otherDonation;
  public otherDonationExists = false;
  public otherDonationObj = {
                                type          : 'Other programs of Pakistan sweet home',
                                price         : null
                            };

  public disabledBtn = false;
  public defaultTimePeriod = 'yearly'; //monthly, yearly
  public defaultPrice = 99;
  public membership = {
      name          : 'Membership',
      // image         : orphan.child_picture,
      type          : 'Special Friends Club',
      timePeriod    : 'yearly',
      price         : 365
    }

  constructor(
    private router : Router
  ) { }



  ngOnInit(): void {
      if(this.pageType == 'sponsorship'){
        this.getOrphan();
        console.log("default Price",this.defaultPrice)

      } else {
        this.sendMembership();
      }

  }

  getOrphan(){
    if( localStorage.getItem('orphan') && localStorage.getItem('moreChildren') ){
      var orphan = localStorage.getItem('orphan');
      console.log("orphan:::",JSON.parse(orphan))

      var moreChildren = localStorage.getItem('moreChildren');

      this.orphan = JSON.parse(orphan);
      if(this.orphan.sponsorship_type == 'cadet'){
        this.defaultPrice = 149;
      }else if(this.orphan.sponsorship_type == 'day-scholar'){
        this.defaultPrice = 59;
      }

      this.moreChildren = JSON.parse(moreChildren);

      if(!this.oldSponsorships){
        this.setChildSponsorships(this.orphan);
      } else {
        this.sponsorships = this.oldSponsorships;
      }

    } else {
      this.router.navigate(['/orphan-profiles']);
    }
  }

  setChildSponsorships(orphan){
    console.log("setChildSponsorships Orphan::::",orphan);
    if(orphan['sponsorship_type']=='cadet' || orphan['sponsorship_type']=='university'){
      this.defaultPrice = 149;
    }else if(orphan['sponsorship_type']=='day-scholar'){
      this.defaultPrice = 59;
    }else{
      this.defaultPrice = 99;
    }
    var obj = {
      id            : orphan.id,
      name          : orphan.name,
      image         : orphan.child_picture,
      type          : 'Child Sponsorship',
      timePeriod    : this.defaultTimePeriod,
      price         : this.defaultTimePeriod == 'monthly' ? this.defaultPrice : this.defaultPrice * 12
    }
    if(!this.otherDonationExists){
      this.sponsorships.push(obj);
    } else {
      this.sponsorships.splice(this.sponsorships.length - 1, 0, obj);
    }

    setTimeout(() => {
      var targetElement = $('.increaseImpactWrapper');
      var scrollToPosition = targetElement.position().top;

      $('html, body').animate({
        scrollTop: scrollToPosition
      }, 10);

      this.sendSponsorship();
    }, 1);

  }

  removePlan(i){
    this.moreChildrenComponent.removeFromEmittedChild(this.sponsorships[i].id);
    this.sponsorships.splice(i, 1);
    this.sendSponsorship();
  }

  changeTimePeriod(i){
    // this.sponsorships[i].timePeriod = this.sponsorships[i].timePeriod == 'monthly' ? 'yearly' : 'monthly';
    // this.sponsorships[i].price = this.sponsorships[i].timePeriod == 'monthly' ? this.sponsorships[i].price / 12 : this.sponsorships[i].price * 12;

    for (let i = 0; i < this.sponsorships.length; i++) {
      this.sponsorships[i].timePeriod = this.sponsorships[i].timePeriod == 'monthly' ? 'yearly' : 'monthly';
      this.sponsorships[i].price = this.sponsorships[i].timePeriod == 'monthly' ? this.sponsorships[i].price / 12 : this.sponsorships[i].price * 12;
    }
    this.defaultTimePeriod = this.sponsorships[i].timePeriod;

    this.sendSponsorship();
  }

  addOtherDonation(){
    if(!this.otherDonationExists){
      this.otherDonationObj.price = this.otherDonation;
      this.sponsorships.push(this.otherDonationObj);
      this.otherDonationExists = true;
    }  else {
      this.otherDonationObj.price = this.otherDonation;
      this.sponsorships[this.sponsorships.length - 1].price = this.otherDonation;
    }
    this.sendSponsorship();
  }

  removeOtherDonations(){
    this.otherDonationObj.price = null;
    this.otherDonation = null;
    this.sponsorships.pop();
    this.otherDonationExists = false;
    this.sendSponsorship();
  }

  sendSponsorship(){
    this.sponsorshipChangeEvent.emit(this.sponsorships);
  }
  sendMembership(){
    var arr = [];
    arr.push(this.membership)
    this.sponsorshipChangeEvent.emit(arr);
  }

  changeStep(stepName){
    this.disabledBtn = true;
    setTimeout(() => {
      this.stepChangeEvent.emit(stepName);
      this.disabledBtn = false;
    }, 1000);

  }

}
