<div class="informationWrapper" *ngIf="admissionInfo">
    <div class="candidateInfoWrapper">
        <h2 class="infoWrapperHead">Candidate Information</h2>
        <div class="contentWrapper">
            <div class="singleInfo">
                <p class="infoLabel">Name:</p>
                <p class="infoValue">{{ admissionInfo.name }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Candidate CNIC:</p>
                <p class="infoValue">{{ admissionInfo.cnic }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Father's Name:</p>
                <p class="infoValue">{{ admissionInfo.father_name }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Date of Birth:</p>
                <p class="infoValue">{{ admissionInfo.dob }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Gender:</p>
                <p class="infoValue">{{ admissionInfo.gender }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Phone Number:</p>
                <p class="infoValue">{{ admissionInfo.phone_number }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Whatsapp Number:</p>
                <p class="infoValue">{{ admissionInfo.whatsapp_number }}</p>
            </div>            
            <div class="singleInfo" *ngIf="admissionInfo.address">
                <p class="infoLabel">Address:</p>
                <p class="infoValue">{{ admissionInfo.address }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Any health/medical issue:</p>
                <p class="infoValue">{{ admissionInfo.health_medical_issue == '0' ? 'No' : admissionInfo.health_issue_name }}</p>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">CNIC Attachment:</p>
                <a target="_blank" *ngIf="admissionInfo.cnic_img" [href]="admissionInfo.cnic_img" class="infoValue">{{ admissionInfo.cnic_img.replace('https://www.pakistansweethome.org.pk/portal/upload/admission/cnic//','') }}</a>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Candidate Photo:</p>
                <a target="_blank" *ngIf="admissionInfo.candidate_photo" [href]="admissionInfo.candidate_photo" class="infoValue">{{ admissionInfo.candidate_photo.replace('https://www.pakistansweethome.org.pk/portal/upload/admission/profile_img//','') }}</a>
            </div>
            <div class="singleInfo">
                <p class="infoLabel">Father Death Certificate:</p>
                <a target="_blank" *ngIf="admissionInfo.father_death_certificate" [href]="admissionInfo.father_death_certificate" class="infoValue">{{ admissionInfo.father_death_certificate.replace('https://www.pakistansweethome.org.pk/portal/upload/admission/death_certificate//','') }}</a>
            </div>
        </div>
    </div>
    <div class="rightSideWrapper">
        <div class="academicInfoWrapper">
            <h2 class="infoWrapperHead mt">Academic Qualification</h2>
            <div class="contentWrapper">
                <div class="singleInfo">
                    <p class="infoLabel">Previous Class:</p>
                    <p class="infoValue">{{ admissionInfo.previous_class }}</p>
                </div>
                <div class="singleInfo">
                    <p class="infoLabel">Previous Grade:</p>
                    <p class="infoValue">{{ admissionInfo.previous_grade }}</p>
                </div>
                <div class="singleInfo" *ngIf="admissionInfo.exam_board">
                    <p class="infoLabel">Name of Board:</p>
                    <p class="infoValue">{{ admissionInfo.exam_board }}</p>
                </div>
                <div class="singleInfo" *ngIf="admissionInfo.exam_year">
                    <p class="infoLabel">Matric Exam year:</p>
                    <p class="infoValue">{{ admissionInfo.exam_year }}</p>
                </div>
                <div class="singleInfo" *ngIf="admissionInfo.previous_marks">
                    <p class="infoLabel">Marks obtained in 9th class:</p>
                    <p class="infoValue">{{ admissionInfo.previous_marks }}</p>
                </div>
                <div class="singleInfo">
                    <p class="infoLabel">Attachment:</p>
                    <a target="_blank" *ngIf="admissionInfo.previous_academic_record" [href]="admissionInfo.previous_academic_record" class="infoValue">{{ admissionInfo.previous_academic_record.replace('https://www.pakistansweethome.org.pk/portal/upload/admission/academic_record//','') }}</a>
                </div>   
            </div>            
        </div>
        <div class="otherInforWrapper" *ngIf="admissionInfo.program_applying">
            <h2 class="infoWrapperHead mt">Additional Info</h2>
            <div class="contentWrapper">
                <div class="singleInfo" *ngIf="admissionInfo.program_applying">
                    <p class="infoLabel">Applying For FSC:</p>
                    <p class="infoValue">{{ admissionInfo.program_applying }}</p>
                </div>
                <div class="singleInfo" *ngIf="admissionInfo.is_hafiz_e_quran">
                    <p class="infoLabel">Huffaz e Quran:</p>
                    <p class="infoValue">{{ admissionInfo.is_hafiz_e_quran == '0' ? 'No' : 'Yes' }}</p>
                </div>
                <div class="singleInfo" *ngIf="admissionInfo.is_son_of_shaheed">
                    <p class="infoLabel">Son of Shaheed:</p>
                    <p class="infoValue">{{ admissionInfo.is_son_of_shaheed == '0' ? 'No' : 'Yes' }}</p>
                </div>
            </div>
        </div>
    </div>
    <div class="submitBtnWrapper" *ngIf="reviewPage == 'review'">
        <button class="editBtn" (click)="editApplicationPage(formId)">Edit Details</button>
        <button class="submitBtn" (click)="thanksPopupShow = true">Submit</button>
    </div>
    <div class="submitBtnWrapper" *ngIf="reviewPage == 'view'">
        <button class="editBtn rejectBtn" (click)="rejected = true">Reject</button>
        <button class="submitBtn">Issue Roll Number Slip</button>
    </div>
</div>

<section class="thanksPopup" *ngIf="thanksPopupShow">
    <div class="overlay"></div>
    <div class="thanksModal">        
        <img class="tickGif" src="/assets/new-images/tickAnim.webp" alt="tick">
        <p class="thanksHead">Thank you for applying!</p>
        <p class="thanksDesc">
            Our Admissions Committee is currently in the process of taking applications
            and will begin reviewing them.
        </p>
        <p class="botText thanksDesc">
            If you are among the shortlisted candidates, we will contact you <br>
            via email or phone.
        </p>
        <div class="thanksBtnWrapper">
            <a class="thanksOKBtn" href="javascript:;" routerLink="/admission/student-application">Okay</a>
            <a class="downloadOKBtn" *ngIf="admissionInfo.class_applying == 'preCadet' " href="/assets/sample-tests/Model-Papers-Pre-Cadet-Class-8th.pdf" download>Download Past Exam Papers</a>
        </div>
    </div>
</section>

<section class="notElligibleModal" *ngIf="rejected">
    <div class="overlay" (click)="rejected = false"></div>
    <div class="notElligibleContent">
        <h2>Are you sure you want to Reject this Application?</h2>
        <textarea name="comment" [(ngModel)]="adminAction.comment" placeholder="Enter Comment"></textarea>
        <p class="error">
            Please Add Comment
        </p>
        <div class="rejectBtnsWrapper">
            <button class="dontReject" (click)="rejected = false">No</button>
            <button (click)="rejectApplication(admissionInfo.id)">Yes</button>
        </div>
    </div>
</section>