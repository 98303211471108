<section class="dashboardHeader">
  <div class="container">
      <div class="studentHead">
          <div class="dashTop">
              <div class="Studentimg">
                  <!-- <img [src]="currentUser.imgUrl ? currentUser.imgUrl : 'assets/images/emptyStudentAvatar.png'" alt="student image"> -->
                  <!-- <div class="absoluteimg">
                      <a href="javascript:;">
                          <svg width="38" height="38" viewBox="0 0 38 38" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <circle cx="19" cy="19" r="17.5" fill="#003366" stroke="white" stroke-width="3" />
                              <g clip-path="url(#clip0_2560_2580)">
                                  <path
                                      d="M26.3716 16.25C26.7747 15.8469 26.9997 15.3094 26.9997 14.7375C26.9997 14.1656 26.7778 13.6281 26.3716 13.225L24.7778 11.6313C24.3716 11.2219 23.8341 11 23.2622 11C22.6903 11 22.1528 11.2219 21.7497 11.6281L11.8091 21.5688C11.2528 22.125 10.9591 22.8906 11.0028 23.675L10.9997 26.2188C10.9997 26.425 11.0809 26.625 11.2278 26.7719C11.3747 26.9188 11.5716 27 11.7809 27L14.3247 26.9969C15.1091 27.0406 15.8747 26.7469 16.4309 26.1906C16.7372 25.8844 16.7372 25.3906 16.4309 25.0844C16.1247 24.7781 15.6309 24.7781 15.3247 25.0844C15.0809 25.3281 14.7403 25.4563 14.3966 25.4344C14.3809 25.4344 14.3622 25.4313 14.3466 25.4313L12.5622 25.4375L12.5653 23.6531C12.5653 23.6375 12.5653 23.6188 12.5622 23.6031C12.5403 23.2594 12.6684 22.9188 12.9122 22.675L21.1966 14.3906L23.6091 16.8031L17.3841 23.0281C17.0778 23.3344 17.0778 23.8281 17.3841 24.1344C17.6903 24.4406 18.1841 24.4406 18.4903 24.1344L26.3716 16.25ZM22.3028 13.2844L22.8559 12.7313C22.9653 12.6219 23.1091 12.5625 23.2653 12.5625C23.4184 12.5625 23.5653 12.6219 23.6747 12.7313L25.2684 14.325C25.4934 14.55 25.4934 14.9188 25.2684 15.1438L24.7153 15.6969L22.3028 13.2844Z"
                                      fill="white" />
                                  <path
                                      d="M26.2188 25.4375H19.1719C18.7406 25.4375 18.3906 25.7875 18.3906 26.2188C18.3906 26.65 18.7406 27 19.1719 27H26.2188C26.65 27 27 26.65 27 26.2188C27 25.7875 26.65 25.4375 26.2188 25.4375Z"
                                      fill="white" />
                              </g>
                              <defs>
                                  <clipPath id="clip0_2560_2580">
                                      <rect width="16" height="16" fill="white" transform="translate(11 11)" />
                                  </clipPath>
                              </defs>
                          </svg>

                      </a>
                  </div> -->
              </div>
              <div class="personalInformation">
                  <div class="StudentInfo">
                      <p class="profileInfo">Welcome, {{currentUser.firstName ? currentUser.firstName : currentUser.name}}!</p>
                      <p class="personalInof">Your personalized dashboard will allow you to view and track all your
                          applications
                          in
                          one place.</p>
                  </div>
              </div>
          </div>
          <!-- <div class="menu userDropdown">
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1583_3019)">
                      <path
                          d="M1.21582 4.94112H18.7845C19.4548 4.94112 20 4.39597 20 3.72558C20 3.0552 19.4548 2.50977 18.7845 2.50977H1.21582C0.545437 2.50977 0 3.0552 0 3.72558C0 4.39597 0.545437 4.94112 1.21582 4.94112Z"
                          fill="#221F1F" />
                      <path
                          d="M18.7845 8.78418H1.21582C0.545437 8.78418 0 9.32962 0 10C0 10.6704 0.545437 11.2155 1.21582 11.2155H18.7845C19.4548 11.2155 20 10.6704 20 10C20 9.32962 19.4548 8.78418 18.7845 8.78418Z"
                          fill="#221F1F" />
                      <path
                          d="M18.7845 15.0586H1.21582C0.545438 15.0586 0 15.6043 0 16.2747C0 16.9451 0.545438 17.4902 1.21582 17.4902H18.7845C19.4548 17.4902 20 16.9451 20 16.2747C20 15.6043 19.4549 15.0586 18.7845 15.0586Z"
                          fill="#221F1F" />
                  </g>
                  <defs>
                      <clipPath id="clip0_1583_3019">
                          <rect width="20" height="20" fill="white" />
                      </clipPath>
                  </defs>
              </svg>
              <div class="userDdBody">
                  <a routerLink="/admission/student-application" href="javascript:;">Application</a>
                  <a routerLink="/admission/student-dashboard" href="javascript:;">Edit Profile</a>
                  <a routerLink="/logout" href="javascript:;">Log out</a>
              </div>
          </div> -->
      </div>
  </div>
</section>
<section class="formSection">
  <div class="container">
    <div class="formWraper">
      <form #profileForm="ngForm" (change)="formChanged()">
        <p class="serverSuccess" *ngIf="serverSuccess">
          <img src="/assets/new-images/successTick.png" alt="success sign">
          {{serverSuccess}}
        </p>
        <div class="inputGroup">
          <label for="">Name</label>
          <input type="text" placeholder="Name" id="userName" name="userName" [(ngModel)]="profile.name" >
        </div>
        <div class="inputGroup">
          <label for="">Email Address</label>
          <input type="text" placeholder="Email" id="email" name="email" [(ngModel)]="profile.email" disabled>
        </div>

        <div class="inputGroup">
          <label for="">Phone Number</label>        
        <div class="doubleDiv">
          <div class="flagInput">
            <div class="flagsWrapper">
              <div class="coutryFlags" >
                <div (click)="selectedFlag = flag; selectedCountry = countryOptions[i]" *ngFor="let flag of flagOptions;index as i" class="singleFlag">
                  <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flag + '.png' " alt="country">
                  <p>{{countryOptions[i]}}</p>
                </div>
              </div>
              <div class="selectedFlag" *ngIf="selectedFlag">
                <img [src]="'https://img.mobiscroll.com/demos/flags/' + selectedFlag + '.png'" alt="">
                <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z" fill="#5E5E5E"/>
                </svg>                    
              </div>
          </div>
          </div>
          <div class="phoneNumber">
            <input type="text" (keyup)="checkPhoneNumber()" mask="(000) 0000000" placeholder="(3xx) 5550123" id="phone" name="phone" [(ngModel)]="profile.phone_number">
          </div>
        </div>
        </div>
        <div class="passwordDiv" *ngIf="!showChangePasswordFields">
          <div class="inputGroup" >
            <label for="password" >Password:</label>
            <input name="password" value="********" type="{{ showPassword ? 'text' : 'password' }}" disabled>
          </div>
          <span class="changePassword">
            <a href="javascript:;" (click)="changePassModal = true; fixBody();">Change Password</a>
          </span>
        </div>

        <div *ngIf="showChangePasswordFields">
            <div class="inputGroup">
            <label for="current-password">Current Password:</label>
            <input type="password" type="{{ showPassword ? 'text' : 'password' }}"  name="current-password" [(ngModel)]="currentPassword">
            </div>
            <div class="inputGroup">
            <label for="new-password">New Password:</label>
            <input type="password" name="new-password" type="{{ showPassword ? 'text' : 'password' }}" [(ngModel)]="newPassword">
            </div>
            <div class="inputGroup">
            <label for="confirm-password">Confirm Password:</label>
            <input type="password" name="confirm-password"  type="{{ showPassword ? 'text' : 'password' }}" [(ngModel)]="confirmPassword">
            </div>
            <div class="checkboxGroup">
              <input type="checkbox" class="checkbox"  name="checkbox" [(ngModel)]="showPassword">
              <label for="" >show Password</label>
            </div>
        </div>
        
        <div class="formbtns">
          <button class="cancel_btn" (click)="resetForm();">Cancel</button>
          <button class="confirmBtn" [disabled]="!profileForm.dirty" (click)="updateProfile(profileForm);">Confirm</button>
        </div>
      </form>
    </div>
  </div>

</section>

<section class="updatePassPop" *ngIf="changePassModal">
  <div class="overlay" (click)="changePassModal = false; unfixBody()"></div>
  <div class="updatePassModal">
    <p class="serverError" *ngIf="passChangeError">
      <img src="/assets/new-images/error-sign-new.png" alt="error sign">
      {{passChangeError}}
    </p>
    <p class="serverSuccess" *ngIf="passChangeSuccess">
      <img src="/assets/new-images/successTick.png" alt="success sign">
      {{passChangeSuccess}}
    </p>
    <p class="modalName">Update Password</p>
    <form #updatePassForm="ngForm" class="updatePassForm">
      <div class="passFormWrapper">
        <div class="modalInWrapper">
          <input type="password" name="old_password" [(ngModel)]="passChange.old_password"
            placeholder="Current Password" />
        </div>
        <div class="modalInWrapper">
          <input type="password" name="new_password" [(ngModel)]="passChange.password" placeholder="New Password" />
        </div>
        <div class="modalInWrapper">
          <input type="password" name="repeat_password" [(ngModel)]="passChange.confirmPass"
            placeholder="Confirm New Password" />
        </div>
      </div>
      <button *ngIf="!passChangeLoad" class="changePassBtn" (click)="changepass()" [disabled]="!updatePassForm.dirty" >Change Password</button>
      <div *ngIf="passChangeLoad" class="loader">
        <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
      </div>
    </form>
  </div>
</section>
