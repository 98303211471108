import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-articles',
  templateUrl: './articles.component.html',
  styleUrls: ['./articles.component.css']
})
export class ArticlesComponent implements OnInit {

  public articlePages : any = [
                                'federal-education-minister-announced-pkr-6-crore-for-orphans',
                                'golden-opportunity-for-orphans-nationwide',
                                'government-of-pakistan-gifted-four-toyota-hiace-vans',
                                'world-boxing-champion-usman-wazir-to-establish-boxing-academy',
                                'zamurrad-khan-inaugurates-a-new-building',
                                'fun-filled-trip-arranged-for-sargodha-campus-children',
                                'joyful-trip-on-the-double-decker-sightseeing-bus',
                                'raja-pervaiz-ashraf-inaugurates-a-new-hostel-building-at-cadet-college',
                                'a-heartwarming-sehri-event',
                                'annual-result-day',
                                'highlights-of-ramadan-2024',
                                'bridging-borders-with-kindness',
                                'empowering-orphans-through-education',
                              ]
  public pageURL      : any = ''

  constructor(
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.pageURL = id;
      if(!this.articlePages.includes(id)){
        this.router.navigate(['']);
      }
    });
   }

  ngOnInit(): void {
  }

}
