import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-government-of-pakistan-gifted',
  templateUrl: './government-of-pakistan-gifted.component.html',
  styleUrls: ['./government-of-pakistan-gifted.component.css' , '../articles.component.css']
})
export class GovernmentOfPakistanGiftedComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Government of Pakistan Gifted Four Toyota Hiace Vans","The Government of Pakistan gifted four Toyota Hiace vans to Pakistan Sweet Home to improve transport for orphaned children.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
