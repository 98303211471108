import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services/seo.service';
declare var $;

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.css']
})
export class FaqComponent implements OnInit {

  public faqs : any = [
                        {
                          'question' : 'What is Pakistan Sweet Home?',
                          'answer'   : ['Pakistan Sweet Home is a non-profit organization dedicated to providing comprehensive care and support for orphaned children in Pakistan. We are committed to creating a nurturing environment where orphans can thrive and have access to education, healthcare, and various skill-building opportunities.'],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'What is the mission of Pakistan Sweet Home?',
                          'answer'   : ['Our mission is to uplift and empower orphaned children by providing them with a secure and nurturing environment. We strive to offer high-quality education, health services, and psychosocial support to prepare them for a self-sufficient and successful future.'],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'When was Pakistan Sweet Home established?',
                          'answer'   : ['Pakistan Sweet Home was established in 2007, with a vision to transform the lives of orphaned children in Pakistan.'],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'Where are Pakistan Sweet Home facilities located?',
                          'answer'   : ['We have multiple facilities across Pakistan. We have 𝟎5 𝐨𝐩𝐞𝐫𝐚𝐭𝐢𝐨𝐧𝐚𝐥 centers i.e', '1. Pakistan Sweet Home, Islamabad', '2. Pakistan Sweet Home Golden Arrow North Waziristan', '3. Pakistan Sweet Home Bhalwal, Sargodha ',
                                        '4. Pakistan Sweet Home Cadet College, Gujar Khan,', '5. Pakistan Sweet Home, Sukkur,' , 'and 𝟎𝟐 𝐮𝐩𝐜𝐨𝐦𝐢𝐧𝐠 projects i.e', 'Pak-Kashmir Cadet College Rehara, Rawalakot and Pakistan Sweet Home, Karachi'],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'What programs does Pakistan Sweet Home offer?',
                          'answer'   : ["We offer a variety of programs including regular and cadet class admissions, a Education Sponsorship program, and a sponsorship program for individuals to support a child's education and overall well-being."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'How is Pakistan Sweet Home funded?',
                          'answer'   : ["We rely on the generous contributions from individuals, corporations, and grants from various organizations. You can support us by donating or sponsoring a child."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'How many children does Pakistan Sweet Home support?',
                          'answer'   : ["We support thousands of children and the number is always growing as we expand our operations and increase our resources."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'How can I stay updated with the activities and progress of Pakistan Sweet Home?',
                          'answer'   : ["You can stay connected with us by subscribing to our newsletter, following us on social media, or regularly visiting our 'Updates' page on the website."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'How can I get involved with Pakistan Sweet Home?',
                          'answer'   : ["There are several ways to get involved. You can donate, sponsor a child, volunteer your time, host a fundraising event, or even request a site tour to visit our facilities and meet the children."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'How does Pakistan Sweet Home ensure the safety and well-being of the children?',
                          'answer'   : ["Child safety and well-being is our top priority. We have a dedicated staff responsible for the care and protection of the children. Regular check-ups, counseling sessions, nutritious meals, and a structured learning environment ensure that the children are healthy, safe, and progressing well."],                          
                          'tag'      : 'About Pakistan Sweet Home'
                        },
                        {
                          'question' : 'Who can apply for admission to Pakistan Sweet Home?',
                          'answer'   : ["We welcome applications from orphaned children across Pakistan. The specific age groups and educational requirements for our different programs can be found on our respective admissions pages."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'What is the procedure for admission?',
                          'answer'   : ["The first step in the admission procedure is to complete our online application form. Following this, there will be an assessment of the application and potentially an interview. Detailed instructions can be found on the respective admissions pages."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'What documents are required for the application?',
                          'answer'   : ["We require proof of the child's orphan status, age (Birth Certificate), academic records, and any other relevant documents that can support the application. A detailed list is provided on the respective admissions pages."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'Is there a fee for the application?',
                          'answer'   : ["No, there is no fee to apply for admission to Pakistan Sweet Home."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'How are students selected for admission?',
                          'answer'   : ["Selection is based on a review of the submitted application, the child's needs, and their potential to benefit from our programs. The selection process is further described on respective admissions pages."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'Can I apply for admission to both the PSH Boarding Schools and Cadet College?',
                          'answer'   : ["Selection is based on a review of the submitted application, the child's needs, and their potential to benefit from our programs. The selection process is further described on respective admissions pages."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'What happens after the selection process?',
                          'answer'   : ["Once the selection process is complete, you will be contacted by our admissions team. If the child is selected, we will provide you with the necessary information to proceed."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'How can I contact the Admissions Office for more information?',
                          'answer'   : ["You can reach out to our Admissions Office via the contact information provided on our 'Contact Us' page. We are here to help answer any questions you might have."],                          
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'Is there a waitlist if my application isn’t immediately accepted?',
                          'answer'   : ["If we receive more qualified applications than available spots, a waitlist may be implemented. You will be notified of your status after the application review."],
                          'tag'      : 'Admissions'
                        },
                        {
                          'question' : 'What is the Scholarship Program?',
                          'answer'   : ["Our Scholarship Program is a merit-based initiative designed to support the educational expenses of bright, orphaned students across Pakistan who consistently excel in their academics."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'Who is eligible for the Scholarship Program?',
                          'answer'   : ["The Scholarship Program is open to orphaned students residing in Pakistan who have consistently achieved grades of 90% or above in their school or college and have passed the Matriculation Examination (10th Grade)."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'What expenses does the Scholarship Program cover?',
                          'answer'   : ["The scholarship covers the costs of tuition, textbooks, stationery, and transportation, aiming to alleviate the financial burden of higher education."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'How can I apply for the Scholarship Program?',
                          'answer'   : ["Eligible students can apply for our Scholarship Program by filling out the application form available on our website. The application includes providing personal information, academic records, and proof of orphan status."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'What is the selection process for the Scholarship Program?',
                          'answer'   : ["The selection process includes a thorough review of the application, academic records, and potentially an interview. The program is merit-based, hence priority is given to those with the highest academic achievements."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'When is the deadline for scholarship applications?',
                          'answer'   : ["Deadlines for scholarship applications are posted on our website and may vary year to year. We encourage students to apply as early as possible."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'Can I reapply for a scholarship each academic year?',
                          'answer'   : ["Yes, students can apply for the scholarship each academic year. However, the renewal of the scholarship depends on the continuous exemplary academic performance of the student."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'What academic standards must I maintain to keep my scholarship?',
                          'answer'   : ["Scholarship recipients are expected to maintain a grade of 90% or above in their college or university. The specific requirements will be outlined in the scholarship agreement."],
                          'tag'      : 'Scholarship Program'
                        },
                        {
                          'question' : 'What is the Sponsorship Program?',
                          'answer'   : ["Our Sponsorship Program allows you to make a significant difference in an orphan's life by sponsoring their education and living expenses. With your monthly contribution, you provide an orphan with education, healthcare, nutritious meals, and emotional support."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : 'How much does it cost to sponsor a child?',
                          'answer'   : ["It costs $99 per month to sponsor a child through our program."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : 'How does my sponsored child benefit from my donation?',
                          'answer'   : ["Your monthly donation provides for the child's education, healthcare, nutrition, and living expenses. This stable support helps the child grow and develop in a secure environment and creates a foundation for a brighter future."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : 'Can I communicate with the child I sponsor?',
                          'answer'   : ["Yes, sponsors are encouraged to build a bond with their sponsored child. You can communicate with the child through letters, emails, or even video calls, arranged by the Sponsor and Orphan Relationship Manager."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "Can I see the sponsored child's progress?",
                          'answer'   : ["Absolutely! As a sponsor, you'll receive regular updates on your sponsored child's academic performance, personal development, and overall well-being."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "Can I send gifts to my sponsored child?",
                          'answer'   : ["While sending personal gifts is generally discouraged due to logistical issues, sponsors can contribute to a gift fund. This fund ensures that your sponsored child and other children receive special gifts during holidays or their birthdays."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "Can I meet my sponsored child?",
                          'answer'   : ["Yes, sponsors can arrange visits to meet their sponsored child. However, these visits are coordinated under the organization's guidelines and procedures to ensure the safety and well-being of the child."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "Can I sponsor more than one child?",
                          'answer'   : ["Absolutely, you can sponsor as many children as you wish. Your generosity can help transform the lives of multiple children."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "What if I need to stop my sponsorship?",
                          'answer'   : ["We understand that circumstances change. If you need to stop your sponsorship for any reason, you can do directly from your account on the website or you can contact your Sponsor and Orphan Relationship Manager. They will guide you through the process and discuss other ways you might support our work."],
                          'tag'      : 'Sponsorship'
                        },
                        {
                          'question' : "What is the difference between a one-time and monthly donation?",
                          'answer'   : ["A one-time donation is a single, lump-sum contribution to our organization. A monthly donation is a recurring contribution that you commit to give every month, providing consistent support to our cause."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How do I make a one-time donation?",
                          'answer'   : ["You can make a one-time donation by clicking on the 'Donate' button on our website, selecting the 'One-time' option, and following the prompts to complete your donation."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How do I set up a monthly donation?",
                          'answer'   : ["To set up a monthly donation, select the 'Monthly' option when you click on the 'Donate' button on our website. Follow the prompts to set up your recurring contribution."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "Can I change or cancel my monthly donation?",
                          'answer'   : ["Yes, you can change or cancel your monthly donation at any time from your account dashboard on our website."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How can I update my profile information or payment details?",
                          'answer'   : ["You can update your profile information, payment details, and other account settings directly from your account dashboard on our website."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "Is my donation tax-deductible?",
                          'answer'   : ["Yes, all donations to our organization are tax-deductible. After making a donation, you will receive a receipt via email that you can use for tax purposes."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How is my donation used?",
                          'answer'   : ["Your donation is used to support the many needs of the orphans we serve, including education, healthcare, nutrition, and living expenses. You can view detailed reports of our expenditure on our website."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "What payment methods do you accept for donations?",
                          'answer'   : ["We accept various payment methods, including credit/debit card payments and bank transfers."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How secure is my donation?",
                          'answer'   : ["We take your security very seriously. Our website uses secure encryption technology to protect your personal information and your payment details."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "Where can I view my donation history?",
                          'answer'   : ["You can view your entire donation history directly from your account dashboard on our website."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "Can I donate in memory or in honor of someone?",
                          'answer'   : ["Yes, you can make a donation in memory or in honor of someone. Please select the appropriate option when making your donation and provide the necessary information. We can send an acknowledgement to the person being honored or the family of the person being remembered upon request."],
                          'tag'      : 'Donations'
                        },
                        {
                          'question' : "How can I volunteer for Pakistan Sweet Home?",
                          'answer'   : ["You can express your interest in volunteering by filling out the volunteer application form available on our website. Once submitted, a member of our team will contact you to discuss the opportunities available that align with your interests and skills."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "Do I need any special skills to volunteer?",
                          'answer'   : ["While certain volunteer roles may require specific skills, we welcome all who are passionate about making a difference in the lives of our children. Our team will work with you to find a volunteer position that suits your skill set and interests."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "Can I organize a fundraising event for Pakistan Sweet Home?",
                          'answer'   : ["Absolutely! Your efforts to organize a fundraising event are highly appreciated. Please reach out to us via the contact information on our website, and our team will provide you with the necessary guidance and resources."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "Are there any guidelines for hosting a fundraising event?",
                          'answer'   : ["Yes, there are certain guidelines to ensure the event aligns with our mission and values. Once you express interest in hosting a fundraiser, our team will provide you with the necessary guidelines and resources."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "What will the funds raised be used for?",
                          'answer'   : ["The funds raised will be used to support the many needs of the children at Pakistan Sweet Home, including education, healthcare, housing, food, and more."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "Can I invite someone from Pakistan Sweet Home to speak at my fundraising event?",
                          'answer'   : ["Depending on availability and location, we might be able to arrange for a representative from Pakistan Sweet Home to attend your event. Please contact us to discuss this possibility."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "How can I contribute if I'm unable to volunteer or organize a fundraiser?",
                          'answer'   : ["There are numerous ways to contribute to our cause if you're unable to volunteer or organize a fundraiser. You can make a one-time or monthly donation, sponsor a child, or help spread awareness about our work in your community. Every contribution, big or small, makes a difference."],
                          'tag'      : 'Volunteering & Fundraising'
                        },
                        {
                          'question' : "How can I schedule a site tour of Pakistan Sweet Home?",
                          'answer'   : ["You can schedule a site tour by reaching out to us via our 'Contact' page or by submitting a request through our online 'Request Site Tour' form. Our team will get back to you to arrange a suitable date and time."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "What should I expect during the site tour?",
                          'answer'   : ["During the site tour, you will have the opportunity to explore our facilities, interact with our staff, and witness firsthand the life-changing work we do for the children. We aim to provide you with a comprehensive understanding of our operations and the impact of your support."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "Can I bring items to donate during the site tour?",
                          'answer'   : ["While we greatly appreciate the sentiment, we encourage you to contribute to our general fund or specific programs instead of bringing physical donations. This ensures fair distribution and meets the most pressing needs of the children."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "Is there any cost associated with the site tour?",
                          'answer'   : ["No, the site tour is free of charge. It's our way of saying thank you for your interest and support, and to foster greater transparency and understanding of our work."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "How long does the site tour last?",
                          'answer'   : ["A typical site tour lasts approximately 1-2 hours. However, this can vary depending on the size of the group and the specific itinerary of the tour."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "Can I bring my children on the site tour?",
                          'answer'   : ["Yes, we welcome families on our site tours. However, we ask that children be supervised at all times to ensure their safety and the comfort of the children in our care."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "Can I take photographs during the site tour?",
                          'answer'   : ["We understand your desire to capture memories of your visit, but we request that you seek permission from our staff before taking photographs. This is to respect the privacy and dignity of our children."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "What safety measures are in place for site tours amidst the COVID-19 pandemic?",
                          'answer'   : ["We follow all government guidelines and regulations to ensure the safety of our visitors and residents during the pandemic. This includes mandatory face masks, social distancing, and limited group sizes. Specific details will be shared with you when scheduling your tour."],
                          'tag'      : 'Site Tours'
                        },
                        {
                          'question' : "How often are updates posted on the website?",
                          'answer'   : ["We strive to keep our supporters informed regularly. Updates are generally posted on a weekly basis, or as significant events and milestones occur."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "Can I subscribe to receive updates directly in my inbox?",
                          'answer'   : ["Yes, you can subscribe to our newsletter from the website. By doing so, you'll receive regular updates about our work, events, and stories directly in your inbox."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "What kind of news and updates are shared on the website?",
                          'answer'   : ["We share a variety of updates to give you a comprehensive view of our work. This includes news about the children, our programs, achievements, events, and ways for you to get involved."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "Are there any interactive features in the updates and news section?",
                          'answer'   : ["While the updates are generally informative, we do encourage interaction. You can share our updates on social media platforms to help spread awareness about our cause."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "I want to learn more about a specific update, how can I do so?",
                          'answer'   : ["We're glad to see your interest! For more information, you can directly reach out to us via the contact information provided on our website."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "Can I contribute an article or a story for the updates and news section?",
                          'answer'   : ["We welcome contributions from our community. If you have a relevant article or story, please reach out to us through the contact information provided on our website. However, please note that all contributions are subject to review and approval by our team."],
                          'tag'      : 'Updates and News'
                        },
                        {
                          'question' : "I forgot my account password, how can I retrieve it?",
                          'answer'   : ["No worries, it happens. Click on the 'Forgot Password' option on the login page and follow the instructions to reset your password."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "How do I update my personal information?",
                          'answer'   : ["You can update your personal information by logging into your account and accessing the 'Profile' or 'Settings' section. Make sure to save changes before exiting."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "I'm having trouble logging into my account, what should I do?",
                          'answer'   : ["If you're unable to log in, first ensure that you're entering the correct username and password. If the issue persists, try resetting your password. If you still face difficulties, contact our support team for assistance."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "How can I update my payment details?",
                          'answer'   : ["You can update your payment information by logging into your account and navigating to the 'Payment Methods' or 'Billing' section."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "Can I view my donation history?",
                          'answer'   : ["Yes, your entire donation history can be viewed in the 'Donation History' section of your account."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "I'm having issues with the website's functionality, who should I contact?",
                          'answer'   : ["We're sorry to hear that you're experiencing difficulties. Please contact our technical support team at info@pakistansweethome.org.pk with a description of the issue and we'll do our best to resolve it as quickly as possible."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "How secure is my personal and payment information?",
                          'answer'   : ["We take your privacy and security very seriously. We employ advanced security measures to protect your information. For more details, please refer to our Privacy Policy."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "I would like to delete my account, how can I do so?",
                          'answer'   : ["If you wish to delete your account, please reach out to our support team. They will guide you through the process. Please note, once an account is deleted, all associated data will be permanently removed and cannot be retrieved."],
                          'tag'      : 'Account and Technical Support'
                        },
                        {
                          'question' : "What are your official contact details?",
                          'answer'   : ["Our official contact details are as follows: Phone: +92 335 1118477, Email: info@pakistansweethome.org.pk, and Address: HEC building، Street 4, H 9/4 , Islamabad, 44000."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "What is the best way to get in touch with your organization?",
                          'answer'   : ["The quickest way to get in touch with us is by using the Contact Form on our website, or you can also email us directly at info@pakistansweethome.org.pk . We aim to respond to all queries within 48 hours."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "Can I contact you over the phone?",
                          'answer'   : ["Yes, you can reach out to us at +92 335 1118477. Our working hours are from 9:00 AM to 5:00 PM, Monday to Friday."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "Who should I contact if I have a specific query about the sponsorship program or admissions?",
                          'answer'   : ["If you have specific questions about the sponsorship program or admissions, you can reach out to our dedicated Sponsor and Orphan Relationship Manager or Admissions Officer at info@pakistansweethome.org.pk."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "I have a media/press inquiry, who should I contact?",
                          'answer'   : ["For all media and press-related inquiries, please email us at HEC building، Street 4, H 9/4 , Islamabad, 44000."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "Can I visit your office or the orphanage?",
                          'answer'   : ["Yes, we welcome visits from sponsors and well-wishers. However, due to security and privacy considerations, visits are by appointment only. Please get in touch with us to schedule your visit."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "Can I get regular updates from Pakistan Sweet Home?",
                          'answer'   : ["Absolutely, you can subscribe to our newsletter to get regular updates about our activities and progress. You can also follow us on our social media channels Facebook : https://www.facebook.com/PakistanSweetHome/, Instagram: https://www.instagram.com/pakistansweethomeofficial/ ."],
                          'tag'      : 'Contact'
                        },
                        {
                          'question' : "I have a suggestion/feedback, how can I share it?",
                          'answer'   : ["We appreciate your feedback and suggestions. Please feel free to share them through our contact form, email, or by calling us directly. Your input helps us continuously improve our services and impact."],
                          'tag'      : 'Contact'
                        },
                      ]

  public activeTab = '';
  public search = '';
  questionFound: boolean = false;
  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {

    $(document).ready(function () {
      $('.card .card-body:first-child').show();

      
    });
    this.setSEO_Tags("Frequently Asked Questions (FAQs) about Pakistan Sweet Home","Find answers to common FAQs about Pakistan Sweet Home’s services, donations, admissions, and how to get involved.")
  }

  changeActiveTab(tabName){
    this.activeTab = tabName;
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  checkSearch(question: string): boolean {
    if (question.toLowerCase().includes(this.search.toLowerCase())) {
      this.questionFound = true;
      return true;
    }
    return false;
  }
  

  get hasMatches(): boolean {
    return this.faqs.some(card => this.checkSearch(card.question));
  }
  cardToggler(el){
    const parent = $(el).closest('.card-head');
    
    if (parent.hasClass('show')) {
        parent.removeClass('show').siblings('.card-body').slideUp("fast");
        parent.find('.opened').hide();  // Hide the opened SVG (minus sign)
        parent.find('.closed').show();  // Show the closed SVG (plus sign)
    } else {
        $(".card .card-body").slideUp("fast");
        $(".card").removeClass("show");
        $(".card-head .opened").hide();  // Hide all opened SVGs
        $(".card-head .closed").show();  // Show all closed SVGs
        
        parent.addClass("show").siblings('.card-body').slideDown("fast");
        parent.find('.opened').show();  // Show the opened SVG (minus sign)
        parent.find('.closed').hide();  // Hide the closed SVG (plus sign)
    }
    return false;
  }

}
