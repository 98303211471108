import {
  Component,
  OnInit
} from '@angular/core';
import {
  AppSettings,
  HelperService
} from 'src/app/services/_services';
declare var $;

@Component({
  selector: 'app-sponsor-child',
  templateUrl: './sponsor-child.component.html',
  styleUrls: ['./sponsor-child.component.css', './mobile-filters.component.css']
})
export class SponsorChildComponent implements OnInit {

  public filters = {
    'gender': '',
    'age': '',
    'month': '',
    'day': '',
    'waiting': '',
    'length': 6,
    'cursor': 0
  }
  public orphans;
  public countTo = 0;
  public countTotal = 1000000;

  public loading = true;
  public halfLoad = false;
  public isMobile = false;
  public filtersOverlay = false;
  public genderModal = false;
  public ageModal = false;
  public monthModal = false;
  public dayModal = false;
  public waitingModal = false;
  public allFilters = false;

  public pageLength = 6;
  public cursor = 0;

  public genderOptions = [{
      label: "Any",
      value: ''
    },
    {
      label: 'Male',
      value: 'Male'
    },
    {
      label: 'Female',
      value: 'Female'
    }
  ]
  public ageOptions = [{
      label: 'Any',
      value: ''
    },
    {
      label: 'Less than 1 Year',
      value: '0'
    },
  ];
  public monthOptions = [{
    label: 'Any',
    value: ''
  }, ];
  public dateOptions = [{
    label: 'Any',
    value: ''
  }, ]
  public waitingOptions = [{
      label: 'Any',
      value: ''
    },
    {
      label: 'Less than 1 Month',
      value: '0'
    },
  ]

  constructor(
    private helperService: HelperService,
  ) {
    this.getAllOrphans(true);
  }

  ngOnInit(): void {
    if (window.innerWidth < 576) {
      this.isMobile = true;
    }
    this.setAgeOptions();
  }

  setAgeOptions() {
    for (let i = 1; i < 32; i++) {
      var obj = {
        label: i.toString(),
        value: i.toString()
      }
      var ageObj = {
        label: i == 1 ? obj.label + ' Year' : obj.label + ' Years',
        value: obj.value
      };
      this.ageOptions.push(ageObj)
      this.dateOptions.push(obj)
      if (i < 13) {
        var monthObj = {
          label: this.changeMonthName(i),
          value: obj.value
        };
        this.monthOptions.push(monthObj)
        var obj2 = {
          label: i == 1 ? obj.label + ' month' : obj.label + ' months',
          value: obj.value
        };
        this.waitingOptions.push(obj2);
      }
    }
  }

  clearAll() {
    for (let key in this.filters) {
      this.filters[key] = '';
    }
    this.rerender();
  }

  rerender() {
    this.loading = true;
    this.filters.length = this.pageLength;
    this.filters.cursor = this.cursor;
    this.getAllOrphans(true);
  }

  getAllOrphans(rerender) {
    let url = AppSettings.BACK_ENDPOINT + 'get-all-orphans';
    let data = new FormData();

    for (let key in this.filters) {
      if (this.filters[key]) {
        data.append(key, this.filters[key]);
      }
    }

    this.helperService.httpPostRequests(url, data).then(resp => {

      this.countTo = resp.to;
      this.countTotal = resp.total;

      if (rerender) {
        this.orphans = resp.orphans;
        this.loading = false;
      } else {
        this.orphans = this.orphans.concat(resp.orphans);
        this.halfLoad = false;
      }

      console.log('orphans::', this.orphans)
    }).catch(error => {
      console.error("error: ");
    });

  }

  dobToAge(dob) {
    var today = new Date();
    var birthDate = new Date(dob);

    var yearsDiff = today.getFullYear() - birthDate.getFullYear();
    var monthsDiff = today.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }

    var yearsString = yearsDiff == 0 ? '' : yearsDiff == 1 ? yearsDiff + ' Year ' : yearsDiff + ' Years ';
    var monthsString = monthsDiff == 0 ? '' : monthsDiff == 1 ? monthsDiff + ' Month' : monthsDiff + ' Months';

    return yearsString + monthsString

  }

  lastSponsoredDate(sponsoredDate, isSponsored) {
    var today = new Date();
    var startDate = new Date(sponsoredDate);

    var monthsDiff = (today.getFullYear() - startDate.getFullYear()) * 12 + (today.getMonth() - startDate.getMonth());
    var DaysDiff = (today.getTime() - startDate.getTime());    
    DaysDiff = Number((DaysDiff / (1000 * 60 * 60 * 24)).toFixed(0));
    DaysDiff = DaysDiff + 1;
    
    monthsDiff = startDate.getDate() > today.getDate() ? monthsDiff - 1 : monthsDiff;

    var monthString = monthsDiff == 1 ? ' Month' : monthsDiff != 0 ? ' Months' : DaysDiff == 1 ? ' Day' : ' Days';
    monthsDiff = monthsDiff == 0 ? DaysDiff : monthsDiff;

    var waitingString = isSponsored == '0' ? 'Waiting ' : 'Sponsored ';
    return waitingString + 'since ' + monthsDiff + monthString;
  }

  showMoreOrphans() {
    this.halfLoad = true;
    this.filters.cursor = Number(this.filters.cursor) + this.pageLength;
    this.getAllOrphans(false);
  }

  toggleFiltersModal(modalID) {

    this.filtersOverlay = !this.filtersOverlay;
    var body = document.getElementsByTagName('body')[0];
    body.style.height = this.filtersOverlay ? '100vh' : 'unset';

    switch (modalID) {
      case 'genderModal': {
        this.genderModal = !this.genderModal;
        break;
      }
      case 'ageModal': {
        this.ageModal = !this.ageModal;
        break;
      }
      case 'monthModal': {
        this.monthModal = !this.monthModal;
        break;
      }
      case 'dayModal': {
        this.dayModal = !this.dayModal;
        break;
      }
      case 'waitingModal': {
        this.waitingModal = !this.waitingModal;
        break;
      }
      case 'allFilters': {
        this.allFilters = !this.allFilters;
        break;
      }
      default: {
        break;
      }
    }
  }

  hideFilterModals() {
    this.genderModal = false;
    this.ageModal = false;
    this.monthModal = false;
    this.dayModal = false;
    this.waitingModal = false;
    this.allFilters = false;
    var body = document.getElementsByTagName('body')[0];
    body.style.height = 'unset';
    this.filtersOverlay = !this.filtersOverlay;
  }

  addWatermark() {
    setTimeout(() => {
      var pshLogo = $('.watermarkImg')[0];
      var i = 0;
      $('.galleryCanvas').each(function () {
        var canvas = $(this)[0];
        var image = $(this).siblings('img')[0];

        // console.log('image.width::',image.naturalWidth)

        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        var ctx = canvas.getContext('2d');

        var canvasWidth = canvas.width;
        var canvasHeight = canvas.height;
        var pshLogoWidth = pshLogo.width;
        var pshLogoHeight = pshLogo.height;
        var offsetX = 0;
        var offsetY = 0;

        ctx.drawImage(image, 0, 0);
        for (var x = 0; x < canvasWidth; x += pshLogoWidth) {
          for (var y = offsetY; y < canvasHeight; y += pshLogoHeight) {
            ctx.drawImage(pshLogo, x + offsetX, y);
          }
        }
        // ctx.drawImage(pshLogo, 0, 100);


      })

    }, 1500);
  }

  changeMonthName(monthNumber){
    switch (monthNumber) {
      case 1:
        return "January";
      case 2:
        return "February";
      case 3:
        return "March";
      case 4:
        return "April";
      case 5:
        return "May";
      case 6:
        return "June";
      case 7:
        return "July";
      case 8:
        return "August";
      case 9:
        return "September";
      case 10:
        return "October";
      case 11:
        return "November";
      case 12:
        return "December";
      default:
        return "Invalid month number";
    }
  }

}
