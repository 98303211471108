import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { SEOService } from 'src/app/services/_services';
import { filter } from 'rxjs/operators';
@Component({
  selector: 'app-support-needs-description',
  templateUrl: './support-needs-description.component.html',
  styleUrls: ['./support-needs-description.component.css'],
})
export class SupportNeedsDescriptionComponent implements OnInit, AfterViewInit {
  slug: string | null = '';
  @ViewChildren('lazyVideo') lazyVideos!: QueryList<ElementRef>;

  article: any;
  loading: boolean = true;
  showIframe: boolean = false;

  similarArticles: any;
  videoSrc = '';
  Articles = [
    {
      title: 'Education',
      slug: 'education',
      h1: 'Empower an Orphan Child Through Education',
      h2: 'Your contribution covers all essential educational needs for a child for one month.',
      description: [
        'Education is the foundation of a brighter future. For the orphans at Pakistan Sweet Home, education is their pathway to opportunities they might not otherwise have. Our children attend renowned schools and colleges like Divisional Public and Siddeeq Public Schools with a focused curriculum designed for holistic development.These institutions are renowned for their commitment to the academic growth of each child.',
        'To further support their education, we provide after-school tuition on campus, led by dedicated and passionate teachers. We recognize the importance of staying ahead in today’s fast-paced world, so we have equipped our campus with state-of-the-art computer labs and regularly organize educational seminars and computer training classes. These sessions cover cutting-edge technologies such as IT and AI and include career counseling to help our children navigate their futures. Our approach to education is not just about academic excellence but equipping our children with the skills and knowledge to thrive in a rapidly evolving world.',
        'Each child follows a structured routine that includes religious education and character building. From the moment children join Sweet Home, they are guided to perform Salah and read the Quran daily as a compulsory part of their routine. Our state-of-the-art mosque facilitates five daily prayers and evening Quran classes.  In addition to that, our approach is fully inclusive, extending a warm welcome to children from different religious minority groups and providing them with the best care and support we can offer.',
      ],
      expenses: [
        {
          title: 'School Fee',
          image: 'assets/new-images/get-involved/school-fee.png',
          alt: 'School Fee',
        },
        {
          title: 'Tuition Classes',
          image: 'assets/new-images/get-involved/classes.png',
          alt: 'Tuition Classes',
        },
        {
          title: 'Uniform',
          image: 'assets/new-images/get-involved/uniform.png',
          alt: 'Uniform',
        },
        {
          title: 'School Shoes',
          image: 'assets/new-images/get-involved/shoes.png',
          alt: 'School Shoes',
        },
        {
          title: 'Books',
          image: 'assets/new-images/get-involved/books.png',
          alt: 'Books',
        },
        {
          title: 'Water Bottle',
          image: 'assets/new-images/get-involved/water-bottle.png',
          alt: 'Water Bottle',
        },

        {
          title: 'Lunch Box',
          image: 'assets/new-images/get-involved/lunch-box.png',
          alt: 'Lunch Box',
        },

        {
          title: 'Educational Seminars',
          image: 'assets/new-images/get-involved/edu-seminars.png',
          alt: 'Educational Seminars',
        },
        {
          title: 'School Transport',
          image: 'assets/new-images/get-involved/school-transport.png',
          alt: 'School Transport',
        },
        {
          title: 'Stationery Supplies',
          image: 'assets/new-images/get-involved/stationery.png',
          alt: 'Stationery Supplies',
        },
      ],

      coverLetter: [
        'Our goal is to provide a comprehensive education, equipping our children to enter diverse fields and become valuable contributors to the nation and the world. Since our inception in 2011, this approach has enabled our students to progress to higher education at top universities, excelling in law, medicine, and IT. They are living proof of the impact and effectiveness of your generous donations.',
        'We rely on the support of individuals like you to continue meeting these educational needs. By contributing, you are not just providing educational support but giving orphaned children the tools to dream, hope, and build a future filled with possibilities.',
        'Your donation helps create a world where every child, regardless of their circumstances, has the chance to succeed. Together, we can empower these children to achieve their full potential.',
      ],
    },
    {
      title: 'Food',
      slug: 'food',
      h1: 'Ensure Every Child’s Plate is Full',
      h2: 'Your contribution provides essential food support for a month for an orphan child.',
      description: [
        "Proper nutrition is the foundation of a thriving childhood. At Pakistan Sweet Home, we understand that providing food goes beyond mere sustenance; it's about nurturing a child's growth and well-being. Each nutritious meal we serve is a symbol of care, love, and the promise of a healthy future.",
        'We adhere to a meticulously planned monthly food plan approved by nutritionists. This includes a well-balanced diet rich in protein, fiber, and essential nutrients tailored to meet the specific needs of growing children. Every Sunday, we celebrate with special food parties and brunches, ensuring our children enjoy their favorite meals.',
        'Our dedicated kitchen staff, under strict supervision, maintains the highest standards of cleanliness and hygiene in our state-of-the-art kitchen. Separate cafeterias for boys and girls provide a comfortable dining environment. We conduct monthly sessions focused on teaching food ethics, minimizing food waste, and celebrating National Food Days. We have collaboration with various international food organizations to enhance food awareness among our children.',
        'Ensuring the health and vitality of our children is our foremost commitment. We have always been transparent in our operations, openly inviting everyone to visit Pakistan Sweet Home to witness firsthand the robust health of our children; not a single child under our care shows even slight signs of malnutrition. This is our living testament to the effectiveness of our food program!',
      ],
      expenses: [
        {
          title: 'Three Meals a Day',
          image: 'assets/new-images/get-involved/meal.png',
          alt: 'Three Meals a Day',
        },
        {
          title: 'Snacks',
          image: 'assets/new-images/get-involved/snacks.png',
          alt: 'snacks',
        },
        {
          title: 'Fruits',
          image: 'assets/new-images/get-involved/fruits.png',
          alt: 'Fruits',
        },
        {
          title: 'School Lunch',
          image: 'assets/new-images/get-involved/lunch-box.png',
          alt: 'School Lunch',
        },
        {
          title: 'Weekly Food Party',
          image: 'assets/new-images/get-involved/food-party.png',
          alt: 'Weekly Food Party',
        },
      ],

      coverLetter: [
        'Your support enables us to provide nourishing, well-balanced meals that fuel both body and mind, ensuring our children have the energy to learn, play, and thrive. Each meal you sponsor brings us closer to welcoming more orphaned children into our campuses and eradicating hunger and malnutrition.',
        'With your donation, we pledge that no child under our care will ever go to bed hungry. Together, we can ensure their plates are full and their hearts are filled with hope, empowering them to chase their dreams and embrace each day to its fullest potential.',
      ],
    },
    {
      title: 'Medical',
      slug: 'medical',
      h1: 'Champion Orphans’ Right to Health',
      h2: 'Your contribution ensures critical medical support and mental health care for children in our care.',
      description: [
        'Health is a fundamental right, yet many of our children have come from backgrounds where medical care was a luxury. At Pakistan Sweet Home, we strive to provide comprehensive healthcare to every child, ensuring they are healthy and well-cared for, both physically and mentally.',
        'Our on-campus mini-hospital, staffed with a team of experienced pediatricians, nurses, and supporting staff, is equipped with 20 beds and a dental unit. They are dedicated to providing the highest quality care and addressing any health-related issues a child may face.',
        'We also schedule weekly visits from psychologists to monitor and support the mental health of our children, many of whom have experienced trauma in the past. Additionally, we organize bi-annual visits to external hospitals for comprehensive ENT and routine check-ups.',
        'Imagine the peace of mind we, as caregivers, would have knowing that a child can receive prompt medical attention when needed. Your donation ensures we can maintain a dedicated health fund ready to address any medical needs as they arise.',
      ],
      expenses: [
        {
          title: 'Emergency Medical Kits',
          image: 'assets/new-images/get-involved/medical-kit.png',
          alt: 'Emergency Medical Kits',
        },
        {
          title: 'Medicine Stock',
          image: 'assets/new-images/get-involved/medicine.png',
          alt: 'Medicine Stock',
        },
        {
          title: 'Ambulance Bus',
          image: 'assets/new-images/get-involved/ambulance.png',
          alt: 'Ambulance Bus',
        },
        {
          title: 'On-campus Medical Unit',
          image: 'assets/new-images/get-involved/medical-expense.png',
          alt: 'On-campus Medical Unit',
        },
        {
          title: 'Specialist Doctors/Nurses',
          image: 'assets/new-images/get-involved/doctor.png',
          alt: 'Specialist Doctors/Nurses',
        },
        {
          title: 'Routine Check-ups',
          image: 'assets/new-images/get-involved/checkups.png',
          alt: 'Routine Check-ups',
        },
      ],
      coverLetter: [
        'By supporting orphan’s health care, you are helping us protect their well-being and giving them the chance to grow up healthy and strong.',
      ],
    },
    {
      title: 'Sports & Recreational',
      slug: 'sports-and-recreational',
      h1: 'Nurture Happy Childhoods Through Fitness & Play',
      h2: 'Your contribution enriches our sports and recreation initiatives, creating an active and  happy childhood for every child.',
      description: [
        "Physical activity is essential for a child's healthy development. Picture the joy of children sharing laughter during a football game or the thrill of a day at the park. These experiences are crucial for their emotional and social growth.",
        "At Pakistan Sweet Home, we offer diverse sports, including football, volleyball, cricket, tennis, and taekwondo classes on our campus. Our partnerships with international sports organizations allow us to host and participate in exciting sports events and awareness programs, such as the recent visit from the Netherlands Hockey team and our children's participation in the Islamabad Marathon.",
        'We regularly invite national sports stars, like boxer Amir Khan and cricketer Shadab Khan, to inspire our children. Our inter-campus tournaments in cricket, football, and tennis are eagerly anticipated by the kids.',
        'In addition to sports, we organize educational and recreational trips to museums, parks, zoos, and hiking, providing a well-rounded experience. For the children at Pakistan Sweet Home, sports and recreational activities are essential outlets for their energy, keeping them healthy and happy.',
      ],
      expenses: [
        {
          title: 'Purchase of Sports Equipment',
          image: 'assets/new-images/get-involved/sport-purchase.png',
          alt: 'Purchase of Sports Equipment',
        },
        {
          title: 'Fieldtrips & Recreational Events',
          image: 'assets/new-images/get-involved/trips.png',
          alt: 'Fieldtrips & Recreational Events',
        },
        {
          title: 'Hosting Sports Tournaments',
          image: 'assets/new-images/get-involved/sports-tournament.png',
          alt: 'Hosting Sports Tournaments',
        },
        {
          title: 'Trained Sports Teachers and Staff',
          image: 'assets/new-images/get-involved/sports-teacher.png',
          alt: 'Trained Sports Teachers and Staff',
        },
        {
          title: 'Extracurricular Activities',
          image: 'assets/new-images/get-involved/activities.png',
          alt: 'Extracurricular Activities',
        },
        {
          title: 'Playground Maintenance',
          image: 'assets/new-images/get-involved/maintenance.png',
          alt: 'Playground Maintenance',
        },
        {
          title: 'Construction of Sports Complex',
          image: 'assets/new-images/get-involved/sport-complex.png',
          alt: 'Construction of Sports Complex',
        },
      ],
      coverLetter: [
        'Contributing to our sports and recreational fund ensures that our children have regular access to these enriching activities, fostering their physical and emotional well-being. Your support helps create a balanced and joyful environment where children can thrive, learn, and grow.',
        'Together, we can make a lasting impact on their lives, giving them the opportunity to enjoy a healthy and vibrant childhood.',
      ],
    },
  ];
  similarArticlesArray = [
    {
      img: 'assets/new-images/get-involved/education-banner-1.jpg',
      title: 'Education',
      slug: 'education',
      alt: 'education',

      summary:
        'Transform an orphan’s life by providing complete educational support.',
    },
    {
      img: 'assets/new-images/get-involved/food-support.jpg',
      title: 'Food',
      slug: 'food',
      alt: 'food',

      summary:
        'Provide nutritious meals to an orphan child living at Sweet Home boarding for a month.',
    },
    {
      img: 'assets/new-images/get-involved/medical-banner.JPG',
      title: 'Medical',
      slug: 'medical',
      alt: 'medical',

      summary:
        'Provide quality healthcare with trained staff and essential equipment for any emergency.',
    },
    {
      img: 'assets/new-images/get-involved/sport-banner-2.jpg',
      title: 'Sports & Recreational',
      slug: 'sports-and-recreational',
      alt: 'sports-and-recreational',

      summary:
        'Support a joyful upbringing for orphans through essential sports and recreation programs.',
    },
  ];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private seoService: SEOService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const slug = this.route.snapshot.paramMap.get('slug');
        this.updateContentBasedOnSlug(slug);
      });
  }

  updateContentBasedOnSlug(slug: string | null) {
    this.slug = slug;
    this.article = this.Articles.find((item: any) => item.slug === this.slug);
    this.similarArticles = this.similarArticlesArray.filter(
      (item: any) => item.slug !== this.slug
    );

    if (this.slug) {
      switch (this.slug) {
        case 'education':
          this.setSEO_Tags(
            'Support Education | Transform Futures of Orphans',
            'Help provide orphans with education, resources, and a brighter future. Support their learning journey today.'
          );
          break;
        case 'food':
          this.setSEO_Tags(
            'Support Food | Transform Futures of Orphans',
            'Help provide orphans with food, resources, and a brighter future. Support their learning journey today.'
          );
          break;
        case 'medical':
          this.setSEO_Tags(
            'Support Medical | Transform Futures of Orphans',
            'Help provide orphans with medical, resources, and a brighter future. Support their learning journey today.'
          );
          break;
        case 'sports-and-recreational':
          this.setSEO_Tags(
            'Support Sports | Transform Futures of Orphans',
            'Help provide orphans with sports, resources, and a brighter future. Support their learning journey today.'
          );
          break;
        default:
          // Handle other cases or do nothing
          break;
      }
    }
  }
  ngOnInit(): void {
    // Subscribe to route parameter changes
    this.videoSrc = 'assets/images/donate-video-compressed.mp4';
    setTimeout(() => {
      this.showIframe = true;
    }, 500);
  }
  goToDetailPage(title) {
    this.router.navigate(['/get-involved/support-our-needs', title]);
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }
  ngAfterViewInit(): void {
    this.lazyVideos.forEach((videoElement) => {
      const video = videoElement.nativeElement;
      const source = video.querySelector('source');
      const dataSrc = video.getAttribute('data-src');

      if (dataSrc) {
        source.src = dataSrc; // Set the actual source for lazy loading
        video.load(); // Start loading the video
      }
    });
  }
  onVideoLoaded() {
    this.loading = false;
  }
}
