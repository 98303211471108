<!-- Donate Section -->
<section class="donate-section">
  <div class="container">
    <div class="donate-content">
      <img
        src="assets/images/jazzcash-donation/jazzcash-logo.webp"
        alt="Jazz Cash Logo"
      />
      <h1>Donate via JazzCash</h1>
      <p>You can donate in two ways through your JazzCash App</p>
    </div>
  </div>
</section>
<!-- /Donate Section -->
<!-- Scan Section -->
<section class="scan-section">
  <div class="container">
    <div class="scan-content">
      <div class="qrcode-content">
        <p>Scan this QR Code</p>
        <img
          src="assets/images/jazzcash-donation/scan-qr-new.webp"
          width="203px"
          height="203px"
          alt="Scan QR Code"
        />
      </div>
      <div class="middle-line-content">
        <span>OR</span>
      </div>
      <div class="scan-numbers-content">
        <p>Enter this 9-Digit Number in your JazzCash App</p>
        <div class="scan-numbers-id">
          <p>Till ID:</p>
          <div class="scan-digits-row">
            <div class="digits-cols">
              <div class="digit-box">
                <span>9</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>3</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
              <div class="digit-box">
                <span>4</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>6</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>1</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- /Scan Section -->

<!-- Contact Section -->
<section class="contact-section">
  <div class="container">
    <div class="contact-content">
      <h2>Confirmation & Acknowledgement</h2>
      <p>
        After Completing the process, kindly email us a copy of the receipt,
        along with your Name, Active Contact Number, and Purpose of Donation, to
        <span
          ><a href="mailto:donations@pakistansweethome.org.pk"
            >donations@pakistansweethome.org.pk</a
          ></span
        >
        or at <span><a href="tel:+92 335 1118477"> +92 335 1118477</a>.</span>
      </p>
      <p>
        Once you share the receipt, we will send you the acknowledgment of the
        donation.
      </p>
    </div>
  </div>
</section>
<!-- /Contact Section -->
