import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-admission-form',
  templateUrl: './admission-form.component.html',
  styleUrls: ['./admission-form.component.css']
})
export class AdmissionFormComponent implements OnInit {

  public activeStep             : any = 'signup';
  public reviewPage             : any = 'review';

  constructor(
    private activatedRoute         : ActivatedRoute,
    private seoService: SEOService,

  ) { 
    let url = activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
  }

  ngOnInit(): void {    
    this.setSEO_Tags("Fill Form of Admission for Pakistan Sweet Home","Fill form of admission for Pakistan Sweet Home and provide orphans with the opportunity for quality care and education.")

  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  routeCheck(url){
    if(url == '/admission/signup'){
      this.activeStep = 'signup';
    } else if(url.includes('fill-form')){
      this.activeStep = 'fill-form';
    } else if(url.includes('review-information') || url.includes('view-form')){
      this.activeStep = 'review-information';
      if(url.includes('view-form')){
        this.reviewPage = 'view';
      } else {
        this.reviewPage = 'review';
      }
    } else if(url == '/admission/login'){
      this.activeStep = 'login';
    } else if(url == '/admission/forgot-password'){
      this.activeStep = 'forgot-password';
    }    
  }


}
