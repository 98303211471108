import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  public activeProjectType : any = 'campuses';

  constructor() { }

  ngOnInit(): void {
  }
  changeProject(projectName){
    this.activeProjectType = projectName;
  }
}
