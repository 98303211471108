<div class="rightbanner">
  <!-- <div class="banner_formbtn">
    <a class="onetime_btn" href="javascript:;" (click)="giveOnce()"
      [ngClass]="user_donation.monthlyPay ? '' : 'active'">One Time</a>
    <a class="monthly_btn" href="javascript:;" (click)="giveMonthly()"
      [ngClass]="user_donation.monthlyPay ? 'active' : ''">Monthly</a>
  </div> -->
  <div class="bannerform">
    <div class="formhead">
      <p>
        {{ topText }}
        <span class="topEditSpan" *ngIf="DonateForm != 'form'"
          >(<a
            class="topEditBtn"
            (click)="DonateForm = 'form'; topText = startText"
            href="javscript:;"
            >edit amount</a
          >)</span
        >
      </p>
    </div>
    <div *ngIf="DonateForm == 'form'" class="formWrapper">
      <div class="formtabs">
        <div
          *ngIf="activePage == 'donate'"
          class="forminput full"
          [ngClass]="activePage != 'home' ? 'donateForm' : ''"
        >
          <label for="other_amount_input">Donation Amount</label>
          <span>{{ selectedCurrencySymbol }}</span>
          <input
            id="other_amount_input"
            [(ngModel)]="otherAmount"
            (change)="convertOtherAmount()"
            type="number"
            name="otherAmount"
          />
          <p class="aj_usd">{{ selectedCurrency }}</p>
        </div>
        <p class="form_error" id="card-error-102" role="alert">
          {{ stripeError }}
        </p>
        <!-- <re-captcha class="customCaptcha" (resolved)="resolved($event)" siteKey="6LebcRwoAAAAAP3p7fvb__XuC6V6i-vNokLSJNKC"></re-captcha> -->

        <div *ngIf="activePage == 'home'" class="maintabs">
          <div
            class="singletab"
            (click)="setDonation(10)"
            [ngClass]="donation == 10 && otherAmount == null ? 'active' : ''"
          >
            <a href="javascript:;">
              {{ selectedCurrencySymbol }} {{ convertCurrency(10).toFixed(0) }}
              <span *ngIf="user_donation.monthlyPay == true">/mo</span>
            </a>
          </div>
          <div
            class="singletab"
            (click)="setDonation(20)"
            [ngClass]="donation == 20 && otherAmount == null ? 'active' : ''"
          >
            <a href="javascript:;">
              {{ selectedCurrencySymbol }} {{ convertCurrency(20).toFixed(0) }}
              <span *ngIf="user_donation.monthlyPay == true">/mo</span>
            </a>
          </div>
          <div
            class="singletab"
            (click)="setDonation(40)"
            [ngClass]="donation == 40 && otherAmount == null ? 'active' : ''"
          >
            <a href="javascript:;">
              {{ selectedCurrencySymbol }} {{ convertCurrency(40).toFixed(0) }}
              <span *ngIf="user_donation.monthlyPay == true">/mo</span>
            </a>
          </div>
          <div
            class="singletab"
            (click)="setDonation(100)"
            [ngClass]="donation == 100 && otherAmount == null ? 'active' : ''"
          >
            <a href="javascript:;">
              {{ selectedCurrencySymbol }} {{ convertCurrency(100).toFixed(0) }}
              <span *ngIf="user_donation.monthlyPay == true">/mo</span>
            </a>
          </div>
          <div class="forminput">
            <a
              *ngIf="inputHidden"
              class="button button--grey-20 other-amount-button"
              (click)="falseInput()"
              >Other amount</a
            >

            <div
              #checkkkkk
              class="input-wrapper lh donate-amount"
              *ngIf="!inputHidden"
            >
              <label for="amount">Donation Amount</label>
              <div class="input-field-container aj_input">
                <span class="preinput">{{ selectedCurrencySymbol }}</span>
                <input
                  autofocus
                  [(ngModel)]="otherAmount"
                  (keyup)="removeError('')"
                  type="number"
                  data-amount="true"
                  [class.aj_error]="otherAmount && otherAmount < 5"
                  data-parsley-currency="5,50000"
                  data-parsley-error-message="The monthly minimum donation is $5"
                  placeholder="Other"
                  data-parsley-errors-container=".donate-amount.a5826748-d59d-4f86-a042-1e4c030720d5 .error"
                  name="amount"
                  required=""
                  step="0.01"
                  type="number"
                  value=""
                  (change)="convertOtherAmount()"
                />

                <span class="postinput"
                  ><span class="uppercase">{{ selectedCurrency }}</span>
                  <span *ngIf="user_donation.monthlyPay == true">/mo</span>
                </span>
              </div>
            </div>
          </div>
          <p class="form_error" id="card-error-12" role="alert">
            {{ stripeError }}
          </p>
        </div>

        <div class="donatebtn" (click)="secondStep()">
          <a href="javascript:;">{{
            user_donation.monthlyPay ? "Get Started" : "Donate"
          }}</a>
        </div>
      </div>
      <div class="secure_text">
        <svg
          width="13"
          height="13"
          viewBox="0 0 13 13"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1453_46)">
            <path
              d="M10.1562 4.875H9.75V3.25C9.75 1.45758 8.29242 0 6.5 0C4.70758 0 3.25 1.45758 3.25 3.25V4.875H2.84375C2.17209 4.875 1.625 5.42149 1.625 6.09375V11.7812C1.625 12.4535 2.17209 13 2.84375 13H10.1562C10.8279 13 11.375 12.4535 11.375 11.7812V6.09375C11.375 5.42149 10.8279 4.875 10.1562 4.875ZM4.33337 3.25C4.33337 2.05505 5.30505 1.08337 6.5 1.08337C7.69495 1.08337 8.66663 2.05505 8.66663 3.25V4.875H4.33337V3.25Z"
              fill="#5E5E5E"
            />
          </g>
          <defs>
            <clipPath id="clip0_1453_46">
              <rect width="13" height="13" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p>
          Secure Payment · This site is protected by reCAPTCHA and the Google
          <a target="_blank" href="https://policies.google.com/privacy"
            ><span>Privacy Policy</span></a
          >
          and
          <a target="_blank" href="https://policies.google.com/terms"
            ><span>Terms of Service</span></a
          >
          apply.
        </p>
      </div>
    </div>
    <div *ngIf="DonateForm == 'monthlyForm'" class="donateSecondStepWrapper">
      <div class="second_Step">
        <div class="second_stepdata">
          <h3>Would you consider something?</h3>
          <p>
            How about a monthly recurring donation instead of one-time
            contribution to help us offer consistent support for orphans?
          </p>
        </div>
        <div class="monthlyBox">
          <div class="monthlyBox_btns">
            <a
              href="javascript:;"
              (click)="giveToggle()"
              class="togglerBtn"
              [ngClass]="user_donation.monthlyPay ? 'true' : 'false'"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1211_3954)">
                  <path
                    d="M6.33723 11.7428C5.7025 12.3775 4.67259 12.3775 4.03816 11.7428L0.47605 8.18057C-0.158684 7.54613 -0.158684 6.51619 0.47605 5.88175C1.11048 5.247 2.14039 5.247 2.77512 5.88175L4.89744 8.00381C5.05766 8.16372 5.31774 8.16372 5.47826 8.00381L11.2249 2.25707C11.8593 1.62232 12.8892 1.62232 13.5239 2.25707C13.8288 2.56188 14 2.97545 14 3.40648C14 3.83751 13.8288 4.25107 13.5239 4.55589L6.33723 11.7428Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1211_3954">
                    <rect width="14" height="14" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_1211_3949)">
                  <path
                    d="M7.97999 6.00037L11.6923 2.28802C12.1025 1.87784 12.1025 1.21284 11.6923 0.803306L11.1974 0.308402C10.7871 -0.101903 10.1221 -0.101903 9.7126 0.308402L6.00037 4.02063L2.28802 0.307633C1.87784 -0.102544 1.21284 -0.102544 0.803306 0.307633L0.307633 0.802536C-0.102544 1.21284 -0.102544 1.87784 0.307633 2.28738L4.02063 6.00037L0.308402 9.7126C-0.101903 10.1229 -0.101903 10.7879 0.308402 11.1974L0.803306 11.6923C1.21348 12.1025 1.87848 12.1025 2.28802 11.6923L6.00037 7.97999L9.7126 11.6923C10.1229 12.1025 10.7879 12.1025 11.1974 11.6923L11.6923 11.1974C12.1025 10.7871 12.1025 10.1221 11.6923 9.7126L7.97999 6.00037Z"
                    fill="#C1C1BA"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1211_3949">
                    <rect width="12" height="12" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
            <p>
              Donate {{ selectedCurrencySymbol }} {{ otherAmount }} per month
              instead
            </p>
          </div>
          <p>
            The donation will be auto-deducted monthly. You will have the option
            to cancel or adjust your donation amount as needed and you will be
            notified in advance before each charge via Email.
          </p>
        </div>
        <div class="nextStep" (click)="thirdStep()">
          <a href="javascript:;">Next Step</a>
        </div>
      </div>
    </div>
    <form
      #donateForm="ngForm"
      [ngClass]="DonateForm == 'cardForm' ? 'show' : ''"
      class="basicThirdStepWrapper"
      autocomplete="off"
    >
      <div class="thirdStep">
        <div class="mainInput">
          <div class="inputGroup">
            <input
              #firstNameIn
              placeholder="Enter First Name"
              [(ngModel)]="signUp.firstName"
              id="firstName"
              name="firstName"
              type="text"
              class="firstname_input"
              [ngClass]="{ 'in-valid': submitted && !signUp.firstName }"
            />

            <p class="error">First Name Is required</p>
          </div>
          <div class="inputGroup">
            <input
              placeholder="Enter Last Name"
              [(ngModel)]="signUp.lastName"
              id="lastName"
              name="lastName"
              class="firstname_input"
              type="text"
              [ngClass]="{ 'in-valid': submitted && !signUp.lastName }"
            />
            <p class="error">Last Name Is required</p>
          </div>
        </div>
        <div class="inputGroup">
          <input
            placeholder="Enter Your Email Address"
            [(ngModel)]="signUp.email"
            id="email"
            name="email"
            class="firstname_input"
            type="email"
            [ngClass]="{
              'in-valid':
                submitted &&
                !(
                  this.signUp.email.includes('@') &&
                  this.signUp.email.includes('.')
                )
            }"
          />
          <p class="error">Please Enter a Valid Email Address</p>
        </div>

        <div class="inputGroup inputGroupNumber">
          <div class="flagsWrapper">
            <div class="coutryFlags">
              <div
                (click)="
                  selectedFlag = flag;
                  selectedCountry = countryOptions[i];
                  coutryCode = '';
                  setCountryCode()
                "
                *ngFor="let flag of flagOptions; index as i"
                class="singleFlag"
              >
                <img
                  [src]="
                    'https://img.mobiscroll.com/demos/flags/' + flag + '.png'
                  "
                  alt="country"
                />
                <p>{{ countryOptions[i] }}</p>
              </div>
            </div>
            <div class="selectedFlag" *ngIf="selectedFlag">
              <img
                [src]="
                  'https://img.mobiscroll.com/demos/flags/' +
                  selectedFlag +
                  '.png'
                "
                alt=""
              />
              <svg
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z"
                  fill="#5E5E5E"
                />
              </svg>
            </div>
          </div>
          <input
            mask="(000) 00000000"
            type="text"
            placeholder="(301) 555-1232"
            [(ngModel)]="phone_number"
            id="phone_number"
            name="phone_number"
            required=""
            (keypress)="isNumberValidate($event)"
            [ngClass]="{
              'in-valid':
                submitted &&
                (!phone_number || phone_number.toString().length < 8)
            }"
            autocomplete="off"
          />
          <span class="countryCode">{{ coutryCode }}</span>
          <p class="error">Please Enter Valid Phone Number</p>
        </div>

        <div class="nextStep" (click)="addDonationLead()" *ngIf="!loading">
          <a href="javascript:;">Next Step</a>
        </div>

        <!-- Loader -->
        <div class="nextStep" *ngIf="loading">
          <a href="javascript:;"
            >Next Step
            <i class="fa fa-spinner fa-spin"></i>
          </a>
        </div>

        <div class="main_msg" *ngIf="user_donation.monthlyPay == true">
          <div class="msg_left">
            <img
              src="assets/images/credit-card-icon.svg"
              alt="credit-card-icon"
            />
          </div>
          <div class="msg_right">
            <p>
              We'll store your payment method through Stripe's secure platform.
              You'll still be able to change your linked payment at any time to
              ensure your donation goes uninterrupted!
            </p>
          </div>
        </div>
      </div>
    </form>

    <div *ngIf="DonateForm == 'addressForm'" class="basicfourthStepWrapper">
      <div class="lastStep">
        <!-- <div class="edit_btn edit_detail">
          <p>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M10.3108 12.6866C10.4493 12.6897 10.5854 12.6495 10.7 12.5716C10.8147 12.4938 10.9022 12.3821 10.9505 12.2522C10.9987 12.1223 11.0053 11.9807 10.9693 11.8468C10.9333 11.713 10.8565 11.5938 10.7496 11.5057L6.67018 8.01087L10.7496 4.51731C10.8236 4.46296 10.8855 4.39395 10.9315 4.31454C10.9776 4.23514 11.0067 4.14706 11.0172 4.05587C11.0276 3.96468 11.0191 3.87233 10.9922 3.78458C10.9653 3.69683 10.9205 3.61556 10.8607 3.5459C10.801 3.47625 10.7275 3.41974 10.6448 3.37981C10.5622 3.33988 10.4722 3.31745 10.3805 3.31391C10.2887 3.31037 10.1973 3.32576 10.1118 3.3592C10.0263 3.39265 9.94868 3.44338 9.88372 3.50822L5.21315 7.50437C5.1398 7.56696 5.0809 7.64465 5.04052 7.7322C5.00013 7.81976 4.97922 7.91506 4.97922 8.01149C4.97922 8.10791 5.00013 8.20321 5.04052 8.29077C5.0809 8.37833 5.1398 8.45609 5.21315 8.51868L9.88372 12.5187C10.0017 12.6235 10.153 12.6829 10.3108 12.6866Z"
                fill="#333638" />
            </svg>
            <a href="javascript:;" (click)="DonateForm = 'cardForm'">Edit card details</a> </p>
        </div> -->

        <div class="billing_deails">
          <input
            #adressIn
            type="text"
            name=""
            id="address"
            (keyup)="removeInvalid('#address')"
            placeholder="Type Your Street Address"
            [(ngModel)]="signUp.address"
          />
          <p id="billing_error">Please Enter Your Address</p>
        </div>
        <div class="zipcode">
          <input
            type="text"
            id="city"
            (keyup)="removeInvalid('#city')"
            placeholder="City Name"
            [(ngModel)]="signUp.city"
          />
          <input
            type="text"
            id="zipCode"
            (keyup)="removeInvalid('#zipCode')"
            placeholder="Enter Zipcode"
            [(ngModel)]="signUp.zipCode"
          />
          <p id="zipecode_error">Enter Valid Zipcode</p>
        </div>
        <ng-select
          class="Countryselect selectTrio"
          [items]="countryOptions"
          [(ngModel)]="signUp.country"
          placeholder="Country"
          [ngModelOptions]="{ standalone: true }"
          [searchable]="false"
          [clearable]="false"
          bindValue="value"
          bindLabel="text"
        >
        </ng-select>
        <ng-select
          class="selectTrio Countryselect purpose"
          id="purposeSel"
          [items]="purposeOptions"
          [(ngModel)]="signUp.purpose"
          placeholder="Select Purpose / Program for Donation"
          [ngModelOptions]="{ standalone: true }"
          [searchable]="false"
          [clearable]="false"
          (click)="removeInvalid('#purposeSel')"
          hidden
          disabled
        >
        </ng-select>
        <p id="purpose_error">Please Select Purpose</p>

        <p id="card-error" role="alert">{{ stripeError }}</p>

        <div class="lastStepBtnWrapper">
          <div class="lastBackBtn">
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.3108 12.6866C10.4493 12.6897 10.5854 12.6495 10.7 12.5716C10.8147 12.4938 10.9022 12.3821 10.9505 12.2522C10.9987 12.1223 11.0053 11.9807 10.9693 11.8468C10.9333 11.713 10.8565 11.5938 10.7496 11.5057L6.67018 8.01087L10.7496 4.51731C10.8236 4.46296 10.8855 4.39395 10.9315 4.31454C10.9776 4.23514 11.0067 4.14706 11.0172 4.05587C11.0276 3.96468 11.0191 3.87233 10.9922 3.78458C10.9653 3.69683 10.9205 3.61556 10.8607 3.5459C10.801 3.47625 10.7275 3.41974 10.6448 3.37981C10.5622 3.33988 10.4722 3.31745 10.3805 3.31391C10.2887 3.31037 10.1973 3.32576 10.1118 3.3592C10.0263 3.39265 9.94868 3.44338 9.88372 3.50822L5.21315 7.50437C5.1398 7.56696 5.0809 7.64465 5.04052 7.7322C5.00013 7.81976 4.97922 7.91506 4.97922 8.01149C4.97922 8.10791 5.00013 8.20321 5.04052 8.29077C5.0809 8.37833 5.1398 8.45609 5.21315 8.51868L9.88372 12.5187C10.0017 12.6235 10.153 12.6829 10.3108 12.6866Z"
                fill="#333638"
              />
            </svg>
            <a href="javascript:;" (click)="DonateForm = 'cardForm'">Back</a>
          </div>
          <ng-container>
            <div class="nextStep last" *ngIf="!loading">
              <a href="javascript:;" (click)="validateFormbill()"
                >Make Payment</a
              >
            </div>
            <!-- Loader -->
            <div class="nextStep last" *ngIf="loading">
              <a href="javascript:;"
                >Make Payment
                <i class="fa fa-spinner fa-spin"></i>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
