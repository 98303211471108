<div class="blood_desc">
  <p>
    The goal of Our Flagship Project is to facilitate those angels and fairies
    who are fighting against Thalassemia, Hemophilia, Blood cancer, dengue and
    other emergency cases.
  </p>
</div>
<div class="our_partner">
  <p class="partner_head">In partnership with</p>
  <div class="main_partner">
    <div class="single_partner">
      <div>
        <img src="assets/images/pims.png" alt="PIMS" />
      </div>

      <div>
        <p style="width: 54px; padding-top: 15px">PIMS Hospital</p>
      </div>
    </div>
    <div class="single_partner">
      <div>
        <img src="assets/images/ptws.png" alt="Pakistan Thalassemia" />
      </div>

      <div>
        <p style="width: 142px">Pakistan Thalassemia Welfare Society</p>
      </div>
    </div>
    <div class="single_partner">
      <div>
        <img src="assets/images/jsf.png" alt="Jamila Sultana" />
      </div>

      <div>
        <p style="width: 95px">Jamila Sultana Foundation</p>
      </div>
    </div>
    <div class="single_partner">
      <div>
        <img src="assets/images/sundas.png" alt="Sundas Foundation" />
      </div>

      <div>
        <p style="width: 75px; padding-top: 15px">Sundas Foundation</p>
      </div>
    </div>
  </div>
</div>
<section class="blood_campus">
  <h2>Our Successful Blood Camps:</h2>
  <div class="main_campus">
    <div class="main_campus_left">
      <div>
        <p>
          In Pakistan, more than 1.5 million bags are required yearly for blood
          transfusion.
        </p>
        <p>
          According to recent estimates, only 28 out of 10,000 people donate
          blood.
        </p>
        <p>
          Out of these, only 11 percent of the donations are by people who don’t
          have a friend or family in immediate need.
        </p>
        <p>Take a look at our successful blood camps:</p>
      </div>

      <div class="play-icon">
        <a
          data-fancybox="testimonial-video"
          href="https://youtu.be/upZPV05rjJI"
        >
          <img src="assets/images/blood.png" alt="hero background image" />
          <span>
            <svg
              width="59"
              height="41"
              viewBox="0 0 59 41"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M57.4956 6.35438C56.8281 3.86963 54.8716 1.91092 52.3904 1.24185C47.8575 0 29.7257 0 29.7257 0C29.7257 0 11.5947 0 7.0618 1.19477C4.62832 1.86313 2.62405 3.86998 1.95655 6.35438C0.763672 10.8927 0.763672 20.3046 0.763672 20.3046C0.763672 20.3046 0.763672 29.764 1.95655 34.2549C2.62476 36.7393 4.58059 38.698 7.06215 39.3671C11.6424 40.6093 29.7264 40.6093 29.7264 40.6093C29.7264 40.6093 47.8575 40.6093 52.3904 39.4145C54.8719 38.7458 56.8281 36.7871 57.4963 34.3027C58.6888 29.764 58.6888 20.3524 58.6888 20.3524C58.6888 20.3524 58.7366 10.8927 57.4956 6.35438ZM23.9329 29.1337L23.953 11.6096L39.0199 19.962L23.9329 29.1337Z"
                fill="#00AAE9"
                fill-opacity="0.8"
              />
              <path
                d="M23.9336 29.1343V11.5953L39.0205 19.962L23.9336 29.1343Z"
                fill="white"
                fill-opacity="0.8"
              />
            </svg>
          </span>
        </a>
      </div>
    </div>
    <div class="main_campus_right">
      <ul>
        <li>
          6th Road
          <p>Total camps: 17 <span>Total collection: 681</span></p>
        </li>
        <li>
          Allama Iqbal park
          <p>Total camps: 17 <span>Total collection: 532</span></p>
        </li>
        <li>
          Liaquat Bagh
          <p>Total camps: 14 <span>Total collection: 514</span></p>
        </li>
        <li>
          Khana pull
          <p>Total camps: 08 <span>Total collection: 310</span></p>
        </li>
        <li>
          Rehmanabad
          <p>Total camps: 08 <span>Total collection: 235</span></p>
        </li>
      </ul>
    </div>
  </div>
</section>
