import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SEOService } from 'src/app/services/_services';
declare var $, Swiper, window;
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css']
})
export class AboutUsComponent implements OnInit {
  public selectedDiv: any = 1;
  public selectedFaq: any = 'all';
  constructor(private route: ActivatedRoute,private seoService: SEOService,) { }

  ngOnInit(): void {

    this.route.fragment.subscribe(fragment => {
      if (fragment) {
        const element = document.querySelector(`#${fragment}`);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth' });
        }
      }
    });

    $(document).ready(function () {
      $('.card .card-body').hide();

      $('.card').click(function () {
        if ($(this).hasClass("show")) {

          $(this).removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });

   
      if ($(window).width() <= 575) {



        $(".temp__swiper").addClass('swiper swiper-container');
        $(".pakTeamwraPPer").addClass('swiper-wrapper');
        $(".pakTeamwraPPer .teammember").addClass('swiper-slide');
        var swiper = new Swiper(".subSwiper", {
          slidesPerView: 1,
          //  spaceBetween: 50,
          // centeredSlides: true,	
          loop: true,
          pagination: {
            el: ".mpwRev-pagination",
            clickable: true
          }
        });
        

        // denswiper
        // $(".den__swiper").addClass('swiper swiper-container');
        // $(".denTeamwraPPer").addClass('swiper-wrapper');
        // $(".denTeamwraPPer .teammember").addClass('swiper-slide');
        // var swiper = new Swiper(".denSwiper", {
        //   slidesPerView: 1,
        //   //  spaceBetween: 50,
        //   // centeredSlides: true,	
        //   loop: true,
        //   pagination: {
        //     el: ".denpag-pagination",
        //     clickable: true
        //   }
        // });

        // getinvol
        $(".stotyparent").addClass('swiper swiper-container');
        $(".organizationEvent").addClass('swiper-wrapper');
        $(".organizationEvent").removeClass('denTeamwraPPer');
        $(".organizationEvent .teammember").addClass('swiper-slide');
        var swiper = new Swiper(".stotyparent", {
          slidesPerView: 1,
          spaceBetween: 20,
          // centeredSlides: true,	
          loop: true,
          pagination: {
            el: ".denpag-pagination",
            clickable: true
          }
        });


        // usa swiper
        $(".usa__swiper").addClass('swiper swiper-container');
        $(".usaWraper").addClass('swiper-wrapper');
        $(".usaWraper .teammember").addClass('swiper-slide');
        var swiper = new Swiper(".usaswiper", {
          slidesPerView: 1,
          //  spaceBetween: 50,
          // centeredSlides: true,	
          loop: true,
          pagination: {
            el: ".usa-pagination",
            clickable: true
          }
        });




      }

      // $(".scroll-link").click(function (e) {
      //   e.preventDefault();
      //   var scrollTo = $(this).data("scroll-to");
      //   $('html, body').animate({
      //     scrollTop: $(scrollTo).offset().top
      //   }, 1000);
      // });

      $(".scroll-link").click(function (e) {
        
        e.preventDefault();
        var scrollTo = $(this).data("scroll-to");
        let headerHeight = $(".navbar").outerHeight();
        console.log(headerHeight);
        $('html, body').animate({
          scrollTop: $(scrollTo).offset().top - headerHeight
        }, 1000);
      });


   

      this.setSEO_Tags("About Us | Vision and Mission of Pakistan Sweet Home","Learn about us: Pakistan Sweet Home’s mission is to provide care, education, and shelter to orphans across Pakistan.")

  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  showDiv(divNumber) {
    this.selectedDiv = divNumber;
  }
  showFaq(faqNumber) {
    this.selectedFaq = faqNumber;
  }

}
