import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-federal-education-minister',
  templateUrl: './federal-education-minister.component.html',
  styleUrls: ['./federal-education-minister.component.css' , '../articles.component.css' ]
})
export class FederalEducationMinisterComponent implements OnInit {

  constructor( private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Federal Education Minister announced PKR 6 crore for Orphans","The Federal Education Minister announced PKR 6 crore in funding for Pakistan Sweet Home to enhance facilities for orphans.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
