import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class TtqTrackingService {

  constructor() { }

  identify(email: string, phoneNumber: string, externalId: string) {
    // Hash email, phone number, and external ID using SHA-256
    const hashedEmail = CryptoJS.SHA256(email).toString(CryptoJS.enc.Hex);
    const hashedPhoneNumber = CryptoJS.SHA256(phoneNumber).toString(CryptoJS.enc.Hex);
    const hashedExternalId = CryptoJS.SHA256(externalId).toString(CryptoJS.enc.Hex);

    // Replace pixel placeholders with hashed values
    const pixelCode = `
      ttq.identify({
        "email": "${hashedEmail}",
        "phone_number": "${hashedPhoneNumber}",
        "external_id": "${hashedExternalId}"
      });
    `;

    // Inject the pixel code into the DOM
    this.injectPixel(pixelCode);
  }

  track(event: string, value: number, currency: string, contentId: string, contentType: string, contentName: string, price?: number) {
    // Replace pixel placeholders with actual values
    const pixelCode = `
      ttq.track('${event}', {
        "value": ${value},
        "currency": "${currency}",
        "contents": [
          {
            "content_id": "${contentId}",
            "content_type": "${contentType}",
            "content_name": "${contentName}",
            ${price ? `"price": ${price}` : ''}
          }
        ]
      });
    `;

    // Inject the pixel code into the DOM
    this.injectPixel(pixelCode);
  }

  private injectPixel(pixelCode: string) {
    const script = document.createElement('script');
    script.innerHTML = pixelCode;
    document.head.appendChild(script);
  }

}
