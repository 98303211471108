<section>
  <div class="container" style="max-height: 700px">
    <div class="inner-container">
      <img src="assets/new-images/404.png" alt="404-image" />
      <h1 class="heading">The Page you are looking for doesn't exist.</h1>
      <ul class="navLinks">
        <li>
          <a href="javascript:;" routerLink="/">Back to Homepage </a>
        </li>
      </ul>
    </div>
  </div>
</section>
