<section class="applyNowSec">
  <div class="container">
    <div class="allWrapUp">
      <div class="stepsWrapperOuter">
        <div class="stepsWrapper">
          <div
            class="singleStep"
            [ngClass]="formStep == 'candidate_info' ? 'ongoing' : 'completed'"
          >
            <p class="stepNumber">1</p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <p class="stepName">Candidate Information</p>
          </div>

          <svg
            width="70"
            height="2"
            viewBox="0 0 70 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1"
              x2="70"
              y2="1"
              stroke="#EEEEF1"
              stroke-width="2"
              stroke-dasharray="4 4"
            />
          </svg>

          <div
            class="singleStep"
            [ngClass]="
              formStep == 'academic_info'
                ? 'ongoing'
                : formStep == 'essay_questions'
                ? 'completed'
                : ''
            "
          >
            <p class="stepNumber">2</p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <p class="stepName">Academic Information</p>
          </div>

          <svg
            width="70"
            height="2"
            viewBox="0 0 70 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1"
              x2="70"
              y2="1"
              stroke="#EEEEF1"
              stroke-width="2"
              stroke-dasharray="4 4"
            />
          </svg>

          <div
            class="singleStep"
            [ngClass]="formStep == 'essay_questions' ? 'ongoing' : ''"
          >
            <p class="stepNumber">3</p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <p class="stepName">Essay Questions</p>
          </div>

          <svg
            width="70"
            height="2"
            viewBox="0 0 70 2"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              y1="1"
              x2="70"
              y2="1"
              stroke="#EEEEF1"
              stroke-width="2"
              stroke-dasharray="4 4"
            />
          </svg>

          <div class="singleStep">
            <p class="stepNumber">4</p>
            <svg
              width="30"
              height="30"
              viewBox="0 0 40 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white"
                stroke="white"
              />
            </svg>
            <p class="stepName">Review & Submit</p>
          </div>
        </div>
      </div>

      <div class="formWrapper">
        <form #admissionForm="ngForm" class="admissionForm">
          <ng-container *ngIf="formStep == 'candidate_info'">
            <h2 class="activeStepHead">Candidate Information</h2>
            <div class="doubleWrapper">
              <div class="inputGroup">
                <p class="asLabel">Full Name<span class="redLab">*</span></p>
                <input
                  class="firstname_input"
                  type="text"
                  name="candidateInfoName"
                  placeholder="Name of candidate"
                  required=""
                  [(ngModel)]="candidateInfo.name"
                  (keydown)="inputKeyup($event.target)"
                />
                <p class="error">Enter Full Name</p>
              </div>
              <div class="inputGroup">
                <p class="asLabel">Gender<span class="redLab">*</span></p>
                <ng-select
                  class="admissionSelect admissionSelectNew"
                  [items]="genderOptions"
                  [(ngModel)]="candidateInfo.gender"
                  placeholder="Select gender"
                  name="gender"
                  [searchable]="false"
                  [clearable]="false"
                  required=""
                  id="gender"
                  (change)="removeInvalid('#gender')"
                >
                </ng-select>
                <p class="error">Please Select Grade</p>
              </div>
            </div>
            <div class="doubleWrapper">
              <div class="inputGroup">
                <p class="asLabel">Father Name<span class="redLab">*</span></p>
                <input
                  class="firstname_input"
                  type="text"
                  name="candidatFatherName"
                  placeholder="Enter Father Name"
                  required=""
                  [(ngModel)]="candidateInfo.father_name"
                  (keydown)="inputKeyup($event.target)"
                />
                <p class="error">Enter Valid Father Name</p>
              </div>
              <div class="inputGroup">
                <p class="asLabel">
                  B-Form Or CNIC<span class="redLab">*</span>
                </p>
                <input
                  mask="00000-0000000-0"
                  id="cnicNum"
                  class="firstname_input"
                  type="text"
                  name="cnicNum"
                  placeholder="xxxxx-xxxx-xxxx"
                  required=""
                  [(ngModel)]="candidateInfo.cnic"
                  (keydown)="inputKeyup($event.target)"
                />
                <p class="error">Enter Valid CNIC</p>
              </div>
            </div>
            <div class="doubleWrapper">
              <div class="DOBWrapper inputWrapper">
                <p class="asLabel" for="profileUrl">
                  Date Of Birth<span class="redLab">*</span>
                </p>
                <div class="doubleInput" [ngClass]="dobError ? 'invalid' : ''">
                  <ng-select
                    class="DOBSelectDate selectTrio selectTrioGrey"
                    id="dateDOB"
                    [items]="dateOptions"
                    [(ngModel)]="selectedDate"
                    placeholder="Date"
                    name="dateDOB"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="removeInvalid('#dateDOB'); checkDOB()"
                  >
                  </ng-select>
                  <ng-select
                    class="DOBSelectMonth selectTrio selectTrioGrey"
                    id="monthDOB"
                    [items]="monthOptions"
                    [(ngModel)]="selectedMonth"
                    bindValue="value"
                    bindLabel="name"
                    placeholder="Month"
                    name="monthDOB"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="removeInvalid('#monthDOB'); checkDOB()"
                  >
                  </ng-select>
                  <ng-select
                    class="DOBSelectYear selectTrio selectTrioGrey"
                    id="yearDOB"
                    [items]="yearOptions"
                    [(ngModel)]="selectedYear"
                    placeholder="Year"
                    name="yearDOB"
                    [searchable]="false"
                    [clearable]="false"
                    (change)="removeInvalid('#yearDOB'); checkDOB()"
                  >
                  </ng-select>
                </div>
                <p class="error">Please Select Date of Birth</p>
              </div>
              <div class="inputGroup">
                <p class="asLabel">
                  Applying Class<span class="redLab">*</span>
                </p>
                <ng-select
                  class="admissionSelect admissionSelectNew"
                  [items]="applyingClassOptions"
                  [(ngModel)]="candidateInfo.applying_class"
                  placeholder="Select Applying Class"
                  name="applyingClass"
                  [searchable]="false"
                  [clearable]="false"
                  required=""
                  id="applyingClass"
                  (change)="removeInvalid('#applyingClass')"
                >
                </ng-select>
                <p class="error">Please Select Applying Class</p>
              </div>
            </div>
            <h2 class="typeInfoHead">Contact Information</h2>
            <div class="inputGroup full">
              <p class="asLabel">
                Current Address<span class="redLab">*</span>
              </p>
              <input
                class="firstname_input"
                type="text"
                name="address"
                placeholder="Enter Full Address"
                required=""
                [(ngModel)]="candidateInfo.address"
                (keydown)="inputKeyup($event.target)"
              />
              <p class="error">Enter Full Address</p>
            </div>
            <div class="doubleWrapper">
              <div class="inputGroup">
                <p class="asLabel">City<span class="redLab">*</span></p>
                <!-- <input class="firstname_input" type="text" name="city"
                        placeholder="Enter City" required="" [(ngModel)]="candidateInfo.city" (keydown)="inputKeyup($event.target)"> -->
                <ng-select
                  class="admissionSelect admissionSelectNew"
                  [items]="cityOptions"
                  [(ngModel)]="candidateInfo.city"
                  placeholder="Select City"
                  name="citySelect"
                  [searchable]="false"
                  [clearable]="false"
                  required=""
                  id="citySelect"
                  (change)="removeInvalid('#citySelect')"
                >
                </ng-select>
                <p class="error">Enter City</p>
              </div>
              <div class="inputGroup">
                <p class="asLabel">
                  Whatsapp Number<span class="redLab">*</span>
                </p>
                <input
                  mask="(000) 00000099"
                  class="phoneInput"
                  id="whatsappNumber"
                  type="text"
                  name="whatsappNumber"
                  placeholder="(3xx) xxxxxxx"
                  [(ngModel)]="candidateInfo.whatsapp.number"
                  (keypress)="isNumberValidate($event, 'whatsapp')"
                  (keydown)="inputKeyup($event.target)"
                  required
                />
                <p class="error">Enter Whatsapp Number</p>
                <span class="countryCode"
                  >+{{ candidateInfo.whatsapp.code }}</span
                >
                <div class="flagsWrapper">
                  <div class="coutryFlags">
                    <div
                      (click)="
                        setPhoneValues('whatsapp', country, flagOptions[i])
                      "
                      *ngFor="let country of countryOptions; index as i"
                      class="singleFlag"
                    >
                      <img
                        [src]="
                          'https://img.mobiscroll.com/demos/flags/' +
                          flagOptions[i] +
                          '.png'
                        "
                        alt="country"
                      />
                      <p>{{ country }}</p>
                    </div>
                  </div>
                  <div class="selectedFlag">
                    <img
                      [src]="
                        'https://img.mobiscroll.com/demos/flags/' +
                        candidateInfo.whatsapp.country +
                        '.png'
                      "
                      alt=""
                    />
                    <svg
                      width="11"
                      height="6"
                      viewBox="0 0 11 6"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z"
                        fill="#5E5E5E"
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="formStep == 'academic_info'">
            <h2 class="activeStepHead">Academic Information</h2>
            <div class="doubleWrapper">
              <div class="inputGroup">
                <p class="asLabel">
                  Previous Class<span class="redLab">*</span>
                </p>
                <ng-select
                  class="admissionSelect admissionSelectNew"
                  [items]="educationLevelOptions"
                  [(ngModel)]="candidateInfo.education_level"
                  placeholder="Select Previous Class"
                  name="educationLevel"
                  [searchable]="false"
                  [clearable]="false"
                  required=""
                  id="educationLevel"
                  (change)="removeInvalid('#educationLevel')"
                >
                </ng-select>
                <p class="error">Please Select Previous Class</p>
              </div>
              <div class="inputGroup">
                <p class="asLabel">
                  Previous Grades<span class="redLab">*</span>
                </p>
                <!-- <input class="firstname_input" type="text" name="currentGPA"
                    placeholder="Enter your previous grades" required="" [(ngModel)]="candidateInfo.current_GPA" (keydown)="inputKeyup($event.target)" /> -->
                <ng-select
                  class="admissionSelect admissionSelectNew"
                  [items]="gradeOptions"
                  [(ngModel)]="candidateInfo.current_GPA"
                  placeholder="A+, A, B+,B,C+,C"
                  name="currentGrade"
                  [searchable]="false"
                  [clearable]="false"
                  required=""
                  id="currentGrade"
                  (change)="removeInvalid('#currentGrade')"
                >
                </ng-select>
                <p class="error">Select Previous Grades</p>
              </div>
            </div>
            <h2 class="typeInfoHead">Attachments</h2>
            <div class="doubleWrapper">
              <div class="fileUpload cnicUpload">
                <input
                  type="file"
                  id="deathCertificate"
                  name="deathCertificate"
                  (change)="saveFile($event, 'deathCertificate')"
                  required=""
                  accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
                />
                <label for="deathCertificate">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9337)">
                      <path
                        d="M26.203 4.47587H23.6729V1.9458C23.6729 1.15057 23.0222 0.5 22.2274 0.5H3.7934C2.99822 0.5 2.34766 1.15057 2.34766 1.9458V25.0783C2.34766 25.8734 2.99822 26.5241 3.79345 26.5241H6.32359V29.0542C6.32359 29.8494 6.97386 30.5 7.76938 30.5H20.0582C20.853 30.5 21.9778 30.054 22.5565 29.5093L26.5953 25.7076C27.1747 25.1629 27.6482 24.0663 27.6482 23.2711L27.6489 5.92179C27.6488 5.12649 26.9982 4.47587 26.203 4.47587ZM6.32347 5.92173V25.4397H3.7934C3.59752 25.4397 3.43199 25.2742 3.43199 25.0783V1.9458C3.43199 1.74992 3.59752 1.58439 3.7934 1.58439H22.2275C22.423 1.58439 22.5889 1.74992 22.5889 1.9458V4.47593H7.76927C6.97374 4.47593 6.32347 5.1265 6.32347 5.92173ZM25.8521 24.9181L21.8133 28.7195C21.7305 28.7973 21.6228 28.8735 21.5038 28.9461V25.6204C21.5038 25.3721 21.604 25.1487 21.7692 24.9825C21.9358 24.8173 22.1592 24.7169 22.4075 24.7169H26.0238C25.9677 24.793 25.9103 24.8632 25.8521 24.9181ZM26.5637 23.271C26.5637 23.3784 26.5441 23.5027 26.5145 23.6324H22.4074C21.3105 23.6346 20.4217 24.5234 20.4195 25.6204V29.3718C20.2904 29.3979 20.1661 29.4155 20.0581 29.4155H7.76927V29.4156C7.57298 29.4156 7.40786 29.25 7.40786 29.0541V5.92167C7.40786 5.72579 7.57304 5.56026 7.76927 5.56026H26.2029C26.3988 5.56026 26.5643 5.72579 26.5643 5.92167L26.5637 23.271Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 9.53516H11.7453C11.4456 9.53516 11.2031 9.77767 11.2031 10.0773C11.2031 10.377 11.4457 10.6195 11.7453 10.6195H22.2272C22.5265 10.6195 22.7693 10.377 22.7693 10.0773C22.7693 9.77767 22.5265 9.53516 22.2272 9.53516Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 16.7656H11.7453C11.4456 16.7656 11.2031 17.0081 11.2031 17.3078C11.2031 17.6074 11.4457 17.85 11.7453 17.85H22.2272C22.5265 17.85 22.7693 17.6074 22.7693 17.3078C22.7693 17.0081 22.5265 16.7656 22.2272 16.7656Z"
                        fill="black"
                      />
                      <path
                        d="M17.5284 20.3789H11.7453C11.4456 20.3789 11.2031 20.6214 11.2031 20.9211C11.2031 21.2207 11.4456 21.4632 11.7453 21.4632H17.5284C17.8277 21.4632 18.0702 21.2207 18.0702 20.9211C18.0702 20.6214 17.8277 20.3789 17.5284 20.3789Z"
                        fill="black"
                      />
                      <path
                        d="M22.2311 13.1504H11.7492C11.4496 13.1504 11.207 13.3929 11.207 13.6926C11.207 13.9922 11.4496 14.2347 11.7492 14.2347H22.2311C22.5304 14.2347 22.7732 13.9922 22.7732 13.6926C22.7732 13.3929 22.5304 13.1504 22.2311 13.1504Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9337">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    <span
                      >Upload Fathers Death Certificate<span class="redLab"
                        >*</span
                      >
                    </span>
                    <span
                      [ngClass]="
                        candidateInfo.father_death_certificate &&
                        candidateInfo.father_death_certificate.name
                          ? 'fileAdded'
                          : ''
                      "
                      >{{
                        candidateInfo?.father_death_certificate?.name
                          ? candidateInfo.father_death_certificate.name
                          : candidateInfo.father_death_certificate
                          ? candidateInfo.father_death_certificate
                              .split("/")
                              .pop()
                          : "(max file size 2mb, accepts .jpg, .png)"
                      }}</span
                    >
                  </p>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9356)">
                      <path
                        d="M10 20.5C9.741 20.5 9.49261 20.3971 9.30947 20.214C9.12632 20.0308 9.02344 19.7824 9.02344 19.5234V1.47656C9.02344 1.21756 9.12632 0.96917 9.30947 0.786029C9.49261 0.602888 9.741 0.5 10 0.5C10.259 0.5 10.5074 0.602888 10.6905 0.786029C10.8737 0.96917 10.9766 1.21756 10.9766 1.47656V19.5234C10.9766 19.7824 10.8737 20.0308 10.6905 20.214C10.5074 20.3971 10.259 20.5 10 20.5Z"
                        fill="black"
                      />
                      <path
                        d="M19.0234 11.4766H0.976562C0.717562 11.4766 0.46917 11.3737 0.286029 11.1905C0.102888 11.0074 0 10.759 0 10.5C0 10.241 0.102888 9.99261 0.286029 9.80947C0.46917 9.62632 0.717562 9.52344 0.976562 9.52344H19.0234C19.2824 9.52344 19.5308 9.62632 19.714 9.80947C19.8971 9.99261 20 10.241 20 10.5C20 10.759 19.8971 11.0074 19.714 11.1905C19.5308 11.3737 19.2824 11.4766 19.0234 11.4766Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9356">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <p
                  class="error"
                  *ngIf="!candidateInfo.father_death_certificate"
                >
                  Please Upload Fathers Death Certificate
                </p>
              </div>

              <div class="fileUpload cnicUpload">
                <input
                  type="file"
                  id="cnicFile"
                  name="cnicFile"
                  (change)="saveFile($event, 'cnicFile')"
                  required=""
                  accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
                />
                <label for="cnicFile">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9337)">
                      <path
                        d="M26.203 4.47587H23.6729V1.9458C23.6729 1.15057 23.0222 0.5 22.2274 0.5H3.7934C2.99822 0.5 2.34766 1.15057 2.34766 1.9458V25.0783C2.34766 25.8734 2.99822 26.5241 3.79345 26.5241H6.32359V29.0542C6.32359 29.8494 6.97386 30.5 7.76938 30.5H20.0582C20.853 30.5 21.9778 30.054 22.5565 29.5093L26.5953 25.7076C27.1747 25.1629 27.6482 24.0663 27.6482 23.2711L27.6489 5.92179C27.6488 5.12649 26.9982 4.47587 26.203 4.47587ZM6.32347 5.92173V25.4397H3.7934C3.59752 25.4397 3.43199 25.2742 3.43199 25.0783V1.9458C3.43199 1.74992 3.59752 1.58439 3.7934 1.58439H22.2275C22.423 1.58439 22.5889 1.74992 22.5889 1.9458V4.47593H7.76927C6.97374 4.47593 6.32347 5.1265 6.32347 5.92173ZM25.8521 24.9181L21.8133 28.7195C21.7305 28.7973 21.6228 28.8735 21.5038 28.9461V25.6204C21.5038 25.3721 21.604 25.1487 21.7692 24.9825C21.9358 24.8173 22.1592 24.7169 22.4075 24.7169H26.0238C25.9677 24.793 25.9103 24.8632 25.8521 24.9181ZM26.5637 23.271C26.5637 23.3784 26.5441 23.5027 26.5145 23.6324H22.4074C21.3105 23.6346 20.4217 24.5234 20.4195 25.6204V29.3718C20.2904 29.3979 20.1661 29.4155 20.0581 29.4155H7.76927V29.4156C7.57298 29.4156 7.40786 29.25 7.40786 29.0541V5.92167C7.40786 5.72579 7.57304 5.56026 7.76927 5.56026H26.2029C26.3988 5.56026 26.5643 5.72579 26.5643 5.92167L26.5637 23.271Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 9.53516H11.7453C11.4456 9.53516 11.2031 9.77767 11.2031 10.0773C11.2031 10.377 11.4457 10.6195 11.7453 10.6195H22.2272C22.5265 10.6195 22.7693 10.377 22.7693 10.0773C22.7693 9.77767 22.5265 9.53516 22.2272 9.53516Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 16.7656H11.7453C11.4456 16.7656 11.2031 17.0081 11.2031 17.3078C11.2031 17.6074 11.4457 17.85 11.7453 17.85H22.2272C22.5265 17.85 22.7693 17.6074 22.7693 17.3078C22.7693 17.0081 22.5265 16.7656 22.2272 16.7656Z"
                        fill="black"
                      />
                      <path
                        d="M17.5284 20.3789H11.7453C11.4456 20.3789 11.2031 20.6214 11.2031 20.9211C11.2031 21.2207 11.4456 21.4632 11.7453 21.4632H17.5284C17.8277 21.4632 18.0702 21.2207 18.0702 20.9211C18.0702 20.6214 17.8277 20.3789 17.5284 20.3789Z"
                        fill="black"
                      />
                      <path
                        d="M22.2311 13.1504H11.7492C11.4496 13.1504 11.207 13.3929 11.207 13.6926C11.207 13.9922 11.4496 14.2347 11.7492 14.2347H22.2311C22.5304 14.2347 22.7732 13.9922 22.7732 13.6926C22.7732 13.3929 22.5304 13.1504 22.2311 13.1504Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9337">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    <span
                      >Upload B-Form Or CNIC<span class="redLab">*</span>
                    </span>
                    <span
                      [ngClass]="
                        candidateInfo.cnic_img && candidateInfo.cnic_img.name
                          ? 'fileAdded'
                          : ''
                      "
                      >{{
                        candidateInfo?.cnic_img?.name
                          ? candidateInfo.cnic_img.name
                          : candidateInfo.cnic_img
                          ? candidateInfo.cnic_img.split("/").pop()
                          : "(max file size 2mb, accepts .jpg, .png)"
                      }}</span
                    >
                  </p>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9356)">
                      <path
                        d="M10 20.5C9.741 20.5 9.49261 20.3971 9.30947 20.214C9.12632 20.0308 9.02344 19.7824 9.02344 19.5234V1.47656C9.02344 1.21756 9.12632 0.96917 9.30947 0.786029C9.49261 0.602888 9.741 0.5 10 0.5C10.259 0.5 10.5074 0.602888 10.6905 0.786029C10.8737 0.96917 10.9766 1.21756 10.9766 1.47656V19.5234C10.9766 19.7824 10.8737 20.0308 10.6905 20.214C10.5074 20.3971 10.259 20.5 10 20.5Z"
                        fill="black"
                      />
                      <path
                        d="M19.0234 11.4766H0.976562C0.717562 11.4766 0.46917 11.3737 0.286029 11.1905C0.102888 11.0074 0 10.759 0 10.5C0 10.241 0.102888 9.99261 0.286029 9.80947C0.46917 9.62632 0.717562 9.52344 0.976562 9.52344H19.0234C19.2824 9.52344 19.5308 9.62632 19.714 9.80947C19.8971 9.99261 20 10.241 20 10.5C20 10.759 19.8971 11.0074 19.714 11.1905C19.5308 11.3737 19.2824 11.4766 19.0234 11.4766Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9356">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <p class="error" *ngIf="!candidateInfo.cnic_img">
                  Please Upload CNIC Image
                </p>
              </div>
            </div>

            <div class="doubleWrapper">
              <div class="fileUpload cnicUpload">
                <input
                  type="file"
                  id="academicRecords"
                  name="academicRecords"
                  (change)="saveFile($event, 'academicRecords')"
                  required=""
                  accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
                  multiple
                />
                <label for="academicRecords">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9337)">
                      <path
                        d="M26.203 4.47587H23.6729V1.9458C23.6729 1.15057 23.0222 0.5 22.2274 0.5H3.7934C2.99822 0.5 2.34766 1.15057 2.34766 1.9458V25.0783C2.34766 25.8734 2.99822 26.5241 3.79345 26.5241H6.32359V29.0542C6.32359 29.8494 6.97386 30.5 7.76938 30.5H20.0582C20.853 30.5 21.9778 30.054 22.5565 29.5093L26.5953 25.7076C27.1747 25.1629 27.6482 24.0663 27.6482 23.2711L27.6489 5.92179C27.6488 5.12649 26.9982 4.47587 26.203 4.47587ZM6.32347 5.92173V25.4397H3.7934C3.59752 25.4397 3.43199 25.2742 3.43199 25.0783V1.9458C3.43199 1.74992 3.59752 1.58439 3.7934 1.58439H22.2275C22.423 1.58439 22.5889 1.74992 22.5889 1.9458V4.47593H7.76927C6.97374 4.47593 6.32347 5.1265 6.32347 5.92173ZM25.8521 24.9181L21.8133 28.7195C21.7305 28.7973 21.6228 28.8735 21.5038 28.9461V25.6204C21.5038 25.3721 21.604 25.1487 21.7692 24.9825C21.9358 24.8173 22.1592 24.7169 22.4075 24.7169H26.0238C25.9677 24.793 25.9103 24.8632 25.8521 24.9181ZM26.5637 23.271C26.5637 23.3784 26.5441 23.5027 26.5145 23.6324H22.4074C21.3105 23.6346 20.4217 24.5234 20.4195 25.6204V29.3718C20.2904 29.3979 20.1661 29.4155 20.0581 29.4155H7.76927V29.4156C7.57298 29.4156 7.40786 29.25 7.40786 29.0541V5.92167C7.40786 5.72579 7.57304 5.56026 7.76927 5.56026H26.2029C26.3988 5.56026 26.5643 5.72579 26.5643 5.92167L26.5637 23.271Z"
                        fill="black"
                      ></path>
                      <path
                        d="M22.2272 9.53516H11.7453C11.4456 9.53516 11.2031 9.77767 11.2031 10.0773C11.2031 10.377 11.4457 10.6195 11.7453 10.6195H22.2272C22.5265 10.6195 22.7693 10.377 22.7693 10.0773C22.7693 9.77767 22.5265 9.53516 22.2272 9.53516Z"
                        fill="black"
                      ></path>
                      <path
                        d="M22.2272 16.7656H11.7453C11.4456 16.7656 11.2031 17.0081 11.2031 17.3078C11.2031 17.6074 11.4457 17.85 11.7453 17.85H22.2272C22.5265 17.85 22.7693 17.6074 22.7693 17.3078C22.7693 17.0081 22.5265 16.7656 22.2272 16.7656Z"
                        fill="black"
                      ></path>
                      <path
                        d="M17.5284 20.3789H11.7453C11.4456 20.3789 11.2031 20.6214 11.2031 20.9211C11.2031 21.2207 11.4456 21.4632 11.7453 21.4632H17.5284C17.8277 21.4632 18.0702 21.2207 18.0702 20.9211C18.0702 20.6214 17.8277 20.3789 17.5284 20.3789Z"
                        fill="black"
                      ></path>
                      <path
                        d="M22.2311 13.1504H11.7492C11.4496 13.1504 11.207 13.3929 11.207 13.6926C11.207 13.9922 11.4496 14.2347 11.7492 14.2347H22.2311C22.5304 14.2347 22.7732 13.9922 22.7732 13.6926C22.7732 13.3929 22.5304 13.1504 22.2311 13.1504Z"
                        fill="black"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9337">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.5)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    <span
                      >Most recent academic records<span class="redLab"
                        >*</span
                      ></span
                    >
                    <ng-container
                      *ngIf="!candidateInfo.academic_records.length"
                    >
                      <span> (max file size 2mb, accepts .jpg, .png) </span>
                    </ng-container>
                    <ng-container *ngIf="candidateInfo.academic_records.length">
                      <span class="fileAdded">
                        <ng-container
                          *ngFor="let record of candidateInfo.academic_records"
                        >
                          <ng-container *ngIf="record.name">
                            {{ record.name }} <br />
                          </ng-container>
                          <ng-container *ngIf="record.url">
                            {{ record.url | fileNameFromUrl }}
                          </ng-container>
                        </ng-container>
                      </span>
                    </ng-container>
                  </p>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9356)">
                      <path
                        d="M10 20.5C9.741 20.5 9.49261 20.3971 9.30947 20.214C9.12632 20.0308 9.02344 19.7824 9.02344 19.5234V1.47656C9.02344 1.21756 9.12632 0.96917 9.30947 0.786029C9.49261 0.602888 9.741 0.5 10 0.5C10.259 0.5 10.5074 0.602888 10.6905 0.786029C10.8737 0.96917 10.9766 1.21756 10.9766 1.47656V19.5234C10.9766 19.7824 10.8737 20.0308 10.6905 20.214C10.5074 20.3971 10.259 20.5 10 20.5Z"
                        fill="black"
                      />
                      <path
                        d="M19.0234 11.4766H0.976562C0.717562 11.4766 0.46917 11.3737 0.286029 11.1905C0.102888 11.0074 0 10.759 0 10.5C0 10.241 0.102888 9.99261 0.286029 9.80947C0.46917 9.62632 0.717562 9.52344 0.976562 9.52344H19.0234C19.2824 9.52344 19.5308 9.62632 19.714 9.80947C19.8971 9.99261 20 10.241 20 10.5C20 10.759 19.8971 11.0074 19.714 11.1905C19.5308 11.3737 19.2824 11.4766 19.0234 11.4766Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9356">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <p class="error" *ngIf="!candidateInfo.academic_records.length">
                  Please Upload Academic Records
                </p>
              </div>

              <div class="fileUpload cnicUpload">
                <input
                  type="file"
                  id="candidateImg"
                  name="candidateImg"
                  (change)="saveFile($event, 'candidateImg')"
                  required=""
                  accept="image/jpeg,image/jpg,image/gif,image/png,application/pdf"
                />
                <label for="candidateImg">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9337)">
                      <path
                        d="M26.203 4.47587H23.6729V1.9458C23.6729 1.15057 23.0222 0.5 22.2274 0.5H3.7934C2.99822 0.5 2.34766 1.15057 2.34766 1.9458V25.0783C2.34766 25.8734 2.99822 26.5241 3.79345 26.5241H6.32359V29.0542C6.32359 29.8494 6.97386 30.5 7.76938 30.5H20.0582C20.853 30.5 21.9778 30.054 22.5565 29.5093L26.5953 25.7076C27.1747 25.1629 27.6482 24.0663 27.6482 23.2711L27.6489 5.92179C27.6488 5.12649 26.9982 4.47587 26.203 4.47587ZM6.32347 5.92173V25.4397H3.7934C3.59752 25.4397 3.43199 25.2742 3.43199 25.0783V1.9458C3.43199 1.74992 3.59752 1.58439 3.7934 1.58439H22.2275C22.423 1.58439 22.5889 1.74992 22.5889 1.9458V4.47593H7.76927C6.97374 4.47593 6.32347 5.1265 6.32347 5.92173ZM25.8521 24.9181L21.8133 28.7195C21.7305 28.7973 21.6228 28.8735 21.5038 28.9461V25.6204C21.5038 25.3721 21.604 25.1487 21.7692 24.9825C21.9358 24.8173 22.1592 24.7169 22.4075 24.7169H26.0238C25.9677 24.793 25.9103 24.8632 25.8521 24.9181ZM26.5637 23.271C26.5637 23.3784 26.5441 23.5027 26.5145 23.6324H22.4074C21.3105 23.6346 20.4217 24.5234 20.4195 25.6204V29.3718C20.2904 29.3979 20.1661 29.4155 20.0581 29.4155H7.76927V29.4156C7.57298 29.4156 7.40786 29.25 7.40786 29.0541V5.92167C7.40786 5.72579 7.57304 5.56026 7.76927 5.56026H26.2029C26.3988 5.56026 26.5643 5.72579 26.5643 5.92167L26.5637 23.271Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 9.53516H11.7453C11.4456 9.53516 11.2031 9.77767 11.2031 10.0773C11.2031 10.377 11.4457 10.6195 11.7453 10.6195H22.2272C22.5265 10.6195 22.7693 10.377 22.7693 10.0773C22.7693 9.77767 22.5265 9.53516 22.2272 9.53516Z"
                        fill="black"
                      />
                      <path
                        d="M22.2272 16.7656H11.7453C11.4456 16.7656 11.2031 17.0081 11.2031 17.3078C11.2031 17.6074 11.4457 17.85 11.7453 17.85H22.2272C22.5265 17.85 22.7693 17.6074 22.7693 17.3078C22.7693 17.0081 22.5265 16.7656 22.2272 16.7656Z"
                        fill="black"
                      />
                      <path
                        d="M17.5284 20.3789H11.7453C11.4456 20.3789 11.2031 20.6214 11.2031 20.9211C11.2031 21.2207 11.4456 21.4632 11.7453 21.4632H17.5284C17.8277 21.4632 18.0702 21.2207 18.0702 20.9211C18.0702 20.6214 17.8277 20.3789 17.5284 20.3789Z"
                        fill="black"
                      />
                      <path
                        d="M22.2311 13.1504H11.7492C11.4496 13.1504 11.207 13.3929 11.207 13.6926C11.207 13.9922 11.4496 14.2347 11.7492 14.2347H22.2311C22.5304 14.2347 22.7732 13.9922 22.7732 13.6926C22.7732 13.3929 22.5304 13.1504 22.2311 13.1504Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9337">
                        <rect
                          width="30"
                          height="30"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <p>
                    <span
                      >Upload Candidate Image<span class="redLab">*</span>
                    </span>
                    <span
                      [ngClass]="
                        candidateInfo.candidate_img &&
                        candidateInfo.candidate_img.name
                          ? 'fileAdded'
                          : ''
                      "
                      >{{
                        candidateInfo?.candidate_img?.name
                          ? candidateInfo.candidate_img.name
                          : candidateInfo.candidate_img
                          ? candidateInfo.candidate_img.split("/").pop()
                          : "(max file size 2mb, accepts .jpg, .png)"
                      }}</span
                    >
                  </p>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6408_9356)">
                      <path
                        d="M10 20.5C9.741 20.5 9.49261 20.3971 9.30947 20.214C9.12632 20.0308 9.02344 19.7824 9.02344 19.5234V1.47656C9.02344 1.21756 9.12632 0.96917 9.30947 0.786029C9.49261 0.602888 9.741 0.5 10 0.5C10.259 0.5 10.5074 0.602888 10.6905 0.786029C10.8737 0.96917 10.9766 1.21756 10.9766 1.47656V19.5234C10.9766 19.7824 10.8737 20.0308 10.6905 20.214C10.5074 20.3971 10.259 20.5 10 20.5Z"
                        fill="black"
                      />
                      <path
                        d="M19.0234 11.4766H0.976562C0.717562 11.4766 0.46917 11.3737 0.286029 11.1905C0.102888 11.0074 0 10.759 0 10.5C0 10.241 0.102888 9.99261 0.286029 9.80947C0.46917 9.62632 0.717562 9.52344 0.976562 9.52344H19.0234C19.2824 9.52344 19.5308 9.62632 19.714 9.80947C19.8971 9.99261 20 10.241 20 10.5C20 10.759 19.8971 11.0074 19.714 11.1905C19.5308 11.3737 19.2824 11.4766 19.0234 11.4766Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6408_9356">
                        <rect
                          width="20"
                          height="20"
                          fill="white"
                          transform="translate(0 0.5)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </label>
                <p class="error" *ngIf="!candidateInfo.candidate_img">
                  Please Upload Candidate Image
                </p>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="formStep == 'essay_questions'">
            <h2 class="activeStepHead">Essay Questions</h2>
            <div class="inputGroup full">
              <p class="asLabel">
                Briefly describe your educational goals and why you believe you
                should receive this scholarship.<span class="redLab">*</span>
              </p>
              <textarea
                name="educationGoals"
                id="educationGoals"
                cols="30"
                rows="12"
                required
                (keydown)="inputKeyup($event.target)"
                (keyup)="countWords('educationGoals')"
                placeholder="Enter description here...."
                [(ngModel)]="candidateInfo.education_goals"
                required=""
              ></textarea>
              <p
                class="wordsCount"
                [ngClass]="education_goals_words > 600 ? 'red' : ''"
              >
                {{ education_goals_words }}/600 Words
              </p>
            </div>
            <div class="inputGroup full textareaWrapper">
              <p class="asLabel">
                What are your plans for the future and how will this scholarship
                help you achieve them?<span class="redLab">*</span>
              </p>
              <textarea
                name="futurePlan"
                id="futurePlan"
                cols="30"
                rows="12"
                required
                (keydown)="inputKeyup($event.target)"
                (keyup)="countWords('futurePlan')"
                placeholder="Enter description here...."
                [(ngModel)]="candidateInfo.future_plans"
                required=""
              ></textarea>
              <p
                class="wordsCount"
                [ngClass]="future_plan_words > 600 ? 'red' : ''"
              >
                {{ future_plan_words }}/600 Words
              </p>
            </div>
          </ng-container>

          <div class="submitButtonOuter">
            <button
              type="button"
              *ngIf="!disabledBtn && !editPage"
              class="saveDraftBtn"
              (click)="saveDraft()"
            >
              Save Draft
            </button>
            <button
              type="button"
              *ngIf="!disabledBtn"
              class="formSubmitBtn"
              (click)="changeStep()"
            >
              Next
            </button>
            <a
              href="javascript:;"
              *ngIf="disabledBtn"
              class="formSubmitBtn loaderBlue"
            >
              <img
                class="loadingImg"
                src="/assets/new-images/pshLoaderWhite.webp"
                alt="loading"
              />
            </a>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img
    id="loaderStatus"
    class="loaderImage"
    alt="logo"
    src="assets/new-images/blue_psh_logo.png"
  />
</div>
