<section class="applyNowSec" *ngIf="!loading && applicationInfo">
  <div class="container">
    <div class="allWrapUp">
      <div class="stepsWrapperOuter">
        <div class="stepsWrapper">
          <div class="singleStep completed">
            <p class="stepNumber">1</p>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Candidate Information</p>
          </div>

          <svg width="70" height="2" viewBox="0 0 70 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="70" y2="1" stroke="#EEEEF1" stroke-width="2" stroke-dasharray="4 4" />
          </svg>

          <div class="singleStep completed">
            <p class="stepNumber">2</p>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Academic Information</p>
          </div>

          <svg width="70" height="2" viewBox="0 0 70 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="70" y2="1" stroke="#EEEEF1" stroke-width="2" stroke-dasharray="4 4" />
          </svg>

          <div class="singleStep completed">
            <p class="stepNumber">3</p>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Essay Questions</p>
          </div>

          <svg width="70" height="2" viewBox="0 0 70 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="70" y2="1" stroke="#EEEEF1" stroke-width="2" stroke-dasharray="4 4" />
          </svg>


          <div class="singleStep ongoing">
            <p class="stepNumber">4</p>
            <svg width="30" height="30" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Review & Submit</p>
          </div>
        </div>
      </div>
    </div>
    <div class="reviewBox">
      <div class="generalDetails">
        <div class="inputDetailsWrapper">
          <div class="singleDetail">
            <p>Child Name:</p>
            <p>{{ applicationInfo.name }}</p>
          </div>
          <div class="singleDetail">
            <p>Father's Name:</p>
            <p>{{ applicationInfo.father_name }}</p>
          </div>
          <div class="singleDetail">
            <p>Gender:</p>
            <p>{{ applicationInfo.gender }}</p>
          </div>
          <div class="singleDetail">
            <p>Date of Birth:</p>
            <p>{{ applicationInfo.dob | date }}</p>
          </div>
          <div class="singleDetail">
            <p>B-Form Or CNIC:</p>
            <p>{{ applicationInfo.cnic }}</p>
          </div>
          <div class="singleDetail">
            <p>Current Address:</p>
            <p>{{ applicationInfo.address }}</p>
          </div>
          <div class="singleDetail">
            <p>City:</p>
            <p>{{ applicationInfo.city }}</p>
          </div>
          <div class="singleDetail">
            <p>Whatsapp Number:</p>
            <p>{{ applicationInfo.whatsapp }}</p>
          </div>
          <div class="singleDetail">
            <p>Current Level of Education:</p>
            <p>{{ applicationInfo.education_level }}</p>
          </div>
          <div class="singleDetail">
            <p>Current Grade Point Average (GPA) or Percentage:</p>
            <p>{{ applicationInfo.current_GPA }}</p>
          </div>
          <div class="singleDetail">
            <p>Candidate Photo:</p>
            <div class="singleFile">
              <a target="_blank"
                [href]="applicationInfo.candidate_photo">{{ applicationInfo.candidate_photo | fileNameFromUrl }}</a>
            </div>
          </div>
          <div class="singleDetail">
            <p>Fathers Death Certificate:</p>
            <div class="singleFile">
              <a target="_blank"
                [href]="applicationInfo.father_death_certificate">{{ applicationInfo.father_death_certificate | fileNameFromUrl }}</a>
            </div>
          </div>
          <div class="singleDetail">
            <p>B-Form Or CNIC:</p>
            <div class="singleFile">
              <a target="_blank" [href]="applicationInfo.cnic_img">{{ applicationInfo.cnic_img | fileNameFromUrl }}</a>
            </div>
          </div>
          <div class="singleDetail">
            <p>Academic Records:</p>
            <div class="singleFile" >
              <a target="_blank" *ngFor="let academicRecord of applicationInfo.academic_records" [href]="academicRecord.url">{{ academicRecord.url | fileNameFromUrl }}</a>
            </div>
          </div>

        </div>        
      </div>
      <div class="essayQuestions">
        <p>Briefly describe your educational goals and why you believe you should receive this scholarship.</p>
        <div class="essayWrapper mg23">
          <p> {{ applicationInfo.education_goals }} </p>
        </div>
        <p>What are your plans for the future and how will this scholarship help you achieve them?</p>
        <div class="essayWrapper">
          <p> {{ applicationInfo.future_plans }} </p>
        </div>
      </div>


      <div class="submitButtonOuter">
        <button type="button" class="formSubmitBtn editBtn" (click)="editApplication()" >Edit</button>
        <button type="button" class="formSubmitBtn" (click)="submitApplication()" >Submit</button>
      </div>
    </div>
  </div>
</section>
  
  <div id="pgLoader" class="pgLoader" *ngIf="loading">
    <img id="loaderStatus" class="loaderImage" alt="logo" src="assets/new-images/blue_psh_logo.png">
  </div>
  
  <section class="thanksPopup" *ngIf="thanksPopupShow">
    <div class="overlay"></div>
    <div class="thanksModal">        
        <img class="tickGif" src="/assets/new-images/tickAnim.webp" alt="tick">
        <p class="thanksHead">Thank you for applying!</p>
        <p class="thanksDesc">
            Our Admissions Committee is currently in the process of taking applications
            and will begin reviewing them.
        </p>
        <p class="botText thanksDesc">
            If you are among the shortlisted candidates, we will contact you <br>
            via email or phone.
        </p>
        <div class="thanksBtnWrapper">
            <a class="thanksOKBtn" href="javascript:;" (click)="rerouteToPortal('/admission/student-application')">Okay</a>
            <a class="downloadOKBtn" *ngIf="admissionInfo.class_applying == 'preCadet' " href="/assets/sample-tests/Model-Paper.pdf" download>Download Past Exam Papers</a>
        </div>
    </div>
  </section>