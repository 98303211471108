export class COUNTRYLIST {
    public static COUNTRY_LIST    =  {
                      'Afghanistan' : {           
                        'countryCodes': ['93'],
                        isoCode2: 'AF',
                        isoCode3: 'AFG',
                      },
                      'Albania' : {           
                        'countryCodes': ['355'],
                        isoCode2: 'AL',
                        isoCode3: 'ALB',
                      },
                      'Algeria' : {           
                        'countryCodes': ['213'],
                        isoCode2: 'DZ',
                        isoCode3: 'DZA',
                      },
                      'American Samoa' : {           
                        'countryCodes': ['1-684'],
                        isoCode2: 'AS',
                        isoCode3: 'ASM',
                      },
                      'Andorra' : {           
                        'countryCodes': ['376'],
                        isoCode2: 'AD',
                        isoCode3: 'AND',
                      },
                      'Angola' : {           
                        'countryCodes': ['244'],
                        isoCode2: 'AO',
                        isoCode3: 'AGO',
                      },
                      'Anguilla' : {           
                        'countryCodes': ['1-264'],
                        isoCode2: 'AI',
                        isoCode3: 'AIA',
                      },
                      'Antarctica' : {           
                        'countryCodes': ['672'],
                        isoCode2: 'AQ',
                        isoCode3: 'ATA',
                      },
                      'Antigua and Barbuda' : {           
                        'countryCodes': ['1-268'],
                        isoCode2: 'AG',
                        isoCode3: 'ATG',
                      },
                      'Argentina' : {           
                        'countryCodes': ['54'],
                        isoCode2: 'AR',
                        isoCode3: 'ARG',
                      },
                      'Armenia' : {           
                        'countryCodes': ['374'],
                        isoCode2: 'AM',
                        isoCode3: 'ARM',
                      },
                      'Aruba' : {           
                        'countryCodes': ['297'],
                        isoCode2: 'AW',
                        isoCode3: 'ABW',
                      },
                      'Australia' : {           
                        'countryCodes': ['61'],
                        isoCode2: 'AU',
                        isoCode3: 'AUS',
                      },
                      'Austria' : {           
                        'countryCodes': ['43'],
                        isoCode2: 'AT',
                        isoCode3: 'AUT',
                      },
                      'Azerbaijan' : {           
                        'countryCodes': ['994'],
                        isoCode2: 'AZ',
                        isoCode3: 'AZE',
                      },
                      'Bahamas' : {           
                        'countryCodes': ['1-242'],
                        isoCode2: 'BS',
                        isoCode3: 'BHS',
                      },
                      'Bahrain' : {           
                        'countryCodes': ['973'],
                        isoCode2: 'BH',
                        isoCode3: 'BHR',
                      },
                      'Bangladesh' : {           
                        'countryCodes': ['880'],
                        isoCode2: 'BD',
                        isoCode3: 'BGD',
                      },
                      'Barbados' : {           
                        'countryCodes': ['1-246'],
                        isoCode2: 'BB',
                        isoCode3: 'BRB',
                      },
                      'Belarus' : {           
                        'countryCodes': ['375'],
                        isoCode2: 'BY',
                        isoCode3: 'BLR',
                      },
                      'Belgium' : {           
                        'countryCodes': ['32'],
                        isoCode2: 'BE',
                        isoCode3: 'BEL',
                      },
                      'Belize' : {           
                        'countryCodes': ['501'],
                        isoCode2: 'BZ',
                        isoCode3: 'BLZ',
                      },
                      'Benin' : {           
                        'countryCodes': ['229'],
                        isoCode2: 'BJ',
                        isoCode3: 'BEN',
                      },
                      'Bermuda' : {           
                        'countryCodes': ['1-441'],
                        isoCode2: 'BM',
                        isoCode3: 'BMU',
                      },
                      'Bhutan' : {           
                        'countryCodes': ['975'],
                        isoCode2: 'BT',
                        isoCode3: 'BTN',
                      },
                      'Bolivia' : {           
                        'countryCodes': ['591'],
                        isoCode2: 'BO',
                        isoCode3: 'BOL',
                      },
                      'Bosnia and Herzegovina' : {           
                        'countryCodes': ['387'],
                        isoCode2: 'BA',
                        isoCode3: 'BIH',
                      },
                      'Botswana' : {           
                        'countryCodes': ['267'],
                        isoCode2: 'BW',
                        isoCode3: 'BWA',
                      },
                      'Brazil' : {           
                        'countryCodes': ['55'],
                        isoCode2: 'BR',
                        isoCode3: 'BRA',
                      },
                      'British Indian Ocean Territory' : {           
                        'countryCodes': ['246'],
                        isoCode2: 'IO',
                        isoCode3: 'IOT',
                      },
                      'British Virgin Islands' : {           
                        'countryCodes': ['1-284'],
                        isoCode2: 'VG',
                        isoCode3: 'VGB',
                      },
                      'Brunei' : {           
                        'countryCodes': ['673'],
                        isoCode2: 'BN',
                        isoCode3: 'BRN',
                      },
                      'Bulgaria' : {           
                        'countryCodes': ['359'],
                        isoCode2: 'BG',
                        isoCode3: 'BGR',
                      },
                      'Burkina Faso' : {           
                        'countryCodes': ['226'],
                        isoCode2: 'BF',
                        isoCode3: 'BFA',
                      },
                      'Burundi' : {           
                        'countryCodes': ['257'],
                        isoCode2: 'BI',
                        isoCode3: 'BDI',
                      },
                      'Cambodia' : {           
                        'countryCodes': ['855'],
                        isoCode2: 'KH',
                        isoCode3: 'KHM',
                      },
                      'Cameroon' : {           
                        'countryCodes': ['237'],
                        isoCode2: 'CM',
                        isoCode3: 'CMR',
                      },
                      'Canada' : {           
                        'countryCodes': ['1'],
                        isoCode2: 'CA',
                        isoCode3: 'CAN',
                      },
                      'Cape Verde' : {           
                        'countryCodes': ['238'],
                        isoCode2: 'CV',
                        isoCode3: 'CPV',
                      },
                      'Cayman Islands' : {           
                        'countryCodes': ['1-345'],
                        isoCode2: 'KY',
                        isoCode3: 'CYM',
                      },
                      'Central African Republic' : {           
                        'countryCodes': ['236'],
                        isoCode2: 'CF',
                        isoCode3: 'CAF',
                      },
                      'Chad' : {           
                        'countryCodes': ['235'],
                        isoCode2: 'TD',
                        isoCode3: 'TCD',
                      },
                      'Chile' : {           
                        'countryCodes': ['56'],
                        isoCode2: 'CL',
                        isoCode3: 'CHL',
                      },
                      'China' : {           
                        'countryCodes': ['86'],
                        isoCode2: 'CN',
                        isoCode3: 'CHN',
                      },
                      'Christmas Island' : {           
                        'countryCodes': ['61'],
                        isoCode2: 'CX',
                        isoCode3: 'CXR',
                      },
                      'Cocos Islands' : {           
                        'countryCodes': ['61'],
                        isoCode2: 'CC',
                        isoCode3: 'CCK',
                      },
                      'Colombia' : {           
                        'countryCodes': ['57'],
                        isoCode2: 'CO',
                        isoCode3: 'COL',
                      },
                      'Comoros' : {           
                        'countryCodes': ['269'],
                        isoCode2: 'KM',
                        isoCode3: 'COM',
                      },
                      'Cook Islands' : {           
                        'countryCodes': ['682'],
                        isoCode2: 'CK',
                        isoCode3: 'COK',
                      },
                      'Costa Rica' : {           
                        'countryCodes': ['506'],
                        isoCode2: 'CR',
                        isoCode3: 'CRI',
                      },
                      'Croatia' : {           
                        'countryCodes': ['385'],
                        isoCode2: 'HR',
                        isoCode3: 'HRV',
                      },
                      'Cuba' : {           
                        'countryCodes': ['53'],
                        isoCode2: 'CU',
                        isoCode3: 'CUB',
                      },
                      'Curacao' : {           
                        'countryCodes': ['599'],
                        isoCode2: 'CW',
                        isoCode3: 'CUW',
                      },
                      'Cyprus' : {           
                        'countryCodes': ['357'],
                        isoCode2: 'CY',
                        isoCode3: 'CYP',
                      },
                      'Czech Republic' : {           
                        'countryCodes': ['420'],
                        isoCode2: 'CZ',
                        isoCode3: 'CZE',
                      },
                      'Democratic Republic of the Congo' : {           
                        'countryCodes': ['243'],
                        isoCode2: 'CD',
                        isoCode3: 'COD',
                      },
                      'Denmark' : {           
                        'countryCodes': ['45'],
                        isoCode2: 'DK',
                        isoCode3: 'DNK',
                      },
                      'Djibouti' : {           
                        'countryCodes': ['253'],
                        isoCode2: 'DJ',
                        isoCode3: 'DJI',
                      },
                      'Dominica' : {           
                        'countryCodes': ['1-767'],
                        isoCode2: 'DM',
                        isoCode3: 'DMA',
                      },
                      'Dominican Republic' : {           
                        'countryCodes': ['1-809', '1-829', '1-849'],
                        isoCode2: 'DO',
                        isoCode3: 'DOM',
                      },
                      'East Timor' : {           
                        'countryCodes': ['670'],
                        isoCode2: 'TL',
                        isoCode3: 'TLS',
                      },
                      'Ecuador' : {           
                        'countryCodes': ['593'],
                        isoCode2: 'EC',
                        isoCode3: 'ECU',
                      },
                      'Egypt' : {           
                        'countryCodes': ['20'],
                        isoCode2: 'EG',
                        isoCode3: 'EGY',
                      },
                      'El Salvador' : {           
                        'countryCodes': ['503'],
                        isoCode2: 'SV',
                        isoCode3: 'SLV',
                      },
                      'Equatorial Guinea' : {           
                        'countryCodes': ['240'],
                        isoCode2: 'GQ',
                        isoCode3: 'GNQ',
                      },
                      'Eritrea' : {           
                        'countryCodes': ['291'],
                        isoCode2: 'ER',
                        isoCode3: 'ERI',
                      },
                      'Estonia' : {           
                        'countryCodes': ['372'],
                        isoCode2: 'EE',
                        isoCode3: 'EST',
                      },
                      'Ethiopia' : {           
                        'countryCodes': ['251'],
                        isoCode2: 'ET',
                        isoCode3: 'ETH',
                      },
                      'Falkland Islands' : {           
                        'countryCodes': ['500'],
                        isoCode2: 'FK',
                        isoCode3: 'FLK',
                      },
                      'Faroe Islands' : {           
                        'countryCodes': ['298'],
                        isoCode2: 'FO',
                        isoCode3: 'FRO',
                      },
                      'Fiji' : {           
                        'countryCodes': ['679'],
                        isoCode2: 'FJ',
                        isoCode3: 'FJI',
                      },
                      'Finland' : {           
                        'countryCodes': ['358'],
                        isoCode2: 'FI',
                        isoCode3: 'FIN',
                      },
                      'France' : {           
                        'countryCodes': ['33'],
                        isoCode2: 'FR',
                        isoCode3: 'FRA',
                      },
                      'French Polynesia' : {           
                        'countryCodes': ['689'],
                        isoCode2: 'PF',
                        isoCode3: 'PYF',
                      },
                      'Gabon' : {           
                        'countryCodes': ['241'],
                        isoCode2: 'GA',
                        isoCode3: 'GAB',
                      },
                      'Gambia' : {           
                        'countryCodes': ['220'],
                        isoCode2: 'GM',
                        isoCode3: 'GMB',
                      },
                      'Georgia' : {           
                        'countryCodes': ['995'],
                        isoCode2: 'GE',
                        isoCode3: 'GEO',
                      },
                      'Germany' : {           
                        'countryCodes': ['49'],
                        isoCode2: 'DE',
                        isoCode3: 'DEU',
                      },
                      'Ghana' : {           
                        'countryCodes': ['233'],
                        isoCode2: 'GH',
                        isoCode3: 'GHA',
                      },
                      'Gibraltar' : {           
                        'countryCodes': ['350'],
                        isoCode2: 'GI',
                        isoCode3: 'GIB',
                      },
                      'Greece' : {           
                        'countryCodes': ['30'],
                        isoCode2: 'GR',
                        isoCode3: 'GRC',
                      },
                      'Greenland' : {           
                        'countryCodes': ['299'],
                        isoCode2: 'GL',
                        isoCode3: 'GRL',
                      },
                      'Grenada' : {           
                        'countryCodes': ['1-473'],
                        isoCode2: 'GD',
                        isoCode3: 'GRD',
                      },
                      'Guam' : {           
                        'countryCodes': ['1-671'],
                        isoCode2: 'GU',
                        isoCode3: 'GUM',
                      },
                      'Guatemala' : {           
                        'countryCodes': ['502'],
                        isoCode2: 'GT',
                        isoCode3: 'GTM',
                      },
                      'Guernsey' : {           
                        'countryCodes': ['44-1481'],
                        isoCode2: 'GG',
                        isoCode3: 'GGY',
                      },
                      'Guinea' : {           
                        'countryCodes': ['224'],
                        isoCode2: 'GN',
                        isoCode3: 'GIN',
                      },
                      'Guinea-Bissau' : {           
                        'countryCodes': ['245'],
                        isoCode2: 'GW',
                        isoCode3: 'GNB',
                      },
                      'Guyana' : {           
                        'countryCodes': ['592'],
                        isoCode2: 'GY',
                        isoCode3: 'GUY',
                      },
                      'Haiti' : {           
                        'countryCodes': ['509'],
                        isoCode2: 'HT',
                        isoCode3: 'HTI',
                      },
                      'Honduras' : {           
                        'countryCodes': ['504'],
                        isoCode2: 'HN',
                        isoCode3: 'HND',
                      },
                      'Hong Kong' : {           
                        'countryCodes': ['852'],
                        isoCode2: 'HK',
                        isoCode3: 'HKG',
                      },
                      'Hungary' : {           
                        'countryCodes': ['36'],
                        isoCode2: 'HU',
                        isoCode3: 'HUN',
                      },
                      'Iceland' : {           
                        'countryCodes': ['354'],
                        isoCode2: 'IS',
                        isoCode3: 'ISL',
                      },
                      'India' : {           
                        'countryCodes': ['91'],
                        isoCode2: 'IN',
                        isoCode3: 'IND',
                      },
                      'Indonesia' : {           
                        'countryCodes': ['62'],
                        isoCode2: 'ID',
                        isoCode3: 'IDN',
                      },
                      'Iran' : {           
                        'countryCodes': ['98'],
                        isoCode2: 'IR',
                        isoCode3: 'IRN',
                      },
                      'Iraq' : {           
                        'countryCodes': ['964'],
                        isoCode2: 'IQ',
                        isoCode3: 'IRQ',
                      },
                      'Ireland' : {           
                        'countryCodes': ['353'],
                        isoCode2: 'IE',
                        isoCode3: 'IRL',
                      },
                      'Isle of Man' : {           
                        'countryCodes': ['44-1624'],
                        isoCode2: 'IM',
                        isoCode3: 'IMN',
                      },
                      'Israel' : {           
                        'countryCodes': ['972'],
                        isoCode2: 'IL',
                        isoCode3: 'ISR',
                      },
                      'Italy' : {           
                        'countryCodes': ['39'],
                        isoCode2: 'IT',
                        isoCode3: 'ITA',
                      },
                      'Ivory Coast' : {           
                        'countryCodes': ['225'],
                        isoCode2: 'CI',
                        isoCode3: 'CIV',
                      },
                      'Jamaica' : {           
                        'countryCodes': ['1-876'],
                        isoCode2: 'JM',
                        isoCode3: 'JAM',
                      },
                      'Japan' : {           
                        'countryCodes': ['81'],
                        isoCode2: 'JP',
                        isoCode3: 'JPN',
                      },
                      'Jersey' : {           
                        'countryCodes': ['44-1534'],
                        isoCode2: 'JE',
                        isoCode3: 'JEY',
                      },
                      'Jordan' : {           
                        'countryCodes': ['962'],
                        isoCode2: 'JO',
                        isoCode3: 'JOR',
                      },
                      'Kazakhstan' : {           
                        'countryCodes': ['7'],
                        isoCode2: 'KZ',
                        isoCode3: 'KAZ',
                      },
                      'Kenya' : {           
                        'countryCodes': ['254'],
                        isoCode2: 'KE',
                        isoCode3: 'KEN',
                      },
                      'Kiribati' : {           
                        'countryCodes': ['686'],
                        isoCode2: 'KI',
                        isoCode3: 'KIR',
                      },
                      'Kosovo' : {           
                        'countryCodes': ['383'],
                        isoCode2: 'XK',
                        isoCode3: 'XKX',
                      },
                      'Kuwait' : {           
                        'countryCodes': ['965'],
                        isoCode2: 'KW',
                        isoCode3: 'KWT',
                      },
                      'Kyrgyzstan' : {           
                        'countryCodes': ['996'],
                        isoCode2: 'KG',
                        isoCode3: 'KGZ',
                      },
                      'Laos' : {           
                        'countryCodes': ['856'],
                        isoCode2: 'LA',
                        isoCode3: 'LAO',
                      },
                      'Latvia' : {           
                        'countryCodes': ['371'],
                        isoCode2: 'LV',
                        isoCode3: 'LVA',
                      },
                      'Lebanon' : {           
                        'countryCodes': ['961'],
                        isoCode2: 'LB',
                        isoCode3: 'LBN',
                      },
                      'Lesotho' : {           
                        'countryCodes': ['266'],
                        isoCode2: 'LS',
                        isoCode3: 'LSO',
                      },
                      'Liberia' : {           
                        'countryCodes': ['231'],
                        isoCode2: 'LR',
                        isoCode3: 'LBR',
                      },
                      'Libya' : {           
                        'countryCodes': ['218'],
                        isoCode2: 'LY',
                        isoCode3: 'LBY',
                      },
                      'Liechtenstein' : {           
                        'countryCodes': ['423'],
                        isoCode2: 'LI',
                        isoCode3: 'LIE',
                      },
                      'Lithuania' : {           
                        'countryCodes': ['370'],
                        isoCode2: 'LT',
                        isoCode3: 'LTU',
                      },
                      'Luxembourg' : {           
                        'countryCodes': ['352'],
                        isoCode2: 'LU',
                        isoCode3: 'LUX',
                      },
                      'Macau' : {           
                        'countryCodes': ['853'],
                        isoCode2: 'MO',
                        isoCode3: 'MAC',
                      },
                      'Macedonia' : {           
                        'countryCodes': ['389'],
                        isoCode2: 'MK',
                        isoCode3: 'MKD',
                      },
                      'Madagascar' : {           
                        'countryCodes': ['261'],
                        isoCode2: 'MG',
                        isoCode3: 'MDG',
                      },
                      'Malawi' : {           
                        'countryCodes': ['265'],
                        isoCode2: 'MW',
                        isoCode3: 'MWI',
                      },
                      'Malaysia' : {           
                        'countryCodes': ['60'],
                        isoCode2: 'MY',
                        isoCode3: 'MYS',
                      },
                      'Maldives' : {           
                        'countryCodes': ['960'],
                        isoCode2: 'MV',
                        isoCode3: 'MDV',
                      },
                      'Mali' : {           
                        'countryCodes': ['223'],
                        isoCode2: 'ML',
                        isoCode3: 'MLI',
                      },
                      'Malta' : {           
                        'countryCodes': ['356'],
                        isoCode2: 'MT',
                        isoCode3: 'MLT',
                      },
                      'Marshall Islands' : {           
                        'countryCodes': ['692'],
                        isoCode2: 'MH',
                        isoCode3: 'MHL',
                      },
                      'Mauritania' : {           
                        'countryCodes': ['222'],
                        isoCode2: 'MR',
                        isoCode3: 'MRT',
                      },
                      'Mauritius' : {           
                        'countryCodes': ['230'],
                        isoCode2: 'MU',
                        isoCode3: 'MUS',
                      },
                      'Mayotte' : {           
                        'countryCodes': ['262'],
                        isoCode2: 'YT',
                        isoCode3: 'MYT',
                      },
                      'Mexico' : {           
                        'countryCodes': ['52'],
                        isoCode2: 'MX',
                        isoCode3: 'MEX',
                      },
                      'Micronesia' : {           
                        'countryCodes': ['691'],
                        isoCode2: 'FM',
                        isoCode3: 'FSM',
                      },
                      'Moldova' : {           
                        'countryCodes': ['373'],
                        isoCode2: 'MD',
                        isoCode3: 'MDA',
                      },
                      'Monaco' : {           
                        'countryCodes': ['377'],
                        isoCode2: 'MC',
                        isoCode3: 'MCO',
                      },
                      'Mongolia' : {           
                        'countryCodes': ['976'],
                        isoCode2: 'MN',
                        isoCode3: 'MNG',
                      },
                      'Montenegro' : {           
                        'countryCodes': ['382'],
                        isoCode2: 'ME',
                        isoCode3: 'MNE',
                      },
                      'Montserrat' : {           
                        'countryCodes': ['1-664'],
                        isoCode2: 'MS',
                        isoCode3: 'MSR',
                      },
                      'Morocco' : {           
                        'countryCodes': ['212'],
                        isoCode2: 'MA',
                        isoCode3: 'MAR',
                      },
                      'Mozambique' : {           
                        'countryCodes': ['258'],
                        isoCode2: 'MZ',
                        isoCode3: 'MOZ',
                      },
                      'Myanmar' : {           
                        'countryCodes': ['95'],
                        isoCode2: 'MM',
                        isoCode3: 'MMR',
                      },
                      'Namibia' : {           
                        'countryCodes': ['264'],
                        isoCode2: 'NA',
                        isoCode3: 'NAM',
                      },
                      'Nauru' : {           
                        'countryCodes': ['674'],
                        isoCode2: 'NR',
                        isoCode3: 'NRU',
                      },
                      'Nepal' : {           
                        'countryCodes': ['977'],
                        isoCode2: 'NP',
                        isoCode3: 'NPL',
                      },
                      'Netherlands' : {           
                        'countryCodes': ['31'],
                        isoCode2: 'NL',
                        isoCode3: 'NLD',
                      },
                      'Netherlands Antilles' : {           
                        'countryCodes': ['599'],
                        isoCode2: 'AN',
                        isoCode3: 'ANT',
                      },
                      'New Caledonia' : {           
                        'countryCodes': ['687'],
                        isoCode2: 'NC',
                        isoCode3: 'NCL',
                      },
                      'New Zealand' : {           
                        'countryCodes': ['64'],
                        isoCode2: 'NZ',
                        isoCode3: 'NZL',
                      },
                      'Nicaragua' : {           
                        'countryCodes': ['505'],
                        isoCode2: 'NI',
                        isoCode3: 'NIC',
                      },
                      'Niger' : {           
                        'countryCodes': ['227'],
                        isoCode2: 'NE',
                        isoCode3: 'NER',
                      },
                      'Nigeria' : {           
                        'countryCodes': ['234'],
                        isoCode2: 'NG',
                        isoCode3: 'NGA',
                      },
                      'Niue' : {           
                        'countryCodes': ['683'],
                        isoCode2: 'NU',
                        isoCode3: 'NIU',
                      },
                      'North Korea' : {           
                        'countryCodes': ['850'],
                        isoCode2: 'KP',
                        isoCode3: 'PRK',
                      },
                      'Northern Mariana Islands' : {           
                        'countryCodes': ['1-670'],
                        isoCode2: 'MP',
                        isoCode3: 'MNP',
                      },
                      'Norway' : {           
                        'countryCodes': ['47'],
                        isoCode2: 'NO',
                        isoCode3: 'NOR',
                      },
                      'Oman' : {           
                        'countryCodes': ['968'],
                        isoCode2: 'OM',
                        isoCode3: 'OMN',
                      },
                      'Pakistan' : {           
                        'countryCodes': ['92'],
                        isoCode2: 'PK',
                        isoCode3: 'PAK',
                      },
                      'Palau' : {           
                        'countryCodes': ['680'],
                        isoCode2: 'PW',
                        isoCode3: 'PLW',
                      },
                      'Palestine' : {           
                        'countryCodes': ['970'],
                        isoCode2: 'PS',
                        isoCode3: 'PSE',
                      },
                      'Panama' : {           
                        'countryCodes': ['507'],
                        isoCode2: 'PA',
                        isoCode3: 'PAN',
                      },
                      'Papua New Guinea' : {           
                        'countryCodes': ['675'],
                        isoCode2: 'PG',
                        isoCode3: 'PNG',
                      },
                      'Paraguay' : {           
                        'countryCodes': ['595'],
                        isoCode2: 'PY',
                        isoCode3: 'PRY',
                      },
                      'Peru' : {           
                        'countryCodes': ['51'],
                        isoCode2: 'PE',
                        isoCode3: 'PER',
                      },
                      'Philippines' : {           
                        'countryCodes': ['63'],
                        isoCode2: 'PH',
                        isoCode3: 'PHL',
                      },
                      'Pitcairn' : {           
                        'countryCodes': ['64'],
                        isoCode2: 'PN',
                        isoCode3: 'PCN',
                      },
                      'Poland' : {           
                        'countryCodes': ['48'],
                        isoCode2: 'PL',
                        isoCode3: 'POL',
                      },
                      'Portugal' : {           
                        'countryCodes': ['351'],
                        isoCode2: 'PT',
                        isoCode3: 'PRT',
                      },
                      'Puerto Rico' : {           
                        'countryCodes': ['1-787', '1-939'],
                        isoCode2: 'PR',
                        isoCode3: 'PRI',
                      },
                      'Qatar' : {           
                        'countryCodes': ['974'],
                        isoCode2: 'QA',
                        isoCode3: 'QAT',
                      },
                      'Republic of the Congo' : {           
                        'countryCodes': ['242'],
                        isoCode2: 'CG',
                        isoCode3: 'COG',
                      },
                      'Reunion' : {           
                        'countryCodes': ['262'],
                        isoCode2: 'RE',
                        isoCode3: 'REU',
                      },
                      'Romania' : {           
                        'countryCodes': ['40'],
                        isoCode2: 'RO',
                        isoCode3: 'ROU',
                      },
                      'Russia' : {           
                        'countryCodes': ['7'],
                        isoCode2: 'RU',
                        isoCode3: 'RUS',
                      },
                      'Rwanda' : {           
                        'countryCodes': ['250'],
                        isoCode2: 'RW',
                        isoCode3: 'RWA',
                      },
                      'Saint Barthelemy' : {           
                        'countryCodes': ['590'],
                        isoCode2: 'BL',
                        isoCode3: 'BLM',
                      },
                      'Saint Helena' : {           
                        'countryCodes': ['290'],
                        isoCode2: 'SH',
                        isoCode3: 'SHN',
                      },
                      'Saint Kitts and Nevis' : {           
                        'countryCodes': ['1-869'],
                        isoCode2: 'KN',
                        isoCode3: 'KNA',
                      },
                      'Saint Lucia' : {           
                        'countryCodes': ['1-758'],
                        isoCode2: 'LC',
                        isoCode3: 'LCA',
                      },
                      'Saint Martin' : {           
                        'countryCodes': ['590'],
                        isoCode2: 'MF',
                        isoCode3: 'MAF',
                      },
                      'Saint Pierre and Miquelon' : {           
                        'countryCodes': ['508'],
                        isoCode2: 'PM',
                        isoCode3: 'SPM',
                      },
                      'Saint Vincent and the Grenadines' : {           
                        'countryCodes': ['1-784'],
                        isoCode2: 'VC',
                        isoCode3: 'VCT',
                      },
                      'Samoa' : {           
                        'countryCodes': ['685'],
                        isoCode2: 'WS',
                        isoCode3: 'WSM',
                      },
                      'San Marino' : {           
                        'countryCodes': ['378'],
                        isoCode2: 'SM',
                        isoCode3: 'SMR',
                      },
                      'Sao Tome and Principe' : {           
                        'countryCodes': ['239'],
                        isoCode2: 'ST',
                        isoCode3: 'STP',
                      },
                      'Saudi Arabia' : {           
                        'countryCodes': ['966'],
                        isoCode2: 'SA',
                        isoCode3: 'SAU',
                      },
                      'Senegal' : {           
                        'countryCodes': ['221'],
                        isoCode2: 'SN',
                        isoCode3: 'SEN',
                      },
                      'Serbia' : {           
                        'countryCodes': ['381'],
                        isoCode2: 'RS',
                        isoCode3: 'SRB',
                      },
                      'Seychelles' : {           
                        'countryCodes': ['248'],
                        isoCode2: 'SC',
                        isoCode3: 'SYC',
                      },
                      'Sierra Leone' : {           
                        'countryCodes': ['232'],
                        isoCode2: 'SL',
                        isoCode3: 'SLE',
                      },
                      'Singapore' : {           
                        'countryCodes': ['65'],
                        isoCode2: 'SG',
                        isoCode3: 'SGP',
                      },
                      'Sint Maarten' : {           
                        'countryCodes': ['1-721'],
                        isoCode2: 'SX',
                        isoCode3: 'SXM',
                      },
                      'Slovakia' : {           
                        'countryCodes': ['421'],
                        isoCode2: 'SK',
                        isoCode3: 'SVK',
                      },
                      'Slovenia' : {           
                        'countryCodes': ['386'],
                        isoCode2: 'SI',
                        isoCode3: 'SVN',
                      },
                      'Solomon Islands' : {           
                        'countryCodes': ['677'],
                        isoCode2: 'SB',
                        isoCode3: 'SLB',
                      },
                      'Somalia' : {           
                        'countryCodes': ['252'],
                        isoCode2: 'SO',
                        isoCode3: 'SOM',
                      },
                      'South Africa' : {           
                        'countryCodes': ['27'],
                        isoCode2: 'ZA',
                        isoCode3: 'ZAF',
                      },
                      'South Korea' : {           
                        'countryCodes': ['82'],
                        isoCode2: 'KR',
                        isoCode3: 'KOR',
                      },
                      'South Sudan' : {           
                        'countryCodes': ['211'],
                        isoCode2: 'SS',
                        isoCode3: 'SSD',
                      },
                      'Spain' : {           
                        'countryCodes': ['34'],
                        isoCode2: 'ES',
                        isoCode3: 'ESP',
                      },
                      'Sri Lanka' : {           
                        'countryCodes': ['94'],
                        isoCode2: 'LK',
                        isoCode3: 'LKA',
                      },
                      'Sudan' : {           
                        'countryCodes': ['249'],
                        isoCode2: 'SD',
                        isoCode3: 'SDN',
                      },
                      'Suriname' : {           
                        'countryCodes': ['597'],
                        isoCode2: 'SR',
                        isoCode3: 'SUR',
                      },
                      'Svalbard and Jan Mayen' : {           
                        'countryCodes': ['47'],
                        isoCode2: 'SJ',
                        isoCode3: 'SJM',
                      },
                      'Swaziland' : {           
                        'countryCodes': ['268'],
                        isoCode2: 'SZ',
                        isoCode3: 'SWZ',
                      },
                      'Sweden' : {           
                        'countryCodes': ['46'],
                        isoCode2: 'SE',
                        isoCode3: 'SWE',
                      },
                      'Switzerland' : {           
                        'countryCodes': ['41'],
                        isoCode2: 'CH',
                        isoCode3: 'CHE',
                      },
                      'Syria' : {           
                        'countryCodes': ['963'],
                        isoCode2: 'SY',
                        isoCode3: 'SYR',
                      },
                      'Taiwan' : {           
                        'countryCodes': ['886'],
                        isoCode2: 'TW',
                        isoCode3: 'TWN',
                      },
                      'Tajikistan' : {           
                        'countryCodes': ['992'],
                        isoCode2: 'TJ',
                        isoCode3: 'TJK',
                      },
                      'Tanzania' : {           
                        'countryCodes': ['255'],
                        isoCode2: 'TZ',
                        isoCode3: 'TZA',
                      },
                      'Thailand' : {           
                        'countryCodes': ['66'],
                        isoCode2: 'TH',
                        isoCode3: 'THA',
                      },
                      'Togo' : {           
                        'countryCodes': ['228'],
                        isoCode2: 'TG',
                        isoCode3: 'TGO',
                      },
                      'Tokelau' : {           
                        'countryCodes': ['690'],
                        isoCode2: 'TK',
                        isoCode3: 'TKL',
                      },
                      'Tonga' : {           
                        'countryCodes': ['676'],
                        isoCode2: 'TO',
                        isoCode3: 'TON',
                      },
                      'Trinidad and Tobago' : {           
                        'countryCodes': ['1-868'],
                        isoCode2: 'TT',
                        isoCode3: 'TTO',
                      },
                      'Tunisia' : {           
                        'countryCodes': ['216'],
                        isoCode2: 'TN',
                        isoCode3: 'TUN',
                      },
                      'Turkey' : {           
                        'countryCodes': ['90'],
                        isoCode2: 'TR',
                        isoCode3: 'TUR',
                      },
                      'Turkmenistan' : {           
                        'countryCodes': ['993'],
                        isoCode2: 'TM',
                        isoCode3: 'TKM',
                      },
                      'Turks and Caicos Islands' : {           
                        'countryCodes': ['1-649'],
                        isoCode2: 'TC',
                        isoCode3: 'TCA',
                      },
                      'Tuvalu' : {           
                        'countryCodes': ['688'],
                        isoCode2: 'TV',
                        isoCode3: 'TUV',
                      },
                      'U.S. Virgin Islands' : {           
                        'countryCodes': ['1-340'],
                        isoCode2: 'VI',
                        isoCode3: 'VIR',
                      },
                      'Uganda' : {           
                        'countryCodes': ['256'],
                        isoCode2: 'UG',
                        isoCode3: 'UGA',
                      },
                      'Ukraine' : {           
                        'countryCodes': ['380'],
                        isoCode2: 'UA',
                        isoCode3: 'UKR',
                      },
                      'United Arab Emirates' : {           
                        'countryCodes': ['971'],
                        isoCode2: 'AE',
                        isoCode3: 'ARE',
                      },
                      'United Kingdom' : {           
                        'countryCodes': ['44'],
                        isoCode2: 'GB',
                        isoCode3: 'GBR',
                      },
                      'United States' : {           
                        'countryCodes': ['1'],
                        isoCode2: 'US',
                        isoCode3: 'USA',
                      },
                      'Uruguay' : {           
                        'countryCodes': ['598'],
                        isoCode2: 'UY',
                        isoCode3: 'URY',
                      },
                      'Uzbekistan' : {           
                        'countryCodes': ['998'],
                        isoCode2: 'UZ',
                        isoCode3: 'UZB',
                      },
                      'Vanuatu' : {           
                        'countryCodes': ['678'],
                        isoCode2: 'VU',
                        isoCode3: 'VUT',
                      },
                      'Vatican' : {           
                        'countryCodes': ['379'],
                        isoCode2: 'VA',
                        isoCode3: 'VAT',
                      },
                      'Venezuela' : {           
                        'countryCodes': ['58'],
                        isoCode2: 'VE',
                        isoCode3: 'VEN',
                      },
                      'Vietnam' : {           
                        'countryCodes': ['84'],
                        isoCode2: 'VN',
                        isoCode3: 'VNM',
                      },
                      'Wallis and Futuna' : {           
                        'countryCodes': ['681'],
                        isoCode2: 'WF',
                        isoCode3: 'WLF',
                      },
                      'Western Sahara' : {           
                        'countryCodes': ['212'],
                        isoCode2: 'EH',
                        isoCode3: 'ESH',
                      },
                      'Yemen' : {           
                        'countryCodes': ['967'],
                        isoCode2: 'YE',
                        isoCode3: 'YEM',
                      },
                      'Zambia' : {           
                        'countryCodes': ['260'],
                        isoCode2: 'ZM',
                        isoCode3: 'ZMB',
                      },
                      'Zimbabwe' : {           
                        'countryCodes': ['263'],
                        isoCode2: 'ZW',
                        isoCode3: 'ZWE',
                      }
                    }
                    public static COUNTRY_AGAINST_CODE    =  {
                      '93' : {                 
                       isoCode2: 'AF',
                     },
                      '355' : {                 
                       isoCode2: 'AL',
                     },
                      '213' : {                 
                       isoCode2: 'DZ',
                     },
                      '1-684' : {                 
                       isoCode2: 'AS',
                     },
                      '376' : {                 
                       isoCode2: 'AD',
                     },
                      '244' : {                 
                       isoCode2: 'AO',
                     },
                      '1-264' : {                 
                       isoCode2: 'AI',
                     },
                      '672' : {                 
                       isoCode2: 'AQ',
                     },
                      '1-268' : {                 
                       isoCode2: 'AG',
                     },
                      '54' : {                 
                       isoCode2: 'AR',
                     },
                      '374' : {                 
                       isoCode2: 'AM',
                     },
                      '297' : {                 
                       isoCode2: 'AW',
                     },
                      '61' : {                 
                       isoCode2: 'AU',
                     },
                      '43' : {                 
                       isoCode2: 'AT',
                     },
                      '994' : {                 
                       isoCode2: 'AZ',
                     },
                      '1-242' : {                 
                       isoCode2: 'BS',
                     },
                      '973' : {                 
                       isoCode2: 'BH',
                     },
                      '880' : {                 
                       isoCode2: 'BD',
                     },
                      '1-246' : {                 
                       isoCode2: 'BB',
                     },
                      '375' : {                 
                       isoCode2: 'BY',
                     },
                      '32' : {                 
                       isoCode2: 'BE',
                     },
                      '501' : {                 
                       isoCode2: 'BZ',
                     },
                      '229' : {                 
                       isoCode2: 'BJ',
                     },
                      '1-441' : {                 
                       isoCode2: 'BM',
                     },
                      '975' : {                 
                       isoCode2: 'BT',
                     },
                      '591' : {                 
                       isoCode2: 'BO',
                     },
                      '387' : {                 
                       isoCode2: 'BA',
                     },
                      '267' : {                 
                       isoCode2: 'BW',
                     },
                      '55' : {                 
                       isoCode2: 'BR',
                     },
                      '246' : {                 
                       isoCode2: 'IO',
                     },
                      '1-284' : {                 
                       isoCode2: 'VG',
                     },
                      '673' : {                 
                       isoCode2: 'BN',
                     },
                      '359' : {                 
                       isoCode2: 'BG',
                     },
                      '226' : {                 
                       isoCode2: 'BF',
                     },
                      '257' : {                 
                       isoCode2: 'BI',
                     },
                      '855' : {                 
                       isoCode2: 'KH',
                     },
                      '237' : {                 
                       isoCode2: 'CM',
                     },
                      '1' : {                 
                       isoCode2: 'CA',
                     },
                      '238' : {                 
                       isoCode2: 'CV',
                     },
                      '1-345' : {                 
                       isoCode2: 'KY',
                     },
                      '236' : {                 
                       isoCode2: 'CF',
                     },
                      '235' : {                 
                       isoCode2: 'TD',
                     },
                      '56' : {                 
                       isoCode2: 'CL',
                     },
                      '86' : {                 
                       isoCode2: 'CN',
                     },
                      '57' : {                 
                       isoCode2: 'CO',
                     },
                      '269' : {                 
                       isoCode2: 'KM',
                     },
                      '682' : {                 
                       isoCode2: 'CK',
                     },
                      '506' : {                 
                       isoCode2: 'CR',
                     },
                      '385' : {                 
                       isoCode2: 'HR',
                     },
                      '53' : {                 
                       isoCode2: 'CU',
                     },
                      '599' : {                 
                       isoCode2: 'CW',
                     },
                      '357' : {                 
                       isoCode2: 'CY',
                     },
                      '420' : {                 
                       isoCode2: 'CZ',
                     },
                      '243' : {                 
                       isoCode2: 'CD',
                     },
                      '45' : {                 
                       isoCode2: 'DK',
                     },
                      '253' : {                 
                       isoCode2: 'DJ',
                     },
                      '1-767' : {                 
                       isoCode2: 'DM',
                     },
                      '670' : {                 
                       isoCode2: 'TL',
                     },
                      '593' : {                 
                       isoCode2: 'EC',
                     },
                      '20' : {                 
                       isoCode2: 'EG',
                     },
                      '503' : {                 
                       isoCode2: 'SV',
                     },
                      '240' : {                 
                       isoCode2: 'GQ',
                     },
                      '291' : {                 
                       isoCode2: 'ER',
                     },
                      '372' : {                 
                       isoCode2: 'EE',
                     },
                      '251' : {                 
                       isoCode2: 'ET',
                     },
                      '500' : {                 
                       isoCode2: 'FK',
                     },
                      '298' : {                 
                       isoCode2: 'FO',
                     },
                      '679' : {                 
                       isoCode2: 'FJ',
                     },
                      '358' : {                 
                       isoCode2: 'FI',
                     },
                      '33' : {                 
                       isoCode2: 'FR',
                     },
                      '689' : {                 
                       isoCode2: 'PF',
                     },
                      '241' : {                 
                       isoCode2: 'GA',
                     },
                      '220' : {                 
                       isoCode2: 'GM',
                     },
                      '995' : {                 
                       isoCode2: 'GE',
                     },
                      '49' : {                 
                       isoCode2: 'DE',
                     },
                      '233' : {                 
                       isoCode2: 'GH',
                     },
                      '350' : {                 
                       isoCode2: 'GI',
                     },
                      '30' : {                 
                       isoCode2: 'GR',
                     },
                      '299' : {                 
                       isoCode2: 'GL',
                     },
                      '1-473' : {                 
                       isoCode2: 'GD',
                     },
                      '1-671' : {                 
                       isoCode2: 'GU',
                     },
                      '502' : {                 
                       isoCode2: 'GT',
                     },
                      '44-1481' : {                 
                       isoCode2: 'GG',
                     },
                      '224' : {                 
                       isoCode2: 'GN',
                     },
                      '245' : {                 
                       isoCode2: 'GW',
                     },
                      '592' : {                 
                       isoCode2: 'GY',
                     },
                      '509' : {                 
                       isoCode2: 'HT',
                     },
                      '504' : {                 
                       isoCode2: 'HN',
                     },
                      '852' : {                 
                       isoCode2: 'HK',
                     },
                      '36' : {                 
                       isoCode2: 'HU',
                     },
                      '354' : {                 
                       isoCode2: 'IS',
                     },
                      '91' : {                 
                       isoCode2: 'IN',
                     },
                      '62' : {                 
                       isoCode2: 'ID',
                     },
                      '98' : {                 
                       isoCode2: 'IR',
                     },
                      '964' : {                 
                       isoCode2: 'IQ',
                     },
                      '353' : {                 
                       isoCode2: 'IE',
                     },
                      '44-1624' : {                 
                       isoCode2: 'IM',
                     },
                      '972' : {                 
                       isoCode2: 'IL',
                     },
                      '39' : {                 
                       isoCode2: 'IT',
                     },
                      '225' : {                 
                       isoCode2: 'CI',
                     },
                      '1-876' : {                 
                       isoCode2: 'JM',
                     },
                      '81' : {                 
                       isoCode2: 'JP',
                     },
                      '44-1534' : {                 
                       isoCode2: 'JE',
                     },
                      '962' : {                 
                       isoCode2: 'JO',
                     },
                      '7' : {                 
                       isoCode2: 'KZ',
                     },
                      '254' : {                 
                       isoCode2: 'KE',
                     },
                      '686' : {                 
                       isoCode2: 'KI',
                     },
                      '383' : {                 
                       isoCode2: 'XK',
                     },
                      '965' : {                 
                       isoCode2: 'KW',
                     },
                      '996' : {                 
                       isoCode2: 'KG',
                     },
                      '856' : {                 
                       isoCode2: 'LA',
                     },
                      '371' : {                 
                       isoCode2: 'LV',
                     },
                      '961' : {                 
                       isoCode2: 'LB',
                     },
                      '266' : {                 
                       isoCode2: 'LS',
                     },
                      '231' : {                 
                       isoCode2: 'LR',
                     },
                      '218' : {                 
                       isoCode2: 'LY',
                     },
                      '423' : {                 
                       isoCode2: 'LI',
                     },
                      '370' : {                 
                       isoCode2: 'LT',
                     },
                      '352' : {                 
                       isoCode2: 'LU',
                     },
                      '853' : {                 
                       isoCode2: 'MO',
                     },
                      '389' : {                 
                       isoCode2: 'MK',
                     },
                      '261' : {                 
                       isoCode2: 'MG',
                     },
                      '265' : {                 
                       isoCode2: 'MW',
                     },
                      '60' : {                 
                       isoCode2: 'MY',
                     },
                      '960' : {                 
                       isoCode2: 'MV',
                     },
                      '223' : {                 
                       isoCode2: 'ML',
                     },
                      '356' : {                 
                       isoCode2: 'MT',
                     },
                      '692' : {                 
                       isoCode2: 'MH',
                     },
                      '222' : {                 
                       isoCode2: 'MR',
                     },
                      '230' : {                 
                       isoCode2: 'MU',
                     },
                      '262' : {                 
                       isoCode2: 'YT',
                     },
                      '52' : {                 
                       isoCode2: 'MX',
                     },
                      '691' : {                 
                       isoCode2: 'FM',
                     },
                      '373' : {                 
                       isoCode2: 'MD',
                     },
                      '377' : {                 
                       isoCode2: 'MC',
                     },
                      '976' : {                 
                       isoCode2: 'MN',
                     },
                      '382' : {                 
                       isoCode2: 'ME',
                     },
                      '1-664' : {                 
                       isoCode2: 'MS',
                     },
                      '212' : {                 
                       isoCode2: 'MA',
                     },
                      '258' : {                 
                       isoCode2: 'MZ',
                     },
                      '95' : {                 
                       isoCode2: 'MM',
                     },
                      '264' : {                 
                       isoCode2: 'NA',
                     },
                      '674' : {                 
                       isoCode2: 'NR',
                     },
                      '977' : {                 
                       isoCode2: 'NP',
                     },
                      '31' : {                 
                       isoCode2: 'NL',
                     },
                      '687' : {                 
                       isoCode2: 'NC',
                     },
                      '64' : {                 
                       isoCode2: 'NZ',
                     },
                      '505' : {                 
                       isoCode2: 'NI',
                     },
                      '227' : {                 
                       isoCode2: 'NE',
                     },
                      '234' : {                 
                       isoCode2: 'NG',
                     },
                      '683' : {                 
                       isoCode2: 'NU',
                     },
                      '850' : {                 
                       isoCode2: 'KP',
                     },
                      '1-670' : {                 
                       isoCode2: 'MP',
                     },
                      '47' : {                 
                       isoCode2: 'NO',
                     },
                      '968' : {                 
                       isoCode2: 'OM',
                     },
                      '92' : {                 
                       isoCode2: 'PK',
                     },
                      '680' : {                 
                       isoCode2: 'PW',
                     },
                      '970' : {                 
                       isoCode2: 'PS',
                     },
                      '507' : {                 
                       isoCode2: 'PA',
                     },
                      '675' : {                 
                       isoCode2: 'PG',
                     },
                      '595' : {                 
                       isoCode2: 'PY',
                     },
                      '51' : {                 
                       isoCode2: 'PE',
                     },
                      '63' : {                 
                       isoCode2: 'PH',
                     },
                      '48' : {                 
                       isoCode2: 'PL',
                     },
                      '351' : {                 
                       isoCode2: 'PT',
                     },
                      '974' : {                 
                       isoCode2: 'QA',
                     },
                      '242' : {                 
                       isoCode2: 'CG',
                     },
                      '40' : {                 
                       isoCode2: 'RO',
                     },
                      '250' : {                 
                       isoCode2: 'RW',
                     },
                      '590' : {                 
                       isoCode2: 'BL',
                     },
                      '290' : {                 
                       isoCode2: 'SH',
                     },
                      '1-869' : {                 
                       isoCode2: 'KN',
                     },
                      '1-758' : {                 
                       isoCode2: 'LC',
                     },
                      '508' : {                 
                       isoCode2: 'PM',
                     },
                      '1-784' : {                 
                       isoCode2: 'VC',
                     },
                      '685' : {                 
                       isoCode2: 'WS',
                     },
                      '378' : {                 
                       isoCode2: 'SM',
                     },
                      '239' : {                 
                       isoCode2: 'ST',
                     },
                      '966' : {                 
                       isoCode2: 'SA',
                     },
                      '221' : {                 
                       isoCode2: 'SN',
                     },
                      '381' : {                 
                       isoCode2: 'RS',
                     },
                      '248' : {                 
                       isoCode2: 'SC',
                     },
                      '232' : {                 
                       isoCode2: 'SL',
                     },
                      '65' : {                 
                       isoCode2: 'SG',
                     },
                      '1-721' : {                 
                       isoCode2: 'SX',
                     },
                      '421' : {                 
                       isoCode2: 'SK',
                     },
                      '386' : {                 
                       isoCode2: 'SI',
                     },
                      '677' : {                 
                       isoCode2: 'SB',
                     },
                      '252' : {                 
                       isoCode2: 'SO',
                     },
                      '27' : {                 
                       isoCode2: 'ZA',
                     },
                      '82' : {                 
                       isoCode2: 'KR',
                     },
                      '211' : {                 
                       isoCode2: 'SS',
                     },
                      '34' : {                 
                       isoCode2: 'ES',
                     },
                      '94' : {                 
                       isoCode2: 'LK',
                     },
                      '249' : {                 
                       isoCode2: 'SD',
                     },
                      '597' : {                 
                       isoCode2: 'SR',
                     },
                      '268' : {                 
                       isoCode2: 'SZ',
                     },
                      '46' : {                 
                       isoCode2: 'SE',
                     },
                      '41' : {                 
                       isoCode2: 'CH',
                     },
                      '963' : {                 
                       isoCode2: 'SY',
                     },
                      '886' : {                 
                       isoCode2: 'TW',
                     },
                      '992' : {                 
                       isoCode2: 'TJ',
                     },
                      '255' : {                 
                       isoCode2: 'TZ',
                     },
                      '66' : {                 
                       isoCode2: 'TH',
                     },
                      '228' : {                 
                       isoCode2: 'TG',
                     },
                      '690' : {                 
                       isoCode2: 'TK',
                     },
                      '676' : {                 
                       isoCode2: 'TO',
                     },
                      '1-868' : {                 
                       isoCode2: 'TT',
                     },
                      '216' : {                 
                       isoCode2: 'TN',
                     },
                      '90' : {                 
                       isoCode2: 'TR',
                     },
                      '993' : {                 
                       isoCode2: 'TM',
                     },
                      '1-649' : {                 
                       isoCode2: 'TC',
                     },
                      '688' : {                 
                       isoCode2: 'TV',
                     },
                      '1-340' : {                 
                       isoCode2: 'VI',
                     },
                      '256' : {                 
                       isoCode2: 'UG',
                     },
                      '380' : {                 
                       isoCode2: 'UA',
                     },
                      '971' : {                 
                       isoCode2: 'AE',
                     },
                      '44' : {                 
                       isoCode2: 'GB',
                     },
                      '598' : {                 
                       isoCode2: 'UY',
                     },
                      '998' : {                 
                       isoCode2: 'UZ',
                     },
                      '678' : {                 
                       isoCode2: 'VU',
                     },
                      '379' : {                 
                       isoCode2: 'VA',
                     },
                      '58' : {                 
                       isoCode2: 'VE',
                     },
                      '84' : {                 
                       isoCode2: 'VN',
                     },
                      '681' : {                 
                       isoCode2: 'WF',
                     },
                      '967' : {                 
                       isoCode2: 'YE',
                     },
                      '260' : {                 
                       isoCode2: 'ZM',
                     },
                      '263' : {                 
                       isoCode2: 'ZW',
                     }
                   }                                        
 }
    