<main>
  <div>
    <h1 class="header">Privacy Policy</h1>
  </div>
  <div class="policy-section">
    <p>
      At Pakistan Sweet Home, we prioritize the privacy and security of all
      visitors to our website, whether you are a student applying for our
      educational programs or a donor supporting our cause. This Privacy Policy
      explains how we collect, use, and safeguard your personal information, as
      well as the choices you have regarding its use.
    </p>
    <h2>Scope of the Policy</h2>
    <p>
      This Privacy Policy applies solely to the information collected through
      Pakistan Sweet Home’s website. By using our website, you agree to the
      terms outlined here.
    </p>
    <p>
      We assure you that all personal information, including payment details,
      will not be stored, sold, shared, rented, or leased to any third parties.
    </p>
    <h2>Updates to the Policy</h2>
    <p>
      Our Privacy Policy and Terms & Conditions may be revised periodically to
      meet evolving standards and requirements. We encourage visitors to review
      this section frequently to stay informed of any changes. Updates will take
      effect the day they are posted.
    </p>

    <div class="information-collect">
      <h2>Information We Collect</h2>
      <p>We collect information based on your interaction with our site:</p>
      <div class="points">
        <h3>1. For Students Applying for Educational Programs:</h3>
        <p>
          We may collect personal details such as your name, contact
          information, educational background, and other relevant information
          required for the application process.
        </p>
        <h3>2. For Donors:</h3>
        <p>
          When you make a donation, we collect your name, email address, and
          payment information to process your donation securely through Stripe.
        </p>
      </div>
    </div>
    <div class="non-pii">
      <h4 class="non-pi-heading">
        Non-Personally Identifiable Information (non-PII):
      </h4>
      <p>
        We may also collect data related to your usage of our website, which
        does not identify you personally. This includes information such as your
        IP address, which helps us understand how visitors use our site and
        improve its functionality.
      </p>
    </div>
    <div class="collected-information">
      <h2>Use of Collected Information</h2>
      <p>We use the information you provide for the following purposes:</p>
      <div class="points">
        <p class="points-for-student">For Students:</p>
        <p>
          To process your application for admission and communicate with you
          regarding the educational programs at Pakistan Sweet Home.
        </p>
        <p class="points-for-donors">For Donors:</p>
        <p>
          To process your donations and send you updates regarding how your
          contributions are making an impact.
        </p>
      </div>
    </div>
    <div class="Sharing-information">
      <h3 class="sharing-info-heading">
        Sharing Information with Third Parties
      </h3>
      <p>
        We DO NOT share or sell your Personally Identifiable Information (PII)
        to third parties except under the following circumstances:
      </p>
      <ul class="bullet-points">
        <li style="margin-bottom: 20px">If required by law.</li>
        <li style="margin-bottom: 20px">
          To process transactions via online payment systems (e.g., Stripe),
          which may involve sharing your name, email, and billing information.
        </li>
        <li>
          When we engage service providers or contractors for operations or
          marketing, who are required to handle your information securely and in
          accordance with this Privacy Policy.
        </li>
      </ul>
    </div>
    <div class="cookies">
      <h3 class="cookies-heading">Cookies</h3>
      <p>Our website uses cookies to enhance your experience by:</p>
      <ul class="bullet-points">
        <li style="margin-bottom: 20px">
          Recognizing if you have previously logged in.
        </li>
        <li style="margin-bottom: 20px">
          Customizing your browsing experience.
        </li>
        <li style="margin-bottom: 20px">
          Tailoring offers or promotions to your preferences.
        </li>
      </ul>
    </div>
    <p>
      Cookies are small data files stored on your device, and you can choose to
      block them in your browser settings. However, this may limit the
      functionality of certain features on our site.
    </p>
  </div>
</main>
