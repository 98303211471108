<section class="projectsTopSec">
    <div class="projectHead">
        <p>Our Projects</p>
        <h1>We are working on multiple projects intending to create an impact for generations to come.</h1>
    </div>
</section>

<section class="allProjectsWrapper">
    <div class="container">
        <div class="genericFilterTabs">
            <h2><button class="filtersBtn" [ngClass]="activeProjectType == 'campuses' ? 'active' : '' " (click)="changeProject('campuses')">Campuses</button></h2>
            <h2><button class="filtersBtn" [ngClass]="activeProjectType == 'bloodBank' ? 'active' : '' " (click)="changeProject('bloodBank')" >Blood Bank</button></h2>
            <h2><button class="filtersBtn" [ngClass]="activeProjectType == 'upcoming' ? 'active' : '' " (click)="changeProject('upcoming')" >Upcoming</button></h2>
        </div>

        <app-campuses [pageName]="activeProjectType" *ngIf="activeProjectType == 'campuses' || activeProjectType == 'upcoming' "></app-campuses>

        <app-blood-bank *ngIf="activeProjectType == 'bloodBank'"></app-blood-bank>

    </div>
</section>