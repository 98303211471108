<section class="meetTeam newBlueSec">
  <h1 style="display: none">Testimonials</h1>
  <div class="container">
    <div class="testimonial_head">
      <p>TESTIMONIALS</p>
      <h2>
        We are truly humbled and grateful to have the support of leading figures
        both in Pakistan and internationally.
      </h2>
    </div>

    <div meetTeamWraper>
      <!-- <div class="tab_buttons">
        <a [ngClass]="{'active': selectedDiv === 1}" (click)="showDiv(1)">all</a>
        <a [ngClass]="{'active': selectedDiv === 2}" (click)="showDiv(2)">politicians</a>

        <a [ngClass]="{'active': selectedDiv === 3}" (click)="showDiv(3)">diplomats</a>
        <a [ngClass]="{'active': selectedDiv === 4}" (click)="showDiv(4)">sportsmen</a>
      </div> -->
      <div class="genericFilterTabs">
        <h2>
          <button
            class="filtersBtn"
            [ngClass]="{ active: selectedDiv === 1 }"
            (click)="showDiv(1)"
          >
            All
          </button>
        </h2>
        <h2>
          <button
            class="filtersBtn"
            [ngClass]="{ active: selectedDiv === 2 }"
            (click)="showDiv(2)"
          >
            Politicians
          </button>
        </h2>
        <h2>
          <button
            class="filtersBtn"
            [ngClass]="{ active: selectedDiv === 3 }"
            (click)="showDiv(3)"
          >
            Diplomats
          </button>
        </h2>
        <h2>
          <button
            class="filtersBtn"
            [ngClass]="{ active: selectedDiv === 4 }"
            (click)="showDiv(4)"
          >
            Sportsmen
          </button>
        </h2>
      </div>
      <div [class.d-none]="selectedDiv != 2">
        <div class="stotyparent">
          <div class="denTeamwraPPer organizationEvent">
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/A0D6UzSt4vk"
                >
                  <img
                    src="assets/images/afridi.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Mirza Muhammad Afridi</h3>
              <p class="slide-desc">Deputy Chairman Senate of Pakistan</p>
            </div>
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/HaqiJrYeXF8"
                >
                  <img
                    src="assets/images/iqbal.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Walid Iqbal</h3>
              <p class="slide-desc">Member Senate of Pakistan</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/POFftHvMexQ"
                >
                  <img
                    src="assets/images/qamar.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Qamar ul Zaman Qaira</h3>
              <p class="slide-desc">Senior Politician</p>
            </div>
          </div>
        </div>
        <div class="denpag-pagination"></div>
      </div>
      <div [class.d-none]="selectedDiv != 3">
        <div class="usa__swiper usaswiper">
          <div class="usaWraper">
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/MP3ADbtFU9k"
                >
                  <img
                    src="assets/images/test_david.webp"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">David Hale</h3>
              <p class="slide-desc">US Ambassador</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/1VVSYs9HUMU"
                >
                  <img
                    src="assets/images/sheraf.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Sheraf</h3>
              <p class="slide-desc">Bangladesh Delegate</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/qJnZ3OTb8aA"
                >
                  <img
                    src="assets/images/idris.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">AH Idris</h3>
              <p class="slide-desc">Nigerian Delegate</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/73iOaxM_iy4"
                >
                  <img
                    src="assets/images/paul.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Paul</h3>
              <p class="slide-desc">UK Delegate</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/8mGRcBoaebQ"
                >
                  <img
                    src="assets/images/fawareh.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Khalif. Al Fawa'reh</h3>
              <p class="slide-desc">Jordan Delegate</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/W1O4z8kHC34"
                >
                  <img
                    src="assets/images/jakob.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Jakob Linulf</h3>
              <p class="slide-desc">Danish Ambassador</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/Vg2bpMQ2p2I"
                >
                  <img
                    src="assets/images/hamad.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Hamad Obaid Ibrahim</h3>
              <p class="slide-desc">UAE Ambassador</p>
            </div>
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/ue2pd8OIWUs"
                >
                  <img
                    src="assets/images/abdul.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">AU Abdul</h3>
              <p class="slide-desc">Nigerian Delegate</p>
            </div>
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/qUUVTJ0_1gw"
                >
                  <img
                    src="assets/images/weerasuriya.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Sujeewa Weerasuriya</h3>
              <p class="slide-desc">Srilankan Delegate</p>
            </div>
          </div>
        </div>
        <div class="usa-pagination"></div>
      </div>

      <div [class.d-none]="selectedDiv != 4">
        <div class="sportsman__swiper sportsswiper">
          <div class="sportsmanWraper">
            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/GwSuMsr8SIc"
                >
                  <img
                    src="assets/images/test_misbah.webp"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Misbah Ul Haq</h3>
              <p class="slide-desc">Former Pakistan Cricket Team Captain</p>
            </div>

            <div class="single_cel">
              <div class="play-icon">
                <a
                  data-fancybox="testimonial-video"
                  href="https://youtu.be/H4YRhzqsnpM"
                >
                  <img
                    src="assets/images/shadab.jpg"
                    alt="hero background image"
                  />
                  <span>
                    <svg
                      width="74"
                      height="52"
                      viewBox="0 0 74 52"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                        fill="white"
                        fill-opacity="0.72"
                      />
                    </svg>
                  </span>
                </a>
              </div>
              <h3 class="slide-heading">Shadaab Khan</h3>
              <p class="slide-desc">Pakistani Cricketer</p>
            </div>
          </div>
        </div>
        <div class="sports-pagination"></div>
      </div>
    </div>
  </div>
  <div class="dsktpSwiperWrapper" [class.d-none]="selectedDiv != 1">
    <div class="temp__swiper subSwiper">
      <div class="pakTeamwraPPer">
        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/A0D6UzSt4vk"
            >
              <img src="assets/images/afridi.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Mirza Muhammad Afridi</h3>
          <p class="slide-desc">Deputy Chairman Senate of Pakistan</p>
        </div>
        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/HaqiJrYeXF8"
            >
              <img src="assets/images/iqbal.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Walid Iqbal</h3>
          <p class="slide-desc">Member Senate of Pakistan</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/POFftHvMexQ"
            >
              <img src="assets/images/qamar.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Qamar ul Zaman Qaira</h3>
          <p class="slide-desc">Senior Politician</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/MP3ADbtFU9k"
            >
              <img
                src="assets/images/test_david.webp"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">David Hale</h3>
          <p class="slide-desc">US Ambassador</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/1VVSYs9HUMU"
            >
              <img src="assets/images/sheraf.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Sheraf</h3>
          <p class="slide-desc">Bangladesh Delegate</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/qJnZ3OTb8aA"
            >
              <img src="assets/images/idris.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">AH Idris</h3>
          <p class="slide-desc">Nigerian Delegate</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/73iOaxM_iy4"
            >
              <img src="assets/images/paul.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Paul</h3>
          <p class="slide-desc">UK Delegate</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/8mGRcBoaebQ"
            >
              <img
                src="assets/images/fawareh.jpg"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Khalif. Al Fawa'reh</h3>
          <p class="slide-desc">Jordan Delegate</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/W1O4z8kHC34"
            >
              <img src="assets/images/jakob.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Jakob Linulf</h3>
          <p class="slide-desc">Danish Ambassador</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/Vg2bpMQ2p2I"
            >
              <img src="assets/images/hamad.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Hamad Obaid Ibrahim</h3>
          <p class="slide-desc">UAE Ambassador</p>
        </div>
        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/ue2pd8OIWUs"
            >
              <img src="assets/images/abdul.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">AU Abdul</h3>
          <p class="slide-desc">Nigerian Delegate</p>
        </div>
        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/qUUVTJ0_1gw"
            >
              <img
                src="assets/images/weerasuriya.jpg"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Sujeewa Weerasuriya</h3>
          <p class="slide-desc">Srilankan Delegate</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/ss6-n98QEuI"
            >
              <img
                src="assets/images/test_sohail.webp"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Mr. Sohail Amaan</h3>
          <p class="slide-desc">Ex-Air Chief Marshall</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/GwSuMsr8SIc"
            >
              <img
                src="assets/images/test_misbah.webp"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Misbah Ul Haq</h3>
          <p class="slide-desc">Former Pakistan Cricket Team Captain</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/H4YRhzqsnpM"
            >
              <img src="assets/images/shadab.jpg" alt="hero background image" />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Shadaab Khan</h3>
          <p class="slide-desc">Pakistani Cricketer</p>
        </div>

        <div class="single_cel">
          <div class="play-icon">
            <a
              data-fancybox="testimonial-video"
              href="https://youtu.be/clYsa35xLWE"
            >
              <img
                src="assets/images/test_alvin.webp"
                alt="hero background image"
              />
              <span>
                <svg
                  width="74"
                  height="52"
                  viewBox="0 0 74 52"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M71.7409 8.02517C70.8968 4.88708 68.4227 2.41336 65.285 1.56837C59.553 0 36.6242 0 36.6242 0C36.6242 0 13.6964 0 7.96436 1.50891C4.88708 2.35301 2.35256 4.88753 1.50846 8.02517C0 13.7568 0 25.6434 0 25.6434C0 25.6434 0 37.59 1.50846 43.2617C2.35345 46.3993 4.82672 48.873 7.96481 49.718C13.7568 51.2868 36.6251 51.2868 36.6251 51.2868C36.6251 51.2868 59.553 51.2868 65.285 49.7779C68.4231 48.9334 70.8968 46.4597 71.7418 43.322C73.2498 37.59 73.2498 25.7038 73.2498 25.7038C73.2498 25.7038 73.3102 13.7568 71.7409 8.02517ZM29.2989 36.794L29.3243 14.6621L48.3773 25.2107L29.2989 36.794Z"
                    fill="white"
                    fill-opacity="0.72"
                  />
                </svg>
              </span>
            </a>
          </div>
          <h3 class="slide-heading">Mr. Alvin Naboya</h3>
          <p class="slide-desc">International program officer DTDA</p>
        </div>
      </div>
    </div>
    <div class="mpwRev-pagination"></div>
  </div>
</section>
