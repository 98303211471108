
<section class="signInPages">
    <div class="aj_mob">
      <img src="assets/images/aj_signin.png" alt="sign in">
    </div>
    <div class="pageWrapper">
      <h1>It happens to the best of us.</h1>
      <p class="aj_confirm">We'll email you a reset link.</p>
   
      <form class="signInForm SignPageForm">
        <!-- <p class="serverError" *ngIf="errorMsg">
          <img src="/assets/new-images/error-sign-new.png" alt="error sign">
          {{errorMsg}}
        </p> -->
        <div class="inputWrapper normalInputWrapper">
          <label>EMAIL</label>
          <input id="email" type="text" [(ngModel)]="tour.email"  [ngModelOptions]="{standalone: true}" (keyup)="removeInvalid('#email')"  placeholder="Email" />
          <!-- <p class="error">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
            Please Enter Valid Email
          </p> -->
        </div>

        <button *ngIf="!loading" (click)="validateFormtour()"  class="formSumitBtn" >
            SEND RESET LINK
        </button>
        <div *ngIf="loading" class="loader">
          <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
        </div>
      </form>
      <a href="javascript:;" routerLink="/sign-up" class="alternateJoinBtn">NEED AN ACCOUNT? JOIN NOW</a>
    </div>
  </section>
  
  <div *ngIf="forgetPass" class="overlay" (click)="toggleforget()"></div>
<div class="cat-page" [ngClass]="forgetPass ? 'show' : ''">

  <div class="new_closeBTN">


    <span class="d-hidden closebtn" (click)="toggleforget()">
      <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.94033 5.95923L11.307 1.59306C11.578 1.322 11.578 0.882532 11.307 0.611496C11.0359 0.340439 10.5965 0.340439 10.3254 0.611496L5.95923 4.97812L1.59306 0.611496C1.322 0.340439 0.882532 0.340439 0.611496 0.611496C0.34046 0.882554 0.340439 1.32202 0.611496 1.59306L4.97812 5.95923L0.611496 10.3254C0.340439 10.5965 0.340439 11.0359 0.611496 11.307C0.882554 11.578 1.32202 11.578 1.59306 11.307L5.95923 6.94033L10.3254 11.307C10.5965 11.578 11.0359 11.578 11.307 11.307C11.578 11.0359 11.578 10.5965 11.307 10.3254L6.94033 5.95923Z"
          fill="#00AAE9" stroke="#00AAE9" stroke-width="0.5" />
      </svg>


    </span>
  </div>
  <div class="forgetpass">
    <img src="assets/images/forgot_pass.gif" alt="forgot_pass">
    <p class="remember_password">Don’t Remember your Password?</p>
    <p class="enter_pass">Enter an email address where we can send you the password.</p>
    <a class="aj_ok" href="javascript:;">Okay!</a>
    <p class="dont_recive">Don’t have an account? <a href="javascript:;">Sign Up</a> </p>
  </div>




</div>
 
  