import { Component, OnInit } from '@angular/core';
import { AppSettings, HelperService, SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-updates',
  templateUrl: './updates.component.html',
  styleUrls: ['./updates.component.css']
})
export class UpdatesComponent implements OnInit {

  public loading = true
  public dynamicArticles

  public articles: any = [
    {
      'name': "PSH Boarding School Empowering Orphans Through Education",
      'desc': "Pakistan Sweet Home (PSH) runs a transformative boarding school program for orphaned children, providing education from Nursery to 7th grade in a supportive environment.",
      'link': '/updates/empowering-orphans-through-education',
      'date': '2024-04-25'
    },
    {
      'name': "Iran’s First Lady Spent a Day with our Children at Pakistan Sweet Home",
      'desc': "Jamileh Alamolhoda, accompanied by Asifa Bhutto Zardari, visited Pakistan Sweet Home to meet the children on the first of her three-day trip to Pakistan.",
      'link': '/updates/bridging-borders-with-kindness',
      'date': '2024-04-22'
    },
    {
      'name': "Highlights of Ramadan 2024",
      'desc': "Let's take a glimpse into how the children of Pakistan Sweet Home spent their Ramadan this year!",
      'link': '/updates/highlights-of-ramadan-2024',
      'date': '2024-04-7'
    },
    {
      'name': "Annual Result Day",
      'desc': "During the Annual Result Day, Headmistress Miss Sadia and Principal Sir Waseem Janjua motivated students by awarding prizes and medals.",
      'link': '/updates/annual-result-day',
      'date': '2024-03-22'
    },
    {
      'name': "A Heartwarming Sehri Event at Serena Hotel, Islamabad 2024",
      'desc': "A remarkable Sehri event, hosted at Serena Hotel in Islamabad and sponsored by Serena and the Islamabad Chamber of Commerce, drew over 500 attendees.",
      'link': '/updates/a-heartwarming-sehri-event',
      'date': '2024-03-22'
    },
    {
      'name': "Joyful Trip on the Double-Decker Sightseeing Bus",
      'desc': "Pakistan Sweet Home organized recreational activities for children, including a joyful trip on the Double-Decker Sightseeing Bus, enhancing their exploration.",
      'link': '/updates/joyful-trip-on-the-double-decker-sightseeing-bus',
      'date': '2022-10-09'
    },
    {
      'name': "Raja Pervaiz Ashraf Inaugurates a New Hostel Building at Cadet College",
      'desc': "Inauguration of Abdul Mussawar & Maskeena Jan complex at Cadet College Sohawa with 448 cadet capacity, featuring Raja Pervaiz Ashraf's support.",
      'link': '/updates/raja-pervaiz-ashraf-inaugurates-a-new-hostel-building-at-cadet-college',
      'date': '2022-09-07'
    },
    {
      'name': "Federal Education Minister Visit to Pakistan Sweet Home",
      'desc': "Federal Education Minister Rana Tanveer Hussain promises PKR 6 crore yearly for orphans' education and praises Pakistan Sweet Home.",
      'link': '/updates/federal-education-minister-announced-pkr-6-crore-for-orphans',
      'date': '2022-06-30'
    },
    {
      'name': "Golden Opportunity for Orphans Nationwide",
      'desc': "Pakistan Sweet Home Cadet College Sohawa offers free education to orphans nationwide, with admissions open for Intermediate programs and advanced facilities.",
      'link': '/updates/golden-opportunity-for-orphans-nationwide',
      'date': '2022-06-30'
    },
    {
      'name': "Government of Pakistan Gifted Four Toyota Hiace Vans",
      'desc': "Prime Minister Shehbaz Sharif and Interior Minister Rana Sanaullah donated four Toyota Hiace vans worth Rs 62 million to Pakistan Sweet Home.",
      'link': '/updates/government-of-pakistan-gifted-four-toyota-hiace-vans',
      'date': '2022-06-30'
    },
    {
      'name': "World Boxing Champion Usman Wazir to Establish Boxing Academy at Pakistan Sweet Homes",
      'desc': "World Boxing Council Middle East Champion Usman Wazir has planned to establish a boxing academy at Pakistan Sweet Home. He aims to train children for international tournaments.",
      'link': '/updates/world-boxing-champion-usman-wazir-to-establish-boxing-academy',
      'date': '2022-06-30'
    },
    {
      'name': "Zamurrad Khan Inaugurates a New Building at Pakistan Sweet Home Sargodha",
      'desc': "Bhalwal Campus expands for higher education, celebrates new building opening & 5th annual prize distribution for academic excellence.",
      'link': '/updates/zamurrad-khan-inaugurates-a-new-building',
      'date': '2022-06-30'
    },
    {
      'name': "Fun-filled Trip Arranged for Sargodha Campus Children",
      'desc': "A joyful trip for Sargodha campus children to Islamabad, where they visited Lake View Park and Faisal Mosque.",
      'link': '/updates/fun-filled-trip-arranged-for-sargodha-campus-children',
      'date': '2022-06-30'
    },    

  ];

  constructor(
    private helperService            : HelperService,
    private seoService: SEOService,
  ) {
    this.getArticles()
  }

  getArticles(){
    this.loading = true;
      let url = AppSettings.BACK_ENDPOINT + 'get-all-articles'
      let formData = {
        active_only : true
      }

      this.helperService.httpPostRequests(url, formData).then(resp => {   
        this.dynamicArticles = resp.articles
        setTimeout(() => {
          this.loading = false;
        }, 3000);   
      })
      .catch(error => {
        this.loading = false;
      });
  }


  ngOnInit(): void {
    this.setSEO_Tags("Get Updates about Pakistan Sweet Home","Stay informed with the latest updates from Pakistan Sweet Home, including events, achievements, and opportunities for involvement.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
