import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })

export class DonationService {
  public step1Data     : any = '';
  public step2Data     : any = '';

  constructor() {
  }

  getStep1Data() {
    return this.step1Data;
  }

  updateStep1Data(step1Data: any) {
    this.step1Data = step1Data;
  }

  getStep2Data() {
    return this.step2Data;
  }

  updateStep2Data(step2Data: any) {
    this.step2Data = step2Data;
  }
}
