<section class="termsSec">
    <div class="container">
        <h1>Terms & Conditions</h1>
        <div class="contentWrapper">
            <p>Pakistan Sweet Home is dedicated to the highest standards of compliance. Your generous contribution to our organization is invaluable and is essential in our mission to provide shelter, care, and support to orphaned children in Pakistan. Please take a moment to review the following terms and conditions about your donation.</p>
            <h2>Purpose of Donation</h2>
            <p class="mg25">Your contribution to Pakistan Sweet Home serves a meaningful and impactful purpose. Our organization is fully committed to improving the lives of underprivileged orphan children across Pakistan. Our mission encompasses various essential services and initiatives to help these children thrive and reach their fullest potential.</p>
            <div class="purposeWrapper">
                <div class="singlePurpose">
                    <h3>Secure Home</h3>
                    <p>Your donation is vital in ensuring these children have a safe and secure place to call home. A stable living environment is the foundation upon which they can build brighter futures.</p>
                </div>
                <div class="singlePurpose">
                    <h3>Education</h3>
                    <p>Education is a powerful tool for breaking the cycle of poverty. Your support enables us to provide quality education to these children, equipping them with the knowledge and skills they need to pursue their dreams and contribute positively to society.</p>
                </div>
                <div class="singlePurpose">
                    <h3>Healthcare</h3>
                    <p>Good health is fundamental to a child's well-being. With your help, we can offer access to healthcare services, including regular check-ups, vaccinations, and medical treatments, ensuring that these children grow up healthy and strong.</p>
                </div>
                <div class="singlePurpose">
                    <h3>Nutritious Meals</h3>
                    <p>Proper nutrition is essential for growth and development. Your donation helps us provide healthy meals to these children, ensuring they have the energy and nourishment they need to thrive.</p>
                </div>
                <div class="singlePurpose">
                    <h3>Nurturing Environment</h3>
                    <p>Beyond meeting their basic needs, we create a loving and nurturing environment for these children. Your support helps us provide emotional and psychological support, counseling, and mentorship to help them overcome the challenges they face.</p>
                </div>
                <div class="singlePurpose">
                    <h3>Physical, Emotional, and Intellectual Growth</h3>
                    <p>We are committed to fostering holistic growth in these children. Your contribution facilitates opportunities for them to engage in extracurricular activities, sports, arts, and cultural experiences that enrich their lives and help them discover their talents and passions.</p>
                </div>                                
            </div>
            <p>In addition to these core services, we aim to create a sense of community and belonging for these children. By providing them with a loving and supportive family-like environment, they can build solid foundations for their futures and break free from the cycle of poverty.</p>
            <p class="mg50">Your donation is not just a financial contribution; it's a lifeline for these children, offering them hope, opportunity, and a chance for a better tomorrow. We are deeply grateful for your support in our mission to transform the lives of underprivileged children in Pakistan. Together, we can make a lasting difference and create a brighter, more promising future for these young souls.</p>
            <h2>Non-Refundable Contribution</h2>
            <p>We are grateful for your generous donation to our cause. Your decision to support our mission is genuinely appreciated and reflects your commitment to positively impacting the lives of the children we serve. We want to clarify the policy regarding your contribution.</p>
            <p class="mg50">Once a donation has been made, it is considered non-refundable. This policy is in place to ensure that the funds we receive are dedicated entirely to our mission and the well-being of the children under our care. Although we understand that circumstances may change and unexpected situations may arise, we assure you that your contributions are the best possible use.</p>
            <h2>Legality and Ethics</h2>
            <p class="mg30">Your commitment to supporting our cause through your generous donation is greatly appreciated, and we want to ensure that every aspect of your contribution aligns with the highest standards of legality and ethics. When you donate, you are helping us fulfil our mission and contributing to our organization's integrity and reputation.</p>
            <div class="legalityWrapper">
                <h3>Lawful Contribution</h3>
                <p>By donating, you affirm that your contribution is entirely legal and complies with all applicable local, national, and international laws and regulations. We hold a deep respect for the rule of law, and your adherence to legal standards is crucial in upholding the integrity of our organization.</p>
                <h3>Ethical Commitment</h3>
                <p>Your donation represents more than just financial support; it signifies a shared commitment to ethical conduct. We operate with unwavering ethical principles, believing that every contribution should reflect those principles. Your moral obligation ensures we can continue our work with integrity and trustworthiness.</p>
                <h3>Fraud-Free Funds</h3>
                <p>It is of utmost importance that donated funds do not originate from illegal or fraudulent activity. Your certification that the funds are clean and earned through legitimate means protects our organization and safeguards the reputation of all our supporters.</p>
                <h3>Preventing Misuse</h3>
                <p>Your commitment to lawful and ethical donations helps us avoid potential misuse of funds. We have stringent internal controls to ensure that all contributions are channeled toward their intended purpose—improving the lives of underprivileged children.</p>
                <h3>Impactful Change</h3>                                
                <p>When you donate with a clear conscience, you maximize its impact. It enables us to direct resources towards initiatives that genuinely make a difference in the lives of the children we serve, providing them with a brighter future.</p>
                <p class="mg90">By adhering to these principles of legality and ethics, you support our organization and set an example for others who may wish to contribute. Your donation serves as a beacon of ethical conduct and legality, reinforcing the values that guide our mission. We are deeply grateful for your commitment to making a positive difference in the world, and we pledge to honour that commitment by upholding the highest standards of legality and ethics in all that we do. Thank you for being a vital part of our cause.</p>
            </div>
            <h2>Commitment to Accountability and Transparency</h2>
            <p class="mg50">At Pakistan Sweet Home, our unwavering commitment to accountability and transparency is at the heart of everything we do. We recognize that when you support our mission with your generous donation, you place tremendous trust in us. We take your trust seriously and guarantee careful, diligent, and transparent contribution handling. We take your trust seriously and ensure attentive, engaged, and transparent contribution handling.</p>
            <h2>Tax Deductibility</h2>
            <p>Your generous contributions to Pakistan Sweet Home may allow you to receive tax deductions per Pakistan's tax laws and regulations. Understanding the tax implications of your donation is essential, and we encourage you to seek guidance from a qualified tax advisor or financial expert who can provide you with personalized information and advice tailored to your circumstances.</p>
            <p class="mg50">By consulting with a tax advisor, you can ensure that you make informed decisions about your charitable giving, maximize any potential tax benefits, and confidently navigate the complexities of tax laws. We appreciate your dedication to our cause and desire to make the most of your contributions. If you have any questions or require specific documentation from Pakistan Sweet Home to support your tax deduction claims, please don't hesitate to contact our team. We are here to assist you in any way we can.</p>
            <h2>Privacy and Data Protection</h2>
            <p>Your privacy and the security of your personal information are paramount to us at Pakistan Sweet Home. We want you to feel confident and secure when donating to our organization. We maintain strict privacy and data protection policies to safeguard your personal and financial information.</p>
            <p class="mg50">We promise to handle your personal and financial information with the highest care and attention. We understand that your trust in us is the foundation of our partnership, and we take our responsibility to protect your data very seriously. Thank you for trusting us to keep your information private and secure.</p>
            <h2>Donor Rights</h2>
            <p>As a cherished and esteemed donor, your commitment to our cause is deeply appreciated and profoundly respected. We believe in transparency and accountability as fundamental principles in our relationship with you. We want you to be informed and empowered, knowing that your support makes a real and meaningful difference in the lives of the children we serve.</p>
            <p>The Relationship Manager provides Regular Updates on children to the sponsors. We believe in keeping you well-informed. You can expect to receive regular updates and activities. These reports highlight the progress and achievements made possible through your support, giving you a clear picture of the positive changes your contribution affects.</p>
            <p class="mg50">We value your engagement and input. If you have specific areas of interest or questions about our work, we are happy to provide personalized information and engage in meaningful conversations to address your concerns.</p>
            <h2>Amendment of Terms</h2>
            <p>At Pakistan Sweet Home, we are committed to ensuring that our operations and policies align with our mission and the evolving needs of our organization. To this end, we retain the right to modify these terms and conditions without prior notification. We value transparency and want to inform you about the reasons behind this practice and how it may impact your engagement with us.</p>
            <p class="mg50">While change is a natural part of any dynamic organization, our unwavering commitment to our mission and you, our supporters, remains constant. We appreciate your understanding and flexibility in adapting to any necessary amendments to our terms and conditions. Your support is integral to our success in improving the lives of underprivileged children, and we look forward to continuing this important journey together.</p>
            <h2>Acknowledgment and Receipts</h2>
            <p class="mg50">At Pakistan Sweet Home, we sincerely appreciate your generous contributions and want to ensure that you have the documentation to acknowledge and track your donations. Here's how we handle acknowledgments and receipts for your assistance:</p>
            <div class="legalityWrapper">
                <h3>Prompt Acknowledgment</h3>
                <p>We will promptly acknowledge your contribution as soon as we receive your donation, whether made online or offline. Your donation plays a crucial role in our mission, and we want to express our gratitude for your support.</p>
                <h3>Electronic Receipts (Online Donations)</h3>
                <p>If you donate through our website or other online platforms, you will receive an electronic receipt via email. This receipt is the official confirmation of your contribution and includes essential details such as the donation amount, date, and our organization's contact information.</p>
                <h3>Paper Receipts (Offline Donations)</h3>
                <p>We are happy to provide a paper receipt upon request for donations made through offline channels, such as checks or in-person contributions. Let us know your preference, and we will ensure you receive a printed acknowledgment for your records.</p>
                <p class="mg50">Your contributions are instrumental in advancing our mission to improve the lives of underprivileged children, and we want to ensure that your support is recognized and adequately documented. If you have any specific requests or questions regarding your donations, receipts, or acknowledgments, please don't hesitate to contact our team. Your trust in Pakistan Sweet Home is deeply valued, and we are here to assist you in any way we can. Thank you for being a vital part of our cause.</p>
            </div>
            <h2>Monthly Recurring Donations</h2>
            <p>By choosing monthly recurring donations, you empower Pakistan Sweet Home to deduct a specified amount from your monthly payment method. This consistent support is vital for the continuity of our programs. If circumstances change, you can modify or cancel your recurring donation at any time through our provided contact information.</p>
            <p>Your recurring donations undergo rigorous transparency and accountability standards. We maintain meticulous records to ensure your support efficiently benefits the children we serve. We will promptly confirm the changes if you decide to adjust or cease your recurring donation. Monthly regular donations significantly impact our ability to sustain long-term projects and provide consistent support to underprivileged children, fostering positive change throughout the year. We sincerely thank our recurring donors for their unwavering commitment to our cause. Your dedication plays a pivotal role in improving the lives of these children, and we sincerely appreciate your ongoing support. Don't hesitate to contact us for any questions, concerns, or specific instructions related to your recurring donation. Your decision to establish a monthly recurring donation reflects your steadfast commitment to our mission and brings hope and opportunity to vulnerable children, enriching their futures.</p>
            <h2>Dispute Resolution</h2>
            <p>At Pakistan Sweet Home, we value your support and take any concerns or disputes seriously. We encourage you to contact our dedicated team if you have any questions or issues related to your donation or our organization. We are committed to fostering open communication and resolving matters amicably, efficiently, and professionally.</p>
            <p class="mg30">Here's how we approach the resolution of disputes or concerns:</p>
            <div class="legalityWrapper">
                <h3>Open Dialogue</h3>
                <p>We believe in the power of sincere and constructive dialogue. Your feedback is invaluable, and we encourage you to communicate your concerns clearly and candidly. Our team is here to listen and address your questions and issues.</p>
                <h3>Prompt Response</h3>
                <p>Your concerns will be promptly acknowledged. We will respond quickly, addressing your questions and taking necessary actions. Professionalism: Our team handles disputes and concerns professionally and respectfully. We treat every inquiry or issue as an opportunity to improve and strengthen our relationship with you, our valued supporter.</p>
                <h3>Resolution Options</h3>
                <p>We will assist in exploring resolution options based on the dispute, which may include providing clarifications or adjustments.</p>
                <h3>Escalation</h3>
                <p>If, for any reason, your concerns are not adequately resolved at the initial stage, we have established escalation procedures to ensure that your issues are reviewed and addressed at a higher level within our organization.</p>
                <p>By encouraging open and respectful communication, we aim to maintain a positive and productive relationship with you, our valued donor.</p>
            </div>
            <h2>Sponsorship Cancellation and Refund Policy</h2>
            <p>We understand that circumstances may change, and sponsors may, for various reasons, need to cancel their sponsorship commitments. Pakistan Sweet Home recognizes the importance of providing clarity and fairness regarding sponsorship cancellations. Therefore, we have developed a clear and transparent Sponsorship Cancellation and Refund Policy to guide sponsors and our organization.</p>
            <p>Our policy ensures the cancellation process is straightforward and equitable for all parties involved. Specific details may vary depending on the sponsorship program and terms.</p>
            <p>By donating to the Pakistan Sweet Home through our website, you acknowledge that you have carefully read, comprehended, and consented to adhere to these terms and conditions. Your donation signifies a profound dedication to our mission, and we extend our heartfelt gratitude for your trust and unwavering support.</p>
            <p>Should you have any questions or require further clarification regarding these terms and conditions, please do not hesitate to contact us using the contact information provided on our website.</p>
        </div>
    </div>
</section>