<section class="banner_section">
  <div class="containerFluid">
    <div class="bannerWrapper">
      <div class="bannerContent">
        <h1>1st Year Admissions - Pakistan Sweet Home Cadet College</h1>
        <p>You can join the college in 1st year. All seats are available for orphans to compete fairly. The college is affiliated with the Federal Board Of intermediate and Secondary Education, Islamabad.</p>
      </div>
      <div class="bg_video">
        <img src="assets/new-images/cadet-college-11th-class-banner-img.png" alt="banner img">
      </div>
    </div>
  </div>
</section>

<!-- <section class="admissionBannerSec">
  <div class="containerFluid">
    <div class="admissionBannerWrapper">
      <h1>1st Year Admissions - Pakistan Sweet Home Cadet College</h1>
      <p>You can join the college in 1st year. All seats are available for orphans to compete fairly. The college is affiliated with the Federal Board Of intermediate and Secondary Education, Islamabad.</p>
      <img src="assets/new-images/cadet-college-11th-class-banner-img.png" alt="banner img">
    </div>
  </div>
</section> -->
  
  <section class="main_accord" id="scroll-section7">
    <div class="container">
      <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
        <div class="card show" data-aos="fade-up" >
          <div class="card-head">
            <h3>Who can apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body">
            <section class="admissionApply">
                  <div class="main_apply">
                    <div class="apply_left">
                      <ol class="noStyle">
                        <li><strong>1.</strong> Candidates must have passed class 9th and waiting for the result of 10th class. </li>
                        <li><strong>2.</strong> Age must be below 16 and a half year on 1st July of the year of admission. </li>
                        <li><strong>3.</strong> Candidate must be medically fit.</li>
                        <li><strong>4.</strong> Only orphans can apply.</li>
                      </ol>
                    </div>
                  </div>
            </section>
          </div>
        </div>
        <div class="card"  >
          <div class="card-head" >
            <h3>Who cannot apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li><strong>1.</strong>  Over-age candidates. </li>
                        <li><strong>2.</strong> Medically unfit candidates.</li>
                        <li><strong>3.</strong> Candidates with tampered certificates and documents.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>
        <div class="card"  >
          <div class="card-head" >
            <h3>What is the admission procedure?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li><strong>Step 1: </strong> Each candidate can apply by sending an application. After an application is received roll no. slip is issued. </li>
                        <li><strong>Step 2:</strong>  The candidate then takes a written test in the near by test center.</li>
                        <li><strong>Step 3:</strong>  Candidates who passed the written exam are required to take a medical test and interview. </li>
                        <li>The candidate who pass are admitted to the cadet college they stay in college for two years and we take care of everything for them for free.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card"  >
          <div class="card-head" >
            <h3>How to apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>Candidates can apply by submitting an application online.</li>
                        <li>Instructions for submitting application</li>
                        <li><strong>Step 1:</strong> Create an account on our website. </li>
                        <li><strong>Step 2:</strong> Fill out the online application form. </li>
                        <li><strong>Step 3:</strong> Submit the application form after verifying your information. </li>
                        <li><strong>Note:</strong> After submitting your form, you will receive an email, and the roll number slip will be enabled in your online account on our website. You have to download it, take a print, and bring it to your exam center. The information provided in the online application form must be correct. False information may result in disciplinary action or withdrawal with payment of training charges. The last date for submitting the online application form is advertised through the press and website.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card"  >
          <div class="card-head" >
            <h3>Documents Required For Application:</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>Candidates are required to provide the following documents:</li>
                        <li><strong>1.</strong> B form issued by NADRA. </li>
                        <li><strong>2.</strong> Father death issued by NADRA. </li>
                        <li><strong>3.</strong> Mark sheet of class 9th.</li>
                        <li><strong>4.</strong> Candidate photo.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card"  >
          <div class="card-head" >
            <h3>Selection Procedure</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>The selection is based on the candidates performance/grading in the following:</li>
                        <li><strong>1.</strong> Written test(MCQs and subjective) from class 9th and 10th.</li>
                        <li><strong>2.</strong> Interview.</li>
                        <li><strong>3.</strong> Physical and medical examination.</li>
                        <li> <strong>Test Centers</strong> </li>
                        <li>
                          <table class="testCenters">
                            <tbody>
                              <tr>
                                <td>Abbotabad</td>
                                <td>Bahawalpur</td>
                                <td>Deraghazi Khan</td>
                              </tr>
                              <tr>
                                <td>Faisalabad</td>
                                <td>Gujranwala</td>
                                <td>Gilgit</td>                              
                              </tr>
                              <tr>
                                <td>Hyderabad</td>
                                <td>Jhelum</td>
                                <td>Kalat</td>
                              </tr>
                              <tr>
                                <td>Karachi</td>
                                <td>Kohat</td>
                                <td>Lahore</td>                                                            
                              </tr>
                              <tr>
                                <td>Mardan</td>
                                <td>Muzaffarabad</td>
                                <td>Multan</td>
                              </tr>
                              <tr>
                                <td>Mirpur</td>
                                <td>Okara</td>
                                <td>Pano Aqil</td>
                              </tr>
                              <tr>                              
                                <td>Peshawar</td>
                                <td>Quetta</td>
                                <td>Rawlakot</td>
                              </tr>
                              <tr>
                                <td>Rawalpindi</td>
                                <td>Skardu</td>
                                <td>Sargodha</td>
                              </tr>
                              <tr>
                                <td>Sialkot</td>
                                <td>Sukkar</td>
                              </tr>
                            </tbody>
                          </table>
                        </li>                        
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card"  >
          <div class="card-head" >
            <h3>Final Selection</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>The final selection is based on the overall merit achieved by the candidate in the written test, interview, intelligence test, and medical fitness. Mere passing the test does not entitle a candidate to admission.</li>
                        <li>Please note that the results are confidential Answer scripts, marks obtained, merit or position obtained will not be shown to parents / guardians. Any correspondence in this regard will not be entertained.</li>
                        <li><strong>Note:</strong> The test center may change if needed and we will inform you via text and email in such event.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        
      </div>
    </div>
  </section>
  <section class="promise_section" id="scroll-section8" data-aos="fade-up" >
    <div class="container">
      <a [ngClass]="nextPage ? 'yes' : 'disabled'" class="apply_btn" routerLink="/admission/signup"
        href="javascript:;">Apply Now</a>
    </div>
  </section>
  