<div class="stepWrapper">
  <form #contactForm="ngForm" class="contactDetailsForm" (submit)="saveContactDetails($event)" autocomplete="off">
    <div class="doubleInput">
      <div class="inputWrapper">
        <label for="name">Name</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" id="name" type="text" name="name" [(ngModel)]="sponsorDetails.name" placeholder="Enter Valid Full Name" required>
        <div *ngIf="loading" class="inputSkeleton"></div>
      </div>
      <div class="inputWrapper">
        <label for="email">Email</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" id="email" type="text" name="email" [(ngModel)]="sponsorDetails.email" required
          disabled>
        <div *ngIf="loading" class="inputSkeleton"></div>
        <span class="lockIcon">
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_5227_4116)">
              <path
                d="M11.7188 5.625H11.25V3.75C11.25 1.68182 9.56818 0 7.5 0C5.43182 0 3.75 1.68182 3.75 3.75V5.625H3.28125C2.50626 5.625 1.875 6.25557 1.875 7.03125V13.5938C1.875 14.3694 2.50626 15 3.28125 15H11.7188C12.4937 15 13.125 14.3694 13.125 13.5938V7.03125C13.125 6.25557 12.4937 5.625 11.7188 5.625ZM5.00004 3.75C5.00004 2.37122 6.12122 1.25004 7.5 1.25004C8.87878 1.25004 9.99996 2.37122 9.99996 3.75V5.625H5.00004V3.75Z"
                fill="#221F1F" />
            </g>
            <defs>
              <clipPath id="clip0_5227_4116">
                <rect width="15" height="15" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </span>
      </div>
    </div>
    <div class="doubleInput">
      <div class="inputWrapper">
        <label for="phoneNumber">PHONE NUMBER</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" mask="(000) 00000999" class="phoneInput" id="phoneNumber" type="text" name="phoneNumber"
          placeholder="(3xx) xxxxxxx" [(ngModel)]="sponsorDetails.phone.number"
          (keypress)="isNumberValidate($event, 'phone')" required>
        <div *ngIf="loading" class="inputSkeleton"></div>
        <span class="countryCode" *ngIf="!loading">+{{ sponsorDetails.phone.code }}</span>
        <div class="flagsWrapper" *ngIf="!loading">
          <div class="coutryFlags">
            <div (click)=" setPhoneValues('phone', country, flagOptions[i] ) "
              *ngFor="let country of countryOptions;index as i" class="singleFlag">
              <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flagOptions[i] + '.png' " alt="country">
              <p>{{country}}</p>
            </div>
          </div>
          <div class="selectedFlag">
            <img [src]="'https://img.mobiscroll.com/demos/flags/' + sponsorDetails.phone.country + '.png'" alt="">
            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z" fill="#5E5E5E" />
            </svg>
          </div>
        </div>
      </div>
      <div class="inputWrapper">
        <label for="whatsappNumber">WHATSAPP</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" mask="(000) 00000999" class="phoneInput" id="whatsappNumber" type="text"
          name="whatsappNumber" placeholder="(3xx) xxxxxxx" [(ngModel)]="sponsorDetails.whatsapp.number"
          (keypress)="isNumberValidate($event, 'whatsapp')" required>
        <div *ngIf="loading" class="inputSkeleton"></div>
        <span class="countryCode" *ngIf="!loading">+{{ sponsorDetails.whatsapp.code }}</span>
        <div class="flagsWrapper" *ngIf="!loading">
          <div class="coutryFlags">
            <div (click)=" setPhoneValues('whatsapp', country, flagOptions[i] ) "
              *ngFor="let country of countryOptions;index as i" class="singleFlag">
              <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flagOptions[i] + '.png' " alt="country">
              <p>{{country}}</p>
            </div>
          </div>
          <div class="selectedFlag">
            <img [src]="'https://img.mobiscroll.com/demos/flags/' + sponsorDetails.whatsapp.country + '.png'" alt="">
            <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z" fill="#5E5E5E" />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div class="doubleInput">
      <div class="inputWrapper adressWrapper">
        <label for="address">ADDRESS</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" type="text" id="address" name="address" placeholder="Enter valid address"
          [(ngModel)]="sponsorDetails.address" required>
        <div *ngIf="loading" class="inputSkeleton"></div>
      </div>
    </div>
    <div class="doubleInput">
      <div class="inputWrapper">
        <label for="city">City</label>
        <input *ngIf="!loading" (keyup)="removeError($event.target)" type="text" id="city" name="city" placeholder="Enter valid City" [(ngModel)]="sponsorDetails.city"
          required>
        <div *ngIf="loading" class="inputSkeleton"></div>
      </div>
      <div class="inputWrapper ">
        <label for="city">COUNTRY</label>
        <ng-select *ngIf="!loading" id="country" name="country" class="selectTrio contactCountry"
          [items]="countryOptions" [(ngModel)]="sponsorDetails.country" placeholder="Select Country"
          [searchable]="false" [clearable]="false">
        </ng-select>
        <div *ngIf="loading" class="inputSkeleton"></div>
      </div>
    </div>
    <div class="actionBtnsWrapper">
      <button class="prevBtn" (click)="changeStep('your-sponsorships')">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_5250_4681)">
          <path d="M20.0011 10.8327V9.16599L2.1303 9.19182L5.73197 5.58849L4.55363 4.41016L0.731966 8.23182C0.263288 8.70064 0 9.33641 0 9.99932C0 10.6622 0.263288 11.298 0.731966 11.7668L4.55363 15.5885L5.73197 14.4102L2.1803 10.8585L20.0011 10.8327Z" fill="#221F1F"/>
          </g>
          <defs>
          <clipPath id="clip0_5250_4681">
          <rect width="20" height="20" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        <span>Previous step</span>
      </button>
      <!-- <button class="nextSteBtn" type="submit" *ngIf="!disabledBtn" >
        proceed to Payment
      </button> -->
      <div class="showMoreBtnWrapper" *ngIf="!disabledBtn && !loading">
        <button class="showMoreBtn" type="submit" >proceed to Payment</button>
        <span></span>
      </div>
      <div class="showMoreBtnWrapper" *ngIf="disabledBtn">
        <button class="showMoreBtn loaderBlue" type="button" ><img class="loadingImg" src="/assets/new-images/pshLoaderWhite.webp" alt="loading"></button>        
      </div>
      <!-- <div *ngIf="disabledBtn" class="loader">
        <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
      </div> -->
    </div>
  </form>
</div>
