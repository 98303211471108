import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HelperService, AppSettings, AuthenticationService } from 'src/app/services/_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-regular-classes-review',
  templateUrl: './regular-classes-review.component.html',
  styleUrls: ['../regular-classes-form/regular-classes-form.component.css', './regular-classes-review.component.css']
})
export class RegularClassesReviewComponent implements OnInit {

  public loading = true;

  public applicationId;
  
  public authSub;
  public currentUser;

  public applicationInfo;
  public thanksPopupShow = false;

  constructor(
    private route                  : ActivatedRoute,
    private helperService          : HelperService,
    private authenticationService  : AuthenticationService,
    private router                 : Router
  ) { 
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.currentUser = user;       
            console.log('this.currentUser::', this.currentUser);
        } else {
          this.currentUser = null;          
        }
      });
    }
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.applicationId = id;
      this.getApplication()
    });
  }



  ngOnInit(): void {
  }

  getApplication(){

    let url = AppSettings.BACK_ENDPOINT + 'regular_admission/' + this.applicationId;

    this.helperService.httpGetRequests(url).then(resp => {
      if(resp.status == 200){
        this.applicationInfo = resp.admission;
        this.loading = false;
      }
    }).catch(error => {
      console.log('error::', error)
      this.loading = false;

    });
  }

  submitApplication(){
    let url = AppSettings.BACK_ENDPOINT + 'regular_admission/' + this.applicationId;

    let data = new FormData();
    data.append('is_completed' , '1');

    this.helperService.httpPostRequests(url, data).then(resp => {
      this.thanksPopupShow = true;
    }).catch(error => {
      console.log('error::', error)
      this.loading = false;
    });
  }

  editApplication(){
    this.router.navigate(['/boarding-school/apply/' + this.applicationId])
  }

}
