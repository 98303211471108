import {
  Component,
  OnInit,
  ChangeDetectorRef,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import {
  AppSettings,
  AuthenticationService,
  HelperService,
  CurrencyApiService,
  COUNTRYLIST,
} from 'src/app/services/_services';
import { Router } from '@angular/router';
import { TtqTrackingService } from 'src/app/services/_services/ttq-tracking.service';
declare var $, Stripe;

@Component({
  selector: 'app-payment-form',
  templateUrl: './payment-form.component.html',
  styleUrls: ['./payment-form.component.css'],
})
export class PaymentFormComponent implements OnInit {
  resolved(captchaResponse: string) {
    this.captchaResponse = captchaResponse;
    if (captchaResponse) {
      this.captchaChecked = true;
    }
  }

  public activePage: any = 'home';

  // public DonateForm : any = 'addressForm';
  public DonateForm: any = 'form';
  public donation: any = null;
  public btnTransparency: any = true;
  public countryOptions: any;
  public flagOptions: any;
  public selectedCountry: any = 'Pakistan';
  public selectedFlag: any = 'PK';
  public selectedCurrency: any = 'PKR';
  public selectedCurrencySymbol: any = 'RS';
  public currencyRates: any = '';
  public otherAmount: any = null;

  public currentUser: any;
  public authSub: any;
  public topText: any = "Change a Child's Life Today!";
  public startText: any = "Change a Child's Life Today!";

  public referralID: any;

  public user_donation: any = {
    amount: null,
    monthlyPay: false,
  };

  public signUp: any = {
    firstName: '',
    lastName: '',
    email: '',
    phone_number: '',
    // "saveCard"          : true,
    address: '',
    city: '',
    zipCode: '',
    country: '',
    purpose: 'Muharram',
  };

  public stripe: any;
  public stripeCard: any;
  public stripePayment: any;
  public stripeError: any;
  public customerResp: any;
  public submitted: any = false;
  public loading: any = false;

  public numberValid: any = false;
  public expiryValid: any = false;

  public inputHidden: any = true;

  public purposeOptions: any = ['Qurbani'];
  public programOptions: any = [
    'PSH Girls Campus - H9',
    'PSH Boys Campus - H9',
    'PSH Cadet College',
    'Other',
  ];

  public coutryCode: any;
  public phone_number: any;

  public isLocalServer: any = false;
  public captchaChecked: any = true;
  public captchaResponse: any = '';

  public leadId: any;

  @ViewChild('checkkkkk') myInputField: ElementRef;
  @ViewChild('firstNameIn') firstNameIn;
  @ViewChild('adressIn') adressIn;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private helperService: HelperService,
    private router: Router,
    private currencyApiService: CurrencyApiService,
    private ttqTrackingService: TtqTrackingService
  ) {
    if (
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('192.168.19.133')
    ) {
      this.isLocalServer = true;
    }

    if (typeof localStorage != 'undefined') {
      this.authSub = this.authenticationService.currentUserS$.subscribe(
        (user) => {
          if (user) {
            this.currentUser = user;
            this.signUp.firstName =
              this.currentUser.firstName && this.currentUser.firstName != 'null'
                ? this.currentUser.firstName
                : '';
            this.signUp.lastName =
              this.currentUser.lastName && this.currentUser.lastName != 'null'
                ? this.currentUser.lastName
                : '';
            this.signUp.email = this.currentUser.email
              ? this.currentUser.email
              : '';
            // this.phone_number = this.currentUser.phone_number ? this.currentUser.phone_number : '';
          } else {
            this.currentUser = null;
          }
        }
      );

      // Get Rates First
      if (localStorage.getItem('currencyRates')) {
        this.currencyRates = JSON.parse(localStorage.getItem('currencyRates'));
        this.getGeoLocs();
      } else {
        this.currencyApiService.currencyRatesS$.subscribe((rates) => {
          this.currencyRates = rates;
          localStorage.setItem(
            'currencyRates',
            JSON.stringify(this.currencyRates)
          );
          this.getGeoLocs();
        });
      }
    }

    let url = activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
    this.getRefferal(url);
  }

  ngOnInit(): void {
    document
      .getElementById('phone_number')
      .addEventListener('paste', function (event) {
        event.preventDefault();
      });

    this.http
      .get('https://trial.mobiscroll.com/content/countries.json')
      .subscribe((resp: any) => {
        const countries = [];
        const countriesImg = [];
        for (let i = 0; i < resp.length; ++i) {
          const country = resp[i];
          countries.push(country.text);
          countriesImg.push(country.value);
        }
        this.countryOptions = countries;
        this.countryOptions.push('Canada');
        // this.countryOptions = resp;
        this.flagOptions = countriesImg;
      });

    if ($('input#firstName').hasClass('in-valid')) {
      $('.eror_msg').css('display', 'block');
    }

    $(document).ready(function () {
      if ($(window).width() > 767) {
        $('#other_amount_input').focus();
      }
    });
  }

  getGeoLocs() {
    const url =
      'https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip';
    this.helperService
      .httpGetRequests(url)
      .then((resp) => {
        console.log('geoIP', resp);

        if (this.currencyRates[resp.geoplugin_currencyCode]) {
          console.log('hereeeee');
          console.log(
            'geoIP222',
            this.currencyRates[resp.geoplugin_currencyCode]
          );

          this.selectedFlag = resp.geoplugin_countryCode;
          this.signUp.country = resp.geoplugin_countryName;
          this.selectedCountry = resp.geoplugin_countryName;
          // setTimeout(() => {
          //   this.selectedCurrency       = resp.geoplugin_currencyCode;
          //   this.selectedCurrencySymbol = resp.geoplugin_currencySymbol == '₨' ? 'Rs' : resp.geoplugin_currencySymbol;
          // }, 1000);

          this.otherAmount = 0;
          this.convertOtherAmount();
          console.log('this.selectedCurrency::', this.selectedCurrency);
          console.log(
            'this.selectedCurrencySymbol::',
            this.selectedCurrencySymbol
          );

          this.setCountryCode();
          // this.setPhoneCode();
        } else {
          this.setDefualtCurrency();
        }
      })
      .catch((err) => {
        this.setDefualtCurrency();
      });
  }

  setDefualtCurrency() {
    this.selectedFlag = 'PK';
    this.signUp.country = 'Pakistan';
    this.selectedCountry = 'Pakistan';
    this.signUp.city = '';
    this.selectedCurrency = 'PKR';
    this.selectedCurrencySymbol = 'RS';
  }

  convertCurrency(amount) {
    if (this.currencyRates) {
      return amount * this.currencyRates[this.selectedCurrency];
    } else {
      return amount;
    }
  }

  setDonation(amount) {
    this.donation = amount;
    this.user_donation.amount = null;
    this.otherAmount = null;
  }

  convertOtherAmount() {
    this.user_donation.amount = Math.floor(
      this.otherAmount / this.currencyRates[this.selectedCurrency]
    );
    this.donation = null;
  }

  getRefferal(url) {
    const urlArr = url.split('/');
    if (urlArr.length == 3 && this.currentUser.ID != urlArr[2]) {
      this.referralID = urlArr[2];
    }
  }

  validateEmail() {
    if (
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.signUp.email)
    ) {
      return true;
    } else {
      // this.emailError = "Enter valid Email!";
      return false;
    }
  }

  addDonationLead() {
    if (this.captchaChecked) {
      this.submitted = true;

      if (
        !this.signUp.firstName ||
        !this.signUp.lastName ||
        !this.signUp.email.includes('@') ||
        !this.signUp.email.includes('.') ||
        !this.phone_number
      )
        // return false;

        this.stripeError = '';
      let self = this;

      // Show loader

      if (
        !self.signUp.firstName ||
        !self.signUp.lastName ||
        !self.signUp.email.includes('@') ||
        !self.signUp.email.includes('.') ||
        !self.phone_number ||
        self.phone_number.toString().length < 8
      ) {
      } else {
        if (!this.customerResp) {
          this.loading = true;
          self.setPhoneCode();
          self.createLead();
        } else {
          this.loading = false;
          this.DonateForm = 'addressForm';
          this.countryOptions.sort();
          setTimeout(() => {
            this.adressIn.nativeElement.focus();
          }, 500);
        }
      }
    }
  }

  routeCheck(url) {
    if (url.includes('/donate')) {
      this.activePage = 'donate';
      this.topText = 'Donation for Qurbani (Goat/Sheep)';
      this.startText = this.topText;
      this.otherAmount = null;
    } else if (url.includes('/give-charity-in-rabi-awwal-2024')) {
      this.activePage = 'donate';
      this.topText = 'Enter an amount to donate';
      this.startText = this.topText;
      this.otherAmount = null;
    } else {
      this.activePage = 'donate';
      this.topText = 'Donation for Qurbani (Goat/Sheep)';
      this.startText = this.topText;
      this.otherAmount = null;
    }
  }

  createLead() {
    this.submitted = true;
    this.convertOtherAmount();
    let url = AppSettings.BACK_ENDPOINT + 'create-donation-lead';
    let data = new FormData();

    for (let key in this.signUp) {
      if (key != 'purpose') data.append(key, this.signUp[key]);
    }
    data.append('amount', this.user_donation.amount);
    data.append('monthlyPay', this.user_donation.monthlyPay);

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        if (parseInt(resp.status) != 500) {
          this.customerResp = resp.lead;

          // Move to next step
          this.submitted = false;
          this.DonateForm = 'addressForm';
          this.countryOptions.sort();

          setTimeout(() => {
            this.adressIn.nativeElement.focus();
          }, 500);
        }
        // hide loader
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error('error: ', error);
      });
  }

  createCustomer() {
    this.submitted = true;

    // Show loader
    this.loading = true;

    // this.user_donation.amount = this.otherAmount ? this.otherAmount : this.user_donation.amount;

    this.convertOtherAmount();

    let url = AppSettings.API_ENDPOINT + 'stripe_actions/api.php';

    let data = new FormData();
    data.append('action', 'createCustomer');

    for (let key in this.signUp) {
      data.append(key, this.signUp[key]);
    }
    data.append('amount', this.user_donation.amount);
    data.append('monthlyPay', this.user_donation.monthlyPay);
    data.append('payment_method', this.stripePayment.id);
    data.append('g-recaptcha-response', this.captchaResponse);
    data.append('env', this.isLocalServer ? 'test' : 'live');

    // data.append("currency",       this.selectedCurrency);

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    });

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        if (parseInt(resp.status) != 500) {
          this.customerResp = resp;

          // Move to next step
          this.submitted = false;
          this.DonateForm = 'addressForm';
          this.countryOptions.sort();

          this.signUp.phone_number = '';

          setTimeout(() => {
            this.adressIn.nativeElement.focus();
          }, 500);

          this.stripeError = '';
        } else {
          this.stripeError = resp.message;
        }

        // hide loader
        this.loading = false;
      })
      .catch((error) => {
        // hide loader
        this.loading = false;

        console.error('error: ', error);
        // this.submitted2 = false;
        // this.formResp = false;
      });
  }

  donate() {
    // Show loader
    this.loading = true;

    let resp = this.customerResp;
    console.log('resp:: ', resp);

    if (resp.intent) {
      let self = this;
      self.stripe
        .confirmCardPayment(resp.intent.client_secret, {
          payment_method: {
            card: self.stripeCard,
          },
        })
        .then(function (result) {
          // hide loader
          self.loading = false;

          if (result.error) {
            // Show error to your customer
            console.log('here in error!!!!');

            self.stripeError = result.error.message;
            self.updatPaymentError(
              resp.user['ID'],
              result.error.payment_intent.id
            );
            return;
          } else {
            // The payment succeeded!
            self.updatPaymentSuccess(
              resp.user['ID'],
              result.paymentIntent.id,
              null
            );
          }
        });
    } else {
      // The subscription succeeded!
      if (resp.price.active)
        this.updatPaymentSuccess(resp.user['ID'], null, resp.price.id);
    }
  }

  updatPaymentError(userId, stripe_transaction_id) {
    this.submitted = true;

    // Show loader
    this.loading = true;

    let url = AppSettings.API_ENDPOINT + 'stripe_actions/api.php';
    let data = new FormData();
    data.append('action', 'stripeSuccess');

    this.signUp.address = this.signUp.address;
    for (let key in this.signUp) {
      data.append(key, this.signUp[key]);
    }

    data.append('amount', this.user_donation.amount);
    data.append('monthlyPay', this.user_donation.monthlyPay);
    data.append('payment_method', this.stripePayment.id);
    data.append('g-recaptcha-response', this.captchaResponse);
    // data.append("currency", this.selectedCurrency);
    // data.append("donationId", donationId);
    data.append('referralID', this.referralID);
    data.append('userId', userId);

    data.append('stripe_transaction_id', stripe_transaction_id);
    data.append('env', this.isLocalServer ? 'test' : 'live');

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    });

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        localStorage.setItem('donation_amount', this.user_donation.amount);
        localStorage.setItem('user_email', this.signUp.email);
        localStorage.setItem('user_exists', this.currentUser ? '1' : '0');
        localStorage.setItem('first_name', this.signUp.firstName);
        localStorage.setItem('last_name', this.signUp.lastName);
        localStorage.setItem(
          'monthly_pay',
          this.user_donation.monthlyPay ? '1' : '0'
        );
      })
      .catch((error) => {
        console.error('error: ', error);
        // this.submitted2 = false;
        // this.formResp = false;
      });
    this.loading = false;
  }
  updatPaymentSuccess(userId, stripe_transaction_id, price_id) {
    this.submitted = true;

    // Show loader
    this.loading = true;

    let url = AppSettings.API_ENDPOINT + 'stripe_actions/api.php';
    let data = new FormData();
    data.append('action', 'stripeSuccess');

    this.signUp.address = this.signUp.address;
    for (let key in this.signUp) {
      data.append(key, this.signUp[key]);
    }
    data.append('amount', this.user_donation.amount);
    data.append('monthlyPay', this.user_donation.monthlyPay);
    data.append('payment_method', this.stripePayment.id);
    data.append('g-recaptcha-response', this.captchaResponse);
    data.append('userId', userId);
    // data.append("donationId", donationId);
    data.append('referralID', this.referralID);

    if (this.user_donation.monthlyPay) data.append('price_id', price_id);
    else data.append('stripe_transaction_id', stripe_transaction_id);

    data.append('env', this.isLocalServer ? 'test' : 'live');

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    });

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        localStorage.setItem('donation_amount', this.user_donation.amount);
        localStorage.setItem('user_email', this.signUp.email);
        localStorage.setItem('user_exists', this.currentUser ? '1' : '0');
        localStorage.setItem('first_name', this.signUp.firstName);
        localStorage.setItem('last_name', this.signUp.lastName);
        localStorage.setItem(
          'monthly_pay',
          this.user_donation.monthlyPay ? '1' : '0'
        );

        this.router.navigate(['/thanks']);
      })
      .catch((error) => {
        console.error('error: ', error);
        // this.submitted2 = false;
        // this.formResp = false;
      });
  }

  identifyUser(email, phone_number, id) {
    this.ttqTrackingService.identify(email, phone_number, id);
  }

  validateFormbill() {
    if (
      this.signUp.address &&
      this.signUp.city &&
      this.signUp.zipCode &&
      this.signUp.purpose
    ) {
      this.startCheckout();
    } else {
      if (!this.signUp.address) {
        $('#address').addClass('invalid');
        $('#billing_error').show();
      }
      if (!this.signUp.city) {
        $('#city').addClass('invalid');
      }
      if (!this.signUp.zipCode) {
        $('#zipCode').addClass('invalid');
        $('#zipecode_error').show();
      }
      if (!this.signUp.purpose) {
        $('#purposeSel').addClass('invalid');
        $('#purpose_error').show();
      }

      return false;
    }
  }

  startCheckout() {
    // Show loader
    this.loading = true;

    if (this.currentUser) {
      this.identifyUser(
        this.signUp.email,
        this.signUp.phone_number,
        this.currentUser.ID
      );
    } else {
      this.identifyUser(this.signUp.email, this.signUp.phone_number, null);
    }

    this.ttqTrackingService.track(
      'InitiateCheckout',
      this.user_donation.amount,
      'USD',
      '123',
      'Donate',
      'Donation',
      this.user_donation.amount
    );

    let url = AppSettings.BACK_ENDPOINT + 'donation-checkout';
    let data = new FormData();

    for (let key in this.signUp) {
      data.append(key, this.signUp[key]);
    }
    data.append('amount', this.user_donation.amount);
    data.append('monthlyPay', this.user_donation.monthlyPay);
    data.append('lead_id', this.customerResp.id);
    data.append('env', this.isLocalServer ? 'test' : 'live');
    data.append('success_url', window.location.origin + '/thanks');
    data.append('cancel_url', window.location.origin + '/donate');

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        if (parseInt(resp.status) != 500) {
          localStorage.setItem('donation_amount', this.user_donation.amount);
          localStorage.setItem('user_email', this.signUp.email);
          localStorage.setItem('user_exists', this.currentUser ? '1' : '0');
          localStorage.setItem('first_name', this.signUp.firstName);
          localStorage.setItem('last_name', this.signUp.lastName);
          localStorage.setItem(
            'monthly_pay',
            this.user_donation.monthlyPay ? '1' : '0'
          );
          window.location = resp.session.url;
        }
        // hide loader
        this.loading = false;
      })
      .catch((error) => {
        this.loading = false;
        console.error('error: ', error);
      });
  }

  removeInvalid(e) {
    $(e).removeClass('invalid');
    // $('#billing_error').hide();
    // $('#zipecode_error').hide();
    // $('#purpose_error').hide();
    $(e).next('p').hide();
  }

  removeError(j) {
    if ($(j).val()) {
      this.stripeError = '';
    }
  }

  falseInput() {
    this.inputHidden = false;
    this.myInputField.nativeElement.focus();
  }

  thirdStep() {
    this.DonateForm = 'cardForm';
    setTimeout(() => {
      this.firstNameIn.nativeElement.focus();
    }, 500);
  }

  secondStep() {
    if (this.captchaChecked) {
      this.stripeError = '';
      if (!this.donation && this.user_donation.amount < 5) {
        let am = 5 * this.currencyRates[this.selectedCurrency];
        this.stripeError =
          'THE MINIMUM DONATION IS ' +
          this.selectedCurrencySymbol +
          ' ' +
          Math.ceil(am).toFixed(0);
        return;
      }

      this.DonateForm = this.user_donation.monthlyPay ? 'cardForm' : 'cardForm';
      this.DonateForm == 'cardForm' ? $('#firstName').focus() : null;
      this.user_donation.amount
        ? this.user_donation.amount
        : (this.user_donation.amount = this.donation);

      this.topText = 'Giving ' + this.selectedCurrencySymbol + ' ';
      if (this.otherAmount) this.topText += this.addComma(this.otherAmount);
      else
        this.topText += this.addComma(
          this.convertCurrency(this.donation).toFixed(0)
        );

      this.topText += this.user_donation.monthlyPay ? ' per month' : '';
    }
  }

  giveOnce() {
    this.user_donation.monthlyPay = false;
    this.topText = this.topText.includes(' per month')
      ? this.topText.replace(' per month', '')
      : this.topText;
  }
  giveMonthly() {
    this.user_donation.monthlyPay = true;
    if (this.DonateForm != 'form') {
      this.topText = this.topText.includes(' per month')
        ? this.topText
        : this.topText + ' per month';
    }
  }
  giveToggle() {
    if (this.user_donation.monthlyPay) {
      this.giveOnce();
    } else {
      this.giveMonthly();
    }
  }

  addComma(value) {
    let stringVal = value.toString();

    let toReturn = '';
    let mode = 3;

    let j = 1;
    for (let i = stringVal.length - 1; i >= 0; i--) {
      const element = stringVal[i];
      if (j % mode == 0 && stringVal.length > mode) {
        toReturn = ',' + element + toReturn;
        mode += 2;
      } else {
        toReturn = element + toReturn;
      }
      j++;
    }

    return toReturn;
  }

  checkPhoneNumber() {
    if (this.phone_number.length == 1 && this.phone_number == '0') {
      this.phone_number = '';
    }
  }

  isNumberValidate(e) {
    var charCode = e.which ? e.which : e.keyCode;
    if (!this.phone_number && charCode == 48) {
      return false;
    }
  }

  setCountryCode() {
    if (
      COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] &&
      !this.coutryCode
    ) {
      var temp = COUNTRYLIST.COUNTRY_LIST;
      this.coutryCode =
        '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0];
    }
  }

  setPhoneCode() {
    this.phone_number = this.removeFirstZero(this.phone_number);

    if (COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0]) {
      var temp = COUNTRYLIST.COUNTRY_LIST;
      if (this.phone_number && !this.phone_number.includes('+')) {
        this.signUp.phone_number =
          '+' +
          COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] +
          ' ' +
          this.phone_number;
      } else if (!this.phone_number.includes('+')) {
        this.signUp.phone_number =
          '+' +
          COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] +
          ' ';
      } else {
        this.phone_number = this.phone_number.split(' ')[1];
        this.signUp.phone_number =
          '+' +
          COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] +
          ' ' +
          this.phone_number;
      }

      console.log('this.signUp.phone_number::', this.signUp.phone_number);
    }
  }

  removeFirstZero(str) {
    let modifiedString = str;

    if (str.charAt(0) == '0') {
      modifiedString = str.slice(1);
    }

    return modifiedString;
  }
}
