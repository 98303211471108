import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, HelperService } from 'src/app/services/_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-student-application',
  templateUrl: './student-application.component.html',
  styleUrls: ['./student-application.component.css']
})
export class StudentApplicationComponent implements OnInit {

  public currentUser: any;
  public authSub: any;
  public loading: any = true;
  showChangePasswordFields = false;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  password: string;
  public applications : any;
  public otherApplications : any = [];
  public maxEditDate : any;

  constructor(
    private authenticationService: AuthenticationService,
    private router                 : Router,
    private helperService          : HelperService,
  ) {


    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.currentUser = user;
            this.getAdmissions();
            this.getOtherAdmissions();
        } else {
          this.currentUser = null;
          this.router.navigate(['/admission/login']);
        }
      });
    }
   }

  ngOnInit(): void {
  }

  getOtherAdmissions(){
    let data = new FormData();

    let url  = AppSettings.BACK_ENDPOINT + 'get_scholor_regular_admissions/' + this.currentUser.ID;

    this.helperService.httpGetRequests(url).then(resp => {
      this.otherApplications = resp.admissions;
    }).catch(error => {
        console.error("error: ",error);
    });
  }

  getAdmissions(){      

      let data = new FormData();
      data.append("action", "get-user-admissions");
      data.append("user_id", this.currentUser.ID);
      
      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if (resp.status != 'ERROR') {
          // Set User in localStorage         
          this.applications = resp.data.reverse();          

        } else {
          
        }
        this.loading = false;
  
      }).catch(error => {
          console.error("error: ",error);
      });
  }

  setAdmissionForm(item){
    localStorage.setItem('application_draft',JSON.stringify(item));
    this.router.navigate(['/admission/fill-form']);
  }

  showComment(comment){
    Swal.fire('Sorry!', comment, 'info')
  }
  showAcceptComment(comment){
    Swal.fire('Congratulations!', comment, 'info')
  }

  checkEditable(appDate){
    this.maxEditDate = new Date('2023-03-09');

    let subDate = new Date(appDate)

    if(subDate > this.maxEditDate){
      return true;
    } else {
      return false;
    }
    

  }
  isDisable(status){
    if(status != '0'){
      return true;
    } else {
      return false;
    }
  }

  getAppClass(status){
    switch (status) {
      case '0':
        return ''
        break;
      case '2':
        return 'Rejected'
        break;
      
      default:
        return 'Accepted'
        break;
    }
  }
  
  getAppStatus(status){
    switch (status) {
      case '0':
        return 'Pending'
        break;
      case '1':
        return 'Accepted'
        break;
      case '2':
        return 'Rejected'
        break;
      case '3':
        return 'Test Center Assigned'
        break;
      
      default:
        return 'Roll Number Slip Issued'
        break;
    }
  }

}
