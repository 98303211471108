import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings,AuthenticationService, FacebookLoginService, HelperService, SEOService } from 'src/app/services/_services';
declare var $;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css','../add-sponsor/modals.css']
})
export class SignUpComponent implements OnInit {

  @Input() modalView;
  @Input() modalStep;
  @Input() signupEmail;

  @Output() stepChangeEvent = new EventEmitter();
  @Output() emailVerifyEvent = new EventEmitter();
  @Output() modalShowEvent = new EventEmitter();

  public signUp                     : any = {
                                               "firstName"         : '',
                                               "lastName"          : '',
                                               "email"             : '',
                                               "password"          : '',
                                               "adult"             : true,
                                            }

  public showPass                   : any = false;    
  public errorMsg                   : any                                            
  public loading                    : any = false;
  public routerSubscription         : any = false;

  public rerouteUrl                 : any = '/dashboard';
  public siUrl                      : any = '/sign-in';

  public OTPPopup                   : any = false;
  public OTP                        : any = '';
  public OTPerror                   : any;
  public OTPLoading                 : any = false;
  public OTPTimer                   : any;

  constructor(
    private router                 : Router,
    private authenticationService  : AuthenticationService,
    private helperService          : HelperService,
    private facebookLoginService   : FacebookLoginService,
    private seoService: SEOService,

  ) { 
    this.routeCheck();
  }

  ngOnInit(): void {

    if(localStorage.getItem('currentUser')){
      this.router.navigate(['/dashboard']);
    }

    $('input').keyup(function () {
      this.removeError($(this));
    })
    this.setSEO_Tags("Sign Up for Admission to Pakistan Sweet Home","Sign up for admission to Pakistan Sweet Home, where orphans receive love, care, and education to build a brighter future.")
  }
setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  routeCheck() {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {          
        let firstUrl = val.url.split('sign-up/')[1];     
        if(firstUrl && firstUrl == 'applicant'){
          this.rerouteUrl = '/admission/student-application'
          this.siUrl      = '/admission/login';
        } else {
          this.rerouteUrl = '/dashboard';
          this.siUrl      = '/sign-in';
        }
             
      }
    })
  }

  removeError(e){
    if($(e).val()){
      $(e).removeClass('invalid');
    }
  }
  restrictOtpLength(event: any) {
    const input = event.target;
    if (input.value.length > 4) {
      input.value = input.value.slice(0, 4);
    }
    this.OTP = input.value; // Keep the ngModel updated
  }
  newUser(){

    if(this.validateForm()){
      this.loading = true;
      let data = new FormData();
      data.append("action",        "signup_stage_1");
      data.append("sendVerificationCode",        "1");
      data.append("firstName",          this.signUp.firstName);
      data.append("lastName",          this.signUp.lastName);
      data.append("email",         this.signUp.email);
      data.append("password", this.signUp.password);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          // Set User in localStorage
          // this.authenticationService.setCurrentUserValue(resp.data);
          // this.router.navigate([this.rerouteUrl]);

          if(this.modalView){
            this.passEmail();
            this.changeParentStep('verify-otp');
          } else {
            this.showOTPModal();
          }

        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;
        
      }).catch(error => {
          console.error("error: ");  
      });


    } else {
      console.log('invalid');
    }

  }

  validateForm(){
    if(this.validateEmail() && this.signUp.firstName && this.signUp.lastName && (this.signUp.password.length > 4) && this.signUp.adult ){
      return true;       
    } else {
      if(!this.signUp.firstName){
        $('#firstName').addClass('invalid');
      }
      if(!this.signUp.lastName){
        $('#lastName').addClass('invalid');
      }
      if(this.signUp.password.length < 5){
        
        $('#password').addClass('invalid');
      }
      if(!this.signUp.adult){
        $('#adultCheck').addClass('invalid');
      }
      return false;
    }
  }

  validateEmail(){
    if (this.signUp.email.includes('.') && this.signUp.email.includes('@'))
    {
      return true;
    } else {
      $('#email').addClass('invalid');
      return false;
    }
  }

  signInWithFB(): void {
    this.facebookLoginService.login().then(user => {
      
      console.log('user::', user);
      
      this.sendFbUser(user);
      
    });
  }

  sendFbUser(user){
    let data = new FormData();
    data.append("action", "social_signup");

    for (let key in user) {
      data.append(key, user[key]);
    }

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';
    
    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){
        // Set User in localStorage
        this.authenticationService.setCurrentUserValue(resp.data);
        this.router.navigate([this.rerouteUrl]);
      } else {
        this.errorMsg = resp.message;
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000)
      }
      
    }).catch(error => {
        console.error("error: ", error);  
    });

  }

  showOTPModal(){
    this.OTPPopup = true;
    this.fixBody();
    this.OTPTimer = 180;

    setInterval(() => {
      if(this.OTPTimer){
        this.OTPTimer--;
      } else {
        return;
      }
    }, 1000);

  }
  hideOTPModal(){
    this.OTPPopup = false;
    this.unfixBody();
    this.OTPTimer = null;
  }

  fixBody(){
    $('body').css('height','100vh');
  }
  unfixBody(){
    $('body').css('height','unset');
  }

  verifyCode(){

    if(this.OTP.toString().length != 4){
      console.log('asdjhasjkdhasd::',this.OTP.toString().length);

      this.OTPerror = 'Please Enter Valid Code!';
          setTimeout(() => {
            this.OTPerror = '';
          }, 3000)
    } else {

      if(this.signupEmail && !this.signUp.email){
        this.signUp.email = this.signupEmail;
      }

      this.OTPLoading = true;

      let data = new FormData();
      data.append("action",        "account_verification");
      data.append("email",        this.signUp.email);
      data.append("otp",        this.OTP);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
          if(!this.modalView){
            this.router.navigate([this.rerouteUrl]);
          } else {
            this.modalShowEvent.emit('contact-details');
          }
        } else {
          this.OTPerror = resp.message;
          setTimeout(() => {
            this.OTPerror = '';
          }, 3000)
        }

        this.OTPLoading = false;

      }).catch(error => {
          console.error("error: ");
          this.OTPLoading = false;
      });

    }
  }

  changeParentStep(stepName) {
    this.stepChangeEvent.emit(stepName);
  }

  passEmail(){
    this.emailVerifyEvent.emit(this.signUp.email);
  }
  sendOTP() {
    this.OTPLoading = true;
    let data = new FormData();
    data.append("action",        "signup_stage_1");
    data.append("sendVerificationCode",        "1");
    data.append("firstName",          this.signUp.firstName);
    data.append("lastName",          this.signUp.lastName);
    data.append("email",         this.signUp.email);
    data.append("password", this.signUp.password);

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){
        // Set User in localStorage
        // this.authenticationService.setCurrentUserValue(resp.data);
        // this.router.navigate([this.rerouteUrl]);

        if(this.modalView){
          this.passEmail();
          this.changeParentStep('verify-otp');
        } else {
          this.showOTPModal();
        }

      } else {
        this.errorMsg = resp.message;
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000)
      }

      this.OTPLoading= false;
      
    }).catch(error => {
        console.error("error: ");  
    });

  }

}
