import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, HelperService, FacebookLoginService } from 'src/app/services/_services';
declare var $;

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.component.html',
  styleUrls: ['./thanks.component.css']
})
export class ThanksComponent implements OnInit {

  public amount : any;
  public email  : any;
  public password  : any;
  public signedup : any = false;
  public controls : any = false;
  public over18 : any = true;
  public firstName : any;
  public lastName : any;
  public loading : any = false;
  public errorMsg : any;
  public monthlyPay : any = false;
  public oldUser : any = false;
  public checkingUser : any = true;

  constructor(
    private router                 : Router,
    private authenticationService  : AuthenticationService,
    private helperService          : HelperService,
    private facebookLoginService   : FacebookLoginService,
  ) { }

  ngOnInit(): void {

    // this.facebookLoginService.fbLibrary();

    if(localStorage.getItem('donation_amount')){
      this.amount = localStorage.getItem('donation_amount');
      this.email = localStorage.getItem('user_email');
      this.firstName = localStorage.getItem('first_name');
      this.lastName = localStorage.getItem('last_name');

      let userString = localStorage.getItem('user_exists');
      let monthly = localStorage.getItem('monthly_pay');

      this.signedup = userString == '1' ? true : false;
      this.monthlyPay = monthly == '1' ? true : false;
      localStorage.removeItem('donation_amount');
      localStorage.removeItem('user_email');
      localStorage.removeItem('user_exists');
      localStorage.removeItem('first_name');
      localStorage.removeItem('last_name');
      localStorage.removeItem('monthly_pay');      
    }

    if(!this.signedup){
      this.isOldUser();
    }


  }

  isOldUser(){

    let data = new FormData();
      data.append("action",        "checkAccountExistance");
      data.append("email",         this.email);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status == 'ERROR'){
          this.oldUser = true;
        } else {
          this.oldUser = false;
        }

        this.checkingUser = false;

        console.log('this.oldUser::', this.oldUser);
      }).catch(error => {
        
      });

      
      

  }

  signInWithFB(): void {
    this.facebookLoginService.login().then(user => {
      
      console.log('user::', user);
      
      this.sendFbUser(user);
      
    });
  }

  sendFbUser(user){
    let data = new FormData();
    data.append("action", "social_signup");

    for (let key in user) {
      data.append(key, user[key]);
    }

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';
    
    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){
        // Set User in localStorage
        this.authenticationService.setCurrentUserValue(resp.data);
        this.router.navigate(['/dashboard']);
      } else {
        this.errorMsg = resp.message;
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000)
      }
      
    }).catch(error => {
        console.error("error: ", error);  
    });

  }

  registerAccount(){
    if(this.email && this.password && this.password.length >= 5  && this.over18 && this.firstName && this.lastName){
      this.loading = true;
      let data = new FormData();
      data.append("action",        "signup_stage_1");
      data.append("firstName",          this.firstName);
      data.append("lastName",          this.lastName);
      data.append("email",         this.email);
      data.append("password", this.password);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
          this.router.navigate(['/dashboard']);
        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;
        
      }).catch(error => {
          console.error("error: ", error);  
      });

  } else {
    console.log('invalid form');    
    if(!this.email){
      this.errorMsg = 'Invalid Email!';      
    } 
    if(!this.over18){
      this.errorMsg = 'Kindly Check Age Checkbox!';      
    }
    if(!this.password){
      this.errorMsg = 'Invalid password!';      
    }   
    if(this.password.length < 5){
      this.errorMsg = 'Write a Stronger Password!';      
    }

    setTimeout(() => {
      this.errorMsg = '';
    }, 3000)
  
  }
} 

signIn(){

  this.loading = true;

      let data = new FormData();
      data.append("action", "login");
      data.append("email", this.email);
      data.append("password", this.password);
      
      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if (resp.status != 'ERROR') {
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
  
          this.email = '';
          this.password = '';

          this.router.navigate(['/dashboard']);

        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;
  
      }).catch(error => {
          console.error("error: ",error);
      });
}


}
