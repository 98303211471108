<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img id="loaderStatus" class="loaderImage" alt="logo" src="assets/new-images/blue_psh_logo.png">
</div>

<div class='writersDynamic'>
  <section class='writerBio'>
    <div class='container'>
      <div class='writerBioWrapper'>
        <div class='aboutWriterWrapper'>
          <div class='aboutWriterHead'>
            <div class='writerImage'>
              <img *ngIf="writerData?.profile_image" [src]="writerData?.profile_image" [alt]="writerData?.name"
                width={68} height={68} />
            </div>
            <h3>{{writerData.name}}</h3>
            <div class='rating'>
              <p>{{ formatRating(writerData.rating) }}</p>
              <svg width="81" height="13" viewBox="0 0 81 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.91991 0.251285C7.84306 0.097415 7.68445 0 7.51079 0C7.33713 0 7.17852 0.097415 7.10167 0.251285L5.23394 3.99054L1.05792 4.58972C0.886053 4.61438 0.74326 4.73331 0.689586 4.89651C0.635911 5.0597 0.680664 5.23885 0.805027 5.35863L3.82692 8.26907L3.11335 12.3791C3.08399 12.5482 3.15435 12.7192 3.29485 12.82C3.43535 12.9209 3.62162 12.9342 3.77533 12.8543L7.51079 10.9138L11.2462 12.8543C11.4 12.9342 11.5862 12.9209 11.7267 12.82C11.8672 12.7192 11.9376 12.5482 11.9082 12.3791L11.1947 8.26907L14.2166 5.35863C14.3409 5.23885 14.3857 5.0597 14.332 4.89651C14.2783 4.73331 14.1355 4.61438 13.9637 4.58972L9.78763 3.99054L7.91991 0.251285Z"
                  fill="#01305c" />
                <path
                  d="M24.3923 0.251285C24.3155 0.097415 24.1569 0 23.9832 0C23.8095 0 23.6509 0.097415 23.5741 0.251285L21.7064 3.99054L17.5303 4.58972C17.3585 4.61438 17.2157 4.73331 17.162 4.89651C17.1083 5.0597 17.1531 5.23885 17.2774 5.35863L20.2993 8.26907L19.5858 12.3791C19.5564 12.5482 19.6268 12.7192 19.7673 12.82C19.9078 12.9209 20.094 12.9342 20.2477 12.8543L23.9832 10.9138L27.7187 12.8543C27.8724 12.9342 28.0586 12.9209 28.1991 12.82C28.3396 12.7192 28.41 12.5482 28.3806 12.3791L27.6671 8.26907L30.689 5.35863C30.8133 5.23885 30.8581 5.0597 30.8044 4.89651C30.7507 4.73331 30.6079 4.61438 30.4361 4.58972L26.26 3.99054L24.3923 0.251285Z"
                  fill="#01305c" />
                <path
                  d="M40.8647 0.251285C40.7879 0.097415 40.6293 0 40.4556 0C40.2819 0 40.1233 0.097415 40.0465 0.251285L38.1788 3.99054L34.0027 4.58972C33.8309 4.61438 33.6881 4.73331 33.6344 4.89651C33.5807 5.0597 33.6255 5.23885 33.7499 5.35863L36.7717 8.26907L36.0582 12.3791C36.0288 12.5482 36.0992 12.7192 36.2397 12.82C36.3802 12.9209 36.5664 12.9342 36.7202 12.8543L40.4556 10.9138L44.1911 12.8543C44.3448 12.9342 44.5311 12.9209 44.6716 12.82C44.8121 12.7192 44.8824 12.5482 44.8531 12.3791L44.1395 8.26907L47.1614 5.35863C47.2857 5.23885 47.3305 5.0597 47.2768 4.89651C47.2231 4.73331 47.0803 4.61438 46.9085 4.58972L42.7325 3.99054L40.8647 0.251285Z"
                  fill="#01305c" />
                <path
                  d="M57.3374 0.251285C57.2605 0.097415 57.1019 0 56.9283 0C56.7546 0 56.596 0.097415 56.5191 0.251285L54.6514 3.99054L50.4754 4.58972C50.3035 4.61438 50.1607 4.73331 50.1071 4.89651C50.0534 5.0597 50.0981 5.23885 50.2225 5.35863L53.2444 8.26907L52.5308 12.3791C52.5015 12.5482 52.5718 12.7192 52.7123 12.82C52.8528 12.9209 53.0391 12.9342 53.1928 12.8543L56.9283 10.9138L60.6637 12.8543C60.8174 12.9342 61.0037 12.9209 61.1442 12.82C61.2847 12.7192 61.3551 12.5482 61.3257 12.3791L60.6121 8.26907L63.634 5.35863C63.7584 5.23885 63.8031 5.0597 63.7495 4.89651C63.6958 4.73331 63.553 4.61438 63.3811 4.58972L59.2051 3.99054L57.3374 0.251285Z"
                  fill="#01305c" />
                <path
                  d="M73.8099 0.251285C73.7331 0.097415 73.5745 0 73.4008 0C73.2271 0 73.0685 0.097415 72.9917 0.251285L71.124 3.99054L66.9479 4.58972C66.7761 4.61438 66.6333 4.73331 66.5796 4.89651C66.5259 5.0597 66.5707 5.23885 66.695 5.35863L69.7169 8.26907L69.0034 12.3791C68.974 12.5482 69.0444 12.7192 69.1849 12.82C69.3254 12.9209 69.5116 12.9342 69.6653 12.8543L73.4008 10.9138L77.1363 12.8543C77.29 12.9342 77.4762 12.9209 77.6167 12.82C77.7572 12.7192 77.8276 12.5482 77.7982 12.3791L77.0847 8.26907L80.1066 5.35863C80.2309 5.23885 80.2757 5.0597 80.222 4.89651C80.1683 4.73331 80.0255 4.61438 79.8537 4.58972L75.6776 3.99054L73.8099 0.251285Z"
                  fill="#E3E3E3" />
                <g clipPath="url(#clip0_1171_5743)">
                  <path
                    d="M73.8317 0.253113C73.7548 0.0981237 73.5962 0 73.4225 0C73.2489 0 73.0903 0.0981237 73.0134 0.253113L71.1457 4.01957L66.9697 4.62311C66.7978 4.64795 66.655 4.76775 66.6013 4.93213C66.5477 5.09651 66.5924 5.27697 66.7168 5.39761L69.7387 8.32922L69.0251 12.4692C68.9957 12.6395 69.0661 12.8117 69.2066 12.9133C69.3471 13.0149 69.5334 13.0283 69.6871 12.9478L73.4225 10.9932L77.158 12.9478C77.3117 13.0283 77.498 13.0149 77.6385 12.9133C77.779 12.8117 77.8493 12.6395 77.82 12.4692L77.1064 8.32922L80.1283 5.39761C80.2527 5.27697 80.2974 5.09651 80.2437 4.93213C80.1901 4.76775 80.0473 4.64795 79.8754 4.62311L75.6994 4.01957L73.8317 0.253113Z"
                    fill="#01305c" />
                </g>
                <defs>
                  <clipPath id="clip0_1171_5743">
                    <rect width="12" height="13" fill="white" transform="translate(66.1222)" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <p class="totalReviews">({{writerData.total_reviews}} Reviews)</p>
          </div>
          <div class='aboutWriterFoot'>
            <div class='totalOrders'>
              <span>Total orders</span>
              <span>{{writerData.total_orders}}</span>
            </div>
          </div>
        </div>
        <div class='writerInfoWrapper'>
          <h1>Writer’s Bio</h1>
          <p class='desc'>{{writerData.bio}}</p>
          <div class='ordersBYSubject'>
            <h2>Competences:</h2>
            <ul>
              <li *ngFor="let subject of getExpertiseList()">{{subject}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>