<div class="signupWrapper">
  <form #admissionSignup="ngForm" class="signupForm">
    <p class="serverError" *ngIf="errorMsg">
      <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
      {{ errorMsg }}
    </p>
    <div class="nameInputWrapper inputWrapper normalInputWrapper">
      <div class="singleInput">
        <p class="asLabel">Name</p>
        <input
          #nameInput
          type="text"
          class="firstname_input"
          name="name"
          id="name"
          [(ngModel)]="signUp.name"
          placeholder="Full Name"
        />
        <!-- <label for="" class="firstname_label">Full Name</label> -->
        <p class="error">
          <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
          Please Enter Valid Full Name
        </p>
      </div>
    </div>
    <div class="inputWrapper normalInputWrapper">
      <p class="asLabel">Email</p>
      <input
        id="email"
        class="password_input"
        type="text"
        name="email"
        [(ngModel)]="signUp.email"
        placeholder="Enter your Email"
      />
      <!-- <label for="" class="password_label">Email</label> -->
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
        Please Enter Valid Email
      </p>
    </div>
    <div class="inputWrapper normalInputWrapper">
      <p class="asLabel">Phone Number</p>
      <div class="flagsWrapper">
        <div class="coutryFlags">
          <div
            (click)="
              selectedFlag = flag;
              selectedCountry = countryOptions[i];
              coutryCode = '';
              setCountryCode()
            "
            *ngFor="let flag of flagOptions; index as i"
            class="singleFlag"
          >
            <img
              [src]="'https://img.mobiscroll.com/demos/flags/' + flag + '.png'"
              alt="country"
            />
            <p>{{ countryOptions[i] }}</p>
          </div>
        </div>
        <div class="selectedFlag" *ngIf="selectedFlag">
          <img
            [src]="
              'https://img.mobiscroll.com/demos/flags/' + selectedFlag + '.png'
            "
            alt=""
          />
          <svg
            width="11"
            height="6"
            viewBox="0 0 11 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z"
              fill="#5E5E5E"
            />
          </svg>
        </div>
      </div>
      <input
        mask="(000) 0000000"
        (keyup)="checkPhoneNumber()"
        id="phone"
        type="text"
        name="phone"
        [(ngModel)]="signUp.phone"
        placeholder="(3xx) xxxxxxx"
        maxlength="14"
      />
      <span class="countryCode">{{ coutryCode }}</span>
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
        Please Enter Valid Phone Number
      </p>
    </div>
    <div class="inputWrapper normalInputWrapper">
      <p class="asLabel">Password</p>
      <input
        id="password"
        class="password_input"
        type="password"
        name="password"
        [(ngModel)]="signUp.password"
        placeholder="Enter a Password"
        [attr.type]="showPass ? 'text' : 'password'"
      />
      <a
        href="javascript:;"
        (click)="showPass = !showPass"
        class="showPassBtn"
        >{{ showPass ? "Hide" : "Show" }}</a
      >
      <!-- <label for="" class="password_label">Password</label> -->
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
        Please Enter At Least 5 Digits
      </p>
    </div>
    <div class="inputWrapper normalInputWrapper">
      <p class="asLabel">Confirm Password</p>
      <input
        id="confirmPass"
        class="password_input"
        type="password"
        name="confirmPass"
        [(ngModel)]="signUp.confirmPass"
        placeholder="Repeat your Password"
        [attr.type]="showConfirmPass ? 'text' : 'password'"
      />
      <a
        href="javascript:;"
        (click)="showConfirmPass = !showConfirmPass"
        class="showPassBtn"
        >{{ showConfirmPass ? "Hide" : "Show" }}</a
      >
      <!-- <label for="" class="password_label">Confirm Password</label> -->
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
        Passwords do not Match
      </p>
    </div>
    <button *ngIf="!loading" class="formSumitBtn" (click)="newUser()">
      Create Account
    </button>
    <div *ngIf="loading" class="loader">
      <img
        class="loadingImg"
        src="/assets/new-images/pshLoader.webp"
        alt="loading"
      />
    </div>
    <p class="alreadyText">
      Already have an account?
      <a href="javascript:;" routerLink="/admission/login">Log In</a>
    </p>
  </form>
</div>

<div class="confirmationPopup" *ngIf="OTPPopup">
  <div class="overlay" (click)="hideOTPModal()"></div>
  <div class="verifyModal">
    <p class="serverError" *ngIf="OTPerror">
      <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
      {{ OTPerror }}
    </p>
    <img
      src="/assets/new-images/otp-gif.webp"
      class="modalTopGif"
      alt="sending gif"
    />
    <p class="verifyHead">Verify its you.</p>
    <p class="verifyText">
      We have sent a verification code to {{ signUp.email }}, Please check your
      inbox and enter the 4-digit code below.
    </p>
    <input
      mask="0000"
      id="verificationCode"
      name="verificationCode"
      type="number"
      [(ngModel)]="OTP"
      placeholder="Enter 4-digits code"
    />
    <button *ngIf="!OTPLoading" class="formSumitBtn" (click)="verifyCode()">
      Verify Code
    </button>
    <div class="loader OTP" *ngIf="OTPLoading">
      <img
        class="loadingImg"
        src="/assets/new-images/pshLoader.webp"
        alt="loading"
      />
    </div>
    <p class="noEmailText">
      Didn’t receive an email?
      <a
        class="tryAgainBtn"
        href="javascript:;"
        *ngIf="!OTPTimer"
        (click)="sendOTP()"
        >Try again</a
      >
      <span class="tryAgainBtn" *ngIf="OTPTimer"
        >Try again in {{ OTPTimer }}</span
      >
    </p>
  </div>
</div>
