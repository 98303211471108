import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyApiService } from 'src/app/services/_services';

@Pipe({
  name: 'convertCurrency'
})
export class ConvertCurrencyPipe implements PipeTransform {

  public currencyRates: any;

  constructor(
    private currencyApiService   : CurrencyApiService,
  ) { }

  transform(value: any, ...args: unknown[]): unknown {
    this.currencyApiService.currencyRatesS$.subscribe(resp => {
      console.log("Pipe resp:: ", resp)
      // this.currencyRates = JSON.parse(localStorage.getItem('currencyRates'));
      // console.log("this.currencyRates:: ", this.currencyRates);
      //
      // if(!this.currencyRates)
      //   return value + '<span class="span_month">PKR</span>';
      //
      // return (value * this.currencyRates['PKR']) + '<span class="span_month">PKR</span>';
    });

    this.currencyRates = JSON.parse(localStorage.getItem('currencyRates'));
    console.log("this.currencyRates:: ", this.currencyRates);

    if(!this.currencyRates)
      return value + '<span class="span_month">PKR</span>';

    return (value * this.currencyRates['PKR']) + '<span class="span_month">PKR</span>';
  }

}
