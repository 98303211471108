<section class="blogListingPage">
  <div class="blogTitle">
    <h1>Expert Guide to Charity and Key Islamic Practices</h1>
    <div class="titlePara">
      <p class="des-head">Learn about the significance of Sadqah, Zakat, Ramadan, and other obligatory practices in
        Islam. Our well-organized, scholar-approved guide help strengthen your faith and keep you connected with the
        Ummah!</p>
    </div>
    <div *ngIf="jsonData; else skeleton">
      <div class="searchWraper">
        <input type="text" class="searchControl" placeholder="Enter blog topic..." [(ngModel)]="search"
          (keyup)="filterBlogs()">
      </div>
    </div>
    <ng-template #skeleton>
      <div class="searchWraperSkeleton"></div>
    </ng-template>
  </div>
  <div class="container">
    <div class="blogList">
      <div *ngIf="noResultFound" class="noResultFound">No result found</div>
      <ng-container *ngIf="filteredData; else skeletonList">
        <div *ngFor="let blog of filteredData" class="blog">
          <div class="blogImg">
            <img [src]="blog.image" [alt]="blog.title + ' Image'" style="color: transparent; filter: invert(0)"
              width="372" height="256" />
          </div>
          <div class="blogName">
            <h2>
              <!-- <a [href]="'/blog/' + [blog.parent_slug ? blog.parent_slug + '/' + blog.page_slug :  blog.page_slug]">
                                <span [innerHTML]="blog.highlightedTitle || blog.title"></span>
                            </a> -->
              <a
                [routerLink]="'/blog/' + [blog.parent_slug ? blog.parent_slug + '/' + blog.page_slug :  blog.page_slug]">
                <span [innerHTML]="blog.highlightedTitle || blog.title"></span>
              </a>
            </h2>
          </div>
        </div>
      </ng-container>
      <ng-template #skeletonList>
        <div class="blogSkeleton" *ngFor="let i of [1, 2, 3, 4, 5, 6]"></div>
      </ng-template>
    </div>
  </div>
</section>