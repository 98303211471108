import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'datetimeToDuration'
})
export class DatetimeToDurationPipe implements PipeTransform {
  transform(datetime: string): string {
    const targetDate = new Date(datetime);

    const formattedDate = `${targetDate.getFullYear().toString().slice(-2)}/${(targetDate.getMonth() + 1).toString().padStart(2, '0')}/${targetDate.getDate().toString().padStart(2, '0')}`;

    return formattedDate;
  }
}
