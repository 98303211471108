import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
// import { $ } from 'protractor';
import { HelperService, AppSettings, AuthenticationService } from 'src/app/services/_services';
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-scholarship-program-review',
  templateUrl: './scholarship-program-review.component.html',
  styleUrls: ['../scholarship-program-form/scholarship-program-form.component.css','./scholarship-program-review.component.css']
})
export class ScholarshipProgramReviewComponent implements OnInit {
  public loading = true;

  public applicationId;
  
  public authSub;
  public currentUser;

  public applicationInfo;
  public thanksPopupShow = false;

  constructor(
    private route                  : ActivatedRoute,
    private helperService          : HelperService,
    private authenticationService  : AuthenticationService,
    private router                 : Router
  ) { 
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.currentUser = user;       
            console.log('this.currentUser::', this.currentUser);
        } else {
          this.currentUser = null;          
        }
      });
    }
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.applicationId = id;
    });
    this.getApplication()
  }

  getApplication(){

    let url = AppSettings.BACK_ENDPOINT + 'scholorship_admission/' + this.applicationId;

    this.helperService.httpGetRequests(url).then(resp => {
      if(resp.status == 200){
        this.applicationInfo = resp.admissions;
        this.loading = false;
      }
    }).catch(error => {
      console.log('error::', error)
      this.loading = false;

    });
  }

  submitApplication(){
    let url = AppSettings.BACK_ENDPOINT + 'scholorship_admission/' + this.applicationId;

    let data = new FormData();
    data.append('user_id' , this.currentUser.ID);
    data.append('is_completed' , '1');

    this.helperService.httpPostRequests(url, data).then(resp => {
      this.thanksPopupShow = true;
      $('body').css('overflow-y', 'hidden');
      $('body').css('position', 'fixed');
    }).catch(error => {
      console.log('error::', error)
      this.loading = false;
    });
  }

  editApplication(){
    this.router.navigate(['/scholarship-program/apply/' + this.applicationId])
  }

  rerouteToPortal(page){
    $('body').css('overflow-y', 'unset');
    $('body').css('position', 'unset');
    this.router.navigate([page]);
  }

  ngOnInit(): void {
  }

}
