import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $;
import { AuthenticationService, HelperService, COUNTRYLIST, AppSettings, SEOService } from 'src/app/services/_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-scholarship-program-form',
  templateUrl: './scholarship-program-form.component.html',
  styleUrls: ['./scholarship-program-form.component.css']
})
export class ScholarshipProgramFormComponent implements OnInit {

public candidateInfo         = {
    name                           : '',
    father_name                    : '',
    gender                         : 'Female',
    dob                            : '',
    cnic                           : '',
    address                        : '',
    city                           : null,
    whatsapp                       : {
                                     code     : '',
                                     number   : '',
                                     country  : ''
                                   },
    // matriculation_year              : '',
    // matriculation_percentage        : '',
    // applying_institution            : '',
    education_level                 : null,
    // expected_grad_date              : '',
    current_GPA                     : null,
    // desired_field                   : '',
    applying_class                  : null,
    cnic_img                        : null,
    candidate_img                   : null,
    father_death_certificate        : null,
    academic_records                : [],
    academic_records_chng           : '0',
    education_goals                 : '',
    future_plans                    : '',
    is_completed                    : '0',
 }

public formStep = 'candidate_info' //candidate_info , academic_info, essay_questions
public education_goals_words = 0;
public future_plan_words = 0;

public educationLevelOptions = [
  '5th Class','6th Class', '7th Class'
]        
public applyingClassOptions = [
  '6th Class', '7th Class','8th Class'
]        

public gradeOptions          = ['A+','A-','A','B+','B','B-','C+','C','C-','D','F']           
public genderOptions         = ['Male', 'Female']           
public cityOptions           = ['Islamabad', 'Rawalpindi']           
public dateOptions           = [];
public monthOptions          = [];
public yearOptions           = [];
public yearOptionsGrad       = [];
public binaryOptions         = [ 
 {
   name  : 'Yes',
   value : '1',
 },
 {
   name  : 'No',
   value : '0',
 },
]                             

public selectedDate ;
public selectedMonth ;
public selectedYear ;
public dobError;

public selectedDateGrad ;
public selectedMonthGrad ;
public selectedYearGrad ;
public gradError;

public countryOptions ;
public flagOptions ;

public countryList;
public countryAgainstCode;

public countryCode;
public country;
public city;
public loading = true;

public authSub;
public currentUser;

public disabledBtn = false;

public applicationId;
public editPage = false;

constructor(
private authenticationService  : AuthenticationService,
private helperService          : HelperService,
private http                   : HttpClient,
private router                 : Router,
private route                  : ActivatedRoute,
private seoService: SEOService,
) {

  this.route.paramMap.subscribe((params) => {
    var id = params.get('id')
    this.applicationId = id;
    if (this.applicationId) {
      this.editPage = true;
    }
  })

  if (!this.applicationId) {
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
          this.currentUser = user;
          console.log('this.currentUser::', this.currentUser.ID);
        } else {
          this.currentUser = null;
          localStorage.setItem('admissionLink', '/scholarship-program/apply');
          this.router.navigate(['/admission/login'])
        }
      });
    }
  } else {
    this.getApplication()
  }
  this.countryList = COUNTRYLIST.COUNTRY_LIST;
  this.countryAgainstCode = COUNTRYLIST.COUNTRY_AGAINST_CODE;
  this.getGeoLocs();
}

ngOnInit(): void {

  this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
    const countries = [];
    const countriesImg = [];
    for (let i = 0; i < resp.length; ++i) {
      const country = resp[i];
      countries.push(country.text);
      countriesImg.push(country.value);
    }
    this.countryOptions = countries;
    this.countryOptions.push('Canada');
    this.countryOptions.sort();
    var canadaIndex = this.countryOptions.indexOf('Canada');
    this.flagOptions = countriesImg;
    this.flagOptions.splice(canadaIndex, 0, 'CA');
  });

  this.setDateOptions();
  this.setYearOptions();

  $("input[type='number']").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  $("input[type='email']").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  $("textarea").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  this.setSEO_Tags("Pakistan Sweet Home Scholarship Program for Orphans","Learn about Pakistan Sweet Home’s scholarship program, helping orphans pursue their education and fulfill their potential.")
}
setSEO_Tags(title: string, desc: string) {
  let self = this;
  setTimeout(function () {
    // Set Basic Meta Tags
    self.seoService.updateTitle(title);
    self.seoService.updateDescription(desc);

    // Set Og Meta tags
    self.seoService.updateOgTitle(title);
    self.seoService.updateOgDesc(desc);
    self.seoService.updateOgUrl();

    // Set Twitter Meta Tags
    self.seoService.updateTwitterTitle(title);
    self.seoService.updateTwitterDesc(desc);

    // Set Canonical Tag
    self.seoService.updateCanonicalUrl();


  }, 500);
}
removeInvalid(e) {
  if ($(e).hasClass('invalid')) {
    $(e).removeClass('invalid');
  }
}

changeStep(){
  if(this.checkFileInput()){
    if ($('.admissionForm').hasClass('ng-valid')) {
      if(this.formStep == 'candidate_info' ){
        this.formStep = 'academic_info';
      } else if(this.formStep == 'academic_info'){
        this.formStep = 'essay_questions';
      } else if(this.formStep == 'essay_questions'){
        if(this.education_goals_words < 601 && this.future_plan_words < 601){
          this.submitApplication()
        } else {
          if(this.education_goals_words > 600){
            $('#educationGoals').addClass('invalid');
          }
          if(this.future_plan_words > 600){
            $('#futurePlan').addClass('invalid');
          }
        }
      }
    } else {
      this.checkTextInput();
      this.checkSelectInput();
      this.focusFirstInvalid();
      this.checkPhoneNumbers();
      if(this.formStep == 'candidate_info') {
        if(this.candidateInfo.cnic.length < 13){
          $('#cnicNum').addClass('invalid');
        }
      }
    }
  } else {
    this.checkTextInput();
      this.checkSelectInput();
      this.focusFirstInvalid();
      this.checkPhoneNumbers();
  }
}

submitApplication() {
    this.disabledBtn = true;
    this.setDOB();
    // this.setGradDate()

    if (!this.editPage) {
      var url = AppSettings.BACK_ENDPOINT + 'scholorship_admission'
      var data = new FormData();
      for (let key in this.candidateInfo) {
        if (key != 'phone' && key != 'whatsapp' && key != 'academic_records') {
          data.append(key, this.candidateInfo[key])
        }
        if(key == 'academic_records'){
          for (let i = 0; i < this.candidateInfo.academic_records.length; i++) {
            const element = this.candidateInfo.academic_records[i];
            data.append('academic_records[]', element)
          }
        }    
      }
      data.append('whatsapp_number', '+' + this.candidateInfo.whatsapp.code + ' ' + this.removeFirstZero(this.candidateInfo.whatsapp.number))
      data.append('user_id', this.currentUser.ID)

      console.log('data::', data)

      this.helperService.httpPostRequests(url, data).then(resp => {
        this.disabledBtn = false;
        this.router.navigate(['/scholarship-program/review/' + resp.scholorship_data.id])

      }).catch(error => {
        console.log('error::', error)
        this.disabledBtn = false;
      });
    } else {
      this.editApplication()
    }
}

checkPhoneNumbers() {
  if (this.candidateInfo.whatsapp.number.length < 9) {
    $('#whatsappNumber').addClass('invalid');
  }
}

setDOB() {
  this.candidateInfo.dob = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
}
// setGradDate(){
//   this.candidateInfo.expected_grad_date = this.selectedYearGrad + '-' + this.selectedMonthGrad + '-' + this.selectedDateGrad;
// }

checkTextInput() {
  $("input[type='text']").each(function () {
    if (!$(this).val() && $(this).attr('required') ) {
      $(this).addClass('invalid');
    }
  })
  $("textarea").each(function () {
    if (!$(this).val()) {
      $(this).addClass('invalid');
    }
  })
  $("input[type='number']").each(function () {
    if (!$(this).val()) {
      $(this).addClass('invalid');
    }
  })
}

checkSelectInput() {
  if(this.formStep == 'candidate_info'){
    if (!this.candidateInfo.gender) {
      $('#gender').addClass('invalid');
    } 
    if (!this.candidateInfo.city) {
      $('#citySelect').addClass('invalid');
    } 
    if(!this.selectedDate){
      $('#dateDOB').addClass('invalid');
      this.dobError = true;
    }    
    if(!this.selectedMonth){
      $('#monthDOB').addClass('invalid');
      this.dobError = true;
    }    
    if(!this.selectedYear){
      $('#yearDOB').addClass('invalid');
      this.dobError = true;
    }    
    if(this.selectedDate && this.selectedMonth && this.selectedYear){
      this.dobError = false;
    }  
    if (!this.candidateInfo.applying_class) {
      $('#applyingClass').addClass('invalid');
    }
  } else if(this.formStep == 'academic_info'){
    if (!this.candidateInfo.education_level) {
      $('#educationLevel').addClass('invalid');
    }
    if (!this.candidateInfo.current_GPA) {
      $('#currentGrade').addClass('invalid');
    }
    // if(!this.selectedDateGrad){
    //   $('#dateDOBGrad').addClass('invalid');
    //   this.gradError = true;
    // }    
    // if(!this.selectedMonthGrad){
    //   $('#monthDOBGrad').addClass('invalid');
    //   this.gradError = true;
    // }    
    // if(!this.selectedYearGrad){
    //   $('#yearDOBGrad').addClass('invalid');
    //   this.gradError = true;
    // }    
    // if(this.selectedDateGrad && this.selectedMonthGrad && this.selectedYearGrad){
    //   this.gradError = false;
    // }  
  }
}

saveFile(event, fileName){
  if(event.target.files[0]){
    let file = event.target.files[0];
    let fileSize = file.size / 1024 / 1024;
    if(fileSize > 2){
      Swal.fire('File Size Too Large', 'File Size Must Be Less Than 2MB', 'error');
    } else {
      if(fileName == 'cnicFile'){
        this.candidateInfo.cnic_img = file;
        $('#cnicFile').removeClass('invalid')
      } else if(fileName == 'deathCertificate'){
        this.candidateInfo.father_death_certificate = file;
        $('#deathCertificate').removeClass('invalid')      
      } else if(fileName == 'candidateImg'){
        this.candidateInfo.candidate_img = file;
        $('#candidateImg').removeClass('invalid')
      } else if(fileName == 'academicRecords'){
        if(this.editPage){
          this.candidateInfo.academic_records_chng = '1';
        }
        this.candidateInfo.academic_records = [];
        for (let i = 0; i < event.target.files.length; i++) {
          const element = event.target.files[i];
          console.log('element::', element);
          this.candidateInfo.academic_records.push(element);            
        }
        $('#academicRecords').removeClass('invalid')
      }
    }
  }
}

checkFileInput(){
  var allFiles = true;
  if(this.formStep == 'academic_info'){
    if(!this.candidateInfo.cnic_img){
      $('#cnicFile').addClass('invalid')
      allFiles = false;
    }  
    if(!this.candidateInfo.father_death_certificate){
      $('#deathCertificate').addClass('invalid')
      allFiles = false;
    }  
    if(!this.candidateInfo.candidate_img){
      $('#candidateImg').addClass('invalid')
      allFiles = false;
    }  
    if(!this.candidateInfo.academic_records.length){
      $('#academicRecords').addClass('invalid')
      allFiles = false;
    }
  }
  return allFiles;
}

focusFirstInvalid() {
  $('.admissionForm .invalid').each(function () {
    $('html, body').animate({
      scrollTop: $(this).offset().top
    }, 500);
    $(this).focus();
    return false;
  });
}

setDateOptions() {
  for (let i = 1; i < 32; i++) {
    this.dateOptions.push(i < 10 ? '0' + i : i);
    if (i < 13) {
      switch (i) {
        case 1: {
          this.monthOptions.push({
            'name': 'January',
            'value': '01'
          });
          break;
        }
        case 2: {
          this.monthOptions.push({
            'name': 'February',
            'value': '02'
          });
          break;
        }
        case 3: {
          this.monthOptions.push({
            'name': 'March',
            'value': '03'
          });
          break;
        }
        case 4: {
          this.monthOptions.push({
            'name': 'April',
            'value': '04'
          });
          break;
        }
        case 5: {
          this.monthOptions.push({
            'name': 'May',
            'value': '05'
          });
          break;
        }
        case 6: {
          this.monthOptions.push({
            'name': 'June',
            'value': '06'
          });
          break;
        }
        case 7: {
          this.monthOptions.push({
            'name': 'July',
            'value': '07'
          });
          break;
        }
        case 8: {
          this.monthOptions.push({
            'name': 'August',
            'value': '08'
          });
          break;
        }
        case 9: {
          this.monthOptions.push({
            'name': 'September',
            'value': '09'
          });
          break;
        }
        case 10: {
          this.monthOptions.push({
            'name': 'October',
            'value': '10'
          });
          break;
        }
        case 11: {
          this.monthOptions.push({
            'name': 'November',
            'value': '11'
          });
          break;
        }
        default: {
          this.monthOptions.push({
            'name': 'December',
            'value': '12'
          });
          break;
        }
      }
    }
  }
}

setYearOptions() {
  let j = 15;
  for (let i = 2022; j > 0; i--) {
    this.yearOptions.push(i);
    this.yearOptionsGrad.push(i + 20);
    j--;
  }
}

checkDOB() {
  if (this.selectedDate && this.selectedMonth && this.selectedYear) {
    this.dobError = false;
  }
}

checkGradDate() {  
  if (this.selectedDateGrad && this.selectedMonthGrad && this.selectedYearGrad) {
    this.gradError = false;
  }
}

getGeoLocs() {
  const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
  this.helperService.httpGetRequests(url).then(resp => {
    this.setPhoneCodes(resp);
  }).catch(err => {
    console.log('error', err)
  })
}

setPhoneCodes(geoResp) {

  console.log('geoResp::', geoResp);

  this.country = geoResp.geoplugin_countryName;
  this.city = geoResp.geoplugin_city;
  this.countryCode = this.countryList[this.country]['countryCodes'][0];

  this.candidateInfo.whatsapp.code = this.countryCode;
  this.candidateInfo.whatsapp.country = this.countryAgainstCode[this.candidateInfo.whatsapp.code]['isoCode2'];

  this.loading = false;

}

isNumberValidate(e, type) {
  var charCode = (e.which) ? e.which : e.keyCode;
  if (type == 'whatsapp') {
    if (!this.candidateInfo.whatsapp.number && charCode == 48) {
      return false;
    }
  }
}

setPhoneValues(numberType, country, flag) {
  if (numberType == 'whatsapp') {
    this.candidateInfo.whatsapp.code = this.countryList[country]['countryCodes'][0];
    this.candidateInfo.whatsapp.country = flag;
  }
}

getWordsCount(para) {
  var spaces = para.split(' ');
  return spaces.length - 1;
}

checkWordsLimit(para, limit, type) {
  var spaces = this.getWordsCount(para);

  if (spaces >= limit) {
    return false;
  } else {
    return true;
  }
}

getApplication() {

  let url = AppSettings.BACK_ENDPOINT + 'scholorship_admission/' + this.applicationId;

  this.helperService.httpGetRequests(url).then(resp => {
    if (resp.status == 200) {
      this.loading = false;
      this.setForm(resp.admissions)
    }
  }).catch(error => {
    console.log('error::', error)
    this.loading = false;

  });
}

setForm(admission) {
  admission.name ? this.candidateInfo.name = admission.name : '';
  admission.dob ? this.candidateInfo.dob = admission.dob : '';
  admission.gender ? this.candidateInfo.gender = admission.gender : '';
  admission.cnic ? this.candidateInfo.cnic = admission.cnic : '';
  admission.father_name ? this.candidateInfo.father_name = admission.father_name : '';
  
  if(admission.whatsapp){
    this.candidateInfo.whatsapp.number = admission.whatsapp.split(' ')[1];
    this.candidateInfo.whatsapp.code = admission.whatsapp.split(' ')[0].replace('+', '');
    this.candidateInfo.whatsapp.country = this.countryAgainstCode[this.candidateInfo.whatsapp.code]['isoCode2']
  }
  admission.city ? this.candidateInfo.city = admission.city : '';
  admission.address ? this.candidateInfo.address = admission.address : '';
  this.setDOBVars()

  // admission.matriculation_year ? this.candidateInfo.matriculation_year = admission.matriculation_year : '';
  // admission.matriculation_percentage ? this.candidateInfo.matriculation_percentage = admission.matriculation_percentage : '';
  // admission.applying_institution ? this.candidateInfo.applying_institution = admission.applying_institution : '';
  admission.education_level ? this.candidateInfo.education_level = admission.education_level : '';
  admission.applying_class ? this.candidateInfo.applying_class = admission.applying_class : '';
  // admission.expected_grad_date ? this.candidateInfo.expected_grad_date = admission.expected_grad_date : '';
  // this.setGradDateVars();

  admission.current_GPA ? this.candidateInfo.current_GPA = admission.current_GPA : '';
  // admission.desired_field ? this.candidateInfo.desired_field = admission.desired_field : '';
  admission.cnic_img ? this.candidateInfo.cnic_img = admission.cnic_img : '';
  admission.father_death_certificate ? this.candidateInfo.father_death_certificate = admission.father_death_certificate : '';
  admission.academic_records ? this.candidateInfo.academic_records = admission.academic_records : '';
  admission.candidate_photo ? this.candidateInfo.candidate_img = admission.candidate_photo : '';
  admission.education_goals ? this.candidateInfo.education_goals = admission.education_goals : '';
  admission.future_plans ? this.candidateInfo.future_plans = admission.future_plans : '';
  admission.is_completed ? this.candidateInfo.is_completed = admission.is_completed : '';

  this.countWords('educationGoals');
  this.countWords('futurePlan');
}

// setGradDateVars(){
//   let fullDOB = this.candidateInfo.expected_grad_date ? new Date(this.candidateInfo.expected_grad_date) : null;
//   if (fullDOB) {
//     this.selectedYearGrad = fullDOB.getFullYear();
//     this.selectedMonthGrad = (fullDOB.getMonth() + 1) < 10 ? '0' + (fullDOB.getMonth() + 1) : (fullDOB.getMonth() + 1).toString();
//     this.selectedDateGrad = fullDOB.getDate();
//   }
// }

setDOBVars() {
  let fullDOB = this.candidateInfo.dob ? new Date(this.candidateInfo.dob) : null;
  if (fullDOB) {
    this.selectedYear = fullDOB.getFullYear();
    this.selectedMonth = (fullDOB.getMonth() + 1) < 10 ? '0' + (fullDOB.getMonth() + 1) : (fullDOB.getMonth() + 1).toString();
    this.selectedDate = fullDOB.getDate();
  }
}

editApplication() {
  var url = AppSettings.BACK_ENDPOINT + 'scholorship_admission/' + this.applicationId
  var data = new FormData(); 
  for (let key in this.candidateInfo ) {
    if(key != 'phone' && key != 'whatsapp' && key != 'academic_records' ){
      data.append(key, this.candidateInfo[key]) 
    }
    if(key == 'academic_records'){
      for (let i = 0; i < this.candidateInfo.academic_records.length; i++) {
        const element = this.candidateInfo.academic_records[i];
        data.append('academic_records[]', element)
      }
    }            
  }
  data.append('whatsapp_number', '+' + this.candidateInfo.whatsapp.code + ' ' + this.candidateInfo.whatsapp.number)
  data.append('id', this.applicationId)

  this.helperService.httpPostRequests(url, data).then(resp => {
    this.disabledBtn = false;
    this.router.navigate(['/scholarship-program/review/' + resp.id])

  }).catch(error => {
    console.log('error::', error)
    this.disabledBtn = false;
  });
}

inputKeyup(el){
  if ($(el).hasClass('invalid')) {
    $(el).removeClass('invalid');
  }
}

countWords(type){
  if(type == 'educationGoals'){
    var arr = this.candidateInfo.education_goals.split(' ');
    this.education_goals_words = this.candidateInfo.education_goals ? arr.length : 0;
  } else {
    var arr = this.candidateInfo.future_plans.split(' ');
    this.future_plan_words = this.candidateInfo.future_plans ? arr.length : 0;
  }
}

saveDraft(){
  this.disabledBtn = true;

  if(this.selectedDate && this.selectedMonth && this.selectedYear ){
    this.setDOB();
  }
  // if(this.selectedDateGrad && this.selectedMonthGrad && this.selectedYearGrad){
  //   this.setGradDate()
  // }

  if (!this.editPage) {
    var url = AppSettings.BACK_ENDPOINT + 'scholorship_admission'
    var data = new FormData();
    for (let key in this.candidateInfo) {
      if (key != 'phone' && key != 'whatsapp' && key != 'academic_records') {
        data.append(key, this.candidateInfo[key] !== null ? this.candidateInfo[key] : '' )
      }
      if(key == 'academic_records' && this.candidateInfo.academic_records.length ){
        for (let i = 0; i < this.candidateInfo.academic_records.length; i++) {
          const element = this.candidateInfo.academic_records[i];
          data.append('academic_records[]', element)
        }
      }    
    }
    this.candidateInfo.whatsapp.number && data.append('whatsapp_number', '+' + this.candidateInfo.whatsapp.code + ' ' + this.removeFirstZero(this.candidateInfo.whatsapp.number))
    data.append('user_id', this.currentUser.ID)

    console.log('data::', data)

    this.helperService.httpPostRequests(url, data).then(resp => {
      this.disabledBtn = false;
      Swal.fire('Draft Saved Successfully!', 'You can Access your draft in Student Application Tab', 'success').then(function() {
        window.location.replace('/admission/student-application');
      });

    }).catch(error => {
      console.log('error::', error)
      this.disabledBtn = false;
    });
  } 
}

removeFirstZero(str) {
  let modifiedString = str;

  if (str.charAt(0) == "0") {
    modifiedString = str.slice(1);
  }

  return modifiedString;
}

}
