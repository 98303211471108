<section class="sponsorChildBanner">
    <div class="container">
        <div class="bannerWrapper">
            <div class="bannerContent">
                <h1>Create a Bright Future: Sponsor an Orphan Today</h1>
                <p>You can change a child's life and provide them a path towards a better future.</p>
                <a href="javascript:;" routerLink="/orphan-profiles">Choose a child to sponsor</a>
            </div>
            <div class="bannerImg">
                <div class="imagesWrapper">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="sponsorshipProcessSec">
  <div class="container">
    <h2>Our Sponsorship Process</h2>
    <div class="sponsorshipStepsWrapper dsktpOnly">
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">01: Explore</p>
          <p class="stepDesc">Visit our website and explore the profiles of children who need sponsorship. Find a child
            whose story touches your heart.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">02: Choose</p>
          <p class="stepDesc">Select a child to sponsor based on their unique story and needs.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">03: Register</p>
          <p class="stepDesc">Fill out a registration form with your personal and contact information.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">04: Set Up Payment</p>
          <p class="stepDesc">Enter your payment details to initiate your monthly sponsorship contributions.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">05: Meet Your Relationship Manager</p>
          <p class="stepDesc">You will be assigned a Sponsor and Orphan Relationship Manager. They will be your primary
            contact and facilitate your relationship with your sponsored child.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">06: Monitor Progress</p>
          <p class="stepDesc">Your Relationship Manager will keep you updated on your sponsored child's progress and
            ensure your sponsorship contributions are received timely.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">07: Celebrate Special Moments</p>
          <p class="stepDesc">Coordinate with your Relationship Manager to send special gifts or arrange a visit to your
            sponsored child, following our organization's guidelines.</p>
        </div>
      </div>
      <div class="singleSponsorshipStep">
        
        <div class="stepBox">
          <p class="stepName">08: Provide Feedback</p>
          <p class="stepDesc">Your Relationship Manager will collect your feedback to improve our sponsorship program
            and provide the best experience for both sponsors and children.</p>
        </div>
      </div>
    </div>

    <div class="stepsSwiperWrapper mobOnly">
      <div class="swiper stepsSwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">01: Explore</p>
              <p class="stepDesc">Visit our website and explore the profiles of children who need sponsorship. Find a
                child whose story touches your heart.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">02: Choose</p>
              <p class="stepDesc">Select a child to sponsor based on their unique story and needs.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">03: Register</p>
              <p class="stepDesc">Fill out a registration form with your personal and contact information.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">04: Set Up Payment</p>
              <p class="stepDesc">Enter your payment details to initiate your monthly sponsorship contributions.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">05: Meet Your Relationship Manager</p>
              <p class="stepDesc">You will be assigned a Sponsor and Orphan Relationship Manager. They will be your
                primary contact and facilitate your relationship with your sponsored child.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">06: Monitor Progress</p>
              <p class="stepDesc">Your Relationship Manager will keep you updated on your sponsored child's progress and
                ensure your sponsorship contributions are received timely.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">07: Celebrate Special Moments</p>
              <p class="stepDesc">Coordinate with your Relationship Manager to send special gifts or arrange a visit to
                your sponsored child, following our organization's guidelines.</p>
            </div>
          </div>
          <div class="swiper-slide">
            <div class="stepBox">
              <p class="stepName">08: Provide Feedback</p>
              <p class="stepDesc">Your Relationship Manager will collect your feedback to improve our sponsorship
                program and provide the best experience for both sponsors and children.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="swiper-pagination steps-pagination"></div>
    </div>

  </div>
</section>

<section class="faqSec">
  <div class="container">
    <app-sponsor-page-faq></app-sponsor-page-faq>
    <a href="javascript:;" class="chooseCTA" routerLink="/orphan-profiles">Choose a child to sponsor</a>
  </div>
</section>
