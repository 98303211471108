import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, HelperService } from 'src/app/services/_services';
import Swal from 'sweetalert2';

declare var $


@Component({
  selector: 'app-admission-forgot-password',
  templateUrl: './admission-forgot-password.component.html',
  styleUrls: ['./admission-forgot-password.component.css']
})
export class AdmissionForgotPasswordComponent implements OnInit {

  @ViewChild ('nameInput') nameInput;

 public email                       : any;
 public errorMsg                    : any  
 public loading                     : any = false;

 public authSub                     : any;
 public currentUser                 : any;

  constructor(
    private http                   : HttpClient,
    private authenticationService  : AuthenticationService,
    private router                 : Router,
    private helperService          : HelperService,
    ) { 

    }

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus()
    
  }

  ngOnInit(): void {
    
    if(localStorage.getItem('currentUser')){
      this.router.navigate(['/admission/fill-form']);
    } 

    $('#email').keyup(function(){
      $(this).removeClass('invalid');
    })
    
  }

  

  sendResetLink(){
      if(this.email && this.email.includes('@') && this.email.includes('.') ){
        this.loading = true;

        let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';    
    
        let data = new FormData();
        data.append("action", "forgot-password");
        data.append("email", this.email);
        
        return this.helperService.httpPostRequests(url, data).then(resp => {
          this.loading = false;
          resp.message && Swal.fire('Success', resp.message, 'success');
        }).catch(error => {
          Swal.fire('Error', 'Something went wrong, Check your internet connection and try again', 'error');
            console.error("error: ",error);
            this.loading = false;
        });
      } else {
        $('#email').addClass('invalid');
      }
  }

}
