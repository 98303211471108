import { Component, OnInit } from '@angular/core';
declare var $;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {


    $(document).ready(function () {
      $('.campusDetailBtn').click(function () {
        $(".campusDetail").css('text-overflow', 'unset');
        $(".campusDetail").css('overflow', 'visible');
        $(".campusDetail").css('white-space', 'normal');
        $(".campusDetail").css('display', 'block');
        $(".full-content").css('display', 'block');
        $(".campusDetailBtn").css('display', 'none');
        $(".showLess").css('display', 'block');

      })
    })



    $(document).ready(function () {
      $('.campusDetailBtn1').click(function () {
        $(".campusDetail1").css('text-overflow', 'unset');
        $(".campusDetail1").css('overflow', 'visible');
        $(".campusDetail1").css('white-space', 'normal');
        $(".full-content1").css('display', 'block');
        $(".campusDetailBtn1").css('display', 'none');
        $(".campusDetail1").css('display', 'block');
        $(".showLess").css('display', 'block');
      })
    })



    $(document).ready(function () {
      $('.campusDetailBtn2').click(function () {
        $(".campusDetail2").css('text-overflow', 'unset');
        $(".campusDetail2").css('overflow', 'visible');
        $(".campusDetail2").css('white-space', 'normal');
        $(".full-content2").css('display', 'block');
        $(".campusDetailBtn2").css('display', 'none');
        $(".campusDetail2").css('display', 'block');
        $(".showLess").css('display', 'block');

      })
    })



    $(document).ready(function () {
      $('.showLess').click(function () {
        $(".campusDetail").css('text-overflow', 'ellipsis');
        $(".campusDetail").css('overflow', 'hidden');
        $(".campusDetail").css('white-space', 'normal');
        $(".campusDetail").css('max-height', '350px');
        $(".campusDetail").css('display', 'block');
        $(".full-content").css('display', 'none');
        $(".showLess").css('display', 'none');
        $(".campusDetailBtn").css('display', 'block');

      })
    })

  }

}
