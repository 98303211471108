<div class="faqsWrapper">
  <h2>Frequently Asked Questions</h2>
  <div class="main-card">
    <ng-container *ngIf="orphanType == 'psh'" >
      <div class="card show">
        <div class="card-head">
          <h3>How much does it cost to sponsor a child?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body">
          <p>It costs $99 per month to sponsor a child through our program.</p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How does a sponsored child benefit from my donation?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Your monthly donation provides a variety of essential benefits for the child. This includes their educational needs, health care, nutritious meals, and additional support that contributes to their overall development and well-being. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I talk to the child I sponsor?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Absolutely, communication with your sponsored child is encouraged. The form of communication can range from letters to emails and sometimes even video calls, based on the regulations of the orphanage and resources available. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I see his/her results and progress reports?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Yes, you will receive regular updates and progress reports about your sponsored child. These updates will include information about the child's academic performance, personal development, and general health status. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I sponsor more than 1 child?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Definitely! You're more than welcome to sponsor more than one child. Every additional sponsorship means a world of difference to another child in need. </p>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="orphanType == 'cadet'" >
      <div class="card show">
        <div class="card-head">
          <h3>How much does it cost to sponsor a cadet?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body">
          <p>It costs $149 per month to sponsor a cadet through our program.</p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How does a sponsored cadet benefit from my donation?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Your monthly donation provides a variety of essential benefits for the cadet. This includes their educational needs, health care, nutritious meals, and additional support that contributes to their overall development and well-being. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I talk to the  cadet I sponsor?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Absolutely, communication with your sponsored  cadet is encouraged. The form of communication can range from letters to emails and sometimes even video calls, based on the regulations of the orphanage and resources available. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I see his/her results and progress reports?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Yes, you will receive regular updates and progress reports about your sponsored cadet. These updates will include information about the cadet's academic performance, personal development, and general health status. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I sponsor more than 1 cadet?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Definitely! You're more than welcome to sponsor more than one cadet. Every additional sponsorship means a world of difference to another child in need. </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="orphanType == 'day-scholar'" >
      <div class="card show">
        <div class="card-head">
          <h3>How much does it cost to sponsor a day scholar?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body">
          <p>It costs $59 per month to sponsor a day scholar through our program.</p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What facilities are provided to day scholars at your center?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Our day scholars receive free education, including uniforms, books, stationery, and other academic necessities. Additionally, they receive free transportation for pick-up and drop-off. We also provide both breakfast and lunch after school at our center.</p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How does a sponsored day scholar benefit from my donation?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Your monthly donation provides a variety of essential benefits for the day scholar. This includes their educational needs, health care, nutritious meals, and additional support that contributes to their overall development and well-being. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I talk to the day scholar I sponsor?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Absolutely, communication with your sponsored  day scholar is encouraged. The form of communication can range from letters to emails and sometimes even video calls, based on the regulations of the orphanage and resources available. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I see his/her results and progress reports?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Yes, you will receive regular updates and progress reports about your sponsored day scholar. These updates will include information about the day scholar's academic performance, personal development, and general health status. </p>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Can I sponsor more than 1 day scholar?</h3>
          <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
            <path
              d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
              fill="#B0B0B0" />
            <path
              d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
              fill="#B0B0B0" />
            <path
              d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
              fill="#B0B0B0" />
          </svg>

        </div>
        <div class="card-body" style="display:none">
          <p>Definitely! You're more than welcome to sponsor more than one day scholar. Every additional sponsorship means a world of difference to another child in need. </p>
        </div>
      </div>
    </ng-container>
    <div class="card">
      <div class="card-head">
        <h3>Can I write to him/her?</h3>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
          <path
            d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
            fill="#B0B0B0" />
          <path
            d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
            fill="#B0B0B0" />
          <path
            d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
            fill="#B0B0B0" />
        </svg>

      </div>
      <div class="card-body" style="display:none">
        <p>Yes, we encourage sponsors to write to their sponsored children. This correspondence can provide additional emotional support and contribute to building a personal relationship. </p>
      </div>
    </div>
    <div class="card">
      <div class="card-head">
        <h3>Can I send him/her gifts?</h3>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
          <path
            d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
            fill="#B0B0B0" />
          <path
            d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
            fill="#B0B0B0" />
          <path
            d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
            fill="#B0B0B0" />
        </svg>

      </div>
      <div class="card-body" style="display:none">
        <p>We understand the desire to give personalized gifts. However, due to potential logistical and fairness issues, we usually recommend contributing via donations instead. This fund allows us to provide gifts to all children during holidays or special occasions, ensuring equitable treatment. </p>
      </div>
    </div>
    <div class="card">
      <div class="card-head">
        <h3>Can I meet him/her?</h3>
        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="18" cy="18" r="17.5" stroke="#B0B0B0" />
          <path
            d="M18 11.8887C17.4 11.8887 17 12.1887 17 12.6387V23.1387C17 23.5887 17.4 23.8887 18 23.8887C18.6 23.8887 19 23.5887 19 23.1387V12.6387C19 12.1887 18.6 11.8887 18 11.8887Z"
            fill="#B0B0B0" />
          <path
            d="M24 15.4444C23.7 15.4444 23.5 15.5514 23.3 15.7653L17.3 22.1819C16.9 22.6097 16.9 23.2514 17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792L24.7 17.2625C25.1 16.8347 25.1 16.1931 24.7 15.7653C24.5 15.5514 24.3 15.4444 24 15.4444Z"
            fill="#B0B0B0" />
          <path
            d="M12 15.4444C11.7 15.4444 11.5 15.5514 11.3 15.7653C10.9 16.1931 10.9 16.8347 11.3 17.2625L17.3 23.6792C17.7 24.1069 18.3 24.1069 18.7 23.6792C19.1 23.2514 19.1 22.6097 18.7 22.1819L12.7 15.7653C12.5 15.5514 12.3 15.4444 12 15.4444Z"
            fill="#B0B0B0" />
        </svg>

      </div>
      <div class="card-body" style="display:none">
        <p>Yes, visits can be arranged for sponsors to meet their sponsored children. All visits must follow the guidelines and procedures established by our organization to ensure the safety and best interests of the children. </p>
      </div>
    </div>


  </div>
</div>
