<section class="volunteerSec">
  <div class="container">
    <div class="bannerContent">
      <h1>Volunteer with Pakistan Sweet Home</h1>
      <p>Lend a helping hand. Make a real difference.</p>
    </div>
  </div>
</section>

<section class="whyVolunteerSec">
  <div class="container">
    <div class="whyVolunteerWrapper">
      <div class="singleWhy">
        <h2>Why Volunteer?</h2>
        <p class="reasonDesc">By volunteering, you don't just contribute your time and skills. You touch lives, create
          hope, and help build a brighter future for our children.</p>
        <img src="assets/new-images/get-involved/why-volunteer-img.png" alt="volunteer img">
      </div>
      <div class="singleWhy">
        <h2>What Can You Do?</h2>
        <p class="reasonDesc">There's a lot you can do - from tutoring, event planning, fundraising to administrative
          tasks. Tell us about your skills and interests, and we'll find a role that suits you.</p>
        <img src="assets/new-images/get-involved/what-volunteer-img.png" alt="volunteer img">
      </div>
    </div>
  </div>
</section>

<section class="joinInfoSec">
  <div class="container">
    <div class="joiningContent">
      <h2>Join us in our mission to transform lives. Volunteer for Pakistan Sweet Home.</h2>
      <p>Fill out the form below to show your interest. We'll get back to you soon!</p>
    </div>
  </div>
</section>

<section class="volunteerFormSec">
  <div class="container">
    <div class="formWrapper">
      <form #volunteerForm="ngForm" class="volunteerForm">
        <div class="inputGroup">
          <input type="text" placeholder="Full Name" [(ngModel)]="volunteerInfo.name" name="name" required>
        </div>
        <div class="inputGroup">
          <input type="text" placeholder="Contact Email" [(ngModel)]="volunteerInfo.email" name="email" required>
        </div>
        <div class="inputGroup">
          <input mask="(000) 00000099" class="phoneInput" id="phoneNumber" type="text" name="phoneNumber"
            placeholder="(3xx) xxxxxxx" [(ngModel)]="volunteerInfo.phone.number"
            (keypress)="isNumberValidate($event, 'phone')" required>
          <span class="countryCode">+{{ volunteerInfo.phone.code }}</span>
          <div class="flagsWrapper">
            <div class="coutryFlags">
              <div (click)=" setPhoneValues('phone', country, flagOptions[i] ) "
                *ngFor="let country of countryOptions;index as i" class="singleFlag">
                <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flagOptions[i] + '.png' " alt="country">
                <p>{{country}}</p>
              </div>
            </div>
            <div class="selectedFlag">
              <img [src]="'https://img.mobiscroll.com/demos/flags/' + volunteerInfo.phone.country + '.png'" alt="">
              <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z" fill="#5E5E5E" />
              </svg>
            </div>
          </div>
        </div>
        <div class="inputGroup">
          <input type="text" placeholder="Skills/Interests" [(ngModel)]="volunteerInfo.skills" name="skills">
        </div>
        <div class="inputGroup">
            <ng-select class="admissionSelect" [items]="binaryOptions" bindLabel="label"
                bindValue="value" [(ngModel)]="volunteerInfo.is_experienced" placeholder='Any previous volunteering experience?' name="isExperienced"
                [searchable]="false" [clearable]="false" required="" id="isExperienced" (change)="removeInvalid('#isExperienced')">
            </ng-select>
        </div>
        <button class="formSubmitBtn" *ngIf="!disabledBtn" (click)="submitApplication()" >Submit Application</button>
        <a href="javascript:;" *ngIf="disabledBtn" class="formSubmitBtn loaderBlue">
          <img class="loadingImg" src="/assets/new-images/pshLoaderWhite.webp" alt="loading">
        </a>
        <div class="noteBox">
            <p><strong>Note:</strong> Your privacy is important to us. We only collect essential information for the volunteer process and adhere to all privacy and data collection regulations.</p>
        </div>
      </form>
    </div>
  </div>
</section>
