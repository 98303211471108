import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { HelperService, COUNTRYLIST, AppSettings, SEOService } from 'src/app/services/_services';
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-volunteer',
  templateUrl: './volunteer.component.html',
  styleUrls: ['./volunteer.component.css']
})
export class VolunteerComponent implements OnInit {

  public volunteerInfo = {
                            name              : '',
                            email             : '',
                            phone             : {
                                                   code     : '',
                                                   number   : '',
                                                   country  : ''
                                                },
                            skills            : '',
                            is_experienced    : null,
                            type              : 'volunteer'
                         }


  public countryOptions ;
  public flagOptions ;

  public countryList;
  public countryAgainstCode;

  public countryCode;
  public country;
  public city;

  public disabledBtn = false;

  public binaryOptions = [
    {
      label : 'Yes',
      value : '1'
    },
    {
      label : 'No',
      value : '0'
    },
  ]                         

  constructor(
    private helperService          : HelperService,
    private http                   : HttpClient,
    private seoService: SEOService,
  ) { 
    this.countryList = COUNTRYLIST.COUNTRY_LIST;
    this.countryAgainstCode = COUNTRYLIST.COUNTRY_AGAINST_CODE;
    this.getGeoLocs();
  }

  ngOnInit(): void {

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
      const countries = [];
      const countriesImg = [];
      for (let i = 0; i < resp.length; ++i) {
        const country = resp[i];
        countries.push(country.text);
        countriesImg.push(country.value);
      }
      this.countryOptions = countries;
      this.countryOptions.push('Canada');
      this.countryOptions.sort();
      var canadaIndex = this.countryOptions.indexOf('Canada');
      this.flagOptions = countriesImg;
      this.flagOptions.splice(canadaIndex, 0, 'CA');
    });

    $("input[type='text']").keyup(function(){      
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })
    $("input[type='number']").keyup(function(){
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })
    $("input[type='email']").keyup(function(){
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  getGeoLocs() {
    const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
      this.helperService.httpGetRequests(url).then(resp => {
            this.setPhoneCodes(resp);
      }).catch(err => {
        console.log('error', err)
      })
  }

  setPhoneCodes(geoResp){

    this.country = geoResp.geoplugin_countryName;
    this.city = geoResp.geoplugin_city;
    this.countryCode = this.countryList[this.country]['countryCodes'][0];

    this.volunteerInfo.phone.code = this.countryCode;     
    this.volunteerInfo.phone.country = this.countryAgainstCode[this.volunteerInfo.phone.code]['isoCode2'];

  }

  isNumberValidate(e, type) {
    var charCode = (e.which) ? e.which : e.keyCode;
    if(type == 'phone'){
      if(!this.volunteerInfo.phone.number && charCode == 48){
        return false;
      }
    }  
  }

  removeInvalid(e){
    if($(e).hasClass('invalid')){
      $(e).removeClass('invalid');
    }
  }

  checkTextInput(){
    $("input[type='text']").each(function(){
        if(!$(this).val()){
          $(this).addClass('invalid');
        }
    })
    $("textarea").each(function(){
        if(!$(this).val()){
          $(this).addClass('invalid');
        }
    })
    $("input[type='number']").each(function(){
      if(!$(this).val()){
        $(this).addClass('invalid');
      }
    })
  }

  checkSelectInput(){
    if(!this.volunteerInfo.is_experienced){
      $('#isExperienced').addClass('invalid');
    }        
  }

  focusFirstInvalid(){
    $('.volunteerForm .invalid').each(function(){      
    $(this).focus();
      return false;
    });
  }

  checkPhoneNumbers(){
    if(this.volunteerInfo.phone.number.length < 9){
      $('#phoneNumber').addClass('invalid');
    }
  }

  submitApplication(){    
    if($('.volunteerForm').hasClass('ng-valid')){
      this.disabledBtn = true;
      var url = AppSettings.BACK_ENDPOINT + 'create-volunteer'
      var data = new FormData();

      for (const key in this.volunteerInfo) {
          const element = this.volunteerInfo[key];
          if(key != 'phone'){
            data.append(key, element)
          }
      }
      data.append('phone', '+' + this.volunteerInfo.phone.code + ' ' + this.removeFirstZero(this.volunteerInfo.phone.number))

      this.helperService.httpPostRequests(url, data).then(resp => {
        if(resp.status){
          this.disabledBtn = false;
          Swal.fire('Request Submitted Successfully!', 'Your Request have been recieved, our team will get back to you in no time!', 'success').then(function(){
            window.location.replace('');
          });
        }
      }).catch(error => {
      console.log('error::', error)
      });

    } else {
      this.checkTextInput();
      this.checkSelectInput();
      this.focusFirstInvalid();
      this.checkPhoneNumbers();
    }
}

setPhoneValues(numberType, country, flag) {
  if (numberType == 'phone') {
    this.volunteerInfo.phone.code = this.countryList[country]['countryCodes'][0];
    this.volunteerInfo.phone.country = flag;
  }
}

removeFirstZero(str) {
  let modifiedString = str;

  if (str.charAt(0) == "0") {
    modifiedString = str.slice(1);
  }

  return modifiedString;
}


}

