import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $, Swiper, window;
@Component({
  selector: 'app-admissions',
  templateUrl: './admissions.component.html',
  styleUrls: ['./admissions.component.css']
})
export class AdmissionsComponent implements OnInit, AfterViewInit {

  public nextPage : any = true;
  public admissionClass : any;
  
  constructor(
    private activatedRoute         : ActivatedRoute,
    private router                 : Router
  ) { 
    let url = this.activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
  }

  routeCheck(url){

    console.log('routeCheck::', url);

    if(url == '/cadet-college-admission'){
      this.router.navigate(['/cadet-college-admission-8th-class'])
    } else if(url.includes('cadet-college-admission-8th-class')){
      this.admissionClass = 8
    } else if(url.includes('cadet-college-admission-11th-class')){
      this.admissionClass = 11
    } else if(url.includes('scholarship-program')){
      this.admissionClass = 100;
    } else if(url.includes('boarding-school')){
      this.admissionClass = 1;
    } else if(url.includes('education-sponsorship')){
      this.admissionClass = 365;
    }
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });
  }

  ngOnInit(): void {

    $(document).ready(function () {
      $('.card .card-body:first-child').show();

      $('.card-head').click(function () {
        console.log('askjdhasudbhm gere')
        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).parent().addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });


    $(".scroll-link").click(function (e) {
        
      e.preventDefault();
      var scrollTo = $(this).data("scroll-to");
      let headerHeight = $(".navbar").outerHeight();
      console.log(headerHeight);
      $('html, body').animate({
        scrollTop: $(scrollTo).offset().top - headerHeight
      }, 1000);
    });
    

  }
  

}
