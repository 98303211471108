import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-give-charity-in-muharram',
  templateUrl: './give-charity-in-muharram.component.html',
  styleUrls: ['./give-charity-in-muharram.component.css'],
})
export class GiveCharityInMuharramComponent implements OnInit, AfterViewInit {
  constructor() {}

  ngOnInit(): void {
    $(document).ready(function () {
      $('.card .card-body:first-child').show();

      $('.card-head').click(function () {
        console.log('askjdhasudbhm gere');
        if ($(this).parent().hasClass('show')) {
          $(this)
            .parent()
            .removeClass('show')
            .find('.card-body')
            .slideUp('fast');
        } else {
          $('.card .card-body').slideUp('fast');
          $('.card').removeClass('show');
          $(this)
            .parent()
            .addClass('show')
            .find('.card-body')
            .slideDown('fast');
        }
        return false;
      });
    });

    const desktopVideo = document.querySelector(
      '.dsktpOnly'
    ) as HTMLVideoElement;
    const mobileVideo = document.querySelector('.mobOnly') as HTMLVideoElement;
    const elementTobeClicked: HTMLElement = document.querySelector(
      'body'
    ) as HTMLElement;
    const playVideo = () => {
      if (desktopVideo) {
        desktopVideo.play().catch((error) => {
          console.error('Desktop video play error: ', error);
        });
      }

      if (mobileVideo) {
        mobileVideo.play().catch((error) => {
          console.error('Mobile video play error: ', error);
        });
      }
    };

    const interactionHandler = () => {
      playVideo();
      document.removeEventListener('click', interactionHandler);
      document.removeEventListener('keydown', interactionHandler);
    };

    setTimeout(() => {
      elementTobeClicked.click();
    }, 2000);
    document.addEventListener('click', interactionHandler);
    document.addEventListener('keydown', interactionHandler);
  }

  ngAfterViewInit(): void {}
}
