import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SEOService } from '../../services/_services/seo.service';

@Component({
  selector: 'app-writer',
  templateUrl: './writer.component.html',
  styleUrls: ['./writer.component.css'],
})
export class WriterComponent implements OnInit {
  public loading: boolean = true;
  public writerSlug: string;
  private backendURL: string = 'https://adminbackend.pakistansweethome.org.pk';
  private siteURL: string = 'www.pakistansweethome.org.pk';
  public writerData: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private http: HttpClient,
    private seoService: SEOService,
    private router: Router
  ) {
    this.activatedRoute.params.subscribe((routeParams) => {
      if (routeParams.slug) {
        this.writerSlug = routeParams.slug;
        console.log('Writer Slug', this.writerSlug);
      }
      if (this.writerSlug) {
        this.getWriterData();
      } else {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {}

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }

  getWriterData() {
    this.loading = true;
    const writerInfoURL = `${this.backendURL}/blogs/writers/site-writers-by-slug`;
    const payload = {
      slug: this.writerSlug,
      site_url: this.siteURL,
    };
    this.http.post(writerInfoURL, payload).subscribe((response: any) => {
      if (response['message'] == 'success') {
        console.log(response.data);
        if (response.data === null) {
          this.router.navigate(['/']);
          this.loading = false;
        } else {
          this.writerData = response.data;
          this.setSEO_Tags(
            this.writerData.meta_title,
            this.writerData.meta_description
          );
          console.log('dsfds', this.writerData);
          this.loading = false;
        }
      } else {
        console.log('else', response);
      }
    });
  }
  formatRating(rating: string | number): string {
    return Number(rating).toFixed(1);
  }
  getExpertiseList(): string[] {
    return this.writerData.expertise.split(',');
  }
}
