import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
declare var $;
import { AuthenticationService, HelperService, COUNTRYLIST, AppSettings } from 'src/app/services/_services';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-day-scholars-form',
  templateUrl: './day-scholars-form.component.html',
  styleUrls: ['./day-scholars-form.component.css']
})
export class DayScholarsFormComponent implements OnInit {

  public candidateInfo         = {
    name                           : '',
    education_level                : null,
    institution_name               : '',
    class                          : '',
    current_grade                  : null,
    dob                            : null,
    current_fee                    : '',
    guardian_name                  : null,
    phone                          : {
                                        code     : '',
                                        number   : '',
                                        country  : ''
                                     },
     whatsapp                      : {
                                       code     : '',
                                       number   : '',
                                       country  : ''
                                     },
     city                          : '',
     address                       : '',
     family_members                : '',
     family_income                 : '',
     admission_statement           : '',
     cnic_img                      : null,
     academic_record               : null,
     father_death_certificate      : null,
     fees_challan                  : null,
     is_hafiz                      : null,
     is_martyr_son                 : null,
     is_completed                  : '0',
 }

 public educationLevelOptions = [
  'School', 'College', 'University'
]        

public gradeOptions          = ['A+','A-','A','B+','B','B-','C+','C','C-','D','F']           
public dateOptions           = [];
public monthOptions          = [];
public yearOptions           = [];
public binaryOptions        = [ 
                                {
                                  name  : 'Yes',
                                  value : '1',
                                },
                                {
                                  name  : 'No',
                                  value : '0',
                                },
                              ]                                 

public selectedDate ;
public selectedMonth ;
public selectedYear ;
public dobError;

public countryOptions ;
public flagOptions ;

public countryList;
public countryAgainstCode;

public countryCode;
public country;
public city;
public loading = true;

public authSub;
public currentUser;

public disabledBtn = false;

public applicationId;
public editPage = false;

constructor(
  private authenticationService: AuthenticationService,
  private helperService: HelperService,
  private http: HttpClient,
  private router: Router,
  private route: ActivatedRoute
) {

  this.route.paramMap.subscribe((params) => {
    var id = params.get('id')
    this.applicationId = id;
    if(this.applicationId){
      this.editPage = true;
    }
  })

  if (!this.applicationId) {
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
          this.currentUser = user;
          console.log('this.currentUser::', this.currentUser);
        } else {
          this.currentUser = null;
          localStorage.setItem('admissionLink', '/education-sponsorship/apply');
          this.router.navigate(['/admission/login'])
        }
      });
    }
  } else {
    this.getApplication()
  }
  this.countryList = COUNTRYLIST.COUNTRY_LIST;
  this.countryAgainstCode = COUNTRYLIST.COUNTRY_AGAINST_CODE;
  this.getGeoLocs();
}

ngOnInit(): void {

  this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
    const countries = [];
    const countriesImg = [];
    for (let i = 0; i < resp.length; ++i) {
      const country = resp[i];
      countries.push(country.text);
      countriesImg.push(country.value);
    }
    this.countryOptions = countries;
    this.countryOptions.push('Canada');
    this.countryOptions.sort();
    var canadaIndex = this.countryOptions.indexOf('Canada');
    this.flagOptions = countriesImg;
    this.flagOptions.splice(canadaIndex, 0, 'CA');
  });

  this.setDateOptions();
  this.setYearOptions();

  $("input[type='text']").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  $("input[type='number']").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  $("input[type='email']").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })
  $("textarea").keyup(function () {
    if ($(this).hasClass('invalid')) {
      $(this).removeClass('invalid');
    }
  })

}

removeInvalid(e) {
  if ($(e).hasClass('invalid')) {
    $(e).removeClass('invalid');
  }
}

submitApplication() {
  if ($('.admissionForm').hasClass('ng-valid')) {
    this.disabledBtn = true;
    this.setDOB();

    if (!this.editPage) {
      var url = AppSettings.BACK_ENDPOINT + 'day_scholor_admission';
      var data = new FormData();
      for (let key in this.candidateInfo) {
        if (key != 'phone' && key != 'whatsapp') {
          data.append(key, this.candidateInfo[key])
        }
      }
      data.append('phone_number', '+' + this.candidateInfo.phone.code + ' ' + this.candidateInfo.phone.number)
      data.append('whatsapp_number', '+' + this.candidateInfo.whatsapp.code + ' ' + this.candidateInfo.whatsapp.number)
      data.append('user_id', this.currentUser.ID)

      console.log('data::', data)

      this.helperService.httpPostRequests(url, data).then(resp => {
        this.disabledBtn = false;
        this.router.navigate(['/education-sponsorship/review/' + resp.id])

      }).catch(error => {
        console.log('error::', error)
        this.disabledBtn = false;
      });
    } else {
      this.editApplication()
    }


  } else {
    this.checkTextInput();
    this.checkSelectInput();
    this.checkFileInput();
    this.focusFirstInvalid();
    this.checkPhoneNumbers();
  }
}

checkPhoneNumbers() {
  if (this.candidateInfo.phone.number.length < 9) {
    $('#phoneNumber').addClass('invalid');
  }
  if (this.candidateInfo.whatsapp.number.length < 9) {
    $('#whatsappNumber').addClass('invalid');
  }
}

setDOB() {
  this.candidateInfo.dob = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
}

checkTextInput() {
  $("input[type='text']").each(function () {
    if (!$(this).val()) {
      $(this).addClass('invalid');
    }
  })
  $("textarea").each(function () {
    if (!$(this).val()) {
      $(this).addClass('invalid');
    }
  })
  $("input[type='number']").each(function () {
    if (!$(this).val()) {
      $(this).addClass('invalid');
    }
  })
}

checkSelectInput() {
  if (!this.candidateInfo.education_level) {
    $('#educationLevel').addClass('invalid');
  }
  if (!this.candidateInfo.current_grade) {
    $('#currentGrade').addClass('invalid');
  }
  if (!this.selectedDate) {
    $('#dateDOB').addClass('invalid');
    this.dobError = true;
  }
  if (!this.selectedMonth) {
    $('#monthDOB').addClass('invalid');
    this.dobError = true;
  }
  if (!this.selectedYear) {
    $('#yearDOB').addClass('invalid');
    this.dobError = true;
  }
  if (this.selectedDate && this.selectedMonth && this.selectedYear) {
    this.dobError = false;
  }
  if (!this.candidateInfo.is_hafiz) {
    $('#isHafiz').addClass('invalid');
  }
  if (!this.candidateInfo.is_martyr_son) {
    $('#isMartyrSon').addClass('invalid');
  }
}

focusFirstInvalid() {
  $('.admissionForm .invalid').each(function () {
    $('html, body').animate({
      scrollTop: $(this).offset().top
    }, 500);
    $(this).focus();
    return false;
  });
}

setDateOptions() {
  for (let i = 1; i < 32; i++) {
    this.dateOptions.push(i < 10 ? '0' + i : i);
    if (i < 13) {
      switch (i) {
        case 1: {
          this.monthOptions.push({
            'name': 'January',
            'value': '01'
          });
          break;
        }
        case 2: {
          this.monthOptions.push({
            'name': 'February',
            'value': '02'
          });
          break;
        }
        case 3: {
          this.monthOptions.push({
            'name': 'March',
            'value': '03'
          });
          break;
        }
        case 4: {
          this.monthOptions.push({
            'name': 'April',
            'value': '04'
          });
          break;
        }
        case 5: {
          this.monthOptions.push({
            'name': 'May',
            'value': '05'
          });
          break;
        }
        case 6: {
          this.monthOptions.push({
            'name': 'June',
            'value': '06'
          });
          break;
        }
        case 7: {
          this.monthOptions.push({
            'name': 'July',
            'value': '07'
          });
          break;
        }
        case 8: {
          this.monthOptions.push({
            'name': 'August',
            'value': '08'
          });
          break;
        }
        case 9: {
          this.monthOptions.push({
            'name': 'September',
            'value': '09'
          });
          break;
        }
        case 10: {
          this.monthOptions.push({
            'name': 'October',
            'value': '10'
          });
          break;
        }
        case 11: {
          this.monthOptions.push({
            'name': 'November',
            'value': '11'
          });
          break;
        }
        default: {
          this.monthOptions.push({
            'name': 'December',
            'value': '12'
          });
          break;
        }
      }
    }
  }
}

setYearOptions() {
  let j = 35;
  for (let i = 2022; j > 0; i--) {
    this.yearOptions.push(i);
    j--;
  }
}

checkDOB() {
  if (this.selectedDate && this.selectedMonth && this.selectedYear) {
    this.dobError = false;
  }
}

getGeoLocs() {
  const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
  this.helperService.httpGetRequests(url).then(resp => {
    this.setPhoneCodes(resp);
  }).catch(err => {
    console.log('error', err)
  })
}

setPhoneCodes(geoResp) {

  console.log('geoResp::', geoResp);

  this.country = geoResp.geoplugin_countryName;
  this.city = geoResp.geoplugin_city;
  this.countryCode = this.countryList[this.country]['countryCodes'][0];

  this.candidateInfo.phone.code = this.countryCode;
  this.candidateInfo.phone.country = this.countryAgainstCode[this.candidateInfo.phone.code]['isoCode2'];

  this.candidateInfo.whatsapp.code = this.countryCode;
  this.candidateInfo.whatsapp.country = this.countryAgainstCode[this.candidateInfo.whatsapp.code]['isoCode2'];

  this.loading = false;

}

isNumberValidate(e, type) {
  var charCode = (e.which) ? e.which : e.keyCode;
  if (type == 'phone') {
    if (!this.candidateInfo.phone.number && charCode == 48) {
      return false;
    }
  } else if (type == 'whatsapp') {
    if (!this.candidateInfo.whatsapp.number && charCode == 48) {
      return false;
    }
  }
}

setPhoneValues(numberType, country, flag) {
  if (numberType == 'phone') {
    this.candidateInfo.phone.code = this.countryList[country]['countryCodes'][0];
    this.candidateInfo.phone.country = flag;
  } else if (numberType == 'whatsapp') {
    this.candidateInfo.whatsapp.code = this.countryList[country]['countryCodes'][0];
    this.candidateInfo.whatsapp.country = flag;
  }
}

getWordsCount(para) {
  var spaces = para.split(' ');
  return spaces.length - 1;
}

checkWordsLimit(para, limit) {
  var spaces = this.getWordsCount(para);

  if (spaces >= limit) {
    return false;
  } else {
    return true;
  }
}

checkLimit(event){
  // const pastedText = event.clipboardData.getData('text/plain');
  var pastedText = this.getWordsCount(event.clipboardData.getData('text/plain'));
  var oldText = this.getWordsCount(this.candidateInfo.admission_statement);
  if(pastedText + oldText > 150){
    return false;
  }
}

saveFile(event, fileName) {
  if (event.target.files[0]) {
    let file = event.target.files[0];
    let fileSize = file.size / 1024 / 1024;
    if (fileSize > 2) {
      Swal.fire('File Size Too Large', 'File Size Must Be Less Than 2MB', 'error');
    } else {
      if (fileName == 'cnicFile') {
        this.candidateInfo.cnic_img = file;   
      } else if (fileName == 'fatherDeathCertificate') {
        this.candidateInfo.father_death_certificate = file;
      } else if (fileName == 'feesChallan') {
        this.candidateInfo.fees_challan = file;
      } else if (fileName == 'academicRecord') {
        this.candidateInfo.academic_record = file;
      }
    }
  }
}

checkFileInput() {
  if (!this.candidateInfo.cnic_img) {
    $('#cnicFile').addClass('invalid')
  }
  if (!this.candidateInfo.father_death_certificate) {
    $('#fatherDeathCertificate').addClass('invalid')
  }
  if (!this.candidateInfo.academic_record) {
    $('#academicRecord').addClass('invalid')
  }
  if (!this.candidateInfo.fees_challan) {
    $('#feesChallan').addClass('invalid')
  }
}

getApplication() {

  let url = AppSettings.BACK_ENDPOINT + 'get_day_scholor_admission/' + this.applicationId;

  this.helperService.httpGetRequests(url).then(resp => {
    if (resp.status == 200) {
      this.loading = false;
      this.setForm(resp.admissions)
    }
  }).catch(error => {
    console.log('error::', error)
    this.loading = false;

  });
}

setForm(admission) {
  this.candidateInfo.name = admission.name;
  this.candidateInfo.education_level = admission.education_level;
  this.candidateInfo.institution_name = admission.institution_name;
  this.candidateInfo.class = admission.class;
  this.candidateInfo.current_grade = admission.current_grade;
  this.candidateInfo.current_fee = admission.current_fee;
  this.candidateInfo.dob = admission.dob;
  this.candidateInfo.guardian_name = admission.guardian_name;

  this.candidateInfo.phone.number = admission.phone.split(' ')[1];
  this.candidateInfo.phone.code = admission.phone.split(' ')[0].replace('+', '');
  this.candidateInfo.phone.country = this.countryAgainstCode[this.candidateInfo.phone.code]['isoCode2'];

  this.candidateInfo.whatsapp.number = admission.whatsapp.split(' ')[1];
  this.candidateInfo.whatsapp.code = admission.whatsapp.split(' ')[0].replace('+', '');
  this.candidateInfo.whatsapp.country = this.countryAgainstCode[this.candidateInfo.whatsapp.code]['isoCode2'];

  this.candidateInfo.city = admission.city;
  this.candidateInfo.address = admission.address;
  this.candidateInfo.family_members = admission.family_members;
  this.candidateInfo.family_income = admission.family_income;
  this.candidateInfo.admission_statement = admission.admission_statement;
  this.candidateInfo.fees_challan = admission.fees_challan;
  this.candidateInfo.cnic_img = admission.cnic_img;
  this.candidateInfo.academic_record = admission.academic_record;
  this.candidateInfo.father_death_certificate = admission.father_death_certificate;
  this.candidateInfo.is_hafiz = admission.is_hafiz_e_quran.toString();
  this.candidateInfo.is_martyr_son = admission.is_son_of_shaheed.toString();
  this.candidateInfo.is_completed = admission.is_completed;

  this.setDOBVars()

}

setDOBVars() {
  let fullDOB = this.candidateInfo.dob ? new Date(this.candidateInfo.dob) : null;
  if (fullDOB) {
    this.selectedYear = fullDOB.getFullYear();
    this.selectedMonth = (fullDOB.getMonth() + 1) < 10 ? '0' + (fullDOB.getMonth() + 1) : (fullDOB.getMonth() + 1).toString();
    this.selectedDate = fullDOB.getDate();
  }
}

editApplication() {
  var url = AppSettings.BACK_ENDPOINT + 'edit_day_scholor_admission/' + this.applicationId
  var data = new FormData();
  for (let key in this.candidateInfo) {
    if (key != 'phone' && key != 'whatsapp') {
      data.append(key, this.candidateInfo[key])
    }
  }
  data.append('phone_number', '+' + this.candidateInfo.phone.code + ' ' + this.candidateInfo.phone.number)
  data.append('whatsapp_number', '+' + this.candidateInfo.whatsapp.code + ' ' + this.candidateInfo.whatsapp.number)
  data.append('id', this.applicationId)

  console.log('data::', data)

  this.helperService.httpPostRequests(url, data).then(resp => {
    this.disabledBtn = false;
    this.router.navigate(['/education-sponsorship/review/' + resp.id])

  }).catch(error => {
    console.log('error::', error)
    this.disabledBtn = false;
  });
}

}
