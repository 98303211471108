<section class="thanksTop">
    <div class="container">
        <div class="topLogo">
            <a href="javascript:;" routerLink="" >
                <img src="./assets/new-images/blue_psh_logo.svg" alt="PSH logo">
            </a>
            <div class="thanksBanner">
                <div class="thanksText">
                    <h1><span>Thank you</span> for your interest in our {{ thanksText }}!</h1>
                    <p>Payment received. Redirecting to your account on the website in <span>00:{{ timer < 10 ? '0' + timer : timer }}</span> seconds.</p>
                </div>
                <img src="./assets/new-images/thanks-house.png" alt="house image">
            </div>
            
            
        </div>
    </div>
</section>

<section class="thanksFooter">
    <div class="container">
        <div class="social_links">
            <div class="socialLinks_left">
              <p class="linkHead">Follow Us</p>
              <ul class="socials_ul">
                <li>
                  <a target="_blank" href="https://www.facebook.com/PakistanSweetHome/"><svg width="27" height="27" viewBox="0 0 21 21" fill="none"
                      xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.1364 0H2.86364C1.28209 0 0 1.28209 0 2.86364V18.1364C0 19.7179 1.28209 21 2.86364 21H18.1364C19.7179 21 21 19.7179 21 18.1364V2.86364C21 1.28209 19.7179 0 18.1364 0Z"
                        fill="#003366" />
                      <path
                        d="M13.8265 9.2567L13.6153 11.0859C13.6065 11.1693 13.5674 11.2467 13.5054 11.3032C13.4434 11.3597 13.3627 11.3915 13.2788 11.3925H11.3697L11.3602 16.8155C11.3608 16.8798 11.3362 16.9417 11.2915 16.9879C11.2469 17.0341 11.1858 17.0609 11.1216 17.0625H9.18741C9.15525 17.0625 9.12342 17.0561 9.09383 17.0435C9.06423 17.0309 9.03749 17.0124 9.0152 16.9893C8.99291 16.9661 8.97554 16.9386 8.96412 16.9086C8.95271 16.8785 8.94749 16.8464 8.94877 16.8143V11.3925H7.51695C7.47198 11.392 7.42755 11.3827 7.38618 11.3651C7.34481 11.3474 7.30732 11.3218 7.27585 11.2897C7.24439 11.2575 7.21956 11.2195 7.20278 11.1778C7.18601 11.1361 7.17762 11.0914 7.17809 11.0465L7.17093 9.21733C7.1703 9.1722 7.17857 9.12739 7.19525 9.08546C7.21194 9.04352 7.23673 9.00529 7.2682 8.97293C7.29966 8.94058 7.3372 8.91474 7.37865 8.8969C7.4201 8.87905 7.46467 8.86954 7.5098 8.86892H8.94877V7.10301C8.94877 5.05312 10.1658 3.9375 11.9508 3.9375H13.4137C13.4589 3.93797 13.5036 3.94736 13.5452 3.96514C13.5868 3.98291 13.6244 4.00873 13.656 4.0411C13.6876 4.07347 13.7125 4.11175 13.7293 4.15376C13.746 4.19577 13.7543 4.24068 13.7537 4.28591V5.82511C13.7543 5.87034 13.746 5.91525 13.7293 5.95726C13.7125 5.99927 13.6876 6.03756 13.656 6.06993C13.6244 6.1023 13.5868 6.12811 13.5452 6.14589C13.5036 6.16366 13.4589 6.17306 13.4137 6.17352H12.5164C11.5463 6.17352 11.3578 6.6508 11.3578 7.34045V8.86892H13.49C13.5385 8.86953 13.5863 8.88039 13.6303 8.9008C13.6743 8.92121 13.7135 8.95069 13.7453 8.98732C13.777 9.02395 13.8007 9.06689 13.8147 9.11332C13.8287 9.15975 13.8327 9.20861 13.8265 9.2567Z"
                        fill="white" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.instagram.com/pakistansweethomeofficial/">
                    <svg width="27" height="27" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.4629 0H5.53711C2.48401 0 0 2.48401 0 5.53711V15.4629C0 18.516 2.48401 21 5.53711 21H15.4629C18.516 21 21 18.516 21 15.4629V5.53711C21 2.48401 18.516 0 15.4629 0ZM17.3086 13.6172C17.3086 15.6526 15.6526 17.3086 13.6172 17.3086H7.38281C5.34741 17.3086 3.69141 15.6526 3.69141 13.6172V7.38281C3.69141 5.34741 5.34741 3.69141 7.38281 3.69141H13.6172C15.6526 3.69141 17.3086 5.34741 17.3086 7.38281V13.6172Z"
                        fill="#003366" />
                      <path
                        d="M12.3457 10.5C12.3457 11.5193 11.5193 12.3457 10.5 12.3457C9.4807 12.3457 8.6543 11.5193 8.6543 10.5C8.6543 9.4807 9.4807 8.6543 10.5 8.6543C11.5193 8.6543 12.3457 9.4807 12.3457 10.5Z"
                        fill="#003366" />
                      <path
                        d="M13.6172 4.92188H7.38281C6.02577 4.92188 4.92188 6.02577 4.92188 7.38281V13.6172C4.92188 14.9742 6.02577 16.0781 7.38281 16.0781H13.6172C14.9742 16.0781 16.0781 14.9742 16.0781 13.6172V7.38281C16.0781 6.02577 14.9742 4.92188 13.6172 4.92188ZM10.5 13.5762C8.80378 13.5762 7.42383 12.1962 7.42383 10.5C7.42383 8.80378 8.80378 7.42383 10.5 7.42383C12.1962 7.42383 13.5762 8.80378 13.5762 10.5C13.5762 12.1962 12.1962 13.5762 10.5 13.5762ZM14.0273 7.58789C13.6875 7.58789 13.4121 7.31248 13.4121 6.97266C13.4121 6.63284 13.6875 6.35742 14.0273 6.35742C14.3672 6.35742 14.6426 6.63284 14.6426 6.97266C14.6426 7.31248 14.3672 7.58789 14.0273 7.58789Z"
                        fill="#003366" />
        
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.youtube.com/channel/UC3LsEXq7nT3_OU9Wycfa3iQ">
                    <svg width="27" height="27" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M15.7333 0H5.25C2.36667 0 0 2.36667 0 5.26667V15.75C0 18.6333 2.36667 21 5.25 21H15.7333C18.6333 21 21 18.6333 21 15.7333V5.26667C21 2.36667 18.6333 0 15.7333 0ZM17 11.0167C17 12.1167 16.9 13.2333 16.9 13.2333C16.9 13.2333 16.7833 14.1667 16.4 14.5833C15.9 15.1333 15.35 15.1333 15.1 15.1667C13.2667 15.3 10.5167 15.3167 10.5167 15.3167C10.5167 15.3167 7.1 15.2667 6.05 15.1667C5.76667 15.1167 5.1 15.1333 4.6 14.5833C4.2 14.1667 4.1 13.2333 4.1 13.2333C4.1 13.2333 4 12.1167 4 11.0167V9.98333C4 8.88333 4.1 7.76667 4.1 7.76667C4.1 7.76667 4.21667 6.83333 4.6 6.41667C5.1 5.86667 5.65 5.85 5.9 5.81667C7.75 5.68333 10.5 5.66667 10.5 5.66667C10.5 5.66667 13.25 5.68333 15.0833 5.81667C15.3333 5.85 15.9 5.86667 16.4 6.4C16.8 6.81667 16.9 7.76667 16.9 7.76667C16.9 7.76667 17 8.88333 17 9.98333V11.0167Z"
                        fill="#003366" />
                      <path d="M9.15039 8.43335V12.2667L12.6837 10.3667L9.15039 8.43335Z" fill="#003366" />
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://www.tiktok.com/@pakistansweethome">
                    <svg width="27" height="27" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clip-path="url(#clip0_1319_7149)">
                        <path
                          d="M19.4855 0H1.51454C0.678029 0 0 0.678029 0 1.51454V19.4855C0 20.322 0.678029 21 1.51454 21H19.4855C20.322 21 21 20.322 21 19.4855V1.51454C21 0.678029 20.322 0 19.4855 0ZM15.7601 8.03738V9.45706C15.0907 9.4573 14.4402 9.32613 13.8268 9.06728C13.4323 8.90076 13.0648 8.68617 12.7287 8.42691L12.7388 12.7968C12.7346 13.7808 12.3453 14.7053 11.6407 15.4017C11.0673 15.9685 10.3407 16.329 9.55324 16.4457C9.36817 16.4731 9.17987 16.4871 8.98944 16.4871C8.14644 16.4871 7.34606 16.214 6.69137 15.7102C6.56816 15.6153 6.45036 15.5125 6.33814 15.4017C5.57456 14.647 5.18081 13.6241 5.24709 12.548C5.2977 11.7289 5.62562 10.9477 6.17232 10.3354C6.89563 9.52522 7.90753 9.07549 8.98944 9.07549C9.17987 9.07549 9.36817 9.08976 9.55324 9.1172V9.64212V11.1023C9.37781 11.0444 9.19041 11.0126 8.9953 11.0126C8.00682 11.0126 7.20714 11.8191 7.22191 12.8082C7.23126 13.4411 7.57702 13.9944 8.08693 14.2994C8.32654 14.4427 8.60225 14.5315 8.89645 14.5476C9.12696 14.5603 9.34828 14.5282 9.55324 14.4605C10.2594 14.2272 10.7689 13.5639 10.7689 12.7815L10.7712 9.85523V4.51287H12.7264C12.7283 4.70658 12.7479 4.89562 12.7844 5.07876C12.932 5.82012 13.3499 6.46332 13.9315 6.90182C14.4386 7.28433 15.0701 7.51107 15.7546 7.51107C15.755 7.51107 15.7606 7.51107 15.7602 7.51062L15.7601 8.03738Z"
                          fill="#003366" />
                      </g>
                      <defs>
                        <clipPath id="clip0_1319_7149">
                          <rect width="27" height="27" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </a>
                </li>
                <li>
                  <a target="_blank" href="https://pk.linkedin.com/company/pakistan-sweet-home-official">
                    <svg width="27" height="27" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M18.9 0H2.1C0.945 0 0 0.945 0 2.1V18.9C0 20.055 0.945 21 2.1 21H18.9C20.055 21 21 20.055 21 18.9V2.1C21 0.945 20.055 0 18.9 0ZM6.3 17.85H3.15V8.4H6.3V17.85ZM4.725 6.615C3.675 6.615 2.835 5.775 2.835 4.725C2.835 3.675 3.675 2.835 4.725 2.835C5.775 2.835 6.615 3.675 6.615 4.725C6.615 5.775 5.775 6.615 4.725 6.615ZM17.85 17.85H14.7V12.285C14.7 11.445 13.965 10.71 13.125 10.71C12.285 10.71 11.55 11.445 11.55 12.285V17.85H8.4V8.4H11.55V9.66C12.075 8.82 13.23 8.19 14.175 8.19C16.17 8.19 17.85 9.87 17.85 11.865V17.85Z"
                        fill="#003366" />
                    </svg>
                  </a>
                </li>
        
        
              </ul>
            </div>
            <div class="socialLinks_right">
              <p>© 2024 Pakistan Sweet Home, Inc. All rights reserved.</p>
            </div>
        
          </div>
    </div>
</section>