<section class="banner_section">
  <div class="bg_video">
    <video class="dsktpOnly" width="100%" height="638px" autoplay loop muted oncanplay="this.play()"
      onloadedmetadata="this.muted = true">
      <source src="assets/videos/rabi-al-awwal.mp4" type="video/mp4">
    </video>
    <video class="mobOnly" width="100%" height="100%" autoplay loop muted oncanplay="this.play()"
      onloadedmetadata="this.muted = true">
      <source src="assets/videos/rabi-al-awwal.mp4" type="video/mp4">
    </video>
    <div class="bg-overlay"></div>
  </div>
  <div class="container">
    <div class="mainBanner">
      <div class="leftbanner">
        <h1>Rabi Al-Awwal 2024</h1>
        <p>Being an orphan himself, our beloved Prophet Muhammad (&#65018;) devoted his life to protecting and uplifting
          orphans. Now,
          it’s our turn to carry forward his Sunnah!</p>

      </div>
      <app-payment-form></app-payment-form>
      <!-- <app-payment-form-checkout></app-payment-form-checkout> -->
    </div>
  </div>
</section>

<section class="support_provided">
  <div class="container">
    <div class="support_head">
      <p class="qoutedText">&ldquo;I and the one who takes the responsibility of an orphan will be in Paradise thus, and
        he joined his two fingers together.&rdquo;
        <br />
        <span>&mdash; Prophet Muhammad (&#xFDFA;)</span>
      </p>
      <!-- <h2>Donate your Qurbani to Pakistan Sweet Home</h2>
      <p>Due to our limited capacity and resources, we are only performing Qurbani for small animals.</p>
    </div>
    <div class="main_support">
      <div class="single_support">

        <img src="../../../assets/images/qurbani/Sheep.svg" alt="sheep package" />
        <p class="qurbani_pkg_price">Sheep: <span class="price_qurbani">PKR 60,000/-</span></p>


      </div>
      <div class="single_support">


        <img src="../../../assets/images/qurbani/Goat.svg" alt="goat package" />

        <p class="qurbani_pkg_price">Goat: <span class="price_qurbani">PKR 60,000/-</span></p>

      </div>
      <div class="support_head">
        <p>The Qurbani will be performed on all three days of Eid at the Sweet Home Islamabad headquarters under our
          direct
          supervision.</p>
      </div>
    </div> -->
    </div>
  </div>

</section>

<section class="support_provided">
  <div class="container">
    <div class="support_head">
      <h2>Impact You Can Make in the Month of Prophet (&#65018;)</h2>
      <p>With your support, we can:</p>
    </div>
    <div class="main_support">
      <div class="single_support">
        <img src="../../../assets/images/muharram/conduct_Islamic_sessions.svg" alt="Conduct Islamic Sessions" />
        <p class="service_name">Conduct Islamic Sessions</p>
        <p class="service_des">Host Islamic sessions and milads with scholars to teach children about Rabi al-Awwal and
          Prophetic teachings. Revive the
          Sunnah of caring for orphans and enriching their spiritual journey.
        </p>

      </div>
      <div class="single_support">
        <img src="../../../assets/images/muharram/feed_in_way.svg" alt="Feed in the Way of Allah" />

        <p class="service_name">Feed in the Way of Allah</p>
        <p class="service_des">Share your blessings by providing meals in the way of Allah. Support Pakistan Sweet Home
          by providing meals, helping
          children and needy families receive the nutritious food they need to thrive.
        </p>

      </div>
      <div class="single_support">
        <img src="../../../assets/images/muharram/educate_orphans.svg" alt="Educate Orphans" />

        <p class="service_name">Educate Orphans</p>
        <p class="service_des">Invest in orphan education to transform their lives. By providing quality learning, you
          equip them with essential skills
          and knowledge, increasing their career opportunities and helping them succeed.
        </p>

      </div>

      <div class="single_support">
        <img src="../../../assets/images/muharram/health_facility.svg" alt="Provide Health Facilities" />

        <p class="service_name">Provide Health Facilities</p>
        <p class="service_des">Ensure orphans have access to excellent health facilities, providing comprehensive
          medical care and regular check-ups.
          Your investment safeguards their well-being and helps them achieve their full potential.
        </p>
      </div>
    </div>
  </div>

</section>

<section class="howToSec">
  <div class="container">
    <h2>Other Ways to Donate</h2>
    <p class="howToDesc">You can directly deposit your donations into our official bank account or send them to our
      JazzCash account using the
      details below:</p>
    <div class="detailsWrapper">

      <div class="singleDetail">
        <img class="meezanBnk" src="assets/new-images/meezan-bank.svg" alt="Meezan Bank">
        <p><span>Bank:</span> Meezan Bank</p>
        <p><span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies Place</p>
        <p><span>Account Number:</span> 08220102632194</p>
        <p><span>IBAN:</span> PK98 MEZN 0008 2201 0263 2194</p>
        <p><span>Branch Code:</span> 0822</p>
        <p><span>Swift Code:</span> MEZNPKKAGRD</p>
      </div>
      <div class="middle-line-content">
        <span>OR</span>
      </div>
      <div class="singleDetail flex-grow">
        <img class="jazzcashLogo" src="assets/images/jazzcash-donation/jazzcash-logo.webp" alt="Jazz Cash Logo">
        <p><span>Till ID:</span></p>
        <div class="scan-numbers-id">
          <div class="scan-digits-row">
            <div class="digits-cols">
              <div class="digit-box">
                <span>9</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>3</span>
              </div>
              <div class="digit-box">
                <span>0</span>
              </div>
              <div class="digit-box">
                <span>4</span>
              </div>
            </div>
            <div class="digits-cols">
              <div class="digit-box">
                <span>6</span>
              </div>
              <div class="digit-box">
                <span>8</span>
              </div>
              <div class="digit-box">
                <span>1</span>
              </div>
            </div>
          </div>
        </div>
        <div class="qrcode-content">
          <img src="assets/images/jazzcash-donation/scan-qr-new.png" alt="Scan QR Code">
        </div>
        <p class="scanQRTxt"><span>Scan this QR Code</span></p>
        <!-- <p><span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies Place</p>
              <p><span>Account Number:</span> 4149300607</p>
              <p><span>IBAN:</span> PK09 NBPA 1531 0041 4930 0607</p>
              <p><span>Branch Code:</span> 1531</p>
              <p><span>Swift Code:</span> NBPAPKKA02i</p> -->
      </div>
    </div>
  </div>
</section>


<section class="confirmationSec">
  <div class="container">
    <h2>Confirmation</h2>
    <p>After completing the process, please Email or WhatsApp us a copy of the funds transfer receipt issued by your
      bank (email/screenshot) at <strong>donations@pakistansweethome.org.pk</strong> or <strong>+92 335
        1118477</strong>.</p>
  </div>
</section>

<section class="main_accord ">
  <div class="container">
    <h2>Frequently Asked Questions</h2>
    <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
      <div class="card show">
        <div class="card-head">
          <h3>When is Rabi al-Awwal 2024?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body">
          <section class="admissionApply">
            <div class="main_apply">
              <div class="apply_left">
                <ol class="noStyle">
                  <li>This year, the month of Rabi al-Awwal is expected to start on Thursday, September 5, 2024  (Rabi ul Awal 1446 AH), subject to the moon sighting.</li>
                </ol>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What are the correct spellings, Rabi ul-Awwal or Rabi al-Awwal?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>Rabi Al-Awwal is occasionally spelled as Rabi Ul-Awwal, but the accurate spelling is Rabi al-Awwal,
                  as ‘Al’ better
                  aligns with the Arabic script for the month.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Which date is 12 Rabi al-Awal?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>In Pakistan, 12 Rabi ul Awal 2024 falls on Tuesday, September 17, 2024. In Islam, numerous significant events are observed during this month.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What does Rabi al-Awwal mean?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>Rabi al-Awwal, also spelled as Rabi ul-Awwal, translates to "The First Spring" in Arabic. It is the
                  third month in the
                  Islamic lunar calendar and holds significant religious importance, particularly for the events related
                  to the life of
                  the Prophet Muhammad (peace be upon him).</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What to do in the month of Rabi al-Awwal?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>During Rabi al-Awwal, Muslims often engage in acts of devotion and reflection. Many focus on
                  studying the life and
                  teachings of the Prophet Muhammad (peace be upon him), attending lectures, and participating in
                  gatherings that honor
                  his Sunnah.

                  While it is not obligatory to perform specific acts of worship during this month, it is a time to
                  strengthen one’s
                  connection with the Prophet (peace be upon him) and follow his Sunnah.
                </li>
              </ol>
            </div>

          </section>
        </div>
      </div>
    </div>
  </div>
</section>