<app-federal-education-minister *ngIf="pageURL == 'federal-education-minister-announced-pkr-6-crore-for-orphans' "></app-federal-education-minister>
<app-golden-opportunity *ngIf="pageURL == 'golden-opportunity-for-orphans-nationwide' " ></app-golden-opportunity>
<app-government-of-pakistan-gifted *ngIf="pageURL == 'government-of-pakistan-gifted-four-toyota-hiace-vans' " ></app-government-of-pakistan-gifted>
<app-world-boxing-champion *ngIf="pageURL == 'world-boxing-champion-usman-wazir-to-establish-boxing-academy' "></app-world-boxing-champion>
<app-zamurrad-khan-inaugurates *ngIf="pageURL == 'zamurrad-khan-inaugurates-a-new-building' " ></app-zamurrad-khan-inaugurates>
<app-fun-filled-trip *ngIf="pageURL == 'fun-filled-trip-arranged-for-sargodha-campus-children' " ></app-fun-filled-trip>
<app-joyful-trip-on-the-double-decker *ngIf="pageURL == 'joyful-trip-on-the-double-decker-sightseeing-bus' " ></app-joyful-trip-on-the-double-decker>
<app-inauguration-of-new-building *ngIf="pageURL =='raja-pervaiz-ashraf-inaugurates-a-new-hostel-building-at-cadet-college'"></app-inauguration-of-new-building>
<app-a-heartwarming-sehri-event *ngIf="pageURL == 'a-heartwarming-sehri-event'"></app-a-heartwarming-sehri-event>
<app-annual-result-day *ngIf="pageURL == 'annual-result-day'"></app-annual-result-day>
<app-highlights-of-ramadan *ngIf="pageURL == 'highlights-of-ramadan-2024'"></app-highlights-of-ramadan>
<app-bridging-borders-with-kindness *ngIf="pageURL == 'bridging-borders-with-kindness'"></app-bridging-borders-with-kindness>
<app-empowering-orphans-through-education *ngIf="pageURL == 'empowering-orphans-through-education'"></app-empowering-orphans-through-education>