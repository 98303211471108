import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $, Swiper, window;

@Component({
  selector: 'app-admission-eight-class',
  templateUrl: './admission-eight-class.component.html',
  styleUrls: ['./admission-eight-class.component.css', '../admissions.component.css', '../sponsorship-program/sponsorship-program.component.css']
})
export class AdmissionEightClassComponent implements OnInit {
  public nextPage : any = true;
  
  constructor(
    private activatedRoute         : ActivatedRoute,
    private router                 : Router
  ) { 
    let url = this.activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
    localStorage.setItem('classApplying', 'preCadet');
    localStorage.removeItem('admissionLink');
  }

  routeCheck(url){

    if(url == '/cadet-college-admission'){
      this.router.navigate(['/cadet-college-admission-8th-class'])
    } 
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });
  }

  ngOnInit(): void {

    // if($(window).width() > 1270){
    //   var marginLeft =  ($(window).width() - 1240) / 2;
    //   console.log('marginLeft::',marginLeft);
    //   $('.bannerWrapper').css('margin-left', marginLeft - 15)
    // }

    $(".scroll-link").click(function (e) {
        
      e.preventDefault();
      var scrollTo = $(this).data("scroll-to");
      let headerHeight = $(".navbar").outerHeight();
      console.log(headerHeight);
      $('html, body').animate({
        scrollTop: $(scrollTo).offset().top - headerHeight
      }, 1000);
    });
    

  }
  

}
