import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { AppSettings, AuthenticationService, HelperService } from 'src/app/services/_services';

@Injectable({
  providedIn: 'root'
})
export class CurrencyApiService {

  // API url
  private currencyRatesSubject : BehaviorSubject<any>;
  public  currencyRates        : any = '';
  private currencyRatesS: BehaviorSubject<any> = new BehaviorSubject<any>(this.currencyRates);
  currencyRatesS$: Observable<any> = this.currencyRatesS.asObservable();

  constructor(
    private http            : HttpClient,
    private helperService   : HelperService,
  ) { }

  getCurrencyRates() {
    let url  = 'https://api.exchangerate-api.com/v4/latest/USD';
    // let url = 'https://v6.exchangerate-api.com/v6/65e2394643595edc0ceae760/latest/USD';

    this.helperService.httpGetRequests(url).then(resp => {
        if(resp.rates)
          this.currencyRates = resp.rates;

        this.currencyRatesS.next(this.currencyRates);

        localStorage.setItem('currencyRates', JSON.stringify(this.currencyRates));
    }).catch(error => {
        console.error("error: ",error);

        this.currencyRates = null;
        this.currencyRatesS.next(this.currencyRates);
        // this.submitted2 = false;
        // this.formResp = false;
    });
  }
}
