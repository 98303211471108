import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppSettings, HelperService, SEOService } from 'src/app/services/_services';
import { TtqTrackingService } from 'src/app/services/_services/ttq-tracking.service';
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-add-sponsor',
  templateUrl: './add-sponsor.component.html',
  styleUrls: ['./add-sponsor.component.css']
})
export class AddSponsorComponent implements OnInit, AfterViewInit {

  public pageType = 'sponsorship';
  public activeStep = 'your-sponsorships'; //your-sponsorships, contact-details, make-payment
  public sponsorshipDetail = {
                                pricePlan : null,
                                total     : 0,
                                contactDetails: null
                             }
  public currentUser;
  public showModal = false;
  public modalStep = 'signup'; //signup, verify-otp, login
  public signupEmail;

  public currencyCode = '$';
  public exchangeRate = 1;
  public isLocalServer = false;

  constructor(
    private helperService          : HelperService,
    private activatedRoute         : ActivatedRoute,
    private ttqTrackingService     : TtqTrackingService,
    private seoService: SEOService,
  ) {

    let url = this.activatedRoute.snapshot['_routerState'].url;
    if(url == '/add-sponsorship'){
      this.pageType ='sponsorship'
    } else {
      this.pageType ='membership'
    }


    if(window.location.origin.includes('localhost')  || window.location.origin.includes('192.168.19.133')){
      this.isLocalServer = true;
    }
    this.currentUser = localStorage.getItem('currentUser');
  }

  ngOnInit(): void {
    this.getGeoLocs();
    this.setSEO_Tags("Pakistan Sweet Home Membership: Make a Difference","Get a membership of Pakistan Sweet Home and support our mission to improve the lives of orphans through love and education.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      $('#fb-root').hide();
    }, 1000);
  }

  getGeoLocs() {
    const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
      this.helperService.httpGetRequests(url).then(resp => {
            console.log('geo resp::', resp);
            if(resp.geoplugin_currencySymbol == "₨"){
              this.currencyCode = 'PKR';
            } else {
              this.currencyCode = resp.geoplugin_currencySymbol;
            }

            // console.log('resp.geoplugin_currencySymbol::', resp.geoplugin_currencySymbol);

            if( Number(resp.geoplugin_currencyConverter) ){
              this.exchangeRate = resp.geoplugin_currencyConverter;
            } else {
              this.exchangeRate = 1;
            }


      }).catch(err => {
        console.log('error', err)
      })
  }

  identifyUser(email, phone_number, id) {
    this.ttqTrackingService.identify(email, phone_number, id);
  }

  updateSponsorshipDetails(sponsorshipDetail){
    setTimeout(() => {
      this.sponsorshipDetail.total = 0;
      for (let i = 0; i < sponsorshipDetail.length; i++) {
        const element = sponsorshipDetail[i];
        this.sponsorshipDetail.total += Number(element.price);
      }
      this.sponsorshipDetail.pricePlan = sponsorshipDetail;
    }, 1);
  }

  changeStep(stepName){
    if(stepName == 'your-sponsorships'){
      this.activeStep = stepName;
    } else if(stepName == 'contact-details') {
      this.currentUser = localStorage.getItem('currentUser');
      if(!this.currentUser){
        this.showModal = true;
        this.fixBody();
      } else {
        this.activeStep = stepName;
        this.showModal = false;
        this.unfixBody();
      }
    } else if(stepName == 'make-payment'){
      if(this.pageType == 'sponsorship'){
        this.makePayment();
      } else {
        this.membershipPayment();
      }
    }


  }

  fixBody(){
    $('body').css('height','100vh');
    $('body').css('overflow','hidden');
  }
  unfixBody(){
    $('body').css('height','unset');
    $('body').css('overflow','visible');
  }

  hideModals(){
    this.showModal = false;
    this.unfixBody();
  }

  changeModalStep(stepName){
    this.modalStep = stepName;
  }

  changeSignupEmail(email){
    this.signupEmail = email;
    console.log('this.signupEmail::', this.signupEmail);
  }

  updateContactDetails(details){
    this.sponsorshipDetail.contactDetails = details
  }

  makePayment(){
    
    var children = [];
    var otherDonation;
    var duration;

    for (let i = 0; i < this.sponsorshipDetail.pricePlan.length; i++) {
      var childObj = {
        id            : '',
        amount        : '',
        timePeriod    : '',
        name    : '',
      }
      const element = this.sponsorshipDetail.pricePlan[i];
      if(element.type == 'Child Sponsorship'){
        childObj.id = element.id;
        childObj.amount = element.price;
        childObj.timePeriod = element.timePeriod == 'monthly' ? 'month' : 'year';
        childObj.name = element.name;
        children.push(childObj);
        duration = element.timePeriod;
      } else {
        otherDonation = element.price
      }
    }

    let urlNow = window.location.origin;

    this.identifyUser(this.sponsorshipDetail.contactDetails.code + ' ' + this.sponsorshipDetail.contactDetails.number, this.sponsorshipDetail.contactDetails.email, this.sponsorshipDetail.contactDetails.ID)
    this.ttqTrackingService.track('InitiateCheckout', this.sponsorshipDetail.total, 'USD', '345', 'Sponsor', 'Sponsorship', this.sponsorshipDetail.total);

    let data = {
      'children'      : children,
      'user_id'       : this.sponsorshipDetail.contactDetails.ID,
      'success_url'   : urlNow + '/thankyou-sponsor',
      'amount'        : this.sponsorshipDetail.total * 100,
      'otherDonation' : otherDonation,
      'duration'      : duration,
      'env'           : this.isLocalServer ? 'test' : 'live'
    }

    console.log('data::',data);

    let url  = AppSettings.BACK_ENDPOINT + 'checkout-session';

    this.helperService.httpPostRequests(url, data).then(resp => {
      localStorage.removeItem('orphan');
      localStorage.removeItem('moreChildren');
      window.location.href = resp.session.url;
    }).catch(error => {
        Swal.fire('Something went wrong!', 'Try again after some time', 'error');
        console.error("error: ", error);
    });

  }

  membershipPayment(){

    let urlNow = window.location.origin;

    let data = {
      'user_id'       : this.sponsorshipDetail.contactDetails.ID,
      'amount'        : this.sponsorshipDetail.pricePlan[0]['price'] * 100,
      'env'           : this.isLocalServer ? 'test' : 'live',
      'success_url'   : urlNow + '/thankyou-member',
    }

    let url  = AppSettings.BACK_ENDPOINT + 'membership';

    this.helperService.httpPostRequests(url, data).then(resp => {
      localStorage.removeItem('orphan');
      localStorage.removeItem('moreChildren');
      window.location.href = resp.session.url;
    }).catch(error => {
        Swal.fire('Something went wrong!', 'Try again after some time', 'error');
        console.error("error: ", error);
    });

  }

}
