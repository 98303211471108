<section class="main_gallery newBlueSec">
  <div class="container">
    <div class="projectHead">
      <p>OUR GALLERY</p>
      <h1>See the Impact of your donations</h1>
    </div>
    <!-- <div class="tab_buttons">

      <a href="javascript:;" [ngClass]="{'active': selectedDiv === 'all'}" (click)="showDiv('all')">all</a>
      <a href="javascript:;" [ngClass]="{'active': selectedDiv === 'politicians'}"
        (click)="showDiv('politicians')">RECREATIONAL ACTIVITIES</a>
      <a href="javascript:;" [ngClass]="{'active': selectedDiv === 'celebrities'}" (click)="showDiv('celebrities')">Life
        at psh</a>
      <a href="javascript:;" [ngClass]="{'active': selectedDiv === 'diplomats'}" (click)="showDiv('diplomats')">blood
        camps</a>
      <a href="javascript:;" [ngClass]="{'active': selectedDiv === 'sportsmen'}"
        (click)="showDiv('sportsmen')">development & training</a>
    </div> -->

    <div class="genericFilterTabs">
      <h2>
        <button
          class="filtersBtn"
          [ngClass]="{ active: selectedDiv === 'all' }"
          (click)="showDiv('all')"
        >
          All
        </button>
      </h2>
      <h2>
        <button
          class="filtersBtn"
          [ngClass]="{ active: selectedDiv === 'politicians' }"
          (click)="showDiv('politicians')"
        >
          Recreational Activities
        </button>
      </h2>
      <h2>
        <button
          class="filtersBtn"
          [ngClass]="{ active: selectedDiv === 'celebrities' }"
          (click)="showDiv('celebrities')"
        >
          Life at PSH
        </button>
      </h2>
      <h2>
        <button
          class="filtersBtn"
          [ngClass]="{ active: selectedDiv === 'diplomats' }"
          (click)="showDiv('diplomats')"
        >
          Blood Camps
        </button>
      </h2>
      <h2>
        <button
          class="filtersBtn"
          [ngClass]="{ active: selectedDiv === 'sportsmen' }"
          (click)="showDiv('sportsmen')"
        >
          Development & Training
        </button>
      </h2>
    </div>
  </div>
  <div class="containerFluid">
    <div class="cellsWrapper">
      <div class="all-cel">
        <ng-container *ngFor="let image of imagesArray">
          <div
            class="single_cel"
            *ngIf="image.type == 'politicians' && image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'politicians' ? 'show' : ''
            "
          >
            <img [src]="image.src" [alt]="image.alt" />
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'politicians' && !image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'politicians' ? 'show' : ''
            "
          >
            <div class="skelImg"></div>
          </div>

          <div
            class="single_cel"
            *ngIf="image.type == 'celebrities' && image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'celebrities' ? 'show' : ''
            "
          >
            <img [src]="image.src" alt="" />
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'celebrities' && !image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'celebrities' ? 'show' : ''
            "
          >
            <div class="skelImg"></div>
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'diplomats' && image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'diplomats' ? 'show' : ''
            "
          >
            <img [src]="image.src" alt="" />
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'diplomats' && !image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'diplomats' ? 'show' : ''
            "
          >
            <div class="skelImg"></div>
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'sportsmen' && image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'sportsmen' ? 'show' : ''
            "
          >
            <img [src]="image.src" alt="" />
          </div>
          <div
            class="single_cel"
            *ngIf="image.type == 'sportsmen' && !image.imgLoaded"
            [ngClass]="
              selectedDiv == 'all' || selectedDiv == 'sportsmen' ? 'show' : ''
            "
          >
            <div class="skelImg"></div>
          </div>
        </ng-container>
      </div>
      <!-- <div class="swiper-pagination story"></div> -->
    </div>
  </div>
</section>
