<section class="signInPages" *ngIf="!modalView">
    <div class="aj_mob">
        <img src="assets/images/aj_signin.png" alt="sign in">
    </div>
    <div class="pageWrapper">
        <h1>Sign Up</h1>
        <p class="membersCountText">You’re about to join 241,873 incredible members.</p>
        <a href="javascript:;" class="facebookBtn" (click)="signInWithFB()">SIGN UP WITH FACEBOOK</a>
        <div class="orWrapper">
            <div class="lineWhite"></div>
            <p class="orText">OR</p>
            <div class="lineWhite"></div>            
        </div>
        <form class="signInForm SignPageForm">
            <p class="serverError" *ngIf="errorMsg">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                {{errorMsg}}
            </p>
            <div class="nameInputWrapper inputWrapper normalInputWrapper">
                <label for="firstName">Name</label>
                <div class="doubleInput">
                    <div class="singleInput">
                        <input type="text" (keyup)="removeError($event.target)" id="firstName" [(ngModel)] = "signUp.firstName" placeholder="First Name" [ngModelOptions]="{standalone: true}">
                        <p class="error">
                            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                            Please Enter Valid First Name
                        </p>
                    </div>
                    <div class="singleInput">
                        <input type="text" (keyup)="removeError($event.target)" id="lastName" [(ngModel)] = "signUp.lastName" placeholder="Last Name" [ngModelOptions]="{standalone: true}">
                        <p class="error">
                            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                            Please Enter Valid Last Name
                        </p>
                    </div>
                </div>
            </div>
            <div class="inputWrapper normalInputWrapper">
                <label>Email</label>
                <input id="email" type="text" (keyup)="removeError($event.target)" [(ngModel)]="signUp.email" placeholder="Email" [ngModelOptions]="{standalone: true}" />
                <p class="error">
                    <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                    Please Enter Valid Email
                </p>
            </div>
            <div class="inputWrapper normalInputWrapper">
                <label>PASSWORD</label>
                <input id="password" (keyup)="removeError($event.target)" [attr.type]="showPass ? 'text' : 'password'" [(ngModel)]="signUp.password" placeholder="Password" [ngModelOptions]="{standalone: true}" />
                <a href="javascript:;" (click)="showPass = !showPass" class="showPassBtn">{{showPass ? 'Hide' : 'Show'}}</a>
                <p class="error">
                    <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                    Please Enter At Least 5 Digits
                </p>
            </div>
            <div class="checkBoxWrapper">
                <input id="adultCheck" type="checkbox" [(ngModel)]="signUp.adult" [ngModelOptions]="{standalone: true}" [attr.checked]="signUp.adult">
                <label class="adultCheckLabel" for="adultCheck" >
                    <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.06977 1.67383C8.83804 1.66808 8.59164 1.76366 8.39877 1.95665L4.01405 6.34553L2.61228 4.9423C2.27043 4.59995 1.75673 4.56316 1.46022 4.85943L0.829184 5.49095C0.533284 5.78734 0.570318 6.30226 0.912051 6.64448L3.48006 9.21445C3.76887 9.50436 4.28086 9.48273 4.5489 9.21445L10.0991 3.65823C10.441 3.31601 10.4785 2.8017 10.182 2.50543L9.55109 1.8733C9.42116 1.74398 9.25005 1.67823 9.06977 1.67383Z" fill="white"/>
                    </svg>                        
                </label>
                <p class="labelText">I’m over 16 or have parental consent to join</p>
            </div>
            <button *ngIf="!loading" class="formSumitBtn" (click)="newUser()">SIGN UP</button>
            <div *ngIf="loading" class="loader">
                <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
            </div>
        </form> 
        <a href="javascript:;" [routerLink]="siUrl" class="alternateJoinBtn">ALREADY HAVE AN ACCOUNT? SIGN IN</a>
    </div>
</section>

<div class="confirmationPopup" [ngClass]="modalView ? 'modalWrapper mwUnset' : '' " *ngIf="OTPPopup || modalStep == 'verify-otp' ">
    <div class="overlay" (click)="modalView ? changeParentStep('signup') : hideOTPModal()"></div>
    <div class="verifyModal">
        <p class="serverError" *ngIf="OTPerror">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
            {{OTPerror}}
        </p>
        <img src="/assets/new-images/otp-gif.webp" class="modalTopGif" alt="sending gif">
        <p class="verifyHead">Verify its you.</p>
        <p class="verifyText">
            We have sent a verification code to {{signUp.email}}, Please check your inbox and enter the 4-digit code below.
        </p>
        <input (input)="restrictOtpLength($event)" id="verificationCode" name="verificationCode" type="number" [(ngModel)]="OTP" placeholder="Enter 4-digits code">
        <button *ngIf="!OTPLoading" class="formSumitBtn" (click)="verifyCode()">Verify Code</button>
        <div class="loader OTP" *ngIf="OTPLoading">
            <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
        </div>        
        <p class="noEmailText">Didn’t receive an email? 
            <a class="tryAgainBtn" href="javascript:;" *ngIf="!OTPTimer" (click)="sendOTP()" >Try again</a> 
            <span class="tryAgainBtn" *ngIf="OTPTimer">Try again in {{OTPTimer}}</span> 
        </p>
    </div>
</div>

<div class="modalWrapper" *ngIf="modalView && modalStep == 'signup' ">
    <h2>Create an account</h2>
    <form class="signInForm SignPageForm">
        <p class="serverError" *ngIf="errorMsg">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
            {{errorMsg}}
        </p>
        <div class="nameInputWrapper inputWrapper normalInputWrapper">
            <label for="firstName">Name</label>
            <div class="doubleInput">
                <div class="singleInput">
                    <input type="text" (keyup)="removeError($event.target)" id="firstName" [(ngModel)] = "signUp.firstName" placeholder="First Name" [ngModelOptions]="{standalone: true}">
                    <p class="error">
                        <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                        Please Enter Valid First Name
                    </p>
                </div>
                <div class="singleInput">
                    <input type="text" (keyup)="removeError($event.target)" id="lastName" [(ngModel)] = "signUp.lastName" placeholder="Last Name" [ngModelOptions]="{standalone: true}">
                    <p class="error">
                        <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                        Please Enter Valid Last Name
                    </p>
                </div>
            </div>
        </div>
        <div class="inputWrapper normalInputWrapper">
            <label>Email</label>
            <input id="email" type="text" (keyup)="removeError($event.target)" [(ngModel)]="signUp.email" placeholder="Email" [ngModelOptions]="{standalone: true}" />
            <p class="error">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                Please Enter Valid Email
            </p>
        </div>
        <div class="inputWrapper normalInputWrapper">
            <label>PASSWORD</label>
            <input id="password" (keyup)="removeError($event.target)" [attr.type]="showPass ? 'text' : 'password'" [(ngModel)]="signUp.password" placeholder="Password" [ngModelOptions]="{standalone: true}" />
            <a href="javascript:;" (click)="showPass = !showPass" class="showPassBtn">{{showPass ? 'Hide' : 'Show'}}</a>
            <p class="error">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                Please Enter Valid Password
            </p>
        </div>
        <button *ngIf="!loading" class="formSumitBtn" (click)="newUser()">Create account</button>
        <div *ngIf="loading" class="loader">
            <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
        </div>
        <p class="modalAlreadyText">Already have an account? <a href="javascript:;" (click)="changeParentStep('login')" >Login to Existing Account</a> </p>
    </form> 
</div>