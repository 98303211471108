<section class="main_head">
  <div class="container">
    <div class="with_us">
      <p class="grey_text">get involved</p>
      <h2>Better Together, Better with YOU!</h2>
      <p>By sharing your time, talent, and energy, you make a direct and meaningful impact on the lives of those we
        serve. Whether it’s working directly with our children or working behind the scenes, you take part in a rich,
        rewarding and fun experience.</p>
      <img src="assets/images/involved_main.png" alt="">
    </div>
  </div>
</section>
<div class="container">
  <div class="projectTabsBtn">

    <!-- <a [ngClass]="activeProjectType == 'campuses' ? 'active' : '' " (click)="changeProject('campuses')"
      href="javascript:;">volunteer</a>
    <a [ngClass]="activeProjectType == 'bloodBank' ? 'active' : '' " (click)="changeProject('bloodBank')"
      href="javascript:;">campus tour</a>
    <a [ngClass]="activeProjectType == 'upcoming' ? 'active' : '' " (click)="changeProject('upcoming')"
      href="javascript:;">ORGANIZE AN EVENT</a> -->

    <a [ngClass]="{'active': selectedDiv === 1}" (click)="changeProject(1)">volunteer</a>
    <a [ngClass]="{'active': selectedDiv === 2}" (click)="changeProject(2)">campus tour</a>
    <a [ngClass]="{'active': selectedDiv === 3}" (click)="changeProject(3)">ORGANIZE AN EVENT</a>

  </div>
</div>
<div [class.d-none]="selectedDiv != 1">
  <section class="volunteer_section">
    <div class="container">
      <div class="volunteer_head">
        <h2>Ready to Join the Pakistan Sweet Family?</h2>
      </div>
      <div class="form_head">
        <p>Pakistan Sweet Home Volunteer Program</p>
      </div>
      <form class="form_section" #volunteerForm="ngForm">
        <div class="main_form">
          <div class="form_left">
            <div class="gb_inputGroup">
              <input type="text" class="gb_input" name="full_name" id="full_name" (keyup)="removeInvalid('#full_name')" [(ngModel)]="volunteer.full_name" placeholder="Full Name">
              <label for="gb_input" class="gb_inputLabel">Full Name</label>
            </div>
            <div class="gb_inputGroup">
              <input type="text" class="gb_input" name="emailvol" id="emailvol" (keyup)="removeInvalid('#emailvol')" [(ngModel)]="volunteer.emailvol" placeholder="Email">
              <label for="gb_input" class="gb_inputLabel">Email</label>
            </div>
            <div class="inputWrapper ">
              <div class="flagsWrapper">
                <!-- <label class="coutryFlags" >
                  <div (click)="selectedFlag = flag; selectedCountry = countryOptions[i]" *ngFor="let flag of flagOptions;index as i" class="singleFlag">
                    <img [src]=" 'https://img.mobiscroll.com/demos/flags/' + flag + '.png' " alt="country">
                    <p>{{countryOptions[i]}}</p>
                  </div>
                </label> -->
                <div class="selectedFlag" *ngIf="selectedFlag">
                  <img [src]="'https://img.mobiscroll.com/demos/flags/' + selectedFlag + '.png'" alt="">
                  <!-- <svg width="11" height="6" viewBox="0 0 11 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z" fill="#5E5E5E"/>
                  </svg>                     -->
                </div>
            </div>
                <div class="gb_inputGroup mobilenuber">
                  <input type="number" class="gb_input" id="phone_numbervol" name="phone_numbervol" (keyup)="removeInvalid('#phone_numbervol')" [(ngModel)]="volunteer.phone_numbervol" placeholder="(201) 555-0123" />
                  <label for="gb_input"  class="gb_inputLabel">Phone Number </label>
                </div>
            </div>
            <div class="gb_inputGroup">
              <!-- <input type="text" name="" id="" placeholder="City"> -->
              <input type="text" class="gb_input" id="city" name="city" (keyup)="removeInvalid('#city')" [(ngModel)]="volunteer.city"  placeholder="City">
              <label for="gb_input"  class="gb_inputLabel">City</label>
            </div>

          </div>
          <div class="form_right">
            
            <div class="gb_inputGroup">
              <!-- <input type="text" name="" id="" placeholder="City"> -->
                <input type="text" id="skill_talent" name="skill_talent" (keyup)="removeInvalid('#skill_talent')" class="gb_input" [(ngModel)]="volunteer.skill_talent" placeholder="Any special skills/talent">
                <label for="gb_input" id="city" name="city" class="gb_inputLabel">Any special skills/talent</label>
              </div>
              <div class="gb_inputGroup">
                <input type="text" id="previous_volunteer_experiencevol" (keyup)="removeInvalid('#previous_volunteer_experiencevol')" [(ngModel)]="volunteer.previous_volunteer_experiencevol" name="previous_volunteer_experiencevol" class="gb_input" placeholder="Any previous volunteer experience">
                <label for="gb_input" class="gb_inputLabel">Any previous volunteer experience</label>
              </div>  
            <ng-select id="information_source" 
                       name="information_source" 
                       (change)="removeInvaliid('#information_source')" 
                       (keyup)="removeInvaliid('#information_source')" 
                       class="admissionSelect involve" 
                       [items]="programOptions" 
                       [(ngModel)]="volunteer.information_source" 
                       (change)="checkOtherValue()"
                       placeholder='How did you hear about our program?' 
                       name="program" 
                       [searchable]="false" 
                       [clearable]="false">
            </ng-select>
            <div class="aj_notificaton">
              <!-- <input type="text" name="" id="" placeholder="City"> -->
                <textarea name="" id="" cols="30" rows="10" placeholder="Add Details"></textarea>
                <!-- <label for="gb_input" class="gb_inputLabel">Any special skills/talent</label> -->
              </div>
            <ng-select id="area_of_interest" 
                       name="area_of_interest" 
                       class="admissionSelect involve" 
                       [items]="interestOptions" 
                       (change)="removeInvaliid('#area_of_interest')" 
                       [(ngModel)]="volunteer.area_of_interest"
                       placeholder='Area of Interest' 
                       name="program" 
                       [searchable]="false" 
                       [clearable]="false">
            </ng-select>

        
          </div>
        </div>
        <a class="submit_btn" (click)="subscribevolunteer()" href="javascript:;">Submit</a>
        <p class="volunteer_notification">Thank you for your interest in volunteering with Pakistan Sweet Home. Our team will review your application and contact you shortly with potential next steps. We look forward to working with you!</p>
      </form>

    </div>
  </section>
</div>
<div [class.d-none]="selectedDiv != 2">
  <section class="our_tour">
    <div class="container">
      <div class="tour_head">
        <h2>Request a Tour</h2>
      </div>
      <div class="main_tour">
        <div class="tour_left">
          <div _ngcontent-ohg-c114="" class="play-icon">
            <a _ngcontent-ohg-c114="" data-fancybox="testimonial-video" href="https://youtu.be/i__-qSlEHeI">
              <img _ngcontent-ohg-c114="" src="assets/images/tour_img.png" alt="hero background image"><span
                _ngcontent-ohg-c114="">
                <svg width="72" height="50" viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M69.6734 7.9061C68.8623 4.89066 66.4849 2.51362 63.4699 1.70166C57.9618 0.19458 35.9292 0.19458 35.9292 0.19458C35.9292 0.19458 13.8975 0.19458 8.38942 1.64452C5.43241 2.45562 2.99694 4.89109 2.18584 7.9061C0.736328 13.4137 0.736328 24.8358 0.736328 24.8358C0.736328 24.8358 0.736328 36.3154 2.18584 41.7655C2.9978 44.7805 5.37441 47.1575 8.38985 47.9695C13.9555 49.477 35.9301 49.477 35.9301 49.477C35.9301 49.477 57.9618 49.477 63.4699 48.0271C66.4853 47.2155 68.8623 44.8385 69.6743 41.8235C71.1234 36.3154 71.1234 24.8938 71.1234 24.8938C71.1234 24.8938 71.1814 13.4137 69.6734 7.9061ZM28.8902 35.5506L28.9145 14.2837L47.2229 24.42L28.8902 35.5506Z"
                    fill="#00AAE9" fill-opacity="0.8" />
                  <path d="M28.8906 35.5513V14.2664L47.2234 24.42L28.8906 35.5513Z" fill="white" fill-opacity="0.8" />
                </svg>

              </span>
            </a>
          </div>
        </div>
        <div class="tour_right">
          <p>Are you interested in viewing our campus? We are
            now booking slots.</p>
          <p>Public visits are available all 7 days from 9.30 am - 7.00 pm.</p>
          <p>To schedule your visit:</p>
          <div class="gb_inputGroup">
               <input type="text" id="previous_volunteer_experience" name="previous_volunteer_experience" class="gb_input" (keyup)="removeInvalid('#previous_volunteer_experience')" [(ngModel)]="tour.previous_volunteer_experience" placeholder="Any previous volunteer experience">
              <label for="gb_input" class="gb_inputLabel">Any previous volunteer experience</label>
            </div>
            <div class="gb_inputGroup">
              <input type="number" id="phone_number" name="phone_number"  placeholder="Phone Number" (keyup)="removeInvalid('#phone_number')" [(ngModel)]="tour.phone_number" class="gb_input">
              <label for="gb_input" class="gb_inputLabel">Phone Number</label>
            </div>
            <div class="gb_inputGroup">
              <input type="text" id="email" name="email" placeholder="Email" (keyup)="removeInvalid('#email')" [(ngModel)]="tour.email" class="gb_input">
              <label for="gb_input" class="gb_inputLabel">Email</label>
          </div>
          <div class="gb_inputGroup">
            <textarea id="message" name="message" (keyup)="removeInvalid('#message')"  class="gb_input" cols="30" rows="10" [(ngModel)]="tour.message" placeholder="Message"></textarea>
            <label for="gb_input" class="gb_inputLabel">Message</label>
          </div>
              
          <a href="javascript:;" (click)="subscribetour()">
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g clip-path="url(#clip0_1390_1750)">
                <path
                  d="M16 0L0 9.00001L5.11329 10.8938L13 3.50001L7.00112 11.5931L7.006 11.5949L7.00002 11.5931V16L9.86753 12.6546L13.5 14L16 0Z"
                  fill="white" />
              </g>
              <defs>
                <clipPath id="clip0_1390_1750">
                  <rect width="16" height="16" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Send
          </a>
          <span class="tour_notification">Your request for our campus tour has been received. Our team will review your message, and will reach out soon via phone/email with further confirmation on the date/day of the visit. We look forward to hosting you!</span>
        </div>
      </div>
    </div>
  </section>
</div>
<div [class.d-none]="selectedDiv != 3">
  <section class="our_events">
    <div class="container">
      <div class="our_stories">
        <h2>Partnership Stories</h2>
        <div class="stotyparent subSwiper">
          <div class="main_stories organizationEvent">
            <div class="single_story">
              <img src="assets/images/story_one.png" alt="">
              <p>Lunch Party Sponsored by
                Cheezious</p>
            </div>
            <div class="single_story">
              <img src="assets/images/story_two.png" alt="">
              <p>Cricket Tournament Organized by High Courts Bar Association</p>
            </div>
            <div class="single_story">
              <img src="assets/images/story_three.png" alt="">
              <p>English Access Program in
                collaboration with US Embassy</p>
            </div>
          </div>
        </div>
        <div class="mpwRev-pagination"></div>
      </div>
    </div>
  </section>
  <section class="our_partner">
    <div class="container">
      <div class="main_partner">
        <div class="partner_data">
          <h2>Partner With Us</h2>
          <p>Drop a message if you would like to know more about a partnership with us</p>
          <form class="rightform">
            <p class="serverError" *ngIf="massegeError">
              <img src="/assets/new-images/error-sign-new.png" alt="error sign">
              {{massegeError}}
            </p>
            <p class="serverSuccess" *ngIf="massegeError == '' && organizeFormValid">
              <img src="/assets/new-images/successTick.png" alt="success sign">
              We appreciate your interest in working together. Our team will review your message and will be in contact shortly to discuss the next steps. We are excited about the opportunity to collaborate!
            </p>
            <div class="gb_inputGroup">
              <input type="text" name="fullName" [(ngModel)]="nameField" placeholder="Full Name" class="gb_input">
              <label for="gb_input" class="gb_inputLabel">Full Name</label>
            </div>
            <div class="gb_inputGroup">
            <input type="email" name="email" [(ngModel)]="emailField" placeholder="Email Address" class="gb_input">
            <label for="gb_input" class="gb_inputLabel">Email Address</label>
            </div>
            <div class="gb_inputGroup">
            <textarea name="message" id="" cols="30" rows="10" value="" placeholder="Message"
              [(ngModel)]="massegeField" class="gb_input"></textarea>
            <label for="gb_input" class="gb_inputLabel">Message</label>
            </div>

            <button href="" (click)="subscribeNewsLetter()">
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_1390_1750)">
                  <path
                    d="M16 0L0 9.00001L5.11329 10.8938L13 3.50001L7.00112 11.5931L7.006 11.5949L7.00002 11.5931V16L9.86753 12.6546L13.5 14L16 0Z"
                    fill="white" />
                </g>
                <defs>
                  <clipPath id="clip0_1390_1750">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
              Send
            </button>
          </form>

        </div>
      </div>
    </div>
  </section>
</div>


