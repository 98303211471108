import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
import {
  HttpClient
} from '@angular/common/http';
import {
  AuthenticationService
} from 'src/app/services/_services';
declare var $, Swiper, window;

@Component({
  selector: 'app-test-page',
  templateUrl: './test-page.component.html',
  styleUrls: ['./test-page.component.css']
})
export class TestPageComponent implements OnInit {

  @ViewChild('wistiastar') wistiastar: any;
  @ViewChild('wistiaimran') wistiaimran: any;
  @ViewChild('wistiasohail') wistiasohail: any;
  @ViewChild('wistiahale') wistiahale: any;
  @ViewChild('wistiamisbah') wistiamisbah: any;
  @ViewChild('wistiaalvin') wistiaalvin: any;
  @ViewChild('wistiazainab') wistiazainab: any;
  @ViewChild('wistiagulshan') wistiagulshan: any;
  @ViewChild('wistiafatima') wistiafatima: any;
  @ViewChild('wistiamusa') wistiamusa: any;
  @ViewChild('wistiajunaidbhai') wistiajunaidbhai: any;

  public vistiashared: any;
  public DonateForm: any = 'form';
  public btnTransparency: any = true;
  public showVidz: any = true;
  public currentUser: any;
  public authSub: any;

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
  ) {
    
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
          this.currentUser = user;
          console.log("--- this.currentUser: ", this.currentUser);
        } else {
          this.currentUser = null;
        }
      });
    }
  }




  ngOnInit(): void {
 
    let self = this;

    var storySlider = new Swiper(".aj_swiper.story", {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: false,

      pagination: {
        el: ".story.swiper-pagination",
      },
      breakpoints: {
        360: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: false,
        },
        575: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 33,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 33,
        },
        1170: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: ".story.swiper-button-next",
        prevEl: ".story.swiper-button-prev",
      },
    });
    var ourWorkSlider = new Swiper(".aj_swiper.workSwiper", {
      slidesPerView: 1,
      spaceBetween: 30,
      centeredSlides: false,
      pagination: {
        el: ".work.swiper-pagination",
      },
      breakpoints: {
        360: {
          slidesPerView: 1,
          spaceBetween: 20,
          centeredSlides: false,
        },
        575: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 33,
          centeredSlides: false,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 33,
        },
        1170: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
      },
      navigation: {
        nextEl: ".work-next",
        prevEl: ".work-prev",
      },
    });
    ourWorkSlider.on('slideChange', function () {
      self.initializeVidz();
      // console.log("Wistia:: ", window.Wistia);

      // for (let index = 0; index < window.wistiaEmbeds.length; index++) {
      //   var vid = window.wistiaEmbeds[index];
      //   var video = window.Wistia.api("m2pc0yyiqr");
      //   console.log("window.wistiaEmbeds:: ", window.wistiaEmbeds);
      //   console.log("vid.uuid:: ", vid.uuid);
      //   console.log("video:: ", video);
      // }

    });
    storySlider.on('slideChange', function () {
      self.initializeVidz();
    });

    var heroSlider = new Swiper(".hero_swiper", {
      slidesPerView: 1,
      spaceBetween: 40,
      centeredSlides: true,
      effect: "fade",
      pagination: {
        el: ".swiper-pagination.hero",
      },
      breakpoints: {
        360: {
          slidesPerView: 1,
          spaceBetween: 20,
        },
        767: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        1024: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        1170: {
          slidesPerView: 1,
          spaceBetween: 40,
        },
      },
      navigation: {
        nextEl: ".hero-next",
        prevEl: ".hero-prev",
      },
    });


    var secPosition = $('.inventoryRecordSec').position().top;
    var windowHeight = $(window).height() / 2;

    var scrolled = 0;

    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll + windowHeight > secPosition) {

        $('.recordValue').each(function () {
          var originalText = $(this).attr('data-rel2');
          var extraText = $(this).attr('data-rel');
          var value = parseInt($(this).text());
          var x = 1;
          var singlejump = value / 50;
          var jump: any = singlejump;
          var element = this;

          if (!scrolled) {
            const counter = setInterval(function () {
              $(element).text(parseInt(jump) + extraText);
              x++;
              jump = singlejump * x;
              if (jump > value) {
                if(originalText){
                  $(element).text(originalText);
                }
                clearInterval(counter);
              }
            }, 50);
          }
        })
        scrolled = 1;

      }
    })

  setTimeout(() => {
    $('#bannerVid').click();
  }, 3000);


  }


  ngAfterViewInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/unrsgsu6sk.jsonp";
    this.wistiastar.nativeElement.prepend(s);
    this.vistiashared = document.createElement("script");
    this.vistiashared.type = "text/javascript";
    this.vistiashared.src = "https://fast.wistia.com/assets/external/E-v1.js";
    this.wistiastar.nativeElement.prepend(this.vistiashared);

    this.initializeVidz();
    
  }


  initializeVidz() {
    this.showVidz = false;
    setTimeout(() => {
      this.showVidz = true;
    }, 200);

    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/m2pc0yyiqr.jsonp";
    this.wistiaimran.nativeElement.prepend(s);
    this.wistiaimran.nativeElement.prepend(this.vistiashared);



    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/qiuk7h36ql.jsonp";
    this.wistiasohail.nativeElement.prepend(s);
    this.wistiasohail.nativeElement.prepend(this.vistiashared);



    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/8a98ln3vle.jsonp";
    this.wistiahale.nativeElement.prepend(s);
    this.wistiahale.nativeElement.prepend(this.vistiashared);



    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/74vczs02nw.jsonp";
    this.wistiamisbah.nativeElement.prepend(s);
    this.wistiamisbah.nativeElement.prepend(this.vistiashared);



    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/74vczs02nw.jsonp";
    this.wistiaalvin.nativeElement.prepend(s);
    this.wistiaalvin.nativeElement.prepend(this.vistiashared);


    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/hioyeo346u.jsonp";
    this.wistiazainab.nativeElement.prepend(s);
    this.wistiazainab.nativeElement.prepend(this.vistiashared);


    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/wvjlnys51w.jsonp";
    this.wistiagulshan.nativeElement.prepend(s);
    this.wistiagulshan.nativeElement.prepend(this.vistiashared);


    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/zuo94c9r58.jsonp";
    this.wistiafatima.nativeElement.prepend(s);
    this.wistiafatima.nativeElement.prepend(this.vistiashared);


    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/2xdueh0gf7.jsonp";
    this.wistiamusa.nativeElement.prepend(s);
    this.wistiamusa.nativeElement.prepend(this.vistiashared);


    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/1xabnun6zf.jsonp";
    this.wistiajunaidbhai.nativeElement.prepend(s);
    this.wistiajunaidbhai.nativeElement.prepend(this.vistiashared);
  }


}
