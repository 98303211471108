import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
@Component({
  selector: 'app-muharram-modal',
  templateUrl: './muharram-modal.component.html',
  styleUrls: ['./muharram-modal.component.css'],
})
export class MuharramModalComponent implements OnInit {
  public hideModal = false;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  hideModalFunc() {
    localStorage.setItem('muharramModalShowed', '2');
    this.hideModal = true;
  }

  donateInMoharram() {
    localStorage.setItem('muharramModalShowed', '1');
    this.hideModal = true;
    this.router.navigate(['/give-charity-in-rabi-awwal-2024']);
  }
}
