import { Component, Input, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-sponsor-faqs',
  templateUrl: './sponsor-faqs.component.html',
  styleUrls: ['./sponsor-faqs.component.css']
})
export class SponsorFaqsComponent implements OnInit {

  @Input() public  orphanType : any;

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.card .card-body:first-child').show();
      $('.card-head').click(function () {

        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).parent().addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });
  }

}
