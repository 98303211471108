<section class="banner_section">
  <div class="containerFluid">
    <div class="bannerWrapper">
      <div class="bannerContent">
        <h1>PSH Boarding Schools Admissions at Pakistan Sweet Home</h1>
        <p>Opportunities for Comprehensive Growth and Learning for Orphans.</p>
      </div>
      <div class="bg_video">
        <img src="assets/new-images/regular-admission-banner-img.png" alt="banner img">
      </div>
    </div>
  </div>
</section>

<!-- <section class="admissionBannerSec">
    <div class="containerFluid">
      <div class="admissionBannerWrapper">
        <h1>PSH Boarding Schools Admissions at Pakistan Sweet Home</h1>
        <p>Opportunities for Comprehensive Growth and Learning for Orphans.</p>
        <img src="assets/new-images/boarding-school-banner-img.png" alt="banner img">
      </div>
    </div>
  </section> -->

<section class="main_accord" id="scroll-section7">
  <div class="container">
    <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
      <div class="card show">
        <div class="card-head">
          <h3>Who can apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body">
          <section class="admissionApply">
            <div class="main_apply">
              <div class="apply_left">
                <ol class="noStyle">
                  <li>Our PSH Boarding Schools are open to orphan children in Pakistan, from Nursery to 7th grade. As a
                    guardian, you're welcome to apply on behalf of the child.</li>
                </ol>
              </div>
            </div>
          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Who cannot apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>Our admission policy is very clear - only orphaned children from Nursery to Class 7 are eligible. If
                  a child has finished school or is not an orphan, they cannot apply.</li>
                <li>This program is specifically for orphans who cannot afford quality education. If the child's
                  guardian can afford the expenses of their education, we kindly request not to apply, as this can take
                  away a deserving child's chance.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>What is the admission procedure?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>Our admission process is transparent and fair. It involves a thorough review of the applicant's
                  academic and personal circumstances. Following initial application review, successful candidates are
                  called for an interview. The final decision is based on academic performance, personal character, and
                  the outcome of the interview.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>How to apply?</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>"The application process is straightforward:</li>
                <li><strong>Step 1:</strong> Start by clicking the ""Apply Now"" button at the bottom of this page and
                  creating an account.</li>
                <li><strong>Step 2:</strong> Fill out the online application form with the child's details.</li>
                <li><strong>Step 3:</strong> Review the information you've entered, and once you're sure everything is
                  correct, submit the application form.</li>
                <li>After we receive your application, you'll get an email confirming it and explaining what happens
                  next."</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Documents Required For Application:</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>Documents Required For Application</li>
                <li>We'll need a few things from you to process the application:</li>
                <li> <strong>1. </strong> The child's Form-B issued by NADRA.</li>
                <li> <strong>2. </strong> Proof of the father's death (certificate issued by NADRA).</li>
                <li> <strong>3. </strong> Proof of the child's academic history (report cards, transcripts, etc.).</li>
                <li> <strong>4. </strong> The child's photo.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Selection Procedure</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>We review every application thoroughly. We look at the child's academic records, the documents you
                  provide, and the results of the interview.</li>
              </ol>
            </div>

          </section>
        </div>
      </div>
      <div class="card">
        <div class="card-head">
          <h3>Final Selection</h3>
          <span class="faq-icon">
            <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
            </svg>
          </span>
        </div>
        <div class="card-body" style="display:none">
          <section class="rquired_documents">
            <div class="main_required">
              <ol class="noStyle">
                <li>"Final Selection:</li>
                <li>The final selection is made based on the child's academic performance, character, and interview
                  performance. Please note that being invited for an interview does not guarantee admission. Also, we
                  maintain confidentiality in our selection process and cannot provide any specific scores or rankings
                  used in the selection process.</li>
                <li>Ready to join our family at Pakistan Sweet Home? Apply now to our PSH Boarding Schools!"</li>
              </ol>
            </div>

          </section>
        </div>
      </div>

    </div>
  </div>
</section>
<section class="promise_section" id="scroll-section8">
  <div class="container">
    <a class="apply_btn" routerLink="/boarding-school/apply" href="javascript:;">Apply Now</a>
  </div>
</section>