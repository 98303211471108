import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

declare var $, Swiper, window;

@Component({
  selector: 'app-sponsorship-program',
  templateUrl: './sponsorship-program.component.html',
  styleUrls: ['../admissions.component.css','./sponsorship-program.component.css']
})
export class SponsorshipProgramComponent implements OnInit {
  public nextPage : any = true;
  public showDownloadModal = false;
  
  constructor(
    private activatedRoute         : ActivatedRoute,
    private router                 : Router
  ) { 
    let url = this.activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
    localStorage.setItem('classApplying', 'preCadet');
  }

  routeCheck(url){

    if(url == '/cadet-college-admission'){
      this.router.navigate(['/cadet-college-admission-8th-class'])
    } 
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });
  }

  ngOnInit(): void {

    $(".scroll-link").click(function (e) {
        
      e.preventDefault();
      var scrollTo = $(this).data("scroll-to");
      let headerHeight = $(".navbar").outerHeight();
      console.log(headerHeight);
      $('html, body').animate({
        scrollTop: $(scrollTo).offset().top - headerHeight
      }, 1000);
    });
        

  }

  toggleDownloadModal(){
    this.showDownloadModal = !this.showDownloadModal;
    this.showDownloadModal ? $('body').css('overflow', 'hidden') : $('body').css('overflow', 'unset');
  }
  

}