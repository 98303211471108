<!-- Qurbani Modal Start -->
<section class="modal-section" [ngClass]="hideModal ? 'hidden' : '' ">
    <div class="modal-overlay">
      <div class="modal-container">
        <div class="left-pane">
  
        </div>
        <div class="right-pane">
          <div class="cancelBtn" (click)="hideModalFunc()">
            <img src="../../../assets/images/modal-homepage/cancel.svg" alt="close modal" />
          </div>
          <div>
            <h3>We are Accepting</h3>
            <h2>Qurbani Donations</h2>
          </div>
          <div>
            <p>Orphans and their families have Eid too.</p>
            <p class="txt_notForgotten">Let them know they're not forgotten!</p>
          </div>
          <div>
            <p class="txt_price_per_goat">Goat/Sheep PKR 60,000/-</p>
          </div>
  
          <div class="btnWrapper">
            <button class="btn_donate_now" (click)="donateQurbani()">Donate Now</button>
            <a class="btn_skip" (click)="hideModalFunc()">Skip</a>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- Qurbani Modal Ends -->