import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, COUNTRYLIST, HelperService } from 'src/app/services/_services';
declare var $;

@Component({
  selector: 'app-student-dashboard',
  templateUrl: './student-dashboard.component.html',
  styleUrls: ['./student-dashboard.component.css']
})
export class StudentDashboardComponent implements OnInit {

  public currentUser: any;
  public authSub: any;
  public countryOptions              : any ;
  public flagOptions                 : any ;
  public selectedFlag                : any = 'PK';
  public selectedCountry             : any = "Pakistan";
  public serverSuccess               : any = '';

  public profile                     : any = {
                                                "name"             : '',
                                                "email"            : '',
                                                "country"          : '',
                                                "phone_number"     : '',
                                             }

  public disabledBtn                : any = true;

  public passChange                         : any = {
    "old_password"       : '',
    "password"           : '',
    "confirmPass"        : '',
  }
  public passChangeError                    : any = '';
  public passChangeSuccess                  : any = '';
  public passChangeLoad                     : any = false;
  public changePassModal                    : any = false;

  public coutryCode                         : any;

  constructor(
    private authenticationService: AuthenticationService,
    private router                 : Router,
    private http                   : HttpClient,
    private helperService          : HelperService,
  ) { 
    if (typeof localStorage != "undefined") {
      this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
        if (user) {
            this.currentUser = user;
            this.setForm(this.currentUser);                        
        } else {
          this.currentUser = null;
          this.router.navigate(['/admission/login']);
        }
      });
    }
  }

  ngOnInit(): void {
    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
      const countries = [];
      const countriesImg = [];
      for (let i = 0; i < resp.length; ++i) {
          const country = resp[i];
          countries.push(country.text);
          countriesImg.push(country.value);
      }
      this.countryOptions = countries;
      this.flagOptions = countriesImg;
  });

  $('input').keyup(function(){
    $(this).removeClass('invalid');
  })

  }

  showChangePasswordFields = false;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
  password: string;

 
  showPassword = false;
  // password = '';
  
  @ViewChild ('profileForm') profileForm : any;
  @ViewChild ('updatePassForm') updatePassForm : any;

  setForm(user){
    console.log('user::', user);
    
    this.selectedFlag = user.country;
    this.profile.name = user.name;
    this.profile.email = user.email;

    var number = user.phone_number.split(' ')[1];
    this.coutryCode = user.phone_number.split(' ')[0];

    var countryCode = this.coutryCode.replace('+','')

    if(countryCode){
      this.selectedFlag = COUNTRYLIST.COUNTRY_AGAINST_CODE[countryCode]['isoCode2'];
    }

    for (let i = 0; i < 11; i++) {
      const element = number[i];
      this.profile.phone_number += element;
    }

  }

  updateProfile(form){
    this.disabledBtn = true;
    if(this.validateForm()){
      this.disabledBtn = true;

      this.setPhoneCode();

      let data = new FormData();    
      data.append("action" , "update_user");
      data.append("ID", this.currentUser.ID);
      this.profile.name && data.append("name", this.profile.name);
      this.profile.phone_number && data.append("phone_number", this.profile.phone_number);


      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          this.authenticationService.setCurrentUserValue(resp.data);
          this.serverSuccess = resp.message;
          
          setTimeout(() => {
            this.serverSuccess = '';
            window.location.replace('/admission/student-dashboard');
          }, 3000);
          
            
        } else {

        }

      }).catch(error => {
          console.error("error: ");  
      });
    }
    this.disabledBtn = false;
  
  }

  validateForm(){

    var profileReturn = false;
    var phoneReturn = false;

    if(this.profile.name){
      if(this.profile.name.includes(' ')){
        profileReturn = true;
      } else{
        profileReturn = false;
        $('#userName').addClass('invalid');        
      }
    } else {
      profileReturn = false;
      $('#userName').addClass('invalid');
    }

    if(this.profile.phone_number){
      if(this.profile.phone_number.toString().length < 9 ){
        phoneReturn = false;
        $('#phone').addClass('invalid');  
      } else {
        phoneReturn = true;
      }
    } else {
      phoneReturn = false;
      $('#phone').addClass('invalid');
    }

    return profileReturn && phoneReturn;

  }

  resetForm(){

    Object.keys( this.profileForm.controls).forEach(key => {
      this.profileForm.controls[key].markAsPristine();
     });
    this.setForm(this.currentUser);        
  }

  formChanged(){
    this.disabledBtn = false;
  }

  changepass(){
    if((this.passChange.old_password.length > 4) && (this.passChange.password.length > 4) && (this.passChange.password == this.passChange.confirmPass) && (this.passChange.password != this.passChange.old_password)){
      this.passChangeLoad = true;
      this.passChangeError = '';

      let data = new FormData();    
      data.append("action" , "update-profile-password");
      data.append("ID", this.currentUser.ID);
      data.append("old_password", this.passChange.old_password);
      data.append("password", this.passChange.password);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });
  
      let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';
  
      this.helperService.httpPostRequests(url, data).then(resp => {
  
        if(resp.status != 'ERROR'){

          this.passChangeSuccess = 'Password changed succussfully!';

          Object.keys( this.updatePassForm.controls).forEach(key => {
            this.updatePassForm.controls[key].markAsPristine();
           });

          this.passChange.old_password = '';
          this.passChange.password = '';
          this.passChange.confirmPass = '';

          setTimeout(() => {
            this.passChangeSuccess = '';
            this.changePassModal = false;
            this.unfixBody();
          }, 3000);

          
        } else {
          
          this.passChangeError = resp.message;
          setTimeout(() => {
            this.passChangeError = '';
          }, 3000);
          
        }
        this.passChangeLoad = false;
        
      }).catch(error => {
          console.error("error: ",error);  
      });

      

    } else {
      if(this.passChange.old_password.length < 5){
        this.passChangeError = 'Enter Valid Old Password';
      } else if(this.passChange.password.length < 5){
        this.passChangeError = 'Enter Valid New Password';
      } else if(this.passChange.password != this.passChange.confirmPass){
        this.passChangeError = 'Confirm Password do not Match!';
      } else if(this.passChange.password == this.passChange.old_password){
        this.passChangeError = 'Old and New Passwords cannot be same!';
      }

      setTimeout(() => {
        this.passChangeError = '';
      }, 3000);

    }
  }

  fixBody(){
    $('body').css('overflow','hidden');
  }
  unfixBody(){
    $('body').css('height','unset');
  }

  setPhoneCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0]){
      var temp = COUNTRYLIST.COUNTRY_LIST;
      if(this.profile.phone_number && !this.profile.phone_number.includes('+')){
        this.profile.phone_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.profile.phone_number;
      } else if(!this.profile.phone_number.includes('+')) {
        var tempNum = this.profile.phone_number.split(' ');
        this.profile.phone_number = tempNum[1];
        this.profile.phone_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.profile.phone_number;
      } else {
        this.profile.phone_number = this.profile.phone_number.split(' ')[1];
        this.profile.phone_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.profile.phone_number;
      }
    }
  }

  setCountryCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] && !this.coutryCode){
      var temp = COUNTRYLIST.COUNTRY_LIST;      
        this.coutryCode = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0];
      }
    }

    checkPhoneNumber(){
      if(this.profile.phone_number.length == 1 && this.profile.phone_number == '0'){
        this.profile.phone_number = '';
      }
    }
  
}
