import { Injectable, Inject  } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { Location } from '@angular/common';
import { DOCUMENT } from '@angular/common';

// import { WINDOW } from '@ng-toolkit/universal'

declare var $;


@Injectable({ providedIn: 'root' })
export class SEOService  {

    constructor(
      private title: Title,
      private meta: Meta,
      private _location  : Location,
      // @Inject(WINDOW) private window: Window,
      @Inject(DOCUMENT) private doc
    ) {}

    updateTitle(title: string) {
      this.title.setTitle(title);
    }

    updateName(full_name: string) {
      this.meta.updateTag({ itemprop: 'name', content: full_name })
    }

    updateDescription(desc: string) {
      this.meta.updateTag({ name: 'description', content: desc })
    }

    updateOgTitle(title: string) {
      this.meta.updateTag({ property: 'og:title', content: title })
    }

    updateOgDesc(desc: string) {
      this.meta.updateTag({ property: 'og:description', content: desc })
    }

    updateOgUrl() {
      let  currentHref = this._location['_platformLocation'].protocol + '//' + this._location['_platformLocation'].hostname + this._location['_platformLocation'].pathname;
      this.meta.updateTag({ property: 'og:url', content: currentHref })
    }

    updateOgImage(url: string) {
      this.meta.updateTag({ property: 'og:image', content: url })
    }

    updateTwitterTitle(title: string) {
      this.meta.updateTag({ property: 'twitter:title', content: title })
    }

    updateTwitterDesc(desc: string) {
      this.meta.updateTag({ property: 'twitter:description', content: desc })
    }

    updateTwitterImage(url: string) {
      this.meta.updateTag({ property: 'twitter:image', content: url })
    }

    updateCanonicalUrl() {
     
      console.log("HERE");

      let  currentHref = this._location['_platformLocation'].protocol + '//' + this._location['_platformLocation'].hostname + this._location['_platformLocation'].pathname;
      console.log("updateCanonicalUrl url: ", currentHref);
  
      let linkElement = this.doc.querySelector("link[rel='canonical']");
  
      if (linkElement) {
        linkElement.setAttribute('href', currentHref);
      } else {
        linkElement = this.doc.createElement('link');
        linkElement.setAttribute('rel', 'canonical');
        linkElement.setAttribute('href', currentHref);
        this.doc.head.appendChild(linkElement);
      }
  
      
      // let  currentHref = this._location['_platformLocation'].protocol + '//' + this._location['_platformLocation'].hostname + this._location['_platformLocation'].pathname;
      // console.log("updateCanonicalUrl url: ",currentHref)
      // if(typeof $ != "undefined")
         
      //   $('link[rel="canonical"]').attr('href', currentHref);
      // // let link: HTMLLinkElement = this.doc.createElement('link');
      // // link.setAttribute('rel', 'canonical');
      // // this.doc.head.appendChild(link);
      // // link.setAttribute('href', url);

      // // Uncomment this for Universal
      // console.log("this.doc: ",this.doc)
      // console.log("this.doc: ",this.doc.getElementsByTagName("link"))
      // console.log("this.doc: ",this.doc.getElementById("canonicalLink"))
      // // let link: HTMLLinkElement = this.doc.createElement('link');
      // let link: HTMLLinkElement = this.doc.getElementById('canonicalLink');
      // // link.setAttribute('rel', 'canonical');
      // // this.doc.head.appendChild(link);
      // link.setAttribute('href', currentHref);
    }

    addNofollow() {
      this.meta.updateTag({ name: 'robots', content: "noindex" })
    }

    removeNoFollow() {
      this.meta.removeTag("name='robots'");
    }
}
