

<section class="signInPages">
    <div class="aj_mob">
      <img src="assets/images/aj_signin.png" alt="sign in">
    </div>
    <div class="pageWrapper">
      <h1>It happens to the best of us.</h1>
      <p class="aj_confirm">Please create a new password</p>
   
      <form class="signInForm SignPageForm">
        <!-- <p class="serverError" *ngIf="errorMsg">
          <img src="/assets/new-images/error-sign-new.png" alt="error sign">
          {{errorMsg}}
        </p> -->
        <div class="inputWrapper normalInputWrapper">
          <label>New Password</label>
          <input id="newpassword" [(ngModel)]="changePassword.password" [ngModelOptions]="{standalone: true}" type="text"  [attr.type]="showPass ? 'text' : 'password'"  placeholder="New Password" />
          <!-- <p class="error">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
            Please Enter Valid Email
          </p> -->
        </div>
        <div class="inputWrapper normalInputWrapper">
            <label>Confirm Password</label>
            <input id="password" [(ngModel)]="changePassword.confirmPassword" [ngModelOptions]="{standalone: true}"  [attr.type]="showPass ? 'text' : 'password'"  placeholder="Password"  />
            <a href="javascript:;" (click)="showPass = !showPass" class="showPassBtn">{{showPass ? 'Hide' : 'Show'}}</a>
            <!-- <p class="error">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                Please Enter Valid Password
            </p> -->
        </div>

        <button [disabled]="!changePassword.confirmPassword || changePassword.confirmPassword !== changePassword.password" (click)="change_password()" class="formSumitBtn" >
            SEND NEW PASSWORD
        </button>
        <!-- <div class="loader">
          <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
        </div> -->
      </form>
      <a href="javascript:;" routerLink="/sign-up" class="alternateJoinBtn">NEED AN ACCOUNT? JOIN NOW</a>
    </div>
  </section>
  

 
  