import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SEOService } from 'src/app/services/_services';
SEOService;
@Component({
  selector: 'app-not-found-page',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.css'],
})
export class NotFoundPageComponent implements OnInit {
  constructor(private router: Router, private seoService: SEOService) {}

  ngOnInit(): void {
    this.setSEO_Tags(
      '404 - This Page Does Not Exist',
      'Page does not exist. Try navigating towards the homepage for useful content.'
    );
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }
}
