<section class="about_us newBlueSec" id="scroll-section1">
  <div class="containerFluid">
    <p class="shortabout">about us</p>
    <h1 class="aboutHead">Learn More about our Journey and our Leadership</h1>
    <a
      class="mainImg"
      data-fancybox="testimonial-video"
      href="https://www.youtube.com/watch?v=LcGwEZ1XIi0&ab_channel=PakistanSweetHome"
      ><img src="assets/images/ourstories-1.jpg" alt="our stories" />
      <svg
        class="video_btn"
        width="72"
        height="50"
        viewBox="0 0 72 50"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M69.6734 7.9061C68.8623 4.89066 66.4849 2.51362 63.4699 1.70166C57.9618 0.19458 35.9292 0.19458 35.9292 0.19458C35.9292 0.19458 13.8975 0.19458 8.38942 1.64452C5.43241 2.45562 2.99694 4.89109 2.18584 7.9061C0.736328 13.4137 0.736328 24.8358 0.736328 24.8358C0.736328 24.8358 0.736328 36.3154 2.18584 41.7655C2.9978 44.7805 5.37441 47.1575 8.38985 47.9695C13.9555 49.477 35.9301 49.477 35.9301 49.477C35.9301 49.477 57.9618 49.477 63.4699 48.0271C66.4853 47.2155 68.8623 44.8385 69.6743 41.8235C71.1234 36.3154 71.1234 24.8938 71.1234 24.8938C71.1234 24.8938 71.1814 13.4137 69.6734 7.9061ZM28.8902 35.5506L28.9145 14.2837L47.2229 24.42L28.8902 35.5506Z"
          fill="#00AAE9"
          fill-opacity="0.8"
        />
        <path
          d="M28.8906 35.5512V14.2664L47.2234 24.4199L28.8906 35.5512Z"
          fill="white"
          fill-opacity="0.8"
        />
      </svg>
      <span class="pulseanim-lp"></span>
    </a>
    <div class="about_usWrapaer">
      <div class="ourStories">
        <h2 class="storyHead">Our Story</h2>
        <p>
          Pakistan Sweet Home started as a project of Bait-ul-Mal in 2009 when a
          single child from Swat, KPK, was handed over to Mr. Zamurrad Khan
        </p>
        <p>
          Since then, Pakistan Sweet Home family has grown to 5 Sweet Home
          centers across Pakistan.
        </p>
        <p>
          Our teams also work in the areas worst affected by poverty and climate
          crises, offering food parcels, funding access to education, essential
          medical care, and emotional healing to more than 10,000 children.
        </p>
        <p>
          As one of the first SECP-registered orphanages in Pakistan, our work
          has made a huge difference in young lives that may have been lost.
        </p>
      </div>
      <div class="strategies">
        <h3>4 Strategic Goals for our Children</h3>
        <div class="strategiesSteps">
          <p><span>Healthy Start in Life</span></p>
          <p><span>Safe Return to School and Quality Learning</span></p>
          <p><span>Childhood Free of Violence</span></p>
          <p><span>Support to Cope with Emotional Trauma</span></p>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="orphanProjects">
  <div class="container">
    <div class="orphan_wraper">
      <div class="orphan_left">
        <h3 class="orphant_head">Honoured with ‘Best Orphan Project 2022’</h3>
        <p>
          We are overjoyed to share that we have received the award for<br />
          <strong> "Best Orphan Support Project 2022"</strong>
        </p>
        <p>
          Award was presented by President Dr. Arif Alvi at the Islamabad
          Chamber of Commerce.
        </p>
        <p>
          This is not just a win for us - It belongs to each and every one of
          you!
        </p>
      </div>
      <div class="orphan_right">
        <img src="assets/images/orphanProjects.jpg" alt="" />
      </div>
    </div>
  </div>
</section>
<!-- <section class="ourApproach" id="scroll-section3">
  <div class="container">
    <div class="approachWraper">
      <div class="approachleft">
        <img src="assets/images/kidzimg.jpg" alt="kids">
      </div>
      <div class="approachright">
        <h3 class="ourapproaches">Our Approach</h3>
        <div class="fourstrategic">
          <h4>4 Strategic Goals for our Children</h4>
          <ul>
            <li>Healthy Start in Life</li>
            <li>Safe Return to School and Quality Learning</li>
            <li>Childhood Free of Violence</li>
            <li>Support to Cope with Emotional Trauma</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</section> -->
<section class="financial_efficiency" id="scroll-section4">
  <div class="container">
    <div class="FEWraper">
      <div class="financial_left">
        <h2 class="financial_head">Financial Efficiency</h2>
        <p>
          Pakistan Sweet Home has a proven track record of using donations
          efficiently and effectively.
        </p>
        <p>
          We work to maximize the value of every donation we receive so that
          less than 6% of it goes to administrative costs.
        </p>
      </div>
      <div class="financial_right">
        <img
          src="assets/images/fundraisingchart.jpg"
          alt="fund raising chart"
        />
      </div>
    </div>
  </div>
</section>
<section class="teamLead" id="scroll-section2">
  <div class="container">
    <div class="teamLeadwraper">
      <h2 class="TL_head">Leading the Team</h2>
      <p class="TL_desc">
        Our team of passionate leaders is helping us to create meaningful
        progress in tackling the growing crisis of orphans in the country. It's
        no small task - but we believe in the power of collective action!
      </p>

      <div class="TL_innerWraper">
        <div class="inner_left">
          <img src="assets/images/mrKhan.jpg" alt="Mr. Zamurrad Khan" />
        </div>
        <div class="inner_right">
          <h3 class="tl-name">Mr. Zamurrad Khan</h3>
          <span class="tl-honour">Hilal-e-Imtiaz</span>
          <div class="TLmsg">
            <p>
              My heartfelt gratitude goes to all the generous donors and
              volunteers who have supported me in this humanitarian cause over
              the last 13 years.
            </p>
            <p>
              Looking ahead to five years, my mission is to expand sweet home
              centres so that children from all five provinces have access to
              high-quality resources that will enable them to become empowered
              citizens of our nation. Your continued support is vital in
              achieving this ambitious goal, and I thank you with utmost
              sincerity!
            </p>
            <p>
              I urge you all to look out for these deserving children in your
              locality and help me reach out to them. Let's come together and
              create opportunities that will brighten their future.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<!-- <section class="meetTeam" id="scroll-section5">
  <div class="container">

    <h2 class="teamHead">Meet the Team</h2>
    <div meetTeamWraper>
      <div class="tabbtns">
        <a [ngClass]="{'active': selectedDiv === 1}" (click)="showDiv(1)">PAKISTAN</a>
        <a [ngClass]="{'active': selectedDiv === 2}" (click)="showDiv(2)">Denmark</a>
        <a [ngClass]="{'active': selectedDiv === 3}" (click)="showDiv(3)">USA</a>
      </div>
      <div [class.d-none]="selectedDiv != 1">
        <div class="temp__swiper  subSwiper">
          <div class="pakTeamwraPPer">
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member2.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member3.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member3.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member2.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
          </div>
        </div>
        <div class="mpwRev-pagination"></div>
      </div>
      <div [class.d-none]="selectedDiv != 2">
        <div class="stotyparent">
          <div class="denTeamwraPPer organizationEvent">
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
            <div class="teammember">
              <img src="assets/images/member1.svg" alt="tean member">
              <p class="membername">Name Here</p>
              <span class="memberDesignation">Designation here</span>
            </div>
          </div>
        </div>
        <div class="denpag-pagination"></div>
      </div>
      <div [class.d-none]="selectedDiv != 3">
        <div class="usa__swiper usaswiper">
          <div class="usaWraper">
            <div class="teammember">
              <img src="assets/images/rizwan.jpg" alt="tean member">
              <p class="membername">Mohammad Rizwan</p>
              <span class="memberDesignation">Trustee</span>
            </div>
            
          </div>
        </div>


      </div>

    </div>
  </div>
</section> -->
<!-- <section class="faqSectiom" id="scroll-section6">
  <div class="container">
    <h2 class="faqs-heading"> Frequently Asked Questions </h2>
    <div class="faqwraper">
      <div class="faqstabbtns">

        <a href="javascript:;" [ngClass]="{'active': selectedFaq === 'all'}" (click)="showFaq('all')">All</a>
        <a href="javascript:;" [ngClass]="{'active': selectedFaq === 'generalquestion'}"
          (click)="showFaq('generalquestion')">GENERAL QUESTIONS</a>
        <a href="javascript:;" [ngClass]="{'active': selectedFaq === 'aboutdonation'}"
          (click)="showFaq('aboutdonation')">ABOUT DONATION</a>
      </div>



      <div class="allcards">
        <div class="main-card">
    
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'generalquestion') ? 'visible' : ''">
            <div class="card-head">
              <h3>Is the education free for orphans at Pakistan Sweet Home?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Yes, Pakistan Sweet Home is dedicated to providing orphans with educational resources and offers a comprehensive program
              that covers all the costs related to their schooling. The initiative provides tuition, food, boarding accommodation,
              stationery, and other materials necessary for learning - ensuring these children have access to quality education.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'generalquestion') ? 'visible' : ''">
            <div class="card-head">
              <h3>Can I adopt a kid from Pakistan Sweet Home centre?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Unfortunately, we do not facilitate adoption at Pakistan Sweet Home. However, the centre remains committed to helping
              orphan children from all over the country by providing them with education and other necessities for a better life.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'generalquestion') ? 'visible' : ''">
            <div class="card-head">
              <h3>Do you take admissions of Mentally/Physically Disabled Children?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Pakistan Sweet Home centre offers education and training opportunities to orphan children from different backgrounds.
              But unfortunately, we do not offer admissions for mentally/physically disabled children as our resources would not allow
              it. These children need delicate treatment and special care.</p>
            </div>
          </div>





          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>How can I make a donation?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Through our website, you can simply click on the donation button and access our donation page. You can then select the
              cause/ project you would like to donate for, select if it is a monthly or a one time donation, enter the amount and
              enter your payment information. Payments can be done either via Credit Card/Debit Card or Paypal.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>How secure are the Pakistan Sweet Home website and the donation transaction process?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>At Pakistan Sweet Home we use the highest information security standards and processes to ensure that the transactions
              are processed in the most secure manner. Also, the best way to secure your personal information is to avoid storing it,
              and that's what we do. Pakistan Sweet home does not keep or store any credit card information: credit card payments are
              processed automatically through Stripe (one of the most renowned International Payment Gateway), therefore no credit
              card information is stored on our website.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>How can I support Pakistan Sweet Home actions throughout the year?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Pakistan Sweet Home offers the possibility to perform monthly recurring payments to enable all our donors to donate
              regularly. Even regular small amount donations can make a difference and change the lives of our children. You can
              easily set up your monthly donation by clicking on the donate button and following the instructions on the donation
              page.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>If I set up a monthly donation, is it easy to stop it?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>You can stop your monthly donation at any time, with one clicks through your account. Your monthly donation will be
              automatically suspended.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>Where are the ZAKAT donations spent?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>At Pakistan Sweet Home we guarantee that 100% of your Zakat donations are directly spent on the projects benefiting deserving children and that no administration fees are deducted from the Zakat donated. We also ensure that zakat
              amounts gathered in the Zakat Fund are all spent on the specific beneficiaries categories mentioned in the Holy Qur'an.
              We consult with our Ethical and Jurisprudential Council to determine the best projects and ways to spend to change the
              living conditions of the beneficiaries. You can donate your zakat now through a simple click on the donate button and
              select the zakat donation category.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>Can I donate something other than money?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>You can donate stationery, money, and food for the children. For detailed information on how you could make this happen,
              please contact our fundraising team at +92331-2099920.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>How will I know where my donation is being spent?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>You can be part of our journey and track where your donations are spent. To stay connected with us, we invite you to
              follow our social media pages and subscribe to our newsletter to receive regular updates on recent activities and
              ongoing projects.</p>
            </div>
          </div>
          <div class="card" [ngClass]="(selectedFaq == 'all' || selectedFaq == 'aboutdonation') ? 'visible' : '' ">
            <div class="card-head">
              <h3>Can I donate to Pakistan Sweet Home from overseas?</h3>
              <span class="faq-icon">
                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="18" cy="18" r="17.5" transform="matrix(1 0 0 -1 0 36)" stroke="#B0B0B0" />
                  <path
                    d="M18 24.1113C17.4 24.1113 17 23.8113 17 23.3613V12.8613C17 12.4113 17.4 12.1113 18 12.1113C18.6 12.1113 19 12.4113 19 12.8613V23.3613C19 23.8113 18.6 24.1113 18 24.1113Z"
                    fill="#B0B0B0" />
                  <path
                    d="M24 20.5556C23.7 20.5556 23.5 20.4486 23.3 20.2347L17.3 13.8181C16.9 13.3903 16.9 12.7486 17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208L24.7 18.7375C25.1 19.1653 25.1 19.8069 24.7 20.2347C24.5 20.4486 24.3 20.5556 24 20.5556Z"
                    fill="#B0B0B0" />
                  <path
                    d="M12 20.5556C11.7 20.5556 11.5 20.4486 11.3 20.2347C10.9 19.8069 10.9 19.1653 11.3 18.7375L17.3 12.3208C17.7 11.8931 18.3 11.8931 18.7 12.3208C19.1 12.7486 19.1 13.3903 18.7 13.8181L12.7 20.2347C12.5 20.4486 12.3 20.5556 12 20.5556Z"
                    fill="#B0B0B0" />
                </svg>
              </span>
            </div>
            <div class="card-body">
              <p>Yes. International donors can visit our donation page and use the online payment option.</p>
            </div>
          </div>







        </div>










      </div>
    </div>
  </div>
</section> -->
