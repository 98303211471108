import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css'],
})
export class BlogComponent implements OnInit {
  search: string = '';
  jsonData: any;
  filteredData: any;
  noResultFound: boolean = false;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.fetchData();
  }

  async fetchData(): Promise<void> {
    try {
      const response: any = await this.http
        .post(
          'https://adminbackend.pakistansweethome.org.pk/blogs/pages/get-pages',
          {
            site_url: 'www.pakistansweethome.org.pk',
          }
        )
        .toPromise();
      this.jsonData = response.data;
      this.filteredData = this.jsonData; // Initialize filteredData
    } catch (error) {
      console.error('Error:', error);
    }
  }

  filterBlogs(): void {
    if (!this.search.trim()) {
      this.filteredData = this.jsonData;
      this.noResultFound = false;
      return;
    }

    const filter = this.search.toLowerCase();
    let count = 0;

    this.filteredData = this.jsonData
      .map((blog: any) => {
        const titleMatch = blog.title.toLowerCase().includes(filter);
        const contentMatch = blog.content?.toLowerCase().includes(filter);

        if (titleMatch || contentMatch) {
          count++;
          blog.highlightedTitle = this.highlightMatch(blog.title, filter);
          return blog;
        } else {
          return null;
        }
      })
      .filter((blog: any) => blog !== null);

    this.noResultFound = count === 0;
  }

  highlightMatch(text: string, filter: string): string {
    const regex = new RegExp(`(${filter})`, 'gi');
    return text.replace(regex, '<span class="highlight">$1</span>');
  }
}
