<section class="signInPages" *ngIf="!modalView">
  <div class="aj_mob">
    <img src="assets/images/aj_signin.png" alt="sign in">
  </div>
  <div class="pageWrapper">
    <h1>Sign in</h1>
    <a href="javascript:;" class="facebookBtn" (click)="signInWithFB()">SIGN IN WITH FACEBOOK</a>
    <div class="orWrapper">
      <div class="lineWhite"></div>
      <p class="orText">OR</p>
      <div class="lineWhite"></div>
    </div>
    <form class="signInForm SignPageForm">
      <p class="serverError" *ngIf="errorMsg">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign">
        {{errorMsg}}
      </p>
      <div class="inputWrapper normalInputWrapper">
        <label>EMAIL</label>
        <input id="email" (keyup)="removeError($event.target)" type="text" [(ngModel)]="login.email" placeholder="Email"
          [ngModelOptions]="{standalone: true}" />
        <p class="error">
          <img src="/assets/new-images/error-sign-new.png" alt="error sign">
          Please Enter Valid Email
        </p>
      </div>
      <div class="inputWrapper normalInputWrapper">
        <label>PASSWORD</label>
        <input id="password" (keyup)="removeError($event.target)" [attr.type]="showPass ? 'text' : 'password'" [(ngModel)]="login.password"
          placeholder="Password" [ngModelOptions]="{standalone: true}" />
        <a href="javascript:;" (click)="showPass = !showPass" class="showPassBtn">{{showPass ? 'Hide' : 'Show'}}</a>
        <p class="error">
          <img src="/assets/new-images/error-sign-new.png" alt="error sign">
          Please Enter Valid Password
        </p>
      </div>
      <button *ngIf="!loading" class="formSumitBtn" (click)="signIn()">
        SIGN IN
      </button>
      <div *ngIf="loading" class="loader">
        <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
      </div>
      <a class="forgotaassText" routerLink="/forgot-password" href="javascript:;">Forgot password?</a>
    </form>
    <a href="javascript:;" [routerLink]="suUrl" class="alternateJoinBtn">NEED AN ACCOUNT? JOIN NOW</a>
  </div>
</section>

<div class="modalWrapper" *ngIf="modalView">
  <h2>Login to existing account</h2>
  <form class="signInForm SignPageForm">
    <p class="serverError" *ngIf="errorMsg">
      <img src="/assets/new-images/error-sign-new.png" alt="error sign">
      {{errorMsg}}
    </p>
    <div class="inputWrapper normalInputWrapper">
      <label>EMAIL</label>
      <input id="email" (keyup)="removeError($event.target)" type="text" [(ngModel)]="login.email" placeholder="Email"
        [ngModelOptions]="{standalone: true}" />
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign">
        Please Enter Valid Email
      </p>
    </div>
    <div class="inputWrapper normalInputWrapper">
      <label>PASSWORD</label>
      <input id="password" (keyup)="removeError($event.target)" [attr.type]="showPass ? 'text' : 'password'" [(ngModel)]="login.password"
        placeholder="Password" [ngModelOptions]="{standalone: true}" />
      <a href="javascript:;" (click)="showPass = !showPass" class="showPassBtn">{{showPass ? 'Hide' : 'Show'}}</a>
      <p class="error">
        <img src="/assets/new-images/error-sign-new.png" alt="error sign">
        Please Enter Valid Password
      </p>
    </div>
    <button *ngIf="!loading" class="formSumitBtn" (click)="signIn()">
      SIGN IN
    </button>
    <div *ngIf="loading" class="loader">
      <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
    </div>
    <p class="modalAlreadyText">Don’t have an account? <a href="javascript:;" (click)="changeParentStep('signup')" >Create New Account</a> </p>
    <!-- <a class="forgotaassText" routerLink="/forgot-password" href="javascript:;">Forgot password?</a> -->
  </form>
</div>