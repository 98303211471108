import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-bridging-borders-with-kindness',
  templateUrl: './bridging-borders-with-kindness.component.html',
  styleUrls: ['./bridging-borders-with-kindness.component.css', '../articles.component.css']
})
export class BridgingBordersWithKindnessComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Bridging Borders with Kindness - Iran's First Lady at PSH","Pakistan Sweet Home’s efforts to foster kindness and unity with Iran through charitable work and partnerships.")
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }

}
