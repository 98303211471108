import {
  HttpClient
} from '@angular/common/http';
import {
  Component,
  OnInit,
  ViewChild
} from '@angular/core';
declare var $, Swiper, window;
import {
  AppSettings,
  SEOService,
  HelperService,
  AuthenticationService
} from '../../services/_services';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl
} from '@angular/forms';
import Swal from 'sweetalert2';
declare var $;
@Component({
  selector: 'app-get-involved',
  templateUrl: './get-involved.component.html',
  styleUrls: ['./get-involved.component.css']
})
export class GetInvolvedComponent implements OnInit {

  public countryOptions: any;
  public flagOptions: any;
  public nameField: any = '';
  public emailField: any = '';
  public massegeField: any = '';
  public selectedFlag: any = 'PK';
  public selectedCountry: any = "United States";
  public programOptions: any = ['Social Media (Facebook, Instagram, LinkedIn etc.)', 'Through A Friend', 'In-Person Events/Conference', 'News (Website, TV or Newspaper)', 'Other - Please specify']
  public interestOptions: any = ['Office Assistance', 'Fundraising', 'PR/Marketing', 'Event Coordination', 'Food Drive']
  public emailSuccess: any = '';
  public pageResp: any = false;
  public emailError: any = '';
  public massegeError: any = '';
  public selectedDiv: any = 1;
  public organizeFormValid = false;
  public formSuccess: any = '';

  public programInfo: any = {
    "grade": null,
    "academicRecord": '',
  }
  public interestInfo: any = {
    "grade": null,
    "academicRecord": '',
  }

  public tour         : any = {
    "previous_volunteer_experience"         : '',
    "email"             : '',
    "phone_number"      : '',
    "message"           : "",
  }


  public volunteer         : any = {
    "full_name"         : '',
    "emailvol"             : '',
    "phone_numbervol"      : '',
    "city"           : "",
    "skill_talent"           : "",
    "previous_volunteer_experiencevol"           : "",
    "information_source"           : null,
    "area_of_interest"           : null,
  }

  @ViewChild ('volunteerForm') volunteerForm : any;


  constructor(
    private http: HttpClient,
    private helperService: HelperService,
  ) {}

  ngOnInit(): void {

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
      const countries = [];
      const countriesImg = [];
      for (let i = 0; i < resp.length; ++i) {
        const country = resp[i];
        countries.push(country.text);
        countriesImg.push(country.value);
      }
      this.countryOptions = countries;
      this.flagOptions = countriesImg;
    });


    if ($(window).width() <= 575) {



      $(".stotyparent").addClass('swiper swiper-container');
      $(".organizationEvent").addClass('swiper-wrapper');
      $(".organizationEvent").removeClass('main_stories');
      $(".organizationEvent .single_story").addClass('swiper-slide');
      var swiper = new Swiper(".stotyparent", {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,	
        loop: true,
        pagination: {
          el: ".mpwRev-pagination",
          clickable: true
        }
      });






    }






  }





  


  subscribeNewsLetter() {
    if (!this.nameField) {
      this.massegeError = "Name Error";
      console.log('error')
      return;
    } else if (!this.validateEmail()) {
      this.massegeError = "emailField Error";
      console.log('erroremail')
      return;
    } else if (this.massegeField == '') {
      this.massegeError = "Message Error";
      console.log('messageError');
      return;
    } else {
      this.massegeError = "";;
      this.formSuccess = "SucessFully";
      this.organizeFormValid = true;
      this.nameField = '';
      this.emailField = '';
      this.massegeField = '';
    }


  }

  subscribetour(){
  if(this.validateFormtour()){

    console.log('valid');
    

        let url  = AppSettings.API_ENDPOINT + 'tour-requests/tour_request.php';

      let data = new FormData();
      data.append("action", "add-tour-request");
      data.append("email", this.tour.email);
      data.append("phone_number", this.tour.phone_number);
      data.append("previous_volunteer_experience", this.tour.previous_volunteer_experience);
      data.append("message", this.tour.message);

      this.helperService.httpPostRequests(url, data).then(resp => {
      
        console.log(resp);
      
        if(resp.status == "OK"){
          
        } else {
          
        }
      
        

      }).catch(error => {
          console.error("error: ",error);
      });

      

    } else {
      console.log('invalid');
    }
  }




  subscribevolunteer(){
  if(this.validateFormvolunteer()){

    console.log('valid');
    

        let url  = AppSettings.API_ENDPOINT + 'volunteers/volunteer.php';

      let data = new FormData();
      data.append("action", "add-volunteer");
      data.append("full_name", this.volunteer.full_name);
      data.append("email", this.volunteer.emailvol);
      data.append("phone_number", this.volunteer.phone_numbervol);
      data.append("city", this.volunteer.city);
      data.append("skill_talent", this.volunteer.skill_talent);
      data.append("previous_volunteer_experience", this.volunteer.previous_volunteer_experiencevol);
      data.append("information_source", this.volunteer.information_source);
      data.append("area_of_interest", this.volunteer.area_of_interest);
  

      this.helperService.httpPostRequests(url, data).then(resp => {
      
        console.log(resp);
      
        if(resp.status == "OK"){
          Swal.fire('Thank You!', 'Thank you for your interest in volunteering with Pakistan Sweet Home. Our team will review your application and contact you shortly with potential next steps. We look forward to working with you!', 'success');
          this.volunteerForm.reset();
        } else {
          
        }
      
        

      }).catch(error => {
          console.error("error: ",error);
      });

      

    } else {
      console.log('invalid');
    }
  }





  validateEmail() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailField)) {
      return true;
    } else {
      this.emailError = "Enter valid Email!";
      return false;
    }
  }
  
  validateEmailtwo() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.tour.email)) {
      return true;
    } else {
      return false;
    }
  }
  validateEmailthree() {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.volunteer.emailvol)) {
      return true;
    } else {
      return false;
    }
  }
  changeProject(projectName) {
    this.selectedDiv = projectName;
  }

  checkOtherValue(){
    if(this.programInfo.program == 'Other - Please specify'){
      $('.aj_notificaton').addClass('show');
      
    } else {
      $('.aj_notificaton').removeClass('show');
    }
  }

  validateFormtour(){
    if(this.tour.previous_volunteer_experience && (this.tour.phone_number && this.tour.phone_number.length >= 11) && this.tour.email && this.tour.message) {
      $('.tour_notification').addClass('show');
      setTimeout(() => {
        $('.tour_notification').removeClass('show');
      }, 3000); 
      
      
      
      return true;
    } else {
      if(!(this.tour.previous_volunteer_experience)){
        $('#previous_volunteer_experience').addClass('invalid');        
      }
      if(!(this.tour.phone_number.length > 11)) {
   
        $('#phone_number').addClass('invalid');        
      }
      if(!this.validateEmailtwo()){
        $('#email').addClass('invalid');        
      }
      if(!this.tour.message){
        $('#message').addClass('invalid');        
      }
     
      return false;
    }
  }

  removeInvalid(e){
    if($(e).val()){
      $(e).removeClass('invalid');
    }
  }

  removeInvaliid(i){
    if($(i).hasClass('invalid')){
      $(i).removeClass('invalid');
    }
  }



  validateFormvolunteer(){
    console.log('this.volunteer: ', this.volunteer);
    if(this.volunteer.full_name && (this.volunteer.phone_numbervol) && this.volunteer.emailvol && this.volunteer.city && this.volunteer.skill_talent && this.volunteer.previous_volunteer_experiencevol && this.volunteer.area_of_interest ) {             
      return true;
    } else {
      if(!(this.volunteer.full_name)){
        $('#full_name').addClass('invalid');        
      }
      if(!(this.volunteer.phone_numbervol)) {
   
        $('#phone_numbervol').addClass('invalid');        
      }
      if(!this.validateEmailthree()){
        $('#emailvol').addClass('invalid');        
      }
      if(!this.volunteer.city){
        $('#city').addClass('invalid');        
      }
      if(!this.volunteer.skill_talent){
        $('#skill_talent').addClass('invalid');        
      }
      if(!this.volunteer.previous_volunteer_experiencevol){
        $('#previous_volunteer_experiencevol').addClass('invalid');        
      }
      if(!this.volunteer.previous_volunteer_experiencevol){
        $('#information_source').addClass('invalid');        
      }
      if(!this.volunteer.area_of_interest){
        $('#area_of_interest').addClass('invalid');        
      }
     
      return false;
    }
    
  }



}
