<main>
  <section class="banner_section">
    <div class="bg_video">
      <video
        class="dsktpOnly"
        width="100%"
        height="100%"
        loop
        autoplay
        muted
        [attr.src]="videoSrc"
      >
        <source src="" type="video/mp4" />
      </video>
      <video
        class="mobOnly"
        width="100%"
        height="100%"
        #lazyVideo
        loop
        autoplay
        muted
        playsinline
        data-src="assets/images/donate-video-compressed.mp4"
      >
        <source src="" type="video/mp4" />
      </video>
      <div class="bg-overlay"></div>
    </div>
    <div class="container">
      <div class="mainBanner">
        <div class="leftbanner" style="z-index: 999">
          <h1>{{ article.h1 }}</h1>
          <p>
            {{ article.h2 }}
          </p>
        </div>
        <!-- <app-payment-form *ngIf="!newPage" ></app-payment-form> -->
        <app-payment-form-checkout
          style="z-index: 1000"
          [donationType]="article.slug"
        ></app-payment-form-checkout>
      </div>
    </div>
  </section>
  <section class="support_provided">
    <div class="container">
      <div class="support_head">
        <!-- Loop through description array to display each as a separate paragraph -->
        <div *ngFor="let paragraph of article.description">
          <p>{{ paragraph }}</p>
        </div>
      </div>
      <p class="expense-heading">Covered Expenses</p>
      <div class="main_support">
        <ng-container *ngFor="let expense of article.expenses">
          <div class="single_support">
            <img
              [src]="expense.image"
              class="expense-img"
              [alt]="expense.alt"
            />
            <p class="service_name">{{ expense.title }}</p>
          </div>
        </ng-container>
      </div>
      <div style="margin-top: 80px">
        <ng-container *ngFor="let para of article.coverLetter">
          <p class="coverLetter-sec">{{ para }}</p>
        </ng-container>
      </div>
      <div style="margin-top: 80px">
        <p class="expense-heading">Other Ways to Support</p>
        <div class="article-section">
          <div class="articles-wrapper">
            <ng-container *ngFor="let article of similarArticles">
              <div class="article">
                <img [src]="article.img" [alt]="article.alt" />
                <div class="article-info">
                  <p class="article-heading">{{ article.title }}</p>
                  <p class="article-summary">{{ article.summary }}</p>
                </div>

                <button (click)="goToDetailPage(article.slug)">
                  View Details
                </button>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </section>
</main>
