import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

declare var $, Swiper,window;

@Component({
  selector: 'app-why-us',
  templateUrl: './why-us.component.html',
  styleUrls: ['./why-us.component.css']
})
export class WhyUsComponent implements OnInit, AfterViewInit {

  @ViewChild('wistiastar') wistiastar: any;

  public vistiashared: any;

  public activeTab                      : any = 'education';
  public tabContent                     : any = {
                                                  'education'       : {
                                                                        'content' : ['Our main goal is to provide a learning environment equipped with resources and support that allows our children to grow into their potential: academically, personally, and professionally.'],
                                                                        'img'     : '/assets/new-images/educationImg.png'
                                                                      },
                                                  'food'            : {
                                                                        'content' : ['Our partnership with renowned local food suppliers means that our children enjoy fresh. nutritious, and delicious meals every single day!'],
                                                                        'img'     : '/assets/new-images/foodImg.png'
                                                                      },
                                                  'shelter'         : {
                                                                        'content' : ['When it comes to our children, nothing is more important than ensuring they have a safe place to call home. We have 05 operational centers across Pakistan – fully equipped with all necessary amenities to provide comfortable and secure accommodation.'],
                                                                        'img'     : '/assets/new-images/shelterImg.png'
                                                                      },
                                                  'recreation'      : {
                                                                        'content' : ['A range of recreational activities is arranged and planned thoughtfully to ensure a well-rounded learning experience that perfectly balances both fun and knowledge.', "From classroom activities and physical sports to educational field trips- there's something for every age group."],
                                                                        'img'     : '/assets/new-images/recreationImg.png'
                                                                      },
                                                  'skill'           : {
                                                                        'content' : ["Our commitment to children is to cover academics and enable young minds to reach their full potential by developing valuable skills necessary for today's competitive world.", "We offer workshops, internships, and training sessions, and children are encouraged to take full advantage of the opportunities available on campus."],
                                                                        'img'     : '/assets/new-images/skillImg.png'
                                                                      },
                                                  'character'           : {
                                                                        'content' : ["We strive to raise the next generation with character values rooted in religious, ethical and patriotic beliefs. ", "We believe that strengthening these foundations is essential for a brighter future."],
                                                                        'img'     : '/assets/new-images/characterImg.png'
                                                                      },
                                                }

  constructor(private seoService: SEOService,) { 
    
    
  }

  ngOnInit(): void {

    var swiper = new Swiper(".docsSwiper", {
      direction: "vertical",
      slidesPerView : 1,
      centeredSlides : true,
      navigation: {
        nextEl: ".files-next",
        prevEl: ".files-prev",
      },
      breakpoints: {
        360: {
          direction: "horizontal",
        },
        575: {
          direction: "vertical",
        }
      },
    });
    this.setSEO_Tags("Legal Transparency Files of Pakistan Sweet Home","Explore Pakistan Sweet Home's transparency files and see how donations directly impact orphans’ lives and futures.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  ngAfterViewInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/0enfr7nvaw.jsonp";
    this.wistiastar.nativeElement.prepend(s);
    this.vistiashared = document.createElement("script");
    this.vistiashared.type = "text/javascript";
    this.vistiashared.src = "https://fast.wistia.com/assets/external/E-v1.js";
    this.wistiastar.nativeElement.prepend(this.vistiashared);

  }

  changeTab(tabName){
    this.activeTab = tabName;
  }

}
