import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SEOService } from 'src/app/services/_services';

declare var $, Swiper, window;

@Component({
  selector: 'app-day-scholars',
  templateUrl: './day-scholars.component.html',
  styleUrls: ['./day-scholars.component.css', '../admissions.component.css', '../sponsorship-program/sponsorship-program.component.css']
})
export class DayScholarsComponent implements OnInit {
  public nextPage : any = true;
  
  constructor(
    private activatedRoute         : ActivatedRoute,
    private router                 : Router,
    private seoService: SEOService,
  ) { 
    let url = this.activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
    localStorage.setItem('classApplying', 'preCadet');
  }

  routeCheck(url){

    if(url == '/cadet-college-admission'){
      this.router.navigate(['/cadet-college-admission-8th-class'])
    } 
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });
  }

  ngOnInit(): void {

    $(document).ready(function () {
      $('.card .card-body:first-child').show();

      $('.card-head').click(function () {
        console.log('askjdhasudbhm gere')
        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).parent().addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });

    $(".scroll-link").click(function (e) {
        
      e.preventDefault();
      var scrollTo = $(this).data("scroll-to");
      let headerHeight = $(".navbar").outerHeight();
      console.log(headerHeight);
      $('html, body').animate({
        scrollTop: $(scrollTo).offset().top - headerHeight
      }, 1000);
    });
    
    this.setSEO_Tags("Education Sponsorship Program of Pakistan Sweet Home","Sponsor an orphan’s education at Pakistan Sweet Home and empower them to achieve their academic dreams through our education sponsorship program.")
  }
  
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
