<section class="banner_section">
  <div class="containerFluid">
    <div class="bannerWrapper">
      <div class="bannerContent">
        <h1>8th Class Admissions - Pakistan Sweet Home Cadet College</h1>
        <p>You can join the college in 8th grade. All seats are available for orphans to compete fairly. There are 50 seats for each entry. The college is affiliated with the Federal Board of Intermediate & Secondary Education, Islamabad.</p>
      </div>
      <div class="bg_video">
        <img src="assets/new-images/cadet-college-8th-class-banner-img.png" alt="banner img">
      </div>
    </div>
  </div>
</section>
  
  <section class="main_accord" id="scroll-section7">
    <div class="container">
      <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
        <div class="card show" >
          <div class="card-head">
            <h3>Who can apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body">
            <section class="admissionApply">
                  <div class="main_apply">
                    <div class="apply_left">
                      <ol class="noStyle">
                        <li><strong>1.</strong> Candidates must have passed Class 7 or waiting for result when applying to the college.</li>
                        <li><strong>2.</strong> Must be between 12 and 14 years old on 1st April of the year of admission.</li>
                        <li><strong>3.</strong> Candidates must be medically fit.                      </li>
                        <li><strong>4.</strong> Only orphans can apply.                      </li>
                      </ol>
                    </div>
                  </div>
            </section>
          </div>
        </div>
        <div class="card" >
          <div class="card-head" >
            <h3>Who cannot apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li><strong>1.</strong> Over or under-age candidates.</li>
                        <li><strong>2.</strong> Medically unfit candidates.</li>
                        <li><strong>3.</strong> Candidates with tampered certificates or documents.</li>
                        <li><strong>4.</strong> Candidates who hide information in their application form.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>
        <div class="card" >
          <div class="card-head" >
            <h3>What is the admission procedure?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li><strong>Step 1:</strong> Each candidate applies by sending an application. After the application is received, a roll number slip is issued.</li>
                        <li><strong>Step 2:</strong> The candidate then takes a written test in a nearby test center.</li>
                        <li><strong>Step 3:</strong> Candidates who pass the written exam are required to take a medical test and interview.</li>
                        <li>The candidates who pass are admitted to the cadet college. They stay in the college for 5 years, and we take care of everything for them for free.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card" >
          <div class="card-head" >
            <h3>How to apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>Candidates can apply in two ways:</li>
                        <li><strong>1.</strong> Submitting an Application Online (preferred)</li>
                        <li><strong>2.</strong> Sending an application via TCS</li>
                        <li> <p class="instructionHead">Instructions for submitting applications online:</p> </li>
                        <li><strong>Step 1:</strong> Create an account on our website.</li>
                        <li><strong>Step 2:</strong> Fill out the online application form.</li>
                        <li><strong>Step 3:</strong> Submit the application form after verifying your information.</li>
                        <li> <strong>Note:</strong> </li>
                        <li>
                          After submitting your form, you will receive an email, and the roll number slip will be enabled in your online account on our website. You have to download it, take a print, and bring it to your exam center.
                          The information provided in the online application form must be correct. False information may result in disciplinary action or withdrawal with payment of training charges.
                          The last date for submitting the online application form is advertised through the press and website in June / July (8th Class) each year.
                        </li>
                        <!-- <li> <p class="instructionHead">Instructions for submitting applications via TCS:</p> </li>
                        <li><a href="javascript:;" download>Download Application here</a> and send it to this address: H-9/4, Pakistan Sweet Home, Islamabad.</li> -->
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card" >
          <div class="card-head" >
            <h3>Documents Required For Application:</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>Candidates are required to provide the following documents:</li>
                        <li><strong>1.</strong> B-form issued by Nadra</li>
                        <li><strong>2.</strong> Father's Death Certificate</li>
                        <li><strong>3.</strong> Recent academic results</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card" >
          <div class="card-head" >
            <h3>Selection Procedure</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>The selection is based on the candidate's performance / grading in the following:</li>
                        <li><strong>1.</strong> Written test (MCQs & Subjective) from Class 7</li>
                        <li><strong>2.</strong> Interview</li>
                        <li><strong>3.</strong> Medical Examination</li>
                        <li> <p class="instructionHead">Written Test Centers</p> </li>
                        <li>
                          <table class="testCenters">
                            <tbody>
                              <tr>
                                <td>Abbotabad</td>
                                <td>Bahawalpur</td>
                                <td>Deraghazi Khan</td>
                              </tr>
                              <tr>
                                <td>Faisalabad</td>
                                <td>Gujranwala</td>
                                <td>Gilgit</td>                              
                              </tr>
                              <tr>
                                <td>Hyderabad</td>
                                <td>Jhelum</td>
                                <td>Kalat</td>
                              </tr>
                              <tr>
                                <td>Karachi</td>
                                <td>Kohat</td>
                                <td>Lahore</td>                                                            
                              </tr>
                              <tr>
                                <td>Mardan</td>
                                <td>Muzaffarabad</td>
                                <td>Multan</td>
                              </tr>
                              <tr>
                                <td>Mirpur</td>
                                <td>Okara</td>
                                <td>Pano Aqil</td>
                              </tr>
                              <tr>                              
                                <td>Peshawar</td>
                                <td>Quetta</td>
                                <td>Rawlakot</td>
                              </tr>
                              <tr>
                                <td>Rawalpindi</td>
                                <td>Skardu</td>
                                <td>Sargodha</td>
                              </tr>
                              <tr>
                                <td>Sialkot</td>
                                <td>Sukkar</td>
                              </tr>
                            </tbody>
                          </table>
                        </li>
                        <li> <p class="instructionHead">Written Test</p> </li>
                        <li>The written test includes the following subjects:English, Mathematics and General Science. Results of successful candidates are uploaded to our website.</li>
                        <li>You can download past papers for preparation by <a href="assets/sample-tests/Model-Papers-Pre-Cadet-Class-8th.pdf" download>clicking here</a>.</li>
                        <li> <p class="instructionHead">Intelligence & Interview</p> </li>
                        <li>Candidates who qualify for the written test are called for the intelligence test / interview at the Pakistan Sweet Home Islamabad Campus.</li>
                        <li> <p class="instructionHead">Medical Examination</p> </li>
                        <li>Candidates who qualify for the intelligence test and interview are issued a letter for a medical examination.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        <div class="card" >
          <div class="card-head" >
            <h3>Final Selection</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
              <section class="rquired_documents">
                    <div class="main_required">
                      <ol class="noStyle">
                        <li>The final selection is based on the overall merit achieved by the candidate in the written test, interview, intelligence test, and medical fitness. Mere passing the test does not entitle a candidate to admission.</li>
                        <li>Please note that the results are confidential Answer scripts, marks obtained, merit or position obtained will not be shown to parents / guardians. Any correspondence in this regard will not be entertained.</li>
                        <li><strong>Note:</strong> The test center may change if needed and we will inform you via text and email in such event.</li>
                      </ol>
                    </div>
                  
                </section>
          </div>
        </div>      
        
      </div>
    </div>
  </section>
  <section class="promise_section" id="scroll-section8" >
    <div class="container">
      <a [ngClass]="nextPage ? 'yes' : 'disabled'" class="apply_btn" routerLink="/admission/signup"
        href="javascript:;">Apply Now</a>
    </div>
  </section>
  