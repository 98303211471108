<section class="volunteerSec">
  <div class="container">
    <div class="bannerContent">
      <h1>Request a Tour</h1>
      <!-- <div class="tour_video_wrapper">
          <div class="play-icon">
            <a class="videoPlayBtn" data-fancybox="testimonial-video" href="https://youtu.be/OKR5zEEgO4w">
              <img src="assets/new-images/get-involved/tour-img-new.png" alt="hero background image">
              <span>
                <svg width="72" height="50" viewBox="0 0 72 50" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M69.6734 7.9061C68.8623 4.89066 66.4849 2.51362 63.4699 1.70166C57.9618 0.19458 35.9292 0.19458 35.9292 0.19458C35.9292 0.19458 13.8975 0.19458 8.38942 1.64452C5.43241 2.45562 2.99694 4.89109 2.18584 7.9061C0.736328 13.4137 0.736328 24.8358 0.736328 24.8358C0.736328 24.8358 0.736328 36.3154 2.18584 41.7655C2.9978 44.7805 5.37441 47.1575 8.38985 47.9695C13.9555 49.477 35.9301 49.477 35.9301 49.477C35.9301 49.477 57.9618 49.477 63.4699 48.0271C66.4853 47.2155 68.8623 44.8385 69.6743 41.8235C71.1234 36.3154 71.1234 24.8938 71.1234 24.8938C71.1234 24.8938 71.1814 13.4137 69.6734 7.9061ZM28.8902 35.5506L28.9145 14.2837L47.2229 24.42L28.8902 35.5506Z"
                    fill="#00AAE9" fill-opacity="0.8" />
                  <path d="M28.8906 35.5513V14.2664L47.2234 24.42L28.8906 35.5513Z" fill="white" fill-opacity="0.8" />
                </svg>
              </span>
            </a>
          </div>
        </div> -->
    </div>
  </div>
</section>

<section class="joinInfoSec">
  <div class="container">
    <div class="joiningContent">
      <h2>
        Are you interested in viewing our campus? We are now booking slots
      </h2>
      <p>
        Public visits are available all 7 days from 10 am - 5 pm. To schedule
        your visit, please fill the form below:
      </p>
    </div>
  </div>
</section>

<section class="volunteerFormSec">
  <div class="container">
    <div class="formWrapper">
      <form #volunteerForm="ngForm" class="volunteerForm">
        <div class="inputGroup">
          <input
            type="text"
            placeholder="Full Name"
            [(ngModel)]="volunteerInfo.name"
            name="name"
            required
          />
        </div>
        <div class="inputGroup">
          <input
            type="text"
            placeholder="Contact Email"
            [(ngModel)]="volunteerInfo.email"
            name="email"
            id="email"
            required
          />
          <p class="error">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign" />
            Please Enter Valid Email
          </p>
        </div>
        <div class="inputGroup">
          <input
            mask="(000) 00000099"
            class="phoneInput"
            id="phoneNumber"
            type="text"
            name="phoneNumber"
            placeholder="(3xx) xxxxxxx"
            [(ngModel)]="volunteerInfo.phone.number"
            (keypress)="isNumberValidate($event, 'phone')"
            required
          />
          <span class="countryCode">+{{ volunteerInfo.phone.code }}</span>
          <div class="flagsWrapper">
            <div class="coutryFlags">
              <div
                (click)="setPhoneValues('phone', country, flagOptions[i])"
                *ngFor="let country of countryOptions; index as i"
                class="singleFlag"
              >
                <img
                  [src]="
                    'https://img.mobiscroll.com/demos/flags/' +
                    flagOptions[i] +
                    '.png'
                  "
                  alt="country"
                />
                <p>{{ country }}</p>
              </div>
            </div>
            <div class="selectedFlag">
              <img
                [src]="
                  'https://img.mobiscroll.com/demos/flags/' +
                  volunteerInfo.phone.country +
                  '.png'
                "
                alt=""
              />
              <svg
                width="11"
                height="6"
                viewBox="0 0 11 6"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.5 6L0.736861 0.75L10.2631 0.749999L5.5 6Z"
                  fill="#5E5E5E"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="inputGroup">
          <ng-select
            class="admissionSelect"
            [items]="binaryOptions"
            bindLabel="label"
            bindValue="value"
            [(ngModel)]="volunteerInfo.is_experienced"
            placeholder="Any previous volunteering experience?"
            name="isExperienced"
            [searchable]="false"
            [clearable]="false"
            required=""
            id="isExperienced"
            (change)="removeInvalid('#isExperienced')"
          >
          </ng-select>
        </div>
        <button
          class="formSubmitBtn"
          *ngIf="!disabledBtn"
          (click)="submitApplication()"
        >
          Submit Application
        </button>
        <a
          href="javascript:;"
          *ngIf="disabledBtn"
          class="formSubmitBtn loaderBlue"
        >
          <img
            class="loadingImg"
            src="/assets/new-images/pshLoaderWhite.webp"
            alt="loading"
          />
        </a>
        <div class="noteBox">
          <p>
            <strong>Note:</strong> Your privacy is important to us. We only
            collect essential information for the volunteer process and adhere
            to all privacy and data collection regulations.
          </p>
        </div>
      </form>
    </div>
  </div>
</section>
