import { HttpHeaders } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettings,AuthenticationService, FacebookLoginService, HelperService } from 'src/app/services/_services';
declare var $;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css','../add-sponsor/modals.css']
})
export class SignInComponent implements OnInit {

  @Input() modalView;

  @Output() stepChangeEvent = new EventEmitter();
  @Output() modalShowEvent = new EventEmitter();

  public login                      : any = {
                                              "email"             : '',
                                              "password"          : ''
                                            }
  public user                        : any ;                     
  public showPass                    : any = false;    
  public errorMsg                    : any;          
  public forgetPass: any = false;
  public loading                     : any = false;  
  public routerSubscription          : any;    

  public rerouteUrl                  : any = '/dashboard';
  public suUrl                       : any = '/sign-up';
  public siAPI                       : any = 'user-site-login.php';

  constructor(
    private authenticationService  : AuthenticationService,
    private helperService          : HelperService,
    private router                 : Router,
    private facebookLoginService   : FacebookLoginService,
  ) { 
    this.routeCheck();
  }

  ngOnInit(): void {

    if(localStorage.getItem('currentUser')){
      this.router.navigate(['/dashboard']);
    }

    $('input').keyup(function () {
      if($(this).val()){
        this.removeError($(this));
      }
    })

    $('.adultCheckLabel').click(function(){
      console.log('hereee label click');      
    })

  }

  removeError(e){
    if($(e).val()){
      $(e).removeClass('invalid');
    }
  }

  routeCheck() {
    this.routerSubscription = this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {          
        let firstUrl = val.url.split('sign-in/')[1];     
        if(firstUrl && firstUrl == 'applicant'){
          this.rerouteUrl = '/admission/student-application'
          this.suUrl      = '/admission/signup';
          this.siAPI      = 'students/student_login.php'
        } else {
          this.rerouteUrl = '/dashboard';
          this.suUrl      = '/sign-up';
          this.siAPI      = 'user-site-login.php'
        }
             
      }
    })
  }

  signIn(){
    if(this.validateForm()){

      this.loading = true;

      let data = new FormData();
      data.append("action", "login");
      data.append("email", this.login.email);
      data.append("password", this.login.password);
      
      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + this.siAPI;

      this.helperService.httpPostRequests(url, data).then(resp => {

        if (resp.status != 'ERROR') {
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
  
          this.login = { email: '', password: '' };

          if(!this.modalView){
            this.router.navigate([this.rerouteUrl]);
          } else {
            this.modalShowEvent.emit('contact-details');
          }

        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;
  
      }).catch(error => {
          console.error("error: ",error);
      });

    } else {
      console.log('invalid');
    }
  }

  validateForm(){
    if(this.validateEmail() && this.login.password){
      return true;
    } else if(!this.login.password) {
      $('#password').addClass('invalid');
    }
  }

  validateEmail(){
    if (this.login.email.includes('.') && this.login.email.includes('@'))
    {
      return true;
    } else {
      $('#email').addClass('invalid');
      return false;
    }
  }

  toggleforget(){
    this.forgetPass = !this.forgetPass;
    $('div').toggleClass('navItems');
  }
  removeforget(){
    this.forgetPass = !this.forgetPass;
    $('div').removeClass('navItems');
  }

  signInWithFB(): void {
    this.facebookLoginService.login().then(user => {
      
      console.log('user::', user);
      
      this.sendFbUser(user);
      
    });
  }

  sendFbUser(user){
    let data = new FormData();
    data.append("action", "social_signup");

    for (let key in user) {
      data.append(key, user[key]);
    }

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';
    
    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){
        // Set User in localStorage
        this.authenticationService.setCurrentUserValue(resp.data);
        this.router.navigate(this.rerouteUrl);        
      } else {
        this.errorMsg = resp.message;
        setTimeout(() => {
          this.errorMsg = '';
        }, 3000)
      }
      
    }).catch(error => {
        console.error("error: ", error);  
    });

  }

  changeParentStep(stepName) {
    this.stepChangeEvent.emit(stepName);
  }  

}
