import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, COUNTRYLIST, HelperService, SEOService } from 'src/app/services/_services';

declare var $;

@Component({
  selector: 'app-admission-signup',
  templateUrl: './admission-signup.component.html',
  styleUrls: ['./admission-signup.component.css']
})
export class AdmissionSignupComponent implements OnInit, AfterViewInit {

  @ViewChild ('nameInput') nameInput;

  public signUp                     : any = {
                                               "firstName"         : '',
                                               "lastName"          : '',
                                               "name"              : '',
                                               "email"             : '',
                                               "phone"             : '',
                                               "password"          : '',
                                               "confirmPass"       : '',
                                            }
 public showPass                    : any = false;
 public showConfirmPass             : any = false;
 public errorMsg                    : any
 public loading                     : any = false;
 public countryOptions              : any ;
 public flagOptions                 : any ;
 public selectedFlag                : any = 'PK';
 public selectedCountry             : any = "Pakistan";

 public authSub                     : any;
 public currentUser                 : any;

 public OTPPopup                    : any = false;
 public OTP                         : any = '';
 public OTPerror                    : any;
 public OTPLoading                  : any = false;
 public OTPTimer                    : any;

 public coutryCode                 : any;

  constructor(
    private http                   : HttpClient,
    private authenticationService  : AuthenticationService,
    private router                 : Router,
    private helperService          : HelperService,
    private seoService: SEOService,

    ) {

    }

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus()

  }

  ngOnInit(): void {

    // this.showOTPModal()

    if(localStorage.getItem('currentUser')){
      this.router.navigate(['/admission/fill-form']);
    } else {
      this.getGeoLocs();
    }

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
        const countries = [];
        const countriesImg = [];
        for (let i = 0; i < resp.length; ++i) {
            const country = resp[i];
            countries.push(country.text);
            countriesImg.push(country.value);
        }
        this.countryOptions = countries;
        this.flagOptions = countriesImg;
        this.setCountryCode();
    });

    $('input').keyup(function () {
      if($(this).val()){
        $(this).removeClass('invalid');
      }
    })
    this.setSEO_Tags("Sign Up for Admission to Pakistan Sweet Home","Sign up for admission to Pakistan Sweet Home, where orphans receive love, care, and education to build a brighter future.")
  }

  getGeoLocs(){
    const url = "https://portal.pakistansweethome.org.pk/media.php?action=get_details_from_ip";
      this.helperService.httpGetRequests(url).then(resp => {
        this.selectedFlag = resp.geoplugin_countryCode;
      })
  }

  newUser(){

    if(this.validateForm()){
      this.loading = true;

      this.setPhoneCode();

      let data = new FormData();
      data.append("action",        "signup_stage_1");
      data.append("sendVerificationCode",        "1");
      data.append("firstName",          this.signUp.firstName);
      data.append("lastName",          this.signUp.lastName);
      data.append("email",         this.signUp.email);
      data.append("country",         this.selectedFlag);
      data.append("phone_number",         this.signUp.phone);
      data.append("password", this.signUp.password);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          this.showOTPModal();
          // Set User in localStorage
          // this.authenticationService.setCurrentUserValue(resp.data);
          // this.router.navigate(['/admission/fill-form']);
        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;

      }).catch(error => {
          console.error("error: ");
          this.loading = false;
      });


    } else {
      console.log('invalid');
    }

  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  showOTPModal(){
    this.OTPPopup = true;
    this.fixBody();
    this.OTPTimer = 180;

    setInterval(() => {
      if(this.OTPTimer){
        this.OTPTimer--;
      } else {
        return;
      }
    }, 1000);

  }
  hideOTPModal(){
    this.OTPPopup = false;
    this.unfixBody();
    this.OTPTimer = null;
  }

  validateForm(){
    if(this.validateEmail() && this.validateName() && this.signUp.phone && (this.signUp.phone.length > 7) && (this.signUp.password.length > 4) && (this.signUp.password == this.signUp.confirmPass) ){
      return true;
    } else {
      if(!(this.signUp.firstName && this.signUp.lastName)){
        $('#name').addClass('invalid');
      }
      if(!this.signUp.phone){
        $('#phone').addClass('invalid');
      }
      if(this.signUp.phone.length < 8){
        $('#phone').addClass('invalid');
      }
      if(this.signUp.password.length < 5){
        $('#password').addClass('invalid');
      }
      if(this.signUp.password != this.signUp.confirmPass){
        $('#confirmPass').addClass('invalid');
      }
      return false;
    }
  }

  validateEmail(){
    if (this.signUp.email.includes('@') &&  this.signUp.email.includes('.'))
    {
      return true;
    } else {
      $('#email').addClass('invalid');
      return false;
    }
  }

  validateName(){
    if(!this.signUp.name){
      return false;
    } else {
      let name = this.signUp.name.split(" ");
        this.signUp.firstName = name[0];
        this.signUp.lastName = name[1];
        if(this.signUp.firstName && this.signUp.lastName){
          return true;
        } else {
          return false;
        }
    }
  }

  verifyCode(){

    if(this.OTP.toString().length != 4){
      console.log('asdjhasjkdhasd::',this.OTP.toString().length);

      this.OTPerror = 'Please Enter Valid Code!';
          setTimeout(() => {
            this.OTPerror = '';
          }, 3000)
    } else {
      this.OTPLoading = true;

      let data = new FormData();
      data.append("action",        "account_verification");
      data.append("email",        this.signUp.email);
      data.append("otp",        this.OTP);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if(resp.status != 'ERROR'){
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
          if(localStorage.getItem('admissionLink')){
            var redirectTo = localStorage.getItem('admissionLink');
            localStorage.removeItem('admissionLink'); 
            this.router.navigate([redirectTo]);
          } else {
            this.router.navigate(['/admission/fill-form']);
          }
        } else {
          this.OTPerror = resp.message;
          setTimeout(() => {
            this.OTPerror = '';
          }, 3000)
        }

        this.OTPLoading = false;

      }).catch(error => {
          console.error("error: ");
          this.OTPLoading = false;
      });

    }
  }

  fixBody(){
    $('body').css('height','100vh');
  }
  unfixBody(){
    $('body').css('height','unset');
  }

  sendOTP(){
    // this.showOTPModal();
    let data = new FormData();
    data.append("action",        "resend_otp");
    data.append("email",         this.signUp.email);

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';

    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){
        // Set User in localStorage
        this.showOTPModal();
      } else {
        this.OTPerror = resp.message;
        setTimeout(() => {
          this.OTPerror = '';
        }, 3000)
      }

      this.loading = false;

    }).catch(error => {
        console.error("error: ");
        this.loading = false;
    });


  }

  setPhoneCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0]){
      var temp = COUNTRYLIST.COUNTRY_LIST;
      if(this.signUp.phone && !this.signUp.phone.includes('+')){
        this.signUp.phone = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.signUp.phone;
      } else if(!this.signUp.phone.includes('+')) {
        var tempNum = this.signUp.phone.split(' ');
        this.signUp.phone = tempNum[1];
        this.signUp.phone = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.signUp.phone;
      } else {
        this.signUp.phone = this.signUp.phone.split(' ')[1];
        this.signUp.phone = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.signUp.phone;
      }
    }
  }

  setCountryCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] && !this.coutryCode){
      var temp = COUNTRYLIST.COUNTRY_LIST;      
        this.coutryCode = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0];
      }
    }

    checkPhoneNumber(){
      if(this.signUp.phone.length == 1 && this.signUp.phone == '0'){
        this.signUp.phone = '';
      }
    }

}
