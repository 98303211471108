import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-fund-raising-event',
  templateUrl: './fund-raising-event.component.html',
  styleUrls: ['./fund-raising-event.component.css']
})
export class FundRaisingEventComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $(document).ready(function () {
      $('.card .card-body:first-child').show();

      $('.card-head').click(function () {
        console.log('askjdhasudbhm gere')
        if ($(this).parent().hasClass("show")) {
          $(this).parent().removeClass("show").find(".card-body").slideUp("fast");
        } else {
          $(".card .card-body").slideUp("fast");
          $(".card").removeClass("show");
          $(this).parent().addClass("show").find(".card-body").slideDown("fast");

        }
        return false;

      });

    });

  }

}
