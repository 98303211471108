import { Component, OnInit } from '@angular/core';
declare var $;

@Component({
  selector: 'app-our-projects',
  templateUrl: './our-projects.component.html',
  styleUrls: ['./our-projects.component.css']
})
export class OurProjectsComponent implements OnInit {

  public activeProjectType : any = 'campuses';

  constructor() { 
    
  }

  ngOnInit(): void {
    // this.addWatermark();    
  }

  changeProject(projectName){
    this.activeProjectType = projectName;
    // this.addWatermark();
  }
  addWatermark(){
      setTimeout(() => {
        var pshLogo = $('.navbarLogo')[0];
        $('.galleryCanvas').each(function(){
          var canvas = $(this)[0];
          var image = $(this).siblings('img')[0];          
    
          canvas.width = image.width;
          canvas.height = image.height;
          var ctx = canvas.getContext('2d');
          ctx.drawImage(image, 0, 0);        
          ctx.drawImage(pshLogo, 40, 100);        
    
          // ctx.font = '30px Arial';
          // ctx.fillStyle = 'rgba(255, 0, 0, 0.5)'; // Set the color and transparency of the watermark
          // ctx.fillText('Watermark Text', 50, 50); // Specify the text and position of the watermark
    
          var modifiedImage = canvas.toDataURL('image/png');
          image.style.display = "none";
          canvas.style.display = "block";
        })
      }, 1000);
    }

}

