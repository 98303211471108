<section class="sponsorChildBanner">
    <div class="container">
        <div class="bannerWrapper">
            <div class="bannerContent">
                <h1>Make a Big Impact: Host a Fundraising Event for Pakistan Sweet Homee</h1>
                <div class="bannerDescWrapper">
                    <p>Join hands with us and create a change!</p>
                    <p>By hosting, you're not just raising funds, but also spreading awareness about the vital work we do.</p>
                </div>
            </div>
            <div class="bannerImg">
                <div class="imagesWrapper">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="dsktpOnly" src="assets/new-images/sponsorship-banner-img.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                    <img class="mobOnly" src="assets/new-images/sponsorship-banner-img-mob.png" alt="banner img">
                </div>
            </div>
        </div>
    </div>
</section>

<section class="main_accord" id="scroll-section7">
    <div class="container">
      <div class="main-card" data-aos="fade-up" data-aos-duration="800" data-aos-delay="200">
        <div class="card show" >
          <div class="card-head">
            <h3>Who can apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body">
            <section class="admissionApply">
                  <div class="main_apply">
                    <div class="apply_left">
                      <ol class="noStyle">
                        <li><strong>1.</strong> Students in 7th or 8th grade. Candidates must have passed Class 7 or waiting for result when applying to the college.</li>
                        <li><strong>2.</strong> Must be between 12 and 14 years old on 1st April of the year of admission.</li>
                        <li><strong>3.</strong> Candidates must be medically fit.                      </li>
                        <li><strong>4.</strong> Only orphans can apply.                      </li>
                      </ol>
                    </div>
                  </div>
            </section>
          </div>
        </div>
        <div class="card" >
          <div class="card-head">
            <h3>Who can apply?</h3>
            <span class="faq-icon">
              <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M6.5 9L0.00480961 0.75L12.9952 0.749999L6.5 9Z" fill="#5E5E5E" />
              </svg>
            </span>
          </div>
          <div class="card-body" style="display:none">
            <section class="admissionApply">
                  <div class="main_apply">
                    <div class="apply_left">
                      <ol class="noStyle">
                        <li><strong>1.</strong> Students in 7th or 8th grade. Candidates must have passed Class 7 or waiting for result when applying to the college.</li>
                        <li><strong>2.</strong> Must be between 12 and 14 years old on 1st April of the year of admission.</li>
                        <li><strong>3.</strong> Candidates must be medically fit.                      </li>
                        <li><strong>4.</strong> Only orphans can apply.                      </li>
                      </ol>
                    </div>
                  </div>
            </section>
          </div>
        </div>

      </div>
    </div>
  </section>