<section class="applyNowSec" *ngIf="!loading && applicationInfo">
  <div class="container">
    <div class="allWrapUp">
      <div class="stepsWrapperOuter">
        <div class="stepsWrapper">
          <div class="singleStep completed">
            <p class="stepNumber">1</p>
            <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Candidate Information</p>
          </div>

          <svg width="70" height="2" viewBox="0 0 70 2" fill="none" xmlns="http://www.w3.org/2000/svg">
            <line y1="1" x2="70" y2="1" stroke="#EEEEF1" stroke-width="2" stroke-dasharray="4 4" />
          </svg>


          <div class="singleStep ongoing">
            <p class="stepNumber">2</p>
            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="20" cy="20" r="20" fill="#003366" />
              <path
                d="M28.1577 13.5406C27.788 13.2173 27.2251 13.2538 26.9009 13.6243L17.417 24.4629L13.0794 19.8359C12.7428 19.4778 12.1798 19.46 11.8209 19.7958C11.4619 20.1316 11.4432 20.6963 11.7799 21.0552L16.79 26.3993C16.9592 26.5783 17.1934 26.6798 17.4402 26.6798H17.4553C17.7074 26.6762 17.9443 26.5658 18.1108 26.3761L28.2423 14.7973C28.5656 14.4268 28.5282 13.8648 28.1577 13.5406Z"
                fill="white" stroke="white" />
            </svg>
            <p class="stepName">Review & Submit</p>
          </div>
        </div>
      </div>
    </div>
    <div class="reviewBox" >
        <div class="generalDetails">
            <div class="inputDetailsWrapper">
                <div class="singleDetail">
                    <p>Child Name:</p>
                    <p>{{ applicationInfo.name }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Previous Class:</p>
                    <p>{{ applicationInfo.education_level }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Class Applying:</p>
                    <p>{{ applicationInfo.class }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Current Grades:</p>
                    <p>{{ applicationInfo.current_grade }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Date of Birth:</p>
                    <p>{{ applicationInfo.dob | date }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Guardian’s Full Name:</p>
                    <p>{{ applicationInfo.guardian_name }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Contact Number:</p>
                    <p>{{ applicationInfo.phone }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Whatsapp Number:</p>
                    <p>{{ applicationInfo.whatsapp }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Full Residential Address:</p>
                    <p>{{ applicationInfo.address }}</p>
                </div>                
                <div class="singleDetail">
                    <p>City:</p>
                    <p>{{ applicationInfo.city }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Total Family Members:</p>
                    <p>{{ applicationInfo.family_members }}</p>
                </div>                
                <div class="singleDetail">
                    <p>Total Family Income:</p>
                    <p>{{ applicationInfo.family_income }}</p>
                </div>                
                <div class="singleFile" *ngFor="let academicRecord of applicationInfo.accedemic_record">
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.6172 5.27344L13.5156 4.10156L12.3438 0H4.14062C3.1698 0 2.38281 0.786992 2.38281 1.75781V18.2422C2.38281 19.213 3.1698 20 4.14062 20H15.8594C16.8302 20 17.6172 19.213 17.6172 18.2422V5.27344Z" fill="#4086F4"/>
                        <path d="M17.6172 5.27344V18.2422C17.6172 19.213 16.8302 20 15.8594 20H10V0H12.3438L13.5156 4.10156L17.6172 5.27344Z" fill="#4175DF"/>
                        <path d="M17.6172 5.27344H13.5156C12.8711 5.27344 12.3438 4.74609 12.3438 4.10156V0C12.4961 0 12.6484 0.0585938 12.7539 0.17582L17.4414 4.86332C17.5586 4.96875 17.6172 5.12109 17.6172 5.27344Z" fill="#80AEF8"/>
                        <path d="M13.5156 9.41406H6.48438C6.16051 9.41406 5.89844 9.15199 5.89844 8.82812C5.89844 8.50426 6.16051 8.24219 6.48438 8.24219H13.5156C13.8395 8.24219 14.1016 8.50426 14.1016 8.82812C14.1016 9.15199 13.8395 9.41406 13.5156 9.41406Z" fill="#FFF5F5"/>
                        <path d="M13.5156 11.7578H6.48438C6.16051 11.7578 5.89844 11.4957 5.89844 11.1719C5.89844 10.848 6.16051 10.5859 6.48438 10.5859H13.5156C13.8395 10.5859 14.1016 10.848 14.1016 11.1719C14.1016 11.4957 13.8395 11.7578 13.5156 11.7578Z" fill="#FFF5F5"/>
                        <path d="M13.5156 14.1016H6.48438C6.16051 14.1016 5.89844 13.8395 5.89844 13.5156C5.89844 13.1918 6.16051 12.9297 6.48438 12.9297H13.5156C13.8395 12.9297 14.1016 13.1918 14.1016 13.5156C14.1016 13.8395 13.8395 14.1016 13.5156 14.1016Z" fill="#FFF5F5"/>
                        <path d="M11.1719 16.4453H6.48438C6.16051 16.4453 5.89844 16.1832 5.89844 15.8594C5.89844 15.5355 6.16051 15.2734 6.48438 15.2734H11.1719C11.4957 15.2734 11.7578 15.5355 11.7578 15.8594C11.7578 16.1832 11.4957 16.4453 11.1719 16.4453Z" fill="#FFF5F5"/>
                        <path d="M10 16.4453H11.1719C11.4957 16.4453 11.7578 16.1832 11.7578 15.8594C11.7578 15.5355 11.4957 15.2734 11.1719 15.2734H10V16.4453Z" fill="#E3E7EA"/>
                        <path d="M10 14.1016H13.5156C13.8395 14.1016 14.1016 13.8395 14.1016 13.5156C14.1016 13.1918 13.8395 12.9297 13.5156 12.9297H10V14.1016Z" fill="#E3E7EA"/>
                        <path d="M10 11.7578H13.5156C13.8395 11.7578 14.1016 11.4957 14.1016 11.1719C14.1016 10.848 13.8395 10.5859 13.5156 10.5859H10V11.7578Z" fill="#E3E7EA"/>
                        <path d="M10 9.41406H13.5156C13.8395 9.41406 14.1016 9.15199 14.1016 8.82812C14.1016 8.50426 13.8395 8.24219 13.5156 8.24219H10V9.41406Z" fill="#E3E7EA"/>
                        </svg>
                    <a target="_blank" [href]="academicRecord.url" >{{ academicRecord.url | fileNameFromUrl }}</a>                        
                </div>
                <div class="singleFile" >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.6172 5.27344L13.5156 4.10156L12.3438 0H4.14062C3.1698 0 2.38281 0.786992 2.38281 1.75781V18.2422C2.38281 19.213 3.1698 20 4.14062 20H15.8594C16.8302 20 17.6172 19.213 17.6172 18.2422V5.27344Z" fill="#4086F4"/>
                        <path d="M17.6172 5.27344V18.2422C17.6172 19.213 16.8302 20 15.8594 20H10V0H12.3438L13.5156 4.10156L17.6172 5.27344Z" fill="#4175DF"/>
                        <path d="M17.6172 5.27344H13.5156C12.8711 5.27344 12.3438 4.74609 12.3438 4.10156V0C12.4961 0 12.6484 0.0585938 12.7539 0.17582L17.4414 4.86332C17.5586 4.96875 17.6172 5.12109 17.6172 5.27344Z" fill="#80AEF8"/>
                        <path d="M13.5156 9.41406H6.48438C6.16051 9.41406 5.89844 9.15199 5.89844 8.82812C5.89844 8.50426 6.16051 8.24219 6.48438 8.24219H13.5156C13.8395 8.24219 14.1016 8.50426 14.1016 8.82812C14.1016 9.15199 13.8395 9.41406 13.5156 9.41406Z" fill="#FFF5F5"/>
                        <path d="M13.5156 11.7578H6.48438C6.16051 11.7578 5.89844 11.4957 5.89844 11.1719C5.89844 10.848 6.16051 10.5859 6.48438 10.5859H13.5156C13.8395 10.5859 14.1016 10.848 14.1016 11.1719C14.1016 11.4957 13.8395 11.7578 13.5156 11.7578Z" fill="#FFF5F5"/>
                        <path d="M13.5156 14.1016H6.48438C6.16051 14.1016 5.89844 13.8395 5.89844 13.5156C5.89844 13.1918 6.16051 12.9297 6.48438 12.9297H13.5156C13.8395 12.9297 14.1016 13.1918 14.1016 13.5156C14.1016 13.8395 13.8395 14.1016 13.5156 14.1016Z" fill="#FFF5F5"/>
                        <path d="M11.1719 16.4453H6.48438C6.16051 16.4453 5.89844 16.1832 5.89844 15.8594C5.89844 15.5355 6.16051 15.2734 6.48438 15.2734H11.1719C11.4957 15.2734 11.7578 15.5355 11.7578 15.8594C11.7578 16.1832 11.4957 16.4453 11.1719 16.4453Z" fill="#FFF5F5"/>
                        <path d="M10 16.4453H11.1719C11.4957 16.4453 11.7578 16.1832 11.7578 15.8594C11.7578 15.5355 11.4957 15.2734 11.1719 15.2734H10V16.4453Z" fill="#E3E7EA"/>
                        <path d="M10 14.1016H13.5156C13.8395 14.1016 14.1016 13.8395 14.1016 13.5156C14.1016 13.1918 13.8395 12.9297 13.5156 12.9297H10V14.1016Z" fill="#E3E7EA"/>
                        <path d="M10 11.7578H13.5156C13.8395 11.7578 14.1016 11.4957 14.1016 11.1719C14.1016 10.848 13.8395 10.5859 13.5156 10.5859H10V11.7578Z" fill="#E3E7EA"/>
                        <path d="M10 9.41406H13.5156C13.8395 9.41406 14.1016 9.15199 14.1016 8.82812C14.1016 8.50426 13.8395 8.24219 13.5156 8.24219H10V9.41406Z" fill="#E3E7EA"/>
                        </svg>
                    <a target="_blank" [href]="applicationInfo.cnic_img" >{{ applicationInfo.cnic_img | fileNameFromUrl }}</a>                        
                </div>
                <div class="singleFile" >
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M17.6172 5.27344L13.5156 4.10156L12.3438 0H4.14062C3.1698 0 2.38281 0.786992 2.38281 1.75781V18.2422C2.38281 19.213 3.1698 20 4.14062 20H15.8594C16.8302 20 17.6172 19.213 17.6172 18.2422V5.27344Z" fill="#4086F4"/>
                        <path d="M17.6172 5.27344V18.2422C17.6172 19.213 16.8302 20 15.8594 20H10V0H12.3438L13.5156 4.10156L17.6172 5.27344Z" fill="#4175DF"/>
                        <path d="M17.6172 5.27344H13.5156C12.8711 5.27344 12.3438 4.74609 12.3438 4.10156V0C12.4961 0 12.6484 0.0585938 12.7539 0.17582L17.4414 4.86332C17.5586 4.96875 17.6172 5.12109 17.6172 5.27344Z" fill="#80AEF8"/>
                        <path d="M13.5156 9.41406H6.48438C6.16051 9.41406 5.89844 9.15199 5.89844 8.82812C5.89844 8.50426 6.16051 8.24219 6.48438 8.24219H13.5156C13.8395 8.24219 14.1016 8.50426 14.1016 8.82812C14.1016 9.15199 13.8395 9.41406 13.5156 9.41406Z" fill="#FFF5F5"/>
                        <path d="M13.5156 11.7578H6.48438C6.16051 11.7578 5.89844 11.4957 5.89844 11.1719C5.89844 10.848 6.16051 10.5859 6.48438 10.5859H13.5156C13.8395 10.5859 14.1016 10.848 14.1016 11.1719C14.1016 11.4957 13.8395 11.7578 13.5156 11.7578Z" fill="#FFF5F5"/>
                        <path d="M13.5156 14.1016H6.48438C6.16051 14.1016 5.89844 13.8395 5.89844 13.5156C5.89844 13.1918 6.16051 12.9297 6.48438 12.9297H13.5156C13.8395 12.9297 14.1016 13.1918 14.1016 13.5156C14.1016 13.8395 13.8395 14.1016 13.5156 14.1016Z" fill="#FFF5F5"/>
                        <path d="M11.1719 16.4453H6.48438C6.16051 16.4453 5.89844 16.1832 5.89844 15.8594C5.89844 15.5355 6.16051 15.2734 6.48438 15.2734H11.1719C11.4957 15.2734 11.7578 15.5355 11.7578 15.8594C11.7578 16.1832 11.4957 16.4453 11.1719 16.4453Z" fill="#FFF5F5"/>
                        <path d="M10 16.4453H11.1719C11.4957 16.4453 11.7578 16.1832 11.7578 15.8594C11.7578 15.5355 11.4957 15.2734 11.1719 15.2734H10V16.4453Z" fill="#E3E7EA"/>
                        <path d="M10 14.1016H13.5156C13.8395 14.1016 14.1016 13.8395 14.1016 13.5156C14.1016 13.1918 13.8395 12.9297 13.5156 12.9297H10V14.1016Z" fill="#E3E7EA"/>
                        <path d="M10 11.7578H13.5156C13.8395 11.7578 14.1016 11.4957 14.1016 11.1719C14.1016 10.848 13.8395 10.5859 13.5156 10.5859H10V11.7578Z" fill="#E3E7EA"/>
                        <path d="M10 9.41406H13.5156C13.8395 9.41406 14.1016 9.15199 14.1016 8.82812C14.1016 8.50426 13.8395 8.24219 13.5156 8.24219H10V9.41406Z" fill="#E3E7EA"/>
                        </svg>
                    <a target="_blank" [href]="applicationInfo.father_death_certificate" >{{ applicationInfo.father_death_certificate | fileNameFromUrl }}</a>                        
                </div>
            </div>
        </div>
        <div class="statements">
            <p class="statementTitle">Brief Statement on why the child should be considered for admission</p>
            <div class="statementBox">
                <p> {{ applicationInfo.admission_statement }} </p>
            </div>
        </div>
        <div class="submitButtonOuter">
            <button type="button" class="formSubmitBtn editBtn" (click)="editApplication()" >Edit</button>
            <button type="button" class="formSubmitBtn" (click)="submitApplication()" >Submit</button>
        </div>
    </div>    
  </div>
</section>

<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img id="loaderStatus" class="loaderImage" alt="logo" src="assets/new-images/blue_psh_logo.png">
</div>

<section class="thanksPopup" *ngIf="thanksPopupShow">
  <div class="overlay"></div>
  <div class="thanksModal">        
      <img class="tickGif" src="/assets/new-images/tickAnim.webp" alt="tick">
      <p class="thanksHead">Thank you for applying!</p>
      <p class="thanksDesc">
          Our Admissions Committee is currently in the process of taking applications
          and will begin reviewing them.
      </p>
      <p class="botText thanksDesc">
          If you are among the shortlisted candidates, we will contact you <br>
          via email or phone.
      </p>
      <div class="thanksBtnWrapper">
          <a class="thanksOKBtn" href="javascript:;" routerLink="/admission/student-application">Okay</a>
          <a class="downloadOKBtn" *ngIf="admissionInfo.class_applying == 'preCadet' " href="/assets/sample-tests/Model-Paper.pdf" download>Download Past Exam Papers</a>
      </div>
  </div>
</section>