import { Component, OnInit } from '@angular/core';
import { AppSettings, HelperService } from 'src/app/services/_services';
import Swal from 'sweetalert2';
declare var $;

@Component({
  selector: 'app-forget',
  templateUrl: './forget.component.html',
  styleUrls: ['./forget.component.css']
})
export class ForgetComponent implements OnInit {
  public forgetPass: any = false;
  public loading                     : any = false;  
  public tour         : any = {
    "email"             : '',
  }

    

  constructor(
    protected helperService : HelperService
  ) { }

  ngOnInit(): void {
  }

  validateFormtour(){
    if(this.tour.email || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.tour.email)){
      this.forgotPassword().then((resp)=>{
        // this.toggleforget()
      });
    }
    else{
    $('#email').addClass('invalid');        
    }
  }
  


  toggleforget(){
    this.forgetPass = !this.forgetPass;
    $('div').toggleClass('navItems');
  }

  removeforget(){
    this.forgetPass = !this.forgetPass;
    $('div').removeClass('navItems');
  }

  removeInvalid(e){
    if($(e).val()){
      $(e).removeClass('invalid');
    }
  }

  forgotPassword(){
    this.loading = true;

    let url  = AppSettings.API_ENDPOINT + 'user-site-login.php';    

    let data = new FormData();
    data.append("action", "forgot-password");
    data.append("email", this.tour.email);
    
    return this.helperService.httpPostRequests(url, data).then(resp => {
      this.loading = false;
      resp.message && Swal.fire('Success', resp.message, 'success');
    }).catch(error => {
      Swal.fire('Error', 'Something went wrong, Check your internet connection and try again', 'error');
        console.error("error: ",error);
        this.loading = false;
    });
    
  }

}
