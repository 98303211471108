import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zamurrad-khan-inaugurates',
  templateUrl: './zamurrad-khan-inaugurates.component.html',
  styleUrls: ['./zamurrad-khan-inaugurates.component.css' , '../articles.component.css']
})
export class ZamurradKhanInauguratesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
