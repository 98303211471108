import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettings, AuthenticationService, HelperService } from 'src/app/services/_services';

declare var $

@Component({
  selector: 'app-admission-login',
  templateUrl: './admission-login.component.html',
  styleUrls: ['./admission-login.component.css']
})
export class AdmissionLoginComponent implements OnInit {

  @ViewChild ('nameInput') nameInput;

  public signUp                     : any = {
                                               "email"             : '',                                               
                                               "password"          : '',                                               
                                            }
 public showPass                    : any = false;    
 public errorMsg                    : any  
 public loading                     : any = false;
 public countryOptions              : any ;
 public flagOptions                 : any ;
 public selectedFlag                : any = 'PK';
 public selectedCountry             : any = "Pakistan";

 public authSub                     : any;
 public currentUser                 : any;

 public OTPPopup                    : any = false;
 public OTP                         : any = '';
 public OTPerror                    : any;
 public OTPLoading                  : any = false;

  constructor(
    private http                   : HttpClient,
    private authenticationService  : AuthenticationService,
    private router                 : Router,
    private helperService          : HelperService,
    ) { 

    }

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus()
    
  }

  ngOnInit(): void {
    
    if(localStorage.getItem('currentUser')){
      this.router.navigate(['/admission/fill-form']);
    } 

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
        const countries = [];
        const countriesImg = [];
        for (let i = 0; i < resp.length; ++i) {
            const country = resp[i];
            countries.push(country.text);
            countriesImg.push(country.value);
        }
        this.countryOptions = countries;
        this.flagOptions = countriesImg;
    });

    $('input').keyup(function () {
      if($(this).val()){
        $(this).removeClass('invalid');
      }
    })

  }
  
  validateForm(){
    if(this.validateEmail() && this.signUp.password.length > 4 ){
      return true;       
    } else {
      if(this.signUp.password.length < 5){
        $('#password').addClass('invalid');
      }
      return false;
    }
  }

  validateEmail(){
    if (this.signUp.email.includes('@') &&  this.signUp.email.includes('.'))
    {
      return true;
    } else {
      $('#email').addClass('invalid');
      return false;
    }
  }

  login(){
    if(this.validateForm()){
      this.loading = true;

      let data = new FormData();
      data.append("action", "login");
      data.append("email", this.signUp.email);
      data.append("password", this.signUp.password);
      
      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        'Accept': 'multipart/form-data',
      });

      let url  = AppSettings.API_ENDPOINT + 'students/student_login.php';

      this.helperService.httpPostRequests(url, data).then(resp => {

        if (resp.status != 'ERROR') {
          // Set User in localStorage
          this.authenticationService.setCurrentUserValue(resp.data);
          
          this.signUp = { email: '', password: '' };

          if(localStorage.getItem('admissionLink')){
            var redirectTo = localStorage.getItem('admissionLink');
            localStorage.removeItem('admissionLink'); 
            this.router.navigate([redirectTo]);
          } else {
            this.router.navigate(['/admission/fill-form']);
          }

        } else {
          this.errorMsg = resp.message;
          setTimeout(() => {
            this.errorMsg = '';
          }, 3000)
        }

        this.loading = false;
  
      }).catch(error => {
          console.error("error: ",error);
      });

    }
  }



}
