import { Component, OnInit, HostListener, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppSettings,
  SEOService,
  HelperService,
} from 'src/app/services/_services';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-dynamic-blog',
  templateUrl: './dynamic-blog.component.html',
  styleUrls: ['./dynamic-blog.component.css'],
})
export class DynamicBlogComponent implements OnInit {
  public loading = true;
  public pageSlug;
  public popularPages;
  public parentSlug;
  public pageData;
  public moreChildren;
  public isMobile = false;
  private jsonld: any;
  public jsonLDBlogData: any;
  public jsonLDBreadCrumbs: any;
  public jsonLDFaqs: any;

  screenSize: any = {
    width: window.innerWidth,
  };
  TOC: string[] = []; // Assume this gets populated with the headings
  progressWidth: number = 0;
  showTocItems: boolean = false;
  tocClass: string = '';
  activeFaq: string = '';
  sanitizedContent: SafeHtml;

  constructor(
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute,
    public router: Router,
    private seoService: SEOService,
    private http: HttpClient,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer
  ) {
    this.activatedRoute.params.subscribe((routeParams) => {
      if (routeParams.id && routeParams.slug) {
        this.parentSlug = routeParams.id;
        this.pageSlug = routeParams.slug;
      } else {
        this.pageSlug = routeParams.id;
      }
      // Get BlogPage Info
      if (this.pageSlug) {
        this.getBlog();
        // this.extractHeadings();
      } else {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < 576) {
      this.isMobile = true;
    }
    window.addEventListener('resize', () => {
      this.screenSize.width = window.innerWidth;
    });
    if (this.pageData) {
      this.handleScroll(); // Initialize the scroll handler
    }
    setTimeout(() => {
      this.setJsonldBlogData();
      this.setJsonldBreadCrumbs();
      this.setJsonldFaqs();
    }, 2000);
  }
 
  scrollToHeading(headingText: string): void {
    const headings = document.querySelectorAll('h2');
    headings.forEach((heading: HTMLElement) => {
      if (heading.innerHTML === headingText) {
        const headingPosition = heading.offsetTop;
        window.scrollTo({ top: headingPosition - 150, behavior: 'smooth' });
      }
    });
  }

  formatDate(date: string): string {
    const d = new Date(date);
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  }

  getBlog() {
    this.loading = true;
    const url =
      'https://adminbackend.pakistansweethome.org.pk/blogs/pages/get-page-data';
    const data = {
      page_slug: this.pageSlug,
      parent_slug: this.parentSlug,
      page_type: 'blog',
      site_url: 'www.pakistansweethome.org.pk',
    };

    this.http.post(url, data).subscribe(
      (resp: any) => {
        if (resp && resp.data) {
          this.pageData = resp.data;

          this.setSEO_Tags(
            this.pageData.meta_title,
            this.pageData.meta_description
          );
          this.popularPages = resp?.popular_pages;

          this.sanitizeContent(); // Sanitize content after receiving it

          setTimeout(() => {
            this.extractHeadings();
          }, 500);
        } else {
          // Handle case when no data is received
        }
        this.loading = false;
      },
      (error) => {
        console.error('Error:', error);
        this.loading = false;
      }
    );
  }

  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }

  sanitizeContent(): void {
    this.sanitizedContent = this.sanitizer.bypassSecurityTrustHtml(
      this.pageData.text
    );
  }

  extractHeadings(): void {
    const blogTextWrapper =
      this.elementRef.nativeElement.querySelector('.blogTextWrapper');
    if (blogTextWrapper) {
      const headings = blogTextWrapper.querySelectorAll('h2');
      headings.forEach((heading: HTMLElement) => {
        this.TOC.push(heading.textContent.trim());
      });
    }
    console.log('Yeh TOC hai', this.TOC);
  }

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const scrollTop = window.scrollY;
    const firstHeading = document.getElementsByTagName('h2')[0];

    const footer = document.getElementsByClassName('footer')[0];
    const keepReading = document.getElementsByClassName('keepReading')[0];

    if (firstHeading && footer && keepReading) {
      const listItemsPosition = firstHeading.offsetTop;
      const pageHeight =
        document.documentElement.scrollHeight -
        footer.clientHeight -
        keepReading.clientHeight;

      if (scrollTop > listItemsPosition) {
        this.tocClass = 'sticked';
      } else {
        this.tocClass = '';
        this.showTocItems = false;
      }
      this.progressWidth = (scrollTop / pageHeight) * 100;
    }
  }

  toggleFaq(question: string): void {
    this.activeFaq = this.activeFaq === question ? '' : question;
  }

  setJsonldBlogData() {
    if (!this.pageData) {
      return;
    }
    const currentUrl = window.location.href; // Get the current URL

    this.jsonld = {
      '@context': 'https://schema.org',
      '@type': 'Article',
      mainEntityOfPage: {
        '@type': 'WebPage',
        '@id': currentUrl,
      },
      headline: this.pageData.meta_title,
      description: this.pageData.meta_description,
      image: this.pageData.image,
      author: {
        '@type': 'Person',
        name: this.pageData.page_writer.name,
        url: `https://${this.pageData.site_url}/writers/${this.pageData.page_writer.slug}`,
      },
      publisher: {
        '@type': 'Organization',
        name: '',
        logo: {
          '@type': 'ImageObject',
          url: '',
        },
      },
      datePublished: this.formatDate(this.pageData.publishing_time),
      dateModified: this.formatDate(this.pageData.updated_at),
    };

    this.jsonLDBlogData =
      '<script type="application/ld+json">' +
      JSON.stringify(this.jsonld) +
      '</script>';
    this.jsonLDBlogData = this.sanitizer.bypassSecurityTrustHtml(
      this.jsonLDBlogData
    );
  }

  setJsonldBreadCrumbs() {
    if (!this.pageData) {
      return;
    }
    const currentUrl = window.location.href;
    const breadcrumbContainer = document.createElement('div');
    breadcrumbContainer.innerHTML = this.pageData.breadcrumbs;

    const breadcrumbItems =
      breadcrumbContainer.querySelectorAll('.BreadcrumbList p');
    const breadcrumbArray = Array.from(breadcrumbItems).map((item, index) => {
      const anchor = item.querySelector('a');
      return {
        '@type': 'ListItem',
        position: index + 1,
        name: anchor ? anchor.textContent.trim() : item.textContent.trim(),
        item: anchor ? anchor.href : currentUrl,
      };
    });

    this.jsonld = {
      '@context': 'https://schema.org',
      '@type': 'BreadcrumbList',
      itemListElement: breadcrumbArray,
    };

    this.jsonLDBreadCrumbs =
      '<script type="application/ld+json">' +
      JSON.stringify(this.jsonld) +
      '</script>';
    this.jsonLDBreadCrumbs = this.sanitizer.bypassSecurityTrustHtml(
      this.jsonLDBreadCrumbs
    );
  }
  setJsonldFaqs() {
    if (!this.pageData) {
      return;
    }

    const currentUrl = window.location.href;
    const faqs = this.pageData.pages_faqs.map((faq) => ({
      '@type': 'Question',
      name: faq.question,
      acceptedAnswer: {
        '@type': 'Answer',
        text: faq.answerPlain, // Assuming you want to use the plain text version of the answer
      },
    }));

    this.jsonld = {
      '@context': 'https://schema.org',
      '@type': 'FAQPage',
      mainEntity: faqs,
    };

    this.jsonLDFaqs =
      '<script type="application/ld+json">' +
      JSON.stringify(this.jsonld) +
      '</script>';
    this.jsonLDFaqs = this.sanitizer.bypassSecurityTrustHtml(this.jsonLDFaqs);

    console.log('Faqs', this.pageData.pages_faqs);
  }
}
