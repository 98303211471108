<!-- <app-news-banner></app-news-banner> -->
<!-- <div class="menuOverlay" (click)="mobNav = false"></div> -->
<section
  class="navbar normal"
  [ngClass]="page == 'special-friends' ? 'fixed' : ''"
>
  <div class="containerFluid">
    <div class="navbarWrapper">
      <div class="mainNav">
        <a href="javascript:;" routerLink="/"
          ><img
            class="navbarLogo color-logo"
            src="assets/new-images/navbar-logo-new.svg"
            alt="pakistansweethome"
            width="168px"
            height="24px"
        /></a>
        <ng-container>
          <div class="centerHeadWrapper">
            <div class="dropdown lg-drodown">
              <a
                class="about_btn"
                href="javascript:;"
                [ngClass]="
                  page == 'about-us' ||
                  page == 'bank-details' ||
                  page == 'why-us' ||
                  page == 'testimonials'
                    ? 'active'
                    : ''
                "
                >About Us</a
              >
              <div id="myDropdown aboutDDWrapper" class="dropdown-content">
                <div class="dropdownWrapper">
                  <a href="javascript:;" routerLink="/why-us">Why Us</a>
                  <a href="javascript:;" routerLink="/about-us">Our NGO</a>
                  <a href="javascript:;" routerLink="/testimonials"
                    >Testimonials</a
                  >
                  <a href="javascript:;" routerLink="/bank-details"
                    >Bank Details</a
                  >
                  <a href="javascript:;" routerLink="/donation-jazzcash"
                    >JazzCash Details</a
                  >
                  <a href="javascript:;" routerLink="/legal">Legal</a>
                </div>
              </div>
            </div>
            <div class="dropdown lg-drodown">
              <a
                class="about_btn"
                [ngClass]="
                  page == 'gallery' || page == 'our-projects' ? 'active' : ''
                "
                href="javascript:;"
                >Our Work</a
              >
              <div id="myDropdown" class="dropdown-content">
                <div class="dropdownWrapper ourWorkDD">
                  <a href="javascript:;" routerLink="/our-projects"
                    >Our Projects</a
                  >
                  <a href="javascript:;" routerLink="/gallery">Gallery</a>
                </div>
              </div>
            </div>
            <a href="javascript:;" routerLink="/faq" routerLinkActive="active"
              >FAQ's</a
            >
            <a
              href="javascript:;"
              routerLink="/contact-us"
              routerLinkActive="active"
              >Contact</a
            >
            <div class="dropdown lg-drodown">
              <a
                class="about_btn getInvolved_btn"
                [ngClass]="page == 'get-involved' ? 'active' : ''"
                href="javascript:;"
                >Get Involved</a
              >
              <div id="myDropdown" class="dropdown-content getInvolved">
                <div class="dropdownWrapper getInvolvedDD">
                  <a
                    href="javascript:;"
                    routerLink="/get-involved/support-our-needs"
                  >
                    Support Our Needs
                  </a>
                  <a href="javascript:;" routerLink="/special-friends"
                    >Special Friends Club</a
                  >
                  <a href="javascript:;" routerLink="/get-involved/volunteer"
                    >Volunteer</a
                  >
                  <a href="javascript:;" routerLink="/get-involved/request-tour"
                    >Request Site Tour</a
                  >
                </div>
              </div>
            </div>
            <div class="dropdown lg-drodown">
              <a
                class="about_btn admissions_btn"
                [ngClass]="
                  page == 'cadet-college-admission-11th-class' ||
                  page == 'cadet-college-admission-8th-class' ||
                  page == 'boarding-school' ||
                  page == 'education-sponsorship' ||
                  page == 'scholarship-program'
                    ? 'active'
                    : ''
                "
                href="javascript:;"
                >Admissions</a
              >
              <div id="myDropdown" class="dropdown-content admissions">
                <div class="dropdownWrapper admissionskDD">
                  <a
                    href="javascript:;"
                    routerLink="/cadet-college-admission-11th-class"
                    >PSH Cadet College - 11th Class
                  </a>
                  <a
                    href="javascript:;"
                    routerLink="/cadet-college-admission-8th-class"
                    >PSH Cadet College - 8th Class
                  </a>
                  <a href="javascript:;" routerLink="/boarding-school"
                    >PSH Boarding Schools</a
                  >
                  <a href="javascript:;" routerLink="/scholarship-program"
                    >Scholarship Program
                  </a>
                </div>
              </div>
            </div>
            <a
              href="javascript:;"
              routerLink="/updates"
              routerLinkActive="active"
              >Updates</a
            >
          </div>
        </ng-container>
        <div class="endHeadBtnsWrapper">
          <a href="javascript:;" routerLink="/donate" class="newDonateBtn"
            >Donate</a
          >
          <a
            href="javascript:;"
            routerLink="/sponsorship-program"
            class="newSponsorBtn"
            >Sponsor a child</a
          >
          <svg
            width="1"
            height="26"
            viewBox="0 0 1 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line x1="0.5" x2="0.5" y2="26" stroke="#CED9D0" />
          </svg>
          <!-- <a *ngIf="!currentUser"  class="signin_btn" [ngClass]="page == 'cadet-college-admission' && subPage == 'cadet-college-admission' ? 'admision' : page == 'admission' ? 'onlyLog' : '' " [routerLink]="siUrl" href="javascript:;"> -->
          <div class="dropdown lg-drodown" *ngIf="!currentUser">
            <a
              class="about_btn getInvolved_btn"
              [ngClass]="page == 'login' || page == 'sign-in' ? 'active' : ''"
              href="javascript:;"
              >Sign In</a
            >
            <div id="myDropdown" class="dropdown-content getInvolved">
              <div class="dropdownWrapper singinDD">
                <a href="javascript:;" routerLink="/sign-in">Donor Account</a>
                <a href="javascript:;" routerLink="/admission/login"
                  >Student Account</a
                >
              </div>
            </div>
          </div>
          <!-- </a> -->
          <div *ngIf="currentUser" class="userDropdown">
            <a href="javascript:;" class="userDdHead">
              <img
                *ngIf="currentUser.imgUrl"
                [src]="currentUser.imgUrl"
                alt="profile image"
              />
              <img
                *ngIf="!currentUser.imgUrl"
                src="/assets/new-images/noProfileImg.svg"
                alt="profile image"
              />
              <svg
                width="10"
                height="5"
                viewBox="0 0 10 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5 5L0.669872 0.500001L9.33013 0.5L5 5Z"
                  fill="#003366"
                />
              </svg>
            </a>
            <div class="userDdBody" *ngIf="currentUser.type != 'student'">
              <a href="javascript:;" routerLink="/dashboard"
                >Account Dashboard</a
              >
              <a href="javascript:;" routerLink="/dashboard/settings"
                >Settings</a
              >
              <a href="javascript:;" routerLink="/logout">Log out</a>
            </div>
            <div class="userDdBody" *ngIf="currentUser.type == 'student'">
              <a href="javascript:;" routerLink="/admission/student-application"
                >Applications</a
              >
              <a href="javascript:;" routerLink="/admission/student-dashboard"
                >Edit Profile</a
              >
              <a href="javascript:;" routerLink="/logout">Log out</a>
            </div>
          </div>
        </div>
      </div>
      <div class="mob_nav">
        <div class="main_mobnav">
          <div class="mobnav_left">
            <a href="javascript:;" routerLink="/"
              ><img
                class="navbarLogo"
                src="assets/new-images/navbar-logo-new.svg"
                alt="logo"
            /></a>
          </div>
          <div class="mobnav_right">
            <a
              class="donate_btn"
              *ngIf="page == 'special-friends'"
              href="javascript:;"
              routerLink="/add-membership"
              >BECOME A MEMBER</a
            >
            <svg
              width="1"
              height="26"
              viewBox="0 0 1 26"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              class="line"
            >
              <line x1="0.5" x2="0.5" y2="26" stroke="#CED9D0" />
            </svg>
            <a
              class="donate_btn"
              *ngIf="
                !currentUser &&
                mobDonateShow &&
                page != 'special-friends' &&
                page != 'add-membership'
              "
              [ngClass]="
                page == 'cadet-college-admission' &&
                subPage == 'cadet-college-admission'
                  ? 'admision'
                  : page == 'cadet-college-admission'
                  ? 'onlyLog'
                  : ''
              "
              href="javascript:;"
              [routerLink]="
                !admissionPage ? '/sponsorship-program' : '/admission/login'
              "
              >{{
                !admissionPage
                  ? "Sponsor a
              Child"
                  : "Log In"
              }}</a
            >
            <div
              *ngIf="currentUser && page != 'special-friends'"
              class="userDropdown"
            >
              <a href="javascript:;" class="userDdHead">
                <img
                  *ngIf="currentUser.imgUrl"
                  [src]="currentUser.imgUrl"
                  alt="profile image"
                />
                <img
                  *ngIf="!currentUser.imgUrl"
                  src="/assets/new-images/noProfileImg.svg"
                  alt="profile image"
                />
                <svg
                  width="10"
                  height="5"
                  viewBox="0 0 10 5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5 5L0.669872 0.500001L9.33013 0.5L5 5Z"
                    fill="#003366"
                  />
                </svg>
              </a>
              <div class="userDdBody" *ngIf="currentUser.type != 'student'">
                <a href="javascript:;" routerLink="/dashboard"
                  >Account Dashboard</a
                >
                <a href="javascript:;" routerLink="/dashboard/settings"
                  >Settings</a
                >
                <!-- <a href="javascript:;" [routerLink]="'/profile/' + currentUser.profileUrl">Public Profile</a> -->
                <a href="javascript:;" routerLink="/logout">Log out</a>
              </div>
              <div class="userDdBody" *ngIf="currentUser.type == 'student'">
                <a
                  href="javascript:;"
                  routerLink="/admission/student-application"
                  >Applications</a
                >
                <a href="javascript:;" routerLink="/admission/student-dashboard"
                  >Edit Profile</a
                >
                <a href="javascript:;" routerLink="/logout">Log out</a>
              </div>
            </div>
            <a href="javascript:;" class="openBtn" (click)="toggleNav()">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_6121_4304)">
                  <path
                    d="M0.972655 3.95289H15.0276C15.5639 3.95289 16 3.51677 16 2.98047C16 2.44416 15.5639 2.00781 15.0276 2.00781H0.972655C0.43635 2.00781 0 2.44416 0 2.98047C0 3.51677 0.43635 3.95289 0.972655 3.95289Z"
                    fill="#221F1F"
                  />
                  <path
                    d="M15.0276 7.02734H0.972655C0.43635 7.02734 0 7.46369 0 8C0 8.5363 0.43635 8.97242 0.972655 8.97242H15.0276C15.5639 8.97242 16 8.5363 16 8C16 7.46369 15.5639 7.02734 15.0276 7.02734Z"
                    fill="#221F1F"
                  />
                  <path
                    d="M15.0276 12.0469H0.972655C0.43635 12.0469 0 12.4835 0 13.0198C0 13.5561 0.43635 13.9922 0.972655 13.9922H15.0276C15.5639 13.9922 16 13.5561 16 13.0198C16 12.4835 15.5639 12.0469 15.0276 12.0469Z"
                    fill="#221F1F"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6121_4304">
                    <rect width="16" height="16" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </a>
          </div>

          <ng-container>
            <div *ngIf="mobNav" class="overlay" (click)="removeNav()"></div>
            <div
              class="cat-pages"
              style="width: 266px"
              [ngClass]="mobNav ? 'show' : ''"
            >
              <div class="new_closeBTN">
                <a href="javascript:;" routerLink="/"
                  ><img
                    class="navbarLogo color-logo"
                    src="assets/new-images/navbar-logo-new.svg"
                    alt="logo"
                /></a>

                <span
                  class="d-hidden closebtn"
                  (click)="removeNav()"
                  style="display: block"
                >
                  <svg
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.94033 5.95923L11.307 1.59306C11.578 1.322 11.578 0.882532 11.307 0.611496C11.0359 0.340439 10.5965 0.340439 10.3254 0.611496L5.95923 4.97812L1.59306 0.611496C1.322 0.340439 0.882532 0.340439 0.611496 0.611496C0.34046 0.882554 0.340439 1.32202 0.611496 1.59306L4.97812 5.95923L0.611496 10.3254C0.340439 10.5965 0.340439 11.0359 0.611496 11.307C0.882554 11.578 1.32202 11.578 1.59306 11.307L5.95923 6.94033L10.3254 11.307C10.5965 11.578 11.0359 11.578 11.307 11.307C11.578 11.0359 11.578 10.5965 11.307 10.3254L6.94033 5.95923Z"
                      fill="#003366"
                      stroke="#003366"
                      stroke-width="0.5"
                    />
                  </svg>
                </span>
              </div>

              <ul class="navItems">
                <li>
                  <div class="dropdown">
                    <a
                      class="pageLink mobmydropbtn about_btn"
                      href="javascript:;"
                    >
                      <span class="about_btn_span">About Us</span>
                    </a>
                    <div
                      (click)="removeNav()"
                      id="mobmyDropdown"
                      class="mobdropdown-content"
                      [ngClass]="mobDropdown ? 'show' : ''"
                    >
                      <a href="javascript:;" routerLink="/why-us">Why Us</a>
                      <a href="javascript:;" routerLink="/about-us">Our NGO</a>
                      <a href="javascript:;" routerLink="/testimonials"
                        >Testimonials</a
                      >
                      <a href="javascript:;" routerLink="/bank-details"
                        >Bank Details</a
                      >
                      <a href="javascript:;" routerLink="/donation-jazzcash"
                        >JazzCash Details</a
                      >
                      <a href="javascript:;" routerLink="/legal">Legal</a>
                      <a href="javascript:;" routerLink="/gallery">Gallery</a>
                    </div>
                  </div>
                </li>

                <li>
                  <div class="dropdown">
                    <a
                      class="pageLink mobmydropbtn about_btn"
                      href="javascript:;"
                    >
                      <span class="about_btn_span">Our Work</span>
                    </a>
                    <div
                      (click)="removeNav()"
                      id="mobmyDropdown"
                      class="mobdropdown-content"
                      [ngClass]="mobDropdown ? 'show' : ''"
                    >
                      <a href="javascript:;" routerLink="/our-projects"
                        >Our Projects</a
                      >
                      <a href="javascript:;" routerLink="/gallery">Gallery</a>
                    </div>
                  </div>
                </li>

                <li (click)="removeNav()">
                  <a class="hover" href="javascript:;" routerLink="/faq"
                    ><span class="mob__only"> </span>FAQ's</a
                  >
                </li>
                <li (click)="removeNav()">
                  <a class="hover" href="javascript:;" routerLink="/contact-us"
                    ><span class="mob__only"> </span>Contact</a
                  >
                </li>

                <li>
                  <div class="dropdown">
                    <a
                      class="pageLink mobmydropbtn about_btn"
                      href="javascript:;"
                    >
                      <span class="about_btn_span">Get Involved</span>
                    </a>
                    <div
                      (click)="removeNav()"
                      id="mobmyDropdown"
                      class="mobdropdown-content"
                      [ngClass]="mobDropdown ? 'show' : ''"
                    >
                      <a
                        href="javascript:;"
                        routerLink="/get-involved/support-our-needs"
                        >Support Our Needs</a
                      >
                      <a href="javascript:;" routerLink="/special-friends"
                        >Special Friends Club</a
                      >
                      <a
                        href="javascript:;"
                        routerLink="/get-involved/volunteer"
                        >Volunteer</a
                      >
                      <a
                        href="javascript:;"
                        routerLink="/get-involved/request-tour"
                        >Request Site Tour</a
                      >
                    </div>
                  </div>
                </li>

                <li>
                  <div class="dropdown">
                    <a
                      class="pageLink mobmydropbtn about_btn"
                      href="javascript:;"
                    >
                      <span class="about_btn_span">Admissions</span>
                    </a>
                    <div
                      (click)="removeNav()"
                      id="mobmyDropdown"
                      class="mobdropdown-content"
                      [ngClass]="mobDropdown ? 'show' : ''"
                    >
                      <a
                        href="javascript:;"
                        routerLink="/cadet-college-admission-11th-class"
                        >PSH Cadet College - 11th Class
                      </a>
                      <a
                        href="javascript:;"
                        routerLink="/cadet-college-admission-8th-class"
                        >PSH Cadet College - 8th Class</a
                      >
                      <a href="javascript:;" routerLink="/boarding-school"
                        >PSH Boarding Schools</a
                      >
                      <a href="javascript:;" routerLink="/scholarship-program"
                        >Scholarship Program
                      </a>
                      <!-- <a href="javascript:;" routerLink="/education-sponsorship">Education Sponsorship</a> -->
                    </div>
                  </div>
                </li>

                <li (click)="removeNav()">
                  <a class="hover" href="javascript:;" routerLink="/updates"
                    ><span class="mob__only"> </span>Updates</a
                  >
                </li>

                <li>
                  <div *ngIf="!currentUser" class="dropdown">
                    <a
                      class="pageLink mobmydropbtn about_btn"
                      href="javascript:;"
                    >
                      <span class="about_btn_span">Sign In</span>
                    </a>
                    <div
                      (click)="removeNav()"
                      id="mobmyDropdown"
                      class="mobdropdown-content"
                      [ngClass]="mobDropdown ? 'show' : ''"
                    >
                      <a href="javascript:;" routerLink="/sign-in"
                        >Donor Account</a
                      >
                      <a href="javascript:;" routerLink="/admission/login"
                        >Student Account</a
                      >
                    </div>
                  </div>
                  <a
                    (click)="removeNav()"
                    *ngIf="currentUser"
                    class="hover"
                    routerLink="/logout"
                    href="javascript:;"
                    ><span class="mob__only"> </span>Logout</a
                  >
                </li>

                <li class="newMenuBtn" (click)="removeNav()">
                  <a
                    href="javascript:;"
                    routerLink="/sponsorship-program"
                    class="newSponsorBtn"
                    >Sponsor a child</a
                  >
                  <a
                    href="javascript:;"
                    routerLink="/donate"
                    class="newDonateBtn"
                    >Donate</a
                  >
                </li>
              </ul>

              <div class="mpw_info d-hidden">
                <a href="mailto:info@pakistansweethome.org.pk">
                  <span>
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.8768 2.78125L9.63086 6.99982L13.8768 11.2184C13.9535 11.058 14.0001 10.8806 14.0001 10.6912V3.30841C14.0001 3.119 13.9535 2.94168 13.8768 2.78125Z"
                        fill="black"
                      />
                      <path
                        d="M12.7693 2.07812H1.23029C1.04087 2.07812 0.863551 2.12469 0.703125 2.20145L6.12985 7.60082C6.60968 8.08065 7.38996 8.08065 7.86978 7.60082L13.2965 2.20145C13.1361 2.12469 12.9588 2.07812 12.7693 2.07812Z"
                        fill="black"
                      />
                      <path
                        d="M0.12332 2.78125C0.0465664 2.94168 0 3.119 0 3.30841V10.6912C0 10.8806 0.0465664 11.058 0.12332 11.2184L4.36923 6.99982L0.12332 2.78125Z"
                        fill="black"
                      />
                      <path
                        d="M9.0506 7.58008L8.44977 8.1809C7.65027 8.98041 6.34934 8.98041 5.54983 8.1809L4.94903 7.58008L0.703125 11.7986C0.863551 11.8754 1.04087 11.922 1.23029 11.922H12.7693C12.9588 11.922 13.1361 11.8754 13.2965 11.7986L9.0506 7.58008Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  info@pakistansweethome.org.pk
                </a>
                <a href="tel:+1 888 687 4420">
                  <span>
                    <svg
                      width="14"
                      height="15"
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M13.6042 10.5356L11.6505 8.5935C10.9527 7.8999 9.76654 8.17736 9.48743 9.07902C9.2781 9.70329 8.58034 10.0501 7.95235 9.91135C6.55682 9.56455 4.67286 7.76117 4.32398 6.30461C4.11465 5.68033 4.53331 4.98673 5.1613 4.77867C6.06839 4.50123 6.3475 3.32211 5.64973 2.6285L3.69599 0.686408C3.13778 0.200885 2.30046 0.200885 1.81203 0.686408L0.486277 2.00426C-0.839475 3.39147 0.62583 7.06757 3.90532 10.3275C7.18481 13.5875 10.883 15.1134 12.2785 13.7262L13.6042 12.4083C14.0927 11.8534 14.0927 11.0211 13.6042 10.5356Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  (051) 4865856</a
                >
                <a href="tel:+1 888 687 4420">
                  <span>
                    <svg
                      width="10"
                      height="16"
                      viewBox="0 0 10 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M7.8091 0H2.19092C1.08183 0 0.190918 0.890909 0.190918 2V14C0.190918 15.1091 1.10001 16 2.19092 16H7.8091C8.91819 16 9.8091 15.1091 9.8091 14V2C9.8091 0.890909 8.91819 0 7.8091 0ZM5.0091 14.7091C4.50001 14.7091 4.10001 14.3091 4.10001 13.8C4.10001 13.2909 4.50001 12.8909 5.0091 12.8909C5.51819 12.8909 5.91819 13.2909 5.91819 13.8C5.91819 14.3091 5.51819 14.7091 5.0091 14.7091ZM8.90001 11.9273H1.10001V2.67273H8.90001V11.9273Z"
                        fill="black"
                      />
                    </svg>
                  </span>
                  +92 335 1118477</a
                >
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</section>
