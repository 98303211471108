<section class="projectsTopSec">
    <div class="projectHead">
        <p class="news_head">news</p>
        <h1>Recent Stories From the Field</h1>
        <p class="newsDesc">Learn more about how we ensure children have access to quality education</p>
    </div>
</section>

<section class="allProjectsWrapper">
    <div class="container">
        <div class="campusesWrapper">
            <div class="singleCampus">
                <img src="/assets/images/girls_campus.JPEG" alt="campus">
                <h2 class="campusName">Resilience Girls Empowerment Program</h2>
                <p class="campusDetail">Resilience Girls Empowerment Program started in 2021 as an initiative geared towards taking a proactive approach to providing girls with skillful education. Our curriculum is designed as a character development program with enrichment activities and cadet training to build self-esteem and determination in girls.</p>
              
                <p class="full-content">All costs of schooling, including tuition, boarding, stationery, and food, are taken care of by Pakistan Sweet Home. As we look ahead to January 2023, another batch will join them on their journey for academic excellence in February!</p>
                <a class="campusDetailBtn"  href="javascript:;">Read More</a>
                <a class="showLess"  href="javascript:;">Show Less</a>
                
            </div>
            <div class="singleCampus">
                <img src="/assets/images/frp.jpg" alt="campus">
                <h2 class="campusName">Flood Rehabilitation Project</h2>
                <p class="campusDetail1">Following the heart-wrenching results of a climate crisis in Pakistan, destroying lives  and homes throughout Sindh Province - it was essential to provide help where needed. So when reports told us that children were desperate for education, even attending classes outdoors under makeshift tents due to destroyed schools, we at Pakistan Sweet Home committed to providing aid in whatever way possible.</p>
                <p class="full-content1"> In collaboration with Harrow Central Mosque and Mascood Islamic Center, our team set out to rebuild one such school near Village Nabi Bux Bhanbro, Tehsil Saleh Patt District Sukkur. After visiting this location, our team laid down the foundation stone to give those local students access to classrooms again!</p>

                <a class="campusDetailBtn1"  href="javascript:;">Read More</a>
                <a class="showLess"  href="javascript:;">Show Less</a>
            </div>
            <div class="singleCampus">
                <img src="/assets/images/newsThumb.jpg" alt="campus">
                <h2 class="campusName">Sargodha Campus: New Building for Higher Studies</h2>
                <p class="campusDetail2">We celebrated a new milestone as CEO Zamurrad Khan inaugurated a new building for Irshad Bibi Campus Bhalwal Sargodha in an official ceremony. With this additional structure, children living nearby will now have access to higher studies up to class 10th!</p>
              
                

                <a class="campusDetailBtn2"  href="javascript:;">Read More</a>
                <a class="showLess"  href="javascript:;">Show Less</a>
            </div>
        </div>
        <!-- <a href="javascript:;" class="readStoriesBtn">Read the Stories</a> -->
    </div>
</section>