// import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AppSettings,AuthenticationService, FacebookLoginService, HelperService } from 'src/app/services/_services';

@Component({
  selector: 'app-create-password',
  templateUrl: './create-password.component.html',
  styleUrls: ['./create-password.component.css']
})
export class CreatePasswordComponent implements OnInit {

  public showPass                   : boolean = false; 
  action:string | undefined;
  otp: string| undefined;
  loading: boolean = false;

  public changePassword                      : any = {
    email             : '',
    password          : '',
    confirmPassword   : ''
  }


  public rerouteUrl                  : any = '/dashboard';
  public suUrl                       : any = '/sign-up';
  public fpUrl                   : any = '/forgot-password';
  public siUrl                   : any = '/sign-in';
  public siAPI                       : any = 'user-site-login.php';

  constructor(private route: ActivatedRoute, private authenticationService  : AuthenticationService,
    private helperService          : HelperService,
    private router                 : Router,
    private facebookLoginService   : FacebookLoginService,) { }

  ngOnInit(): void {
    this.otp = this.route.snapshot.queryParams?.otp;
    this.action = this.route.snapshot.queryParams?.action;
    this.match_otp()
  }

  match_otp(){
    

      this.loading = true;

      let data = new FormData();
      data.append("action", this.action);
      data.append("otp", this.otp);
      
      // let corsHeaders = new HttpHeaders({
      //   'Content-Type': 'multipart/form-data',
      //   'Accept': 'multipart/form-data',
      // });

      let url  = AppSettings.API_ENDPOINT + this.siAPI;

      this.helperService.httpPostRequests(url, data).then(resp => {
        if(resp.statusCode==200){
          console.log("resp",resp);
          this.changePassword.email = resp.data.email;
        }else{
          this.router.navigate([this.fpUrl]);
        }

        // if (resp.status != 'ERROR') {
        //   // Set User in localStorage
        //   // this.authenticationService.setCurrentUserValue(resp.data);
        //   console.log(resp);

        //   // this.router.navigate([this.rerouteUrl]);

        // } else{

        // }
        // else {
        //   this.errorMsg = resp.message;
        //   setTimeout(() => {
        //     this.errorMsg = '';
        //   }, 3000)
        // }

        this.loading = false;
  
      }).catch(error => {
          console.error("error: ",error);
      });

  }

  change_password(){
    

    this.loading = true;

    let data = new FormData();
    data.append("action", 'change-password');
    data.append("email", this.changePassword.email);
    data.append("password", this.changePassword.password);
    
    // let corsHeaders = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data',
    //   'Accept': 'multipart/form-data',
    // });

    let url  = AppSettings.API_ENDPOINT + this.siAPI;

    this.helperService.httpPostRequests(url, data).then(resp => {
      if(resp.statusCode==200){
        console.log("resp",resp);
        this.router.navigate([this.siUrl]);
      }else{
        this.router.navigate([this.fpUrl]);
      }

      // if (resp.status != 'ERROR') {
      //   // Set User in localStorage
      //   // this.authenticationService.setCurrentUserValue(resp.data);
      //   console.log(resp);

      //   // this.router.navigate([this.rerouteUrl]);

      // } else{

      // }
      // else {
      //   this.errorMsg = resp.message;
      //   setTimeout(() => {
      //     this.errorMsg = '';
      //   }, 3000)
      // }

      this.loading = false;

    }).catch(error => {
        console.error("error: ",error);
    });

}

}
