<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img id="loaderStatus" class="loaderImage" alt="logo" src="assets/new-images/blue_psh_logo.png">
</div>
<!-- template 2 -->
<div class="singleBlogPage">
  <section class="blogPage">
    <div class="blogHead" [ngStyle]="{'background': '#f4f9ff'}">
      <div class="container">
        <div *ngIf="pageData?.breadcrumbs" [innerHTML]="pageData?.breadcrumbs.replaceAll('www.', '')"></div>
        <div class="blogHeadWrapper" [ngClass]="pageData?.text_color">
          <div class="innerHead">
            <div class="writerInfo">
              <label>Written by</label>
              <div class="avatar">
                <img *ngIf="pageData?.page_writer.profile_image" [src]="pageData?.page_writer.profile_image"
                  [alt]="pageData?.page_writer.name" width="40" height="40" class="writerImg">
              </div>
              <a [routerLink]="['/writer', pageData?.page_writer.slug]">
                <span [ngClass]="pageData?.text_color">{{
                  pageData?.page_writer.name }}</span>
              </a>
            </div>
            <h1>{{ pageData?.title }}</h1>
            <div class="blog-info">
              <div class="readTime">
                <img src="assets/images/blog/blog-clock-icon.svg" alt="Read Time"
                  [ngStyle]="{color: 'transparent', filter: 'invert(0)'}">
                <p>{{ pageData?.read_duration }}</p>
              </div>
              <!-- Uncomment these lines if needed -->
              <!-- <div class="blog-info-item readTime">
                    <img [src]="published" alt="Published Date" [ngStyle]="{color: 'transparent', filter: 'invert(0)'}">
                    <p><strong>Published on:</strong> {{ formatDate(pageData?.publishing_time) }}</p>
                  </div>
                  <div class="blog-info-item readTime">
                    <img [src]="updated" alt="Updated Date" [ngStyle]="{color: 'transparent', filter: 'invert(0)'}">
                    <p><strong>Last updated on:</strong> {{ formatDate(pageData?.updated_at) }}</p>
                  </div> -->
            </div>
          </div>
          <div class="blogImage">
            <img *ngIf="screenSize.width > 575" [src]="pageData?.image" [alt]="pageData?.alt_text" width="640"
              height="440" priority>
            <img *ngIf="screenSize.width <= 575" [src]="pageData?.image" [alt]="pageData?.alt_text" width="320"
              height="220" priority>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="blogBody">
    <div class="container">
      <div class="blogBodyWrapper">
        <div class="blogSideBar">
          <div class="relatedBlogsWidget">
            <p class="tocRelP">
              On This Page
              <span [style.width.%]="progressWidth"></span>
            </p>
            <ul class="relatedblogwrap">
              <li *ngFor="let heading of TOC; let i = index" class="toc_scroll"
                (click)="scrollToHeading(heading); showTocItems = !showTocItems">
                <span class="scroll" [innerHTML]="heading"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="blogBodyContentWraper">
          <div class="blogBodyContent">
            <div class="blogBodyContent" [innerHTML]="pageData?.title_paragraph"></div>
            <div class="blogTextWrapper" [innerHTML]="sanitizedContent"></div>
          </div>
          <div *ngIf="pageData?.pages_faqs.length !== 0" class="faqWraper">
            <h2>{{ pageData?.faqs_heading }}</h2>
            <div class="accordd">
              <div *ngFor="let faq of pageData?.pages_faqs"
                [ngClass]="{'card': true, 'active': activeFaq === faq.question}" (click)="toggleFaq(faq.question)">
                <div class="accord-card-head">
                  <h3>{{ faq.question }}</h3>
                  <span class="faq-icon">
                    <img src="assets/images/blog/faq-question-icon.webp" alt="FAQ Icon" [style.color]="'transparent'"
                      [style.filter]="'invert(0)'" />
                  </span>
                </div>
                <div class="accord-card-body">
                  <div class="answerInner" [innerHTML]="faq.answer"></div>
                </div>
              </div>
            </div>
          </div>
          <div class="writerWrapperBody d-col">
            <div class="writerWrapper">
              <div class="writerProfile">
                <img [src]="pageData?.page_writer.profile_image" [alt]="pageData?.page_writer.name" width="73"
                  height="73" />
              </div>
              <div class="writerAbout">
                <div class="writerAboutWraper flexWidget flex-end">
                  <div class="writerAboutLeft">
                    <div class="writtenByBody flexWidget">
                      <p class="writtenBy">WRITTEN BY</p>
                      <div class="media-links">
                        <ul class="flexWidget">
                          <li *ngIf="pageData?.facebook">
                            <a [href]="pageData?.facebook">
                              <img src="assets/images/blog/share-facebook.webp" alt="Facebook Link" />
                            </a>
                          </li>
                          <li *ngIf="pageData?.linkedin">
                            <a [href]="pageData?.linkedin">
                              <img src="assets/images/blog/share-linkedin.webp" alt="Linkedin Link" />
                            </a>
                          </li>
                          <li *ngIf="pageData?.twitter">
                            <a [href]="pageData?.twitter">
                              <img src="assets/images/blog/share-twitter.webp" alt="Twitter Link" />
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <p class="writerbio">{{ pageData?.page_writer.name }}</p>
                  </div>
                </div>
                <p class="writerAboutcontent write-desk">{{ pageData?.page_writer.bio }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="articleSidebar">
          <div class="articleSidebarWrapper">
            <img src="assets/new-images/articles/sidebarImg.png" alt="posted by">
            <div class="sidebarDetails">
              <strong>Your Small Donation can Bring Brighter & Lasting Smiles!</strong>
              <p>Join hands with us to empower over 5000 orphaned children.</p>
            </div>
            <div class="sidebarButtons">
              <a href="javascript:;" routerLink="/donate" class="donateBtnSidebar">Donate Now</a>
              <!-- <a href="javascript:;" routerLink="/sponsorship-program" class="sponsorBtnSidebar">Sponsor a Child</a> -->
            </div>
          </div>
        </div>
      </div>

    </div>
  </section>
  <section *ngIf="popularPages?.length != 0" class="keepReading">
    <div class="container">
      <h2>Keep reading</h2>
      <div class="otherBlogsWrapper">
        <a *ngFor="let page of popularPages"
          [routerLink]="'/blog/' + [page.parent_slug ? page.parent_slug + '/' + page.page_slug :  page.page_slug]"
          key="{{ page.page_slug }}">
          <div class="blogCard">
            <div class="blogCardHead">
              <span *ngIf="page.read_duration">{{ page.read_duration }}</span>
            </div>
            <p>{{ page.title }}</p>
            <div class="imageWraper">
              <img [src]="page.image" [alt]="page.alt_text" width="384" height="264" />
            </div>
          </div>
        </a>
      </div>
      <a class="browseBtn" routerLink="/blog">
        Browse All
        <svg xmlns="http://www.w3.org/2000/svg" fill="none" height="16" viewBox="0 0 16 16" width="16">
          <path clipRule="evenodd"
            d="m6.28775 4.83797.90097-.98287 3.92878 3.65347c.2882.26422.2882.71864 0 .98287l-3.92878 3.63276-.90097-.9829 3.39264-3.1413z"
            fill="#98a1b3" fillRule="evenodd"></path>
        </svg>
      </a>
    </div>
  </section>
</div>
<div [innerHtml]="jsonLDBlogData"></div>
<div [innerHtml]="jsonLDBreadCrumbs"></div>
<div [innerHtml]="jsonLDFaqs"></div>