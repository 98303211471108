<section class="faqBanner">
  <div class="containerFluid">
    <h1>Frequently Asked Questions</h1>
    <p>Find Quick Answers to Your Queries about Pakistan Sweet Home</p>

    <div class="bannerInputWrapper">
      <input
        type="text"
        placeholder="Search"
        [(ngModel)]="search"
        name="search"
      />
      <svg
        width="13"
        height="13"
        viewBox="0 0 13 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_6168_7267)">
          <path
            d="M12.7617 11.6162L10.2456 9.09898C12.1282 6.58306 11.6149 3.01727 9.09898 1.13458C6.58306 -0.748098 3.01727 -0.234752 1.13458 2.28117C-0.748098 4.79709 -0.234752 8.36288 2.28117 10.2456C4.30216 11.7579 7.07796 11.7579 9.09898 10.2456L11.6162 12.7627C11.9325 13.0791 12.4454 13.0791 12.7617 12.7627C13.078 12.4464 13.078 11.9335 12.7617 11.6172L12.7617 11.6162ZM5.71105 9.75972C3.47502 9.75972 1.66238 7.94708 1.66238 5.71105C1.66238 3.47502 3.47502 1.66238 5.71105 1.66238C7.94708 1.66238 9.75972 3.47502 9.75972 5.71105C9.75734 7.94607 7.94609 9.75734 5.71105 9.75972Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_6168_7267">
            <rect width="13" height="13" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  </div>
</section>

<section class="allFaqsWrapperSec">
  <div class="containerFluid">
    <div class="genericFilterTabs">
      <button
        class="filtersBtn"
        (click)="changeActiveTab('')"
        [ngClass]="activeTab == '' ? 'active' : ''"
      >
        All
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('About Pakistan Sweet Home')"
        [ngClass]="activeTab == 'About Pakistan Sweet Home' ? 'active' : ''"
      >
        About Pakistan Sweet Home
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Admissions')"
        [ngClass]="activeTab == 'Admissions' ? 'active' : ''"
      >
        Admissions
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Scholarship Program')"
        [ngClass]="activeTab == 'Scholarship Program' ? 'active' : ''"
      >
        Scholarship Program
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Sponsorship')"
        [ngClass]="activeTab == 'Sponsorship' ? 'active' : ''"
      >
        Sponsorship
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Donations')"
        [ngClass]="activeTab == 'Donations' ? 'active' : ''"
      >
        Donations
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Volunteering & Fundraising')"
        [ngClass]="activeTab == 'Volunteering & Fundraising' ? 'active' : ''"
      >
        Volunteering & Fundraising
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Site Tours')"
        [ngClass]="activeTab == 'Site Tours' ? 'active' : ''"
      >
        Site Tours
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Updates and News')"
        [ngClass]="activeTab == 'Updates and News' ? 'active' : ''"
      >
        Updates and News
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Account and Technical Support')"
        [ngClass]="activeTab == 'Account and Technical Support' ? 'active' : ''"
      >
        Account and Technical Support
      </button>
      <button
        class="filtersBtn"
        (click)="changeActiveTab('Contact')"
        [ngClass]="activeTab == 'Contact' ? 'active' : ''"
      >
        Contact
      </button>
    </div>

    <div class="main-card">
      <ng-container *ngIf="hasMatches; else notFoundTemplate">
        <ng-container *ngFor="let card of faqs; let indx = index">
          <ng-container
            *ngIf="
              (activeTab == '' || card.tag == activeTab) &&
              checkSearch(card.question)
            "
          >
            <div class="card" [ngClass]="indx == 0 ? 'show' : ''">
              <div class="card-head" (click)="cardToggler($event.target)">
                <h3>{{ card.question }}</h3>
                <span class="opened">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                   
                  >
                    <g clip-path="url(#clip0_6168_7474)">
                      <path
                        d="M9 0C4.03725 0 0 4.03725 0 9C0 13.9628 4.03725 18 9 18C13.9628 18 18 13.9628 18 9C18 4.03725 13.9628 0 9 0ZM9 16.5C4.8645 16.5 1.5 13.1355 1.5 9C1.5 4.8645 4.8645 1.5 9 1.5C13.1355 1.5 16.5 4.8645 16.5 9C16.5 13.1355 13.1355 16.5 9 16.5ZM12.75 9C12.75 9.414 12.414 9.75 12 9.75H6C5.586 9.75 5.25 9.414 5.25 9C5.25 8.586 5.586 8.25 6 8.25H12C12.414 8.25 12.75 8.586 12.75 9Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6168_7474">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                <span class="closed">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_6168_7478)">
                      <path
                        d="M9 0C7.21997 0 5.47991 0.527841 3.99987 1.51677C2.51983 2.50571 1.36628 3.91131 0.685088 5.55585C0.00389957 7.20038 -0.17433 9.00998 0.172937 10.7558C0.520204 12.5016 1.37737 14.1053 2.63604 15.364C3.89472 16.6226 5.49836 17.4798 7.24419 17.8271C8.99002 18.1743 10.7996 17.9961 12.4442 17.3149C14.0887 16.6337 15.4943 15.4802 16.4832 14.0001C17.4722 12.5201 18 10.78 18 9C17.9974 6.61384 17.0484 4.32616 15.3611 2.63889C13.6738 0.951621 11.3862 0.00258081 9 0ZM9 16.5C7.51664 16.5 6.0666 16.0601 4.83323 15.236C3.59986 14.4119 2.63856 13.2406 2.07091 11.8701C1.50325 10.4997 1.35473 8.99168 1.64411 7.53682C1.9335 6.08197 2.64781 4.74559 3.6967 3.6967C4.7456 2.64781 6.08197 1.9335 7.53683 1.64411C8.99168 1.35472 10.4997 1.50325 11.8701 2.0709C13.2406 2.63856 14.4119 3.59985 15.236 4.83322C16.0601 6.06659 16.5 7.51664 16.5 9C16.4978 10.9885 15.7069 12.8948 14.3009 14.3009C12.8948 15.7069 10.9885 16.4978 9 16.5ZM12.75 9C12.75 9.19891 12.671 9.38968 12.5303 9.53033C12.3897 9.67098 12.1989 9.75 12 9.75H9.75V12C9.75 12.1989 9.67099 12.3897 9.53033 12.5303C9.38968 12.671 9.19892 12.75 9 12.75C8.80109 12.75 8.61033 12.671 8.46967 12.5303C8.32902 12.3897 8.25 12.1989 8.25 12V9.75H6C5.80109 9.75 5.61033 9.67098 5.46967 9.53033C5.32902 9.38968 5.25 9.19891 5.25 9C5.25 8.80109 5.32902 8.61032 5.46967 8.46967C5.61033 8.32902 5.80109 8.25 6 8.25H8.25V6C8.25 5.80109 8.32902 5.61032 8.46967 5.46967C8.61033 5.32902 8.80109 5.25 9 5.25C9.19892 5.25 9.38968 5.32902 9.53033 5.46967C9.67099 5.61032 9.75 5.80109 9.75 6V8.25H12C12.1989 8.25 12.3897 8.32902 12.5303 8.46967C12.671 8.61032 12.75 8.80109 12.75 9Z"
                        fill="black"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_6168_7478">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </div>
              <div
                class="card-body"
                [ngStyle]="indx != 0 ? { display: 'none' } : ''"
              >
                <ng-container *ngFor="let answer of card.answer">
                  <p>{{ answer }}</p>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-template #notFoundTemplate>
        <div class="no-data-found-sec">
          <img src="/assets/new-images/faq/no-data-found.png" />
          <div>
            <P>No data found</P>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</section>
