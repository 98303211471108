<div class="campusesWrapper">
  <div
    *ngFor="let campus of allCampuses[pageName]; let i = index"
    class="singleCampus"
  >
    <div class="main_img">
      <div class="single_img" style="position: relative">
        <!-- Overlay div to catch clicks -->
        <div
          *ngIf="pageName == 'campuses'"
          class="click-overlay"
          (click)="playVideo(i, $event)"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
          "
        ></div>

        <!-- Container for YouTube iframe, which will be initialized by YouTube API -->
        <div id="player-{{ i }}" class="videoPlayer" style="width: 407px"></div>

        <ng-container *ngIf="pageName == 'upcoming'">
          <canvas class="galleryCanvas"></canvas>
          <img
            class="galleryImg"
            [src]="'/assets/new-images/campuses/' + campus.image + '.png'"
            alt="campus"
          />
        </ng-container>
      </div>
    </div>
    <h2 class="campusName">{{ campus.name }}</h2>
    <p class="campusDes">{{ campus.des }}</p>
  </div>
</div>
