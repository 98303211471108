<section class="dashboardHeader">
  <div class="container">
    <div class="studentHead">
      <div class="dashTop">
        <div class="personalInformation">
          <div class="StudentInfo">
            <p class="profileInfo">Welcome, {{currentUser.firstName ? currentUser.firstName : currentUser.name}}!</p>
            <p class="personalInof">Your personalized dashboard will allow you to view and track all your
              applications
              in
              one place.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="Recentactivity">
  <div class="container">
    <h1 class="recentActivityTab">Recent Activity</h1>
    <div class="tableWraper cadetTable" *ngIf="!loading && applications.length">
      <h2>Cadet Application(s)</h2>
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Time</th>
            <th>Application ID</th>
            <th>Status</th>
            <th>Date Applied</th>
            <th>Decision</th>
            <th>Note</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody *ngIf="applications && !loading">
          <tr *ngFor="let item of applications; let i=index;"
            (click)="item.application_completed == '0' ? setAdmissionForm(item) : ''"
            [ngClass]="item.application_completed == '0' ? 'pointer' : '' ">
            <td>{{ i+1 }}</td>
            <td>{{ item.created_at | date : 'shortTime' :'+1000' }}</td>
            <td class="applicationID">
              <a *ngIf="item.application_completed == '0' " href="javascript:;"
                (click)="setAdmissionForm(item)">PSH-{{ item.id }}</a>
              <span *ngIf="item.application_completed == '1' ">PSH-{{ item.id }}</span>
            </td>
            <td class="complete" [style.color]="item.application_completed == '1' ? '#13AD35' : 'red'">
              <p class="red"> {{ item.application_completed == '1' ? 'Complete' : 'Incomplete' }}</p>
            </td>
            <td>{{ item.application_completed == '1' ? (item.created_at | date) : '-'  }}</td>
            <td [ngClass]="getAppClass(item.application_status)">
              {{ getAppStatus(item.application_status) }}
            </td>
            <td>
              <div *ngIf="item.admission_comment && (item.application_status == '1' || item.application_status == '2')">
                <a *ngIf="item.application_status == '2'" href="javascript:;"
                  (click)="showComment(item.admission_comment)">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.243 5.12424C23.8334 6.28293 23.8334 7.79974 23.8334 10.8334V12.1162C23.8334 13.9462 23.8334 14.8611 23.6146 15.6075C23.0954 17.3778 21.7112 18.7621 19.9409 19.2812C19.1945 19.5 18.2796 19.5 16.4496 19.5H15.8551L15.7846 19.5001C14.6794 19.5072 13.6026 19.8522 12.6991 20.4889L12.6417 20.5298L9.81344 22.55C8.84387 23.2425 7.5698 22.2424 8.01231 21.1362C8.32577 20.3525 7.74863 19.5 6.90461 19.5H6.25271C3.9961 19.5 2.16675 17.6707 2.16675 15.414V10.8334C2.16675 7.79974 2.16675 6.28293 2.75713 5.12424C3.27644 4.10503 4.10509 3.27638 5.1243 2.75707C6.28299 2.16669 7.7998 2.16669 10.8334 2.16669H15.1667C18.2004 2.16669 19.7172 2.16669 20.8759 2.75707C21.8951 3.27638 22.7238 4.10503 23.243 5.12424ZM8.66675 7.85419C8.21802 7.85419 7.85425 8.21796 7.85425 8.66669C7.85425 9.11541 8.21802 9.47919 8.66675 9.47919H17.3334C17.7821 9.47919 18.1459 9.11541 18.1459 8.66669C18.1459 8.21796 17.7821 7.85419 17.3334 7.85419H8.66675ZM10.8334 12.1875C10.3847 12.1875 10.0209 12.5513 10.0209 13C10.0209 13.4487 10.3847 13.8125 10.8334 13.8125H15.1667C15.6155 13.8125 15.9792 13.4487 15.9792 13C15.9792 12.5513 15.6155 12.1875 15.1667 12.1875H10.8334Z"
                      fill="black" />
                  </svg>
                </a>
                <a *ngIf="item.application_status == '1'" href="javascript:;"
                  (click)="showAcceptComment(item.admission_comment)">
                  <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M23.243 5.12424C23.8334 6.28293 23.8334 7.79974 23.8334 10.8334V12.1162C23.8334 13.9462 23.8334 14.8611 23.6146 15.6075C23.0954 17.3778 21.7112 18.7621 19.9409 19.2812C19.1945 19.5 18.2796 19.5 16.4496 19.5H15.8551L15.7846 19.5001C14.6794 19.5072 13.6026 19.8522 12.6991 20.4889L12.6417 20.5298L9.81344 22.55C8.84387 23.2425 7.5698 22.2424 8.01231 21.1362C8.32577 20.3525 7.74863 19.5 6.90461 19.5H6.25271C3.9961 19.5 2.16675 17.6707 2.16675 15.414V10.8334C2.16675 7.79974 2.16675 6.28293 2.75713 5.12424C3.27644 4.10503 4.10509 3.27638 5.1243 2.75707C6.28299 2.16669 7.7998 2.16669 10.8334 2.16669H15.1667C18.2004 2.16669 19.7172 2.16669 20.8759 2.75707C21.8951 3.27638 22.7238 4.10503 23.243 5.12424ZM8.66675 7.85419C8.21802 7.85419 7.85425 8.21796 7.85425 8.66669C7.85425 9.11541 8.21802 9.47919 8.66675 9.47919H17.3334C17.7821 9.47919 18.1459 9.11541 18.1459 8.66669C18.1459 8.21796 17.7821 7.85419 17.3334 7.85419H8.66675ZM10.8334 12.1875C10.3847 12.1875 10.0209 12.5513 10.0209 13C10.0209 13.4487 10.3847 13.8125 10.8334 13.8125H15.1667C15.6155 13.8125 15.9792 13.4487 15.9792 13C15.9792 12.5513 15.6155 12.1875 15.1667 12.1875H10.8334Z"
                      fill="black" />
                  </svg>
                </a>
              </div>
              <p *ngIf="item.application_status == '3'">{{ item.selected_test_city }}</p>
              <a target="_blank"
                [href]=" 'https://adminbackend.pakistansweethome.org.pk/api/download_roll_no_slip/' + item.roll_no"
                *ngIf="item.application_status == '4'">Download Roll Number Slip</a>
              <div *ngIf="item.application_status == '0'">
                <p>-</p>
              </div>
            </td>
            <td>
              <a class="editAppBtn" *ngIf="item.application_completed == '0'"
                [ngClass]="{'disabledPointer': isDisable(item.application_status)}"
                [attr.href]="isDisable(item.application_status) ? null : '/admission/fill-form/' + item.id + '?admin=2'">
                Edit
              </a>

              <p *ngIf="item.application_completed == '1'">-</p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="tableWraper" *ngIf="!loading && otherApplications.length">
      <h2>General Application(s)</h2>
      <table>
        <thead>
          <tr>
            <th>Serial Number</th>
            <th>Time</th>
            <th>Application ID</th>
            <th>Status</th>
            <th>Date Applied</th>
            <th>Decision</th>
            <th>Note</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody *ngIf="otherApplications && !loading">
          <tr *ngFor="let item of otherApplications; let i=index;" >
            <td>{{ i+1 }}</td>
            <td>{{ item.updated_at | date : 'shortTime' }}</td>
            <td>PSH-GA-{{ item.id }} </td>
            <td class="complete" [style.color]="item.is_completed == '1' ? '#13AD35' : 'red'" > 
              <p class="red"> {{ item.is_completed == '1' ? 'Complete' : 'Incomplete' }}</p>
            </td>
            <td> {{ item.created_at | date }} </td>
            <td> Pending </td>
            <td>-</td>
            <td> 
                <a class="editAppBtn" *ngIf="item.is_completed == '0'" [routerLink]=" (item.type == 'RegularAdmission' ? '/boarding-school/apply/' : item.type == 'scholorship_programs' ? '/scholarship-program/apply/' : '/education-sponsorship/apply/') + item.id "   >
                    Edit
                </a>
                <ng-container *ngIf="item.is_completed == '1'">
                    -
                </ng-container>
             </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</section>

<div id="pgLoader" class="pgLoader" *ngIf="loading">
  <img id="loaderStatus" class="loaderImage" alt="logo" src="assets/new-images/blue_psh_logo.png">
</div>
