import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-inauguration-of-new-building',
  templateUrl: './inauguration-of-new-building.component.html',
  styleUrls: ['./inauguration-of-new-building.component.css' , '../articles.component.css']
})
export class InaugurationOfNewBuildingComponent implements OnInit {
  constructor( private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Raja Pervaiz Ashraf Inaugurated New Hostel at Cadet College","Raja Pervaiz Ashraf inaugurated a new hostel building at Pakistan Sweet Home’s Cadet College, enhancing facilities for orphans.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
