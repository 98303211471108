import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dobToAge'
})
export class DobToAgePipe implements PipeTransform {

  transform(dob): String {
    var today = new Date();
    var birthDate = new Date(dob);

    var yearsDiff = today.getFullYear() - birthDate.getFullYear();
    var monthsDiff = today.getMonth() - birthDate.getMonth();

    if (monthsDiff < 0) {
      yearsDiff--;
      monthsDiff += 12;
    }

    var yearsString = yearsDiff == 0 ? '' : yearsDiff == 1 ? yearsDiff + ' Year ' : yearsDiff + ' Years ';
    var monthsString = monthsDiff == 0 ? '' : monthsDiff == 1 ? monthsDiff + ' Month' : monthsDiff + ' Months';

    return yearsString + monthsString
  }

}
