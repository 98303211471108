import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-support-our-needs',
  templateUrl: './support-our-needs.component.html',
  styleUrls: ['./support-our-needs.component.css'],
})
export class SupportOurNeedsComponent implements OnInit {
  Articles = [
    {
      img: 'assets/new-images/get-involved/education-banner-1.jpg',
      title: 'Education',
      slug: 'education',
      alt: 'education',

      summary:
        'Transform an orphan’s life by providing complete educational support.',
    },
    {
      img: 'assets/new-images/get-involved/food-support.jpg',
      title: 'Food',
      slug: 'food',
      alt: 'food',

      summary:
        'Provide nutritious meals to an orphan child living at Sweet Home boarding for a month.',
    },
    {
      img: 'assets/new-images/get-involved/medical-banner.JPG',
      title: 'Medical',
      slug: 'medical',
      alt: 'medical',

      summary:
        'Provide quality healthcare with trained staff and essential equipment for any emergency.',
    },
    {
      img: 'assets/new-images/get-involved/sport-banner-2.jpg',
      title: 'Sports & Recreational',
      slug: 'sports-and-recreational',
      alt: 'sports-and-recreational',

      summary:
        'Support a joyful upbringing for orphans through essential sports and recreation programs.',
    },
  ];
  constructor(private router: Router, private seoService: SEOService) {}

  ngOnInit(): void {
    this.setSEO_Tags(
      'Support Our Needs to Empower Orphans',
      ' Your support helps provide vital resources and opportunities to orphans in need. Support our needs to make a difference today. '
    );
  }

  goToDetailPage(slug) {
    this.router.navigate(['/get-involved/support-our-needs', slug]);
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }
}
