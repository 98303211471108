import { Injectable } from '@angular/core';


@Injectable({ providedIn: 'root' })
export class deferImageService  {

    constructor(){}

    deferParseImages(){
        var imgDefer = document.getElementsByTagName('img');
        for (var i=0; i<imgDefer.length; i++) {
          if(imgDefer[i].getAttribute('data-src')) {
            imgDefer[i].setAttribute('src',imgDefer[i].getAttribute('data-src'));
          }
        }
        var picDefer = document.getElementsByTagName('source');
        for (var i=0; i<picDefer.length; i++) {
          if(picDefer[i].getAttribute('data-src')) {
            picDefer[i].setAttribute('srcset',picDefer[i].getAttribute('data-src'));
          }
        }
    }

}
