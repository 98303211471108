<main>
  <section class="hero-section">
    <div class="hero-text">
      <h1>Empower Orphans with Your Support</h1>
      <p>
        Every aspect of a child's life is important. Your generous support
        ensures we have enough funds readily available to meet the needs of the
        orphans living in Sweet Home!
      </p>
    </div>
    <div class="hero-img hero-web">
      <img
        src="assets/new-images/get-involved/hero-banner-1.webp"
        alt="Empower Orphans with Your Support"
      />
    </div>
    <div class="hero-mob">
      <img
        width="330px"
        height="220px"
        src="assets/new-images/get-involved/hero-banner-mob.jpg"
        alt="Empower Orphans with Your Support"
      />
    </div>
  </section>
  <section class="article-section">
    <div class="articles-wrapper">
      <ng-container *ngFor="let article of Articles">
        <div class="article">
          <img [src]="article.img" [alt]="article.alt" />
          <div class="article-info">
            <p class="article-heading">{{ article.title }}</p>
            <p class="article-summary">{{ article.summary }}</p>
          </div>

          <button (click)="goToDetailPage(article.slug)">View Details</button>
        </div>
      </ng-container>
    </div>
  </section>
</main>
