import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css'],
})
export class PrivacyPolicyComponent implements OnInit {
  constructor(private seoService: SEOService) {}

  ngOnInit(): void {
    this.setSEO_Tags(
      'Privacy Policy | Your Data is Our Responsibility',
      'Learn how we protect your data, privacy, and security. Our privacy policy ensures confidentiality and transparent data handling.'
    );
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();
    }, 500);
  }
}
