import { Component, OnInit, AfterViewInit } from '@angular/core';
declare var $, Swiper,window;


@Component({
  selector: 'app-testimonials',
  templateUrl: './testimonials.component.html',
  styleUrls: ['./testimonials.component.css']
})
export class TestimonialsComponent implements OnInit {

  public selectedDiv: any = 1;

  constructor() {

   }

  ngOnInit(): void {
    if ($(window).width() <= 575) {



      $(".temp__swiper").addClass('swiper swiper-container');
      $(".pakTeamwraPPer").addClass('swiper-wrapper');
      $(".pakTeamwraPPer .single_cel").addClass('swiper-slide');
      var swiper = new Swiper(".subSwiper", {
        slidesPerView: 1,
        //  spaceBetween: 50,
        // centeredSlides: true,	
        loop: true,
        pagination: {
          el: ".mpwRev-pagination",
          clickable: true
        }
      });
      

      // denswiper
      // $(".den__swiper").addClass('swiper swiper-container');
      // $(".denTeamwraPPer").addClass('swiper-wrapper');
      // $(".denTeamwraPPer .single_cel").addClass('swiper-slide');
      // var swiper = new Swiper(".denSwiper", {
      //   slidesPerView: 1,
      //   //  spaceBetween: 50,
      //   // centeredSlides: true,	
      //   loop: true,
      //   pagination: {
      //     el: ".denpag-pagination",
      //     clickable: true
      //   }
      // });

      // getinvol
      $(".stotyparent").addClass('swiper swiper-container');
      $(".organizationEvent").addClass('swiper-wrapper');
      $(".organizationEvent").removeClass('denTeamwraPPer');
      $(".organizationEvent .single_cel").addClass('swiper-slide');
      var swiper = new Swiper(".stotyparent", {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,	
        loop: true,
        pagination: {
          el: ".denpag-pagination",
          clickable: true
        }
      });


      // usa swiper
      $(".usa__swiper").addClass('swiper swiper-container');
      $(".usaWraper").addClass('swiper-wrapper');
      $(".usaWraper .single_cel").addClass('swiper-slide');
      var swiper = new Swiper(".usaswiper", {
        slidesPerView: 1,
        //  spaceBetween: 50,
        // centeredSlides: true,	
        loop: true,
        pagination: {
          el: ".usa-pagination",
          clickable: true
        }
      });



          // sportsman swiper
          $(".sportsman__swiper").addClass('swiper swiper-container');
          $(".sportsmanWraper").addClass('swiper-wrapper');
          $(".sportsmanWraper .single_cel").addClass('swiper-slide');
          var swiper = new Swiper(".sportsswiper", {
            slidesPerView: 1,
            //  spaceBetween: 50,
            // centeredSlides: true,	
            loop: true,
            pagination: {
              el: ".sports-pagination",
              clickable: true
            }
          });




    }
  }


  showDiv(divNumber) {
    this.selectedDiv = divNumber;    
  }



  

}
