import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
declare var $;

@Component({
  selector: 'app-sponsorship-summary',
  templateUrl: './sponsorship-summary.component.html',
  styleUrls: ['./sponsorship-summary.component.css']
})
export class SponsorshipSummaryComponent implements OnInit {

  @Input() pricePlan;
  @Input() totalPrice;
  @Input() activeStep;
  @Input() currencyCode;
  @Input() exchangeRate;
  @Input() pageType;

  @Output() stepChangeEvent = new EventEmitter();

  public showSummary = false;
  public disabledBtn = false;

  constructor() { }

  ngOnInit(): void {
  }

  changeStep(stepName){
    this.disabledBtn = true;
    setTimeout(() => {
      this.stepChangeEvent.emit(stepName);
      this.disabledBtn = false;
    }, 1000);
  }

  showSummaryModal(){
    this.fixBody();
    this.showSummary = true;
  }
  hideSummaryModal(){
    this.unfixBody();
    this.showSummary = false;
  }

  fixBody(){
    $('body').css('height','100vh');
    $('body').css('overflow','hidden');
  }
  unfixBody(){
    $('body').css('height','unset');
    $('body').css('overflow','visible');
  }

  getSummaryWrapperClasses(showSummary, pageType){    
    var classes = showSummary ? 'show' : '';
    classes = classes + ' ' + (pageType  == 'membership' ? 'membership' : '');
    return classes;
  }

}
