import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './include/header/header.component';
// import { HomeComponent } from './pages/home/home.component';
import { FooterComponent } from './include/footer/footer.component';
// import { CertificatesComponent } from './pages/certificates/certificates.component';
// import { AuditReportsComponent } from './pages/audit-reports/audit-reports.component';
// import { ProjectDetailsComponent } from './pages/project-details/project-details.component';
// import { ItemDetailComponent } from './pages/item-detail/item-detail.component';
// import { GalleryAlbumComponent } from './pages/gallery-album/gallery-album.component';
// import { CreateProfileComponent } from './pages/create-profile/create-profile.component';
// import { DonationDetailsComponent } from './pages/donation-details/donation-details.component';
// import { DonationPaymentComponent } from './pages/donation-payment/donation-payment.component';
// import { GoldenArrowPakistanComponent } from './pages/golden-arrow-pakistan/golden-arrow-pakistan.component';
// import { IrshadBbComplexComponent } from './pages/irshad-bb-complex/irshad-bb-complex.component';
// import { CollegeGujarkhanComponent } from './pages/college-gujarkhan/college-gujarkhan.component';
// import { AzizBhattiCampusComponent } from './pages/aziz-bhatti-campus/aziz-bhatti-campus.component';
// import { PakistanGreatHomesComponent } from './pages/pakistan-great-homes/pakistan-great-homes.component';
// import { RashidMinhasCampusComponent } from './pages/rashid-minhas-campus/rashid-minhas-campus.component';
// import { SarwarShaheedCampusComponent } from './pages/sarwar-shaheed-campus/sarwar-shaheed-campus.component';
// import { SheikhupuraCenterComponent } from './pages/sheikhupura-center/sheikhupura-center.component';
// import { SukkurCenterComponent } from './pages/sukkur-center/sukkur-center.component';
// import { PrivacyComponent } from './pages/privacy/privacy.component';
// import { RefundExchangePolicyComponent } from './pages/refund-exchange-policy/refund-exchange-policy.component';
// import { DonateFormComponent } from './pages/donate-form/donate-form.component';
// import { ThankyouComponent } from './pages/thankyou/thankyou.component';
// import { SorryComponent } from './pages/sorry/sorry.component';
// import { VideoGalleryComponent } from './pages/video-gallery/video-gallery.component';
// import { UnderReviewComponent } from './pages/under-review/under-review.component';
// import { ContactComponent } from './pages/contact/contact.component';
// import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
// import { PersonalInformationComponent } from './pages/personal-information/personal-information.component';
// import { AcademicDetailsComponent } from './pages/academic-details/academic-details.component';
// import { SubmissionComponent } from './pages/submission/submission.component';
import { FilePondModule, registerPlugin } from 'ngx-filepond';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSliderModule } from '@angular/material/slider';
import { NgxMaskModule } from 'ngx-mask';
import { RecaptchaModule } from 'ng-recaptcha';

// import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
// import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import * as FilePondPluginImagePreview from 'filepond-plugin-image-preview';
// import { PastPapersComponent } from './pages/past-papers/past-papers.component';
// import { LandingPageComponent } from './pages/landing-page/landing-page.component';
// import { HomeNewComponent } from './pages/home-new/home-new.component';
// import { DonationPaymentProcessComponent } from './pages/donation-payment-process/donation-payment-process.component';
// import { DonationPaymentStepsComponent } from './pages/donation-payment-steps/donation-payment-steps.component';
// import { CreateProfileStepComponent } from './pages/create-profile-step/create-profile-step.component';
// import { DonationDetailStepComponent } from './pages/donation-detail-step/donation-detail-step.component';
// import { DonationReviewComponent } from './pages/donation-review/donation-review.component';
// import { ReviewDetailStepComponent } from './pages/review-detail-step/review-detail-step.component';
import { HomepageComponent } from './newPages/homepage/homepage.component';
import { DashboardComponent } from './newPages/dashboard/dashboard.component';
import { SignInComponent } from './newPages/sign-in/sign-in.component';
import { SignUpComponent } from './newPages/sign-up/sign-up.component';
import { LogoutComponent } from './newPages/logout/logout.component';
import { ProfileComponent } from './newPages/profile/profile.component';
import { DonateComponent } from './newPages/donate/donate.component';
import { DateAsAgoPipe } from './pipes/date-as-ago.pipe';
import { ThanksComponent } from './newPages/thanks/thanks.component';
import { PaymentFormComponent } from './newPages/payment-form/payment-form.component';
import { AboutUsComponent } from './newPages/about-us/about-us.component';
import { TestimonialsComponent } from './newPages/testimonials/testimonials.component';
import { WhyUsComponent } from './newPages/why-us/why-us.component';
import { OurProjectsComponent } from './newPages/our-projects/our-projects.component';
import { CampusesComponent } from './newPages/our-projects/campuses/campuses.component';
import { BloodBankComponent } from './newPages/our-projects/blood-bank/blood-bank.component';
import { AdmissionFormComponent } from './newPages/admission-form/admission-form.component';
import { AdmissionSignupComponent } from './newPages/admission-form/admission-signup/admission-signup.component';
import { AdmissionsComponent } from './newPages/admissions/admissions.component';
import { AdmissionDetailsComponent } from './newPages/admission-form/admission-details/admission-details.component';
import { ReviewInformationComponent } from './newPages/admission-form/review-information/review-information.component';
import { GetInvolvedComponent } from './newPages/get-involved/get-involved.component';
import { NewsComponent } from './newPages/news/news.component';
import { ContactUsComponent } from './newPages/contact-us/contact-us.component';
import { GalleryComponent } from './newPages/gallery/gallery.component';
import { ForgetComponent } from './newPages/forget/forget.component';
import { StudentDashboardComponent } from './newPages/student-dashboard/student-dashboard.component';
import { CreatePasswordComponent } from './newPages/create-password/create-password.component';
import { ConvertCurrencyPipe } from './pipes/convert-currency/convert-currency.pipe';
import { DatetimeToDurationPipe } from './pipes/datetime-to-duration/datetime-to-duration.pipe';
import { StudentApplicationComponent } from './newPages/student-application/student-application.component';
import { AdmissionLoginComponent } from './newPages/admission-form/admission-login/admission-login.component';
import { AdmissionForgotPasswordComponent } from './newPages/admission-form/admission-forgot-password/admission-forgot-password.component';
import { TestPageComponent } from './newPages/test-page/test-page.component';
import { AdmissionEightClassComponent } from './newPages/admissions/admission-eight-class/admission-eight-class.component';
import { AdmissionElevenClassComponent } from './newPages/admissions/admission-eleven-class/admission-eleven-class.component';
import { NewsBannerComponent } from './newPages/news-banner/news-banner.component';
import { SponsorChildComponent } from './newPages/sponsor-child/sponsor-child.component';
import { SponsorChildProfileComponent } from './newPages/sponsor-child-profile/sponsor-child-profile.component';
import { SponsorFaqsComponent } from './newPages/sponsor-child-profile/sponsor-faqs/sponsor-faqs.component';
import { MoreChildrenComponent } from './newPages/sponsor-child-profile/more-children/more-children.component';
import { DobToAgePipe } from './pipes/dob-to-age.pipe';
import { SponsorshipProgramComponent } from './newPages/admissions/sponsorship-program/sponsorship-program.component';
import { AddSponsorComponent } from './newPages/add-sponsor/add-sponsor.component';
import { YourSponsorshipsComponent } from './newPages/add-sponsor/your-sponsorships/your-sponsorships.component';
import { SponsorshipSummaryComponent } from './newPages/add-sponsor/sponsorship-summary/sponsorship-summary.component';
import { SponsorContactDetailsComponent } from './newPages/add-sponsor/sponsor-contact-details/sponsor-contact-details.component';
import { SponsorshipThanksComponent } from './newPages/add-sponsor/sponsorship-thanks/sponsorship-thanks.component';
import { ExchangeRatePipe } from './pipes/exchange-rate.pipe';
import { SponsorPageComponent } from './newPages/sponsor-page/sponsor-page.component';
import { DueDatePipe } from './pipes/due-date/due-date.pipe';
import { SponsorPageFaqComponent } from './newPages/sponsor-page/sponsor-page-faq/sponsor-page-faq.component';
import { InquiryComponent } from './newPages/inquiry/inquiry.component';
import { RegularClassesComponent } from './newPages/admissions/regular-classes/regular-classes.component';
import { DayScholarsComponent } from './newPages/admissions/day-scholars/day-scholars.component';
import { FaqComponent } from './newPages/faq/faq.component';
import { UpdatesComponent } from './newPages/updates/updates.component';
import { ArticlesComponent } from './newPages/articles/articles.component';
import { FederalEducationMinisterComponent } from './newPages/articles/federal-education-minister/federal-education-minister.component';
import { JoyfulTripOnTheDoubleDeckerComponent } from './newPages/articles/joyful-trip-on-the-double-decker/joyful-trip-on-the-double-decker.component';
import { GoldenOpportunityComponent } from './newPages/articles/golden-opportunity/golden-opportunity.component';
import { GovernmentOfPakistanGiftedComponent } from './newPages/articles/government-of-pakistan-gifted/government-of-pakistan-gifted.component';
import { WorldBoxingChampionComponent } from './newPages/articles/world-boxing-champion/world-boxing-champion.component';
import { ZamurradKhanInauguratesComponent } from './newPages/articles/zamurrad-khan-inaugurates/zamurrad-khan-inaugurates.component';
import { FunFilledTripComponent } from './newPages/articles/fun-filled-trip/fun-filled-trip.component';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';
import { RegularClassesFormComponent } from './newPages/regular-classes-form/regular-classes-form.component';
import { RegularClassesReviewComponent } from './newPages/regular-classes-review/regular-classes-review.component';
import { DayScholarsFormComponent } from './newPages/day-scholars-form/day-scholars-form.component';
import { VolunteerComponent } from './newPages/volunteer/volunteer.component';
import { FundRaisingEventComponent } from './newPages/fund-raising-event/fund-raising-event.component';
import { FileNameFromUrlPipe } from './pipes/file-name-from-url/file-name-from-url.pipe';
import { DayScholarsReviewComponent } from './newPages/day-scholars-review/day-scholars-review.component';
import { FriendsCircleComponent } from './newPages/friends-circle/friends-circle.component';
import { ScholarshipProgramFormComponent } from './newPages/scholarship-program-form/scholarship-program-form.component';
import { ScholarshipProgramReviewComponent } from './newPages/scholarship-program-review/scholarship-program-review.component';
import { RequestTourComponent } from './newPages/request-tour/request-tour.component';
import { LegalComponent } from './newPages/legal/legal.component';
import { PaymentFormCheckoutComponent } from './newPages/payment-form-checkout/payment-form-checkout.component';
import { TermsComponent } from './newPages/terms/terms.component';
import { InaugurationOfNewBuildingComponent } from './newPages/articles/inauguration-of-new-building/inauguration-of-new-building.component';
import { BankDetailsComponent } from './newPages/bank-details/bank-details.component';
import { ShortNamePipe } from './pipes/short-name/short-name.pipe';
import { AHeartwarmingSehriEventComponent } from './newPages/articles/a-heartwarming-sehri-event/a-heartwarming-sehri-event.component';
import { AnnualResultDayComponent } from './newPages/articles/annual-result-day/annual-result-day.component';
import { DonationJazzcashComponent } from './newPages/donation-jazzcash/donation-jazzcash.component';
import { HighlightsOfRamadanComponent } from './newPages/articles/highlights-of-ramadan/highlights-of-ramadan.component';
import { BridgingBordersWithKindnessComponent } from './newPages/articles/bridging-borders-with-kindness/bridging-borders-with-kindness.component';
import { EmpoweringOrphansThroughEducationComponent } from './newPages/articles/empowering-orphans-through-education/empowering-orphans-through-education.component';
import { DynamicBlogComponent } from './newPages/dynamic-blog/dynamic-blog.component';
import { DynamicArticleComponent } from './newPages/dynamic-article/dynamic-article.component';
import { WriterComponent } from './newPages/writer/writer.component';
import { BlogComponent } from './newPages/blog/blog.component';
import { QurbaniModalComponent } from './include/qurbani-modal/qurbani-modal.component';
import { DonateQurbaniComponent } from './newPages/donate-qurbani/donate-qurbani.component';
import { GiveCharityInMuharramComponent } from './newPages/give-charity-in-muharram/give-charity-in-muharram.component';
import { MuharramModalComponent } from './include/muharram-modal/muharram-modal.component';
import { SupportOurNeedsComponent } from './newPages/get-involved/support-our-needs/support-our-needs.component';
import { SupportNeedsDescriptionComponent } from './newPages/get-involved/support-our-needs/support-needs-description/support-needs-description.component';
import { PrivacyPolicyComponent } from './newPages/privacy-policy/privacy-policy.component';
import { NotFoundPageComponent } from './newPages/not-found-page/not-found-page.component';

// import FilePondPluginPdfPreview from "filepond-plugin-pdf-preview";
// registerPlugin(FilePondPluginFileValidateType);
// registerPlugin(FilePondPluginFileValidateSize);
registerPlugin(FilePondPluginImagePreview);
// registerPlugin(FilePondPluginPdfPreview);

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    // HomeComponent,
    FooterComponent,
    // CertificatesComponent,
    // AuditReportsComponent,
    // ProjectDetailsComponent,
    GalleryComponent,
    // ItemDetailComponent,
    // GalleryAlbumComponent,
    // CreateProfileComponent,
    // DonationDetailsComponent,
    // DonationPaymentComponent,
    // GoldenArrowPakistanComponent,
    // IrshadBbComplexComponent,
    // CollegeGujarkhanComponent,
    // AzizBhattiCampusComponent,
    // PakistanGreatHomesComponent,
    // RashidMinhasCampusComponent,
    // SarwarShaheedCampusComponent,
    // SheikhupuraCenterComponent,
    // SukkurCenterComponent,
    // PrivacyComponent,
    TermsComponent,
    // RefundExchangePolicyComponent,
    // DonateFormComponent,
    // ThankyouComponent,
    // SorryComponent,
    // VideoGalleryComponent,
    // UnderReviewComponent,
    // ContactComponent,
    // ResetPasswordComponent,
    // PersonalInformationComponent,
    // AcademicDetailsComponent,
    // SubmissionComponent,
    // PastPapersComponent,
    // LandingPageComponent,
    // HomeNewComponent,
    // DonationPaymentProcessComponent,
    // DonationPaymentStepsComponent,
    // CreateProfileStepComponent,
    // DonationDetailStepComponent,
    // DonationReviewComponent,
    // ReviewDetailStepComponent,
    HomepageComponent,
    DashboardComponent,
    SignInComponent,
    SignUpComponent,
    LogoutComponent,
    ProfileComponent,
    DonateComponent,
    DateAsAgoPipe,
    ThanksComponent,
    PaymentFormComponent,
    TestimonialsComponent,
    AboutUsComponent,
    WhyUsComponent,
    OurProjectsComponent,
    CampusesComponent,
    BloodBankComponent,
    AdmissionFormComponent,
    AdmissionSignupComponent,
    AdmissionsComponent,
    AdmissionDetailsComponent,
    ReviewInformationComponent,
    GetInvolvedComponent,
    NewsComponent,
    ContactUsComponent,
    ForgetComponent,
    StudentDashboardComponent,
    CreatePasswordComponent,
    ConvertCurrencyPipe,
    DatetimeToDurationPipe,
    StudentApplicationComponent,
    AdmissionLoginComponent,
    AdmissionForgotPasswordComponent,
    TestPageComponent,
    AdmissionEightClassComponent,
    AdmissionElevenClassComponent,
    SponsorChildComponent,
    NewsBannerComponent,
    SponsorChildProfileComponent,
    SponsorFaqsComponent,
    MoreChildrenComponent,
    DobToAgePipe,
    SponsorshipProgramComponent,
    AddSponsorComponent,
    YourSponsorshipsComponent,
    SponsorshipSummaryComponent,
    SponsorContactDetailsComponent,
    SponsorshipThanksComponent,
    ExchangeRatePipe,
    SponsorPageComponent,
    DueDatePipe,
    SponsorPageFaqComponent,
    InquiryComponent,
    RegularClassesComponent,
    DayScholarsComponent,
    FaqComponent,
    UpdatesComponent,
    ArticlesComponent,
    FederalEducationMinisterComponent,
    JoyfulTripOnTheDoubleDeckerComponent,
    GoldenOpportunityComponent,
    GovernmentOfPakistanGiftedComponent,
    WorldBoxingChampionComponent,
    ZamurradKhanInauguratesComponent,
    FunFilledTripComponent,
    SafeUrlPipe,
    RegularClassesFormComponent,
    RegularClassesReviewComponent,
    DayScholarsFormComponent,
    VolunteerComponent,
    FundRaisingEventComponent,
    FileNameFromUrlPipe,
    DayScholarsReviewComponent,
    FriendsCircleComponent,
    ScholarshipProgramFormComponent,
    ScholarshipProgramReviewComponent,
    RequestTourComponent,
    LegalComponent,
    PaymentFormCheckoutComponent,
    InaugurationOfNewBuildingComponent,
    BankDetailsComponent,
    ShortNamePipe,
    AHeartwarmingSehriEventComponent,
    AnnualResultDayComponent,
    DonationJazzcashComponent,
    HighlightsOfRamadanComponent,
    BridgingBordersWithKindnessComponent,
    EmpoweringOrphansThroughEducationComponent,
    DynamicArticleComponent,
    DynamicBlogComponent,
    BlogComponent,
    QurbaniModalComponent,
    DonateQurbaniComponent,
    WriterComponent,
    GiveCharityInMuharramComponent,
    MuharramModalComponent,
    SupportOurNeedsComponent,
    SupportNeedsDescriptionComponent,
    PrivacyPolicyComponent,
    NotFoundPageComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FilePondModule,
    NgSelectModule,
    MatSliderModule,
    RecaptchaModule,
    NgxMaskModule.forRoot({
      showMaskTyped: true,
      // clearIfNotMatch : true
    }),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
