import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, HelperService } from 'src/app/services/_services';

declare var $;

@Component({
  selector: 'app-review-information',
  templateUrl: './review-information.component.html',
  styleUrls: ['./review-information.component.css']
})
export class ReviewInformationComponent implements OnInit {

  public formId                   : any;
  public loading                  : any;
  public admissionInfo            : any;
  public thanksPopupShow          : any = false;

  public adminAction              : any =  {
                                             "comment"  : '',                                     
                                           }

  public accepted                 : any = false;                                    
  public rejected                 : any = false;                                    

  constructor(
    private activatedRoute         : ActivatedRoute,
    private helperService          : HelperService,
    private router                 : Router
  ) { 
    let url = activatedRoute.snapshot['_routerState'].url;
    this.getID(url);
  }

  @Input ('reviewPage') reviewPage : any;

  ngOnInit(): void {
    console.log('reviewPage::', this.reviewPage);
    
  }

  getID(url){
    url = url.split('/');
    this.formId = url[url.length - 1]    ;
    this.getApplication(this.formId);
  }

  getApplication(appId){
    this.loading = true;

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';    

    let data = new FormData();
    data.append("id",appId);    
    data.append("action", "preview-admission");    

    this.helperService.httpPostRequests(url, data).then(resp => {          
    
      if(resp.status == "OK"){
          this.admissionInfo = resp.data;

        console.log('this.admissionInfo::',this.admissionInfo);
        

      } 
    
      this.loading = false;          

    }).catch(error => {
        console.error("error: ",error);
        this.loading = false;
    });


  }

  rejectApplication(appId){
    if(!this.adminAction.comment){
      $('textarea').addClass('invalid');
    } else {
      this.loading = true;

      let url  = AppSettings.BACK_ENDPOINT + 'admissions/accept_reject/' + appId;    

      let data = new FormData();
      data.append("application_status",   '2');    
      data.append("admission_comment ", this.adminAction.comment);    

      this.helperService.httpPostRequests(url, data).then(resp => {          
      
        if(resp.status == 200){

          console.log('resp::',resp);


        } 
      
        this.loading = false;          

      }).catch(error => {
          console.error("error: ",error);
          this.loading = false;
      });
    }
    
  }

  editApplicationPage(formId){
    localStorage.setItem('adminRole', '2');
    this.router.navigate(['/admission/fill-form/' + formId]);
  }

}
