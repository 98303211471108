import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AppSettings,
  AuthenticationService,
  COUNTRYLIST,
  HelperService,
} from 'src/app/services/_services';
// import { FormControl } from '@angular/forms';
import { HttpHeaders } from '@angular/common/http';
import { HttpClient } from '@angular/common/http';
import { Chart } from 'chart.js';
import Swal from 'sweetalert2';
// import { AdmissionForgotPasswordComponent } from '../admission-form/admission-forgot-password/admission-forgot-password.component';
import { of } from 'rxjs';
declare var $, Swiper, window;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css', './dashboard-mob.css'],
})
export class DashboardComponent implements OnInit, AfterViewInit {
  public activePage: any = 'account';
  // public selectedPrivacy                    : any = 'private';
  public profileImg: any;
  public donations: any;
  public referrals: any;
  public sponsorships: any;
  public memberships: any;
  public subscriptions: any;
  // public monthlyAmount                      : any = 0;
  public donationAmout: any = 0;
  public referralCount: any = 0;
  public referralAmount: any = 0;
  public countryOptions: any;
  public flagOptions: any;
  public selectedCountry: any = 'United States';
  public selectedFlag: any = 'US';

  public privacyOptions = [
    { name: 'private', value: '0' },
    { name: 'public', value: '1' },
  ];

  public donationsObj: any = {
    oneTime: [],
    monthly: false,
    sponser: false,
    member: false,
  };

  shortLink: string = '';
  loading: boolean = false; // Flag variable
  msg = '';
  disabledBtn: any = true;

  public profileBtnDisabled: any = true;
  public routerSubscription: any;

  public profile: any;

  public dateOptions: any = [];
  public monthOptions: any = [];
  public yearOptions: any = [];
  public selectedDate: any;
  public selectedMonth: any;
  public selectedYear: any;

  public privacyMode: any = false;
  public donationUpdate: any = false;

  public popupShow: any = false;
  public monthlyDonationSlider: any = 5;
  public referralsSlider: any = 0;
  public potentialWrapperShow: any = false;

  public authSub: any;
  public currentUser: any;
  public chart: any;

  public donationAmounts: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  public referralAmounts: any = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  public passChange: any = {
    old_password: '',
    password: '',
    confirmPass: '',
  };
  public passChangeError: any = '';
  public passChangeSuccess: any = '';
  public passChangeLoad: any = false;
  public changePassModal: any = false;

  public cancelModal: any = false;
  public cancelIndex: any;
  public cancelArray: any;
  public cancelLoading: any;
  public cancelSuccess: any = '';

  public countryList;
  public countryAgainstCode;

  public phone: any = {
    code: '',
    number: '',
    country: '',
  };
  public whatsapp: any = {
    code: '',
    number: '',
    country: '',
  };

  public showPausedPopup: any = false;
  public isLocalServer: any = false;
  public activeTab: any = 'sponsorships';
  public hideProfileTop: any = false;

  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private http: HttpClient,
    private helperService: HelperService,
    private activatedRoute: ActivatedRoute
  ) {
    if (
      window.location.origin.includes('localhost') ||
      window.location.origin.includes('192.168.19.133')
    ) {
      this.isLocalServer = true;
    }

    let url = activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);
    this.countryList = COUNTRYLIST.COUNTRY_LIST;
    this.countryAgainstCode = COUNTRYLIST.COUNTRY_AGAINST_CODE;
  }

  ngAfterViewInit(): void {}

  ngOnInit(): void {
    this.http
      .get('https://trial.mobiscroll.com/content/countries.json')
      .subscribe((resp: any) => {
        const countries = [];
        const countriesImg = [];
        for (let i = 0; i < resp.length; ++i) {
          const country = resp[i];
          countries.push(country.text);
          countriesImg.push(country.value);
        }
        this.countryOptions = countries;
        this.countryOptions.push('Canada');
        this.countryOptions.sort();
        var canadaIndex = this.countryOptions.indexOf('Canada');
        this.flagOptions = countriesImg;
        this.flagOptions.splice(canadaIndex, 0, 'CA');
      });

    if (typeof localStorage != 'undefined') {
      this.authSub = this.authenticationService.currentUserS$.subscribe(
        (user) => {
          if (user) {
            this.profile = user;
            if (user.type == 'student') {
              this.router.navigate(['/admission/student-application']);
            }
            console.log('this.profile::', this.profile);
            this.setPage();
          } else {
            this.profile = null;
            this.router.navigate(['/sign-in']);
          }
        }
      );
    }

    if (this.activePage == 'account') {
      this.getAllDonations();
    }

    this.setDateOptions();
    this.setYearOptions();

    $(document).ready(function () {
      $('.mobmydropbtn').click(function () {
        $('.mobbuttonsWrapper').slideToggle('slow');
      });
    });
  }

  setPage() {
    let fullDOB = this.profile.DOB ? new Date(this.profile.DOB) : null;
    if (fullDOB) {
      this.selectedYear = fullDOB.getFullYear();
      this.selectedMonth =
        fullDOB.getMonth() + 1 < 10
          ? '0' + (fullDOB.getMonth() + 1)
          : (fullDOB.getMonth() + 1).toString();
      this.selectedDate = fullDOB.getDate();
    }

    if (
      this.profile.phone_number &&
      this.profile.phone_number.includes('+') &&
      this.profile.phone_number.includes(' ')
    ) {
      this.phone.number = this.profile.phone_number.split(' ')[1];
      this.phone.code = this.profile.phone_number
        .split(' ')[0]
        .replace('+', '');
      this.phone.country = this.countryAgainstCode[this.phone.code]['isoCode2'];
    } else {
      this.phone.code = '92';
      this.phone.country = 'PK';
    }

    if (
      this.profile.whatsapp_number &&
      this.profile.whatsapp_number.includes('+') &&
      this.profile.whatsapp_number.includes(' ')
    ) {
      this.whatsapp.number = this.profile.whatsapp_number.split(' ')[1];
      this.whatsapp.code = this.profile.whatsapp_number
        .split(' ')[0]
        .replace('+', '');
      this.whatsapp.country =
        this.countryAgainstCode[this.whatsapp.code]['isoCode2'];
    } else {
      this.whatsapp.code = '92';
      this.whatsapp.country = 'PK';
    }
  }

  setChart() {
    var labels = this.getMonths();

    this.chart = new Chart('impactGraph', {
      type: 'bar',
      data: {
        labels: labels,
        datasets: [
          {
            data: this.donationAmounts,
            backgroundColor: '#003265',
          },
          {
            data: this.referralAmounts,
            backgroundColor: '#BBD4DD',
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          yAxes: [
            {
              position: 'right',
              ticks: {
                fontSize: 14,
                fontColor: '#C5C5C5',
                beginAtZero: true,
                callback: function (value) {
                  if (value % 1 === 0) {
                    return value;
                  }
                },
              },
              stacked: true,
            },
          ],
          xAxes: [
            {
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: '#C5C5C5',
                fontSize: 14,
                autoSkip: true,
                maxRotation: 0,
                autoSkipPadding: 0,
              },
              stacked: true,
            },
          ],
        },
        legend: {
          display: false,
        },
      },
    });
  }

  getMonths() {
    var date = new Date().getMonth();

    var months = [];
    var oldDate = date;
    let index = date % 12;
    date += 12;

    while (1) {
      switch (index) {
        case 0: {
          months.unshift(['Jan', new Date().getFullYear()]);
          break;
        }
        case 1: {
          months.unshift('Feb');
          break;
        }
        case 2: {
          months.unshift('Mar');
          break;
        }
        case 3: {
          months.unshift('Apr');
          break;
        }
        case 4: {
          months.unshift('May');
          break;
        }
        case 5: {
          months.unshift('Jun');
          break;
        }
        case 6: {
          months.unshift('Jul');
          break;
        }
        case 7: {
          months.unshift('Aug');
          break;
        }
        case 8: {
          months.unshift('Sep');
          break;
        }
        case 9: {
          months.unshift('Oct');
          break;
        }
        case 10: {
          months.unshift('Nov');
          break;
        }
        default: {
          months.unshift(['Dec', new Date().getFullYear() - 1]);
          break;
        }
      }

      date--;
      index = date % 12;
      if (index - 1 == oldDate) {
        return months;
      }
    }

    return months;
  }

  routeCheck(url) {
    if (url == '/dashboard/settings') {
      this.activePage = 'setting';
    }
  }

  selectFile(event: any) {
    //Angular 11, for stricter type
    if (!event.target.files[0] || event.target.files[0].length == 0) {
      this.msg = 'You must select an image';
      return;
    }

    var mimeType = event.target.files[0].type;

    if (mimeType.match(/image\/*/) == null) {
      this.msg = 'Only images are supported';
      return;
    }

    var reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);

    reader.onload = (_event) => {
      this.msg = '';
      this.profileImg = reader.result;
    };

    this.profileBtnDisabled = false;
  }

  setDateOptions() {
    for (let i = 1; i < 32; i++) {
      this.dateOptions.push(i);
      if (i < 13) {
        switch (i) {
          case 1: {
            this.monthOptions.push({ name: 'January', value: '01' });
            break;
          }
          case 2: {
            this.monthOptions.push({ name: 'February', value: '02' });
            break;
          }
          case 3: {
            this.monthOptions.push({ name: 'March', value: '03' });
            break;
          }
          case 4: {
            this.monthOptions.push({ name: 'April', value: '04' });
            break;
          }
          case 5: {
            this.monthOptions.push({ name: 'May', value: '05' });
            break;
          }
          case 6: {
            this.monthOptions.push({ name: 'June', value: '06' });
            break;
          }
          case 7: {
            this.monthOptions.push({ name: 'July', value: '07' });
            break;
          }
          case 8: {
            this.monthOptions.push({ name: 'August', value: '08' });
            break;
          }
          case 9: {
            this.monthOptions.push({ name: 'September', value: '09' });
            break;
          }
          case 10: {
            this.monthOptions.push({ name: 'October', value: '10' });
            break;
          }
          case 11: {
            this.monthOptions.push({ name: 'November', value: '11' });
            break;
          }
          default: {
            this.monthOptions.push({ name: 'December', value: '12' });
            break;
          }
        }
      }
    }
  }

  setYearOptions() {
    for (let i = 2023; i > 1950; i--) {
      this.yearOptions.push(i);
    }
  }

  updateProfile() {
    let data = new FormData();
    data.append('action', 'update_user_form');
    data.append('ID', this.profile.ID);

    if (this.selectedMonth && this.selectedDate && this.selectedYear) {
      let dob =
        this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
      data.append('DOB', dob);
    }

    this.profile.firstName && data.append('firstName', this.profile.firstName);
    this.profile.lastName && data.append('lastName', this.profile.lastName);

    this.phone.number &&
      data.append(
        'phone_number',
        '+' + this.phone.code + ' ' + this.phone.number
      );
    this.whatsapp.number &&
      data.append(
        'whatsapp_number',
        '+' + this.whatsapp.code + ' ' + this.whatsapp.number
      );
    this.profile.address && data.append('address', this.profile.address);
    this.profile.city && data.append('city', this.profile.city);
    this.profile.country && data.append('country', this.profile.country);

    this.profile.location && data.append('location', this.profile.location);
    this.profile.bio && data.append('bio', this.profile.bio);
    this.profile.facebook && data.append('facebook', this.profile.facebook);
    this.profile.twitter && data.append('twitter', this.profile.twitter);
    this.profile.instagram && data.append('instagram', this.profile.instagram);
    this.profile.website && data.append('website', this.profile.website);
    this.profile.profilePrivacy &&
      data.append('profilePrivacy', this.profile.profilePrivacy);

    this.updateCall(data, 'data');
  }

  updateDonation() {
    this.profile.donationUpdate =
      this.profile.donationUpdate == '1'
        ? (this.profile.donationUpdate = '0')
        : (this.profile.donationUpdate = '1');

    let data = new FormData();
    data.append('action', 'update_user');
    data.append('ID', this.profile.ID);
    this.profile.donationUpdate &&
      data.append('donationUpdate', this.profile.donationUpdate);

    this.updateCall(data, 'data');
  }

  updateCall(data: any, updateType: string) {
    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    });

    let url = AppSettings.API_ENDPOINT + 'user-site-login.php';

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        if (resp.status != 'ERROR') {
          Swal.fire('Profile Updated Successfully!', '', 'success');
          this.authenticationService.setCurrentUserValue(resp.data);
          this.disabledBtn = true;
          if (updateType == 'image') {
            this.profileBtnDisabled = true;
            this.router.navigate(['/dashboard/settings']);
          }
        } else {
        }
      })
      .catch((error) => {
        console.error('error: ');
      });
  }

  getAllDonations() {
    let data = new FormData();
    data.append('action', 'get-user-donations');
    data.append('profileUrl', this.profile.profileUrl);

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      Accept: 'multipart/form-data',
    });

    let url = AppSettings.API_ENDPOINT + 'donations/user-donation.php';

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        if (resp.status != 'ERROR') {
          console.log('donations response::', resp);
          this.donations = resp.data.user_donation;
          this.referrals = resp.data.referral_donation;
          this.sponsorships = resp.data.sponsership;
          this.sponsorships = this.sponsorships
            ? this.sponsorships.reverse()
            : this.sponsorships;

          if (resp.data.subscriptions) {
            this.donationsObj.monthly = true;
            this.subscriptions = resp.data.subscriptions;
          }

          if (resp.data.user_membership) {
            this.donationsObj.member = true;
            this.memberships = resp.data.user_membership;
          }

          let thisYear = new Date().getFullYear();
          let thisMonth = new Date().getMonth();

          if (this.donations) {
            for (let i = 0; i < this.donations.length; i++) {
              const element =
                this.donations[i].active_subscription != 'unpaid'
                  ? parseInt(this.donations[i].amount)
                  : 0;

              let donationDate = new Date(this.donations[i].date);

              let donationMonth = donationDate.getMonth();
              let donationYear = donationDate.getFullYear();

              if (donationYear == thisYear) {
                var val =
                  this.donationAmounts[10 - (thisMonth - donationMonth)];
                val += element;

                this.donationAmounts[10 - (thisMonth - donationMonth)] = val;
              } else if (donationYear == thisYear - 1) {
                if (donationMonth > thisMonth + 1) {
                  var val =
                    this.donationAmounts[donationMonth - (thisMonth + 2)];
                  val += element;

                  this.donationAmounts[donationMonth - (thisMonth + 2)] = val;
                }
              }

              this.donationAmout += element;

              const donation = this.donations[i];
              if (donation.duration == 'One Time') {
                this.donationsObj.oneTime.push(donation);
              } else if (donation.duration == 'Monthly') {
              }
            }
          }

          if (this.sponsorships) {
            this.donationsObj.sponser = true;

            for (let i = 0; i < this.sponsorships.length; i++) {
              const element =
                this.sponsorships[i].is_sponsered != '0' ||
                this.sponsorships[i].is_canceled == '1'
                  ? parseInt(this.sponsorships[i].price)
                  : 0;

              let donationDate = new Date(this.sponsorships[i].sponserd_date);

              let donationMonth = donationDate.getMonth();
              let donationYear = donationDate.getFullYear();

              if (donationYear == thisYear) {
                var val =
                  this.donationAmounts[10 - (thisMonth - donationMonth)];
                val += element;

                this.donationAmounts[10 - (thisMonth - donationMonth)] = val;
              } else if (donationYear == thisYear - 1) {
                if (donationMonth > thisMonth + 1) {
                  var val =
                    this.donationAmounts[donationMonth - (thisMonth + 2)];
                  val += element;

                  this.donationAmounts[donationMonth - (thisMonth + 2)] = val;
                }
              }

              this.donationAmout += element;
            }
          }

          if (this.subscriptions) {
            for (let i = 0; i < this.subscriptions.length; i++) {
              const element =
                this.subscriptions[i].is_sponsered != 'unpaid'
                  ? parseInt(this.subscriptions[i].amount)
                  : 0;

              let donationDate = new Date(
                this.subscriptions[i].last_donation_date
              );

              let donationMonth = donationDate.getMonth();
              let donationYear = donationDate.getFullYear();

              if (donationYear == thisYear) {
                var val =
                  this.donationAmounts[10 - (thisMonth - donationMonth)];
                val += element;

                this.donationAmounts[10 - (thisMonth - donationMonth)] = val;
              } else if (donationYear == thisYear - 1) {
                if (donationMonth > thisMonth + 1) {
                  var val =
                    this.donationAmounts[donationMonth - (thisMonth + 2)];
                  val += element;

                  this.donationAmounts[donationMonth - (thisMonth + 2)] = val;
                }
              }

              this.donationAmout += element;
            }
          }

          if (this.sponsorships) {
            this.activeTab = 'sponsorships';
          } else if (this.subscriptions) {
            this.activeTab = 'subscriptions';
          } else if (this.donations) {
            this.activeTab = 'oneTime';
          } else {
            this.hideProfileTop = true;
          }

          if (this.referrals) {
            for (let i = 0; i < this.referrals.length; i++) {
              const element = parseInt(this.referrals[i].amount);
              this.referralAmount += element;
              this.referralCount++;

              let donationDate = new Date(this.referrals[i].date);

              let donationMonth = donationDate.getMonth();
              let donationYear = donationDate.getFullYear();

              if (donationYear == thisYear) {
                var val =
                  this.referralAmounts[10 - (thisMonth - donationMonth)];
                val += element;

                this.referralAmounts[10 - (thisMonth - donationMonth)] = val;
              } else if (donationYear == thisYear - 1) {
                if (donationMonth > thisMonth + 1) {
                  var val =
                    this.referralAmounts[donationMonth - (thisMonth + 2)];
                  val += element;

                  this.referralAmounts[donationMonth - (thisMonth + 2)] = val;
                }
              }
            }
          }

          // this.setChart();
        }
      })
      .catch((error) => {
        console.error('error: ', error);
      });
  }

  getSubTotalAmount(donation) {
    var j = 0;
    for (let i = 0; i < donation.donations.length; i++) {
      const element = donation.donations[i];
      if ((element.status = '1')) {
        j++;
      }
    }

    return Number(donation.amount) * j;
  }

  cancelSubscription() {
    let index = this.cancelIndex;
    let donation =
      this.cancelArray == 'donations'
        ? this.donations[index]
        : this.sponsorships[index];

    // Show Loader
    this.cancelLoading = true;

    if (this.cancelArray == 'donations') {
      let url = AppSettings.API_ENDPOINT + 'stripe_actions/api.php';

      let data = new FormData();
      data.append('action', 'cancelSubscription');
      data.append('donationId', donation.id);
      data.append('subId', donation.stripe_transaction_id);
      data.append('env', this.isLocalServer ? 'test' : 'live');

      this.helperService
        .httpPostRequests(url, data)
        .then((resp) => {
          this.cancelLoading = false;
          donation.active_subscription = '0';
          this.cancelSuccess =
            'Your Monthly Donation has been Cancelled Successfully!';
          setTimeout(() => {
            this.cancelSuccess = '';
            this.unsetCancelModal();
          }, 3000);
        })
        .catch((error) => {
          console.error('error: ', error);
        });
    } else {
      let url = AppSettings.BACK_ENDPOINT + 'delete_subscription_item';

      let data = new FormData();
      data.append('item_id', donation.item_id);

      this.helperService
        .httpPostRequests(url, data)
        .then((resp) => {
          this.cancelLoading = false;
          donation.is_sponsered = '0';
          this.cancelSuccess =
            'Your Sponsorship has been Cancelled Successfully!';
          setTimeout(() => {
            this.cancelSuccess = '';
            this.unsetCancelModal();
          }, 3000);
        })
        .catch((error) => {
          Swal.fire(
            'Your Request cannot be completed!',
            'Try again after some time!',
            'error'
          );
          this.cancelLoading = false;
          this.cancelSuccess = '';
          this.unsetCancelModal();
          console.error('error: ', error);
        });
    }
  }

  updateProfileImage() {
    let data = new FormData();
    data.append('action', 'update_user');
    data.append('ID', this.profile.ID);
    data.append('imgUrl', this.profileImg);
    this.updateCall(data, 'image');
  }

  changepass() {
    if (
      this.passChange.old_password.length > 4 &&
      this.passChange.password.length > 4 &&
      this.passChange.password == this.passChange.confirmPass &&
      this.passChange.password != this.passChange.old_password
    ) {
      this.passChangeError = '';
      this.passChangeLoad = true;

      let data = new FormData();
      data.append('action', 'update-profile-password');
      data.append('ID', this.profile.ID);
      data.append('old_password', this.passChange.old_password);
      data.append('password', this.passChange.password);

      let corsHeaders = new HttpHeaders({
        'Content-Type': 'multipart/form-data',
        Accept: 'multipart/form-data',
      });

      let url = AppSettings.API_ENDPOINT + 'user-site-login.php';

      this.helperService
        .httpPostRequests(url, data)
        .then((resp) => {
          if (resp.status != 'ERROR') {
            this.passChangeSuccess = 'Password changed succussfully!';

            this.passChange.old_password = '';
            this.passChange.password = '';
            this.passChange.confirmPass = '';

            setTimeout(() => {
              this.passChangeSuccess = '';
              this.changePassModal = false;
            }, 3000);
          } else {
            this.passChangeError = resp.message;
            setTimeout(() => {
              this.passChangeError = '';
            }, 3000);
          }
        })
        .catch((error) => {
          console.error('error: ', error);
        });

      this.passChangeLoad = false;
    } else {
      if (this.passChange.old_password.length < 5) {
        this.passChangeError = 'Enter Valid Old Password';
      } else if (this.passChange.password.length < 5) {
        this.passChangeError = 'Enter Valid New Password';
      } else if (this.passChange.password != this.passChange.confirmPass) {
        this.passChangeError = 'Confirm Password do not Match!';
      } else if (this.passChange.password == this.passChange.old_password) {
        this.passChangeError = 'Old and New Passwords cannot be same!';
      }

      setTimeout(() => {
        this.passChangeError = '';
      }, 3000);
    }
  }

  setCancelModal(index, cancelArray) {
    this.cancelModal = true;
    this.fixBody();
    this.cancelIndex = index;
    this.cancelArray = cancelArray;
  }
  unsetCancelModal() {
    this.cancelModal = false;
    this.unfixBody();
    this.cancelIndex = null;
  }

  fixBody() {
    $('body').css('height', '100vh');
    $('body').css('overflow', 'hidden');
  }
  unfixBody() {
    $('body').css('height', 'unset');
    $('body').css('overflow', 'unset');
  }

  setPhoneValues(numberType, country, flag) {
    if (numberType == 'phone') {
      this.phone.code = this.countryList[country]['countryCodes'][0];
      this.phone.country = flag;
      console.log('this.phone::', this.phone);
    } else if (numberType == 'whatsapp') {
      this.whatsapp.code = this.countryList[country]['countryCodes'][0];
      this.whatsapp.country = flag;
    }
  }

  getSerialNumber(index, toMatch) {
    if (index > 0) {
      var skippedElems = 0;
      for (let i = 0; i < index; i++) {
        const element = this.donations[i];
        if (element.duration != toMatch) {
          skippedElems++;
        }
      }
      return index - skippedElems + 1;
    } else {
      return index + 1;
    }
  }

  getNextDonationDate(donationDate, timeperiod = 'month') {
    var oldDonationDate = new Date(donationDate);
    var year = oldDonationDate.getFullYear();
    var month = oldDonationDate.getMonth();
    var day = oldDonationDate.getDate();

    if (timeperiod == 'month') {
      return '' + year + '-' + (month + 2) + '-' + day;
    } else {
      return '' + (year + 1) + '-' + (month + 1) + '-' + day;
    }
  }

  lastSponsoredDate(sponsoredDate, isSponsored) {
    var today = new Date();
    var startDate = new Date(sponsoredDate);
    var monthsDiff =
      (today.getFullYear() - startDate.getFullYear()) * 12 +
      (today.getMonth() - startDate.getMonth());
    var DaysDiff = today.getDate() - startDate.getDate();

    monthsDiff =
      startDate.getDate() > today.getDate() ? monthsDiff - 1 : monthsDiff;

    var monthString =
      monthsDiff == 1
        ? ' Month'
        : monthsDiff != 0
        ? ' Months'
        : DaysDiff == 1
        ? ' Day'
        : DaysDiff == 0
        ? ' Today'
        : ' Days';
    monthsDiff = monthsDiff == 0 ? DaysDiff : monthsDiff;

    var waitingString = isSponsored == '0' ? 'Waiting ' : 'Sponsored ';
    return (
      waitingString +
      (monthsDiff == 0 ? '' : 'since ') +
      (monthsDiff == 0 ? '' : monthsDiff) +
      monthString
    );
  }

  donateOneTimeAgain(id, el) {
    var loadingSib = $(el).siblings()[0];
    $(loadingSib).removeClass('hidden');
    $(el).addClass('hidden');

    let url = AppSettings.API_ENDPOINT + 'stripe_actions/api.php';

    let data = new FormData();
    data.append('action', 'pay_again');
    data.append('id', id);
    data.append('success_url', window.location.origin + '/thanks');
    data.append('cancel_url', window.location.origin + '/');
    data.append('env', this.isLocalServer ? 'test' : 'live');

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        window.location.href = resp.checkout.url;
      })
      .catch((error) => {
        console.error('error: ', error);
        $(loadingSib).addClass('hidden');
        $(el).removeClass('hidden');
      });
  }

  manageSponsorship(el) {
    var loadingSib = $(el).siblings()[0];
    $(loadingSib).removeClass('hidden');
    $(el).addClass('hidden');

    let url = AppSettings.BACK_ENDPOINT + 'manage-subscription';

    let data = new FormData();
    data.append('user_id', this.profile.ID);
    data.append('return_url', window.location.origin + '/thanks');
    data.append('env', this.isLocalServer ? 'test' : 'live');

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        window.location.href = resp.session.url;
      })
      .catch((error) => {
        console.error('error: ', error);
        $(loadingSib).addClass('hidden');
        $(el).removeClass('hidden');
      });
  }

  makePayment(donation, el) {
    var loadingSib = $(el).siblings()[0];
    $(loadingSib).removeClass('hidden');
    $(el).addClass('hidden');

    var children = [];
    var duration;

    var childObj = {
      id: donation.orphan_id,
      amount: donation.price,
      timePeriod: donation.time_period,
      name: donation.name,
    };

    children.push(childObj);
    duration = donation.timePeriod;

    let urlNow = window.location.origin;

    let data = {
      children: children,
      user_id: this.profile.ID,
      success_url: urlNow + '/thankyou-sponsor',
      cancel_url: urlNow + '/',
      amount: donation.price * 100,
      otherDonation: 0,
      duration: duration,
      env: this.isLocalServer ? 'test' : 'live',
    };

    console.log('data::', data);

    let url = AppSettings.BACK_ENDPOINT + 'checkout-session';

    this.helperService
      .httpPostRequests(url, data)
      .then((resp) => {
        localStorage.removeItem('orphan');
        localStorage.removeItem('moreChildren');
        window.location.href = resp.session.url;
      })
      .catch((error) => {
        Swal.fire(
          'Something went wrong!',
          'Try again after some time',
          'error'
        );
        $(loadingSib).addClass('hidden');
        $(el).removeClass('hidden');
        console.error('error: ', error);
      });
  }

  getTotalAmountPaid(payments, baseAmount) {
    var totalPaid = 0;
    if (payments) {
      for (let i = 0; i < payments.length; i++) {
        const element = payments[i];
        totalPaid += Number(baseAmount);
      }
      return totalPaid;
    } else {
      return baseAmount;
    }
  }

  subscribeAgain(donation, el) {
    console.log('donation::', donation);
    this.donateOneTimeAgain(donation[0].id, el);
  }

  getUnpaidAmount(donation) {
    return Number(donation[0].amount);
  }

  getAllParents(element) {
    var parents = [];
    var currentElement = element.parentNode;

    while (currentElement && currentElement.nodeType !== Node.DOCUMENT_NODE) {
      parents.push(currentElement);
      currentElement = currentElement.parentNode;
    }

    return parents;
  }

  showCardBody(el) {
    var parents = this.getAllParents(el);
    for (let i = 0; i < parents.length; i++) {
      const element = parents[i];
      if ($(element).hasClass('moneyCardHead')) {
        var sibling = $(element).siblings()[0];
        $(sibling).slideToggle('fast');
        $(sibling).parent().toggleClass('show');
      }
    }
  }

  changeTab(tabName) {
    this.activeTab = tabName;
  }
}
