<section class="bankDetailsSec">
  <div class="container">
    <h1>Pakistan Sweet Home - Bank Details</h1>
    <p class="bankDesc">
      Donating with a bank account is safe and easy. Additionally, individuals
      who donate using a bank account can receive the same tax benefits as
      online donations.
    </p>
  </div>
</section>

<section class="howToSec">
  <div class="container">
    <h2>How to Donate</h2>
    <p class="howToDesc">
      You can directly deposit your donations to our official bank account using
      the following details:
    </p>
    <div class="detailsWrapper">
      <div class="singleDetail">
        <img
          src="assets/new-images/national-bank-of-pakistan.webp"
          alt="National Bank of Pakistan"
        />
        <p><span>Bank:</span> NBP (National Bank of Pakistan)</p>
        <p>
          <span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies
          Place
        </p>
        <p><span>Account Number:</span> 4149300607</p>
        <p><span>IBAN:</span> PK09 NBPA 1531 0041 4930 0607</p>
        <p><span>Branch Code:</span> 1531</p>
        <p><span>Swift Code:</span> NBPAPKKA02i</p>
      </div>
      <div class="singleDetail">
        <img src="assets/new-images/meezan-bank.webp" alt="Meezan Bank" />
        <p><span>Bank:</span> Meezan Bank</p>
        <p>
          <span>Account Title:</span> PAKISTAN SWEET HOME Angels and Fairies
          Place
        </p>
        <p><span>Account Number:</span> 08220102632194</p>
        <p><span>IBAN:</span> PK98 MEZN 0008 2201 0263 2194</p>
        <p><span>Branch Code:</span> 0822</p>
        <p><span>Swift Code:</span> MEZNPKKAGRD</p>
      </div>
    </div>
  </div>
</section>

<section class="confirmationSec">
  <div class="container">
    <h2>Confirmation</h2>
    <p>
      After completing the process, please Email or WhatsApp us a copy of the
      funds transfer receipt issued by your bank (email/screenshot) at
      <strong>donations@pakistansweethome.org.pk</strong> or
      <strong>+92 335 1118477</strong>.
    </p>
  </div>
</section>

<section class="informationSec">
  <div class="container">
    <h2>Information Required for Acknowledgment</h2>
    <p class="informationDesc">
      To help us monitor your payment and keep a record of your valuable
      contribution, we kindly request the following details:
    </p>
    <ul class="infoWrapper">
      <li>Full Name</li>
      <li>Postal Address</li>
      <li>Active WhatsApp Number</li>
      <li>Purpose of Donation</li>
      <li>Email Address</li>
    </ul>
    <p class="lastDesc">
      Once you share the details mentioned above, we will send you the donation
      acknowledgment.
    </p>
  </div>
</section>
