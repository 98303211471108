import { Component, OnInit, HostListener } from '@angular/core';
declare var $, Swiper, window;
@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css'],
})
export class GalleryComponent implements OnInit {
  public numOfImagesLoaded: any = 0;
  public loading: any = false;
  public imagesArray: any = [
    {
      src: 'assets/images/gallery/sports/cricket.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/football.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/cricket.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/joy.png',
      type: 'politicians',
      alt: 'politicians',
    },

    {
      src: 'assets/images/gallery/sports/monument.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/pass.png',
      type: 'politicians',
      alt: 'politicians',
    },

    {
      src: 'assets/images/gallery/sports/skating.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/race.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/trip.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/small-team.png',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_1.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_2.JPG',
      type: 'politicians',
      alt: 'politicians',
    },

    {
      src: 'assets/images/ra_7.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_8.JPG',
      alt: 'politicians',
      type: 'politicians',
    },
    {
      src: 'assets/images/ra_9.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_10.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_11.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_12.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_13.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_14.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_15.jpg',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_16.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_17.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_18.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_19.JPG',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/ra_20.jpg',
      type: 'politicians',
      alt: 'politicians',
    },
    {
      src: 'assets/images/gallery/sports/brothers.png',
      type: 'politicians',
      alt: 'politicians',
    },

    {
      src: 'assets/images/gallery/life-at-psh/class.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/bakra-eid.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/august.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/group.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/group-1.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/primary-group.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/shadab-khan.png',
      type: 'celebrities',
      alt: 'diplomats',
    },

    {
      src: 'assets/images/gallery/life-at-psh/laugh.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/lunch.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/classroom.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/doctor.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/namaz.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/uniboys.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    // {
    //   src: 'assets/images/gallery/life-at-psh/working.png',
    //   type: 'celebrities',
    //   alt: 'diplomats',
    // },
    {
      src: 'assets/images/lap_1.JPG',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/life-at-psh/yellow.png',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/lap_2.JPG',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/lap_3.JPG',
      type: 'celebrities',
      alt: 'diplomats',
    },

    {
      src: 'assets/images/lap_5.JPG',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/lap_6.JPG',
      type: 'celebrities',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/lap_7.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },
    {
      src: 'assets/images/lap_8.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },
    {
      src: 'assets/images/lap_9.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },
    {
      src: 'assets/images/lap_10.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },
    {
      src: 'assets/images/lap_11.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },

    // {
    //   src: 'assets/images/lap_13.JPG',
    //   type: 'celebrities',
    //   alt: 'celebrities',
    // },
    // {
    //   src: 'assets/images/lap_14.JPG',
    //   type: 'celebrities',
    //   alt: 'celebrities',
    // },
    {
      src: 'assets/images/lap_15.JPG',
      type: 'celebrities',
      alt: 'celebrities',
    },
    {
      src: 'assets/images/gallery/development/comptetion.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/gallery/development/fire-brigade.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/gallery/development/painting.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },

    {
      src: 'assets/images/gallery/development/project.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/gallery/development/seminar.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },

    {
      src: 'assets/images/dt_04.JPG',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/gallery/development/planting.png',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/dt_05.JPG',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/dt_06.JPG',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/dt_07.JPG',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/dt_08.jpg',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/dt_09.jpg',
      type: 'sportsmen',
      alt: 'sportsmen',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp1.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp2.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp3.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp4.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp5.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp6.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    {
      src: 'assets/images/gallery/blood-camp/camp7.png',
      type: 'diplomats',
      alt: 'diplomats',
    },
    // {
    //   src: 'assets/images/bc_7.jpg',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },
    // {
    //   src: 'assets/images/bc_3.jpg',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },

    // {
    //   src: 'assets/images/bc_5.jpg',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },

    // {
    //   src: 'assets/images/bc_2.webp',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },
    // {
    //   src: 'assets/images/bc_1.webp',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },
    // {
    //   src: 'assets/images/bc_6.jpg',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },
    // {
    //   src: 'assets/images/bc_4.jpg',
    //   type: 'diplomats',
    //   alt: 'diplomats',
    // },
  ];
  public selectedDiv: any = 'all';
  constructor() {}

  ngOnInit(): void {
    for (let i = 0; i < this.imagesArray.length; i++) {
      if (!this.imagesArray[i].imagesPreloaded) {
        // if(window.pageYOffset >= $('#modSec-' + i)[0].offsetTop - (this.is_mobile ? 650 : 400)) {
        //   // Preload Images
        //   this.preLoadImages(i);
        //
        //   // Set preloaded true
        //   this.Modules[i].imagesPreloaded = true;
        // }

        // Preload Images
        this.preLoadImages(i);

        // Set preloaded true
        this.imagesArray[i].imagesPreloaded = true;
      }
    }

    if ($(window).width() <= 575) {
      $('.temp__swiper').addClass('swiper swiper-container');
      $('.pakTeamwraPPer').addClass('swiper-wrapper');
      $('.pakTeamwraPPer .single_cel').addClass('swiper-slide');
      var swiper = new Swiper('.subSwiper', {
        slidesPerView: 1,
        //  spaceBetween: 50,
        // centeredSlides: true,
        loop: true,
        pagination: {
          el: '.mpwRev-pagination',
          clickable: true,
        },
      });

      // denswiper
      // $(".den__swiper").addClass('swiper swiper-container');
      // $(".denTeamwraPPer").addClass('swiper-wrapper');
      // $(".denTeamwraPPer .single_cel").addClass('swiper-slide');
      // var swiper = new Swiper(".denSwiper", {
      //   slidesPerView: 1,
      //   //  spaceBetween: 50,
      //   // centeredSlides: true,
      //   loop: true,
      //   pagination: {
      //     el: ".denpag-pagination",
      //     clickable: true
      //   }
      // });

      // getinvol
      $('.stotyparent').addClass('swiper swiper-container');
      $('.organizationEvent').addClass('swiper-wrapper');
      $('.organizationEvent').removeClass('denTeamwraPPer');
      $('.organizationEvent .single_cel').addClass('swiper-slide');
      var swiper = new Swiper('.stotyparent', {
        slidesPerView: 1,
        spaceBetween: 20,
        // centeredSlides: true,
        loop: true,
        pagination: {
          el: '.denpag-pagination',
          clickable: true,
        },
      });

      // usa swiper
      $('.usa__swiper').addClass('swiper swiper-container');
      $('.usaWraper').addClass('swiper-wrapper');
      $('.usaWraper .single_cel').addClass('swiper-slide');
      var swiper = new Swiper('.usaswiper', {
        slidesPerView: 1,
        //  spaceBetween: 50,
        // centeredSlides: true,
        loop: true,
        pagination: {
          el: '.usa-pagination',
          clickable: true,
        },
      });

      // sportsman swiper
      $('.sportsman__swiper').addClass('swiper swiper-container');
      $('.sportsmanWraper').addClass('swiper-wrapper');
      $('.sportsmanWraper .single_cel').addClass('swiper-slide');
      var swiper = new Swiper('.sportsswiper', {
        slidesPerView: 1,
        //  spaceBetween: 50,
        // centeredSlides: true,
        loop: true,
        pagination: {
          el: '.sports-pagination',
          clickable: true,
        },
      });
    }
  }

  // ngAfterViewInit(): void {
  //   this.changeSwiper();
  // }

  // changeSwiper(){
  //   if($(window).width() < 767){
  //     $('.single_cel.show').addClass('swiper-slide');

  //     var storySlider = new Swiper(".cellsWrapper", {
  //       slidesPerView: 3,
  //       spaceBetween: 30,
  //       centeredSlides: false,

  //       pagination: {
  //         el: ".story.swiper-pagination",
  //       },
  //       breakpoints: {
  //         360: {
  //           slidesPerView: 1,
  //           spaceBetween: 20,
  //           centeredSlides: true,
  //         },
  //         575: {
  //           slidesPerView: 1,
  //           spaceBetween: 20,
  //         },
  //         767: {
  //           slidesPerView: 3,
  //           spaceBetween: 33,
  //           centeredSlides: false,
  //         },
  //         1024: {
  //           slidesPerView: 3,
  //           spaceBetween: 33,
  //         },
  //         1170: {
  //           slidesPerView: 3,
  //           spaceBetween: 30,
  //         },
  //       },
  //       navigation: {
  //         nextEl: ".story.swiper-button-next",
  //         prevEl: ".story.swiper-button-prev",
  //       },
  //   });

  //   }
  // }

  showDiv(divNumber) {
    this.selectedDiv = divNumber;
  }

  preLoadImages(j) {
    let module = this.imagesArray[j];
    module.image = new Image();
    module.image.src = this.imagesArray[j].src;

    let self = this;
    module.image.onload = () => {
      self.numOfImagesLoaded++;

      if (self.numOfImagesLoaded == self.imagesArray.length) {
        // Show Images
        for (let k = 0; k < self.imagesArray.length; k++) {
          setTimeout(() => {
            self.imagesArray[k].imgLoaded = true;
          }, 200 * (k + 2));
        }
      }
    };
  }
}
