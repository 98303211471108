import { Component, OnInit } from '@angular/core';
import { SEOService } from 'src/app/services/_services';

@Component({
  selector: 'app-annual-result-day',
  templateUrl: './annual-result-day.component.html',
  styleUrls: ['./annual-result-day.component.css', '../articles.component.css']
})
export class AnnualResultDayComponent implements OnInit {

  constructor(private seoService: SEOService,) { }

  ngOnInit(): void {
    this.setSEO_Tags("Annual Result Day of Orphans at Nova City School - PSH","Celebrate the academic achievements of Pakistan Sweet Home’s children on their annual result day, a moment of pride and joy.")
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
}
