import { Component, Input, OnInit, AfterViewInit, Output, EventEmitter } from '@angular/core';
import Swal from 'sweetalert2';
declare var Swiper;

@Component({
  selector: 'app-more-children',
  templateUrl: './more-children.component.html',
  styleUrls: ['./more-children.component.css']
})
export class MoreChildrenComponent implements OnInit, AfterViewInit {

  @Input() moreChildren;
  @Input() appClass;
  @Input() sponsorships;

  @Output() newChildEvent = new EventEmitter<object>();

  public emittedChildren = [];
  public swiper;

  constructor() { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    this.setSwiper();
    setTimeout(() => {
      this.checkIfChildAdded();
    }, 1);
  }

  checkIfChildAdded(){
    if(this.sponsorships){
      for (let i = 0; i < this.sponsorships.length; i++) {    
        const element = this.sponsorships[i];
        if(element.type == 'Child Sponsorship'){
          this.emittedChildren.push(element.id);
        }
      }
    }
  }

  addNewChild(child , el) {        
      this.newChildEvent.emit(child);
      this.emittedChildren.push(child.id);

      console.log(el);

  }

  removeFromEmittedChild(id){
      this.emittedChildren = this.emittedChildren.filter(function(item){
        return item !== id;
      })
  }

  setSwiper(){
    this.swiper = new Swiper(".moreChildrenSwiper", {
      slidesPerView: "auto",
      spaceBetween: this.appClass ? 23 : 27,
      navigation: {
        nextEl: ".children-next",
        prevEl: ".children-prev",
      },
      pagination: {
        el: ".children-pagination",
      },
      breakpoints: {
        992: {
          spaceBetween: this.appClass ? 23 : 27,
        },
        576: {
          spaceBetween: 20,
        },
        360 : {
          slidesPerView: this.appClass ? "auto" : 1,
          spaceBetween: this.appClass ? 7 : 20,
        },
        200 :{
          slidesPerView: 1,
        }
      }
    });
  }

}
