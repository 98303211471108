import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fileNameFromUrl'
})
export class FileNameFromUrlPipe implements PipeTransform {

  transform(url: String, ...args: unknown[]): unknown {
    var urlArr = url.split('/');
    return urlArr[urlArr.length - 1]
  }

}
