import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SEOService } from 'src/app/services/_services';

declare var $, Swiper, window;

@Component({
  selector: 'app-admission-eleven-class',
  templateUrl: './admission-eleven-class.component.html',
  styleUrls: ['./admission-eleven-class.component.css', '../admissions.component.css', '../sponsorship-program/sponsorship-program.component.css']
})
export class AdmissionElevenClassComponent implements OnInit {
  public nextPage : any = true;
  
  constructor(
    private activatedRoute         : ActivatedRoute,
    private router                 : Router,
    private seoService: SEOService,
  ) { 
    let url = this.activatedRoute.snapshot['_routerState'].url;
    this.routeCheck(url);

    localStorage.setItem('classApplying', 'elevenClass');
    localStorage.removeItem('admissionLink');
  }

  routeCheck(url){

    if(url == '/cadet-college-admission'){
      this.router.navigate(['/cadet-college-admission-8th-class'])
    } 
  }

  ngAfterViewInit(){
    var swiper = new Swiper(".mySwiper", {
      slidesPerView: 1,
      autoplay: {
        delay: 5000
      }
    });
  }
  setSEO_Tags(title: string, desc: string) {
    let self = this;
    setTimeout(function () {
      // Set Basic Meta Tags
      self.seoService.updateTitle(title);
      self.seoService.updateDescription(desc);

      // Set Og Meta tags
      self.seoService.updateOgTitle(title);
      self.seoService.updateOgDesc(desc);
      self.seoService.updateOgUrl();

      // Set Twitter Meta Tags
      self.seoService.updateTwitterTitle(title);
      self.seoService.updateTwitterDesc(desc);

      // Set Canonical Tag
      self.seoService.updateCanonicalUrl();


    }, 500);
  }
  ngOnInit(): void {

    $(".scroll-link").click(function (e) {
        
      e.preventDefault();
      var scrollTo = $(this).data("scroll-to");
      let headerHeight = $(".navbar").outerHeight();
      console.log(headerHeight);
      $('html, body').animate({
        scrollTop: $(scrollTo).offset().top - headerHeight
      }, 1000);
    });
    
    this.setSEO_Tags("Apply for Cadet College Admission - 11 class","Apply for Pakistan Sweet Home's cadet college admission-11 class, offering academic excellence for orphaned students.")
  }
  

}
