<section class="newsOuter">
  <section class="newsWrapper">
    <div class="singleNews">
      <p>The Result for 11th Class Cadet College is Out!</p>
      <a href="./assets/results/List-of-Shortlisted-Students-Fsc-2024.pdf" data-fancybox="result">Click here to
        view.</a>
    </div>
    <div class="singleNews singleNews2">
      <p>The Result for 11th Class Cadet College is Out!</p>
      <a href="./assets/results/List-of-Shortlisted-Students-Fsc-2024.pdf" data-fancybox="result">Click here to
        view.</a>
    </div>
    <div class="singleNews singleNews3">
      <p>The Result for 11th Class Cadet College is Out!</p>
      <a href="./assets/results/List-of-Shortlisted-Students-Fsc-2024.pdf" data-fancybox="result">Click here to
        view.</a>
    </div>
  </section>
</section>