import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dueDate'
})
export class DueDatePipe implements PipeTransform {

  transform(value: string, type : string, ...args: unknown[]): unknown {

    var payOn = new Date(value);
    var dateToday = new Date();

    var payOnTime = payOn.getTime() / (24 * 60 * 60 * 1000);
    var dateTodayTime = dateToday.getTime() / (24 * 60 * 60 * 1000);

    var typeString = type == 'due' ? 'Due in ' : 'Late By ';

    var returnString = (payOnTime - dateTodayTime).toFixed(0);
    returnString = type == 'due' ? returnString : (Number(returnString) * -1).toString();

    var dayString = returnString == '1' ? ' Day' : ' Days';

    return typeString + returnString + dayString;
  }

}
