import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, HelperService } from 'src/app/services/_services';

@Component({
  selector: 'app-dynamic-article',
  templateUrl: './dynamic-article.component.html',
  styleUrls: ['./dynamic-article.component.css']
})
export class DynamicArticleComponent implements OnInit {

  public loading = true
  public pageSlug
  public pageData
  public moreChildren;
  public isMobile = false;

  constructor(
    private helperService            : HelperService,
    private activatedRoute           : ActivatedRoute,
    public router                    : Router,
  ) {
    this.activatedRoute.params.subscribe(routeParams => {
      this.pageSlug = routeParams.id;      

      // Get BloPageg Info
      if(this.pageSlug){
        this.getArticle();        
      } else {
        this.loading = false;
      }
    });
  }

  ngOnInit(): void {
    if (window.innerWidth < 576) {
      this.isMobile = true;
    }
  }

  getArticle(){
    this.loading = true;
      let url = AppSettings.BACK_ENDPOINT + 'get-article-by-slug/' + this.pageSlug
      this.helperService.httpGetRequests(url).then(resp => {   
        this.pageData = resp.article
        if(!this.pageData){
          this.router.navigate(['/']);
        }
        this.getOrphans()
      })
      .catch(error => {
        this.loading = false;
      });
  }

  getOrphans() {
    let url = AppSettings.BACK_ENDPOINT + 'get-all-orphans'
    let formData = new FormData();
    formData.append('length', '6')
    this.helperService.httpPostRequests(url,formData).then(resp => {
      this.moreChildren = resp.orphans;
      setTimeout(() => {
        this.loading = false;   
      }, 3000);
    }).catch(error => {
      console.error("error: ");
      this.loading = false;   
    });

  }

}
