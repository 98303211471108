import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { HomepageComponent } from './newPages/homepage/homepage.component';
import { DashboardComponent } from './newPages/dashboard/dashboard.component';
import { SignInComponent } from './newPages/sign-in/sign-in.component';
import { SignUpComponent } from './newPages/sign-up/sign-up.component';
import { LogoutComponent } from './newPages/logout/logout.component';
import { ProfileComponent } from './newPages/profile/profile.component';
import { DonateComponent } from './newPages/donate/donate.component';
import { TestimonialsComponent } from './newPages/testimonials/testimonials.component';
import { AboutUsComponent } from './newPages/about-us/about-us.component';
import { ThanksComponent } from './newPages/thanks/thanks.component';
import { WhyUsComponent } from './newPages/why-us/why-us.component';
import { OurProjectsComponent } from './newPages/our-projects/our-projects.component';
import { AdmissionFormComponent } from './newPages/admission-form/admission-form.component';
import { AdmissionsComponent } from './newPages/admissions/admissions.component';
import { GetInvolvedComponent } from './newPages/get-involved/get-involved.component';
import { NewsComponent } from './newPages/news/news.component';
import { ContactUsComponent } from './newPages/contact-us/contact-us.component';
import { GalleryComponent } from './newPages/gallery/gallery.component';
import { ForgetComponent } from './newPages/forget/forget.component';
import { StudentDashboardComponent } from './newPages/student-dashboard/student-dashboard.component';
import { StudentApplicationComponent } from './newPages/student-application/student-application.component';
import { CreatePasswordComponent } from './newPages/create-password/create-password.component';
import { TestPageComponent } from './newPages/test-page/test-page.component';
import { SponsorChildComponent } from './newPages/sponsor-child/sponsor-child.component';
import { SponsorChildProfileComponent } from './newPages/sponsor-child-profile/sponsor-child-profile.component';
import { AddSponsorComponent } from './newPages/add-sponsor/add-sponsor.component';
import { SponsorshipThanksComponent } from './newPages/add-sponsor/sponsorship-thanks/sponsorship-thanks.component';
import { SponsorPageComponent } from './newPages/sponsor-page/sponsor-page.component';
import { InquiryComponent } from './newPages/inquiry/inquiry.component';
import { RegularClassesComponent } from './newPages/admissions/regular-classes/regular-classes.component';
import { DayScholarsComponent } from './newPages/admissions/day-scholars/day-scholars.component';
import { FaqComponent } from './newPages/faq/faq.component';
import { UpdatesComponent } from './newPages/updates/updates.component';
import { ArticlesComponent } from './newPages/articles/articles.component';
import { RegularClassesFormComponent } from './newPages/regular-classes-form/regular-classes-form.component';
import { RegularClassesReviewComponent } from './newPages/regular-classes-review/regular-classes-review.component';
import { DayScholarsFormComponent } from './newPages/day-scholars-form/day-scholars-form.component';
import { VolunteerComponent } from './newPages/volunteer/volunteer.component';
import { FundRaisingEventComponent } from './newPages/fund-raising-event/fund-raising-event.component';
import { DayScholarsReviewComponent } from './newPages/day-scholars-review/day-scholars-review.component';
import { FriendsCircleComponent } from './newPages/friends-circle/friends-circle.component';
import { ScholarshipProgramFormComponent } from './newPages/scholarship-program-form/scholarship-program-form.component';
import { ScholarshipProgramReviewComponent } from './newPages/scholarship-program-review/scholarship-program-review.component';
import { RequestTourComponent } from './newPages/request-tour/request-tour.component';
import { LegalComponent } from './newPages/legal/legal.component';
import { TermsComponent } from './newPages/terms/terms.component';
import { BankDetailsComponent } from './newPages/bank-details/bank-details.component';
import { DonationJazzcashComponent } from './newPages/donation-jazzcash/donation-jazzcash.component';
import { DynamicArticleComponent } from './newPages/dynamic-article/dynamic-article.component';
import { DynamicBlogComponent } from './newPages/dynamic-blog/dynamic-blog.component';
import { BlogComponent } from './newPages/blog/blog.component';
import { WriterComponent } from './newPages/writer/writer.component';
import { DonateQurbaniComponent } from './newPages/donate-qurbani/donate-qurbani.component';
import { GiveCharityInMuharramComponent } from './newPages/give-charity-in-muharram/give-charity-in-muharram.component';
import { SupportOurNeedsComponent } from './newPages/get-involved/support-our-needs/support-our-needs.component';
import { SupportNeedsDescriptionComponent } from './newPages/get-involved/support-our-needs/support-needs-description/support-needs-description.component';
import { PrivacyPolicyComponent } from './newPages/privacy-policy/privacy-policy.component';
import { NotFoundPageComponent } from './newPages/not-found-page/not-found-page.component';

const routes: Routes = [
  { path: '', component: HomepageComponent },
  { path: 'facebook-lp-7578466214', component: HomepageComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'dashboard/settings', component: DashboardComponent },
  { path: 'sign-in', component: SignInComponent },
  { path: 'sign-up', component: SignUpComponent },
  { path: 'sign-in/applicant', component: SignInComponent },
  { path: 'sign-up/applicant', component: SignUpComponent },
  { path: 'logout', component: LogoutComponent },
  { path: 'profile/:profileUrl', component: ProfileComponent },
  { path: 'thanks', component: ThanksComponent },
  { path: 'about-us', component: AboutUsComponent },
  { path: 'testimonials', component: TestimonialsComponent },
  { path: 'why-us', component: WhyUsComponent },
  { path: 'our-projects', component: OurProjectsComponent },
  { path: 'cadet-college-admission', component: AdmissionsComponent },
  { path: 'cadet-college-admission-8th-class', component: AdmissionsComponent },
  {
    path: 'cadet-college-admission-11th-class',
    component: AdmissionsComponent,
  },
  { path: 'get-involved', component: GetInvolvedComponent },
  { path: 'get-involved/volunteer', component: VolunteerComponent },
  { path: 'get-involved/request-tour', component: RequestTourComponent },
  {
    path: 'get-involved/support-our-needs',
    component: SupportOurNeedsComponent,
  },
  {
    path: 'get-involved/support-our-needs/:slug',
    component: SupportNeedsDescriptionComponent,
  },
  // { path: 'get-involved/host-event',  component: FundRaisingEventComponent },
  { path: 'special-friends', component: FriendsCircleComponent },
  { path: 'admission/signup', component: AdmissionFormComponent },
  { path: 'admission/login', component: AdmissionFormComponent },
  { path: 'admission/forgot-password', component: AdmissionFormComponent },
  { path: 'admission/fill-form', component: AdmissionFormComponent },
  { path: 'admission/fill-form/:id', component: AdmissionFormComponent },
  {
    path: 'admission/review-information/:id',
    component: AdmissionFormComponent,
  },
  { path: 'admission/view-form/:id', component: AdmissionFormComponent },
  { path: 'news', component: NewsComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'gallery', component: GalleryComponent },
  { path: 'donate', component: DonateComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  // { path: 'donate-qurbani', component: DonateQurbaniComponent },
  // { path: 'donate-new',           component: DonateComponent },
  {
    path: 'give-charity-in-rabi-awwal-2024',
    component: GiveCharityInMuharramComponent,
  },
  { path: 'forgot-password', component: ForgetComponent },
  { path: 'admission/student-dashboard', component: StudentDashboardComponent },
  {
    path: 'admission/student-application',
    component: StudentApplicationComponent,
  },
  { path: 'reset-password', component: CreatePasswordComponent },
  { path: 'donate/:referralID', component: DonateComponent },
  { path: 'test-page', component: TestPageComponent },
  { path: 'orphan-profiles', component: SponsorChildComponent },
  { path: 'orphan-profiles/:id', component: SponsorChildProfileComponent },
  { path: 'scholarship-program', component: AdmissionsComponent },
  { path: 'add-sponsorship', component: AddSponsorComponent },
  { path: 'add-membership', component: AddSponsorComponent },
  { path: 'thankyou-sponsor', component: SponsorshipThanksComponent },
  { path: 'thankyou-member', component: SponsorshipThanksComponent },
  { path: 'sponsorship-program', component: SponsorPageComponent },
  { path: 'inquiry', component: InquiryComponent },
  { path: 'boarding-school', component: RegularClassesComponent },
  { path: 'education-sponsorship', component: DayScholarsComponent },
  { path: 'faq', component: FaqComponent },
  { path: 'updates', component: UpdatesComponent },
  { path: 'updates/:id', component: ArticlesComponent },
  { path: 'article/:id', component: DynamicArticleComponent },
  { path: 'blog/:id', component: DynamicBlogComponent },
  { path: 'writer/:slug', component: WriterComponent },
  { path: 'blog/:id/:slug', component: DynamicBlogComponent },
  { path: 'boarding-school/apply', component: RegularClassesFormComponent },
  { path: 'boarding-school/apply/:id', component: RegularClassesFormComponent },
  {
    path: 'boarding-school/review/:id',
    component: RegularClassesReviewComponent,
  },
  { path: 'education-sponsorship/apply', component: DayScholarsFormComponent },
  {
    path: 'education-sponsorship/apply/:id',
    component: DayScholarsFormComponent,
  },
  {
    path: 'education-sponsorship/review/:id',
    component: DayScholarsReviewComponent,
  },
  {
    path: 'scholarship-program/apply',
    component: ScholarshipProgramFormComponent,
  },
  {
    path: 'scholarship-program/apply/:id',
    component: ScholarshipProgramFormComponent,
  },
  { path: 'blog', component: BlogComponent },
  {
    path: 'scholarship-program/review/:id',
    component: ScholarshipProgramReviewComponent,
  },
  { path: 'legal', component: LegalComponent },
  { path: 'terms', component: TermsComponent },
  { path: 'bank-details', component: BankDetailsComponent },
  { path: 'donation-jazzcash', component: DonationJazzcashComponent },
  { path: '404', component: NotFoundPageComponent },
  { path: '**', redirectTo: '/404' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
