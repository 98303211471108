import { getLocaleFirstDayOfWeek } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, AuthenticationService, COUNTRYLIST, HelperService } from 'src/app/services/_services';
import Swal from 'sweetalert2';

declare var $;

@Component({
  selector: 'app-admission-details',
  templateUrl: './admission-details.component.html',
  styleUrls: ['./admission-details.component.css']
})
export class AdmissionDetailsComponent implements OnInit, AfterViewInit {

  @ViewChild ('nameInput') nameInput;

  public candidateInfo                  : any = {
                                                  "name"                      : '',
                                                  "cnic"                      : null,
                                                  "dob"                       : '',
                                                  "gender"                    : null,
                                                  "whatsapp_number"           : null,
                                                  "health_medical_issue"      : '0',
                                                  "health_issue_name"         : '',
                                                  "cnic_img"                  : null,
                                                  "father_name"               : '',
                                                  "candidate_photo"           : '',
                                                  "father_death_certificate"  : ''
                                                }
  public guardianInfo                   : any = {
                                                  "guardian_name"             : '',
                                                  "guardian_relation"         : null,
                                                  "guardian_cnic"             : '',
                                                  "guardian_occupation"       : '',
                                                  "guardian_phone_number"     : null,
                                                  "guardian_email"            : '',
                                                  "guardian_address"          : '',
                                                  "guardianship_img"          : ''
                                                }
  public gradeInfo                       : any = {
                                                  "class"                      : null,
                                                  "grade"                      : null,
                                                  "previous_academic_record"   : '',
                                                 }

  public classElevenFields               : any = {
                                                   'address'                   : '',
                                                   'exam_board'                : '',
                                                   'exam_year'                 : '',
                                                   'previous_marks'            : '',
                                                   'program_applying'          : null,
                                                   'is_hafiz_e_quran'          : '0',
                                                   'is_son_of_shaheed'         : '0',
                                                 }

  public class_applying                  : any = 'preCadet';                                                

  public genderOptions                  : any = ['Male', 'Female']                                          
  public religionOptions                : any = ['Muslim','Non-Muslim']                                          
  public relationOptions                : any = ['Brother','Sister','Uncle','Aunty','Mother']             
  public domicileOptions                : any = ['Islamabad Capital Territory','Punjab','Sindh','Khyber Pakhtunkhwa','Balochistan','Azad Jammu and Kashmir','Gilgit-Baltistan']             
  public classOptions                   : any = ['7th Class', '8th Class']                       
  public gradeOptions                   : any = ['Result Awaiting','A+','A-','A','B+','B','B-','C+','C','C-','D','F']             
  public programmeOptions               : any = ['Pre Medical', 'Pre Engineering', 'ICS']             
  
  public countryOptions                 : any ;
  public flagOptions                    : any ;
  public selectedFlag                   : any = 'PK';
  public selectedCountry                : any = "Pakistan";                             

  public loading                        : any = true;   
  public draftLoading                   : any = false;   
  
  public editPage                       : any = false;   
  public admissionInfo                  : any = false;   
  public formId                         : any = false;   

  public authSub                        : any = false;   
  public currentUser                    : any = false;   

  public draftEditing                   : any = false;   

  public elligible                      : any = true;                                                
  public unelligible                    : any = false;                                                
  public ageError                       : any = 'Please Enter Date of Birth';  

  public preferred_test_center          : any;
  public centerOptions                  : any;

  public applications                   : any;

  public coutryCode                     : any;

  public dateOptions                        : any = [];
  public monthOptions                       : any = [];
  public yearOptions                        : any = [];

  public selectedDate                       : any;
  public selectedMonth                      : any;
  public selectedYear                       : any;

  constructor(
    private http                   : HttpClient,
    private router                 : Router,
    private helperService          : HelperService,
    private activatedRoute         : ActivatedRoute,
    private authenticationService  : AuthenticationService,
  ) { 


    if(localStorage.getItem('classApplying')){
      this.class_applying = localStorage.getItem('classApplying');
      if(this.class_applying == 'elevenClass'){
        this.gradeInfo.class = '10th Class';
      }
    }

    this.http.get('https://trial.mobiscroll.com/content/countries.json').subscribe((resp: any) => {
      const countries = [];
      const countriesImg = [];
      for (let i = 0; i < resp.length; ++i) {
          const country = resp[i];
          countries.push(country.text);
          countriesImg.push(country.value);
        }
        this.countryOptions = resp;
        this.flagOptions = countriesImg;
        this.setCountryCode();
      
  });

  if (typeof localStorage != "undefined") {
    this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
      if (user) {
          this.currentUser = user;                    
      } else {
        this.currentUser = null;
      }
    });
  }

  let url = activatedRoute.snapshot['_routerState'].url;
  this.getID(url);

  }

  getAdmissions(){      
    this.loading = true;
    let data = new FormData();
    data.append("action", "get-user-admissions");
    data.append("user_id", this.currentUser.ID);
    
    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';

    this.helperService.httpPostRequests(url, data).then(resp => {

      if (resp.status != 'ERROR') {
        // Set User in localStorage         
        this.applications = resp.data;          
        console.log('this.applications::', this.applications);
        
        for (let i = 0; i < this.applications.length; i++) {
          const element = this.applications[i];
          if(element.application_completed == '1'){
            
            var nextDate = new Date(new Date(element.created_at).setFullYear(new Date().getFullYear() + 1));
            var today =  new Date();
            //  if(today < nextDate)
            //  window.location.replace('admission/student-application');            
          }
        }
        
      } else {
        
      }
      this.loading = false;

    }).catch(error => {
        console.error("error: ",error);
    });
}

  ngAfterViewInit(): void {
    this.nameInput.nativeElement.focus()
  }

  getID(url){
    url = url.split('fill-form/');

    if(url.length > 1){
        if(!url[url.length - 1].includes('admin')){
          console.log('in if');
          if(localStorage.getItem('adminRole')){
            localStorage.removeItem('adminRole');
            this.formId = url[url.length - 1];
            this.editPage = true;
            this.getApplication(this.formId);
        } else {
          this.router.navigate(['/admission/login']);
        }
      } else {
        console.log('in else');
        let temp =  url[url.length - 1];

        localStorage.setItem('adminRole', '2');
        localStorage.setItem('view-form', '2');
        window.location.replace('admission/fill-form/' + temp.split('?')[0]);
      }
      

      
    } else {
      if(!localStorage.getItem('currentUser')){
        this.router.navigate(['/admission/signup']);
      }
      this.getAdmissions();
      this.checkDraft();
    }   
  }

  getApplication(appId){
    this.loading = true;

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';    

    let data = new FormData();
    data.append("id",appId);    
    data.append("action", "preview-admission");    

    this.helperService.httpPostRequests(url, data).then(resp => {          
    
      if(resp.status == "OK"){
          this.admissionInfo = resp.data;

          if(this.admissionInfo){                   
            this.setFormData(this.admissionInfo);
          }

      } else {
        
      }
    
      this.loading = false;          

    }).catch(error => {
        console.error("error: ",error);
        this.loading = false;
    });


  }

  ngOnInit(): void {    

    this.setDateOptions();
    this.setYearOptions();

    if(localStorage.getItem('application_draft')){
      this.formId = JSON.parse(localStorage.getItem('application_draft')).id;
      this.setFormData(JSON.parse(localStorage.getItem('application_draft')));
      this.draftEditing = true;
      localStorage.removeItem('application_draft')
    }

    $("input[type='text']").keyup(function(){      
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })
    $("input[type='number']").keyup(function(){
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })
    $("input[type='email']").keyup(function(){
      if($(this).hasClass('invalid')){
        $(this).removeClass('invalid');
      }
    })

    this.getTestCenters();

  }

  getTestCenters(){
    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';  

    let data = new FormData();

    data.append('action', 'get-test-cities');

    this.helperService.httpPostRequests(url, data).then(resp => {          
    
      if(resp.status == 'Success'){
        console.log('cities response::', resp);
        
        this.centerOptions = resp.data.sort();
        this.centerOptions.sort((a, b) => a.name.localeCompare(b.name));
      }
      
      this.loading = false;

    }).catch(error => {
        console.error("error: ",error);
        this.loading = false;
    });
  }

  submitApplication(){
    
    if(!this.editPage){
      console.log('this.classElevenFields.program_applying', this.classElevenFields.program_applying)
      if($('.admissionForm').hasClass('ng-valid') && this.checkDOB()){
            if(!this.draftEditing){
              this.postApplication();
            } else {
              this.editApplication('review', 1);
            }
      } else {
        this.checkTextInput();
        this.checkSelectInput();
        this.checkFileInput();
        this.checkDOB();
        this.focusFirstInvalid();
      }
    } else {
        this.editApplication('review', 1);
    }
  }

  checkDOB(){
    if(!this.selectedMonth || !this.selectedDate || !this.selectedYear){
      $('.doubleInput').addClass('invalid');
      this.ageError = 'Please Enter Date of Birth';
      return false;
    } else if(this.selectedMonth && this.selectedDate && this.selectedYear){

      this.candidateInfo.dob = new Date(this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate );

      var compareDate = new Date();
      var dob = new Date(this.candidateInfo.dob);
      var age;
      var ageMonth = 0;

      var month = compareDate.getMonth();

      if(dob.getMonth() > month){
        age = compareDate.getFullYear() - dob.getFullYear() - 1;     
        
        console.log('age::', age);
        

      } else if(dob.getMonth() <= month) {
        age = compareDate.getFullYear() - dob.getFullYear();
        ageMonth = 12 - (month - dob.getMonth());    
        console.log('age::', age + '/' + ageMonth);            
      }
      
      if(this.class_applying == 'preCadet'){      
        if(age < 12 || age > 14){
          if(age == 11){
            if(ageMonth >= 10){
              this.elligible = true;
            } else {
              this.elligible = false;
              this.ageError = '12,13,14 years old orphans can apply for cadet college';
            }
          } else {
            this.elligible = false;
            this.ageError = '12,13,14 years old orphans can apply for cadet college';
          }       
        } else {
          this.elligible = true;
        }
      } else if(this.class_applying == 'nursaryToSeven'){
        if(age >= 12){
          this.elligible = false;
          this.ageError = 'Orphans under 12 years old can apply for Nursery to 7 class';
        } else {
          this.elligible = true;
        }
      } else if(this.class_applying == 'elevenClass'){
        if(age > 16){
          this.elligible = false;
          this.ageError = 'Orphans under 16 and a half years old can apply for Class 11';
        } else {
          this.elligible = true;
        }
      }
      
      if(this.elligible){
        return true
      } else {
        console.log('hereeeee');
        
        $('.doubleInput').addClass('invalid');
        return false;
      }
      
    }
  }

  checkTextInput(){
    $("input[type='text']").each(function(){
      if($(this).attr('name') != 'healthIssue'){
        if(!$(this).val()){
          $(this).addClass('invalid');
        }
      }
    })
    $("input[type='number']").each(function(){
      if(!$(this).val()){
        $(this).addClass('invalid');
      }
    })
  }

  checkSelectInput(){
    if(!this.candidateInfo.gender){
      $('#gender').addClass('invalid');
    }
    if(!this.gradeInfo.grade){
      $('#grade').addClass('invalid');
    }
    if(!this.gradeInfo.class){
      $('#class').addClass('invalid');
    }
    if(!this.preferred_test_center){
      $('#testCentre').addClass('invalid');
    }
    if(this.class_applying == 'elevenClass'){
      if(!this.classElevenFields.program_applying){
        $('#programmeApplying').addClass('invalid');
      }
    }
  }

  checkFileInput(){
    $("input[type='file']").each(function(){
      if(!$(this).val()){
        $(this).addClass('invalid');
      }
    })
  }

  validateEmail(){
    if (this.guardianInfo.guardian_email.includes('@') && this.guardianInfo.guardian_email.includes('.'))
    {
      return true;
    } else {
      $('#email').addClass('invalid');
      return false;
    }
  }

  focusFirstInvalid(){
    $('.admissionForm .invalid').each(function(){
      $('html, .formFields').animate({
        scrollTop: $(this).offset().top
    }, 500);
    $(this).focus();
      return false;
    });
  }

  postApplication(){
    console.log(
      " IME THERE"
    );
    
    this.loading = true;

    this.setPhoneCode();

    if(this.selectedMonth && this.selectedDate && this.selectedYear){
      this.candidateInfo.dob = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
    }

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';    

    let data = new FormData();
    data.append("action", "admission-form");

    data.append("user_id", this.currentUser.ID);
    data.append("user_email", this.currentUser.email);
    data.append("class_applying", this.class_applying);
    data.append("name", this.candidateInfo.name);
    data.append("cnic", this.candidateInfo.cnic);
    data.append("cnic_img", this.candidateInfo.cnic_img);
    data.append("father_name", this.candidateInfo.father_name);
    data.append("dob", this.candidateInfo.dob);
    data.append("gender", this.candidateInfo.gender);
    data.append("health_medical_issue", this.candidateInfo.health_medical_issue);
    this.candidateInfo.health_medical_issue == '1' && data.append("health_issue_name", this.candidateInfo.health_issue_name);
    data.append("father_death_certificate", this.candidateInfo.father_death_certificate);
    data.append("whatsapp_number", this.candidateInfo.whatsapp_number);
    data.append("candidate_photo", this.candidateInfo.candidate_photo);
    data.append("previous_grade", this.gradeInfo.grade);
    data.append("previous_class", this.gradeInfo.class);
    data.append("previous_academic_record", this.gradeInfo.previous_academic_record);
    data.append("test_city_id", this.preferred_test_center);
    data.append("application_completed", '1');

    if(this.class_applying == 'elevenClass' ){
      data.append("address", this.classElevenFields.address);
      data.append("exam_board", this.classElevenFields.exam_board);
      data.append("exam_year", this.classElevenFields.exam_year);
      data.append("previous_marks", this.classElevenFields.previous_marks);
      data.append("program_applying", this.classElevenFields.program_applying);
      data.append("is_hafiz_e_quran", this.classElevenFields.is_hafiz_e_quran);
      data.append("is_son_of_shaheed", this.classElevenFields.is_son_of_shaheed);
    }

    this.helperService.httpPostRequests(url, data).then(resp => {
        
      console.log(resp);
    
      if(resp.status == "OK"){
        this.router.navigate(['/admission/review-information/' + resp.data.id]);
        
      } else if(resp?.message?.length) {
        Swal.fire('Error', resp.message[0], 'error');
      }
    
      this.loading = false;          

    }).catch(error => {
      Swal.fire('Error', 'Something went wrong, Check your internet connection and try again', 'error');
        console.error("error: ",error);
        this.loading = false;
    });


  }

  saveFile(event, fileName){
    
    console.log(" IM NEAR");
    
    let file = event.target.files[0];
    let fileSize = file.size / 1024 / 1024;

    if(fileSize > 2){
      Swal.fire('File Size Too Large', 'File Size Must Be Less Than 2MB', 'error');
    } else {
      if(fileName == 'cnicFile'){
        this.candidateInfo.cnic_img = file;
      } else if(fileName == 'deathcertificate'){
        this.candidateInfo.father_death_certificate = file;
      } else if(fileName == 'candidatePhoto'){
        this.candidateInfo.candidate_photo = file;
      } else if(fileName == 'academicRecord'){
        this.gradeInfo.previous_academic_record = file;
      }
    }
  }

  removeInvalid(e){
    if($(e).hasClass('invalid')){
      $(e).removeClass('invalid');
    }
    
  }

  setFormData(data){
    this.loading = true;
    var countryCode;

    if(data.whatsapp_number){
      countryCode = data.whatsapp_number.split(' ')[0].split('+')[1];
    }

    if(countryCode && countryCode.length < 3){
      this.selectedFlag = COUNTRYLIST.COUNTRY_AGAINST_CODE[countryCode]['isoCode2'];
      this.coutryCode = '+' + countryCode;
    } else {
      countryCode = '92';
      this.selectedFlag = COUNTRYLIST.COUNTRY_AGAINST_CODE[countryCode]['isoCode2'];
      this.coutryCode = '+' + countryCode;
    }
    
    this.class_applying = data.class_applying;

    this.candidateInfo.name = data.name;
    this.candidateInfo.cnic = data.cnic;
    this.candidateInfo.cnic_img = data.cnic_img;
    this.candidateInfo.father_name = data.father_name;
    this.candidateInfo.father_cnic = data.father_cnic;
    this.candidateInfo.father_death_certificate = data.father_death_certificate;
    this.candidateInfo.religion = data.religion;

    this.candidateInfo.dob = data.dob;

    if(data.dob){
      var date = data.dob.split('-');

      this.selectedYear = date[0];
      this.selectedMonth = date[1];
      this.selectedDate = date[2];

    }

    this.candidateInfo.domicile = data.domicile;
    this.candidateInfo.gender = data.gender;
    this.candidateInfo.health_medical_issue = data.health_medical_issue;
    this.candidateInfo.health_issue_name = data.health_issue_name;
    this.candidateInfo.candidate_photo = data.candidate_photo;
    this.guardianInfo.guardian_name = data.guardian_name;
    this.guardianInfo.guardian_relation = data.guardian_relation;
    this.guardianInfo.guardian_cnic = data.guardian_cnic;
    this.guardianInfo.candidate_photo = data.candidate_photo;
    this.guardianInfo.guardian_occupation = data.guardian_occupation;
    this.guardianInfo.guardian_phone_number = data.guardian_phone_number;
    this.guardianInfo.guardian_email = data.guardian_email;
    this.guardianInfo.guardian_address = data.guardian_address;
    this.gradeInfo.previous_academic_record = data.previous_academic_record;
    this.gradeInfo.grade = data.previous_grade;
    this.gradeInfo.class = data.previous_class;
    this.preferred_test_center = data.test_city_id;
    if(data.whatsapp_number && data.whatsapp_number.split(' ')[1]){
      this.candidateInfo.whatsapp_number = data.whatsapp_number.split(' ')[1];
    } else if(data.whatsapp_number) {
      this.candidateInfo.whatsapp_number = data.whatsapp_number.split(countryCode)[1];
    }

    if(data.class_applying == "elevenClass" ){
      this.classElevenFields.address = data.address;
      this.classElevenFields.exam_board = data.exam_board;
      this.classElevenFields.exam_year = data.exam_year;
      this.classElevenFields.previous_marks = data.previous_marks;
      this.classElevenFields.program_applying = data.program_applying;
      this.classElevenFields.is_hafiz_e_quran = data.is_hafiz_e_quran;
      this.classElevenFields.is_son_of_shaheed = data.is_son_of_shaheed;
    } 
    this.class_applying = data.class_applying;

    this.loading = false;
    
  }

  editApplication(redirectPage, markComplete){
    this.loading = true;

    this.setPhoneCode();

    if(this.selectedMonth && this.selectedDate && this.selectedYear){
      this.candidateInfo.dob = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
    }

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';    

    let data = new FormData();
    data.append("action", "update-admission-form");
    data.append("id", this.formId);

    this.currentUser?.ID && data.append("user_id", this.currentUser.ID);
    this.class_applying && data.append("class_applying", this.class_applying);
    this.candidateInfo.name && data.append("name", this.candidateInfo.name);
    this.candidateInfo.cnic && data.append("cnic", this.candidateInfo.cnic);
    this.candidateInfo.cnic_img?.name && data.append("cnic_img", this.candidateInfo.cnic_img);
    this.candidateInfo.father_name && data.append("father_name", this.candidateInfo.father_name);
    this.candidateInfo.father_cnic && data.append("father_cnic", this.candidateInfo.father_cnic);
    this.candidateInfo.religion && data.append("religion", this.candidateInfo.religion);
    this.candidateInfo.dob && data.append("dob", this.candidateInfo.dob);
    this.candidateInfo.domicile && data.append("domicile", this.candidateInfo.domicile);
    this.candidateInfo.gender && data.append("gender", this.candidateInfo.gender);
    this.candidateInfo.health_medical_issue && data.append("health_medical_issue", this.candidateInfo.health_medical_issue);
    this.candidateInfo.health_medical_issue == '1' && data.append("health_issue_name", this.candidateInfo.health_issue_name);
    this.candidateInfo.father_death_certificate?.name && data.append("father_death_certificate", this.candidateInfo.father_death_certificate);
    
    this.guardianInfo.guardian_name && data.append("guardian_name", this.guardianInfo.guardian_name);
    this.guardianInfo.guardian_relation && data.append("guardian_relation", this.guardianInfo.guardian_relation);
    this.guardianInfo.guardian_cnic && data.append("guardian_cnic", this.guardianInfo.guardian_cnic);
    this.guardianInfo.guardian_occupation && data.append("guardian_occupation", this.guardianInfo.guardian_occupation);
    this.guardianInfo.guardian_phone_number && data.append("guardian_phone_number", this.guardianInfo.guardian_phone_number);
    this.guardianInfo.guardian_email && data.append("guardian_email", this.guardianInfo.guardian_email);
    this.guardianInfo.guardian_address && data.append("guardian_address", this.guardianInfo.guardian_address);
    this.selectedCountry && data.append("guardian_country", this.selectedCountry);
    this.candidateInfo.candidate_photo?.name && data.append("candidate_photo", this.candidateInfo.candidate_photo);
    
    this.gradeInfo.grade && data.append("previous_grade", this.gradeInfo.grade);
    this.gradeInfo.class && data.append("previous_class", this.gradeInfo.class);
    this.gradeInfo.previous_academic_record?.name && data.append("previous_academic_record", this.gradeInfo.previous_academic_record);

    this.candidateInfo.whatsapp_number && data.append("whatsapp_number", this.candidateInfo.whatsapp_number);
    this.preferred_test_center && data.append("test_city_id", this.preferred_test_center);

    data.append("application_completed", markComplete);

    this.classElevenFields.address && data.append("address", this.classElevenFields.address);
    this.classElevenFields.exam_board && data.append("exam_board", this.classElevenFields.exam_board);
    this.classElevenFields.exam_year && data.append("exam_year", this.classElevenFields.exam_year);
    this.classElevenFields.previous_marks && data.append("previous_marks", this.classElevenFields.previous_marks);
    this.classElevenFields.program_applying && data.append("program_applying", this.classElevenFields.program_applying);
    this.classElevenFields.is_hafiz_e_quran && data.append("is_hafiz_e_quran", this.classElevenFields.is_hafiz_e_quran);
    this.classElevenFields.is_son_of_shaheed && data.append("is_son_of_shaheed", this.classElevenFields.is_son_of_shaheed);

    this.helperService.httpPostRequests(url, data).then(resp => {
        
      console.log(resp);
    
      if(resp.status == "OK"){
        if(redirectPage == 'review'){
          if(localStorage.getItem('view-form')){
            localStorage.removeItem('view-form');
            Swal.fire('Success', 'Information Updated Successfully!', 'success').then(function(){
              window.location.replace('');
            });
            
          } else {
            this.router.navigate(['/admission/review-information/' + resp.data.id]);
          }          
        } else {
          this.router.navigate(['/admission/student-application']);
        }
        
      } else if(resp?.message?.length) {
        Swal.fire('Error', resp.message[0], 'error');
      }
    
      this.loading = false;          

    }).catch(error => {
      Swal.fire('Error', 'Something went wrong, Check your internet connection and try again', 'error');
        console.error("error: ",error);
        this.loading = false;
    });
    
  }

  maskCNIC(e){
    var value = $(e).val();    

    if(value.replaceAll('-','').length == 5 || value.replaceAll('-','').length == 12){
      $(e).val(value + '-')
    }

  }

  saveDraft(){
    console.log(" IM HERE");
    
    if(this.draftEditing){
      this.editApplication('portal', 0);
    } else {
      this.loading = true;

      if(this.candidateInfo.whatsapp_number){
        this.setPhoneCode();
      }

      if(this.selectedMonth && this.selectedDate && this.selectedYear){
        this.candidateInfo.dob = this.selectedYear + '-' + this.selectedMonth + '-' + this.selectedDate;
      }

    let url  = AppSettings.API_ENDPOINT + 'admissions/admission.php';    

    let data = new FormData();
    data.append("action", "admission-form-optional");

    data.append("user_id", this.currentUser.ID);
    data.append("class_applying", this.class_applying);

    this.candidateInfo.name && data.append("name", this.candidateInfo.name);
    this.candidateInfo.cnic && data.append("cnic", this.candidateInfo.cnic);
    this.candidateInfo.cnic_img && data.append("cnic_img", this.candidateInfo.cnic_img);
    this.candidateInfo.father_name && data.append("father_name", this.candidateInfo.father_name);
    this.candidateInfo.father_cnic && data.append("father_cnic", this.candidateInfo.father_cnic);
    this.candidateInfo.religion && data.append("religion", this.candidateInfo.religion);
    this.candidateInfo.dob && data.append("dob", this.candidateInfo.dob);
    this.candidateInfo.domicile && data.append("domicile", this.candidateInfo.domicile);
    this.candidateInfo.gender && data.append("gender", this.candidateInfo.gender);
    data.append("health_medical_issue", this.candidateInfo.health_medical_issue);
    this.candidateInfo.health_medical_issue == '1' && data.append("health_issue_name", this.candidateInfo.health_issue_name);
    this.candidateInfo.father_death_certificate && data.append("father_death_certificate", this.candidateInfo.father_death_certificate);
    
    this.guardianInfo.guardian_name && data.append("guardian_name", this.guardianInfo.guardian_name);
    this.guardianInfo.guardian_relation && data.append("guardian_relation", this.guardianInfo.guardian_relation);
    this.guardianInfo.guardian_cnic && data.append("guardian_cnic", this.guardianInfo.guardian_cnic);
    this.guardianInfo.guardian_occupation && data.append("guardian_occupation", this.guardianInfo.guardian_occupation);
    this.guardianInfo.guardian_phone_number && data.append("guardian_phone_number", this.guardianInfo.guardian_phone_number);
    this.guardianInfo.guardian_email && data.append("guardian_email", this.guardianInfo.guardian_email);
    this.guardianInfo.guardian_address && data.append("guardian_address", this.guardianInfo.guardian_address);
    this.selectedFlag && data.append("guardian_country", this.selectedFlag);
    this.candidateInfo.candidate_photo && data.append("candidate_photo", this.candidateInfo.candidate_photo);
    
    this.gradeInfo.grade && // data.append("grade", this.gradeInfo.grade);
    this.gradeInfo.grade && data.append("previous_grade", this.gradeInfo.grade);
    this.gradeInfo.class && data.append("previous_class", this.gradeInfo.class);
    this.gradeInfo.previous_academic_record && data.append("previous_academic_record", this.gradeInfo.previous_academic_record);

    this.candidateInfo.whatsapp_number && data.append("whatsapp_number", this.candidateInfo.whatsapp_number);
    this.preferred_test_center && data.append("test_city_id", this.preferred_test_center);

    data.append("application_completed", '0');

    this.classElevenFields.address && data.append("address", this.classElevenFields.address);
    this.classElevenFields.exam_board && data.append("exam_board", this.classElevenFields.exam_board);
    this.classElevenFields.exam_year && data.append("exam_year", this.classElevenFields.exam_year);
    this.classElevenFields.previous_marks && data.append("previous_marks", this.classElevenFields.previous_marks);
    this.classElevenFields.program_applying && data.append("program_applying", this.classElevenFields.program_applying);
    this.classElevenFields.is_hafiz_e_quran && data.append("is_hafiz_e_quran", this.classElevenFields.is_hafiz_e_quran);
    this.classElevenFields.is_son_of_shaheed && data.append("is_son_of_shaheed", this.classElevenFields.is_son_of_shaheed);

    this.helperService.httpPostRequests(url, data).then(resp => {
        
      console.log(resp);
    
      if(resp.status == "OK"){
        this.router.navigate(['/admission/student-application']);
        
      } else if(resp?.message?.length) {
        Swal.fire('Error', resp.message[0], 'error');
      }
    
      this.loading = true;        

    }).catch(error => {
      Swal.fire('Error', 'Something went wrong, Check your internet connection and try again', 'error');
        console.error("error: ",error);
        this.draftLoading = false;
        this.loading = false;        
    });
    }
    
  }

  checkDraft(){
    if(localStorage.getItem('candidate_info')){
      this.candidateInfo = JSON.parse(localStorage.getItem('candidate_info'));
      localStorage.removeItem('candidate_info');
    }
    if(localStorage.getItem('guardian_info')){
      this.guardianInfo = JSON.parse(localStorage.getItem('guardian_info'));
      localStorage.removeItem('guardian_info');
    }
    if(localStorage.getItem('grade_info')){
      this.gradeInfo = JSON.parse(localStorage.getItem('grade_info'));
      localStorage.removeItem('grade_info');
    }
  }

  fixBody(){
    $('body').css('height','100vh');
  }
  unfixBody(){
    $('body').css('height','unset');
  }

  setDate(offset){
    let date = new Date();
    var month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
    var dateToday = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()

    var dateString = (date.getFullYear() - offset) + '-' + month + '-' +  dateToday;
    return dateString;
  }

  setPhoneCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0]){
      var temp = COUNTRYLIST.COUNTRY_LIST;
      console.log('this.candidateInfo.whatsapp_number::', this.candidateInfo.whatsapp_number);
      
      if(this.candidateInfo.whatsapp_number && !this.candidateInfo.whatsapp_number.includes('+')){
        this.candidateInfo.whatsapp_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.candidateInfo.whatsapp_number;
      } else if(!this.candidateInfo.whatsapp_number.includes('+')) {
        var tempNum = this.candidateInfo.whatsapp_number.split(' ');
        this.candidateInfo.whatsapp_number = tempNum[1];
        this.candidateInfo.whatsapp_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.candidateInfo.whatsapp_number;
      } else {
        this.candidateInfo.whatsapp_number = this.candidateInfo.whatsapp_number.split(' ')[1];
        this.candidateInfo.whatsapp_number = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] + ' ' + this.candidateInfo.whatsapp_number;
      }
    }
  }

  setCountryCode(){
    if(COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0] && !this.coutryCode){
      var temp = COUNTRYLIST.COUNTRY_LIST;      
        this.coutryCode = '+' + COUNTRYLIST.COUNTRY_LIST[this.selectedCountry]['countryCodes'][0];
      }
    }

    checkPhoneNumber(){
      if(this.candidateInfo.whatsapp_number.length == 1 && this.candidateInfo.whatsapp_number == '0'){
        this.candidateInfo.whatsapp_number = '';
      }
    }

    setDateOptions(){
      for (let i = 1; i < 32; i++) {
        this.dateOptions.push(i < 10 ? '0' + i : i);
        if(i < 13){
          switch(i) {
            case 1: {
               this.monthOptions.push({'name' : 'January', 'value' : '01'});
               break;
            }
            case 2: {
               this.monthOptions.push( {'name' : 'February', 'value' : '02' } );
               break;
            }
            case 3: {
               this.monthOptions.push( {'name' : 'March', 'value' : '03' } );
               break;
            }
            case 4: {
               this.monthOptions.push( {'name' : 'April', 'value' : '04' } );
               break;
            }
            case 5: {
               this.monthOptions.push( {'name' : 'May', 'value' : '05' } );
               break;
            }
            case 6: {
               this.monthOptions.push( {'name' : 'June', 'value' : '06' } );
               break;
            }
            case 7: {
               this.monthOptions.push( {'name' : 'July', 'value' : '07' } );
               break;
            }
            case 8: {
               this.monthOptions.push( {'name' : 'August', 'value' : '08' } );
               break;
            }
            case 9: {
               this.monthOptions.push( {'name' : 'September', 'value' : '09' } );
               break;
            }
            case 10: {
               this.monthOptions.push( {'name' : 'October', 'value' : '10' } );
               break;
            }
            case 11: {
               this.monthOptions.push( {'name' : 'November', 'value' : '11' } );
               break;
            }
            default: {
              this.monthOptions.push( {'name' : 'December', 'value' : '12' } );
              break;
            }
         }
        }
      }
    }
  
    setYearOptions(){
      let j = 20;
      for (let i = 2022; j > 0; i--) {
        this.yearOptions.push(i);
        j--;
      }
    }

}

 