<div class="signupWrapper">
    <form #admissionSignup="ngForm" class="signupForm">
        <p class="serverError" *ngIf="errorMsg">
            <img src="/assets/new-images/error-sign-new.png" alt="error sign">
            {{errorMsg}}
        </p>        
        <div class="inputWrapper normalInputWrapper">
            <p class="asLabel">Email</p>
            <input #nameInput id="email" class="password_input" type="text" name="email" [(ngModel)]="email" placeholder="Enter your Email" />
            <!-- <label for="" class="password_label">Email</label> -->
            <p class="error">
                <img src="/assets/new-images/error-sign-new.png" alt="error sign">
                Please Enter Valid Email
            </p>
        </div>                

        <button *ngIf="!loading" class="formSumitBtn" (click)="sendResetLink()">Send Account Information</button>
        <div *ngIf="loading" class="loader">
            <img class="loadingImg" src="/assets/new-images/pshLoader.webp" alt="loading">
        </div>
        <p class="alreadyText">Need an account?  <a href="javascript:;" routerLink="/admission/signup">Join Now</a> </p>
    </form> 
</div>