import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TtqTrackingService } from 'src/app/services/_services/ttq-tracking.service';

@Component({
  selector: 'app-donate',
  templateUrl: './donate.component.html',
  styleUrls: ['./donate.component.css']
})
export class DonateComponent implements OnInit {

  public newPage = false;

  constructor(
    private activatedRoute: ActivatedRoute,
    private ttqTrackingService: TtqTrackingService
  ) 
  {
    let url = this.activatedRoute.snapshot['_routerState'].url;
  }

  identifyUser() {
    this.ttqTrackingService.identify('mansoor.ahmadd13@gmail.com', '23762362736', '0');
  }

  trackEvent() {
    this.ttqTrackingService.track('ViewContent', 5, 'USD', '123', 'Donate', 'Donation', 5);
  }

  ngOnInit(): void {

  
  }



}
