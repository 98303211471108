import { HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings, AuthenticationService, HelperService } from 'src/app/services/_services';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild('wistiastar') wistiastar: any;

  public currentUser: any;
  public authSub: any;

  public requestedProfile       : any = '';
  public hiddenBox              : any = false;
  public donations              : any;
  public referrals              : any;
  public profile                : any = {
                                          "profileUrl" : ""
                                        };
  public totalContribution      : any = 0;
  public latestDonationDate     : any;
  public latestReferralDate     : any;

  public vistiashared: any;

  constructor(
    private activatedRoute         : ActivatedRoute,
    private helperService          : HelperService,
    private router                 : Router,
    private authenticationService  : AuthenticationService,
  ) { 
    let url = activatedRoute.snapshot['_routerState'].url.split('/')[2];

    if(url){

      if (typeof localStorage != "undefined") {
        this.authSub = this.authenticationService.currentUserS$.subscribe(user => {
          if (user) {
              this.currentUser = user;
              // console.log("--- this.currentUser: ", this.currentUser);
          } else {
            this.currentUser = null;
          }
        });
      }
      this.requestedProfile = url;

      this.getProfile();

    } 
  }
  
  ngAfterViewInit(): void {
    var s = document.createElement("script");
    s.type = "text/javascript";
    s.src = "https://fast.wistia.com/embed/medias/k3l6f2s6hx.jsonp";
    this.wistiastar.nativeElement.prepend(s);
    this.vistiashared = document.createElement("script");
    this.vistiashared.type = "text/javascript";
    this.vistiashared.src = "https://fast.wistia.com/assets/external/E-v1.js";
    this.wistiastar.nativeElement.prepend(this.vistiashared);
  }

  ngOnInit(): void {



  }

  getProfile(){

    let data = new FormData();  
    data.append("action" , "get-user-donations");
    data.append("profileUrl" , this.requestedProfile);

    let corsHeaders = new HttpHeaders({
      'Content-Type': 'multipart/form-data',
      'Accept': 'multipart/form-data',
    });

    let url  = AppSettings.API_ENDPOINT + 'donations/user-donation.php';

    this.helperService.httpPostRequests(url, data).then(resp => {

      if(resp.status != 'ERROR'){        
        this.donations =  resp.data.user_donation;
        this.referrals =  resp.data.referral_donation;
        this.profile =  resp.data.site_user;       

          // console.log(' this.profile ::',  this.profile );
          

          this.setTotalContribution();


      } else {
        this.router.navigate(['/']);
      }
      
    }).catch(error => {
        console.error("error: ");  
    });

  }

  setTotalContribution(){
    for (let i = 0; i < this.donations.length; i++) {
      const element = parseInt(this.donations[i].amount);
      this.totalContribution += element;

      if(i == 0){
        this.latestDonationDate = this.donations[i].date;
      }

    }
    for (let i = 0; i < this.referrals.length; i++) {
      const element = parseInt(this.referrals[i].amount);
      this.totalContribution += element;

      if(i == 0){
        this.latestReferralDate = this.referrals[i].date;
      }

    }
  }

 
}
